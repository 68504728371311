import { store } from "../redux/store";
import request from "./request";
import { setSubscriptions } from "../redux/actions/setSubscription";
import { SubscriptionState } from "../redux/models/subscriptionState";
import { App } from "@capacitor/app";
import { SubscriptionDetails, SubscriptionItems } from "refsix-js-models";
import moment from "moment/moment";
import { getLanguageCode } from "../utils/localeUtils";
import { useSubscription } from "../components/hooks";

const SUBSCRIPTION_URL = "/subscriptions/";
const PAYMENT_PROVIDERS = ["google", "apple", "stripe"];

export function hasFeatureAccessFromState(
    subscriptionState: SubscriptionState,
    feature: string
): boolean {
    const features = subscriptionState.features;
    return features.indexOf(feature) >= 0;
}

export function hasFeatureAccess(feature: string): boolean {
    const subState = store.getState().subscriptions;
    return hasFeatureAccessFromState(subState, feature);
}

export function filterActiveSubscriptions(subscriptions: SubscriptionItems[]) {
    return subscriptions.filter((subscription) => {
        return subscription.status === "active";
    });
}

export function getSubscriptionStatus(): string {
    const subscriptions = store.getState().subscriptions.subscriptions;
    const activeSubs = filterActiveSubscriptions(subscriptions);
    const activeNotExpired = activeSubs.filter((subscription) => {
        return subscription.validUntil > Date.now();
    });
    if (activeSubs.length && activeNotExpired.length) {
        return "active";
    } else if (activeSubs.length && !activeNotExpired.length) {
        return "expired";
    }
    return "none";
}

export function getSubscriptionItem(
    planFeature: string
): SubscriptionItems | undefined {
    const subscriptions = store.getState().subscriptions.subscriptions;
    var subscriptionItems = subscriptions.filter(function (
        subscriptionItem: SubscriptionItems
    ) {
        return subscriptionItem.planFeatures === planFeature;
    });
    if (subscriptionItems && subscriptionItems.length > 0) {
        return subscriptionItems[0];
    }
    return undefined;
}

export function checkIfDbuUser(subscriptions: SubscriptionItems[]): boolean {
    for (let i = 0; i < subscriptions.length; i++) {
        if (subscriptions[i].planFeatures === "dbuMember") {
            return true;
        }
    }
    return false;
}

export function checkIfBeenPro(): boolean {
    const subscriptions = store.getState().subscriptions;
    return subscriptions.beenPro || false;
}

export function checkForActiveFreeTrialSubscription(
    subscriptions: SubscriptionItems[] | []
): any[] {
    return subscriptions.filter((subs) => {
        return (
            subs.planName === "registration-free-trial" &&
            subs.provider === "refsix" &&
            subs.rawData.freeTrial &&
            subs.validUntil > Date.now()
        );
    });
}

// react hook that returns boolean from checkForActiveFreeTrialSubscription
export function useHasActiveFreeTrial(): boolean {
    const subscriptions = useSubscription();
    return subscriptions?.subscriptions
        ? checkForActiveFreeTrialSubscription(subscriptions?.subscriptions)
              .length > 0
        : false;
}

export function getNumberOfDaysLeftOnFreeTrial(
    subscriptions: SubscriptionItems[] | []
): number {
    const activeSubs = checkForActiveFreeTrialSubscription(subscriptions);

    if (activeSubs.length > 0) {
        return Math.ceil(
            moment(activeSubs[0].validUntil)
                .endOf("day")
                .diff(moment().endOf("day"), "days", true)
        );
    }

    return 0;
}

export function checkForActiveMgmSubscription(
    subscriptions: SubscriptionItems[]
): SubscriptionItems | undefined {
    return subscriptions.find(
        (sub) =>
            sub.planName === "mgm" &&
            sub.status === "active" &&
            sub.validUntil > Date.now()
    );
}

export function checkIfBeenBasic(subscription: SubscriptionDetails): boolean {
    return subscription.beenBasic || false;
}

export function currentProvider(): string {
    const subscriptions = store.getState().subscriptions.subscriptions;
    const activeSubs = filterActiveSubscriptions(subscriptions)
        .filter((sub) => {
            return PAYMENT_PROVIDERS.indexOf(sub.provider) >= 0;
        })
        .sort((a, b) => {
            return b.validUntil - a.validUntil;
        });

    return activeSubs.length ? activeSubs[0].provider : "none";
}

export function checkIfPro(subscriptions: SubscriptionItems[]): boolean {
    const activeSubs = subscriptions.filter((subs) => {
        return (
            subs.status === "active" &&
            subs.validUntil > Date.now() &&
            subs.planFeatures === "pro"
        );
    });

    return activeSubs.length > 0;
}

export function checkIfBasic(subscriptions: SubscriptionItems[]): boolean {
    const activeSubs = subscriptions.filter((subs) => {
        return (
            subs.status === "active" &&
            subs.validUntil > Date.now() &&
            subs.planFeatures === "basic"
        );
    });

    return activeSubs.length > 0;
}

export function isValidSubscription(
    subscriptionDetails: SubscriptionDetails
): boolean {
    const now = Date.now();
    if (!subscriptionDetails.subscriptions.length) {
        return true;
    }
    // TODO test whether this has the desired affect with non-paid subscriptions expiring e.g. dbu membership
    for (let i = 0; i < subscriptionDetails.subscriptions.length; i++) {
        if (
            subscriptionDetails.subscriptions[i].validUntil +
                SubscriptionService.GRACE_PERIOD <
            now
        ) {
            return false;
        }
    }
    return true;
}

export function shouldRefreshSubscriptions(refreshTimestamp: number): boolean {
    return refreshTimestamp < Date.now();
}

export function username() {
    const currentSession = store.getState().auth.session;
    if (!currentSession) {
        return;
    }
    return currentSession.user_id;
}

export async function getOnlineSubscription() {
    if (!username()) {
        return Promise.reject();
    }

    try {
        const response = await request.get<SubscriptionState>(
            SUBSCRIPTION_URL + username()
        );
        return Promise.resolve(response.data);
    } catch (error) {
        return Promise.reject(error);
    }
}

export function getSubscriptions(): Promise<any> {
    return new Promise((resolve, reject) => {
        if (username()) {
            const subState = store.getState().subscriptions;
            const subscriptions = subState.subscriptions;
            const refreshSubscriptions = subState.refreshTs
                ? shouldRefreshSubscriptions(subState.refreshTs)
                : true;

            if (!subscriptions || refreshSubscriptions) {
                getOnlineSubscription()
                    .then(resolve)
                    .catch((error) => {
                        return resolve(store.getState().subscriptions); // get latest as const can be out of sync with state
                    });
            } else {
                resolve(store.getState().subscriptions); // get latest as const can be out of sync with state
            }
        } else {
            reject("Not logged in");
        }
    });
}

export async function refreshSubscriptions() {
    return SubscriptionService.getInstance().refreshSubscription();
}

export async function startFreeTrial(
    username: string,
    planDescription: string,
    trialLength: number
) {
    try {
        await request.post(`/free-trial-start/${username}`, {
            planDescription: planDescription,
            freeTrialLength: trialLength,
        });
        await refreshSubscriptions();
    } catch (error) {
        throw error;
    }
}

export function isUserPlusEligible(): boolean {
    // TODO fix me when we have translations
    const lang = getLanguageCode();
    if (!(lang === "en" || lang === "da")) {
        return false;
    }

    const isPro = store.getState().subscriptions.isPro;
    const signUpTimestamp = store.getState().subscriptions.signUpTimestamp;

    return !!(
        signUpTimestamp &&
        !isPro &&
        moment().diff(moment(signUpTimestamp), "days") > 60
    );

    // TODO reinstate if we want to do the A/B test on eligability
    // if (signUpTimestamp) {
    //     // A/B test for eligibility 14 days vs 60 days
    //     const refsixEligibilityExperimentVariant = getExperimentVariant(
    //         "refsixPlusEligibility1"
    //     );
    //     if (refsixEligibilityExperimentVariant === 1) {
    //         return true;
    //     }
    //
    //     return !isPro && moment().diff(moment(signUpTimestamp), "days") > 60;
    // }
    //
    // return false;
}

export class SubscriptionService {
    private static instance: SubscriptionService | undefined;

    private static readonly LOCAL_STORAGE_KEY = "refsix.subscription";
    private static readonly LOCAL_STORAGE_KEY_REFRESH =
        "refsix.subscription_refresh";
    private static readonly LOCAL_STORAGE_KEY_EXPIRY =
        "refsix.subscription_expiry";
    private static readonly SUBSCRIPTION_URL = "/subscriptions/";
    private static readonly TIMEOUT_MAX_VALUE = 2147483647;
    private static readonly MIXPANEL_ISPRO_PROPERTY = "userIsPro";
    private expiryTimeout: any; // todo
    private resumeListener: any; // todo
    public static readonly REFRESH_PERIOD = 7 * 24 * 60 * 60 * 1000;
    public static readonly GRACE_PERIOD = 7 * 24 * 60 * 60 * 1000;
    // Display 'Free User' on as the plan descriptions for free accounts.

    // TODO: Need to figure out how to translate ("myaccount.free") after changing to i18nnext
    private readonly defaultUserSubscription = {
        details: {
            subscriptions: [
                {
                    planDescription: "myaccount.free",
                },
            ],
        },
    };

    private constructor() {}

    static getInstance(): SubscriptionService {
        if (!SubscriptionService.instance) {
            SubscriptionService.instance = new SubscriptionService();
        }
        return SubscriptionService.instance;
    }

    //
    // _saveExpiryTimestamp(revalidationTimestamp) {
    //     var timestampToStore = revalidationTimestamp;
    //     if (revalidationTimestamp === Infinity) {
    //         timestampToStore = 'Infinity';
    //     }
    //     LocalStorage.setItem(LOCAL_STORAGE_KEY_EXPIRY, timestampToStore);
    // };
    //
    // _saveRefreshTimestamp(revalidationTimestamp) {
    //     LocalStorage.setItem(LOCAL_STORAGE_KEY_REFRESH, revalidationTimestamp);
    // };
    //
    // _readFromLsOrFallback(key, fallbackValue) {
    //     return new Promise(function(resolve) {
    //         LocalStorage.getItem(key)
    //             .then(function(value) {
    //                 return resolve(value);
    //             })
    //             .catch(function() {
    //                 return resolve(fallbackValue);
    //             })
    //     });
    // };
    //

    _checkRefreshSubscriptions() {
        const refreshTs = store.getState().subscriptions.refreshTs;
        if (refreshTs) {
            shouldRefreshSubscriptions(refreshTs);
        }
        // default to always checking (e.g. free user might have upgraded)
        return true;
    }

    _calculateRefreshDate(
        lastRefreshedAt: number,
        expiryDate: number,
        isPro: boolean,
        isPlus: boolean
    ): number {
        if (isPro || isPlus) {
            return Math.min(
                lastRefreshedAt + SubscriptionService.REFRESH_PERIOD,
                expiryDate
            );
        } else {
            //make free users refresh their subscription every time they come in the app
            return 0;
        }
    }

    _calculateExpiryDate(subscriptions: SubscriptionItems[]) {
        let closestToExpire = Infinity;

        subscriptions.forEach(function (subscription) {
            closestToExpire = Math.min(
                closestToExpire,
                subscription.validUntil
            );
        });
        return closestToExpire;
    }

    // clearSubscription() {
    //     LocalStorage.removeItem(LOCAL_STORAGE_KEY);
    //     LocalStorage.removeItem(LOCAL_STORAGE_KEY_REFRESH);
    //     LocalStorage.removeItem(LOCAL_STORAGE_KEY_EXPIRY);
    //     self._userFeatures = [];
    //     self._setPro(undefined);
    // };
    //

    // _setPro(val) {
    //     var superProperties = {};
    //     superProperties[MIXPANEL_ISPRO_PROPERTY] = val;
    //     MixPanelService.registerSuperProperties(superProperties);
    // };
    //
    // _checkIfDowngrade(isNowPro) {
    //     self._readLocalSubscription().then(function(oldSub) {
    //         var wasPro = (oldSub && oldSub.details && oldSub.details.subscriptions) ? self.checkIfPro(oldSub.details.subscriptions) : false;
    //         if (wasPro && !isNowPro) {
    //             // TODO need new mechnaism
    //             $rootScope.$emit('subscription.invalid_downgrade');
    //         }
    //     });
    // };

    // TODO figure out waht we are going to do about events here
    _restoreSubscriptions() {
        // TODO
        // var listener = $rootScope.$on('payment:verified', function() {
        //     listener && listener();
        //     self.refreshSubscription();
        // });
        // setTimeout(function() {
        //     listener && listener();
        // }, 60000);
        // TODO
        // PaymentService.restoreSubscriptions();
        // TODO
        // $analytics.eventTrack('subscriptionService', {
        //     category: 'restore', label: 'restore'
        // });
    }

    _setExpiryPurchaseReset(expiryMs: number) {
        if (this.expiryTimeout) {
            clearTimeout(this.expiryTimeout);
        }
        if (expiryMs === Infinity) {
            return;
        }

        var now = new Date().getTime();
        var millisUntilExpiry = expiryMs + 60000 - now;

        if (
            millisUntilExpiry >= 0 &&
            millisUntilExpiry >= SubscriptionService.TIMEOUT_MAX_VALUE
        ) {
            this.expiryTimeout = setTimeout(
                this._restoreSubscriptions,
                SubscriptionService.TIMEOUT_MAX_VALUE - 1000
            );
        } else if (
            millisUntilExpiry >= 0 &&
            millisUntilExpiry < SubscriptionService.TIMEOUT_MAX_VALUE
        ) {
            this.expiryTimeout = setTimeout(
                this._restoreSubscriptions,
                millisUntilExpiry
            );
        } else {
            this._restoreSubscriptions();
        }
    }

    _processSubscriptions(subs: SubscriptionState) {
        let downgrade = false;

        if (subs && isValidSubscription(subs)) {
            const isPro = checkIfPro(subs.subscriptions);
            const isBasic = checkIfBasic(subs.subscriptions);
            // TODO we need to figure this out
            // this._checkIfDowngrade(isPro);

            const expiryTs = this._calculateExpiryDate(subs.subscriptions);
            const lastUpdated = subs.updatedAt || Date.now(); // TODO is this good?
            const refreshTs = this._calculateRefreshDate(
                lastUpdated,
                expiryTs,
                isPro,
                isBasic
            );

            const subState = new SubscriptionState(
                subs.subscriptions,
                subs.features,
                subs.signUpTimestamp,
                subs.beenPro,
                subs.beenBasic,
                Date.now(),
                refreshTs,
                expiryTs,
                isPro,
                isBasic
            );
            store.dispatch(setSubscriptions(subState));

            this._setExpiryPurchaseReset(expiryTs);
        } else {
            const subState = new SubscriptionState(
                subs.subscriptions,
                subs.features,
                subs.signUpTimestamp,
                subs.beenPro,
                subs.beenBasic,
                Date.now(),
                0,
                undefined,
                false,
                false
            );
            store.dispatch(setSubscriptions(subState));

            downgrade = true;
        }

        // TODO still do this?
        // if (subs.details) {
        //     if (subs.details.subscriptions.length === 0) {
        //         subs = defaultUserSubscription;
        //     }
        // }

        // TODO events
        // // Emit some events
        // $rootScope.$emit('subscription.updated');
        // if(downgrade){
        //     $rootScope.$emit('subscription.invalid_downgrade');
        // }

        return subs;
    }

    _onResume() {
        // TODO also add basic
        const isPro = store.getState().subscriptions.isPro;
        const isPlus = store.getState().subscriptions.isPlus;

        if (!isPro || !isPlus) {
            this.refreshSubscription().catch((error) => console.log(error));
        }
    }

    checkSubscriptions() {
        const self = this;
        if (username()) {
            // once the app has settled, add a resume listener to check if free users have upgraded when they re-enter the app.
            if (!this.resumeListener) {
                setTimeout(function () {
                    self.resumeListener =
                        self.resumeListener ||
                        App.addListener("appStateChange", ({ isActive }) => {
                            if (isActive) {
                                self._onResume();
                            }
                        });
                }, 10000);
            }

            getSubscriptions()
                .then((subState: SubscriptionState) => {
                    self._processSubscriptions(subState);
                })
                .catch(function (error) {
                    console.log("Couldn't get subscription", error);
                });
        }
    }

    refreshSubscription(): Promise<string> {
        const self = this;
        return getOnlineSubscription()
            .then(function (subs) {
                self._processSubscriptions(subs);
                return Promise.resolve("ok");
            })
            .catch(function () {
                return Promise.reject("Couldn't get subscription");
            });
    }

    //
    //
    //
    // init(isLoggedIn: boolean) {
    //     const self = this;
    //     this.initialize(isLoggedIn);
    //     // TODO replace events with rxjs?
    //     // $rootScope.$on('sl:logout', self.clearSubscription);
    //     // $rootScope.$on('login:completed', function onLoginCompleted() {
    //     //     console.log('login:completed triggering SubscriptionService.checkSubscriptions');
    //     //     self.checkSubscriptions(arguments)
    //     // }); // NOTE not sl:login
    //     // $rootScope.$on('connection.online', function onConnectionOnline() {
    //     //     console.log('connection.online triggering SubscriptionService.checkSubscriptions');
    //     //     self.checkSubscriptions(arguments);
    //     // });
    // }
    //
    initialize(isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.checkSubscriptions();
        }

        // return self.readExpiryTimestamp()
        //     .then(self.validateExpiryTimestamp);
    }

    // validateExpiryTimestamp(expiry) {
    //     if (expiry && (expiry + GRACE_PERIOD <= new Date().getTime())) {
    //         if (!ConnectionUtils.isOnline()) {
    //             $rootScope.$emit('subscription.expired_offline');
    //         }
    //     }
    // };
}
