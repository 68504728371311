import {
    AggregatedStats,
    ICardCodeMap,
    MatchPhone,
    OfficialRole,
    SinBinSystem,
} from "refsix-js-models";
import { processMatch } from "./matchEvents";

export function reduceMatchStats(
    matches: MatchPhone[],
    startDate?: number,
    endDate?: number
): AggregatedStats {
    let stats = new AggregatedStats();
    stats = matches.reduce(addMatchToAggregation, stats);
    if (matches.length > 0) {
        finaliseAggregatedStats(stats, startDate, endDate);
    }

    return stats;
}

function divide(dividend, divisor) {
    if (divisor) {
        return dividend / divisor;
    }
    return 0;
}

function plusEquals(total, value) {
    total = total || 0;
    value = value || 0;

    return total + value;
}

function averageArray(arrayWithTotals: number[], totalOccurrences: number) {
    if (totalOccurrences === 0) {
        return arrayWithTotals;
    }
    return arrayWithTotals.map(function (total) {
        return divide(total, totalOccurrences);
    });
}

function averageSprintsArray(
    arrayWithTotals: number[][],
    totalOccurrencesPeriods: number,
    totalOccurrencesExtraTime: number,
    periodsNumber: number
) {
    if (totalOccurrencesPeriods === 0) {
        return arrayWithTotals;
    }
    return arrayWithTotals.map(function (innerArray, index) {
        return innerArray.map(function (total) {
            if (index + 1 > periodsNumber) {
                return divide(total, totalOccurrencesExtraTime);
            }
            return divide(total, totalOccurrencesPeriods);
        });
    });
}

function averageArrayWithExtraTime(
    arrayWithTotals: number[],
    totalOccurrencesPeriods: number,
    totalOccurrencesExtraTime: number,
    periodsNumber: number
) {
    if (totalOccurrencesPeriods === 0) {
        return arrayWithTotals;
    }
    return arrayWithTotals.map(function (total, index) {
        if (index + 1 > periodsNumber) {
            return divide(total, totalOccurrencesExtraTime);
        }
        return divide(total, totalOccurrencesPeriods);
    });
}

function aggregateMap(
    aggregatedMap: ICardCodeMap,
    matchMap: ICardCodeMap
): ICardCodeMap {
    Object.keys(matchMap).forEach(function (key) {
        if (typeof aggregatedMap[key] === "undefined") {
            aggregatedMap[key] = 0;
        }
        aggregatedMap[key] = plusEquals(aggregatedMap[key], matchMap[key]);
    });
    return aggregatedMap;
}

function aggregateArray(
    aggregatedArray: number[],
    matchArray: number[]
): number[] {
    matchArray.forEach(function (value, i) {
        if (typeof aggregatedArray[i] === "undefined") {
            aggregatedArray[i] = 0;
        }
        aggregatedArray[i] = plusEquals(aggregatedArray[i], value);
    });
    return aggregatedArray;
}

function aggregateNestedArray(
    aggregatedArray: number[][],
    matchArray: number[][]
): number[][] {
    matchArray.forEach(function (innerArray, outerIndex) {
        if (typeof aggregatedArray[outerIndex] === "undefined") {
            aggregatedArray[outerIndex] = [0, 0, 0];
        }
        if (innerArray) {
            innerArray.forEach(function (value, innerIndex) {
                aggregatedArray[outerIndex][innerIndex] = plusEquals(
                    aggregatedArray[outerIndex][innerIndex],
                    value
                );
            });
        } else {
            console.log("innerArray is undefined or null");
        }
    });
    return aggregatedArray;
}

export function addMatchToAggregation(
    stats: AggregatedStats,
    match: MatchPhone | undefined
): AggregatedStats {
    if (!match) {
        return stats;
    }
    let matchStats = match.stats;

    if (
        matchStats &&
        (typeof matchStats.version === "undefined" || matchStats.version < 0)
    ) {
        matchStats = processMatch(match);
    }

    if (!matchStats) {
        return stats;
    }

    stats.yellowCardTotal = plusEquals(
        stats.yellowCardTotal,
        matchStats.yellowCardTotal
    );
    stats.yellowCardHomeTotal = plusEquals(
        stats.yellowCardHomeTotal,
        matchStats.yellowCardHomeTotal
    );
    stats.yellowCardAwayTotal = plusEquals(
        stats.yellowCardAwayTotal,
        matchStats.yellowCardAwayTotal
    );
    stats.redCardHomeTotal = plusEquals(
        stats.redCardHomeTotal,
        matchStats.redCardHomeTotal
    );
    stats.redCardAwayTotal = plusEquals(
        stats.redCardAwayTotal,
        matchStats.redCardAwayTotal
    );
    stats.redCardTotal = plusEquals(
        stats.redCardTotal,
        matchStats.redCardTotal
    );
    stats.sinBinsTotal = plusEquals(
        stats.sinBinsTotal,
        matchStats.sinBinsTotal
    );
    stats.sinBinsMinutesSpent = plusEquals(
        stats.sinBinsMinutesSpent,
        matchStats.sinBinsMinutesSpent
    );
    stats.sinBinsGoalOppositeTeam = plusEquals(
        stats.sinBinsGoalOppositeTeam,
        matchStats.sinBinsGoalOppositeTeam
    );
    stats.sinBinsGoalPlayerTeam = plusEquals(
        stats.sinBinsGoalPlayerTeam,
        matchStats.sinBinsGoalPlayerTeam
    );
    stats.yellowCardCodes = aggregateMap(
        stats.yellowCardCodes,
        matchStats.yellowCardCodes
    );
    stats.redCardCodes = aggregateMap(
        stats.redCardCodes,
        matchStats.redCardCodes
    );
    stats.yellowCardPositions = aggregateArray(
        stats.yellowCardPositions,
        matchStats.yellowCardPositions
    );
    stats.redCardPositions = aggregateArray(
        stats.redCardPositions,
        matchStats.redCardPositions
    );

    stats.goalsHomeTotal = plusEquals(
        stats.goalsHomeTotal,
        matchStats.goalsHomeTotal
    );
    stats.goalsTotal = plusEquals(stats.goalsTotal, matchStats.goalsTotal);
    stats.goalsAwayTotal = plusEquals(
        stats.goalsAwayTotal,
        matchStats.goalsAwayTotal
    );

    stats.penaltyShotHomeScored = plusEquals(
        stats.penaltyShotHomeScored,
        matchStats.penaltyShotHomeScored
    );
    stats.penaltyShotHomeMissed = plusEquals(
        stats.penaltyShotHomeMissed,
        matchStats.penaltyShotHomeMissed
    );
    stats.penaltyShotAwayScored = plusEquals(
        stats.penaltyShotAwayScored,
        matchStats.penaltyShotAwayScored
    );
    stats.penaltyShotAwayMissed = plusEquals(
        stats.penaltyShotAwayMissed,
        matchStats.penaltyShotAwayMissed
    );

    if (matchStats.winnerHome) {
        stats.winnerHome++;
    } else if (matchStats.winnerAway) {
        stats.winnerAway++;
    } else if (matchStats.winnerDraw) {
        stats.winnerDraw++;
    }

    stats.feesTotal = plusEquals(stats.feesTotal, matchStats.feesTotal);
    stats.expensesTotal = plusEquals(
        stats.expensesTotal,
        matchStats.expensesTotal
    );
    stats.earningsTotal = plusEquals(
        stats.earningsTotal,
        matchStats.earningsTotal
    );
    stats.earningsAsRefereeTotal = plusEquals(
        stats.earningsAsRefereeTotal,
        matchStats.earningsAsRefereeTotal
    );
    stats.earningsAsAssistantTotal = plusEquals(
        stats.earningsAsAssistantTotal,
        matchStats.earningsAsAssistantTotal
    );
    stats.earningsAsFourthOfficialTotal = plusEquals(
        stats.earningsAsFourthOfficialTotal,
        matchStats.earningsAsFourthOfficialTotal
    );
    stats.earningsAsObserverTotal = plusEquals(
        stats.earningsAsObserverTotal,
        matchStats.earningsAsObserverTotal
    );

    stats.minutesPlayed = plusEquals(
        stats.minutesPlayed,
        matchStats.minutesPlayed
    );
    stats.minutesPlayedAsReferee = plusEquals(
        stats.minutesPlayedAsReferee,
        matchStats.minutesPlayedAsReferee
    );
    stats.minutesPlayedAsAssistant = plusEquals(
        stats.minutesPlayedAsAssistant,
        matchStats.minutesPlayedAsAssistant
    );
    stats.minutesPlayedAsFourthOfficial = plusEquals(
        stats.minutesPlayedAsFourthOfficial,
        matchStats.minutesPlayedAsFourthOfficial
    );
    stats.minutesPlayedAsObserver = plusEquals(
        stats.minutesPlayedAsObserver,
        matchStats.minutesPlayedAsObserver
    );

    stats.matchesTotal++;

    if (match.date) {
        let matchDate = new Date(match.date);
        let matchTs = matchDate.getTime();
        stats.dateOldestMatch =
            stats.dateOldestMatch > matchTs ? matchTs : stats.dateOldestMatch;
        stats.dateNewestMatch =
            stats.dateNewestMatch < matchTs ? matchTs : stats.dateNewestMatch;
        stats.matchesByMonth[matchDate.getMonth()]++;
    }

    switch (match.officialRole) {
        case OfficialRole.assistant:
            stats.matchesAsAssistant++;
            if (matchStats.gpsAvailable[0]) {
                stats.matchesAsAssistantWithGPS++;
            }
            break;
        case OfficialRole.fourthOfficial:
            stats.matchesAsFourthOfficial++;
            break;
        case OfficialRole.observer:
            stats.matchesAsObserver++;
            break;
        default:
            stats.matchesAsReferee++;
            if (matchStats.gpsAvailable[0]) {
                stats.matchesAsRefereeWithGPS++;
            }
            break;
    }

    stats.heartRateMaxTotal = plusEquals(
        stats.heartRateMaxTotal,
        matchStats.heartRateMax
    );
    stats.heartRateAverageTotal = plusEquals(
        stats.heartRateAverageTotal,
        matchStats.heartRateAverage
    );
    stats.heartRateAvailable = aggregateArray(
        stats.heartRateAvailable,
        matchStats.heartRateAvailable
    );
    stats.gpsAvailable = aggregateArray(
        stats.gpsAvailable,
        matchStats.gpsAvailable
    );

    if (match.periodsNo == "3") {
        stats.matchesThirds++;
    } else if (match.periodsNo == "4") {
        stats.matchesQuarters++;
    } else {
        stats.matchesHalves++;
    }

    if (match.earnings) {
        stats.matchesWithEarnings++;
    }

    if (match.sinBinSystem && match.sinBinSystem !== SinBinSystem.none) {
        stats.matchesWithSinBins++;
    }

    stats.playedET = aggregateArray(stats.playedET, matchStats.playedET);
    stats.playedPenalties = aggregateArray(
        stats.playedPenalties,
        matchStats.playedPenalties
    );

    stats.distanceByHalvesTotal = aggregateArray(
        stats.distanceByHalvesTotal,
        matchStats.distanceByHalvesTotal
    );
    stats.distanceByThirdsTotal = aggregateArray(
        stats.distanceByThirdsTotal,
        matchStats.distanceByThirdsTotal
    );
    stats.distanceByQuartersTotal = aggregateArray(
        stats.distanceByQuartersTotal,
        matchStats.distanceByQuartersTotal
    );

    stats.speedCategoryDurations = aggregateArray(
        stats.speedCategoryDurations,
        matchStats.speedCategoryDurations
    );
    stats.speedCategoryDistances = aggregateArray(
        stats.speedCategoryDistances,
        matchStats.speedCategoryDistances
    );

    stats.distanceTotal = plusEquals(
        stats.distanceTotal,
        matchStats.distanceTotal
    );
    stats.distanceAsAssistantTotal = plusEquals(
        stats.distanceAsAssistantTotal,
        matchStats.distanceAsAssistantTotal
    );
    stats.distanceAsRefereeTotal = plusEquals(
        stats.distanceAsRefereeTotal,
        matchStats.distanceAsRefereeTotal
    );
    stats.distanceHalvesTotal = plusEquals(
        stats.distanceHalvesTotal,
        matchStats.distanceHalvesTotal
    );
    stats.distanceThirdsTotal = plusEquals(
        stats.distanceThirdsTotal,
        matchStats.distanceThirdsTotal
    );
    stats.distanceQuartersTotal = plusEquals(
        stats.distanceQuartersTotal,
        matchStats.distanceQuartersTotal
    );
    stats.gpsCalibratedTotal = plusEquals(
        stats.gpsCalibratedTotal,
        matchStats.gpsCalibratedTotal
    );

    stats.heartRateZoneDuration = aggregateArray(
        stats.heartRateZoneDuration,
        matchStats.heartRateZoneDuration
    );

    stats.gpsProcessed = plusEquals(
        stats.gpsProcessed,
        matchStats.gpsProcessed
    );
    stats.heartRateProcessed = plusEquals(
        stats.heartRateProcessed,
        matchStats.heartRateProcessed
    );

    stats.sprintsTotal = aggregateArray(
        stats.sprintsTotal,
        matchStats.sprintsTotal
    );
    stats.sprintsDistanceTotal = aggregateArray(
        stats.sprintsDistanceTotal,
        matchStats.sprintsDistanceTotal
    );

    stats.sprintsByHalvesTotal = aggregateNestedArray(
        stats.sprintsByHalvesTotal,
        matchStats.sprintsByHalvesTotal
    );
    stats.sprintsByThirdsTotal = aggregateNestedArray(
        stats.sprintsByThirdsTotal,
        matchStats.sprintsByThirdsTotal
    );
    stats.sprintsByQuartersTotal = aggregateNestedArray(
        stats.sprintsByQuartersTotal,
        matchStats.sprintsByQuartersTotal
    );

    stats.sprintsByHalvesDistanceTotal = aggregateNestedArray(
        stats.sprintsByHalvesDistanceTotal,
        matchStats.sprintsByHalvesDistanceTotal
    );
    stats.sprintsByThirdsDistanceTotal = aggregateNestedArray(
        stats.sprintsByThirdsDistanceTotal,
        matchStats.sprintsByThirdsDistanceTotal
    );
    stats.sprintsByQuartersDistanceTotal = aggregateNestedArray(
        stats.sprintsByQuartersDistanceTotal,
        matchStats.sprintsByQuartersDistanceTotal
    );

    //TODO these properties below are not being averaged at the end. Should we do it? They will show the total sprints and total distance of sprints for halves, thirds and quarters
    stats.sprintsHalvesTotal = aggregateArray(
        stats.sprintsHalvesTotal,
        matchStats.sprintsHalvesTotal
    );
    stats.sprintsThirdsTotal = aggregateArray(
        stats.sprintsThirdsTotal,
        matchStats.sprintsThirdsTotal
    );
    stats.sprintsQuartersTotal = aggregateArray(
        stats.sprintsQuartersTotal,
        matchStats.sprintsQuartersTotal
    );

    stats.sprintsDistanceHalvesTotal = aggregateArray(
        stats.sprintsDistanceHalvesTotal,
        matchStats.sprintsDistanceHalvesTotal
    );
    stats.sprintsDistanceThirdsTotal = aggregateArray(
        stats.sprintsDistanceThirdsTotal,
        matchStats.sprintsDistanceThirdsTotal
    );
    stats.sprintsDistanceQuartersTotal = aggregateArray(
        stats.sprintsDistanceQuartersTotal,
        matchStats.sprintsDistanceQuartersTotal
    );

    stats.injuryTimeTotal = plusEquals(
        stats.injuryTimeTotal,
        matchStats.injuryTimeTotal
    );
    stats.injuryTimeHalvesTotal = plusEquals(
        stats.injuryTimeHalvesTotal,
        matchStats.injuryTimeHalvesTotal
    );
    stats.injuryTimeThirdsTotal = plusEquals(
        stats.injuryTimeThirdsTotal,
        matchStats.injuryTimeThirdsTotal
    );
    stats.injuryTimeQuartersTotal = plusEquals(
        stats.injuryTimeQuartersTotal,
        matchStats.injuryTimeQuartersTotal
    );

    stats.injuryTimeByHalvesTotal = aggregateArray(
        stats.injuryTimeByHalvesTotal,
        matchStats.injuryTimeByHalvesTotal
    );
    stats.injuryTimeByThirdsTotal = aggregateArray(
        stats.injuryTimeByThirdsTotal,
        matchStats.injuryTimeByThirdsTotal
    );
    stats.injuryTimeByQuartersTotal = aggregateArray(
        stats.injuryTimeByQuartersTotal,
        matchStats.injuryTimeByQuartersTotal
    );

    if (Object.keys(matchStats.gpsCenterPoint).length > 0) {
        stats.gpsCenterPoints.push(matchStats.gpsCenterPoint);
    }
    return stats;
}

export function finaliseAggregatedStats(
    stats: AggregatedStats,
    startDate?: number,
    endDate?: number
) {
    let monthsSinceFirstMatch;
    if (startDate && endDate) {
        monthsSinceFirstMatch =
            (endDate - startDate) / (1000 * 60 * 60 * 24 * 30);
    } else {
        monthsSinceFirstMatch =
            (new Date().getTime() - stats.dateOldestMatch) /
            (1000 * 60 * 60 * 24 * 30);
    }
    stats.matchesAveragePerMonth = stats.matchesTotal / monthsSinceFirstMatch;

    stats.cardsAverage = divide(
        stats.redCardTotal + stats.yellowCardTotal,
        stats.matchesTotal
    );
    stats.redCardAverage = divide(stats.redCardTotal, stats.matchesTotal);
    stats.yellowCardAverage = divide(stats.yellowCardTotal, stats.matchesTotal);
    stats.sinBinsAverage = divide(stats.sinBinsTotal, stats.matchesWithSinBins);

    stats.winnerHomePercentage = divide(stats.winnerHome, stats.matchesTotal);
    stats.winnerAwayPercentage = divide(stats.winnerAway, stats.matchesTotal);
    stats.winnerDrawPercentage = divide(stats.winnerDraw, stats.matchesTotal);

    stats.heartRateMax = divide(
        stats.heartRateMaxTotal,
        stats.heartRateAvailable[0]
    );
    stats.heartRateAverage = divide(
        stats.heartRateAverageTotal,
        stats.heartRateAvailable[0]
    );

    stats.goalsAverage = divide(stats.goalsTotal, stats.matchesTotal);

    stats.distanceAverage = divide(stats.distanceTotal, stats.gpsAvailable[0]);
    stats.distanceAsRefereeAverage = divide(
        stats.distanceAsRefereeTotal,
        stats.matchesAsRefereeWithGPS
    );
    stats.distanceAsAssistantAverage = divide(
        stats.distanceAsAssistantTotal,
        stats.matchesAsAssistantWithGPS
    );
    stats.distanceHalvesAverage = divide(
        stats.distanceHalvesTotal,
        stats.gpsAvailable[1]
    );
    stats.distanceThirdsAverage = divide(
        stats.distanceThirdsTotal,
        stats.gpsAvailable[2]
    );
    stats.distanceQuartersAverage = divide(
        stats.distanceQuartersTotal,
        stats.gpsAvailable[3]
    );

    stats.distanceByHalvesAverage = averageArrayWithExtraTime(
        stats.distanceByHalvesTotal,
        stats.gpsAvailable[1],
        stats.playedET[1],
        2
    );
    stats.distanceByThirdsAverage = averageArrayWithExtraTime(
        stats.distanceByThirdsTotal,
        stats.gpsAvailable[2],
        stats.playedET[2],
        3
    );
    stats.distanceByQuartersAverage = averageArrayWithExtraTime(
        stats.distanceByQuartersTotal,
        stats.gpsAvailable[3],
        stats.playedET[3],
        4
    );

    stats.expensesAverage = divide(
        stats.expensesTotal,
        stats.matchesWithEarnings
    );
    stats.feesAverage = divide(stats.feesTotal, stats.matchesWithEarnings);

    let yellowCardPositionsSum = stats.yellowCardPositions.reduce(function (
        acc,
        val
    ) {
        return acc + val;
    },
    0);
    let redCardPositionsSum = stats.redCardPositions.reduce(function (
        acc,
        val
    ) {
        return acc + val;
    },
    0);
    stats.yellowCardPositionsPercentage = averageArray(
        stats.yellowCardPositions,
        yellowCardPositionsSum
    );
    stats.redCardPositionsPercentage = averageArray(
        stats.redCardPositions,
        redCardPositionsSum
    );

    stats.sprintsByHalvesAverage = averageSprintsArray(
        stats.sprintsByHalvesTotal,
        stats.gpsAvailable[1],
        stats.playedET[1],
        2
    );
    stats.sprintsByThirdsAverage = averageSprintsArray(
        stats.sprintsByThirdsTotal,
        stats.gpsAvailable[2],
        stats.playedET[2],
        3
    );
    stats.sprintsByQuartersAverage = averageSprintsArray(
        stats.sprintsByQuartersTotal,
        stats.gpsAvailable[3],
        stats.playedET[3],
        4
    );

    stats.sprintsByHalvesDistanceAverage = averageSprintsArray(
        stats.sprintsByHalvesDistanceTotal,
        stats.gpsAvailable[1],
        stats.playedET[1],
        2
    );
    stats.sprintsByThirdsDistanceAverage = averageSprintsArray(
        stats.sprintsByThirdsDistanceAverage,
        stats.gpsAvailable[2],
        stats.playedET[2],
        3
    );
    stats.sprintsByQuartersDistanceAverage = averageSprintsArray(
        stats.sprintsByQuartersDistanceAverage,
        stats.gpsAvailable[3],
        stats.playedET[3],
        4
    );

    stats.sprintsTotalAverage = averageArray(
        stats.sprintsTotal,
        stats.gpsAvailable[0]
    );
    stats.sprintsDistanceTotalAverage = averageArray(
        stats.sprintsDistanceTotal,
        stats.gpsAvailable[0]
    );

    stats.heartRateZoneDurationAverage = averageArray(
        stats.heartRateZoneDuration,
        stats.heartRateAvailable[0]
    );

    stats.injuryTimeAverage = divide(stats.injuryTimeTotal, stats.matchesTotal);
    stats.injuryTimeHalvesAverage = divide(
        stats.injuryTimeHalvesTotal,
        stats.matchesHalves
    );
    stats.injuryTimeThirdsAverage = divide(
        stats.injuryTimeThirdsTotal,
        stats.matchesThirds
    );
    stats.injuryTimeQuartersAverage = divide(
        stats.injuryTimeQuartersTotal,
        stats.matchesQuarters
    );

    stats.injuryTimeByHalvesAverage = averageArrayWithExtraTime(
        stats.injuryTimeByHalvesTotal,
        stats.matchesHalves,
        stats.playedET[1],
        2
    );
    stats.injuryTimeByThirdsAverage = averageArrayWithExtraTime(
        stats.injuryTimeByThirdsTotal,
        stats.matchesThirds,
        stats.playedET[2],
        3
    );
    stats.injuryTimeByQuartersAverage = averageArrayWithExtraTime(
        stats.injuryTimeByQuartersTotal,
        stats.matchesQuarters,
        stats.playedET[3],
        4
    );

    return stats;
}
