import {getPlatforms} from "@ionic/core";
import {UserProfile} from "../models/userProfile";
import request from "./request";
import * as Sentry from "@sentry/react";

/**
 * Posts feedback, along with platform info. Returns a promise that resolves without
 * hitting the actual API for any environment that isn't production.
 */
export async function sendFeedback(
    profile: UserProfile,
    comments?: string,
    stars?: number
): Promise<void> {
    // We don't want to hit real API if it's not a prod build
    if (process.env.NODE_ENV !== "production") {
        return Promise.resolve();
    }

    try {
        await request.post("/feedback", {
            name: `${profile.firstName} ${profile.lastName}`,
            username: profile.username,
            comments,
            stars,
            platform: getPlatforms().join(","),
        });
    } catch (err) {
        Sentry.captureMessage(
            `Send feedback failed with err: ${JSON.stringify(err)}`
        );
        console.log("Send feedback failed with err: ", err);
        throw err;
    }
}
