import { FC } from "react";
import { getLanguageCode } from "../utils/localeUtils";
import playStoreEN from "../assets/images/play-store/en.png";
import appStoreEN from "../assets/images/app-store/en.png";
import appStoreDA from "../assets/images/app-store/da.png";
import appStoreDE from "../assets/images/app-store/de.png";
import appStoreES from "../assets/images/app-store/es.png";
import appStoreFR from "../assets/images/app-store/fr.png";
import appStoreJA from "../assets/images/app-store/ja.png";
import appStoreNB from "../assets/images/app-store/nb.png";
import appStoreNL from "../assets/images/app-store/nl.png";
import appStorePT from "../assets/images/app-store/pt.png";
import playStoreDA from "../assets/images/play-store/da.png";
import playStoreDE from "../assets/images/play-store/de.png";
import playStoreES from "../assets/images/play-store/es.png";
import playStoreFR from "../assets/images/play-store/fr.png";
import playStoreJA from "../assets/images/play-store/ja.png";
import playStoreNB from "../assets/images/play-store/nb.png";
import playStoreNL from "../assets/images/play-store/nl.png";
import playStorePT from "../assets/images/play-store/pt.png";

export const AppStoreLogo: FC = () => {
    const language = getLanguageCode();

    let storeLogo = playStoreEN;
    switch (language) {
        case "en":
            storeLogo = appStoreEN;
            break;
        case "da":
            storeLogo = appStoreDA;
            break;
        case "de":
            storeLogo = appStoreDE;
            break;
        case "es":
            storeLogo = appStoreES;
            break;
        case "fr":
            storeLogo = appStoreFR;
            break;
        case "ja":
            storeLogo = appStoreJA;
            break;
        case "nb":
            storeLogo = appStoreNB;
            break;
        case "nl":
            storeLogo = appStoreNL;
            break;
        case "pt":
            storeLogo = appStorePT;
            break;
    }
    return <img style={{ height: 35 }} src={storeLogo} />;
};
export const PlayStoreLogo: FC = () => {
    const language = getLanguageCode();

    let storeLogo = playStoreEN;
    switch (language) {
        case "en":
            storeLogo = playStoreEN;
            break;
        case "da":
            storeLogo = playStoreDA;
            break;
        case "de":
            storeLogo = playStoreDE;
            break;
        case "es":
            storeLogo = playStoreES;
            break;
        case "fr":
            storeLogo = playStoreFR;
            break;
        case "ja":
            storeLogo = playStoreJA;
            break;
        case "nb":
            storeLogo = playStoreNB;
            break;
        case "nl":
            storeLogo = playStoreNL;
            break;
        case "pt":
            storeLogo = playStorePT;
            break;
    }

    return <img src={storeLogo} style={{ height: 35 }}></img>;
};
