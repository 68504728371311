import { FC } from "react";
import { useController } from "react-hook-form";
import { IonSegment as OrigIonSegment } from "@ionic/react";

export const IonFormSegment: FC<{ name: string; value: any }> = ({
    name,
    value: defaultValue,
    children,
}) => {
    const {
        field: { onChange, ref, value },
    } = useController({
        name,
        defaultValue,
    });
    return (
        <OrigIonSegment
            className="segment"
            ref={ref}
            onIonChange={onChange}
            value={value}
        >
            {children}
        </OrigIonSegment>
    );
};
