import { IonCard, IonCol, IonItem, IonText } from "@ionic/react";
import { FC, useEffect, useState } from "react";
import halfPitchImage from "../../../../assets/images/half-pitch.svg";
import {
    dummyPositionsNumber,
    dummyPositionsString,
} from "../../../../services/dummyDataService";
import "./cardPositions.css";

interface CardPositionsProps {
    title: string;
    positionsTotals: (number | string)[];
    positionsPercentages: number[];
    testIdPrefix?: string;
    useDummyData?: boolean;
}

export const CardPositions: FC<CardPositionsProps> = ({
    title,
    positionsTotals,
    positionsPercentages,
    testIdPrefix,
    useDummyData,
}) => {
    if (useDummyData) {
        positionsTotals = dummyPositionsString;
        positionsPercentages = dummyPositionsNumber;
    }

    const [values, setValues] = useState<(number | string)[]>([]);
    const [asPercentage, setAsPercentage] = useState(false);

    useEffect(() => {
        setValues(displayOrder(positionsTotals));
    }, [positionsTotals]);

    const displayOrder = (values: (number | string)[]) => {
        return [
            values[2],
            values[5],
            values[8],
            values[1],
            values[4],
            values[7],
            values[0],
            values[3],
            values[6],
        ];
    };

    const toggleTotalAndPercent = () => {
        if (!asPercentage) {
            setValues(
                displayOrder(
                    positionsPercentages.map(
                        (value) => +(value * 100).toFixed(1)
                    )
                )
            );
        } else {
            setValues(displayOrder(positionsTotals));
        }
        setAsPercentage(!asPercentage);
    };

    return (
        <IonCard>
            <IonItem lines="none">
                <IonCol>
                    <IonText
                        className="text-small"
                        data-testid={`${testIdPrefix}-card-positions-title`}
                    >
                        {title}
                    </IonText>
                </IonCol>
            </IonItem>

            <div
                className="half-pitch-container"
                onClick={toggleTotalAndPercent}
                data-testid={`${testIdPrefix}-card-positions-pitch`}
            >
                {values.map((value, i) => (
                    <span
                        className={`half-pitch-value half-pitch-value-${i}`}
                        key={i}
                    >
                        {value}
                        {asPercentage && "%"}
                    </span>
                ))}
                <img src={halfPitchImage} className="half-pitch" />
            </div>
        </IonCard>
    );
};
