import { ChartOptions, ChartType, TooltipItem } from "chart.js";
import moment from "moment";
import { MatchPhone } from "refsix-js-models";

export function heartRateLineBarOptions(
    allResults: MatchPhone[],
    yGraphLabel: string
): ChartOptions<"line"> {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    color: "white",
                },
            },
            y: {
                title: {
                    display: true,
                    text: yGraphLabel,
                    color: "white",
                },
                ticks: {
                    color: "white",
                },
                grid: { color: "#2d2d2d" },
            },
        },
        interaction: {
            mode: "index",
        },
        plugins: {
            legend: {
                position: "bottom" as const,
                display: true,
                labels: {
                    boxWidth: 12,
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem: TooltipItem<ChartType>) {
                        return tooltipItem.parsed.y.toString();
                    },
                    title: function (tooltipItem: TooltipItem<ChartType>[]) {
                        let title = "DD MM";
                        if (
                            allResults &&
                            allResults[tooltipItem[0].dataIndex] &&
                            allResults[tooltipItem[0].dataIndex].date
                        ) {
                            title = moment(
                                allResults[tooltipItem[0].dataIndex].date
                            ).format("DD MMM.");
                        }
                        return title;
                    },
                },
            },
        },
    };
}
