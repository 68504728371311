import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonText,
    IonTitle,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import ProBadge from "../assets/images/pro-badge.png";
import PlusBadge from "../assets/images/upgrade-plus-and-pro/plus-badge.png";
import "./componentsStyle.css";
import { FC } from "react";
import { routes } from "../route/constants";
import { useTranslation } from "react-i18next";
import {
    chevronBack,
    createOutline,
    ellipsisHorizontalOutline,
    gift,
} from "ionicons/icons";
import "./HeaderComponent.css";
import {
    getNumberOfDaysLeftOnFreeTrial,
    useHasActiveFreeTrial,
} from "../services/subscriptionService";
import {
    trackEvent,
    trackProTeaser,
} from "../services/analytics/analyticsService";
import { isAndroid, isIos } from "../services/platformDetection";
import { URLS } from "../constants/urls";
import { useSubscription } from "./hooks";
import { isFeatureAvailableCurrentLanguage } from "../utils/FeatureToggle";
import { getExperimentVariant } from "../utils/Experiments";
import { SessionExpiredBanner } from "./SessionExpiredBanner";
import { useEnrolledInMgm, useIsMgmAvailableInCountry } from "./hooks/mgmHooks";
import { IntegrationsBanner } from "./IntegrationsBanner";

interface HeaderInterface {
    showFilterIcon?: boolean;
    title?: string;
    showProBadge?: boolean;
    showPlusBadge?: boolean;
    showBackButton?: boolean;
    showLeftSideBtn?: boolean;
    onLeftBtnClicked?: () => void;
    leftBtnDisabled?: boolean;
    leftSideBtnTxt?: string;
    defaultHref?: string;
    headerEndText?: string;
    onClickEndButton?: (...args: any[]) => void;
    endButtonDisabled?: boolean;
    titleTestId?: string;
    endBtnIconName?: string;
    endBtnTestId?: string;
    showPopOverOptions?: boolean;
    backButtonTestId?: string;
    integration?: boolean;
    customStartButton?: boolean;
    customStartButtonOnClick?: (...args: any[]) => void;
    onCalendarListViewClick?: (...args: any[]) => void;
    showCalendarListViewButton?: boolean;
    calendarListViewIcon?: string;
    showMgmButton?: boolean;

    setShowPopover?({}): void;
}

const HeaderComponent: FC<HeaderInterface> = (props) => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const subscriptions = useSubscription();
    const optionsMenuExperimentVariant = getExperimentVariant("optionsMenu1");
    const enrolledInMgm = useEnrolledInMgm();
    const hasReverseFreeTrial = useHasActiveFreeTrial();
    const mgmAvailableInCountry = useIsMgmAvailableInCountry();

    const renderOptionsMenuBasedOnVariant = () => {
        if (optionsMenuExperimentVariant === 2) {
            return <IonIcon icon={createOutline} size="large" />;
        } else if (optionsMenuExperimentVariant === 3) {
            return <IonIcon icon={ellipsisHorizontalOutline} size="large" />;
        } else if (optionsMenuExperimentVariant === 4) {
            return t("general.edit");
        }

        return t("overview.options");
    };

    const getVariantShown = () => {
        if (optionsMenuExperimentVariant === 2) {
            return "CreateIcon";
        } else if (optionsMenuExperimentVariant === 3) {
            return "EllipsisIcon";
        } else if (optionsMenuExperimentVariant === 4) {
            return "EditText";
        }

        return "textOptions";
    };

    return (
        <IonHeader>
            {/* Mode ios will keep the title centered on iOS and Android */}
            <IonToolbar mode="ios">
                <IonTitle data-testid={props.titleTestId}>
                    {props.title}
                </IonTitle>
                <IonButtons slot="start">
                    {props.showLeftSideBtn && (
                        <IonButton
                            color="primary"
                            className="button-header"
                            onClick={props.onLeftBtnClicked}
                            disabled={props.leftBtnDisabled}
                            data-testid="headerLeftSideButton"
                        >
                            {props.leftSideBtnTxt}
                        </IonButton>
                    )}
                    {props.showBackButton && (
                        <IonBackButton
                            text={t("help.OnboardingVideo.controls.back")}
                            defaultHref={props.defaultHref}
                            data-testid={props.backButtonTestId}
                        />
                    )}
                    {props.customStartButton && (
                        <IonButton
                            onClick={props.customStartButtonOnClick}
                            data-testid={
                                props.backButtonTestId || "customStartButton"
                            }
                        >
                            <IonIcon size="large" icon={chevronBack} />
                            <IonText>
                                {t("help.OnboardingVideo.controls.back")}
                            </IonText>
                        </IonButton>
                    )}
                    {props.showProBadge &&
                        subscriptions &&
                        (isFeatureAvailableCurrentLanguage(
                            "freeTrialCountdown"
                        ) && hasReverseFreeTrial ? (
                            <div
                                data-testid="free-trial-countdown"
                                className="free-trial-button"
                                onClick={() => {
                                    if (isIos() || isAndroid()) {
                                        route.push(routes.upgrade + "/pro");
                                        trackProTeaser(
                                            "FreeTrialCountdown",
                                            "pro"
                                        );
                                    } else {
                                        window.open(
                                            URLS.REFSIX_ACCOUNT +
                                                "?utm_source=refsix&utm_medium=app&utm_campaign=in-app-mobile-web&utm_id=in-app-mobile-web",
                                            "_system"
                                        );
                                    }
                                }}
                            >
                                <img src={ProBadge} alt="" height={30} />

                                <IonText
                                    style={{ marginLeft: 5 }}
                                >{`${getNumberOfDaysLeftOnFreeTrial(
                                    subscriptions.subscriptions
                                )} ${t("upgrade.upgrade.daysLeft")}`}</IonText>
                            </div>
                        ) : (
                            <img
                                alt=""
                                src={ProBadge}
                                data-testid="headerProBadge"
                                width={25}
                                height={30}
                                style={{ marginLeft: 10 }}
                            />
                        ))}
                    {props.showPlusBadge && (
                        <img
                            alt=""
                            src={PlusBadge}
                            data-testid="headerPlusBadge"
                            width={30}
                            style={{ marginLeft: 10 }}
                            color="primary"
                        />
                    )}
                    {props.showMgmButton &&
                        mgmAvailableInCountry &&
                        enrolledInMgm &&
                        !hasReverseFreeTrial && (
                            <IonButton
                                color="primary"
                                onClick={() => {
                                    route.push(routes.referFriend);
                                }}
                                size="large"
                            >
                                <IonIcon icon={gift} />
                            </IonButton>
                        )}
                </IonButtons>
                <IonButtons slot="end" data-testid="options-menu">
                    {props.showPopOverOptions && (
                        <IonText
                            slot="end"
                            color="primary"
                            onClick={(e) => {
                                props.setShowPopover &&
                                    props.setShowPopover({
                                        open: true,
                                        event: e.nativeEvent,
                                    });
                                trackEvent("OptionsButtonClicked", {
                                    variant: getVariantShown(),
                                });
                            }}
                            data-testid="optionsButton"
                        >
                            {renderOptionsMenuBasedOnVariant()}
                        </IonText>
                    )}
                    <IonButton
                        hidden={!props.showCalendarListViewButton}
                        type="submit"
                        color="primary"
                        className="button-header"
                        data-testid="button-calendar-list-view"
                        onClick={props.onCalendarListViewClick}
                    >
                        <IonIcon
                            className="header-end-button"
                            icon={props.calendarListViewIcon}
                            color="primary"
                        />
                    </IonButton>
                    <IonButton
                        type="submit"
                        color="primary"
                        className="button-header"
                        data-testid={props.endBtnTestId}
                        onClick={props.onClickEndButton}
                        disabled={props.endButtonDisabled}
                    >
                        {props.endBtnIconName && (
                            <IonIcon
                                className="header-end-button"
                                icon={props.endBtnIconName}
                                color="primary"
                            />
                        )}
                        {props.headerEndText}
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <SessionExpiredBanner>
                <IntegrationsBanner />
            </SessionExpiredBanner>
        </IonHeader>
    );
};

HeaderComponent.defaultProps = {
    showFilterIcon: false,
    title: "",
    showProBadge: false,
    showBackButton: false,
    defaultHref: routes.login,
    headerEndText: undefined,
    onClickEndButton: undefined,
};

export default HeaderComponent;
