import {
    IonButton,
    IonCard,
    IonContent,
    IonPage,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { SinBinSystem, Template as TemplateModel } from "refsix-js-models";
import { HeaderComponent, MatchFormatComponent } from "../../../../components";
import { MatchFormatMode } from "../../../../components/MatchFormatComponent";
import { RefsixState } from "../../../../redux/models/refsixState";
import { routes } from "../../../../route/constants";
import {
    deleteTemplate,
    getDefaultTemplates,
    saveTemplate,
} from "../../../../services/templateService";
import type { PartialDeep } from "type-fest";
import { assign, omit, pick } from "lodash";
import {
    transformInIntervals,
    transformInPeriods,
} from "../../../../utils/matchConfigUtil";
import { transFormValuesOut } from "../../../../services/matchService";
import * as Sentry from "@sentry/react";
import {
    trackEvent,
    trackUserActionsCheckList,
} from "../../../../services/analytics/analyticsService";

export const Template: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id?: string }>();
    const router = useIonRouter();
    const [alert] = useIonAlert();

    const templates = useSelector(
        (state: RefsixState) =>
            state.templates.templates || getDefaultTemplates()
    );

    const editing = !!id;

    // If we're editing, find the template from its id, else create empty template
    const [template, setTemplate] = useState<PartialDeep<TemplateModel>>(
        editing
            ? (templates.templates.find(
                  (template) => template._id === id
              ) as TemplateModel)
            : { config: {} }
    );

    const formMethods = useForm({
        mode: "all",
        defaultValues: template.config,
    });

    const { handleSubmit, reset } = formMethods;

    useEffect(() => {
        reset((formValues) => ({
            ...formValues,
            periodsNo: formValues.periodsNo || "2",
            withGoalScorers: formValues.withGoalScorers || false,
            penaltiesAvailable: formValues.penaltiesAvailable || false,
            extraTimeAvailable: formValues.extraTimeAvailable || false,
            sinBinSystem: formValues.sinBinSystem || SinBinSystem.none,
            misconductCodeId: formValues.misconductCodeId || "england",
            periods: formValues.timings
                ? transformInPeriods(formValues.timings)
                : [undefined, undefined],
            interval: formValues.timings
                ? transformInIntervals(formValues.timings)
                : [undefined],
            name: template.name || undefined,
            _id: template._id || undefined,
        }));
    }, []);

    const handleSaveTemplate = async (data: any) => {
        console.log(data);
        const periods = transFormValuesOut(data.periods, "period");
        const intervals = transFormValuesOut(data.interval, "interval");

        // Joining all the properties in timings
        data.timings = assign(data.timings, periods, intervals);

        // Removing unused properties
        data = omit(data, ["periods", "interval", "config"]);
        data.config = omit(data, "name");
        data = pick(data, ["config", "name", "_id"]);

        data.config.teamSize = Number(data.config.teamSize);
        data.config.subsNo = Number(data.config.subsNo);

        if (data.config.timings.extraTimeHalfLength) {
            // Fix string to number
            data.config.timings.extraTimeHalfLength = Number(
                data.config.timings.extraTimeHalfLength
            );
        }

        if (data.config.timings.sinBinTimerLength) {
            // Fix string to number
            data.config.timings.sinBinTimerLength = Number(
                data.config.timings.sinBinTimerLength
            );
        }

        try {
            // safe to assert template as we're unable to submit if template is not valid
            await saveTemplate(data as TemplateModel, templates, editing);
            trackEvent("templateCreated", data.config);
            trackUserActionsCheckList("templateCreated");
            router.goBack();
        } catch (err) {
            // TODO: show error popup
            Sentry.captureMessage(
                `Failed to save template: ${JSON.stringify(err)}`
            );
        }
    };

    const handleDeleteTemplate = async () => {
        try {
            // safe to assert id as we can't call this if it's is undefined
            await deleteTemplate(id as string, templates);
            router.goBack();
        } catch (err) {
            // TODO: show error popup
            Sentry.captureMessage(
                `Failed to delete template: ${JSON.stringify(err)}`
            );
        }
    };

    const presentDeleteAlert = () =>
        alert({
            header: t("general.confirmDeletion.title"),
            message: t("general.confirmDeletion.description"),
            buttons: [
                t("general.cancel"),
                {
                    text: t("general.delete"),
                    handler: handleDeleteTemplate,
                    cssClass: "delete-confirm-button",
                },
            ],
        });

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMoreSettingsTemplates}
                title={
                    editing
                        ? t("settings.TemplateEdit.editTitle")
                        : t("settings.TemplateEdit.addTitle")
                }
                headerEndText={t("general.save")}
                onClickEndButton={handleSubmit(handleSaveTemplate)}
                titleTestId="add-edit-template-page"
                endBtnTestId="save-template-button"
            />
            <IonContent>
                <FormProvider {...formMethods}>
                    <form>
                        <IonCard>
                            <MatchFormatComponent
                                mode={MatchFormatMode.Template}
                            />
                            {editing && (
                                <div
                                    className="align-right"
                                    hidden={templates.templates.length <= 1}
                                >
                                    <IonButton
                                        type="button"
                                        color="primary"
                                        className="align-right"
                                        onClick={presentDeleteAlert}
                                        data-testid="delete-button"
                                    >
                                        {t("general.delete")}
                                    </IonButton>
                                </div>
                            )}
                        </IonCard>
                    </form>
                </FormProvider>
            </IonContent>
        </IonPage>
    );
};
