import { FC, useState } from "react";
import { IonCard, IonContent, IonPage } from "@ionic/react";
import { t } from "i18next";
import { TimerType } from "refsix-js-models";
import { HeaderComponent } from "../../../components";
import "./timerOptions.css";
import { ProTeaser } from "../../../components/ProTeaser";
import { routes } from "../../../route/constants";
import {
    hasFeatureAccess,
    isUserPlusEligible,
} from "../../../services/subscriptionService";
import { TimerOptionsComponent } from "../../../components/TimerOptionsComponent";

export interface TimerConfigOptions {
    timer1: TimerType;
    timer2: TimerType;
    timer3?: TimerType;
}

const TimerOptions: FC = () => {
    const [isValidLayout, setIsValidLayout] = useState<boolean>(true);
    const hasTimerAccess = hasFeatureAccess("timers");

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMoreSettings}
                title={t("settings.TimerSettings.title")}
                titleTestId="timer-options-page-header"
            />
            <IonContent>
                <IonCard className="ion-card-transparent">
                    <ProTeaser
                        showTeaser={!hasTimerAccess}
                        teaserName="timers-options"
                        isRefSixEligible={isUserPlusEligible()}
                    >
                        <TimerOptionsComponent
                            validationListener={(valid) =>
                                setIsValidLayout(valid)
                            }
                        />
                    </ProTeaser>
                </IonCard>
                <IonCard className="ion-card-transparent">
                    {!isValidLayout && (
                        <p
                            className="error-message"
                            data-testid="duplicate-timer-error"
                        >
                            {t("settings.TimerSettings.failure.template")}
                        </p>
                    )}
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default TimerOptions;
