import axios, { AxiosError, AxiosResponse } from "axios";
import { apiDomain } from "./domains";
import { store } from "../redux/store";
import { LoginSession } from "../redux/models/loginSession";
import * as _ from "lodash";
import { setSessionExpired } from "../redux/actions/auth";

export default class request {
    static customFetch = async <T>({
        url = "",
        ...options
    }): Promise<AxiosResponse<T>> => {
        try {
            return await axios({
                timeout: 20000,
                url: url,
                ...options,
            }).catch((err) => {
                if (axios.isAxiosError(err)) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            // If the request host contains the refauth url
                            if (
                                process.env.REACT_APP_REFSIX_AUTH_URL &&
                                url.startsWith(
                                    process.env.REACT_APP_REFSIX_AUTH_URL
                                )
                            ) {
                                // Unauthorized
                                store.dispatch(setSessionExpired(true));
                            }
                        }
                    }
                }
                throw err;
            });
        } catch (err) {
            if (axios.isAxiosError(err)) {
                const error = err as AxiosError;
                if (error.response) {
                    // error code out of 2xx range, e.g. wrong matchId or something
                    console.log("Out of 2xx range: ", { error });
                } else if (error.request) {
                    // request made but no response received (e.g. no network)
                    console.log("No response received: ", { error });
                } else {
                    // error'd out when setting up request
                    console.log("Unable to set up request: ", { error });
                }
            } else {
                console.log("Something real bad went down. Error: ", { err });
            }
            throw err;
        }
    };

    static headers = async (path: string) => {
        const session: LoginSession | undefined = store.getState().auth.session;
        const token = session
            ? session.token + ":" + session.password
            : undefined;
        return {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token ? "Bearer " + token : undefined,
            // If using ngrok free, add the following line to skip the browser warning
            // "ngrok-skip-browser-warning": "true"
        };
    };

    static post = async <Response = null, Request = object>(
        path: string,
        data: Request,
        options?: any
    ) => {
        const headers = await request.headers(path);
        console.log("apiDomain", apiDomain);
        return request.customFetch<Response>({
            method: "post",
            url: apiDomain + path,
            headers,
            data,
            ...options,
        });
    };

    static put = async <T>(path: string, data: object) => {
        const headers = await request.headers(path);
        return request.customFetch<T>({
            method: "put",
            url: apiDomain + path,
            headers,
            data,
        });
    };

    static get = async <T>(path: string, options?: any) => {
        const headers = await request.headers(path);
        return request.customFetch<T>(
            _.defaultsDeep(
                {
                    method: "get",
                    url: apiDomain + path,
                    headers,
                },
                options
            )
        );
    };

    static delete = async <T>(path: string) => {
        const headers = await request.headers(path);
        return request.customFetch<T>({
            method: "delete",
            url: apiDomain + path,
            headers,
        });
    };
}
