import React, { useEffect, useRef, useState } from "react";
import { R6IonInput } from "./r6ionic/R6IonInput";
import { IonItem } from "@ionic/react";
import { RegisterOptions, useWatch } from "react-hook-form";
import { errorProps } from "../services/errorService";
import { UseListRenderer, UseListSearch } from "./hooks/autocomplete/types";
import { useIsLanguageJapanese } from "./hooks";
import { useSafeKeyboard } from "./hooks/useSafeKeyboard";

interface AutocompleteInputProps {
    label: string;
    name: string;
    errors: any;
    ready: boolean;
    setValue: (name: any, value: any, options?: any) => void;
    testId: string;
    searchFn: UseListSearch<any>;
    renderFn: UseListRenderer<any>;
    rules?: RegisterOptions<any>;
    onFocus?: () => void;
    onBlur?: () => void;
    placeholder?: string;
    useFlex?: boolean;
    disabled?: boolean;
}

function AutocompleteInput({
    label,
    name,
    errors,
    ready,
    setValue,
    testId,
    searchFn,
    renderFn,
    onFocus,
    onBlur,
    rules,
    placeholder,
    useFlex,
    disabled = false,
}: AutocompleteInputProps) {
    const MIN_SEARCH_CHARS = 3;
    const [isFocussed, setIsFocussed] = useState<boolean>(false);
    const [suggestions, setSuggestions] = React.useState<any>(null);
    const value = useWatch({ name: name });
    const inputRef = useRef<HTMLIonItemElement>(null);
    const { isOpen: isKeyboardVisible } = useSafeKeyboard();

    useEffect(() => {
        if (!ready) {
            return;
        }

        const res =
            value && value.length >= MIN_SEARCH_CHARS
                ? searchFn(value)
                : searchFn("");
        setSuggestions(res);
    }, [searchFn, ready, value]);

    useEffect(() => {
        if (isFocussed && isKeyboardVisible && inputRef.current) {
            console.log("Scrolling into view");
            // setTimeout(() => {
            inputRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
            });
            // }, 100);
        }
    }, [isFocussed, isKeyboardVisible]);

    return (
        <>
            <IonItem
                ref={inputRef}
                data-testid={testId}
                {...errorProps(name, errors)}
                lines="full"
                onClick={(e) => {
                    // scroll segment into center of the view on click
                    if (e.target && !disabled) {
                        const el = e.target as HTMLElement;
                        el.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "start",
                        });
                    }
                }}
            >
                <R6IonInput
                    disabled={disabled}
                    name={name}
                    rules={rules}
                    onFocus={() => {
                        setIsFocussed(true);
                        onFocus && onFocus();
                    }}
                    onBlur={() => {
                        // need a delay to ensure setValue is called before element is hidden
                        setTimeout(() => {
                            setIsFocussed(false);
                            onBlur && onBlur();
                        }, 300);
                    }}
                    placeholder={placeholder}
                    debounce={500}
                    className={"align-text-end"}
                    label={label}
                />
            </IonItem>
            <div
                className={
                    !suggestions ||
                    !isFocussed ||
                    (value &&
                        value.length >= 3 &&
                        Object.keys(suggestions).length === 0)
                        ? "autocomplete"
                        : useFlex
                        ? "autocomplete open flex"
                        : "autocomplete open"
                }
            >
                {suggestions &&
                    renderFn(suggestions, (item) => {
                        setValue(name, item, { shouldValidate: true });
                    })}
            </div>
        </>
    );
}

function SimpleInput({
    label,
    name,
    errors,
    testId,
    rules,
}: AutocompleteInputProps) {
    return (
        <IonItem
            data-testid={testId}
            {...errorProps(name, errors)}
            lines="full"
            onClick={(e) => {
                // scroll segment into center of the view on click
                if (e.target) {
                    const el = e.target as HTMLElement;
                    el.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "start",
                    });
                }
            }}
        >
            <R6IonInput
                name={name}
                rules={rules}
                debounce={500}
                className={"align-text-end"}
                label={label}
            />
        </IonItem>
    );
}

const AutocompleteSwitch = (props: AutocompleteInputProps) => {
    const isLocalJP = useIsLanguageJapanese();
    if (isLocalJP) {
        return <SimpleInput {...props} />;
    }
    return <AutocompleteInput {...props} />;
};
export default AutocompleteSwitch;
