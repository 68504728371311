import { MatchData } from "refsix-js-models";
import { trackEvent } from "./analytics/analyticsService";
import { watchMatchIdToMatchId } from "refsix-core";
import { store } from "../redux/store";
import { getWatchSubscriptionStatus } from "./watch/messages/prepareBaseResponse";

const WATCH_PLATFORM = "phoneWatch";

const UPCOMING_MATCHES_FILENAME: string = "r6watch_upcomingMatches.json";
const FINISHED_MATCHES_FILENAME: string = "r6watch_finishedMatches.json";
const CURRENT_MATCH_ID_FILENAME: string = "r6watch_currentMatchId.txt";
const CONFIGURATION_FILENAME: string = "r6watch_configuration.json"; // user's settings, templates misconduct codes..
const DEVICE_SETTINGS_FILENAME: string = "r6watch_deviceSettings.json";
const SUBSCRIPTIONS_FILENAME: string = "r6watch_subscriptions.json";
// the watch stores each match and gps seperately
const MATCH_FILENAME_PREFIX: string = "r6watch_matchData-";
const MATCH_FILENAME_SUFFIX: string = ".json";
const GPS_FILENAME_PREFIX = "r6watch_gps-";
const GPS_FILENAME_SUFFIX = ".csv";

export const getMatchFilename = (matchId: string) =>
    MATCH_FILENAME_PREFIX + matchId + MATCH_FILENAME_SUFFIX;

export const getGPSFilename = (matchId: string) =>
    GPS_FILENAME_PREFIX + matchId + GPS_FILENAME_SUFFIX;

export const loadMatchFromLocalStorage = () => {
    const currentMatchId = localStorage.getItem(CURRENT_MATCH_ID_FILENAME);
    if (!currentMatchId) {
        return null;
    }
    const matchData = localStorage.getItem(getMatchFilename(currentMatchId));
    if (matchData) {
        return JSON.parse(matchData);
    }
    return null;
};

export const getGPS = (): string => {
    const currentMatchId = localStorage.getItem(CURRENT_MATCH_ID_FILENAME);
    if (!currentMatchId) {
        return "";
    }
    return localStorage.getItem(getGPSFilename(currentMatchId)) || "";
};

export const getWatchMatchId = () => {
    const watchData = loadMatchFromLocalStorage();
    if (watchData && watchData.match) {
        const watchId = watchData.match.matchId;
        return watchMatchIdToMatchId(watchId);
    }
};

export const getWatchConfig = () => {
    const watchData = loadMatchFromLocalStorage();
    if (watchData) {
        return watchData.config;
    }
};

export const putMatchInLocalStorage: (f: MatchData) => void = function (
    matchData
) {
    matchData.config = {
        ...matchData.config,
        disableWebPairing: true,
    };
    const matchId = matchData.match.matchId;
    localStorage.setItem(
        UPCOMING_MATCHES_FILENAME,
        JSON.stringify([matchData])
    );
    localStorage.setItem(getMatchFilename(matchId), JSON.stringify(matchData));
    localStorage.setItem(CURRENT_MATCH_ID_FILENAME, matchId);
    localStorage.setItem(getGPSFilename(matchId), "");
    // new watch also requires this
    localStorage.setItem(
        CONFIGURATION_FILENAME,
        JSON.stringify(store.getState().settings)
    );
    localStorage.setItem(
        SUBSCRIPTIONS_FILENAME,
        JSON.stringify(getWatchSubscriptionStatus())
    );
    // TODO subscriptions?

    // TODO do we still event want this stuff?
    trackEvent("sendToWatchSuccess", {
        category: "watch",
        label: "watch",
        platform: WATCH_PLATFORM,
        method: WATCH_PLATFORM,
        fixtureId: matchData.match.matchId.split("_")[0],
    });
};

export const isMatchStarted = () => {
    const matchOnWatch = loadMatchFromLocalStorage();
    return (
        matchOnWatch &&
        matchOnWatch.match &&
        matchOnWatch.match.matchEvents &&
        Object.keys(matchOnWatch.match.matchEvents).length > 0
    );
};

export const clearSavedData = () => {
    // remove all localStorage keys that begin with r6Watch_
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith("r6watch_")) {
            localStorage.removeItem(key);
        }
    }
};
