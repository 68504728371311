import { FC, useEffect, useState } from "react";
import { IonButton, IonIcon, IonText, useIonRouter } from "@ionic/react";
import { routes } from "../../route/constants";
import "./MgmBanner.css";
import { useTranslation } from "react-i18next";
import { formatForCurrency } from "../../utils/CurrencySymbol";
import { getCountryCode, getLocale } from "../../utils/localeUtils";
import { getMgmConfig } from "../../services/mgmService";
import {
    countryToCurrency,
    MGM_FALLBACK_KICKBACK_AMOUNT,
    pickMgmKickbackConfig,
} from "refsix-core";
import { MgmKickbackConfig } from "refsix-js-models";
import { giftOutline } from "ionicons/icons";
import { useProfile } from "../hooks";

export const MgmBanner: FC = () => {
    const route = useIonRouter();
    const enrolledInMgm = false; //useEnrolledInMgm();
    const { t } = useTranslation();
    const profile = useProfile();

    const [kickback, setKickback] = useState<MgmKickbackConfig | undefined>(
        undefined
    );

    const getAmount = () => {
        if (!kickback) {
            return formatForCurrency(
                MGM_FALLBACK_KICKBACK_AMOUNT,
                "USD",
                getLocale()
            );
        }

        return formatForCurrency(
            kickback.amount,
            kickback.currency,
            getLocale()
        );
    };

    useEffect(() => {
        getMgmConfig()
            .then((config) => {
                const country = profile?.country || getCountryCode() || "US";
                const kickback = pickMgmKickbackConfig(
                    config,
                    country,
                    countryToCurrency(country)
                );
                setKickback(kickback);
            })
            .catch((error) => {
                console.error("Error fetching mgm config: ", error);
            });
    }, []);

    if (enrolledInMgm) {
        return null;
    }

    return (
        <div
            className="mgm-banner"
            onClick={() => route.push(routes.referFriend)}
        >
            <div className="mgm-banner-top">
                <div className="mgm-gift-icon">
                    <IonIcon icon={giftOutline} />
                </div>
                <div>
                    <IonText
                        style={{
                            fontSize: 22,
                            marginBottom: 5,
                            fontWeight: "bold",
                        }}
                    >
                        {t("mgm.title")}
                    </IonText>
                    <p style={{ fontSize: 14, margin: 0 }}>
                        {t("mgm.betterWithFriends")}
                    </p>
                </div>
            </div>
            <IonButton expand="block">
                {t("mgm.getMoneyOff", { amount: getAmount() })}
            </IonButton>
        </div>
    );
};
