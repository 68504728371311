import { IonCol, IonItem } from "@ionic/react";

const PrivacyText = () => {
    return (
        <IonItem>
            <IonCol size="12">
                <h5>
                    We are REFSIX Ltd, a company incorporated and registered in
                    England and Wales with company number 10416702 whose
                    registered office is at 51 Half Moon Lane, Worthing, West
                    Sussex.
                </h5>
                <div className="sqs-block-content">
                    <p>1. INTRODUCTION</p>
                    <p>
                        1.1. This policy (the Privacy Policy) sets out the basis
                        on which any personal data we collect from you, or that
                        you provide to us, will be processed by us. Please read
                        the following carefully to understand our views and
                        practices regarding your personal data and how we will
                        treat it. By using the REFSIX Services you are accepting
                        and consenting to the practices described in this
                        Privacy Policy.
                    </p>
                    <p>
                        1.2. For the purpose of the Data Protection Act 1998
                        (the Act), the data controller is REFSIX Limited
                        (REFSIX, we, us), a company registered in England and
                        Wales with registered company number 10416702 and
                        registered address at 51 Half Moon Lane, Worthing, West
                        Sussex, BN13 2EP.
                    </p>
                    <p>2. DEFINITIONS</p>
                    <p>
                        In this Privacy Policy capitalised words and expressions
                        shall have the meanings given to them in the REFSIX{" "}
                        <a href="https://refsix.com/terms">Terms of Use</a>{" "}
                        unless defined below:
                    </p>
                    <p>
                        Company means the business you work for or represent (if
                        applicable).
                    </p>
                    <p>
                        REFSIX Customer means any person authorised by REFSIX to
                        access and use the REFSIX Services.
                    </p>
                    <p>
                        REFSIX Services means the app provided by way of the
                        REFSIX website and/or REFSIX software from time to time.
                    </p>
                    <p>
                        you means each REFSIX Customer and your shall be
                        understood accordingly.
                    </p>
                    <p>3. INFORMATION REFSIX COLLECTS</p>
                    <p>
                        3.1. If you wish to subscribe for access to the REFSIX
                        app you will be required to register. When you register,
                        REFSIX will collect personal information about you and
                        (if applicable) information about your Company (such as
                        the Company name, and your e-mail address). This data
                        allows us to provide a personalised service to you. You
                        consent to us accessing and collecting such personal
                        information about you.
                    </p>
                    <p>
                        3.2. REFSIX will create an account for your subscription
                        based on the information you give us (an Account).
                        REFSIX may also ask that you provide other information
                        to us for the Account. You are under no obligation to
                        provide such information. However, if you choose to
                        withhold requested information, REFSIX may not be able
                        to provide the REFSIX Services to you.
                    </p>
                    <p>4. HOW REFSIX STORES YOUR INFORMATION</p>
                    <p>
                        Information REFSIX collects may be transferred to,
                        stored and processed by its subsidiaries as well as its
                        service providers and other reputable third party
                        organisations, some of which may be situated outside the
                        European Economic Area “EEA”. Where REFSIX does transfer
                        your personal data outside the EEA it will use its best
                        endeavours to put measures in place to ensure that such
                        personal data will be adequately protected to a standard
                        at least as high as under the Act. In addition, if you
                        use the REFSIX Services outside the EEA, your
                        information may be transferred outside the EEA in order
                        to provide you with those Services. By using the REFSIX
                        Services, you agree to the collection, transfer, storage
                        or processing of information about you for the purposes
                        described in this Privacy Policy.
                    </p>
                    <p>5. HOW YOUR INFORMATION IS USED</p>
                    <p>
                        5.1. REFSIX may use the information you provide to us
                        to:
                    </p>
                    <p>
                        5.1.1. monitor, develop and improve the REFSIX Services
                        and your experience;
                    </p>
                    <p>
                        5.1.2. assist in and administer the provision of the
                        REFSIX Services to you;
                    </p>
                    <p>
                        5.1.3. process and deal with any complaints or enquiries
                        made about or by you (or your Company);
                    </p>
                    <p>
                        5.1.4. contact you about a submission you have made to
                        the REFSIX Services; and/or
                    </p>
                    <p>5.1.5. understand customer trends and patterns.</p>
                    <p>6. WHEN REFSIX MAY CONTACT YOU</p>
                    <p>6.1. REFSIX may contact you:</p>
                    <p>
                        6.1.1. in relation to the functioning of any service you
                        have signed up for in order to ensure that REFSIX can
                        deliver the service to you;
                    </p>
                    <p>
                        6.1.2. in relation to any contribution you have made to
                        the REFSIX Services;
                    </p>
                    <p>
                        6.1.3. to invite you to participate in surveys about the
                        REFSIX Services (participation is always voluntary);
                        and/or
                    </p>
                    <p>
                        6.1.4. for marketing purposes as described in the next
                        section.
                    </p>
                    <p>
                        6.2 Local pages will give you information about how
                        REFSIX will contact you in relation to specific
                        services.
                    </p>
                    <p>7. WILL I BE CONTACTED FOR MARKETING PURPOSES?</p>
                    <p>
                        7.1 REFSIX may send you and keep you updated with
                        information by email or telephone (including SMS) about
                        existing and new services, products and special offers
                        from REFSIX.
                    </p>
                    <p>
                        7.2 Where requested by you REFSIX may send you
                        information by email or telephone about related products
                        or services of selected third parties that may be of
                        interest to you. However, you always have the
                        opportunity to remove your consent to such use by
                        sending an email to{" "}
                        <a href="mailto:support@refsix.com">
                            support@refsix.com
                        </a>
                        with a removal request.
                    </p>
                    <p>
                        7.3 You can tell us not to contact you or share your
                        information with a third party either at initial
                        registration for your access to the Account, or later by
                        way of updates or amendments to your personal
                        information. REFSIX may occasionally contact you about
                        products and services REFSIX believes may be of interest
                        to you. These contacts will only come from REFSIX (or
                        its agents) and only if you have indicated that you do
                        not object to these offers.
                    </p>
                    <p>&nbsp;</p>
                </div>
                <div className="sqs-block-content">
                    <p>
                        8. WILL REFSIX SHARE PERSONAL INFORMATION WITH ANYONE
                        ELSE?
                    </p>
                    <p>
                        REFSIX will keep your information confidential except
                        where disclosure is required or permitted by law.
                        Generally, REFSIX will only use your information within
                        our business and that of our subsidiaries or affiliates.
                        However, sometimes REFSIX uses third parties to process
                        your information, and if so REFSIX requires these third
                        parties to comply strictly with its instructions and
                        requires it not to use your personal information for
                        their own business purposes.
                    </p>
                    <p>9. KEEPING OUR RECORDS ACCURATE</p>
                    <p>
                        REFSIX aims to keep its information about you as
                        accurate as possible, but REFSIX depends on you to
                        update your personal information whenever necessary. You
                        can change the details you have supplied to us at any
                        time by sending your name, Company (if applicable) and
                        full details of the change required to{" "}
                        <a href="mailto:support@refsix.com">
                            support@refsix.com
                        </a>
                        .
                    </p>
                    <p>10. YOUR RIGHTS</p>
                    <p>
                        You have the right to request a copy of the personal
                        information REFSIX holds about you and to have any
                        inaccuracies corrected. REFSIX charges £10 for
                        information requests and REFSIX will ask you to prove
                        your identity with two (2) pieces of approved
                        photographic identification. REFSIX will use reasonable
                        efforts to supply, correct or delete personal
                        information about you on our files. Please address
                        requests and questions about this or any other question
                        about this Privacy Policy to us at{" "}
                        <a href="mailto:support@refsix.com">
                            support@refsix.com
                        </a>
                        .
                    </p>
                    <p>11. CHANGES TO THIS PRIVACY POLICY</p>
                    <p>
                        REFSIX reserves the right from time to time to change or
                        update this Privacy Policy. All changes will be
                        published on the REFSIX Services. Each change will
                        become effective on publication and you will be deemed
                        to be aware of and bound by it. you should therefore
                        review this Privacy Policy regularly to ensure that you
                        are up-to-date with the current terms of the Privacy
                        Policy.
                    </p>
                    <p>12. IP ADDRESSES, COOKIES AND WEB BEACONS</p>
                    <p>
                        12.1 REFSIX may collect information about your computer,
                        including where available your computer’s IP address,
                        operating system and browser type, for system
                        administration. This information is limited to
                        statistical data about REFSIX Customers’ browsing
                        actions and patterns, and does not identify any
                        individual.
                    </p>
                    <p>
                        12.2 For the same reason, REFSIX may obtain information
                        about your general internet usage by using a cookie file
                        which is stored on the hard drive of your computer.
                        Cookies contain information that helps us to improve the
                        REFSIX Services and to deliver a better and more
                        personalised service. They enable us to:
                    </p>
                    <p>
                        12.2.1 identify who you are and to access your Account
                        information;
                    </p>
                    <p>12.2.2 estimate our audience size and patterns; and</p>
                    <p>
                        12.2.3 track preferences and to improve and update the
                        REFSIX Services.
                    </p>
                    <p>
                        12.3 You may refuse to accept cookies by activating the
                        setting on your Internet browser which allows you to
                        refuse the setting of cookies. You can also delete
                        cookies that have been installed on your hard drive –
                        consult your software manual for further help. However,
                        if you select the refuse setting, or delete cookies, you
                        may be unable to access certain parts of the REFSIX
                        Services. Unless you have adjusted your browser setting
                        so that it will refuse cookies, our system will issue
                        cookies when you log on to our site.
                    </p>
                    <p>
                        12.4 To learn more about cookies and how to disable them
                        please visit{" "}
                        <a href="https://filmstro.com/www.youronlinechoices.com">
                            www.youronlinechoices.com
                        </a>{" "}
                        and{" "}
                        <a href="https://filmstro.com/www.allaboutcookies.org">
                            www.allaboutcookies.org
                        </a>
                        .
                    </p>
                    <p>13. SECURITY</p>
                    <p>
                        REFSIX has installed security measures to ensure that
                        any personal data entered on the REFSIX Services is
                        protected against loss, misuse or alteration. However,
                        due to the nature of the Internet, REFSIX cannot
                        guarantee the protection of your personal information
                        and REFSIX cannot be responsible for any outcomes
                        resulting from a breach of security when the REFSIX
                        Services are used.
                    </p>
                    <p>
                        14. CONTACTING REFSIX AND CANCELLATION OF REGISTRATION
                    </p>
                    <p>
                        If you have any questions or comments about this Privacy
                        Policy, or would like to cancel your access to the
                        Account, please contact us at{" "}
                        <a href="mailto:support@refsix.com">
                            support@refsix.com
                        </a>
                        .
                    </p>
                </div>
            </IonCol>
        </IonItem>
    );
};

export default PrivacyText;
