import { TimerLayout } from "./config/timerLayout";
import { WatchFace } from "./config/watchFace";
import { Reason } from "./reason";
import { MisconductCodes } from "./misconductCodes";
import { TimerType } from "./config/timerType";

export interface ConfigPhoneDBWrapper {
    readonly _id?: string;
    readonly _rev?: string;
    settings: ConfigPhone;
}
export class ConfigPhone {
    readonly misconductCodes?: Reason[];
    readonly disableWebPairing?: boolean = false; // disable pairing, download matches, upload result

    //     "settings": {
    //         "disableGPS": false,
    readonly disableGPS: boolean;
    //         "playerNumberPicker": true,
    readonly playerNumberPicker: boolean;
    //         "showGoalOptions": true,
    readonly showGoalOptions: boolean;
    //         "hide13": false,
    hide13: boolean;
    //         "recordRefereeRoles": false,
    recordRefereeRoles: boolean;
    //         "layout": {
    //             "main": "countup",
    //             "left": "countdown",
    //             "right": "additional"
    //         },
    readonly layout: TimerLayout;
    //         "recordGoalScorers": false,
    readonly recordGoalScorers: boolean;
    //         "automaticallyBeginHalfTime": true,
    readonly automaticallyBeginHalfTime: boolean;

    //         "elapsedHalfTimerFromZero": true,
    readonly elapsedHalfTimerFromZero: boolean;
    //         "useImperial": false,
    useImperial: boolean;
    //         "use12HourTime": false,
    use12HourTime: boolean;
    //         "watchFace": "classic",
    readonly watchFace: WatchFace;

    //         "misconductCode": "germany",
    misconductCode: string;
    //         "customCodes": [
    customCodes: [MisconductCodes];
    //             {
    //                 "id": "custom",
    //                 "name": "Custom",
    //                 "codes": [
    //                     {
    //                         "code": "TO1",
    //                         "reason": "Yellow",
    //                         "type": "yellow",
    //                         "secondYellow": false,
    //                         "teamOfficial": true
    //                     },
    //                     {
    //                         "code": "TO2",
    //                         "reason": "Red",
    //                         "type": "red",
    //                         "secondYellow": true,
    //                         "teamOfficial": true
    //                     }
    //                 ]
    //             }
    //         ],
    //         "seasonDate": "2018-08-31T23:00:00.000Z",
    seasonDate: string;

    //         "pairedWatch": "tizen",
    pairedWatch?: string; // TODO is this legacy?
    //         "watches": [
    watches: any[];
    //             {
    //                 "watchOS": "tizen",
    //                 "model": "SM-R732",
    //                 "platform": "tizen",
    //                 "datePaired": 1568642616852,
    //                 "sessionId": "XcRqarhKSr-r_mTgTaeDww"
    //             },
    //             {
    //                 "watchOS": "tizen",
    //                 "model": "SM-R760",
    //                 "platform": "tizen",
    //                 "datePaired": 1571414195394,
    //                 "sessionId": "m85c3fKJSa6gO4az_saLzA"
    //             },
    //             {
    //                 "watchOS": "tizen",
    //                 "model": "WebWatch",
    //                 "platform": "browser",
    //                 "datePaired": 1591627379598,
    //                 "sessionId": "3tgde1vzQSuxeyi624qwLQ"
    //             },
    //             {
    //                 "watchOS": "tizen",
    //                 "model": "SM-R760",
    //                 "platform": "tizen",
    //                 "datePaired": 1592311080965,
    //                 "sessionId": "J9l0m64qSJ2AGq3bd6bn0Q"
    //             }
    //         ],
    //         "saveFixturesCalendar": true,
    saveFixturesCalendar: boolean;
    //         "teamPacks": [
    //             "England"
    teamPacks: string[];
    //         ],
    //         "screenAlwaysOn": false
    readonly screenAlwaysOn: boolean | undefined;
    //     }
    // }

    constructor(
        misconductCodes: Reason[],
        disableWebPairing: boolean | undefined,
        disableGPS: boolean,
        playerNumberPicker: boolean,
        showGoalOptions: boolean,
        hide13: boolean,
        recordRefereeRoles: boolean,
        layout: TimerLayout,
        recordGoalScorers: boolean,
        automaticallyBeginHalfTime: boolean,
        elapsedHalfTimerFromZero: boolean,
        useImperial: boolean,
        use12HourTime: boolean,
        watchFace: WatchFace,
        misconductCode: string,
        customCodes: [MisconductCodes],
        seasonDate: string,
        pairedWatch: string,
        watches: any[],
        saveFixturesCalendar: boolean,
        teamPacks: string[],
        screenAlwaysOn: boolean | undefined
    ) {
        this.misconductCodes = misconductCodes;
        this.disableWebPairing = disableWebPairing;
        this.disableGPS = disableGPS;
        this.playerNumberPicker = playerNumberPicker;
        this.showGoalOptions = showGoalOptions;
        this.hide13 = hide13;
        this.recordRefereeRoles = recordRefereeRoles;
        this.layout = layout;
        this.recordGoalScorers = recordGoalScorers;
        this.automaticallyBeginHalfTime = automaticallyBeginHalfTime;
        this.elapsedHalfTimerFromZero = elapsedHalfTimerFromZero;
        this.useImperial = useImperial;
        this.use12HourTime = use12HourTime;
        this.watchFace = watchFace;
        this.misconductCode = misconductCode;
        this.customCodes = customCodes;
        this.seasonDate = seasonDate;
        this.pairedWatch = pairedWatch;
        this.watches = watches;
        this.saveFixturesCalendar = saveFixturesCalendar;
        this.teamPacks = teamPacks;
        this.screenAlwaysOn = screenAlwaysOn;
    }

    static fromJSON(json: any): ConfigPhone {
        let misconductCodes: Reason[] = [];
        if (json.misconductCodes && json.misconductCodes.length) {
            json.misconductCodes.map(function (jsonReason: any): Reason {
                return Reason.fromJSON(jsonReason);
            });
        }

        let settings = new ConfigPhone(
            misconductCodes,
            json.disableWebPairing,
            json.disableGPS,
            json.playerNumberPicker,
            json.showGoalOptions,
            json.hide13,
            json.recordRefereeRoles,
            TimerLayout.fromJSON(json.layout),
            json.recordGoalScorers,
            json.automaticallyBeginHalfTime,
            json.elapsedHalfTimerFromZero,
            json.useImperial,
            json.use12HourTime,
            json.watchFace,
            json.misconductCode,
            [MisconductCodes.fromJSON(json.customCodes)],
            json.seasonDate,
            json.pairedWatch,
            json.watches,
            json.saveFixturesCalendar,
            json.teamPacks,
            json.screenAlwaysOn
        );

        return settings;
    }

    static defaultConfig(): ConfigPhone {
        const seasonDate = new Date(
            new Date().getFullYear() - 1,
            8,
            1
        ).toISOString();
        const layout = new TimerLayout(
            TimerType.countdown,
            undefined,
            TimerType.elapsed,
            TimerType.additional
        );
        return new ConfigPhone(
            [],
            false,
            false,
            false,
            false,
            false,
            false,
            layout,
            false,
            true,
            false,
            false,
            false,
            WatchFace.bigTimers,
            "fifa",
            [MisconductCodes.defaultCodes()],
            seasonDate,
            "",
            [],
            false,
            [],
            true
        );
    }
}
