import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IonText } from "@ionic/react";
import "./reviewSharedStyles.css";
import "./topMatchesReview.css";
import { useMatch } from "../hooks/match";
import { MatchHeader } from "../../pages/addResult/matchHeader";
import Distance from "../DistanceComponent";
import Stat, {
    Content,
    Footer,
    Icon,
    MainHeader,
    MatchResult,
    Row,
    StatsContainer,
} from "./reviewCard";

interface TopMatchesProps {
    highestScoringMatchId?: string;
    mostCardsMatchId?: string;
    highestDistanceMatchId?: string;
    hardestWorkingMatchId?: string;
}

const TopMatchesReview: FC<TopMatchesProps> = ({
    highestScoringMatchId,
    mostCardsMatchId,
    highestDistanceMatchId,
    hardestWorkingMatchId,
}) => {
    const { t } = useTranslation();
    const highestScoringMatch = useMatch(highestScoringMatchId);
    const mostCardsMatch = useMatch(mostCardsMatchId);
    const highestDistanceMatch = useMatch(highestDistanceMatchId);
    const hardestWorkingMatch = useMatch(hardestWorkingMatchId);

    const cardsTotal = mostCardsMatch?.stats
        ? mostCardsMatch?.stats?.redCardTotal +
          mostCardsMatch?.stats?.yellowCardTotal
        : 0;

    const sprintsArray =
        hardestWorkingMatch && hardestWorkingMatch.stats
            ? hardestWorkingMatch.stats.sprintsTotal
            : null;
    const sprintsTotal =
        sprintsArray && sprintsArray.length === 3
            ? sprintsArray[0] + sprintsArray[1] + sprintsArray[2]
            : 0;

    return (
        <div className="top-matches-review review">
            <StatsContainer>
                {highestScoringMatch && (
                    <Stat className={"highest-scoring-header"}>
                        <MainHeader>
                            {t("review.highestScoringGame")}
                            <Icon />
                        </MainHeader>
                        {/*<MatchHeader match={highestScoringMatch} />*/}
                        <MatchResult match={highestScoringMatch} />
                    </Stat>
                )}

                {highestDistanceMatch && (
                    <Stat className={"highest-distance-in-match-header"}>
                        <MainHeader>
                            {t("review.highestDistanceInMatch")}
                            {":"}
                            <span className="top-match-value">
                                &nbsp;
                                <Distance
                                    distanceInMeters={
                                        highestDistanceMatch.stats
                                            ?.distanceTotal || 0
                                    }
                                >
                                    {highestDistanceMatch.stats?.distanceTotal}
                                </Distance>
                            </span>{" "}
                            <Icon />
                        </MainHeader>
                        <MatchResult match={highestDistanceMatch} />
                    </Stat>
                )}

                {hardestWorkingMatch && (
                    <Stat className={"hardest-match-header"}>
                        <MainHeader>
                            {t("review.hardestWorkingGame")}
                            {": "}
                            <span className="top-match-value">
                                &nbsp;
                                {sprintsTotal}{" "}
                                {t("stats.stats.Sprints.sprints")}
                            </span>
                            <Icon />
                        </MainHeader>
                        <MatchResult match={hardestWorkingMatch} />
                    </Stat>
                )}

                {mostCardsMatch && (
                    <Stat className={"most-cards-header"}>
                        <MainHeader>
                            {t("review.mostCardsInGame")}
                            {": "}
                            <span className="top-match-value">
                                &nbsp;
                                {cardsTotal}
                            </span>
                            <Icon />
                        </MainHeader>
                        <MatchResult match={mostCardsMatch} />
                    </Stat>
                )}
            </StatsContainer>
        </div>
    );
};

export default TopMatchesReview;
