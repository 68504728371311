import { buildGPX, GarminBuilder } from "gpx-builder";
import GpsProcessingService from "./GpsProcessingService";
import HeartRateProcessingService from "./HeartRateProcessingService";

const Point = GarminBuilder.MODELS.Point;

export class GPXConversion {
    lastHrUsed: number = 0;
    gpsProcessingService = new GpsProcessingService();
    heartRateProcessingService = new HeartRateProcessingService();

    getClosestHrPoint(timestamp, hrData) {
        var minDifference = Infinity;
        var closestHrPoint;
        if (timestamp && hrData) {
            for (var i = this.lastHrUsed; i < hrData.length; i++) {
                if (hrData[i]) {
                    var timeDifference = Math.abs(
                        timestamp - hrData[i].timestamp
                    );
                    if (timeDifference < minDifference) {
                        minDifference = timeDifference;
                        closestHrPoint = hrData[i].value;
                        this.lastHrUsed = i;
                    } else if (i > this.lastHrUsed && closestHrPoint) {
                        return closestHrPoint;
                    }
                }
            }
        }
        return closestHrPoint;
    }

    generateGPX(gpsData, hrData, matchTimings) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var gpxData = new GarminBuilder();
            var sortedHr;
            if (hrData) {
                sortedHr = self.heartRateProcessingService.sortHeartRate({
                    heartRateValues: hrData,
                }).heartRateValues;
            }
            gpsData = self.gpsProcessingService.sortGpsData(gpsData);
            gpsData = self.gpsProcessingService.filterInvalidPoints(
                gpsData,
                matchTimings
            );
            var points: any[] = [];
            self.lastHrUsed = 0;
            if (gpsData && gpsData.geoPoints) {
                gpsData.geoPoints.forEach(function (point) {
                    var extensions = {
                        speed: point.speed,
                        time: new Date(point.time),
                        hr: undefined,
                    };
                    if (sortedHr) {
                        extensions.hr = self.getClosestHrPoint(
                            point.time,
                            sortedHr
                        );
                    }

                    var garminPoint = new Point(
                        point.latitude,
                        point.longitude,
                        extensions
                    );
                    points.push(garminPoint);
                });
                gpxData.setSegmentPoints(points);
                resolve(buildGPX(gpxData.toObject()));
            } else {
                reject();
            }
        });
    }
}
