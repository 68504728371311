import { FC } from "react";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonList,
    IonPage,
    IonText,
} from "@ionic/react";
import { HeaderComponent } from "../../../../components";
import { useTranslation } from "react-i18next";
import { checkmarkCircleOutline, openOutline } from "ionicons/icons";
import { useSubscription } from "../../../../components/hooks";

const RefAssistIntegration: FC = () => {
    const { t } = useTranslation();

    const subscriptionState = useSubscription();

    const refAssistSubs = subscriptionState?.subscriptions?.filter((sub) => {
        return sub.provider === "refassist";
    });

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={"/"}
                title="RefAssist"
                titleTestId="refassist-integrations-page-header"
            />
            <IonContent>
                <IonCard color="transparent">
                    <IonCardHeader>
                        <IonCardTitle>RefAssist</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {!refAssistSubs || refAssistSubs.length === 0 ? (
                            <>
                                <p>
                                    <IonText>
                                        {t(
                                            "integrations.refAssist.description"
                                        )}
                                    </IonText>
                                </p>

                                <p style={{ marginTop: 10 }}>
                                    <IonText>
                                        {t(
                                            "integrations.refAssist.askYourLeague"
                                        )}
                                    </IonText>
                                </p>

                                <p style={{ marginTop: 10 }}>
                                    <IonText>
                                        {t("integrations.refAssist.email")}
                                    </IonText>
                                </p>

                                <a
                                    href="https://refassist.com"
                                    target="_system"
                                >
                                    <IonIcon icon={openOutline} />
                                    RefAssist
                                </a>
                            </>
                        ) : (
                            <IonList
                                style={{
                                    marginTop: 20,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    borderRadius: 5,
                                }}
                                lines="none"
                            >
                                {refAssistSubs.map((sub) => {
                                    return (
                                        <IonItem key={sub.id}>
                                            <IonIcon
                                                color={"primary"}
                                                icon={checkmarkCircleOutline}
                                                slot={"start"}
                                            />
                                            {sub.rawData?.levels?.length > 0 ? (
                                                <ul
                                                    style={{
                                                        paddingLeft: 0,
                                                        listStyle: "none",
                                                    }}
                                                >
                                                    {sub.rawData?.levels?.map(
                                                        (level: any) => (
                                                            <li>
                                                                {level.subLevel}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            ) : (
                                                <IonText>
                                                    {sub.planDescription}
                                                </IonText>
                                            )}
                                        </IonItem>
                                    );
                                })}
                            </IonList>
                        )}
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default RefAssistIntegration;
