import {
    IonButton,
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
} from "@ionic/react";
import { t } from "i18next";
import { FC, useState } from "react";
import { MatchPhone, SelectedTeam } from "refsix-js-models";
import ScrollableContent from "../../../components/ScrollableContents";
import { ToggleRowComponent } from "../../../components/ToggleRowComponent";
import { updateMatch } from "../../../services/matchService";
import { ToastComponent } from "../../../components";

export const TeamBehaviorSegment: FC<{ match: MatchPhone }> = ({ match }) => {
    const [homeTeamScore, setHomeTeamScore] = useState<number>(
        match.teamBehavior ? match.teamBehavior.teamBehaviorScore.home : 3
    );
    const [awayTeamScore, setAwayTeamScore] = useState<number>(
        match.teamBehavior ? match.teamBehavior.teamBehaviorScore.away : 3
    );

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastTitle, setToastTitle] = useState<string>("");

    const saveTeamBehavior = async () => {
        const newMatch = {
            ...match,
            teamBehavior: {
                teamBehaviorScore: { home: homeTeamScore, away: awayTeamScore },
            },
        };
        try {
            await updateMatch(newMatch);
            setToastTitle(t("teamBehavior.updated"));
            setShowToast(true);
        } catch (err) {
            setToastTitle(t("teamBehavior.notUpdated"));
            setShowToast(true);
            console.log("error trying to update player of the match", err);
        }
    };

    return (
        <ScrollableContent>
            <IonContent>
                <IonCard color="transparent">
                    <IonItem lines="none" color="transparent">
                        <IonLabel>{t("teamBehavior.title")}</IonLabel>
                    </IonItem>
                </IonCard>
                <IonCard>
                    <ToggleRowComponent
                        teamName={match.homeTeam}
                        behaviorScore={homeTeamScore}
                        updateScore={(score) => setHomeTeamScore(score)}
                        testIdTeamSide={SelectedTeam.home}
                    />
                </IonCard>

                <IonCard>
                    <ToggleRowComponent
                        teamName={match.awayTeam}
                        behaviorScore={awayTeamScore}
                        updateScore={(score) => setAwayTeamScore(score)}
                        testIdTeamSide={SelectedTeam.away}
                    />
                </IonCard>
                <IonCard color="transparent">
                    <IonButton
                        expand="block"
                        color="light"
                        onClick={() => saveTeamBehavior()}
                    >
                        {t("general.save")}
                    </IonButton>
                </IonCard>
            </IonContent>
            <ToastComponent
                isOpen={showToast}
                position="bottom"
                message={toastTitle}
                onDismiss={() => setShowToast(false)}
            />
        </ScrollableContent>
    );
};
