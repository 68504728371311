import {
    LOADING_MATCHES,
    SELECTED_MATCH,
    SET_CLOSED_DATES,
    SET_MATCHES,
    SET_OPEN_DATES,
} from "./types";
import { MatchPhone, RefAssistAvailability } from "refsix-js-models";

export const setMatches = (matches: MatchPhone[]) => ({
    type: SET_MATCHES,
    matches,
});

export const setSelectedMatch = (match: MatchPhone | undefined) => ({
    type: SELECTED_MATCH,
    match,
});

export const setLoadingMatches = (loading: boolean) => ({
    type: LOADING_MATCHES,
    loading,
});

export const setClosedDates = (closedDates: RefAssistAvailability[]) => ({
    type: SET_CLOSED_DATES,
    closedDates,
});

export const setOpenDates = (openDates: RefAssistAvailability[]) => ({
    type: SET_OPEN_DATES,
    openDates,
});
