import { MatchEvent } from "../matchEvent";
import { EventName } from "../enums/eventName";

export class HalfEvent extends MatchEvent {
    index: number; // index of the half event e.g. 1st half = 0, half time = 1, 2nd half = 2...
    name: string;
    playing: boolean; // whether it is a playing period or an interval (break)
    length: number; // the prescribed half length in millis
    kickOffSide?: number; // 0 = home, 1 = away NOTE: some garmin matches have used "home" and "away" instead of 0 and 1
    injuryTimeAccumulated: number; // injury time in seconds recorded during the half (e.g. during 0-45)
    injuryTimeInInjuryTimeAccumulated: number; // injury time in seconds recorded when playing the additional time (e.g. during 45-45+3)
    endMinute?: number; // the end minute of the half starting from 0 (e.g. 45)
    endTime?: number; // the timestamp of when the half ended (utc millis)
    isExtraTime: boolean; // whether this half object represents extra time

    constructor(
        timestamp: number,
        eventName: EventName,
        half: number,
        minuteOfPlay: number,
        index: number,
        name: string,
        playing: boolean,
        length: number,
        kickOffSide: number | undefined,
        injuryTimeAccumulated: number,
        injuryTimeInInjuryTimeAccumulated: number,
        endMinute: number | undefined,
        endTime: number | undefined,
        isExtraTime: boolean
    ) {
        super(timestamp, eventName, half, minuteOfPlay);
        this.index = index;
        this.name = name;
        this.playing = playing;
        this.length = length;
        this.kickOffSide = kickOffSide;
        this.injuryTimeAccumulated = injuryTimeAccumulated;
        this.injuryTimeInInjuryTimeAccumulated =
            injuryTimeInInjuryTimeAccumulated;
        this.endMinute = endMinute;
        this.endTime = endTime;
        this.isExtraTime = isExtraTime;
    }

    static fromJSON(json: any) {
        return new HalfEvent(
            json.timestamp,
            json.eventName,
            json.half,
            json.minuteOfPlay,
            json.index,
            json.name,
            json.playing,
            json.length,
            json.kickOffSide,
            json.injuryTimeAccumulated,
            json.injuryTimeInInjuryTimeAccumulated,
            json.endMinute,
            json.endTime,
            json.isExtraTime
        );
    }
}
