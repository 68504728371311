export class Wellbeing {
    score: number;
    comments?: string;

    constructor(score: number, comments: string | undefined) {
        this.score = score;
        this.comments = comments;
    }

    static fromJSON(json: any) {
        return new Wellbeing(json.score, json.comments);
    }
}
