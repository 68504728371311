import * as self from "./match";

import { MatchPhone } from "refsix-js-models";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import _, { filter } from "lodash/fp";
import { compact, find, uniq } from "lodash";
import { useEffect, useState } from "react";
import { FixtureResultCount, getFixtureResultCount } from "refsix-core";

export const useMatch = (matchId?: string): MatchPhone | undefined =>
    find(self.useMatches(), { _id: matchId });
export const useMatches = (): MatchPhone[] | undefined =>
    useSelector((state: RootState) => state.matches.matches);
export const useMatchesBy = (
    predicate: (m: MatchPhone) => boolean | any
): MatchPhone[] | undefined => {
    const matches = self.useMatches();
    const filterBy = filter<MatchPhone>(predicate);
    return filterBy(matches);
};
export const useVenues = (): string[] => {
    const [res, setRes] = useState<string[]>([]);
    const matches = self.useMatches();
    useEffect(() => {
        const venues = matches?.map((m) => m.venue || null);
        setRes(uniq(compact(venues)));
    }, [matches]);

    return res;
};
export const useCompetitions = (): string[] => {
    const [res, setRes] = useState<string[]>([]);
    const matches = self.useMatches();

    useEffect(() => {
        const competitions = matches?.map((m) => m.competition || null);
        setRes(uniq(compact(competitions)));
    }, [matches]);

    return res;
};

export const useTeams = (): string[] => {
    const [res, setRes] = useState<string[]>([]);

    const matches = self.useMatches();
    useEffect(() => {
        const getAllTeams = (m: MatchPhone) => [m.homeTeam, m.awayTeam];
        const getListOfTeams = _.flow(
            _.flatMap(getAllTeams),
            _.compact,
            _.uniq
        );
        setRes(getListOfTeams(matches));
    }, [matches]);

    return res;
};

export const useFixtureResultCount = (): FixtureResultCount => {
    const matches = self.useMatches();

    return getFixtureResultCount(matches || []);
};

export const useMatchIds = (): string[] => {
    const matches = self.useMatches();
    if (!matches) return [];

    // for matches that have an _id property return the _id in an array
    return matches
        .map((match) => match._id)
        .filter((id): id is string => typeof id === "string");
};
