// a react ionic page component to display the developer tools

import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { MatchHeader } from "../addResult/matchHeader";
import { MatchPhone } from "refsix-js-models";
import PouchDebugMatchRevs from "../../components/debug/PouchDebugMatchRevs";
import { getDatabaseService } from "../../services/matchService";
import { useTranslation } from "react-i18next";

const PouchDebugMatch: React.FC = () => {
    const { t } = useTranslation();
    const [matchId, setMatchId] = useState<string>("");
    const [match, setMatch] = useState<MatchPhone | null>(null);

    useEffect(() => {
        if (matchId !== "") {
            getDatabaseService()
                ?.getLocalDatabase()
                .get(matchId)
                .then((match: any) => {
                    setMatch(match);
                });
        }
    }, [matchId]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref="/"
                            text={t("help.OnboardingVideo.controls.back")}
                        />
                    </IonButtons>
                    <IonTitle>PouchDB Debug</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonText>Show all local revisions of a match</IonText>
                    </IonItem>
                    {/*    input to get matchId from the user */}
                    <IonItem>
                        <IonLabel>Match ID</IonLabel>
                        <IonInput
                            type="text"
                            onBlur={(e) => {
                                setMatchId((e.target.value as string) || "");
                            }}
                        ></IonInput>
                    </IonItem>
                </IonList>

                {match && <MatchHeader match={match} />}

                {matchId !== "" && <PouchDebugMatchRevs matchId={matchId} />}
            </IonContent>
        </IonPage>
    );
};

export default PouchDebugMatch;
