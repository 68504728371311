import { IonCard, IonCol, IonRow, IonText } from "@ionic/react";
import { FC } from "react";
import { RenderTeamsPlayerEvents } from "./RenderTeamsPlayerEvents";
import { useTranslation } from "react-i18next";
import { PlayerWithEvents } from "../../utils/playersUtils";
import { Player } from "refsix-js-models";

interface RenderTeamSheetForTeamInterface {
    playersWithEvents: PlayerWithEvents[];
    headerTitle: string;
    isNumbersHidden?: boolean;
}

export const RenderTeamSheetForTeam: FC<RenderTeamSheetForTeamInterface> = ({
    playersWithEvents,
    headerTitle,
    isNumbersHidden,
}) => {
    const { t } = useTranslation();

    const getTeamOfficialRole = (player: Player): string => {
        if (player.teamOfficial) {
            return `${t(`fixture.FixtureTeam.${player.role}`)}`;
        }

        return "";
    };

    return (
        <IonCard className="match-card-container">
            <div>
                <IonText className="match-details-title">{headerTitle}</IonText>
            </div>
            {playersWithEvents.length <= 0 ? (
                <div>
                    <IonText>
                        {t("integrations.teamSheets.noTeamSheet")}
                    </IonText>
                </div>
            ) : (
                playersWithEvents.map((playerWithEvents, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                marginTop: "10px",
                            }}
                        >
                            <IonCol
                                size="1"
                                style={{
                                    padding: 0,
                                }}
                                hidden={isNumbersHidden}
                            >
                                <IonText className="white-text-and-font">
                                    {playerWithEvents.player.number}
                                </IonText>
                            </IonCol>

                            <IonRow>
                                {playerWithEvents.player.teamOfficial && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IonText>
                                            {getTeamOfficialRole(
                                                playerWithEvents.player
                                            )}
                                        </IonText>
                                    </div>
                                )}
                                <IonText
                                    className="white-text-and-font"
                                    style={{
                                        marginLeft: playerWithEvents.player
                                            .teamOfficial
                                            ? 10
                                            : 0,
                                    }}
                                >
                                    {playerWithEvents.player.name}
                                </IonText>

                                <RenderTeamsPlayerEvents
                                    sortedEvents={playerWithEvents.events}
                                    player={playerWithEvents.player}
                                />
                            </IonRow>
                        </div>
                    );
                })
            )}
        </IonCard>
    );
};
