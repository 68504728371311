import { FC, useEffect, useState } from "react";
import {
    IonCard,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonText,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { HeaderComponent } from "../../../../components";
import {
    Controller,
    FieldValues,
    FormProvider,
    useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { URLS } from "../../../../constants/urls";
import "./videoPlayer.css";
import {
    getDbuAccessToken,
    handleFixturesFromDBU,
    syncDbuMatches,
} from "../../../../services/integrationService";
import { routes } from "../../../../route/constants";
import { getSubscriptions } from "../../../../services/subscriptionService";
import { IntegrationPartner, SubscriptionItems } from "refsix-js-models";
import { find } from "lodash";
import { trackEvent } from "../../../../services/analytics/analyticsService";

const DbuIntegration: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [displayUpdatesAlert] = useIonAlert();

    const formMethods = useForm<FieldValues>({
        mode: "all",
        defaultValues: {
            refNumber: "",
            refApiKey: "",
        },
    });

    const {
        formState: { errors },
        handleSubmit,
        reset,
    } = formMethods;

    useEffect(() => {
        getSubscriptions()
            .then((res) => {
                if (res) {
                    getSubsCredentials(res.subscriptions);
                }
            })
            .catch((error) => {
                console.log("Error trying to get subscriptions for dbu", error);
            });
    }, []);

    const getSubsCredentials = (subscriptions: SubscriptionItems[]) => {
        const rawData = find(subscriptions, ["provider", "dbu"])?.rawData;

        if (rawData) {
            reset((formValues) => ({
                ...formValues,
                refNumber: rawData.refereeNumber,
                refApiKey: rawData.apiKey,
            }));
        }
    };

    const handleDbuUpdates = async (data: any) => {
        const fixturesUpdate = handleFixturesFromDBU(data);

        if (fixturesUpdate) {
            await displayUpdatesAlert({
                header: t("integrations.matches.updatesTitle"),
                message: `${t("integrations.matches.matchesAdded")} ${
                    fixturesUpdate.added.length
                } <br>
                    ${t("integrations.matches.matchesDeleted")} ${
                    fixturesUpdate.deleted.length
                }
                                        <br>
                    ${t("integrations.matches.matchesUpdated")} ${
                    fixturesUpdate.updated.length
                }`,
                buttons: [
                    {
                        text: t("general.cancel"),
                    },
                    {
                        handler: () => {
                            route.push(routes.refsixFixtures, "root");
                        },
                        id: "confirm",
                        text: t("help.OnboardingVideo.controls.ok"),
                    },
                ],
            });
            setLoading(false);
        }
    };

    const dbuAccessToken = async (refNumber: number, apiKey: number) => {
        try {
            // Getting access token from DBU for user
            await getDbuAccessToken(refNumber, apiKey);
            trackEvent("AddIntegrationSuccess", {
                partner: IntegrationPartner.dbu,
            });
            return Promise.resolve(true);
        } catch (error) {
            setLoading(false);
            trackEvent("AddIntegrationFailed", {
                partner: IntegrationPartner.dbu,
            });
            console.log("Error trying to get access token", error);
            await generalError();
            return Promise.reject(false);
        }
    };

    const syncWithDbu = async () => {
        try {
            const syncMatchesResponse = await syncDbuMatches();
            await handleDbuUpdates(syncMatchesResponse.data);
        } catch (error) {
            setLoading(false);
            await generalError();
        }
    };

    const syncDbuCredentials = async (data: FieldValues) => {
        if (inputsDisabled) {
            setInputsDisabled(false);
            return;
        }

        setLoading(true);

        let refereeNumber = data.refNumber;
        let apiKey = data.refApiKey;

        setInputsDisabled(true);

        try {
            const response = await dbuAccessToken(refereeNumber, apiKey);

            // We are ready to sync matches for the user from DBU
            if (response) {
                return await syncWithDbu();
            }
        } catch (e) {
            console.log("error trying to get dbu access token", e);
        }
    };

    const generalError = async () => {
        await displayUpdatesAlert({
            header: t("integrations.integrationsFailure.title"),
            message: t("integrations.integrationsFailure.descriptionMessage"),
            buttons: [
                {
                    handler: () => {},
                    id: "confirm",
                    text: t("help.OnboardingVideo.controls.ok"),
                },
            ],
        });
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton={true}
                defaultHref={routes.refsixFixtures}
                title={t("integrations.dbuIntegrationPopup.title")}
                titleTestId="dbu-integrations-page-header"
                headerEndText={
                    inputsDisabled ? t("general.edit") : t("general.save")
                }
                onClickEndButton={
                    inputsDisabled
                        ? () => setInputsDisabled(false)
                        : handleSubmit(syncDbuCredentials)
                }
                endBtnTestId="dbu-btn-save"
            />
            <IonContent>
                <IonLoading isOpen={loading} />
                <IonCard color="transparent">
                    <FormProvider {...formMethods}>
                        <form onSubmit={handleSubmit(syncDbuCredentials)}>
                            <IonItem
                                className={
                                    errors &&
                                    !inputsDisabled &&
                                    errors["refNumber"]
                                        ? "danger"
                                        : ""
                                }
                                lines="full"
                            >
                                <IonLabel>
                                    {t("integrations.refereeNumber")}
                                </IonLabel>
                                <Controller
                                    rules={{ required: true }}
                                    name="refNumber"
                                    data-testid="referee-number-input"
                                    render={({
                                        field: { onBlur, onChange, ...rest },
                                    }) => (
                                        <IonInput
                                            disabled={inputsDisabled}
                                            onIonChange={onChange}
                                            {...rest}
                                        />
                                    )}
                                />
                            </IonItem>
                            <IonItem
                                className={
                                    errors &&
                                    !inputsDisabled &&
                                    errors["refApiKey"]
                                        ? "danger"
                                        : ""
                                }
                                lines="none"
                            >
                                <IonLabel>
                                    {t("integrations.refereeKey")}
                                </IonLabel>
                                <Controller
                                    rules={{ required: true }}
                                    name="refApiKey"
                                    render={({
                                        field: { onBlur, onChange, ...rest },
                                    }) => (
                                        <IonInput
                                            disabled={inputsDisabled}
                                            onIonChange={onChange}
                                            {...rest}
                                        />
                                    )}
                                />
                            </IonItem>
                        </form>
                    </FormProvider>
                </IonCard>
                <IonCard color="transparent">
                    <IonItem lines="none" color="transparent">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <IonText>
                                {t("integrations.findDBUIntegrationKey")}
                            </IonText>
                            <br />
                            <IonText>
                                {t("integrations.byEnablingDbuIntegration")}
                            </IonText>
                        </div>
                    </IonItem>
                </IonCard>

                <IonCard color="transparent">
                    <IonItem color="transparent">
                        <iframe
                            className="onboarding vimeo-player"
                            src="https://player.vimeo.com/video/591006232?h=226e9fdf44"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        />
                    </IonItem>
                </IonCard>

                <IonCard color="transparent">
                    <IonItem
                        lines="none"
                        button
                        onClick={() => window.open(URLS.DBU_API_KEY, "_system")}
                    >
                        <IonText>{t("integrations.findAPIKey")}</IonText>
                    </IonItem>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default DbuIntegration;
