import React, { FC, useState } from "react";
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { forgotPassword } from "../../services/authService";
import { AlertComponent } from "../../components";
import { routes } from "../../route/constants";
import { EMAIL_REGEX } from "../../utils/formValidation";
import { errorProps } from "../../services/errorService";
import { R6IonInput } from "../../components/r6ionic/R6IonInput";
import RefSixImage from "../../assets/images/refsix-words-h80px.png";
import { useSafeKeyboard } from "../../components/hooks/useSafeKeyboard";
import { useProfile } from "../../components/hooks";

const ForgotPassword: FC = () => {
    const { t } = useTranslation();
    const profile = useProfile();
    const formMethods = useForm<FieldValues>({
        mode: "all",
        defaultValues: {
            resetPasswordEmail: profile?.email || "",
        },
    });

    const {
        handleSubmit,
        formState: { isValid, errors },
    } = formMethods;

    let route = useIonRouter();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const emailError: string = t("general.errorInvalidEmail");
    const { isOpen: isKeyboardVisible } = useSafeKeyboard();

    const handleResetPassword = async (data: any) => {
        setIsLoading(true);
        try {
            const response = await forgotPassword(data.resetPasswordEmail);
            if (response) {
                route.push(routes.resetPassword);
            }
            setIsLoading(false);
        } catch (error: any) {
            setShowAlert(true);
            setIsLoading(false);
        }
    };

    const validateEmail = (value: string) => {
        return !!value.match(EMAIL_REGEX) || emailError;
    };

    return (
        <IonPage>
            <IonContent data-testid="forgot-password-content">
                <IonItem
                    lines="none"
                    color="transparent"
                    style={{ marginTop: 50 }}
                >
                    <div className="flex-column-container">
                        <div className="flex-center-container">
                            <img src={RefSixImage} style={{ width: "100px" }} />
                        </div>
                        <h1>{t("general.resetPassword")}</h1>
                    </div>
                </IonItem>

                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(handleResetPassword)}>
                        <IonCard className="match-card-container create-match-wrapper">
                            <div style={{ marginBottom: 10 }}>
                                <IonLabel
                                    style={{ color: "white", fontSize: 14 }}
                                >
                                    {t("register.register.email")}
                                </IonLabel>
                            </div>
                            <IonItem
                                lines="full"
                                {...errorProps(
                                    "resetPasswordEmail",
                                    errors,
                                    true,
                                    "focus",
                                    true
                                )}
                            >
                                <R6IonInput
                                    name="resetPasswordEmail"
                                    placeholder={t("register.register.email")}
                                    rules={{
                                        required: t(
                                            "utils.ErrorMessages.required"
                                        ).toString(),
                                        validate: {
                                            validEmail: validateEmail,
                                        },
                                    }}
                                    autocapitalize="off"
                                />
                            </IonItem>

                            {errors && errors.resetPasswordEmail && (
                                <IonItem lines="none" color="transparent">
                                    <IonText className="error-message">
                                        {errors.resetPasswordEmail.message}
                                    </IonText>
                                </IonItem>
                            )}
                            <IonButton
                                data-testid="reset-password-button"
                                className="login-button"
                                expand="block"
                                type="submit"
                                disabled={!isValid}
                            >
                                {t("general.resetPassword")}
                            </IonButton>
                            <input type="submit" hidden={true} />
                        </IonCard>
                    </form>
                </FormProvider>

                <IonLoading isOpen={isLoading} />
                <AlertComponent
                    testId={"forgot-password-alert"}
                    onDonePressed={() => setShowAlert(false)}
                    buttonDone={t("help.OnboardingVideo.controls.ok")}
                    showAlert={showAlert}
                    title={t("login.ForgotPassword.failure.title")}
                    description={t("login.ForgotPassword.failure.description")}
                />
            </IonContent>
            <IonFooter hidden={isKeyboardVisible}>
                <IonButton
                    className="forgot-password-wrapper"
                    onClick={() => route.goBack()}
                    fill="clear"
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                >
                    {t("general.cancel")}
                </IonButton>
            </IonFooter>
        </IonPage>
    );
};

export default ForgotPassword;
