import _, { flow, map } from "lodash/fp";
import { UseList } from "./types";
import { useTranslation } from "react-i18next";
import { useTeamPacksContext } from "../TeamPacks";
import { TeamPackData } from "refsix-js-models";
import React, { useCallback, useMemo } from "react";
import { usePlayersByTeam } from "../index";
import MiniSearch from "minisearch";

export type TeamPlayerListItem = {
    id: string;
    team: string;
    name: string;
};
const playerNameToPlayerListItem = (player: string) => ({
    id: _.uniqueId("player"),
    name: player,
    team: "",
});
//
// export const getAllPlayersForTeamFromTeamPacks = (
//     teamPacks: TeamPackData[],
//     teamName: string
// ) => {
//     const players = teamPacks.flatMap((teamPack) => teamPack.players);
//     const team = players.find((player) => player.name === teamName);
//     return team ? team.players : [];
// };
//
// const useTeampackPlayers = (teamName: string) => {
//     const { t } = useTranslation();
//
//     const [players, setPlayers] = useState<any>([]);
//     const { downloadedPacks } = useTeamPacksContext();
//     const previousPlayers = usePlayersByTeam(teamName);
//
//     useEffect(() => {
//         const getAllPlayersFromTeamPacks: (d: TeamPackData[]) => any = flow(
//             _.flatMap((t) => t.players),
//             _.find((p) => p.name === teamName),
//             _.getOr([], "players")
//         );
//
//         const teamPackPlayersByTeam =
//             getAllPlayersFromTeamPacks(downloadedPacks);
//         const allPlayers = _.compact(
//             _.uniq([...previousPlayers, ...teamPackPlayersByTeam])
//         );
//         const filterOutDefaultPlayers = _.filter(
//             (p: string) => !p.startsWith(t("fixture.FixtureTeam.player"))
//         );
//         const createAllPlayerIndex = flow(
//             filterOutDefaultPlayers,
//             map(playerNameToPlayerListItem)
//         );
//         const idx = createAllPlayerIndex(allPlayers);
//     }, [downloadedPacks, previousPlayers, t]);
// };
export const useTeamPlayerList: (
    teamName: string,
    ignoredItems?: string[]
) => UseList<TeamPlayerListItem[]> = (teamName, ignoredItems = []) => {
    const { t } = useTranslation();

    const { downloadedPacks } = useTeamPacksContext();
    const getAllPlayersFromTeamPacks: (d: TeamPackData[]) => any = flow(
        _.flatMap((t) => t.players),
        _.find((p) => p.name === teamName),
        _.getOr([], "players")
    );

    const teamPackPlayersByTeam = useMemo(
        () => getAllPlayersFromTeamPacks(downloadedPacks),
        [downloadedPacks, getAllPlayersFromTeamPacks]
    );
    const previousPlayers = usePlayersByTeam(teamName);
    const allPlayers = _.compact(
        _.uniq([...previousPlayers, ...teamPackPlayersByTeam])
    );
    const filterOutDefaultPlayers = _.filter(
        (p: string) => !p.startsWith(t("fixture.FixtureTeam.player"))
    );
    const createAllPlayerIndex = flow(
        filterOutDefaultPlayers,
        map(playerNameToPlayerListItem)
    );
    const idx = createAllPlayerIndex(allPlayers);
    const search = useMemo(() => {
        const ms = new MiniSearch({
            fields: ["name"],
            storeFields: ["name"],
        });
        ms.addAll(idx);
        return ms;
    }, [idx]);

    const searcher = (query: string = "") => {
        if (query === "") {
            return idx;
        }
        const res = search.search(query, {
            prefix: true,
            fuzzy: false,
            combineWith: "AND",
        });
        return res as any as TeamPlayerListItem[];
    };
    const cb = useCallback(searcher, [searcher]);

    return {
        search: cb,
        renderer: (results, save) => {
            const filterIgnored = _.filter(
                (r: TeamPlayerListItem) => !ignoredItems.includes(r.name)
            );
            return filterIgnored(results).map((result: any, index) => {
                return (
                    <span
                        color="medium"
                        style={{
                            display: "inline-block",
                            backgroundColor: "#363636",
                            margin: "7px 5px 7px 0px",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                        }}
                        key={`${index}-${result}`}
                        onClick={() => save(result.name)}
                    >
                        {result.name}
                    </span>
                );
            });
        },
    };
};
