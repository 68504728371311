import {
    Config,
    ConfigPhone,
    Half,
    Match,
    MatchData,
    MatchPhone,
    OfficialRole,
    PeriodNames,
    SelectedTeam,
    SinBinSystem,
    TemplateConfig,
    TimerLayout,
    TimerType,
    Timings,
    WatchFace,
} from "refsix-js-models";
import {
    defaultMisconductCodes,
    getPreSetCodesById,
} from "../PreSetMisconducts";
import { copyObject } from "./copyObject";

export const periodStructure = {
    1: [PeriodNames.firstPeriod],
    2: [PeriodNames.firstHalf, PeriodNames.halfTime, PeriodNames.secondHalf],
    3: [
        PeriodNames.firstThird,
        PeriodNames.firstBreak,
        PeriodNames.secondThird,
        PeriodNames.secondBreak,
        PeriodNames.thirdThird,
    ],
    4: [
        PeriodNames.firstQuarter,
        PeriodNames.firstBreak,
        PeriodNames.secondQuarter,
        PeriodNames.secondBreak,
        PeriodNames.thirdQuarter,
        PeriodNames.thirdBreak,
        PeriodNames.fourthQuarter,
    ],
};

export function minsToMillis(mins: number | undefined): number | undefined {
    return mins && !isNaN(mins) ? mins * 60000 : mins;
}

// TODO - would be nice to get rid of this nonsense
export function generateWatchId(match: MatchPhone): string {
    if (match.refsixUpdateCount) {
        return match._id + "_" + match.refsixUpdateCount;
    }
    return match._id || "missing_match_id";
}

export function watchMatchIdToMatchId(matchId: string): string {
    return matchId.split("_")[0];
}

export function watchMatchIdToRevision(matchId: string) {
    return parseInt(matchId.split("_")[1]);
}

function _isInterval(num: number): number {
    return num % 2;
}

function _generateHalves(timings: Timings, periods: number): Half[] {
    let halves: Half[] = [];

    let intervalCount = 1;
    let periodCount = 1;

    for (let i = 0; i < 2 * periods - 1; i++) {
        const periodName = periodStructure[periods][i];

        if (!_isInterval(i)) {
            // Playing period
            const periodLengthMins: number =
                timings["period" + periodCount] || timings["period1"] || 45;

            halves.push(
                new Half(
                    periodName,
                    true,
                    minsToMillis(periodLengthMins) as number, // we have defaulted above so this should be correct
                    false
                )
            );
            periodCount++;
        } else {
            // interval
            const intervalLengthMins =
                timings["interval" + intervalCount] ||
                timings["interval1"] ||
                15;
            halves.push(
                new Half(
                    periodName,
                    false,
                    minsToMillis(intervalLengthMins) as number, // we have defaulted above so this should be correct
                    false
                )
            );
            intervalCount++;
        }
    }
    return halves;
}

var _addTeamOfficialsToPlayers = function (
    players,
    team,
    hasAccessToTeamOfficials,
    teamOfficials
) {
    var shouldAddTeamOfficials = true;
    for (var i = 0; i < players[team].length; i++) {
        if (players[team][i].teamOfficial) {
            shouldAddTeamOfficials = false;
        }
    }
    const updatedPlayers = players[team] ? players[team] : [];

    // TODO - this is a bit of a hack, but it's the only way to get the player summary to show to show up in the apple watch app
    updatedPlayers.forEach((player) => {
        if (player.teamOfficial === false) {
            delete player.teamOfficial;
        }
    });

    if (shouldAddTeamOfficials && hasAccessToTeamOfficials) {
        return updatedPlayers.concat(copyObject(teamOfficials));
    }
    return updatedPlayers;
};

export function _watchMatch(
    fixture: MatchPhone,
    hasAccessToTeamOfficials: boolean,
    teamOfficials: boolean,
    allowMissingProperties: boolean | undefined = false
): Match {
    const match: Partial<Match> = {};
    if (!allowMissingProperties && !fixture.periodsNo) {
        throw "Missing periodsNo property";
    }
    if (!allowMissingProperties && !fixture.timings) {
        throw "Missing timings property";
    }

    match.matchId = generateWatchId(fixture);
    // match.withGoalScorers = fixture.withGoalScorers || false; // not used in apple watch
    match.extraTimeAvailable = fixture.extraTimeAvailable || false;
    match.extraTimeHalfLength = minsToMillis(
        fixture?.timings?.extraTimeHalfLength
    );
    match.penaltiesAvailable = fixture.penaltiesAvailable || false;
    match.matchFinished = false;
    match.sinBinTimerLength =
        fixture.timings && fixture.timings.sinBinTimerLength
            ? minsToMillis(fixture.timings.sinBinTimerLength)
            : 0;
    match.sinBinSystem = fixture.sinBinSystem;
    match.officialRole = fixture.officialRole;
    match.misconductCodeId = fixture.misconductCodeId || "fifa";
    match.teams = [
        {
            players: _addTeamOfficialsToPlayers(
                fixture.players,
                fixture.homeTeam,
                hasAccessToTeamOfficials,
                teamOfficials
            ),
            shortName: fixture.homeTeamShort
                ? fixture.homeTeamShort
                : fixture.homeTeam.substring(0, 3).toUpperCase(),
            side: SelectedTeam.home,
            teamName: fixture.homeTeam,
            color: fixture.homeColor,
        },
        {
            players: _addTeamOfficialsToPlayers(
                fixture.players,
                fixture.awayTeam,
                hasAccessToTeamOfficials,
                teamOfficials
            ),
            shortName: fixture.awayTeamShort
                ? fixture.awayTeamShort
                : fixture.awayTeam.substring(0, 3).toUpperCase(),
            side: SelectedTeam.away,
            teamName: fixture.awayTeam,
            color: fixture.awayColor,
        },
    ];
    match.kickOffTime = new Date(fixture.date).getTime();
    if (!fixture.timings || !fixture.periodsNo) {
        match.halves = [];
    } else {
        match.halves = _generateHalves(
            fixture.timings,
            parseInt(fixture.periodsNo)
        );
    }
    return Match.fromJSON(match);
}

interface PartialConfigTimers {
    watchFace: WatchFace;
    elapsedHalfTimerFromZero: boolean;
    layout: TimerLayout;
}

function _getDefaultTimers(): PartialConfigTimers {
    return {
        watchFace: WatchFace.classic,
        elapsedHalfTimerFromZero: true,
        layout: TimerLayout.fromJSON({
            main: TimerType.elapsed,
            secondary: TimerType.additional,
        }),
    };
}

var _changeYellowCardsSinBinValue = function (codes, value) {
    return codes.map(function (code) {
        if (code.subCodes) {
            code.subCodes = _changeYellowCardsSinBinValue(code.subCodes, value);
        }

        if (code.type === "yellow" && !code.teamOfficial) {
            code.sinBin = value;
        }
        return code;
    });
};

var _addTeamOfficialsCodes = function (customCodes, hasAccessToTeamOfficials) {
    if (hasAccessToTeamOfficials) {
        for (var i = 0; i < customCodes.length; i++) {
            var hasTeamOfficialCodes = false;
            for (var j = 0; j < customCodes[i].codes.length; j++) {
                if (customCodes[i].codes[j].teamOfficial) {
                    hasTeamOfficialCodes = true;
                    break;
                }
            }

            if (!hasTeamOfficialCodes) {
                customCodes[i].codes = customCodes[i].codes.concat([
                    {
                        code: "TO1",
                        reason: "Yellow",
                        type: "yellow",
                        secondYellow: false,
                        teamOfficial: true,
                    },
                    {
                        code: "TO2",
                        reason: "Red",
                        type: "red",
                        secondYellow: true,
                        teamOfficial: true,
                    },
                ]);
            }
        }
    }

    return customCodes;
};

var _filterMisconductCodesIfNoAccessTeamOfficials = function (
    codes,
    hasAccessToTeamOfficials
) {
    return codes.filter(function (code) {
        return (
            (hasAccessToTeamOfficials && !!code.teamOfficial) ||
            !!!code.teamOfficial
        );
    });
};

/**
 * Returns true if the players list contains any players which aren't teamOfficials
 * @private
 */
var _hasPlayers = function (watchTeam) {
    for (var i = 0; i < watchTeam.players.length; i++) {
        var player = watchTeam.players[i];
        if (!player.teamOfficial) {
            return true;
        }
    }
    return false;
};

export function _watchConfig(
    phoneFixture: MatchPhone,
    watchFixture: Match,
    settings: ConfigPhone,
    hasAccessToTimers: boolean,
    hasAccessToTeamOfficials: boolean
): Config {
    // copy over any matching settings
    const config: Config = Config.fromJSON(settings);
    const defaultTimers = _getDefaultTimers();
    const customCodes = _addTeamOfficialsCodes(
        settings.customCodes[0],
        hasAccessToTeamOfficials
    );

    if (!hasAccessToTimers) {
        config.elapsedHalfTimerFromZero =
            defaultTimers.elapsedHalfTimerFromZero;
        config.layout = defaultTimers.layout;
        config.watchFace = defaultTimers.watchFace;
    }

    // only for the option in settings
    config.playerNumberPicker = settings.playerNumberPicker;

    // default them in case no teams.
    config.homeTeamPlayerNumberPicker = true;
    config.awayTeamPlayerNumberPicker = true;

    watchFixture.teams &&
        watchFixture.teams.forEach(function (watchTeam) {
            if (watchTeam.side === "home") {
                config.homeTeamPlayerNumberPicker = !_hasPlayers(watchTeam);
            } else if (watchTeam.side === "away") {
                config.awayTeamPlayerNumberPicker = !_hasPlayers(watchTeam);
            }
        });

    if (!watchFixture.misconductCodeId) {
        watchFixture.misconductCodeId = phoneFixture.misconductCodeId;
    }
    if (!watchFixture.sinBinSystem) {
        watchFixture.sinBinSystem = SinBinSystem.none;
    }

    const selectCode = watchFixture.misconductCodeId;

    let misconductCodes =
        selectCode === "custom"
            ? customCodes
            : getPreSetCodesById(watchFixture.misconductCodeId || "fifa");
    if (!misconductCodes) {
        console.error(
            "Error: Couldn't find the requested misconduct codes with id: " +
                watchFixture.misconductCodeId +
                " for fixture " +
                watchFixture.matchId +
                ". You probably need to update refsix-core. Defaulted to fifa"
        );
        misconductCodes = defaultMisconductCodes;
        watchFixture.misconductCodeId = defaultMisconductCodes.id;
    }

    config.misconductCodes =
        misconductCodes && misconductCodes.codes
            ? copyObject(misconductCodes.codes)
            : undefined;

    if (watchFixture.sinBinSystem === SinBinSystem.systemA) {
        config.misconductCodes = _changeYellowCardsSinBinValue(
            config.misconductCodes,
            true
        );
    } else if (watchFixture.sinBinSystem === SinBinSystem.none) {
        config.misconductCodes = _changeYellowCardsSinBinValue(
            config.misconductCodes,
            false
        );
    }
    //TODO remove in distantish future
    config.misconductCodes = _filterMisconductCodesIfNoAccessTeamOfficials(
        config.misconductCodes,
        hasAccessToTeamOfficials
    );

    if (watchFixture.misconductCodeId === "custom") {
        watchFixture.customMisconductCodes = config.misconductCodes;
    }
    config.disableGPS =
        phoneFixture.officialRole === OfficialRole.fourthOfficial ||
        phoneFixture.officialRole === OfficialRole.observer
            ? true
            : config.disableGPS;

    config.recordGoalScorers = !!phoneFixture.withGoalScorers;
    return Config.fromJSON(config);
}

// function WatchFixture(
//     fixture,
//     settings,
//     hasAccessToTimers,
//     hasAccessToTeamOfficials,
//     teamOfficials
// ) {
//     this.match = _watchMatch(fixture, hasAccessToTeamOfficials, teamOfficials);
//     this.config = _watchConfig(
//         fixture,
//         this.match,
//         settings,
//         hasAccessToTimers,
//         hasAccessToTeamOfficials
//     );
// }

export const buildMatchForWatch = (
    match,
    settings,
    hasAccessToTimers,
    hasAccessToTeamOfficials,
    teamOfficials,
    allowMissingProperties = false
): MatchData => {
    const watchMatch = _watchMatch(
        match,
        hasAccessToTeamOfficials,
        teamOfficials,
        allowMissingProperties
    );
    const watchConfig = _watchConfig(
        match,
        watchMatch,
        settings,
        hasAccessToTimers,
        hasAccessToTeamOfficials
    );
    return new MatchData(watchMatch, watchConfig);
};

function _combinePlayers(phoneFixture, watchTeam) {
    phoneFixture.players = phoneFixture.players || {};
    // walk over phone players
    var currentTeamPlayers = phoneFixture.players[watchTeam.teamName] || [];

    currentTeamPlayers = currentTeamPlayers.map(function (phonePlayer) {
        for (var j = 0; j < watchTeam.players.length; j++) {
            var watchPlayer = watchTeam.players[j];
            if (watchTeam.players[j].number === phonePlayer.number) {
                delete watchPlayer.events;
                // remove this player from the watch fixture
                watchTeam.players.splice(j, 1);

                // override with the player name they selected on the watch
                phonePlayer.name = watchPlayer.name;
                return phonePlayer;
            }
        }
        return phonePlayer;
    });

    // add in any players that were used on the watch but missing from the phone
    watchTeam.players.forEach(function (watchPlayer) {
        watchPlayer.starting = true;
        currentTeamPlayers.push(watchPlayer);
    });

    phoneFixture.players[watchTeam.teamName] = currentTeamPlayers;
}

export function _mapWatchPropertiesToPhone(
    phoneFixture,
    watchFixture,
    version,
    combinePlayers
) {
    phoneFixture = copyObject(phoneFixture);

    phoneFixture.appVersion = version; // update to current version
    phoneFixture.device = watchFixture.device; // save device info if available
    phoneFixture.matchFinished = true;
    phoneFixture.matchInProgress = false;
    phoneFixture.matchAbandoned = watchFixture.matchAbandoned;
    phoneFixture.misconductCodeId = watchFixture.misconductCodeId;
    if (phoneFixture.misconductCodeId === "custom") {
        phoneFixture.customMisconductCodes = watchFixture.customMisconductCodes;
    }

    //Update players
    if (!combinePlayers) {
        phoneFixture.players = {};
    }

    watchFixture.teams &&
        watchFixture.teams.forEach(function (watchTeam) {
            if (watchTeam.side === "home") {
                phoneFixture.homeTeam = watchTeam.teamName;
                phoneFixture.homeTeamShort = watchTeam.shortName;
                phoneFixture.homeColor = watchTeam.color;
            } else {
                phoneFixture.awayTeam = watchTeam.teamName;
                phoneFixture.awayTeamShort = watchTeam.shortName;
                phoneFixture.awayColor = watchTeam.color;
            }

            if (combinePlayers) {
                _combinePlayers(phoneFixture, watchTeam);
            } else {
                phoneFixture.players[watchTeam.teamName] =
                    watchTeam.players.map(function (player) {
                        delete player.events;
                        return player;
                    });
            }
        });

    //Parse events
    phoneFixture.matchEvents = watchFixture.matchEvents;
    phoneFixture.eventsProcessed = false;

    return MatchPhone.fromJSON(phoneFixture);
}

export function updateMatchForPhone(
    phoneFixture: MatchPhone,
    watchFixture: Match,
    version: string,
    combinePlayers: boolean
) {
    return _mapWatchPropertiesToPhone(
        phoneFixture,
        watchFixture,
        version,
        combinePlayers
    );
}

export function createNewMatchForPhone(
    watchFixture: Match,
    template: TemplateConfig
): MatchPhone {
    var phoneFixture: any = {};
    phoneFixture._id = watchFixture.matchId.split("_")[0];
    if (watchFixture.matchCreatedTs) {
        phoneFixture.date = new Date(watchFixture.matchCreatedTs).toISOString();
    } else {
        phoneFixture.date = new Date().toISOString();
    }
    phoneFixture.timings = template.timings;
    phoneFixture.officialRole = "referee";
    phoneFixture.competition = "?";
    phoneFixture.venue = "?";
    phoneFixture.periodsNo = template.periodsNo;
    phoneFixture.withGoalScorers = template.withGoalScorers;
    phoneFixture.teamSize = template.teamSize;
    phoneFixture.subsNo = template.subsNo;
    phoneFixture.extraTimeAvailable = template.extraTimeAvailable;
    phoneFixture.penaltiesAvailable = template.penaltiesAvailable;
    phoneFixture.sinBinSystem = template.sinBinSystem;

    phoneFixture = _mapWatchPropertiesToPhone(
        phoneFixture,
        watchFixture,
        watchFixture.device?.watchAppVersion || "",
        false
    );
    return MatchPhone.fromJSON(phoneFixture);
}
