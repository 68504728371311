import React, { FC } from "react";
import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelectOption,
    IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import "./components.css";
import "./CreateMatchAutocomplete.css";
import R6SelectorComponent from "./R6SelectorComponent";
import { IntegrationPartner, MatchPhone } from "refsix-js-models";
import { R6IonInput } from "./r6ionic/R6IonInput";

import AutocompleteInput from "./AutocompleteInputItem";
import {
    competitionsList2Renderer,
    useCompetitionList,
} from "./hooks/autocomplete/useCompetitionList";
import { useVenueList } from "./hooks/autocomplete/useVenueList";

export interface MatchInterface {
    match: MatchPhone;
    integration: boolean;
}

const MatchDetailsComponent: FC<MatchInterface> = ({ integration, match }) => {
    const { t } = useTranslation();
    const {
        formState: { errors },
        setValue,
    } = useFormContext();
    const compList = useCompetitionList();

    const venueList = useVenueList();
    const isAssignr = match.integration?.partner === IntegrationPartner.assignr;

    return (
        <IonCol>
            <IonRow className="row-container">
                <div className="create-match-step">
                    <IonText>1</IonText>
                </div>
                <IonText className="create-match-card-title">
                    {t("fixture.FixtureNew.enterBasicDetails")}
                </IonText>
            </IonRow>
            <IonItem
                className="item-title-container rounded-corners-top"
                lines="full"
            >
                <IonLabel>{t("general.matchDetails")}</IonLabel>
            </IonItem>
            {compList.search && (
                <AutocompleteInput
                    label={t("fixture.FixtureNew.competition")}
                    name="competition"
                    errors={errors}
                    placeholder={t("fixture.FixtureNew.competition")}
                    setValue={setValue}
                    searchFn={compList.search}
                    renderFn={competitionsList2Renderer(t)}
                    testId="competition-item-input"
                    ready={!!(compList.ready && compList.search)}
                    disabled={isAssignr}
                />
            )}
            {venueList.search && venueList.renderer && (
                <AutocompleteInput
                    label={t("fixture.FixtureNew.venue")}
                    name="venue"
                    placeholder={t("fixture.FixtureNew.venue")}
                    errors={errors}
                    setValue={setValue}
                    searchFn={venueList.search}
                    renderFn={venueList.renderer}
                    testId="venue-item-input"
                    ready={!!(compList.ready && compList.search)}
                    disabled={isAssignr}
                />
            )}

            <IonItem className="item-title" lines="full">
                {match.dateLocalisedString &&
                match.dateLocalisedString !== "" ? (
                    <R6IonInput
                        className={"align-text-end"}
                        name="dateLocalisedString"
                        testId="datePicker"
                        type="text"
                        disabled={true}
                        label={t("stats.stats.Filters.date")}
                    />
                ) : (
                    <R6IonInput
                        name="date"
                        testId="datePicker"
                        type="datetime-local"
                        disabled={!!match.integration}
                        label={t("stats.stats.Filters.date")}
                    />
                )}
            </IonItem>
            {match.timezone && (
                <IonItem className="item-title" lines="full">
                    <IonLabel className="item-title">
                        {t("fixture.FixtureNew.timezone")}
                    </IonLabel>
                    <IonInput
                        className="align-text-end"
                        disabled={true}
                        value={match.timezone}
                    />
                </IonItem>
            )}

            <IonItem className="item-title rounded-corners-bottom" lines="none">
                <IonLabel>{t("stats.stats.Filters.officialRole")}</IonLabel>

                <R6SelectorComponent
                    testId="officialRoleSelect"
                    name="officialRole"
                    className="select-text"
                    disabled={
                        match.integration?.partner === IntegrationPartner.dbu
                    }
                >
                    <IonSelectOption value="referee" data-testid="roleReferee">
                        {t("fixture.FixtureNew.referee")}
                    </IonSelectOption>
                    <IonSelectOption
                        value="assistant"
                        data-testid="roleAssistant"
                    >
                        {t("fixture.FixtureNew.assistant")}
                    </IonSelectOption>
                    <IonSelectOption
                        value="fourthOfficial"
                        data-testid="roleFourthOfficial"
                    >
                        {t("fixture.FixtureNew.fourthOfficial")}
                    </IonSelectOption>
                    <IonSelectOption
                        value="observer"
                        data-testid="roleObserver"
                    >
                        {t("fixture.FixtureNew.observer")}
                    </IonSelectOption>
                </R6SelectorComponent>
            </IonItem>
        </IonCol>
    );
};

export default MatchDetailsComponent;
