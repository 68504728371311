import { Template } from "./template";

export class Templates {
    _id: string = "r6_fixtureTemplates";
    _rev?: string;
    templates: Template[];
    defaultTemplate: string;

    constructor(templates: Template[], defaultTemplate: string, rev?: string) {
        this._rev = rev;
        this.templates = templates;
        this.defaultTemplate = defaultTemplate;
    }

    static fromJSON(json: any) {
        let templateList: Template[] = [];
        if (json.templates && json.templates.length) {
            templateList = json.templates.map(function (templateJson: any) {
                return Template.fromJSON(templateJson);
            });
        }
        return new Templates(templateList, json.defaultTemplate, json._rev);
    }
}
