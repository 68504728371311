import { FC, useState } from "react";
import { HeaderComponent } from "../../components";
import { useTranslation } from "react-i18next";
import {
    IonButton,
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonText,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { RootState } from "../../redux/store";
import {
    allMatches,
    createDefaultMatch,
    updateMatch,
} from "../../services/matchService";
import { useSelector } from "react-redux";
import { SelectedTeam } from "refsix-js-models";
import { useSettings } from "../../components/hooks";
import { sendRequestTeamSheet } from "../../services/requestTeamSheetService";
import { emailPattern } from "../../constants/emailPattern";
import { R6IonInput } from "../../components/r6ionic/R6IonInput";

const RequestTeamSheet: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const formMethods = useForm<FieldValues>({
        mode: "all",
    });
    const route = useIonRouter();
    const settings = useSettings();
    const currentMatch = useSelector((state: RootState) =>
        allMatches(state.matches.matches).find((match) => match._id === id)
    );
    const match = currentMatch || createDefaultMatch();
    const [showAlert] = useIonAlert();
    const [loading, setLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        formState: { isValid },
    } = formMethods;

    const fields = [
        {
            name: "home",
            label: match.homeTeam,
            side: SelectedTeam.home,
            hidden: !!(match.teamSheetRequests && match.teamSheetRequests.home),
        },
        {
            name: "away",
            label: match.awayTeam,
            side: SelectedTeam.away,
            hidden: !!(match.teamSheetRequests && match.teamSheetRequests.away),
        },
    ];

    const watchHomeTeamRequest = formMethods.watch("home");
    const watchAwayTeamRequest = formMethods.watch("away");

    const checkForLeagueEmail = (competition: string) => {
        // TODO: AutofillService.getComps();
    };

    const handleRequestClicked = async (data: any) => {
        if (!match._id) return;
        setLoading(true);

        try {
            const response: any = await sendRequestTeamSheet(
                match._id,
                data,
                "",
                settings?.teamPacks,
                match.date.toLocaleString()
            );

            if (!response.data.home && !response.data.away) {
                // do something here
                setLoading(false);
                return;
            }

            // save the request IDs on the match
            match.teamSheetRequests = match.teamSheetRequests || {};
            if (response.data.home) {
                match.teamSheetRequests.home = response.data.home;
            }

            if (response.data.away) {
                match.teamSheetRequests.away = response.data.away;
            }

            updateMatch(match).then(() => {
                setLoading(false);
                showAlert({
                    header: t("requestTeamSheets.requestTeamSheet"),
                    message: t("requestPayments.requestSent"),
                    buttons: [
                        {
                            text: t("help.OnboardingVideo.controls.ok"),
                            handler: () => route.goBack(),
                        },
                    ],
                });
            });
        } catch (e) {
            console.log("Error: ", e);
            showAlert({
                header: t("requestTeamSheets.requestTeamSheet"),
                message: t("general.errorTryAgain"),
                buttons: [
                    {
                        text: t("help.OnboardingVideo.controls.ok"),
                    },
                ],
            });
            setLoading(false);
        }
    };

    const checkRequired = () => {
        return !(watchHomeTeamRequest || watchAwayTeamRequest);
    };

    return (
        <IonPage>
            <HeaderComponent
                titleTestId="request-team-sheet-header"
                title={t("requestTeamSheets.requestTeamSheet")}
                showBackButton
            />

            <IonContent>
                <IonLoading isOpen={loading} />
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(handleRequestClicked)}>
                        <IonCard className="ion-card-transparent">
                            <IonItem
                                className="item-title"
                                lines={"none"}
                                color="transparent"
                            >
                                <IonText>
                                    {t("requestTeamSheets.addEmailDescription")}
                                </IonText>
                            </IonItem>
                        </IonCard>

                        {fields.map((field) => {
                            return (
                                <IonCard hidden={field.hidden} key={field.name}>
                                    <IonItem
                                        className="item-title"
                                        lines="full"
                                    >
                                        {field.label}
                                    </IonItem>
                                    <IonItem
                                        className="item-title"
                                        lines={"none"}
                                    >
                                        <IonLabel>
                                            {t(
                                                "login.ForgotPassword.emailPlaceholder"
                                            )}
                                        </IonLabel>
                                        <R6IonInput
                                            name={field.name}
                                            inputMode="email"
                                            placeholder={t(
                                                "login.ForgotPassword.emailPlaceholder"
                                            )}
                                            testId={`request-input-${field.name}`}
                                            rules={{
                                                required: checkRequired(),
                                                pattern: {
                                                    value: emailPattern,
                                                    message: t(
                                                        "utils.ErrorMessages.email"
                                                    ),
                                                },
                                            }}
                                            autocapitalize="off"
                                        ></R6IonInput>
                                    </IonItem>
                                </IonCard>
                            );
                        })}
                        <IonCard>
                            <IonButton
                                data-testid="button-request-team-sheet"
                                expand="block"
                                type="submit"
                                disabled={!isValid}
                            >
                                {t("requestTeamSheets.requestTeamSheet")}
                            </IonButton>
                        </IonCard>
                    </form>
                </FormProvider>
            </IonContent>
        </IonPage>
    );
};

export default RequestTeamSheet;
