import { FC, useEffect, useState } from "react";
import { IonButton, IonCard, IonCol, IonItem, IonTextarea } from "@ionic/react";
import "./notes.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ToastComponent } from "../../../components";
import { updateMatch } from "../../../services/matchService";
import { MatchPhone } from "refsix-js-models";
import { updateUserActionsCheckList } from "../../../services/userActionsCheckListService";
import ScrollableContentWithAds from "../../../components/ScrollableContentsWithAd";

interface NotesProps {
    notes: string | undefined;
}

export const NotesWrapper: FC<{ match: MatchPhone }> = ({ match }) => {
    const [formValues, setFormValues] = useState<NotesProps>();

    useEffect(() => {
        if (match && match.notes) {
            setFormValues({ notes: match.notes });
        }
    }, [match]);

    const defaultValues = {
        notes: "",
    };

    return match ? (
        <Notes
            match={match}
            defaultValues={defaultValues}
            values={formValues}
        />
    ) : null;
};

const Notes: FC<{
    match: MatchPhone;
    defaultValues: NotesProps;
    values: NotesProps | undefined;
}> = ({ match, defaultValues, values }) => {
    const { t } = useTranslation();
    const [notes, setNotes] = useState<string>(match?.notes || "");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastTitle, setToastTitle] = useState<string>("");

    const formMethods = useForm<NotesProps>({
        mode: "all",
        defaultValues,
        values,
    });

    const { handleSubmit, register } = formMethods;

    async function updateNotesClicked() {
        match.notes = notes;
        try {
            let updated = await updateMatch(match);
            if (updated) {
                updateUserActionsCheckList({ notesAdded: true });

                setToastTitle(t("fixture.FixtureSummaryNotes.notesUpdated"));
                setShowToast(true);
            }
        } catch (err) {
            setToastTitle(t("fixture.FixtureSummaryNotes.failedUpdating"));
            setShowToast(true);
            console.log("error trying to update earnings", err);
        }
    }

    return (
        <ScrollableContentWithAds>
            <form onSubmit={handleSubmit(updateNotesClicked)}>
                <IonCard
                    className="ion-card-transparent"
                    data-testid="fixture-segment-notes"
                >
                    <IonCol>
                        <IonItem className="rounded-corners" lines="none">
                            <IonTextarea
                                data-testid={"notes-textarea"}
                                {...register("notes")}
                                className="notes-area"
                                autoCapitalize="on"
                                placeholder={t("general.notes")}
                                onIonChange={(e) =>
                                    e.detail.value! && setNotes(e.detail.value!)
                                }
                            />
                        </IonItem>
                    </IonCol>
                    <IonButton
                        data-testid="notesSaveBtn"
                        expand="block"
                        color="light"
                        type="submit"
                    >
                        {t("general.save")}
                    </IonButton>

                    <ToastComponent
                        isOpen={showToast}
                        position="bottom"
                        message={toastTitle}
                        onDismiss={() => setShowToast(false)}
                    />
                </IonCard>
            </form>
        </ScrollableContentWithAds>
    );
};
