import {
    makeInsertId,
    prepareMatchCreatedProperties,
    prepareMatchFinishedProperties,
} from "refsix-core";
import { MatchPhone } from "refsix-js-models";
import request from "../request";
import { getLanguageCode, getLocale } from "../../utils/localeUtils";
import MixpanelUserId from "./mixpanelUserId";
import { UserProfile } from "../../models/userProfile";
import {
    checkIfBasic,
    checkIfBeenBasic,
    checkIfBeenPro,
    currentProvider,
    getSubscriptionStatus,
} from "../subscriptionService";
import { getCurrentInAppVersion } from "../../components/hooks/appVersionHooks";
import { store } from "../../redux/store";

const MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL_KEY;

// TODO distinct id - ensure we identify the correct user and join pre-login with post login.
// TODO watch if user is PRO and update the super property

/**
 * All exported functions are prefixed with mp for mixpanel to differentiate them from the platform-agnostic equivalents
 * we should use.
 */
let isActive = false;
const isAvailable = () => window.mixpanel && isActive;

export const mpEnable = () => (isActive = true);
export const mpDisable = () => (isActive = false);

export async function mpInit() {
    if (window.mixpanel) {
        mpEnable();
        console.log("Init Mixpanel with key " + MIXPANEL_KEY);
        window.mixpanel.init(
            MIXPANEL_KEY,
            function () {},
            function (error: any) {
                console.error("Mixpanel init failed", error);
            }
        );
        new MixpanelUserId();
        mpSetUserLocale();

        mpRegisterSuperProperties({
            $app_version_string: await getCurrentInAppVersion(),
        });
    } else {
        console.log("Mixpanel not available");
    }
}
const _onSuccess = () => {};

const _onError = () => {};

const _importHistoricalEvent = function (
    username: string,
    eventName: string,
    properties: any
) {
    console.log("Tracking historical data ", properties, eventName, username);
    request
        .post("/mixpanel/import/" + username, {
            properties: properties,
            eventName: eventName,
        })
        .then(function () {
            console.log("Successfully imported event");
        })
        .catch(function (error) {
            console.log("Something went wrong", error);
        });
};

export async function mpUpdatePerson(properties: any) {
    const username = store.getState().auth?.session?.user_id;
    if (isAvailable() && username) {
        // the library is not working
        //window.mixpanel.people.set(properties, _onSuccess, _onError);

        console.log("Updating person", properties);
        // call refauth instead
        try{
            await request.post("/mixpanel/update-person/" + username, {
                properties: properties,
            });

        } catch(e){
            console.log("Error updating person", e);
        }
    } else {
        console.log("Mixpanel unavailable");
    }
}

export function mpUpdatePersonOnce(properties: any) {
    if (isAvailable()) {
        window.mixpanel.people.setOnce(properties, _onSuccess, _onError);
    } else {
        console.log("Mixpanel unavailable");
    }
}

/**
 * Track an event in mixpanel. Do not do this directly, instead call `trackEvent` from analyticsService.ts
 * @param eventName The name of the event e.g. MatchFinished
 * @param payload Any object of properties to be tracked
 */
export function mpTrackEvent(eventName: string, payload: any) {
    payload = payload || {};
    if (isAvailable()) {
        window.mixpanel.track(eventName, payload, _onSuccess, _onError);
    } else {
        console.log("Mixpanel not present - not tracking " + eventName, payload);
    }
}

export function mpTrackHistoricalMatchCreated(
    match: MatchPhone,
    time: number,
    username: string
) {
    let matchEvent = prepareMatchCreatedProperties(match, "watch");
    matchEvent.distinct_id = username;
    matchEvent.time = parseInt("" + time / 1000);
    matchEvent.$insert_id = makeInsertId("MC", match._id as string);

    _importHistoricalEvent(username, "MatchCreated", matchEvent);
}

export function mpTrackHistoricalMatchFinished(
    match: MatchPhone,
    time: number,
    username: string
) {
    let matchEvent = prepareMatchFinishedProperties(match, "bluetooth");
    matchEvent.distinct_id = username;
    matchEvent.time = parseInt("" + time / 1000);
    matchEvent.$insert_id = makeInsertId("MF", match._id as string);

    _importHistoricalEvent(username, "MatchFinished", matchEvent);
}

// these properties will be added to all events
export function mpRegisterSuperProperties(properties: any) {
    if (window.mixpanel && window.mixpanel.register) {
        // browser
        window.mixpanel.register(properties);
    } else if (window.mixpanel && window.mixpanel.registerSuperProperties) {
        window.mixpanel.registerSuperProperties(
            properties,
            _onSuccess,
            _onError
        );
    }
}

// This should only be called on user login
export function mpSetIdentity(distinctId: string) {
    if (isAvailable()) {
        window.mixpanel.identify(distinctId);
    } else {
        console.log("Mixpanel unavailable");
    }
}

// TODO add in iOS search ads when that feature is required
/**
 * Read the iOS Search Ad attribution and set it once on the mixpanel user profile.
 */
// export function setSearchAdAttribution() {
//     // @ts-ignore
//     if (window.SearchAds) {
//         const searchAds = new window.SearchAds();
//         searchAds.initialize(function (attribution) {
//             if (typeof attribution === 'object') {
//                 self.updatePersonOnce(attribution);
//             }
//         }, function (error) {
//         });
//     }
// };

export function mpSetUserLocale() {
    if (getLocale() && getLanguageCode())
        mpUpdatePerson({
            locale: getLocale(),
            language: getLanguageCode(),
        });
}

export function mpCreateUserProfile(customer: UserProfile, isPro: boolean) {
    const userData = {
        $email: customer.email,
        $first_name: customer.firstName,
        $last_name: customer.lastName,
        $name: `${customer.firstName} ${customer.lastName}`,
        username: customer.username,
        $onesignal_user_id: customer.username,
        $country_code: customer.country,
        countryCode: customer.country,
        nationalFA: customer.nationalFA || "undefined",
        localFA: customer.localFA || "undefined",
        isPro: isPro,
        subscriptionStatus: getSubscriptionStatus(),
        beenPro: checkIfBeenPro(),
        isBasic: checkIfBasic(store.getState().subscriptions.subscriptions),
        beenBasic: checkIfBeenBasic(store.getState().subscriptions),
        currentProvider: currentProvider(),
        resultCount: 0,
        fixtureCount: 0,
        refereeLevel: customer.refereeLevel || "undefined",
        refereeClub: customer.refereeClub || "undefined",
    };
    console.log("mpCreateUserProfile");
    mpUpdatePerson(userData);
}

export function mpUpdateUserProfile(customer: UserProfile) {
    const userData = {
        $first_name: customer.firstName,
        $last_name: customer.lastName,
        $name: `${customer.firstName} ${customer.lastName}`,
        $email: customer.email,
        refereeLevel: customer.refereeLevel,
        dateOfBirth: customer.dob,
        $country_code: customer.country,
        countryCode: customer.country,
        nationalFA: customer.nationalFA || "undefined",
        localFA: customer.localFA || "undefined",
        height: customer.biometrics ? customer.biometrics.height : undefined,
        weight: customer.biometrics ? customer.biometrics.weight : undefined,
        gender: customer.gender,
        refereeClub: customer.refereeClub || "undefined",
    };
    mpUpdatePerson(userData);
}
