import { SinBinSystem } from "./enums/sinBinSystem";
import { Stats } from "./stats";
import { OfficialRole } from "./enums/officialRole";
import { IEventMap } from "./interfaces/eventMap";
import { ITeamPlayerMap } from "./interfaces/teamPlayerMap";
import { EventName } from "./enums/eventName";
import { GoalEvent } from "./matchEvents/goalEvent";
import { SubstitutionEvent } from "./matchEvents/substitutionEvent";
import { PenaltyShotEvent } from "./matchEvents/penaltyShotEvent";
import { PenaltiesEvent } from "./matchEvents/penaltiesEvent";
import { HalfEvent } from "./matchEvents/halfEvent";
import { IncidentEvent } from "./matchEvents/incidentEvent";
import { Player } from "./player";
import { Timings } from "./timings";
import { Earnings } from "./earnings";
import { MatchOfficials } from "./matchOfficials";
import { Integration } from "./integration";
import { Wellbeing } from "./Wellbeing";
import { Reason } from "./reason";
import { Device } from "./device";
import { TeamSheetRequests } from "./teamSheetRequests";
import { AbandonEvent } from "./matchEvents/abandonEvent";
import { PlayerOfTheMatch } from "./playerOfTheMatch";
import { TeamBehavior } from "./teamBehavior";

/**
 * This class is the Phone's representation of a match which differs from the watch because
 * of Italian history.
 */
export class MatchPhone {
    _id?: string;
    _rev?: string;
    _deleted?: boolean;
    _conflicts?: string[];

    refsixUpdateCount?: number;
    refsixCount?: number;

    date: string; // ISO time string
    dateLocalisedString?: string; //  Introduced for Assignr integration, REFSIX-190: concatenate “localized_date  localized_time". We will show this in the fixture/result list and when the match is opened, but internally we will use the date
    officialRole?: OfficialRole;
    competition?: string;
    venue?: string;
    homeTeam: string;
    homeTeamShort?: string;
    homeColor?: string;
    awayTeam: string;
    awayTeamShort?: string;
    awayColor?: string;

    timings?: Timings;
    periodsNo?: string;
    withGoalScorers?: boolean;
    sinBinSystem?: SinBinSystem;
    misconductCodeId?: string;
    customMisconductCodes?: Reason[] | undefined;
    teamSize?: number;
    subsNo?: number;
    players?: ITeamPlayerMap; // Mandatory not really optional

    matchFinished: boolean = false;
    matchInProgress?: boolean = false;
    eventsProcessed: boolean = false;
    matchEvents?: IEventMap = {}; // map of events with timestamp as key
    stats?: Stats;
    results?: any; // Deprecated
    device?: Device = { model: "", os: { name: "" } };
    appVersion?: string;

    extraTimeAvailable?: boolean;
    penaltiesAvailable?: boolean;

    playedExtraTime?: boolean;
    playedPenalties?: boolean;

    hasTracking?: boolean;
    hasHeartRate?: boolean;

    earnings?: Earnings;
    notes?: string;
    keywords?: string[];

    matchOfficials?: MatchOfficials;

    matchAbandoned?: boolean;

    integration?: Integration;
    // TODO make concrete object.
    assessment?: any;
    wellbeing?: Wellbeing;

    teamSheetRequests?: TeamSheetRequests;

    // Integrations might give us a match the user needs to accept
    pendingAcceptance?: boolean;

    // e.g. "Europe/London"
    timezone?: string;
    source?: string;

    playerOfTheMatch?: PlayerOfTheMatch;
    teamBehavior?: TeamBehavior;

    constructor(
        id: string,
        rev: string,
        refsixUpdateCount: number,
        refsixCount: number,
        date: string,
        officialRole: OfficialRole,
        competition: string,
        venue: string,
        homeTeam: string,
        homeTeamShort: string,
        homeColor: string,
        awayTeam: string,
        awayTeamShort: string,
        awayColor: string,
        timings: Timings | undefined,
        periodsNo: string,
        withGoalScorers: boolean,
        sinBinSystem: SinBinSystem,
        misconductCodeId: string,
        teamSize: number,
        subsNo: number,
        players: ITeamPlayerMap,
        matchFinished: boolean,
        eventsProcessed: boolean,
        matchEvents: IEventMap,
        stats: Stats | undefined,
        results: any,
        device: any,
        appVersion: string,
        extraTimeAvailable: boolean,
        penaltiesAvailable: boolean,
        playedExtraTime: boolean,
        playedPenalties: boolean,
        hasTracking: boolean,
        hasHeartRate: boolean,
        earnings: Earnings,
        notes: string,
        matchOfficials?: MatchOfficials,
        integration?: Integration,
        keywords?: string[],
        assessment?: any,
        wellbeing?: Wellbeing,
        customMisconductCodes?: Reason[],
        timezone?: string,
        matchAbandoned?: boolean,
        teamSheetRequests?: TeamSheetRequests,
        pendingAcceptance?: boolean,
        matchInProgress?: boolean,
        source?: string,
        playerOfTheMatch?: PlayerOfTheMatch,
        teamBehavior?: TeamBehavior
    ) {
        this._id = id;
        this._rev = rev;
        this.refsixUpdateCount = refsixUpdateCount;
        this.refsixCount = refsixCount;
        this.date = date;
        this.officialRole = officialRole;
        this.competition = competition;
        this.venue = venue;
        this.homeTeam = homeTeam;
        this.homeTeamShort = homeTeamShort;
        this.homeColor = homeColor;
        this.awayTeam = awayTeam;
        this.awayTeamShort = awayTeamShort;
        this.awayColor = awayColor;
        this.timings = timings;
        this.periodsNo = periodsNo;
        this.withGoalScorers = withGoalScorers;
        this.sinBinSystem = sinBinSystem;
        this.misconductCodeId = misconductCodeId;
        this.teamSize = teamSize;
        this.subsNo = subsNo;
        this.players = players;
        this.matchFinished = matchFinished;
        this.eventsProcessed = eventsProcessed;
        this.matchEvents = matchEvents;
        this.stats = stats;
        this.results = results;
        this.device = device;
        this.appVersion = appVersion;
        this.extraTimeAvailable = extraTimeAvailable;
        this.penaltiesAvailable = penaltiesAvailable;
        this.playedExtraTime = playedExtraTime;
        this.playedPenalties = playedPenalties;
        this.hasTracking = hasTracking;
        this.hasHeartRate = hasHeartRate;
        this.earnings = earnings;
        this.notes = notes;
        this.matchOfficials = matchOfficials;
        this.integration = integration;
        this.keywords = keywords;
        this.assessment = assessment;
        this.wellbeing = wellbeing;
        this.customMisconductCodes = customMisconductCodes;
        this.timezone = timezone;
        this.teamSheetRequests = teamSheetRequests;
        this.pendingAcceptance = pendingAcceptance;
        this.matchInProgress = matchInProgress;
        this.matchAbandoned = matchAbandoned;
        this.source = source;
        this.playerOfTheMatch = playerOfTheMatch;
        this.teamBehavior = teamBehavior;
    }

    static fromJSON(json: any): MatchPhone {
        let newEvents: IEventMap = {};
        json.matchEvents &&
            Object.keys(json.matchEvents).map(function (key: any) {
                switch (json.matchEvents[key].eventName) {
                    case EventName.goal:
                        newEvents[key] = GoalEvent.fromJSON(
                            json.matchEvents[key]
                        );
                        break;
                    case EventName.substitution:
                        newEvents[key] = SubstitutionEvent.fromJSON(
                            json.matchEvents[key]
                        );
                        break;
                    case EventName.penaltyShot:
                        newEvents[key] = PenaltyShotEvent.fromJSON(
                            json.matchEvents[key]
                        );
                        break;
                    case EventName.penalties:
                        newEvents[key] = PenaltiesEvent.fromJSON(
                            json.matchEvents[key]
                        );
                        break;
                    case EventName.half:
                        newEvents[key] = HalfEvent.fromJSON(
                            json.matchEvents[key]
                        );
                        break;
                    case EventName.incident:
                        newEvents[key] = IncidentEvent.fromJSON(
                            json.matchEvents[key]
                        );
                        break;
                    case EventName.abandoned:
                        newEvents[key] = AbandonEvent.fromJSON(
                            json.matchEvents[key]
                        );
                        break;
                }
            });

        let newPlayers: ITeamPlayerMap = {};
        json.players &&
            Object.keys(json.players).map(function (team: any) {
                newPlayers[team] = json.players[team].map(function (
                    player: any
                ) {
                    return Player.fromJSON(player);
                });
            });

        let timings = json.timings ? Timings.fromJSON(json.timings) : undefined;

        let matchOfficials = json.matchOfficials
            ? MatchOfficials.fromJSON(json.matchOfficials)
            : undefined;

        let integration = json.integration
            ? Integration.fromJSON(json.integration)
            : undefined;

        let wellbeing = json.wellbeing
            ? Wellbeing.fromJSON(json.wellbeing)
            : undefined;

        let customCodes = undefined;
        if (Array.isArray(json.customMisconductCodes)) {
            customCodes = json.customMisconductCodes.map(function (
                reasonJson: any
            ) {
                return Reason.fromJSON(reasonJson);
            });
        }

        return {
            ...json,
            timings,
            players: newPlayers,
            matchEvents: newEvents,
            matchOfficials,
            integration,
            wellbeing,
            customMisconductCodes: customCodes,
        } as MatchPhone;
    }
}
