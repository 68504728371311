import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonItem,
    IonPage,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { HeaderComponent } from "../../components";
import { OnBoardingWatchRender } from "./onBoardingWatchRender";
import AppleImage from "../../assets/images/watches/watchOS.png";
import GoogleImage from "../../assets/images/watches/wearOS.png";
import SamsungImage from "../../assets/images/watches/tizen.png";
import GarminImage from "../../assets/images/watches/garmin.png";
import "./onBoarding.css";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../route/constants";
import { OnBoardingHeadingComponent } from "./onBoardingHeadingComponent";
import { useUsername } from "../../components/hooks";
import { setShowOnBoardingFlow } from "../../redux/actions/appStatus";

export const OnboardingWatchFound: FC = () => {
    const { t } = useTranslation();
    const { watchPlatform } = useParams<{ watchPlatform: string }>();
    const watchStatus = useSelector((state: RootState) => state.watchStatus);
    const settings = useSelector((state: RootState) => state.settings.settings);
    const isApple = watchPlatform === "apple";
    const route = useIonRouter();
    const username = useUsername();
    const dispatch = useDispatch();

    const handleNextButtonClicked = () => {
        if (isApple) {
            route.push(
                routes.refsixOnBoardingShareLocationData,
                "forward",
                "replace"
            );
        } else {
            dispatch(setShowOnBoardingFlow(false));
            return route.push(
                routes.refsixOnBoardingSetupComplete,
                "root",
                "replace"
            );
        }
    };

    const hasWebWatch = () => {
        return settings.watches && settings.watches.length > 0;
    };

    const getImage = (makeOrPlatform: string) => {
        switch (makeOrPlatform.toLowerCase()) {
            case "watchos":
            case "apple":
                return AppleImage;
            case "tizen":
                return SamsungImage;
            case "garmin":
                return GarminImage;
            case "samsung":
            case "wearos":
                return GoogleImage;
            default:
                return GoogleImage;
        }
    };

    const getWatchText = () => {
        if (watchStatus.isPaired && watchStatus.model) {
            return watchStatus.model;
        } else if (settings.watches && settings.watches.length > 0) {
            const watch = settings.watches[settings.watches.length - 1];
            return watch.make ? `${watch.make} ${watch.model}` : watch.model;
        }
        return "";
    };

    return (
        <IonPage>
            <HeaderComponent
                title={t("help.onBoarding.step0a")}
                titleTestId="onboarding-watch-found"
            />
            <IonContent>
                <IonCard color="transparent">
                    <OnBoardingHeadingComponent
                        progressValue={0.2}
                        upperTitleInBold={t("help.onBoarding.foundWatch")}
                    />

                    <IonCard>
                        <IonItem lines="none">
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "10px 0",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div>
                                            <img
                                                src={getImage(
                                                    watchStatus.make ||
                                                        watchPlatform
                                                )}
                                                height={15}
                                                alt="watch image"
                                            />
                                        </div>

                                        <IonText
                                            className="text-small"
                                            hidden={!getWatchText()}
                                        >
                                            {getWatchText()}
                                        </IonText>
                                    </div>
                                </div>
                                <div>
                                    <IonText color="primary">
                                        {t(
                                            "help.onBoarding.paired"
                                        ).toUpperCase()}
                                    </IonText>
                                </div>
                            </div>
                        </IonItem>
                    </IonCard>

                    {hasWebWatch() && (
                        <p style={{ fontSize: 16 }}>
                            {" "}
                            {t("settings.WatchSettings.success", {
                                model: getWatchText(),
                            })}
                        </p>
                    )}

                    <OnBoardingWatchRender
                        imageSrc={getImage(watchStatus.make || watchPlatform)}
                    />
                </IonCard>
            </IonContent>
            <IonFooter>
                <IonButton
                    expand="block"
                    onClick={() => handleNextButtonClicked()}
                    data-testid="onboarding-watch-found-next-button"
                >
                    {t("general.next")}
                </IonButton>
            </IonFooter>
        </IonPage>
    );
};
