import { SET_WATCH_STATUS } from "./types";
import { WatchBluetoothStatus } from "refsix-js-models";

export const setWatchStatus = (watchStatus: WatchBluetoothStatus) => ({
    type: SET_WATCH_STATUS,
    watchStatus,
});

export const setWatchStatusSupported = (supported: boolean) => ({
    type: SET_WATCH_STATUS,
    supported,
});
