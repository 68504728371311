import FuseResult = Fuse.FuseResult;
import { UseList } from "./types";
import { useTranslation } from "react-i18next";
import _, { filter, get, map } from "lodash/fp";
import { useVenues } from "../match";
import React, { useCallback, useMemo } from "react";
import Fuse from "fuse.js";
import { IonItem } from "@ionic/react";

export const useVenueList: () => UseList<string[]> = () => {
    const { t } = useTranslation();
    const getResultsFromFuse = map<FuseResult<string>, string>(get("item"));

    const venues = useVenues();

    const fuse = useMemo(
        () =>
            new Fuse<string>(venues, {
                threshold: 0.2,
                findAllMatches: false,
                ignoreLocation: true,
                shouldSort: true,
            }),

        [venues]
    );

    const cb = useCallback(
        (query: string = "") => {
            if (query === "") {
                return venues;
            }
            const res = getResultsFromFuse(fuse?.search(query, { limit: 50 }));
            const removeExactMatch: (l: string[]) => string[] = filter(
                (x) => x !== query
            );
            return removeExactMatch(res);
        },
        [fuse, getResultsFromFuse, venues]
    );

    return useMemo(
        () => ({
            search: cb,
            renderer: venueListRenderer(t),
        }),
        [cb, t]
    );
};
export const venueListRenderer =
    (t: any) => (results: string[], save: (v: string) => void) => {
        const orderByValue = _.orderBy<string>((x) => x, ["asc"]);
        if (results.length === 0) {
            return <p>NONE</p>;
        }
        return orderByValue(results).map((result, index) => {
            return (
                <IonItem
                    key={`${index}-${result}`}
                    onClick={() => save(result)}
                >
                    {result}
                </IonItem>
            );
        });
    };
