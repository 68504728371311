import { FC, useEffect, useRef, useState } from "react";
import ScrollableContent from "./ScrollableContents";
import { UpgradeBanner } from "./upgrade/UpgradeBanner";
import { useShowBannerAd } from "./hooks/adHooks";

const ScrollableContentWithAds: FC = ({ children }) => {
    const [bottomOffset, setBottomOffset] = useState<number>(0);

    const showBannerAd = useShowBannerAd();

    const bannerEl = useRef<HTMLDivElement | null>(null);

    const recalculateBottomOffset = () => {
        const adHeight = bannerEl.current?.getBoundingClientRect().height;
        console.log("adHeight", adHeight);
        if (adHeight !== bottomOffset) {
            setBottomOffset(adHeight ? adHeight + 5 : 0);
        }
    };

    useEffect(() => {
        recalculateBottomOffset();
    }, [children, showBannerAd, bannerEl]);

    return (
        <>
            <ScrollableContent bottomOffset={showBannerAd ? bottomOffset : 0}>
                {children}
            </ScrollableContent>
            <div ref={bannerEl}>
                <UpgradeBanner />
            </div>
        </>
    );
};

export default ScrollableContentWithAds;
