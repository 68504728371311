import { Match, MatchPhone, UserActionsCheckList } from "refsix-js-models";
import {
    prepareBasicMatchProperties,
    prepareMatchCreatedProperties,
} from "refsix-core";
import {
    mpTrackEvent,
    mpTrackHistoricalMatchFinished,
    mpUpdatePerson,
} from "./mixpanelService";
import * as Sentry from "@sentry/react";
import OneSignal from "onesignal-cordova-plugin";
import { isAndroid, isIos } from "../platformDetection";
import { routes } from "../../route/constants";
import { updateUserActionsCheckList } from "../userActionsCheckListService";
import { store } from "../../redux/store";

/**
 * Generic trackEvent function which can send the event through to multiple analytics platforms if required.
 * @param eventName The name of the event e.g. MatchFinished
 * @param payload Any object of properties to be tracked
 */
export function trackEvent(eventName: string, payload: any) {
    oneSignalTrigger(eventName, "");
    trackUserActionsCheckList(eventName);
    mpTrackEvent(eventName, payload);
}

export function oneSignalTrigger(eventName: string, payload: any) {
    const isOneSignalAvailable = isIos() || isAndroid();
    if (eventName === "Page Viewed") {
        const page = payload.page;
        const pageViewTriggers: { [key: string]: string } = {
            [routes.refsixTrends]: "viewedTrends",
            [routes.refsixSubscriptionCancel]: "viewedCancelSubscription",
            [routes.upgrade + "/pro"]: "viewedUpgradeToPro",
            [routes.upgrade + "/plus"]: "viewedUpgradeToPlus",
        };

        const pageViewTrigger = pageViewTriggers[page];

        if (pageViewTrigger && isOneSignalAvailable) {
            OneSignal.InAppMessages.addTrigger(pageViewTrigger, "true");
        }
    } else if (isOneSignalAvailable) {
        OneSignal.InAppMessages.addTrigger(eventName, JSON.stringify(payload));
    }
}

export async function trackHistoricalMatchFinished(
    username: string,
    updatedMatch: MatchPhone,
    watchMatch: Match
) {
    // always use this API for match finished so that we
    mpTrackHistoricalMatchFinished(
        updatedMatch,
        watchMatch.matchFinishedTs || Date.now(),
        username
    );
    oneSignalTrigger("HistoricalMatchFinished", {
        matchId: updatedMatch._id,
        matchFinishedTs: watchMatch.matchFinishedTs || Date.now(),
        username: username,
    });
}

export function trackUserActionsCheckList(eventName: string) {
    const userActionsCheckList = store.getState().userActionsCheckList;
    if (
        eventName === "AddIntegrationSuccess" &&
        !userActionsCheckList.AddIntegrationSuccess
    ) {
        updateUserActionsCheckList({ AddIntegrationSuccess: true });
    } else if (
        eventName === "templateCreated" ||
        eventName === "timerOptionsChanged"
    ) {
        updateUserActionsCheckList({ [eventName]: true });
    } else if (eventName === "ResultAdded") {
        if (!userActionsCheckList.resultAddedCount) {
            updateUserActionsCheckList({ resultAddedCount: 1 });
        } else {
            updateUserActionsCheckList({
                resultAddedCount: userActionsCheckList.resultAddedCount + 1,
            });
        }
    } else if (eventName === "shareLocationData") {
        updateUserActionsCheckList({ shareLocationData: true });
    } else if (eventName === "shareHealthData") {
        updateUserActionsCheckList({ shareHealthData: true });
    }
}

export function processUserActionsCheckListForMatchFinished(
    match: MatchPhone,
    userActionsCheckList: UserActionsCheckList,
    updatedUserActionsCheckList: UserActionsCheckList,
    newMatch: boolean
) {
    const {
        createdMatchOnPhone,
        matchCreatedCountPhone,
        financeAdded,
        matchOfficialsAdded,
        notesAdded,
        tagsAdded,
    } = userActionsCheckList;

    if (!createdMatchOnPhone) {
        updatedUserActionsCheckList.createdMatchOnPhone = true;
        updatedUserActionsCheckList.matchCreatedCountPhone = 1;
    } else if (newMatch && matchCreatedCountPhone) {
        updatedUserActionsCheckList.matchCreatedCountPhone =
            matchCreatedCountPhone + 1;
    }

    if (
        match &&
        !financeAdded &&
        ((match.earnings?.fees && !isNaN(match.earnings?.fees)) ||
            (match.earnings?.expenses && !isNaN(match.earnings?.expenses)))
    ) {
        updatedUserActionsCheckList.financeAdded = true;
    }

    if (
        match &&
        !matchOfficialsAdded &&
        match.matchOfficials &&
        Object.values(match.matchOfficials).some((prop) => prop !== undefined)
    ) {
        updatedUserActionsCheckList.matchOfficialsAdded = true;
    }

    if (match && match.notes && !notesAdded) {
        updatedUserActionsCheckList.notesAdded = true;
    }

    if (match && !tagsAdded && match.keywords && match.keywords.length > 0) {
        updatedUserActionsCheckList.tagsAdded = true;
    }

    return updatedUserActionsCheckList;
}

const extractMatchIdFromPath = (path: string, route: string): string | null => {
    const matchIdMatch = path.match(new RegExp(`/${route}/([^/]+)`));
    return matchIdMatch ? matchIdMatch[1] : null;
};

export function trackPageView(path: string) {
    const userActionsCheckList = store.getState().userActionsCheckList;
    let matchId = undefined;

    let routeToBeIncluded = "";

    const routesToCheck = [
        routes.match,
        routes.refsixRequestTeamSheet,
        routes.refsixResultsAddEvent,
        routes.refsixAddEditTeamSheet,
        routes.createMatch,
        routes.startWatchPhone,
    ];

    for (const route of routesToCheck) {
        if (path.includes(route)) {
            routeToBeIncluded = route.replace("/", "");
            break;
        }
    }

    if (routeToBeIncluded !== "" && path.includes(routeToBeIncluded)) {
        const extractedMatchId = extractMatchIdFromPath(
            path,
            routeToBeIncluded
        );
        if (extractedMatchId) {
            const match = store
                .getState()
                .matches.matches.find(
                    (match) => match._id === extractedMatchId
                );

            if (match) {
                matchId = match._id;
                path = path.replace(extractedMatchId, "match-id");
            }
        }
    }
    const pathActionMap: Record<string, string> = {
        [routes.refsixTrends + "/matches"]: "trends",
        [routes.refsixFixturesFilter]: "fixturesFilters",
        [routes.refsixResultsFilters]: "resultsFilters",
        [routes.refsixTrendsFilter]: "trendsFilters",
        [routes.refsixMoreSettingsEditCustomMisconductCodes]: "customCodes",
        [routes.refsixMoreSettingsMisconductCodes]: "misconductCodes",
        [routes.refsixSubscriptionCancel + "/refsix"]: "cancelSubscription",
        [routes.upgrade + "/pro"]: "upgradeToPro",
        [routes.upgrade + "/plus"]: "upgradeToPlus",
        [`${routes.match}/${matchId}/stats`]: "resultsStats",
        [`${routes.match}/${matchId}/match-log`]: "resultsMatchLog",
        [routes.refsixMoreSettingsTemplates]: "templates",
        [routes.refsixMoreSettingsTemplatesTemplate]: "templatesAdd",
        [routes.refsixTimerOptions]: "timerOptions",
        [`${routes.refsixRequestTeamSheet}/${matchId}`]: "requestTeamSheet",
    };

    const userActionCategory = pathActionMap[path];

    if (
        userActionCategory &&
        !userActionsCheckList[`${userActionCategory}Viewed`]
    ) {
        updateUserActionsCheckList({ [`${userActionCategory}Viewed`]: true });
    }

    trackEvent("Page Viewed", { page: path, matchId: matchId });
}

export function trackConflictResolve(
    conflictType: string,
    matchId: string,
    revision: string,
    mergeTrigger: string,
    matchFinished: boolean
) {
    Sentry.addBreadcrumb({
        category: "matchConflict",
        message: `Conflict resolved type: ${conflictType} trigger ${mergeTrigger} id ${matchId} rev ${revision} finished ${matchFinished}`,
    });
    trackEvent("MergedMatchConflict", {
        mergedBy: "phone",
        conflictType: conflictType,
        matchId: matchId,
        matchRev: revision,
        mergeTrigger: mergeTrigger,
        matchFinished: matchFinished,
    });
}

export function resultAdded(match: MatchPhone) {
    const matchEvent = prepareBasicMatchProperties(match);
    matchEvent.hasGPS = false;
    matchEvent.hasHR = false;
    trackEvent("ResultAdded", matchEvent);
}

function triggeredFromToString(triggeredFrom: string) {
    switch (triggeredFrom) {
        case "0":
            return "heatMap";
        case "1":
            return "sprintMap";
        case "2":
            return "cardMap";
        case "3":
            return "recalibrate";
        case "4":
            return "compare";
        default:
            return "other";
    }
}

export function calibrationStarted(triggeredFrom: string, match: MatchPhone) {
    const matchEvent = prepareBasicMatchProperties(match);
    matchEvent.hasGPS = match.hasTracking;
    matchEvent.hasHR = match.hasHeartRate;
    matchEvent.calibrationTrigger = triggeredFrom;
    trackEvent("CalibrationStarted", {
        calibrationSource: triggeredFromToString(triggeredFrom),
    });
}

export function calibrationCompleted(triggeredFrom: string, match: MatchPhone) {
    const matchEvent = prepareBasicMatchProperties(match);
    matchEvent.hasGPS = match.hasTracking;
    matchEvent.hasHR = match.hasHeartRate;
    matchEvent.calibrationTrigger = triggeredFrom;
    if (!store.getState().userActionsCheckList.hasCalibrated) {
        updateUserActionsCheckList({ hasCalibrated: true });
    }
    trackEvent("CalibrationCompleted", matchEvent);
}

// export function trackMatchFinished(match: MatchPhone) {
//     const matchEvent = prepareMatchFinishedProperties(match, "bluetooth");
//     trackEvent("MatchFinished", matchEvent);
// }

export function openShareScreen(
    shareHasGPS: boolean,
    shareHasHR: boolean,
    shareHasHeatmap: boolean,
    shareIsPro: boolean
) {
    const payload = {
        shareHasGPS: shareHasGPS,
        shareHasHR: shareHasHR,
        shareHasHeatmap: shareHasHeatmap,
        shareIsPro: shareIsPro,
    };
    trackEvent("OpenShareScreen", payload);
}

export function sharedMatch(
    shareHasGPS: boolean,
    shareHasHR: boolean,
    shareHasHeatmap: boolean,
    shareIsPro: boolean,
    shareScreenChosen: boolean
) {
    const payload = {
        shareHasGPS: shareHasGPS,
        shareHasHR: shareHasHR,
        shareHasHeatmap: shareHasHeatmap,
        shareIsPro: shareIsPro,
        shareScreenChosen: shareScreenChosen,
        category: shareScreenChosen,
    };
    trackEvent("SharedMatch", payload);
}

export function sharedMatchEmail(
    sentToSelf: boolean,
    competition: string,
    matchId: string
) {
    const payload = {
        sentToSelf: sentToSelf,
        competition: competition,
        matchId: matchId,
    };
    trackEvent("SharedMatchEmail", payload);
}

export function trackMatchCreated(match: MatchPhone) {
    trackEvent("MatchCreated", prepareMatchCreatedProperties(match, "phone"));
}

export function trackOnboardingStart(
    watchPlatform: string,
    triggeredFrom: string
) {
    trackEvent("OnboardingStart", {
        watchPlatform: watchPlatform,
        triggeredFrom: triggeredFrom,
        category: watchPlatform,
    });
}

export function trackOnboardingAbort(
    watchPlatform: string,
    step: number,
    triggeredFrom: string,
    videoStep: number
) {
    trackEvent("OnboardingAbort", {
        watchPlatform: watchPlatform,
        step: step,
        videoStep: videoStep,
        triggeredFrom: triggeredFrom,
        category: watchPlatform,
    });
}

export function trackOnboardingCompleted(
    watchPlatform: string,
    triggeredFrom: string
) {
    trackEvent("OnboardingCompleted", {
        watchPlatform: watchPlatform,
        triggeredFrom: triggeredFrom,
        category: watchPlatform,
    });
}

export function trackRegistration(provider = "local") {
    let eventData = { category: "", label: "", authProvider: provider};

    // GA STUFF
    eventData.category = "register";
    eventData.label = "register";

    trackEvent("register", eventData);
}

export function trackProTeaser(teaserName: string, subscriptionType?: string) {
    const properties = {
        category: teaserName,
        eventType: "click",
        subscriptionType: subscriptionType,
    };
    trackEvent("proTeaser", properties);
}

export function trackUserActionsCheckListUpdated(
    userActionCheckList: UserActionsCheckList
) {
    // Add prefix xp_ to all properties in a new object
    const properties: { [key: string]: any } = {};
    for (const key in userActionCheckList) {
        if (userActionCheckList.hasOwnProperty(key)) {
            properties["xp_" + key] = userActionCheckList[key];
        }
    }
    mpUpdatePerson(properties);

    if (isIos() && isAndroid()) {
        OneSignal.User.addTags(properties);
    }
}
