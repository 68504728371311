import { store } from "../../redux/store";
import { updateSettings } from "../settingsService";
import { watchModel } from "../../models/watchModel";
import * as semver from "semver";

export async function removeWatchDetails(sessionId: string) {
    let prev = "none";
    const settings = { ...store.getState().settings };

    settings.settings.watches = settings.settings.watches.filter((watch) => {
        if (watch.sessionId !== sessionId) {
            prev = watch.watchOS;
        }
        return watch.sessionId !== sessionId;
    });
    settings.settings.pairedWatch = prev;

    await updateSettings(settings);
}

export async function addWatchDetails(watchDetails: watchModel) {
    const settings = { ...store.getState().settings };
    settings.settings.watches = [...settings.settings.watches, watchDetails];
    await updateSettings(settings);
}
export const isWatchSystemB2024Compatible = (watchVersion: string) => {
    return !!(semver.valid(watchVersion) && semver.gte(watchVersion, "6.9.0"));
}
