import { ConnectionStatus, Network } from "@capacitor/network";

let networkStatus: ConnectionStatus | undefined = undefined;

export function initConnectionListener() {
    Network.addListener("networkStatusChange", (status) => {
        networkStatus = status;
        console.log("Network status changed", status);
    });
}

export function isOnline() {
    return networkStatus ? networkStatus.connected : true; // assume online if we don't have access to the network plugin
}
