import React, { FC, useContext, useEffect, useState } from "react";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRadioGroup,
    IonText,
    IonTitle,
    IonToolbar,
    useIonLoading,
} from "@ionic/react";
import { filter, first } from "lodash";
import "./goPro.css";
import proHeaderImage from "../assets/images/go-pro/REFSIXPRO-Box.png";
import { useTranslation } from "react-i18next";
import { InAppPurchaseStoreContext } from "../components/InAppPurchaseStore";
import * as Sentry from "@sentry/react";
import { getLanguageCode } from "../utils/localeUtils";
import GoProTeaser1 from "../assets/images/go-pro/trends-go-pro-1.png";
import { startAds, stopAds } from "../services/AdMobService";
import { URLS } from "../constants/urls";
import { isAndroid, isIos } from "../services/platformDetection";
import "cordova-plugin-purchase";
import { InAppPurchaseComponent } from "../components/upgrade/InAppPurchaseComponent";
import { PlanType } from "../pages/refsixPlus/upgradeToPlusOrPro";
import { getCurrentUsername } from "../utils/username";
import { updateUserActionsCheckList } from "../services/userActionsCheckListService";
import { useUserActionsCheckList } from "../components/hooks";
import OneSignal from "onesignal-cordova-plugin";

interface GoProModal {
    openModal: boolean;
    onDismiss: (purchased: boolean) => void;
    showContent?: boolean;
    breakpoint?: number;
}

export const GoProModal: FC<GoProModal> = ({
    openModal,
    onDismiss,
    showContent = true,
    breakpoint = 1,
}) => {
    const { products, store } = useContext(InAppPurchaseStoreContext);
    const { t } = useTranslation();
    const [presentLoader, dismissLoader] = useIonLoading();
    const monthProd = first(
        filter(products, { period: "month", active: true })
    );
    const userActionsCheckList = useUserActionsCheckList();

    const yearTrialProd = first(
        filter(products, {
            period: "year",
            trial: true,
            active: true,
        })
    );

    const [selectedSubscription, setSelectedSubscription] = useState<PlanType>(
        PlanType.yearTrial
    );

    const onReady = (event: any) => {
        event.target.mute();
        event.target.playVideo();
    };

    // useEffect to call dismissLoader when the component unmounts
    useEffect(() => {
        return () => {
            dismissLoader();
        };
    }, []);

    useEffect(() => {
        stopAds();
    }, []);

    useEffect(() => {
        if (openModal) {
            console.log("GoProModal useEffect stopAds");
            stopAds();
        } else {
            console.log("GoProModal useEffect startAds");
            startAds();
        }
        return () => {
            startAds();
            console.log("GoProModal useEffect cleanup startAds");
        };
    }, [openModal]);

    const startOrder = () => {
        if (!store) {
            Sentry.captureMessage(
                "Tried to order in-app purchase but no store object defined"
            );
            return;
        }

        if (!yearTrialProd || !monthProd) {
            Sentry.captureMessage(
                "Tried to order in-app purchase but products not found"
            );
            return;
        }

        if (!userActionsCheckList.initiatedPurchase) {
            updateUserActionsCheckList({ initiatedPurchase: true });
        }
        if (isIos() || isAndroid()) {
            OneSignal.InAppMessages.addTrigger("initiatedPurchase", "true");
        }

        let product = yearTrialProd;
        switch (selectedSubscription) {
            case PlanType.month:
                product = monthProd;
                break;
            default:
            case PlanType.yearTrial:
                product = yearTrialProd;
                break;
        }

        if (!product || !product.name) {
            Sentry.captureMessage(
                `Tried to order in-app purchase but no product was selected or it had no name`
            );
            return;
        }

        const productStore = store && store.get(product.name);
        const productOffers = productStore ? productStore.offers : null;
        if (productOffers) {
            store.applicationUsername = getCurrentUsername;
            store.order(productOffers[0]);
            presentLoader({});

            store.when().approved(() => {
                dismissLoader();
            });
        }
        store.error((error: any) => {
            if (error) {
                if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
                    console.log(
                        "Purchase flow has been cancelled by user: " +
                            error.message
                    );

                    Sentry.addBreadcrumb({
                        category: "innAppPurchase",
                        message: `Purchase flow has been cancelled by user ${error.message}`,
                    });
                } else {
                    console.log("Other issue: " + error.message);

                    Sentry.addBreadcrumb({
                        category: "innAppPurchase",
                        message: `Purchase flow issue ${error.message}`,
                    });
                }
            }

            dismissLoader();
        });
    };

    return (
        <IonModal
            isOpen={openModal}
            onDidDismiss={() => onDismiss(false)}
            initialBreakpoint={breakpoint}
            showBackdrop
        >
            <IonHeader>
                <IonToolbar style={breakpoint >= 0.9 ? {} : { paddingTop: 0 }}>
                    <IonTitle>
                        <img
                            src={proHeaderImage}
                            className="pro-header-image"
                        />
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            onClick={() => onDismiss(false)}
                            data-testid="goProModalCloseButton"
                        >
                            {t("upgrade.proFeatures.close")}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="go-pro-modal">
                <IonCard className="ion-card-transparent no-margin">
                    {showContent && (
                        <>
                            <div className="youtube-full-width">
                                {/* Show video in english*/}
                                {getLanguageCode() === "en" && (
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "100%",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: "56.25% 0 0 0",
                                                position: "relative",
                                            }}
                                        >
                                            <iframe
                                                src="https://player.vimeo.com/video/814949486?h=cd5137d2be&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&muted=1&background=1"
                                                frameBorder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowFullScreen
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                                title="REFSIX PRO"
                                            ></iframe>
                                            <script src="https://player.vimeo.com/api/player.js"></script>
                                        </div>
                                    </div>
                                )}

                                {/* Show image in other languages */}
                                {getLanguageCode() !== "en" && (
                                    <div style={{ textAlign: "center" }}>
                                        <img
                                            style={{ width: "80%" }}
                                            src={GoProTeaser1}
                                            alt="Go Pro teaser 1"
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {store && monthProd && yearTrialProd && (
                        <>
                            <IonItem lines="none">
                                {/*<div className="space-top">*/}
                                <IonLabel className="text-weight-bold">
                                    Select a Plan:
                                </IonLabel>
                            </IonItem>
                            <IonList className="pro-product-select">
                                <IonRadioGroup
                                    value={selectedSubscription}
                                    onIonChange={(val) =>
                                        setSelectedSubscription(
                                            val.detail.value
                                        )
                                    }
                                >
                                    <InAppPurchaseComponent
                                        userSelectedProduct={yearTrialProd}
                                        buttonTitle={t(
                                            "upgrade.proFeatures.annual"
                                        )}
                                        planType={PlanType.yearTrial}
                                    />
                                    <InAppPurchaseComponent
                                        userSelectedProduct={monthProd}
                                        buttonTitle={t(
                                            "upgrade.proFeatures.month"
                                        )}
                                        planType={PlanType.month}
                                    />
                                </IonRadioGroup>
                            </IonList>

                            <div
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                }}
                            >
                                <IonButton
                                    className="ion-align-items-center"
                                    // expand="block"
                                    onClick={startOrder}
                                >
                                    {t("billing.subscribe")}
                                </IonButton>
                            </div>
                        </>
                    )}

                    {!store && (
                        <IonCard data-testid="goProModalError">
                            <IonCardContent>
                                {(isIos() || isAndroid()) && (
                                    <div>
                                        <IonText style={{ color: "white" }}>
                                            {t(
                                                "register.register.customerFailure.title"
                                            )}
                                        </IonText>
                                    </div>
                                )}
                                <div className="ion-text-center">
                                    <IonButton
                                        data-testid="goProWebsiteButton"
                                        onClick={() =>
                                            window.open(
                                                URLS.REFSIX_ACCOUNT,
                                                "_system"
                                            )
                                        }
                                    >
                                        {t("menu.upgrade")}
                                    </IonButton>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    )}
                </IonCard>
            </IonContent>
        </IonModal>
    );
};
