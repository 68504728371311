import { Filters } from "../models/refsixState";
import {
    UPDATE_FILTERS_MATCHES,
    UPDATE_FILTERS_RESULTS,
} from "../actions/types";

const initialState = new Filters();

function filterReducer(state: Filters = initialState, action: any) {
    switch (action.type) {
        case UPDATE_FILTERS_MATCHES:
            return { ...state, filterMatches: action.filters };
        case UPDATE_FILTERS_RESULTS:
            return { ...state, filterResults: action.filters };
        default:
            return state;
    }
}

export default filterReducer;
