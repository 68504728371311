import {
    EventName,
    GoalEvent,
    HalfEvent,
    IncidentEvent,
    ITeamPlayerMap,
    MatchEvent,
    MatchPhone,
    Player,
    SelectedTeam,
    Stats,
    SubstitutionEvent,
    Team,
} from "refsix-js-models";
import { copyObject } from "./copyObject";
import { processMatch } from "../stats";

export type matchEventsInNumbers = {
    yellowCards?: number;
    redCards?: number;
    goals?: number;
    subs?: number;
    sinBins?: number;
};

export function getWatchId(match: MatchPhone): string | undefined {
    if (match.refsixUpdateCount) {
        return match._id + "_" + match.refsixUpdateCount;
    }
    return match._id;
}

export function hasTeam(match: MatchPhone, teamName: string): boolean {
    if (match.players && match.players[teamName]) {
        const players = match.players[teamName].filter(function (member) {
            return !member.teamOfficial;
        });
        return players.length > 0;
    }
    return false;
}

export function hasTeamOfficials(match: MatchPhone, teamName: string): boolean {
    if (match.players && match.players[teamName]) {
        const teamOfficials = match.players[teamName].filter(function (member) {
            return member.teamOfficial;
        });
        return teamOfficials.length > 0;
    }
    return false;
}

export function addTeam(
    match: MatchPhone,
    teamName: string,
    team: Team
): MatchPhone {
    const existingPlayers = match.players || {};
    const newPlayers: ITeamPlayerMap = Object.assign({}, existingPlayers, {
        [teamName]: team,
    });
    return {
        ...match,
        players: newPlayers,
    };
}

export function getStartingPlayers(
    match: MatchPhone,
    teamName: string
): Player[] {
    if (!match.players || !match.players[teamName]) {
        return [];
    }
    return match.players[teamName].filter(
        (player: Player) => player.starting && !player.teamOfficial
    );
}

export function getBenchPlayers(match: MatchPhone, teamName: string): Player[] {
    if (!match.players || !match.players[teamName]) {
        return [];
    }
    return match.players[teamName].filter(
        (player: Player) => !player.starting && !player.teamOfficial
    );
}

export function getTeamOfficials(
    match: MatchPhone,
    teamName: string
): Player[] {
    if (!match.players || !match.players[teamName]) {
        return [];
    }
    return match.players[teamName].filter(
        (player: Player) => player.teamOfficial
    );
}

export function hasTeamsheets(match: MatchPhone): boolean {
    return hasTeam(match, match.homeTeam) || hasTeam(match, match.awayTeam);
}

export function isHomeReady(match: MatchPhone) {
    return hasTeam(match, match.homeTeam);
}

export function isAwayReady(match: MatchPhone) {
    return hasTeam(match, match.awayTeam);
}

export function hasRole(match: MatchPhone, role) {
    return match.officialRole === role;
}

export function millisToMins(millis: number): number {
    return millis && !isNaN(millis) ? Math.round(millis / 60000) : millis;
}

/**
 * @returns {Array.<*>} List of matchEvents representing segments of the game where the match is in play. Sorted in
 * chronological order.
 */
export function getPlayingSegments(match: MatchPhone): HalfEvent[] {
    const halfEvents: HalfEvent[] = [];
    if (!match.matchEvents) {
        return [];
    }
    Object.values(match.matchEvents).forEach((matchEvent) => {
        // the trim() if to handle historical bad data from old watch app that left a trailing space
        if (matchEvent.eventName.trim() === EventName.half) {
            const halfEvent = matchEvent as HalfEvent;
            if (halfEvent.playing) {
                halfEvents.push({
                    ...halfEvent,
                    length: millisToMins(halfEvent.length), // TODO - this is replacing the millis value with mins
                });
            }
        }
    });
    return halfEvents.sort(
        (a: HalfEvent, b: HalfEvent) => a.timestamp - b.timestamp
    );
}

export function processEvents(match: MatchPhone): MatchPhone {
    match = copyObject(match);
    match.stats = new Stats(); // initialise stats object
    let isPlaying = false;
    let accumulatedMatchTime = 0;
    let currentSegmentLength = 0;

    if (match.matchEvents) {
        const processedEventsArray: MatchEvent[] = Object.values<MatchEvent>(
            match.matchEvents
        )
            .sort((a, b) => {
                return a.timestamp - b.timestamp;
            })
            .map((event: MatchEvent): MatchEvent => {
                let updatedEvent = { ...event };
                const timestamp = updatedEvent.timestamp;

                if (
                    updatedEvent.eventName === EventName.half ||
                    updatedEvent.eventName === EventName.penalties
                ) {
                    const halfUpdatedEvent = updatedEvent as HalfEvent;
                    isPlaying = halfUpdatedEvent.playing || false;

                    if (
                        halfUpdatedEvent.playing ||
                        halfUpdatedEvent.eventName === EventName.penalties
                    ) {
                        accumulatedMatchTime += currentSegmentLength;
                        currentSegmentLength = millisToMins(
                            halfUpdatedEvent.length
                        );

                        if (halfUpdatedEvent.isExtraTime) {
                            match.playedExtraTime = true;
                        }
                        if (event.eventName === EventName.penalties) {
                            match.playedPenalties = true;
                        }
                    } else {
                        // stuff in half time still needs to have minutes from the first half
                        accumulatedMatchTime += currentSegmentLength;
                        currentSegmentLength = 0;
                    }
                }

                if (!isPlaying) {
                    updatedEvent.minuteOfPlay = 0; // e.g. halftime and pens
                } else if (updatedEvent.minuteOfPlay > currentSegmentLength) {
                    // e.g. convert 48 to 45+3
                    updatedEvent.additionalTime =
                        updatedEvent.minuteOfPlay - currentSegmentLength;
                    updatedEvent.minuteOfPlay = currentSegmentLength;
                }
                updatedEvent.minuteOfPlay =
                    updatedEvent.minuteOfPlay + accumulatedMatchTime;

                // updatedEvent.team && (updatedEvent.team.players = undefined); // clean up if the full team sheet added.
                updatedEvent = processEvent(match, timestamp, updatedEvent);

                return updatedEvent;
            });

        match.matchEvents = {};

        processedEventsArray.forEach((event) => {
            if (match.matchEvents) {
                // will always exist
                match.matchEvents[event.timestamp] = event;
            }
        });
        match.stats = processMatch(match);
    }

    match.eventsProcessed = true;

    return match;
}

function processEvent(
    match: MatchPhone,
    timestamp: number,
    event: MatchEvent
): MatchEvent {
    const processedEvent = { ...event } as MatchEvent | any;
    // todo there are a lot of ts-ignores here where the child event might have a property

    // @ts-ignore
    if (event.player && event.team) {
        // @ts-ignore
        var teamName = event.team.teamName;
        // @ts-ignore
        if (event.ownGoal) {
            teamName =
                match.homeTeam === teamName ? match.awayTeam : match.homeTeam;
        }
        // @ts-ignore
        var player = findPlayer(match, teamName, event.player.number);
        if (player && player.events) {
            delete player.events;
        }
    }
    return processedEvent;
}

export function updateTeamNameInEvents(
    match: MatchPhone,
    oldHomeTeam,
    oldAwayTeam,
    newHomeTeamName,
    newHomeShortName,
    newAwayTeamName,
    newAwayShortName
) {
    if (match.matchEvents) {
        const orderedEvents = Object.keys(match.matchEvents).sort();
        for (let index in orderedEvents) {
            const prop = orderedEvents[index];
            if (match.matchEvents.hasOwnProperty(prop)) {
                const event: any = match.matchEvents[prop];
                if (event.team && event.team.side === SelectedTeam.home) {
                    event.team.teamName = newHomeTeamName
                        ? newHomeTeamName
                        : event.team.teamName;
                    event.team.shortName = newHomeShortName
                        ? newHomeShortName
                        : event.team.shortName;
                } else if (
                    event.team &&
                    event.team.side === SelectedTeam.away
                ) {
                    event.team.teamName = newAwayTeamName
                        ? newAwayTeamName
                        : event.team.teamName;
                    event.team.shortName = newAwayShortName
                        ? newAwayShortName
                        : event.team.shortName;
                }
                // update the match in the matchEvents array.
                match.matchEvents[prop] = processEvent(
                    match,
                    Number(prop),
                    event
                );
            }
        }
    }
}

export function updatePlayersInEvents(match: MatchPhone, players: Player[]) {
    if (match.matchEvents) {
        const orderedEvents = Object.keys(match.matchEvents).sort();

        orderedEvents.forEach((element, index) => {
            const prop = orderedEvents[index];
            if (match.matchEvents && match.matchEvents.hasOwnProperty(prop)) {
                let event: any = match.matchEvents[prop];
                if (
                    event.player &&
                    event.team &&
                    players[
                        event.team.teamName +
                            "_" +
                            event.player.name +
                            "_" +
                            event.player.number
                    ]
                ) {
                    event = updatePlayerName(match, event, "player", players);
                } else if (
                    event.playerOn &&
                    event.team &&
                    players[
                        event.team.teamName +
                            "_" +
                            event.playerOn.name +
                            "_" +
                            event.playerOn.number
                    ]
                ) {
                    event = updatePlayerName(match, event, "playerOn", players);
                } else if (
                    event.playerOff &&
                    event.team &&
                    players[
                        event.team.teamName +
                            "_" +
                            event.playerOff.name +
                            "_" +
                            event.playerOff.number
                    ]
                ) {
                    event = updatePlayerName(
                        match,
                        event,
                        "playerOff",
                        players
                    );
                }
                match.matchEvents[prop] = processEvent(
                    match,
                    Number(prop),
                    event
                );
            }
        });
    }
}

export function updateMatchEvents(match: MatchPhone) {
    if (match.matchEvents) {
        const orderedEvents = Object.keys(match.matchEvents).sort();
        for (const index in orderedEvents) {
            const prop = orderedEvents[index];
            if (match.matchEvents.hasOwnProperty(prop)) {
                const event = match.matchEvents[prop];
                match.matchEvents[prop] = processEvent(
                    match,
                    Number(prop),
                    event
                );
            }
        }
    }
}

export function findPlayer(
    match: MatchPhone,
    teamName: string,
    number: number
): Player | undefined {
    if (!match.players || !match.players[teamName]) {
        return;
    }
    return match.players[teamName].find(findPlayerByNumber(match, number));
}

export function findPlayerByNumber(match: MatchPhone, playerNumber: number) {
    return function (player: Player) {
        return player.number == playerNumber;
    };
}

function updatePlayerName(
    match: MatchPhone,
    event: GoalEvent | IncidentEvent | SubstitutionEvent,
    key: string,
    players: Player[]
) {
    const newPlayer =
        players[
            event.team.teamName +
                "_" +
                event[key].name +
                "_" +
                event[key].number
        ];
    event[key].name = newPlayer.name;
    event[key].number = newPlayer.number;
    event[key].starting = newPlayer.starting;
    if (newPlayer.role) {
        event[key].role = newPlayer.role;
    }
    return event;
}

export function initResults(match: MatchPhone): MatchPhone {
    return { ...match, stats: new Stats() };
}

export interface FixtureResultCount {
    fixtureCount: number;
    resultCount: number;
}

export function getFixtureResultCount(
    matches: MatchPhone[]
): FixtureResultCount {
    return matches.reduce(
        function (acc, el) {
            if (el.matchFinished) {
                acc.resultCount += 1;
            } else {
                acc.fixtureCount += 1;
            }
            return acc;
        },
        { fixtureCount: 0, resultCount: 0 }
    );
}

export function getMatchEventsInNumbers(
    match: MatchPhone
): matchEventsInNumbers {
    const matchEvents: matchEventsInNumbers = {};

    if (match.matchEvents) {
        for (const key in match.matchEvents) {
            const event = match.matchEvents[key];

            if (event) {
                switch (event.eventName) {
                    case EventName.incident:
                        const incidentEvent = event as IncidentEvent;
                        if (incidentEvent.card === "yellow") {
                            // Total number of yellow cards
                            matchEvents.yellowCards =
                                (matchEvents.yellowCards || 0) + 1;

                            // Total number of sinBins
                            if (incidentEvent.sinBin) {
                                matchEvents.sinBins =
                                    (matchEvents.sinBins || 0) + 1;
                            }
                        } else if (incidentEvent.card === "red") {
                            matchEvents.redCards =
                                (matchEvents.redCards || 0) + 1;
                        }
                        break;
                    case EventName.goal:
                        // Total number of goals
                        matchEvents.goals = (matchEvents.goals || 0) + 1;
                        break;
                    case EventName.substitution:
                        // Total number of substitutions
                        matchEvents.subs = (matchEvents.subs || 0) + 1;
                        break;
                    default:
                        break;
                }
            }
        }
    }
    return matchEvents;
}
