import {
    IonButton,
    IonCard,
    IonContent,
    IonPage,
    IonText,
    IonTextarea,
} from "@ionic/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactStars from "react-stars";
import { AlertComponent, HeaderComponent } from "../../../components";
import { RefsixState } from "../../../redux/models/refsixState";
import { routes } from "../../../route/constants";
import { UserProfile } from "../../../models/userProfile";
import { sendFeedback } from "../../../services/feedbackService";

export const ContactUs: FC = () => {
    const { t } = useTranslation();

    const profile = useSelector(
        (state: RefsixState) => state.currentUser?.profile as UserProfile
    );

    const [stars, setStars] = useState<number>();
    const [comments, setComments] = useState<string>();

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState<string>();
    const [alertDesc, setAlertDesc] = useState<string>();
    const [successfullySent, setSuccessfullySent] = useState(false);

    const handleStarsChanged = (val: number) => {
        // Deselect stars if same rating selected twice
        if (val === stars) {
            setStars(undefined);
        } else {
            setStars(val);
        }
    };

    const handleCommentChanged = (val: string | undefined | null) =>
        setComments(val || "");

    const handleSubmit = async () => {
        try {
            await sendFeedback(profile, comments, stars);
            setAlertTitle(t("feedback.feedback.success.title"));
            setAlertDesc(t("feedback.feedback.success.description"));
            setSuccessfullySent(true);
        } catch (err) {
            setAlertTitle(t("feedback.feedback.failure.title"));
            setAlertDesc(t("feedback.feedback.failure.description"));
            setSuccessfullySent(false);
        }
        setShowAlert(true);
    };

    const handleAlertDismissed = () => {
        setShowAlert(false);
        if (successfullySent) {
            setComments("");
            setStars(undefined);
        }
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMoreSupport}
                title={t("general.feedback")}
            />
            <IonContent>
                <IonCard>
                    <IonText className="white-text-and-font">
                        {t("feedback.feedback.title")}{" "}
                        {t("feedback.feedback.description")}
                    </IonText>
                </IonCard>
                <IonCard>
                    <IonTextarea
                        className="white-text-and-font margin"
                        rows={10}
                        placeholder={t("feedback.feedback.commentPlaceholder")}
                        onIonChange={({ detail: { value } }) =>
                            handleCommentChanged(value)
                        }
                        onIonInput={(event: CustomEvent<any>) =>
                            handleCommentChanged(event.detail.value)
                        }
                        value={comments}
                        data-testid="contact-text-comment"
                    ></IonTextarea>
                </IonCard>
                <IonCard>
                    <IonText className="white-text-and-font">
                        {t("feedback.feedback.rating")}
                    </IonText>
                    <div
                        style={{ width: 200, margin: "10px auto" }}
                        data-testid="contact-stars"
                    >
                        <ReactStars
                            count={5}
                            onChange={handleStarsChanged}
                            size={36}
                            color2={"#ffd700"}
                            half={false}
                            value={stars}
                        />
                    </div>
                    <IonButton
                        expand="block"
                        disabled={!comments && !stars}
                        onClick={handleSubmit}
                        data-testid="contact-submit-button"
                    >
                        {t("feedback.feedback.button")}
                    </IonButton>
                </IonCard>
            </IonContent>
            <AlertComponent
                testId={"feedback-alert"}
                showAlert={showAlert}
                buttonDone={t("help.OnboardingVideo.controls.ok")}
                description={alertDesc}
                title={alertTitle}
                onDonePressed={handleAlertDismissed}
                dismissAlert={handleAlertDismissed}
                confirmButtonClassName="feedback-submission-confirmation"
            />
        </IonPage>
    );
};
