import { IonAlert } from "@ionic/react";
import { t } from "i18next";
import { FC } from "react";
import { isUserPlusEligible } from "../services/subscriptionService";

interface GoProAlertComponentProps {
    testId: string;
    showGoProAlert: boolean;
    onDismissGoProAlert: () => void;
    cancelButtonText?: string;
    cancelButtonClicked?: () => void;
    buttonLeanMoreText?: string;
    buttonLeanMoreClicked?: () => void;
    subHeaderTitle?: string;
}

export const GoProAlertComponent: FC<GoProAlertComponentProps> = ({
    testId,
    showGoProAlert,
    onDismissGoProAlert,
    subHeaderTitle,
    cancelButtonText,
    cancelButtonClicked,
    buttonLeanMoreText,
    buttonLeanMoreClicked,
}) => {
    const updatedHeaderTitle: string = !isUserPlusEligible()
        ? `REFSIX ${t("upgrade.proFeaturesContent.tableHeader3")}`
        : `REFSIX ${t("upgrade.proFeaturesContent.tableHeader4")}`;

    const updatedDescription: string = !isUserPlusEligible()
        ? t("accountNotification.featureUnavailable.description")
        : t("accountNotification.featureUnavailable.descriptionPlus");

    return (
        <IonAlert
            data-testid={`alert-${testId}`}
            id={`alert-${testId}`}
            isOpen={showGoProAlert}
            onDidDismiss={onDismissGoProAlert}
            header={updatedHeaderTitle}
            subHeader={subHeaderTitle}
            message={updatedDescription}
            buttons={[
                {
                    text: cancelButtonText || t("general.cancel"),
                    handler: cancelButtonClicked,
                    cssClass: "alert-button-cancel",
                },
                {
                    text: buttonLeanMoreText || "",
                    handler: buttonLeanMoreClicked,
                    cssClass: "alert-button-done",
                },
            ]}
        />
    );
};
