import React, { FC, useEffect, useRef, useState } from "react";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import { useHasFeatureAccess, useProfile } from "../../components/hooks";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Player, SelectedTeam } from "refsix-js-models";
import { useMatch } from "../../components/hooks/match";
import { useTranslation } from "react-i18next";
import "./teamSheetScan.css";
import { camera, closeOutline } from "ionicons/icons";
import { trackEvent } from "../../services/analytics/analyticsService";
import {
    cleanPlayers,
    postTeamSheetScan,
} from "../../utils/teamSheetScanHelper";
import { getLocale } from "../../utils/localeUtils";
import { updateMatch } from "../../services/matchService";
import { routes } from "../../route/constants";

export interface TeamSheetScanProps {
    matchId?: string;
    teamSide?: SelectedTeam.home | SelectedTeam.away;
    isOpen: boolean;
    onSuccess?: () => void;
    onDismiss?: () => void;
}

export const TeamSheetScan: FC<TeamSheetScanProps> = ({
    matchId,
    teamSide,
    isOpen,
    onSuccess,
    onDismiss,
}) => {
    const { t } = useTranslation();
    const [scanningTeamSheet, setScanningTeamSheet] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<string>(""); // base64 encoded image url
    const [scannerError, setScannerError] = useState<boolean>(false);
    const modalElement = useRef<HTMLIonModalElement>(null);

    const match = useMatch(matchId);
    const profile = useProfile();
    const hasAccess = useHasFeatureAccess("teamSheetScan");
    const route = useIonRouter();

    useEffect(() => {
        if (hasAccess && isOpen && !scanningTeamSheet) {
            setScanningTeamSheet(true);
            scanTeamSheet();
        }
    }, [isOpen]);

    // TODO redirect?
    if (!match || !teamSide) {
        return null;
    }

    const scanTeamSheet = async () => {
        try {
            setScanningTeamSheet(true);
            setCurrentImage("");
            setScannerError(false);

            // start the camera from @capacitor/camera plugin
            const image = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                correctOrientation: true,
                width: 2048,
                height: 2048,
                source: CameraSource.Camera,
                promptLabelHeader: `AI ${t("fixture.Fixture.teamsheet")}`,
                promptLabelPhoto: t("camera.promptLabelPhoto"),
                promptLabelPicture: t("camera.promptLabelPicture"),
            });
            console.log(image);
            // log the size of the image in MB
            console.log(
                `Image size: ${
                    (image.base64String?.length || 1) / 1024 / 1024
                } MB`
            );
            if (!image.base64String) {
                throw new Error("No image base64 string");
            }
            setCurrentImage(`data:image/jpeg;base64,${image.base64String}`);

            const response = await postTeamSheetScan({
                image: image.base64String,
                locale: getLocale(),
                country: profile?.country || "",
                startingPlayersCount: match.teamSize || 11,
                benchPlayersCount: match.subsNo || 5,
                matchId: match._id,
            });

            if (!response || !response.data || !response.data) {
                throw new Error("No response or data or players");
            }

            const scannedPlayers: Player[] = response.data;

            console.log("players", scannedPlayers);

            const cleanedPlayers = cleanPlayers(scannedPlayers);

            // TODO make up the correct number of players?

            console.log("cleanedPlayers", cleanedPlayers);

            const selectedTeamName =
                teamSide === SelectedTeam.home
                    ? match.homeTeam
                    : match.awayTeam;

            const updatedPlayers = {
                ...match.players,
                [selectedTeamName]: cleanedPlayers,
            };

            const updatedMatch = {
                ...match,
                players: updatedPlayers,
            };

            await updateMatch(updatedMatch);

            trackEvent("ScanTeamSheetSuccess", {
                matchId: match._id,
                side: teamSide,
            });

            setScanningTeamSheet(false);
            // open the edit team sheet page
            onSuccess && onSuccess();
        } catch (error) {
            console.error(error);
            trackEvent("ScanTeamSheetError", {
                matchId: match._id,
                teamSide,
                error: JSON.stringify(error),
            });
            setScanningTeamSheet(false);
            setScannerError(true);
        }
    };

    return (
        <IonModal isOpen={isOpen} ref={modalElement}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton
                            onClick={() => {
                                setScanningTeamSheet(false);
                                onDismiss && onDismiss();
                            }}
                        >
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>
                        <span className="rainbox-text">AI</span>{" "}
                        {t("fixture.Fixture.teamsheet")} BETA
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                {currentImage && !scannerError && (
                    <>
                        <div className="border-wrapper">
                            <div className="image-container">
                                <img src={currentImage} />
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    textAlign: "center",
                                    marginTop: "50%",
                                }}
                            >
                                <IonSpinner
                                    name="dots"
                                    style={{
                                        width: 100,
                                        height: 100,
                                    }}
                                    duration={1500}
                                ></IonSpinner>
                            </div>
                        </div>

                        <IonCard style={{ margin: "20px 0" }}>
                            <IonCardContent>
                                <IonText style={{ color: "white" }}>
                                    {t("teamSheetScan.disclaimer")}
                                </IonText>

                                <p style={{ marginTop: 20 }}>
                                    <small>
                                        {t(
                                            "teamSheetScan.disclaimerPhotoStorage"
                                        )}
                                    </small>
                                </p>
                            </IonCardContent>
                        </IonCard>
                    </>
                )}
                {scannerError && (
                    <IonCard>
                        <IonCardContent>
                            <h2>{t("general.errorTryAgain")}</h2>
                            <div className="ion-text-center">
                                <IonButton
                                    style={{ marginTop: 20 }}
                                    data-testid="scanTeamSheetButton"
                                    onClick={() => scanTeamSheet()}
                                    className="rainbow-button"
                                >
                                    <IonIcon
                                        icon={camera}
                                        style={{ fontSize: 28 }}
                                    />
                                </IonButton>
                            </div>
                        </IonCardContent>
                    </IonCard>
                )}
                {!hasAccess && (
                    <IonCard>
                        <IonCardContent>
                            <h2 style={{ color: "white", marginBottom: 20 }}>
                                {t("general.proFeature")}
                            </h2>

                            {/* Vimeo player */}
                            <div
                                style={{
                                    position: "relative",
                                    width: "80%",
                                    margin: "0 auto 10px",
                                }}
                            >
                                <div
                                    style={{
                                        padding: "177.78% 0 0 0",
                                        position: "relative",
                                    }}
                                >
                                    <iframe
                                        src="https://player.vimeo.com/video/1056009294?h=29ae7bc3d8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&muted=1&background=1"
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        title={`AI ${t(
                                            "fixture.Fixture.teamsheet"
                                        )}`}
                                    ></iframe>
                                </div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                            </div>
                            <p style={{ marginTop: 20 }}>
                                <IonText style={{ color: "white" }}>
                                    {t(
                                        "upgrade.proFeaturesContent.teamSheetScan"
                                    )}
                                </IonText>
                            </p>
                            <div className="ion-text-center">
                                <IonButton
                                    style={{ marginTop: 20 }}
                                    data-testid="scanTeamSheetButton"
                                    onClick={() => {
                                        route.push(`${routes.upgrade}/pro`);
                                        onDismiss && onDismiss();
                                    }}
                                >
                                    {t("menu.upgrade")}
                                </IonButton>
                            </div>
                        </IonCardContent>
                    </IonCard>
                )}
            </IonContent>
        </IonModal>
    );
};
