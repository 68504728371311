import { IonCol, IonList, IonRadioGroup, useIonLoading } from "@ionic/react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../modals/goPro.css";
import { InAppPurchaseComponent } from "../../components/upgrade/InAppPurchaseComponent";
import {
    checkForActiveFreeTrialSubscription,
    checkIfBeenPro,
} from "../../services/subscriptionService";
import proImage from "../../assets/images/upgrade-plus-and-pro/pro-image.png";
import plusImage from "../../assets/images/upgrade-plus-and-pro/plus-image.png";
import "./upgradeToPlusOrPro.css";
import { GoProOrPlusBenefits } from "../../components/upgrade/goProOrPlusBenefits";
import { StoreProductsInterface } from "./selectProOrPlus";
import { getLanguageCode } from "../../utils/localeUtils";
import { useSubscription } from "../../components/hooks";
import { MgmCredit } from "./mgmCredit";

interface UpgradeToPlusOrProProps {
    plusSegmentSelected: boolean;
    showLoader: boolean;
    selectedSubscription: PlanType;
    setSelectedSubscription: (type: PlanType) => void;
    products: StoreProductsInterface;
}

export enum PlanType {
    month = "month",
    year = "year",
    yearTrial = "yearTrial",
    basicMonth = "basicMonth",
}

export const UpgradeToPlusOrPro: FC<UpgradeToPlusOrProProps> = ({
    plusSegmentSelected,
    showLoader,
    selectedSubscription,
    setSelectedSubscription,
    products,
}) => {
    const { basicProd, monthProd, yearProd } = products;
    const { t } = useTranslation();
    const plusBenefits: string[] = [
        t("upgrade.goProModal.advancedTimers"),
        t("upgrade.basicFeatures.uploadTool"),
        t("upgrade.proFeaturesContent.tableFeature6"),
        t("upgrade.basicFeatures.calendarSync"),
        t("upgrade.basicFeatures.addFreeExperience"),
    ];

    const proBenefits: string[] = [
        t("upgrade.proFeatures.everythingInPlus"),
        t("upgrade.goProModal.heatmaps"),
        t("upgrade.goProModal.performanceData"),
        t("upgrade.proFeatures.careerGoals"),
        t("upgrade.goProModal.videoAnalysis"),
    ];
    const [presentLoader, dismissLoader] = useIonLoading();

    const upgradeTitle = plusSegmentSelected
        ? t("upgrade.basic.upgradeToPlus")
        : getLanguageCode() === "en" // TODO: remove this when we have translations
        ? t("upgrade.upgrade.goPro")
        : t("upgrade.proFeaturesContent.title");

    const benefits = plusSegmentSelected ? plusBenefits : proBenefits;
    const subscription = useSubscription();

    useEffect(() => {
        if (showLoader) {
            presentLoader({});
        } else {
            dismissLoader();
        }
    }, [showLoader]);

    useEffect(() => {
        if (plusSegmentSelected) {
            setSelectedSubscription(PlanType.basicMonth);
        } else {
            checkIfBeenPro() ||
            (subscription &&
                checkForActiveFreeTrialSubscription(subscription.subscriptions)
                    .length > 0)
                ? setSelectedSubscription(PlanType.year)
                : setSelectedSubscription(PlanType.yearTrial);
        }
    }, [plusSegmentSelected]);

    const isFreeTrialEnabled = (): boolean => {
        if (checkIfBeenPro() || !subscription) {
            return false;
        }

        return (
            checkForActiveFreeTrialSubscription(subscription.subscriptions)
                .length <= 0
        );
    };

    return (
        <IonCol style={{ padding: 0 }}>
            <>
                <div className="youtube-full-width">
                    <img
                        src={plusSegmentSelected ? plusImage : proImage}
                        alt=""
                    />
                </div>

                <GoProOrPlusBenefits
                    title={upgradeTitle}
                    benefits={benefits}
                    selectedSegment={plusSegmentSelected ? "plus" : "pro"}
                />
            </>

            <MgmCredit />
            {plusSegmentSelected && basicProd && (
                <IonList className="pro-product-select">
                    <IonRadioGroup value={PlanType.basicMonth}>
                        <InAppPurchaseComponent
                            userSelectedProduct={basicProd}
                            buttonTitle={t("upgrade.proFeatures.month")}
                            planType={PlanType.basicMonth}
                        />
                    </IonRadioGroup>
                </IonList>
            )}

            {!plusSegmentSelected && monthProd && yearProd && (
                <>
                    <IonList className="pro-product-select">
                        <IonRadioGroup
                            value={selectedSubscription}
                            onIonChange={(val) => {
                                const plan = val.detail.value;
                                setSelectedSubscription(plan);
                            }}
                        >
                            <InAppPurchaseComponent
                                userSelectedProduct={yearProd}
                                buttonTitle={t("upgrade.proFeatures.annual")}
                                planType={
                                    checkIfBeenPro() ||
                                    (subscription &&
                                        checkForActiveFreeTrialSubscription(
                                            subscription.subscriptions
                                        ).length > 0)
                                        ? PlanType.year
                                        : PlanType.yearTrial
                                }
                            />
                            <InAppPurchaseComponent
                                userSelectedProduct={monthProd}
                                buttonTitle={t("upgrade.proFeatures.month")}
                                planType={PlanType.month}
                            />
                        </IonRadioGroup>
                    </IonList>
                </>
            )}
        </IonCol>
    );
};
