import { IonIcon, IonText } from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons";
import { FC } from "react";
import {
    EventName,
    GoalEvent,
    HalfEvent,
    IncidentEvent,
    SelectedTeam,
} from "refsix-js-models";
import { getMatchEventIcon } from "../../utils/playersUtils";
import { getStringForEvent } from "../../utils/teamUtils";

interface RenderMatchEventForTeamInterface {
    side: SelectedTeam;
    event: any;
}

export const RenderMatchEventForTeam: FC<RenderMatchEventForTeamInterface> = ({
    side,
    event,
}) => {
    // Showing the event for either home or away team
    const displayMatchEvent = (
        event: GoalEvent | HalfEvent | IncidentEvent
    ) => {
        switch (event.eventName) {
            case EventName.penaltyShot:
                return (
                    <IonText className="player-name-log-text">
                        {getStringForEvent(event)}
                    </IonText>
                );
            case EventName.goal:
                return (
                    <IonText className="player-name-log-text">
                        {getStringForEvent(event)}
                    </IonText>
                );
            case EventName.substitution:
                return (
                    <>
                        <IonText className="player-name-log-text">
                            <IonIcon
                                className="subIcons"
                                style={{ color: "green" }}
                                icon={arrowForwardOutline}
                            />
                            <span>{getStringForEvent(event, false)}</span>
                        </IonText>
                        <IonText className="player-off-text">
                            <IonIcon
                                className="subIcons"
                                style={{ color: "#d50000" }}
                                icon={arrowBackOutline}
                            ></IonIcon>
                            <span>{getStringForEvent(event, true)}</span>
                        </IonText>
                    </>
                );
            case EventName.incident:
                return (
                    <IonText className="player-name-log-text">
                        <span>{getStringForEvent(event)}</span>
                    </IonText>
                );
            default:
                return null;
        }
    };

    const isEventVisible = event.team && event.team.side === side;

    return (
        <div
            className={event.eventName.toLowerCase()}
            hidden={!isEventVisible}
            data-testid={`logEvent-${side}`}
        >
            <div className="match-log-event-name">
                <div
                    style={{ display: "flex", alignItems: "center" }}
                    className={
                        side === SelectedTeam.home ? "log-left" : "log-right"
                    }
                >
                    <div
                        className={`event-icons ${getMatchEventIcon(event)}`}
                    />
                    <div className="match-log-event-name">
                        {displayMatchEvent(event)}
                    </div>
                </div>
            </div>
        </div>
    );
};
