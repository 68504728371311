import { IonFab, IonFabButton, IonFabList, IonIcon } from "@ionic/react";
import {
    addSharp,
    calendar,
    cloudUploadOutline,
    footballOutline,
    readerOutline,
} from "ionicons/icons";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../route/constants";
import { useLocation, useProfile } from "./hooks";
import { hasFeatureAccess } from "../services/subscriptionService";
import { AvailabilityType, IntegrationPartner } from "refsix-js-models";
import { AddAvailabilityWrapper } from "../pages/addAvailability/addAvailability";
import knvb from "../assets/images/icon/knvb.svg";

export const FabButtonComponent: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
    const [showFab, setShowFab] = useState<boolean>(false);
    const closedDatesVisible = hasFeatureAccess(
        IntegrationPartner.refassist + ":closedDates"
    );

    const openDatesVisible = hasFeatureAccess(
        IntegrationPartner.refassist + ":openDates"
    );
    const [showAddClosedDate, setShowAddClosedDate] = useState<boolean>(false);
    const [availabilityType, setAvailabilityType] = useState<AvailabilityType>(
        AvailabilityType.closedDate
    );
    const userProfile = useProfile();
    const isDutchUser = userProfile?.country?.toUpperCase() === "NL";

    const hideFabAndBackdrop = () => {
        setShowFab(false);
        setShowBackdrop(false);
    };

    return (
        <>
            <IonFab
                vertical="bottom"
                horizontal="center"
                slot="fixed"
                hidden={!location.pathname.includes("refsix")}
                id="ionFab"
                className="fab-button"
                activated={showFab}
            >
                <IonFabButton
                    onClick={() => {
                        setShowFab(true);
                        setShowBackdrop(true);
                    }}
                >
                    <IonIcon
                        className="add-icon"
                        icon={addSharp}
                        size="large"
                    />
                </IonFabButton>
                <IonFabList side="top">
                    {showBackdrop && (
                        <div
                            className="backdrop"
                            onClick={() => hideFabAndBackdrop()}
                        />
                    )}

                    <IonFabButton
                        hidden={!openDatesVisible}
                        className="ion-fab-button"
                        data-testid="fab-add-open-date"
                        color="primary"
                        data-desc={t("availability.addOpenDate")}
                        routerDirection="forward"
                        onClick={() => {
                            setAvailabilityType(AvailabilityType.openDate);
                            hideFabAndBackdrop();
                            setShowAddClosedDate(true);
                        }}
                    >
                        <IonIcon className="add-icon" icon={calendar} />
                    </IonFabButton>
                    <IonFabButton
                        hidden={!closedDatesVisible}
                        className="ion-fab-button"
                        data-testid="fab-add-closed-date"
                        color="primary"
                        data-desc={t("availability.addClosedDate")}
                        routerDirection="forward"
                        onClick={() => {
                            setAvailabilityType(AvailabilityType.closedDate);
                            hideFabAndBackdrop();
                            setShowAddClosedDate(true);
                        }}
                    >
                        <IonIcon className="add-icon" icon={calendar} />
                    </IonFabButton>
                    <IonFabButton
                        className="ion-fab-button"
                        data-testid="fab-upload-match"
                        color="primary"
                        data-desc={t("general.addMultipleMatches")}
                        routerLink={routes.addMultipleMatches}
                        routerDirection="forward"
                        onClick={() => hideFabAndBackdrop()}
                    >
                        <IonIcon
                            className="add-icon"
                            icon={cloudUploadOutline}
                        />
                    </IonFabButton>
                    <IonFabButton
                        className="ion-fab-button"
                        data-testid="fab-add-result"
                        color="primary"
                        data-desc={t("general.addResult")}
                        routerLink={routes.addResult}
                        routerDirection="forward"
                        onClick={() => hideFabAndBackdrop()}
                    >
                        <IonIcon className="add-icon" icon={readerOutline} />
                    </IonFabButton>
                    <IonFabButton
                        className="ion-fab-button"
                        data-testid="fab-add-match"
                        color="primary"
                        data-desc={t("fixture.FixtureList.addFixture")}
                        routerLink={routes.createMatch}
                        routerDirection="forward"
                        onClick={() => hideFabAndBackdrop()}
                    >
                        <IonIcon className="add-icon" icon={footballOutline} />
                    </IonFabButton>
                    <IonFabButton
                        hidden={!isDutchUser}
                        className="ion-fab-button knvb-fab-button"
                        data-testid="fab-add-knvb-match"
                        data-desc={"KNVB " + t("general.match") + " BETA"}
                        routerLink={routes.addKnvbMatch}
                        routerDirection="forward"
                        onClick={() => hideFabAndBackdrop()}
                    ></IonFabButton>
                </IonFabList>
            </IonFab>
            {showAddClosedDate && (
                <AddAvailabilityWrapper
                    openClosedDateModal={showAddClosedDate}
                    dismiss={() => setShowAddClosedDate(false)}
                    availabilityType={availabilityType}
                />
            )}
        </>
    );
};
