import request from "./request";
import { pushIntegrationMessage } from "../redux/actions/integration";
import { IntegrationPartner, MatchPhone } from "refsix-js-models";
import { IntegrationMessageType } from "../redux/models/refsixState";
import { store } from "../redux/store";

const STACK_SPORTS_TURNSTILE_URL =
    process.env.REACT_APP_STACK_SPORTS_TURNSTILE_URL;

const STACK_AUTH_KEY = process.env.REACT_APP_STACK_SPORTS_AUTH_KEY;
const REDIRECT_URI = process.env.REACT_APP_ASSIGNR_REDIRECT_URI as string;

export async function getDbuAccessToken(refNumber: number, apiKey: number) {
    return await request.post("/integrations/dbu/link-account", {
        refereeNumber: refNumber,
        apiKey: apiKey,
    });
}

export async function syncDbuMatches() {
    return await request.get("/integrations/dbu/sync");
}

export function handleFixturesFromDBU(res: any) {
    return { added: res.added, deleted: res.deleted, updated: res.updated };
}

export async function getAssignrAccessToken(
    assignrAuthCode: string,
    redirectUri: string
) {
    if (!assignrAuthCode) {
        throw new Error("Authorization code is required");
    }

    return await request.post("/integrations/assignr/link-account", {
        assignrAuthCode: assignrAuthCode,
        redirectUri: redirectUri || REDIRECT_URI,
    });
}

export async function disconnectAssignrSubscription() {
    return await request.post("/integrations/assignr/unlink-account", {});
}

export async function syncAssignrMatches() {
    try {
        return await request.get("/integrations/assignr/sync");
    } catch (error: any) {
        if (
            error.response.status === 503 &&
            error.response.data === "USER_NEEDS_TO_REAUTH"
        ) {
            store.dispatch(
                pushIntegrationMessage(
                    IntegrationPartner.assignr,
                    IntegrationMessageType.MISSING_CREDENTIALS
                )
            );
        } else {
            store.dispatch(
                pushIntegrationMessage(
                    IntegrationPartner.assignr,
                    IntegrationMessageType.SERVICE_UNAVAILABLE
                )
            );
        }
    }
}

export async function stackSportsLogUserIn(email: string, password: string) {
    const loginUrl = STACK_SPORTS_TURNSTILE_URL + "/authn/login";
    return await request.customFetch({
        url: loginUrl,
        method: "post",
        data: {
            email: email,
            password: password,
        },
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-KEY": STACK_AUTH_KEY,
        },
    });
}

export async function saveStackSportsUser(email: string, accessToken: string) {
    return await request.post("/integrations/stack-sports/user", {
        email: email,
        accessToken: accessToken, // token for the server to verify that the login was successful,
    });
}

export async function syncStackSportsMatches() {
    return await request.get("/integrations/stack-sports/sync");
}
export async function syncKNVBMatches() {
    return await request.get("/integrations/knvb/sync");
}

export const hasKnvbFixtures = (matches: MatchPhone[]) => {
    // check if any of the matches have the integration.partner property set to "KNVB"
    const knvbMatches = matches.filter(
        (match) =>
            match.integration?.partner === IntegrationPartner.knvb &&
            !match.matchFinished
    );
    return knvbMatches.length > 0;
};

export async function updateParticipantStatus(
    participantId: string | number,
    status: "accepted" | "declined",
    username: string
) {
    const url =
        "/integrations/stack-sports/change-status/" +
        username +
        "/" +
        participantId +
        "/" +
        status;
    return request.put(url, {});
}

export function sendMatchReport(matchId: string, username: string) {
    return request.post("/integrations/stack-sports/match-report", {
        username: username,
        matchId: matchId,
    });
}

export function downloadDBUTeamSheet(id: string) {
    return request.get("/integrations/dbu/team-sheets/" + id);
}

export async function syncRefAssistMatches() {
    return request.get("/integrations/refassist/sync");
}
