import {
    IonCard,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonText,
} from "@ionic/react";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import {
    AggregatedStats,
    MisconductCodes as MisconductCode,
} from "refsix-js-models";
import { RoundBarComponent } from "../../../../components";
import {
    ALL_CODES,
    CardType,
    getAllCodesForId,
    getMisconductCodes,
    MisconductCodeTotal,
    transformToMisconductCodeTotals,
} from "../../../../services/misconductService";

interface MisconductCodesProps {
    title: string;
    cardType: CardType;
    aggregatedStats: AggregatedStats | undefined;
    graphDataTestId?: string;
    graphColorOverride?: string;
    useDummyData: boolean;
}

export const MisconductCodes: FC<MisconductCodesProps> = ({
    title,
    cardType,
    aggregatedStats,
    graphDataTestId,
    graphColorOverride,
    useDummyData,
}) => {
    const [presetCodes, setPresetCodes] = useState<MisconductCode[]>([]);
    const [cardCodes, setCardCodes] = useState<MisconductCodeTotal[]>([]);

    useEffect(() => {
        const cardCodes = getAllMisconductCodeTotals();
        const codeIds = cardCodes.map((code) => code.id);
        // Only include codes that exist in the aggregated stats
        setPresetCodes(
            getMisconductCodes().filter((code) => codeIds.includes(code.id))
        );
        setCardCodes(cardCodes);
    }, [aggregatedStats]);

    const getAllMisconductCodeTotals = () =>
        aggregatedStats
            ? transformToMisconductCodeTotals(
                  cardType === CardType.Red
                      ? aggregatedStats.redCardCodes
                      : aggregatedStats.yellowCardCodes
              )
            : [];

    const handleCardCodeChange = (codeId: string) => {
        setCardCodes(getAllCodesForId(codeId, getAllMisconductCodeTotals()));
    };

    return (
        <IonCard>
            <IonItem lines="none">
                <IonText className="text-small">{title}</IonText>
            </IonItem>
            <IonItem>
                <IonSelect
                    className="select-text"
                    defaultValue={ALL_CODES}
                    placeholder={t("stats.stats.Discipline.allCodes")}
                    onIonChange={(e) => handleCardCodeChange(e.detail.value)}
                    interface="popover"
                    data-testid={`${cardType}-card-select`}
                >
                    <IonSelectOption value={ALL_CODES}>
                        {t("stats.stats.Discipline.allCodes")}
                    </IonSelectOption>
                    {presetCodes.map((code, i) => (
                        <IonSelectOption key={i} value={code.id}>
                            {code.name}
                        </IonSelectOption>
                    ))}
                </IonSelect>
            </IonItem>

            <RoundBarComponent
                series={cardCodes.map((code) => code.code)}
                values={cardCodes.map((code) => code.value)}
                numberOfCategories={cardCodes.length}
                testId={graphDataTestId}
                colorOverride={graphColorOverride}
                useDummyData={useDummyData}
            />
        </IonCard>
    );
};
