import {
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertComponent, HeaderComponent } from "../../components";
import { watchModel } from "../../models/watchModel";
import { setSession } from "../../services/watch/watchPairingService";
import { addWatchDetails } from "../../services/watch/watchHelpers";
import moment from "moment/moment";
import { AuthInputComponent } from "../../components/AuthInputComponent";
import { routes } from "../../route/constants";
import { isIos } from "../../services/platformDetection";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export const OnBoardingPairWatch: FC = () => {
    const { t } = useTranslation();
    const [authCode, setAuthCode] = useState<string>("");
    const [codeMismatch, setCodeMismatch] = useState<boolean>(false);
    const [disablePairButton, setDisablePairButton] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const route = useIonRouter();
    const { watchPlatform } = useParams<{ watchPlatform: string }>();

    const watchStatus = useSelector((state: RootState) => state.watchStatus);

    useEffect(() => {
        if (watchStatus.isPaired) {
            const platform = isIos() ? "apple" : "wearos";
            route.push(
                routes.refsixOnboardingFoundWatch + `/${platform}`,
                "forward",
                "replace"
            );
        }
    }, [watchStatus.isPaired]);

    const onChange = (value: string) => {
        setAuthCode(value);
        setCodeMismatch(false);
    };

    const handlePairWatch = async () => {
        if (!authCode) return;
        setLoading(true);
        let watchDetails: watchModel = { watchOS: "", model: "" };

        try {
            const setSessionResponse: any = await setSession(authCode);
            if (setSessionResponse) {
                watchDetails.watchOS = setSessionResponse.data.platform;
                watchDetails.model = setSessionResponse.data.watchModel;
                watchDetails.platform = setSessionResponse.data.platform;
                watchDetails.datePaired = moment().valueOf();
                watchDetails.sessionId = setSessionResponse.data.sessionId;
                await addWatchDetails(watchDetails);
            }
            setLoading(false);

            route.push(
                routes.refsixOnboardingFoundWatch +
                    `/${watchDetails.platform?.toLowerCase()}`,
                "forward",
                "replace"
            );
        } catch (e) {
            setLoading(false);
            console.log("error trying to pair watch", e);
            setShowAlert(true);
            setCodeMismatch(true);
        }
    };

    return (
        <IonPage>
            <HeaderComponent
                titleTestId="pair-watch-header"
                title={t("settings.WatchSettings.pairWatch")}
                showBackButton
            />
            <IonContent>
                <IonLoading isOpen={loading} />
                <IonCard color="transparent">
                    <IonRow>
                        <IonCol size="12">
                            <IonText
                                color="primary"
                                className="text-weight-bold text-large"
                            >
                                {t("help.onBoarding.step1")}
                            </IonText>
                        </IonCol>
                        <IonCol size="12">
                            <IonText>
                                {t("help.onBoarding.pairWatchStep1")}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonCard>
                <IonCard color="transparent">
                    <IonRow>
                        <IonCol size="12">
                            <IonText
                                color="primary"
                                className="text-weight-bold text-large"
                            >
                                {t("help.onBoarding.step2")}
                            </IonText>
                        </IonCol>
                        <IonCol size="12">
                            <IonText>
                                {watchPlatform === "garmin"
                                    ? t(
                                          "settings.WatchSettings.pairWatchHelpGarmin"
                                      )
                                    : t("settings.WatchSettings.pairWatchHelp")}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonCard>

                <AuthInputComponent
                    authCode={authCode}
                    onChange={onChange}
                    disablePairButton={(enable) => setDisablePairButton(enable)}
                    codeMismatch={codeMismatch}
                />

                <IonCard>
                    <IonButton
                        data-testid="pair-watch-button"
                        expand="block"
                        onClick={() => handlePairWatch()}
                        disabled={disablePairButton}
                    >
                        <IonText>
                            {t("settings.WatchSettings.pairWatch")}
                        </IonText>
                    </IonButton>
                </IonCard>
            </IonContent>
            <AlertComponent
                testId="pair-watch-alert"
                onDonePressed={() => setShowAlert(false)}
                showAlert={showAlert}
                description={t("settings.WatchSettings.codeMismatch")}
                buttonDone={t("help.OnboardingVideo.controls.ok")}
            />
        </IonPage>
    );
};
