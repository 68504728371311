import featuresJson from "../feature-toggle.json";
import { getLanguageCode } from "./localeUtils";

interface FeatureMap {
    [featureName: string]: Feature;
}

type Feature = {
    description: string;
    languages: string[];
};

const features: FeatureMap = featuresJson;

/**
 * @param featureName
 * @param language e.g. "en"
 * @returns true if the feature is available for the given locale or if feature not found
 */
export function isFeatureAvailable(
    features: FeatureMap,
    featureName: string,
    language: string
): boolean {
    const feature: Feature | undefined = features[featureName];
    if (feature) {
        return feature.languages.indexOf(language) > -1;
    }
    return true;
}

export function isFeatureAvailableCurrentLanguage(
    featureName: string
): boolean {
    return isFeatureAvailable(features, featureName, getLanguageCode());
}
