import { WatchInterface, WatchMessage } from "./watchInterface";
import { Observer } from "rxjs";
import shortUUID from "short-uuid";
import * as Sentry from "@sentry/react";
import { wakeUpMessage, WatchWakeUpRequest } from "./messages/wakeUp";
import { MessageTypes } from "./messageTypesEnum";
import { getMatchesListMessage } from "./messages/getMatchesList";
import { getConfiguration } from "./messages/getConfiguration";
import { ChunkMessage, MessageChunkType } from "./TrackingChunkAssembler";
import { handleChunk } from "./messages/gpsHrReceived";
import {
    handleResultReceived,
    ResultReceived,
} from "./messages/resultRecieved";
import { tokenMessage } from "./messages/getToken";
import { DeviceInfo, deviceInfoResponse } from "./messages/deviceInfo";
import { store } from "../../redux/store";

export const FREE_USER_EXPIRES_IN_MS = 15778800000; // 6 months in millis

export class WatchService {
    watch: WatchInterface;

    constructor(watch: WatchInterface) {
        this.watch = watch;

        const self = this;

        let watchObserver: Observer<any> = {
            next: (payload: any) => {
                console.log(`[WatchService] Received ${payload.messageId}`);
                self.onMessageReceived(payload).catch((error) => {
                    console.log(
                        `[WatchService] Error handling message ${
                            payload.messageId
                        } ${JSON.stringify(error)}`
                    );
                    Sentry.captureException(error);
                });
            },
            error: (error) =>
                console.log(`[WatchService] The error was ${error}`), // TODO not sure what to do
            complete: () =>
                console.log("[WatchService] The subscription has completed"), // TODO not sure what to do
        };

        this.watch.messageReceivedStream().subscribe(watchObserver);
    }

    /**
     * This function is called when a message is received from the Watch.
     * It will create and send any required response to the watch.
     * @param request
     */
    onMessageReceived = async (request: WatchMessage) => {
        const username = store.getState().auth?.session?.user_id;
        const databaseLoading = store.getState().matches.loading;

        if (!username || databaseLoading !== false) {
            console.log("The app isn't ready yet!");
            return;
        }

        Sentry.configureScope((scope: Sentry.Scope) => {
            scope.addAttachment({
                filename: "watch-message.json",
                data: JSON.stringify(request),
            });
        });

        let messageId: MessageTypes | undefined = request.messageId;

        if (!messageId) {
            // TODO remove this once we're sure all watches are on latest version >6.0.0
            // old watch app used payloadType instead of messageId. Allow backwards compatability
            if (request.payloadType) {
                messageId = request.payloadType;
                request = {
                    ...request,
                    messageId: messageId,
                    payloadType: undefined,
                };
            } else {
                Sentry.captureMessage(
                    "[WatchService.onMessageReceived] Unknown BT payload missing messageId"
                );
                Sentry.configureScope((scope) => {
                    scope.clearAttachments();
                });
                return;
            }
        }

        let responseMsgPromise: Promise<any> | undefined;

        console.log(`Message received from watch: ${messageId}`, request);

        try {
            switch (messageId) {
                case MessageTypes.WAKE_UP:
                    const wakeUpRequest = request as WatchWakeUpRequest;
                    responseMsgPromise = wakeUpMessage(wakeUpRequest);
                    break;
                case MessageTypes.GET_MATCHES_LIST:
                    // TODO sending tokens on other request types?
                    responseMsgPromise = getMatchesListMessage(
                        request,
                        undefined
                    );
                    break;
                case MessageTypes.GET_CONFIGURATION:
                    responseMsgPromise = getConfiguration(request, undefined);
                    break;
                case MessageTypes.SEND_RESULT:
                    const resultRequest = request as ResultReceived;
                    responseMsgPromise = handleResultReceived(
                        resultRequest,
                        undefined
                    );
                    break;
                case MessageTypes.TOKEN_REQUEST:
                    responseMsgPromise = tokenMessage(request);
                    break;
                case MessageTypes.GPS_RECEIVED:
                    const gpsRequest = request as unknown as ChunkMessage;
                    await handleChunk(MessageChunkType.gps, gpsRequest);
                    break;
                case MessageTypes.HR_RECEIVED:
                    const hrRequest = request as unknown as ChunkMessage;
                    await handleChunk(MessageChunkType.hr, hrRequest);
                    break;
                case MessageTypes.DEVICE_INFO_RESPONSE:
                    // This is only implemented on wearOS
                    const deviceInfo = request as unknown as DeviceInfo;
                    await deviceInfoResponse(deviceInfo);
                    break;
                default:
                    // If not, we don't recognise this message
                    Sentry.captureMessage(
                        `[WatchService.onMessageReceived] Unknown BT payload`
                    );
                    console.log("Unknown payload");
            }
        } catch (error: any) {
            const message = `[WatchService.onMessageReceived()] something went wrong ${JSON.stringify(
                error
            )}`;
            console.log(message);
            Sentry.captureMessage(message);
        }

        // TODO if the message states the need a token then send them one separately
        //  which will require debouncing if we actually chose to do this

        // if (
        //     request.needsToken &&
        //     request.messageId !== MessageTypes.TOKEN_REQUEST
        // ) {
        //     console.log(
        //         `Watch needsToken from ${request.messageId} message so sending separately`
        //     );
        //     const tokenResponse = await tokenMessage({
        //         ...request,
        //         id: shortUUID().uuid(),
        //     });
        //     await this.watch.sendMessage(tokenResponse);
        // }

        // Send the response if there is any
        if (responseMsgPromise) {
            let responseMessage: any;
            try {
                responseMessage = await responseMsgPromise;
                if (!responseMessage) {
                    return;
                }
                console.log("Sending response to watch: ", JSON.stringify(responseMessage));
                await this.watch.sendMessage(responseMessage);
                Sentry.addBreadcrumb({
                    message: `[WatchService.onMessageReceived()] sent ${responseMessage.messageId} response`,
                });
            } catch (error: any) {
                const message = `[WatchService.onMessageReceived()] unable to send response. ${error.message}`;
                Sentry.addBreadcrumb({ message });
                console.error(message);
            }
        }

        // Clear attachments
        Sentry.configureScope((scope) => {
            scope.clearAttachments();
        });
    };

    /**
     * Send a message from the phone to the watch.
     * @param payload
     */
    sendMessage = (payload: any) => {
        return this.watch.sendMessage(payload);
    };

    sendTestMessage = () => {
        return this.sendMessage({
            messageId: "pullMatchId",
            id: shortUUID().uuid(),
        });
    };
}
