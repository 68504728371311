import { IonSpinner, IonText } from "@ionic/react";
import React, { FC } from "react";
import WatchFrame from "../../assets/images/timers/ios-watch-frame.png";
import "./onBoarding.css";

interface OnBoardingWatchWrapperInterface {
    showProgressBar?: boolean;
    text?: string;
    imageSrc?: string;
}

export const OnBoardingWatchRender: FC<OnBoardingWatchWrapperInterface> = ({
    showProgressBar,
    text,
    imageSrc,
}) => {
    return (
        <div className="finding-watch-container">
            <div style={{ position: "relative" }}>
                <img src={WatchFrame} alt="Watch frame" height={250} />
                {imageSrc && (
                    <div className="watch-outline-content">
                        <img src={imageSrc} width={150} alt="Watch image" />
                    </div>
                )}

                <div
                    className="watch-outline-content"
                    style={{ marginBottom: 20 }}
                >
                    {text && <IonText>{text}</IonText>}

                    {showProgressBar && (
                        <IonSpinner
                            style={{
                                width: 50,
                                height: 50,
                                marginTop: 20,
                            }}
                            className="div-wrapper spinner-top-space"
                            name="crescent"
                            color="primary"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
