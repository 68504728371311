import { PouchDatabaseService } from "./pouchDatabase.service";
import { FitnessData, FitnessDataType } from "refsix-js-models";

type Dbs = {
    [FitnessDataType.GPS]: PouchDB.Database<FitnessData> | null;
    [FitnessDataType.HeartRate]: PouchDB.Database<FitnessData> | null;
};
const dbs: Dbs = {
    [FitnessDataType.GPS]: null,
    [FitnessDataType.HeartRate]: null,
};
// This is to avoid DBs being created just from this file being required. Now, something has to call a function in this module for the DBs to be created.
// This has been causing Heisenbugs for a while
function _getOrCreateDB(
    dataType: FitnessDataType
): PouchDB.Database<FitnessData> {
    if (!dbs[dataType]) {
        dbs[dataType] = createDatabase(dataType);
    }
    const db = dbs[dataType];
    if (db) {
        return db;
    }
    throw new Error(`Unknown DB ${dataType}`);
}
async function _recreateDB(dataType: FitnessDataType): Promise<void> {
    const db = dbs[dataType];
    if (db) {
        try {
            await db.destroy();
        } catch (e) {
            console.error("Unable to distroy DB", e);
        }
    }
    dbs[dataType] = createDatabase(dataType);
}

/**
 * This function maintains the same local database names as the old app so that they should carry over
 */
function getDatabaseName(dataType: FitnessDataType): string {
    switch (dataType) {
        case FitnessDataType.GPS:
            return "gps";
        case FitnessDataType.HeartRate:
            return "heartRate";
    }
}

function createDatabase(dataType: FitnessDataType) {
    let databaseName = getDatabaseName(dataType);

    const database =
        new PouchDatabaseService().createLocalDatabase<FitnessData>(
            databaseName,
            {
                auto_compaction: true,
            }
        );
    database.createIndex({
        index: { fields: ["synced"] },
    });

    return database;
}

export function getDatabase(dataType: FitnessDataType) {
    return _getOrCreateDB(dataType);
}

export async function removeDatabase(dataType: FitnessDataType) {
    await _recreateDB(dataType);
}
