import {
    GoogleLoginResponse,
    SocialLogin,
} from "@capgo/capacitor-social-login";

import React, { FC, useCallback } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { Provider, socialLogin } from "../../../services/authService";
import { UserProfile } from "../../../models/userProfile";
// Import SVG
import logo from "../../../assets/images/google-icon.svg";
import { useTranslation } from "react-i18next";
import { isAndroid, isIos } from "../../../services/platformDetection";

const liveEnv: string[] = [];
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_IOS_CLIENT_ID = process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID;

export type SocialOauthRequestType = "Login" | "Register";
export type SocialLoginProps = {
    requestType: SocialOauthRequestType;
    showSpinner?: boolean;
    login: (
        profile: Partial<UserProfile>,
        auth: any,
        provider: Provider
    ) => Promise<void>;
};

type SocialLoginGoogleProps = SocialLoginProps & {
    onSignIn?: (email: string, next: () => void) => void;
};

export const Google: FC<SocialLoginGoogleProps> = ({
    requestType,
    showSpinner = false,
    onSignIn,
    login,
}) => {
    const { t } = useTranslation();

    const handleGoogleLogin = useCallback(async () => {
        let googleUser: any | null;
        // Show popup/ or directly return the credentials
        try {
            if (isIos()) {
                await SocialLogin.initialize({
                    google: {
                        iOSClientId: GOOGLE_IOS_CLIENT_ID,
                    },
                });
            } else {
                await SocialLogin.initialize({
                    google: {
                        webClientId: GOOGLE_CLIENT_ID,
                    },
                });
            }

            const googleUserReq = await SocialLogin.login({
                provider: "google",
                options: {
                    scopes: ["profile", "email"],
                },
            });
            googleUser = googleUserReq;
        } catch (e) {
            console.error(e);
            return;
        }

        if (googleUser?.provider !== "google") {
            return;
        }
        const response: GoogleLoginResponse =
            googleUser.result as GoogleLoginResponse;

        const next = async () => {
            if (googleUser?.provider !== "google") {
                return;
            }
            const response: GoogleLoginResponse =
                googleUser.result as GoogleLoginResponse;
            const profile = response.profile;
            let userId = response.accessToken?.userId;
            if (isAndroid()) {
                if (response.idToken) {
                    const idToken = response.idToken;
                    const payload = idToken.split(".")[1];
                    const decodedPayload = atob(payload);
                    const parsedPayload = JSON.parse(decodedPayload);
                    userId = parsedPayload.sub;
                }
            }
            const user: UserProfile & { id: any } = {
                email: profile.email || "",
                username: "",
                country: "",
                firstName: profile.givenName || "",
                lastName: profile.familyName || "",
                localFA: "",
                nationalFA: "",
                refereeLevel: "",
                id: userId,
            };
            const auth = {
                access_token: response.accessToken,
            };
            await login(user, auth, "google");
        };
        if (onSignIn) {
            return onSignIn(response.profile.email || "", next);
        }
        return next();
    }, []);

    return (
        <IonButton onClick={handleGoogleLogin} color="login" expand="block">
            <IonIcon src={logo} slot="start" />
            {requestType === "Register"
                ? t("register.registerOrLogin.continueWithGoogle")
                : t("register.registerOrLogin.signInWithGoogle")}
        </IonButton>
    );
};
