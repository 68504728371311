export const URLS = {
    ROADMAP: "https://refsix-beta.nolt.io/roadmap",
    FAQS: "https://refsix.zendesk.com/hc/en-us",
    FEEDBACK: "https://feedback.refsix.com/sso/",
    TERMSCONDITIONS: "https://refsix.com/terms-conditions",
    PRIVACYPOLICY: "https://refsix.com/terms-conditions-1",
    MERCHANDISESHOP: "https://refsix-merch-store.creator-spring.com/",
    DBU_API_KEY: "https://assets.refsix.com/dbu/how_to_find_api_key.pdf",
    REFSIX_ACCOUNT: "https://account.refsix.com/",
    REFSIX_TEMPLATE_DOWNLOAD:
        "https://assets.refsix.com/app-content/REFSIXUploadMatches2024.xlsx",
    MGM_TERMS: "https://refsix.com/referral-ts-and-cs",
};
