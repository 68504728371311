import {
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    MisconductCodes as MisconductCodesModel,
    Reason,
    ReasonType,
} from "refsix-js-models";
import { HeaderComponent } from "../../../../components";
import { routes } from "../../../../route/constants";
import incidentYellowImg from "../../../../assets/images/event-icons/incident-yellow.png";
import incidentRedImg from "../../../../assets/images/event-icons/incident-red.png";
import incidentSecondYellowImg from "../../../../assets/images/event-icons/incident-second-yellow.png";
import { useSelector } from "react-redux";
import { RefsixState } from "../../../../redux/models/refsixState";
import { updateSettings } from "../../../../services/settingsService";
import { getUserSelectedCodes } from "../../../../utils/Misconduct";
import { getMisconductCodes } from "../../../../services/misconductService";

export enum SegmentName {
    Players = "players",
    Officials = "officials",
}

export const MisconductCodes: FC = () => {
    const { t } = useTranslation();
    const router = useIonRouter();

    const settings = useSelector((state: RefsixState) => state.settings);

    const misconductCodes = getMisconductCodes();

    // Default to first code if default is not found (shouldn't be the case)
    const [selectedMisconductCode, setSelectedMisconductCode] = useState(
        getUserSelectedCodes() || misconductCodes[0]
    );
    const [selectedSegment, setSelectedSegment] = useState(SegmentName.Players);

    const handleMisconductCodeChange = (codeId: string) => {
        const code = misconductCodes.find(
            (code) => code.id === codeId
        ) as MisconductCodesModel;

        setSelectedMisconductCode(code);

        updateSettings({
            ...settings,
            settings: {
                ...settings.settings,
                misconductCode: code.id,
            },
        });
    };

    const renderCodes = (type: ReasonType) => {
        const selectTeamOfficials = selectedSegment === SegmentName.Officials;
        const filteredCodes = selectedMisconductCode.codes.filter(
            (code) =>
                code.type === type &&
                (selectTeamOfficials ? code.teamOfficial : !code.teamOfficial)
        );

        return filteredCodes.map((code, i) => (
            <div key={i}>
                <IonItem lines={"full"} data-testid={`code-${code.code}`}>
                    <IonText className="text-small">
                        {code.code} - {code.reason}
                        {code.sinBin &&
                            `(${t("fixture.FixtureSummary.sinBinShort")})`}
                    </IonText>
                    {code.secondYellow && (
                        <img
                            src={incidentSecondYellowImg}
                            alt={t("settings.MisconductEdit.secondYellow")}
                            width={20}
                        />
                    )}
                </IonItem>
                {renderSubCodes(code)}
            </div>
        ));
    };

    const renderSubCodes = (code: Reason) =>
        code.subCodes?.map((subCode, i) => (
            <IonItem
                lines="full"
                key={i}
                data-testid={`subcode-${subCode.code}`}
            >
                <IonText className="indent-left text-small">
                    {subCode.code} - {subCode.reason}
                </IonText>
            </IonItem>
        ));

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMore}
                title={t("settings.MisconductSettings.title")}
                titleTestId="misconduct-page-header"
            />
            <IonContent>
                <IonCard>
                    <IonItem
                        button
                        onClick={() =>
                            router.push(
                                routes.refsixMoreSettingsEditCustomMisconductCodes
                            )
                        }
                        data-testid="edit-codes-button"
                        lines="full"
                        className="item-title"
                    >
                        <IonLabel>
                            {t("settings.MisconductSettings.edit")}
                        </IonLabel>
                    </IonItem>

                    <IonItem lines="none" className="item-title">
                        <IonLabel>
                            {t("settings.MisconductSettings.selected")}
                        </IonLabel>
                        <IonSelect
                            className="select-text"
                            onIonChange={(e) =>
                                handleMisconductCodeChange(e.detail.value)
                            }
                            interface="popover"
                            value={selectedMisconductCode.id}
                            data-testid="misconduct-select"
                        >
                            {misconductCodes.map((code, i) => (
                                <IonSelectOption key={i} value={code.id}>
                                    {code.name}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>
                </IonCard>

                {selectedMisconductCode.systemB && (
                    <IonCard
                        className="info-box"
                        data-testid="misconduct-info-box"
                    >
                        <IonItem className="text-small" lines="none">
                            <IonText>
                                {`${t(
                                    "settings.MisconductSettings.systemBSelected"
                                )} (${t(
                                    "fixture.FixtureSummary.sinBinShort"
                                )})`}
                            </IonText>
                        </IonItem>{" "}
                    </IonCard>
                )}

                <IonCard>
                    <IonSegment
                        onIonChange={(e) =>
                            setSelectedSegment(e.detail.value as SegmentName)
                        }
                        value={selectedSegment}
                    >
                        <IonSegmentButton
                            value={SegmentName.Players}
                            data-testid="players-segment"
                        >
                            <IonLabel>
                                {t("settings.TemplateSettings.players")}
                            </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={SegmentName.Officials}
                            data-testid="officials-segment"
                        >
                            <IonLabel>
                                {t("fixture.FixtureTeam.teamOfficials")}
                            </IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonCard>

                <IonCard>
                    <IonItem lines="full">
                        <IonLabel>
                            {t("fixture.FixtureSummary.yellowFull")}
                            <img
                                src={incidentYellowImg}
                                alt="Yellow Card"
                                className="label-image"
                            />
                        </IonLabel>
                    </IonItem>
                    {renderCodes(ReasonType.yellow)}
                </IonCard>

                <IonCard>
                    <IonItem lines="full">
                        <IonLabel>
                            {t("fixture.FixtureSummary.red")}
                            <img
                                src={incidentRedImg}
                                alt="Red Card"
                                className="label-image"
                            />
                        </IonLabel>
                    </IonItem>
                    {renderCodes(ReasonType.red)}
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
