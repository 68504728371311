import storage from "redux-persist/lib/storage";
import { capacitorStore } from "../capacitorStore";
import * as Sentry from "@sentry/react";

// migrate from old local storage to new
export async function migrateData() {
    try {
        const oldState = await storage.getItem("persist:root");
        if (oldState) {
            await capacitorStore.setItem("persist:root", oldState);
            await storage.removeItem("persist:root");
        }
    } catch (error) {
        console.error("Error migrating data:", error);
        Sentry.captureMessage("Error migrating data: " + error);
    }
}
