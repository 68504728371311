import { SpeedCategoryNames } from "refsix-js-models";
import { SpeedCategoryConstants } from "../fitness/SpeedCategoryConstants";

export function SpeedCategoryFilter(): Function {
    return function (speed?: number): string {
        if (typeof speed !== "number" || isNaN(speed)) {
            return "n/a";
        }

        const _isBetween = function (
            key: SpeedCategoryNames,
            speed: number
        ): boolean {
            return (
                speed > SpeedCategoryConstants[key].min &&
                speed <= SpeedCategoryConstants[key].max
            );
        };

        // JSCR_HI_International_published.pdf page 2
        if (_isBetween(SpeedCategoryNames.stand, speed)) {
            return SpeedCategoryNames.stand;
        } else if (_isBetween(SpeedCategoryNames.walk, speed)) {
            return SpeedCategoryNames.walk;
        } else if (_isBetween(SpeedCategoryNames.jog, speed)) {
            return SpeedCategoryNames.jog;
        } else if (_isBetween(SpeedCategoryNames.run, speed)) {
            return SpeedCategoryNames.run;
        } else if (_isBetween(SpeedCategoryNames.fastRun, speed)) {
            return SpeedCategoryNames.fastRun;
        } else if (_isBetween(SpeedCategoryNames.sprint, speed)) {
            return SpeedCategoryNames.sprint;
        }
        return "n/a";
    };
}
