import {ChartOptions, ChartType, TooltipItem} from "chart.js";
import moment from "moment";
import {MatchPhone} from "refsix-js-models";
import {secondsToMinuteClock} from "refsix-core";

// Setting the different bar options for Sprint Bar and Speed Bar graph
export function barChartOptions(
    allResults: MatchPhone[],
    yGraphLabel: string,
    useImperial: boolean,
    unit?: "distance" | "time"
): ChartOptions<"bar"> {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    maxRotation: 80,
                    minRotation: 80,
                    color: "white",
                },
                stacked: true,
            },
            y: {
                title: {
                    display: true,
                    text: yGraphLabel,
                    color: "white",
                },
                ticks: {
                    color: "white",
                    callback: function (value, index, values) {
                        return value;
                    },
                },
                stacked: true,
            },
        },
        interaction: {
            mode: "index",
        },
        plugins: {
            legend: {
                position: "bottom" as const,
                display: true,
                labels: {
                    boxWidth: 12,
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem: TooltipItem<ChartType>) {
                        if (unit === "distance") {
                            const unit = useImperial ? " mi." : " km";
                            return tooltipItem.parsed.y.toString() + unit;
                        } else if (unit === "time") {
                            return secondsToMinuteClock(
                                tooltipItem.parsed.y * 60
                            );
                        }

                        return tooltipItem.parsed.y.toString();
                    },
                    title: function (tooltipItem: TooltipItem<ChartType>[]) {
                        let title = "DD MM";
                        if (
                            allResults &&
                            allResults[tooltipItem[0].dataIndex] &&
                            allResults[tooltipItem[0].dataIndex].date
                        ) {
                            title = moment(
                                allResults[tooltipItem[0].dataIndex].date
                            ).format("DD MMM.");
                        }
                        return title;
                    },
                },
            },
        },
    };
}
