import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import { createOutline, trashOutline } from "ionicons/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface EventToolbarProps {
    popoverId: string;
    editEvent: () => void;
    deleteEvent: () => void;
    dismiss: () => void;
}

export const EventToolbar: FC<EventToolbarProps> = ({
    popoverId,
    editEvent,
    deleteEvent,
    dismiss,
}) => {
    const { t } = useTranslation();
    const [displayConfirmation] = useIonAlert();

    return (
        <div
            style={{
                width: "100%",
                backgroundColor: "transparent",
                textAlign: "center",
            }}
        >
            <IonButton
                size="small"
                onClick={() => {
                    dismiss();
                    editEvent();
                }}
            >
                <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
            </IonButton>
            <IonButton
                size="small"
                onClick={() => {
                    displayConfirmation({
                        header: t("general.confirmDeletion.description"),
                        buttons: [
                            {
                                text: t("general.cancel"),
                                role: "cancel",
                                handler: () => {
                                    dismiss();
                                },
                            },
                            {
                                text: t("general.yes"),
                                role: "confirm",
                                handler: () => {
                                    dismiss();
                                    deleteEvent();
                                },
                            },
                        ],
                        onDidDismiss: () => dismiss(),
                    });
                }}
            >
                <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
            </IonButton>
        </div>
    );
};
