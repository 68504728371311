import {
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonProgressBar,
    IonText,
    useIonAlert,
    useIonModal,
    useIonRouter,
} from "@ionic/react";
import {
    bug,
    cloud,
    cog,
    document,
    gift,
    helpCircle,
    informationCircle,
    logOut,
    notifications,
    person,
    reader,
    refreshSharp,
    ribbon,
    share,
    shirt,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { logoutAndRedirect } from "../../../services/authService";
import { routes } from "../../../route/constants";
import { HeaderComponent } from "../../../components";
import "../fixtures.css";
import "../../../css/forms.css";
import {
    useDbUpdatedAt,
    useInstalledAppVersion,
    useIsBasic,
    useIsPro,
    useSessionExpired,
    useSubscription,
} from "../../../components/hooks";
import {
    trackEvent,
    trackProTeaser,
} from "../../../services/analytics/analyticsService";
import { now } from "moment";
import { NewFeatureContent } from "../../../components/NewFeatureContent";
import { URLS } from "../../../constants/urls";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
    useAppUpdateContext,
    useCurrentInAppVersion,
} from "../../../components/hooks/appVersionHooks";
import { setDeveloperToolsEnabled } from "../../../redux/actions/developerToolsAction";
import { MessageCount } from "../../../components/MessageCount";
import {
    checkForActiveFreeTrialSubscription,
    hasFeatureAccess,
    isUserPlusEligible,
} from "../../../services/subscriptionService";
import { IntegrationPartner } from "refsix-js-models";
import { stopAds } from "../../../services/AdMobService";
import { isAndroid, isIos } from "../../../services/platformDetection";
import { UpgradeBanner } from "../../../components/upgrade/UpgradeBanner";
import { shouldShowLinkYearInReview } from "../../../utils/review/review";
import { LinkToReview } from "../../../components/review/linkToReview";
import moment from "moment/moment";
import { Share } from "@capacitor/share";
import { isFeatureAvailableCurrentLanguage } from "../../../utils/FeatureToggle";
import {
    useEnrolledInMgm,
    useIsMgmAvailableInCountry,
} from "../../../components/hooks/mgmHooks";
import { MgmBanner } from "../../../components/mgm/MgmBanner";

function More() {
    const ref = useRef();
    const { t } = useTranslation();
    const route = useIonRouter();
    const dispatch = useDispatch();
    const installedAppVersion = useInstalledAppVersion();
    const openedAt = now();
    const subscription = useSubscription();
    const inAppVersion = useCurrentInAppVersion();
    // const hasDeveloperAccess = useHasFeatureAccess("developerTools");
    const [versionClickCount, setVersionClickCount] = useState<number>(0);
    const developerToolsEnabled = useSelector(
        (state: RootState) => state.developerTools.enabled
    );
    const allMatches = useSelector((state: RootState) => state.matches.matches);
    const hasAccessToFiles = hasFeatureAccess(
        IntegrationPartner.refassist + ":files"
    );
    const hasAccessToMessages = hasFeatureAccess("refAssistMember");
    const enrolledInMgm = useEnrolledInMgm();
    const mgmAvailableInCountry = useIsMgmAvailableInCountry();
    const isSessionExpired = useSessionExpired();
    const dbUpdatedAt = useDbUpdatedAt();

    const trackAndClose = () => {
        trackEvent("WhatsNewViewed", {
            previousVersion: installedAppVersion,
            newVersion: installedAppVersion,
            durationViewed: (now() - openedAt) / 1000,
        });
        if (dismiss) dismiss();
    };

    const isPro = useIsPro();
    const isBasic = useIsBasic();

    const [show, dismiss] = useIonModal(NewFeatureContent(trackAndClose));

    const [present] = useIonAlert();

    const { updateAvailable, updating, reload } = useAppUpdateContext();

    useEffect(() => {
        if (updateAvailable && !updating) {
            // update is downloaded and ready to install
            stopAds();
        }
    }, [updateAvailable, updating]);

    const handleLogoutClick = () =>
        present({
            header: t("more.logoutConfirm.header"),
            message: isSessionExpired
                ? t("login.sessionExpired.description", {
                      date: dbUpdatedAt
                          ? moment(dbUpdatedAt).format("DD MMM YYYY")
                          : "?",
                  })
                : t("more.logoutConfirm.body"),
            buttons: [
                t("more.logoutConfirm.cancel"),
                {
                    text: t("more.logoutConfirm.confirm"),
                    handler: async () => {
                        trackEvent("logout", {
                            category: "logout",
                            label: "standardLogout",
                        });
                        await logoutAndRedirect();
                    },
                    cssClass: "logout-confirm-button",
                },
            ],
        });

    // function called by onClick if clicked 5 times in a row will set hasDeveloperAccess to true
    const developerAccess = () => {
        // have a counter for how many times clicked
        if (versionClickCount === 4) {
            dispatch(setDeveloperToolsEnabled(true));
        } else if (versionClickCount < 4) {
            setVersionClickCount(versionClickCount + 1);
        }
    };

    const handleShare = async () => {
        try {
            const appForRefs = `REFSIX - ${t(
                "appMarketing.theAppForReferees"
            )}`;
            await Share.share({
                title: appForRefs,
                text: `${appForRefs} ${t("appMarketing.bestRefereeApp")} ${t(
                    "appMarketing.downloadNow"
                )}`,
                url: "https://download.refsix.com",
            });
        } catch (e) {
            console.error("Error sharing", e);
        }
    };

    return (
        <IonPage ref={ref}>
            <HeaderComponent
                title={t("fixture.FixtureSummaryEarnings.more")}
                titleTestId="more-page-header"
                showProBadge={subscription?.isPro}
                showPlusBadge={subscription?.isPlus}
            />
            <IonContent scrollY={false}>
                <IonContent>
                    {mgmAvailableInCountry && !enrolledInMgm && (
                        <MgmBanner></MgmBanner>
                    )}
                    <IonList>
                        <IonItem
                            button
                            onClick={() => route.push(routes.refsixMoreProfile)}
                            data-testid="account-button"
                        >
                            <IonIcon icon={person} slot="start" />
                            <IonLabel>{t("more.menu.account")}</IonLabel>
                        </IonItem>
                        {mgmAvailableInCountry && enrolledInMgm && (
                            <IonItem
                                button
                                onClick={() => {
                                    route.push(routes.referFriend);
                                }}
                                data-testid="account-button"
                            >
                                <IonIcon icon={gift} slot="start" />

                                <MessageCount slot="end" />
                                <IonLabel>{t("mgm.title")}</IonLabel>
                            </IonItem>
                        )}
                        {hasAccessToMessages && (
                            <IonItem
                                button
                                onClick={() =>
                                    route.push(routes.refsixMoreNotifications)
                                }
                                data-testid="account-button"
                            >
                                <IonIcon icon={notifications} slot="start" />
                                <MessageCount slot="end" />
                                <IonLabel>
                                    {t("notifications.notifications")}
                                </IonLabel>
                            </IonItem>
                        )}
                        {hasAccessToFiles && (
                            <IonItem
                                button
                                onClick={() =>
                                    route.push(routes.refsixRefAssistFiles)
                                }
                                data-testid="refassist-files-button"
                            >
                                <IonIcon icon={document} slot="start" />
                                <IonLabel>{t("more.menu.files")}</IonLabel>
                            </IonItem>
                        )}
                        <IonItem
                            button
                            onClick={() =>
                                route.push(routes.refsixMoreSettings)
                            }
                            data-testid="settings-button"
                        >
                            <IonIcon icon={cog} slot="start" />
                            <IonLabel>{t("general.settings")}</IonLabel>
                        </IonItem>
                        <IonItem
                            button
                            onClick={() =>
                                route.push(routes.refsixMoreIntegrations)
                            }
                            data-testid="integrations-button"
                        >
                            <IonIcon icon={cloud} slot="start" />
                            <IonLabel>{t("general.integrations")}</IonLabel>
                        </IonItem>
                        <IonItem
                            button
                            onClick={() => route.push(routes.refsixMoreSupport)}
                            data-testid="support-button"
                        >
                            <IonIcon icon={helpCircle} slot="start" />
                            <IonLabel>{t("more.menu.support")}</IonLabel>
                        </IonItem>
                        <IonItem
                            button
                            // TODO to be implemented
                            onClick={() => {
                                const openedAt = now();
                                show({
                                    onWillDismiss: () => {
                                        trackEvent("WhatsNewViewed", {
                                            triggeredFrom: "MorePage",
                                            previousVersion:
                                                installedAppVersion,
                                            newVersion: installedAppVersion,
                                            durationViewed:
                                                (now() - openedAt) / 1000,
                                        });
                                    },
                                });
                            }}
                            data-testid="features-button"
                        >
                            <IonIcon icon={reader} slot="start" />
                            <IonLabel>{t("help.WhatsNewModal.title")}</IonLabel>
                        </IonItem>
                        <IonItem
                            button
                            onClick={() => {
                                trackEvent("OpenMerchStore", {
                                    linkedFrom: "more",
                                });
                                window.open(URLS.MERCHANDISESHOP, "_system");
                            }}
                            data-testid="merchandise-button"
                        >
                            <IonIcon icon={shirt} slot="start" />
                            <IonLabel>{t("more.menu.shop")}</IonLabel>
                        </IonItem>
                        <IonItem
                            hidden={
                                isPro &&
                                !(
                                    subscription &&
                                    checkForActiveFreeTrialSubscription(
                                        subscription.subscriptions
                                    ).length > 0
                                )
                            }
                            button
                            onClick={() => {
                                if (isIos() || isAndroid()) {
                                    if (isUserPlusEligible() && !isBasic) {
                                        route.push(routes.upgrade + "/plus");
                                        trackProTeaser("more", "basic");
                                    } else if (
                                        !isUserPlusEligible() ||
                                        isBasic
                                    ) {
                                        route.push(routes.upgrade + "/pro");
                                        trackProTeaser("more", "pro");
                                    }
                                } else {
                                    window.open(
                                        URLS.REFSIX_ACCOUNT +
                                            "?utm_source=refsix&utm_medium=app&utm_campaign=in-app-mobile-web&utm_id=in-app-mobile-web",
                                        "_system"
                                    );
                                }
                            }}
                            data-testid="in-app-puchase-button"
                        >
                            <IonIcon icon={ribbon} slot="start" />
                            <IonLabel>{t("menu.upgrade")}</IonLabel>
                        </IonItem>
                        {(isIos() || isAndroid()) &&
                            isFeatureAvailableCurrentLanguage(
                                "shareThisApp"
                            ) && (
                                <IonItem
                                    button
                                    onClick={async () => handleShare()}
                                    data-testid="share-this-app"
                                >
                                    <IonIcon icon={share} slot="start" />
                                    <IonLabel>
                                        {t("general.shareThisApp")}
                                    </IonLabel>
                                </IonItem>
                            )}
                        <IonItem
                            button
                            onClick={handleLogoutClick}
                            data-testid="logout-button"
                        >
                            <IonIcon icon={logOut} slot="start" />
                            <IonLabel>{t("menu.logout")}</IonLabel>
                        </IonItem>
                        {(developerToolsEnabled || !inAppVersion) && (
                            <IonItem
                                routerLink={routes.developerTools}
                                data-testid="developer-tools"
                            >
                                <IonIcon icon={bug} slot="start" />
                                <IonLabel>Developer Tools</IonLabel>
                            </IonItem>
                        )}
                        <IonItem data-testid="app-version" lines="none">
                            <IonIcon icon={informationCircle} slot="start" />
                            <IonText onClick={developerAccess}>
                                {t("menu.version", {
                                    versionNumber: inAppVersion || "DEV",
                                })}
                            </IonText>
                            {updateAvailable && (
                                <IonButton
                                    slot="end"
                                    onClick={() => {
                                        stopAds();
                                        reload();
                                    }}
                                >
                                    <IonText>
                                        {t("fixture.FixtureNew.update")}
                                    </IonText>
                                    <IonIcon icon={refreshSharp}></IonIcon>
                                </IonButton>
                            )}
                        </IonItem>
                        {updating && (
                            <IonProgressBar type="indeterminate"></IonProgressBar>
                        )}
                    </IonList>
                    <div style={{ marginTop: 10 }}>
                        {shouldShowLinkYearInReview(new Date(), allMatches) && (
                            <LinkToReview />
                        )}
                        <UpgradeBanner />
                    </div>
                </IonContent>
            </IonContent>
        </IonPage>
    );
}

export default More;
