import { Player } from "../../../models";
import { t } from "i18next";

const INITIAL_TEAM_OFFICIAL_NUMBER = 1000;
const INITIAL_TEAM_OFFICIAL_SIZE = 4;

export function getDefaultTeamOfficials() {
    return [
        {
            name: t("fixture.FixtureTeam.manager"),
            value: "manager",
        },
        {
            name: t("fixture.FixtureTeam.assistantManager"),
            value: "assistantManager",
        },
        {
            name: t("fixture.FixtureTeam.coach"),
            value: "coach",
        },
        {
            name: t("fixture.FixtureTeam.physio"),
            value: "physio",
        },
    ];
}

export function createDefaultTeamOfficials() {
    let teamOfficials: Player[] = [];

    getDefaultTeamOfficials().forEach(function (initialTeamOfficial, index) {
        let teamOfficial = {
            captain: false,
            starting: false,
            number: INITIAL_TEAM_OFFICIAL_NUMBER + index,
            name: initialTeamOfficial.name,
            role: initialTeamOfficial.value,
            teamOfficial: true,
        };
        teamOfficials.push(teamOfficial);
    });
    return teamOfficials.slice();
}
