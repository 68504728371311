import { FC, useEffect, useState } from "react";
import { HeaderComponent, HeartRateZonesComponent } from "../../components";
import HeartRateImage from "../../assets/images/onboarding/heartrate.png";
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonProgressBar,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useMatch } from "../../components/hooks/match";
import { HrData } from "refsix-js-models";
import {
    FitnessDataProvider,
    useFitnessDataContext,
} from "../../components/hooks";
import { trackEvent } from "../../services/analytics/analyticsService";
import { routes } from "../../route/constants";
import "./onBoardingShareHealthData.css";
import { isIos } from "../../services/platformDetection";

const demoMatchId = "example-result1";

export const OnBoardingShareHealthDataWrapper: React.FC = () => {
    return (
        <FitnessDataProvider matchId={demoMatchId}>
            <OnBoardingShareHealthData />
        </FitnessDataProvider>
    );
};

export const OnBoardingShareHealthData: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const demoMatch = useMatch(demoMatchId);
    const [filteredHrData, setFilteredHrData] = useState<HrData>();
    const { hrData, loading } = useFitnessDataContext();

    useEffect(() => {
        console.log(`[OnBoardingShareHealthData] ${!!demoMatchId} ${!!hrData}`);
    }, [demoMatch, hrData]);

    useEffect(() => {
        if (demoMatch && demoMatch.matchFinished) {
            if (hrData) {
                if (
                    hrData.heartRateValues &&
                    hrData.heartRateValues.length > 0
                ) {
                    setFilteredHrData(hrData);
                }
            }
        }
    }, [hrData, demoMatch]);

    // var _areHealthPermissionsDetermined = function () {
    //     var keys = Object.keys(self.permissions.health);
    //     var result = false;
    //     keys.forEach(function (key) {
    //         if (
    //             self.permissions.health[key] !==
    //             PermissionsService.NOT_DETERMINED
    //         ) {
    //             result = true;
    //         }
    //     });
    //     return result;
    // };
    //
    // self.requestHealthPermissions = function () {
    //     if (_areHealthPermissionsDetermined()) {
    //         window.open("x-apple-health://", "_system"); // use inappbrowser plugin to open in safari
    //     } else {
    //         PermissionsService.requestIosWorkoutPermission().then(function (
    //             res
    //         ) {
    //             self.updatePermissions();
    //         });
    //     }
    // };

    const directToNextPage = () => {
        route.push(routes.refsixOnBoardingGoToWatch, "forward");
    };

    const askForPermission = async () => {
        if (isIos() && window.iospermissions) {
            setIsLoading(true);
            return new Promise((resolve, reject) => {
                window.iospermissions?.requestWorkoutPermissions(
                    (permissions: any) => {
                        setIsLoading(false);
                        trackEvent("shareHealthData", {});
                        directToNextPage();
                        resolve(permissions);
                    },
                    (error: any) => {
                        setIsLoading(false);
                        directToNextPage();
                        reject(error);
                    }
                );
            });
        }
        setIsLoading(false);
        directToNextPage();
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton={false}
                title={t("help.onBoarding.performanceData")}
            />
            <IonContent>
                <IonLoading isOpen={isLoading} />
                <IonCard color="transparent">
                    <IonProgressBar style={{ marginTop: 10 }} value={0.35} />
                </IonCard>
                <IonCard color="transparet">
                    <IonItem lines="none" color="transparet">
                        <IonLabel
                            className="align-center text-weight-bold"
                            color="primary"
                        >
                            {t("help.onBoarding.shareHealthData")}
                        </IonLabel>
                    </IonItem>{" "}
                    <IonItem lines="none" color="transparet">
                        <IonText className="align-center">
                            {t("help.onBoarding.trackFitness")}
                        </IonText>
                    </IonItem>
                </IonCard>

                {demoMatch && filteredHrData && (
                    <IonCard
                        data-testid="onboarding-heart-rate-graph"
                        className="onboarding-heart-rate"
                    >
                        {/*<HeartRateGraphComponent*/}
                        {/*    match={demoMatch}*/}
                        {/*    filteredHrData={filteredHrData}*/}
                        {/*/>*/}
                        <HeartRateZonesComponent
                            zoneDuration={
                                demoMatch.stats?.heartRateZoneDuration || []
                            }
                        ></HeartRateZonesComponent>
                    </IonCard>
                )}

                {demoMatch && !loading && (!demoMatch || !filteredHrData) && (
                    <IonCard
                        color="transparent"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IonItem lines="none" color="transparent">
                            <img src={HeartRateImage} alt="HearRate Image" />
                        </IonItem>
                    </IonCard>
                )}

                <IonCard color="transparent"></IonCard>
            </IonContent>
            <IonFooter>
                <IonButton
                    expand="block"
                    data-testid={"share-health-data-next-button"}
                    onClick={() => askForPermission()}
                >
                    {t("general.next")}
                </IonButton>
            </IonFooter>
        </IonPage>
    );
};
