import { UPDATE_FILTERS_MATCHES, UPDATE_FILTERS_RESULTS } from "./types";
import { FiltersModel } from "../models/filtersModel";

export type setFiltersMatchesAction = {
    type: string;
    filters: FiltersModel;
};

export const setMatchesFilters = (
    filters: FiltersModel
): setFiltersMatchesAction => ({
    type: UPDATE_FILTERS_MATCHES,
    filters,
});

export const setResultsFilters = (
    filters: FiltersModel
): setFiltersMatchesAction => ({
    type: UPDATE_FILTERS_RESULTS,
    filters,
});
