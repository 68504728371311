import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonPage,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import "../login/login.css";
import goProTrendsImage from "../../assets/images/go-pro/trends-go-pro-1.png";
import { GoProBenefits } from "../../components/upgrade/GoProBenefits";
import "./register.css";
import { GoProModal } from "../../modals/GoProModal";
import { routes } from "../../route/constants";
import { useUsername } from "../../components/hooks";
import { trackEvent } from "../../services/analytics/analyticsService";
import { HeaderComponent } from "../../components";
import { OnBoardingHeadingComponent } from "../onBoarding/onBoardingHeadingComponent";
import { useDispatch } from "react-redux";
import { endOnboarding } from "./registreFreeTrialHelper";

const RegisterUpgrade: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [planSelectModalOpen, setPlanSelectModalOpen] =
        useState<boolean>(false);
    const username = useUsername();
    const fourteenDayPlanName = t("upgrade.upgrade.freeTrailHeading");
    const dispatch = useDispatch();

    /**
     * User clicked no thanks, so we try and give a free trial anyway.
     * If anything goes wrong we redirect to the fixtures page.
     * If we succeed we redirect to the free trial confirmation page.
     */

    const paymentModalDismissed = (purchased: boolean) => {
        setPlanSelectModalOpen(false);
        if (purchased) {
            // we can close this page
            trackEvent("RegistrationUpgradePurchased", {});
            route.push(
                routes.refsixOnBoardingSetupComplete,
                "forward",
                "replace"
            );
        } else {
            trackEvent("RegistrationUpgradeAborted", {});
        }
    };

    return (
        <IonPage>
            <HeaderComponent title={t("menu.upgrade")} />
            <IonContent data-testid="registerUpgradePage">
                <OnBoardingHeadingComponent progressValue={0.7} />
                {/*<div className="align-center">*/}
                {/*    <img*/}
                {/*        src={refsixProLogo}*/}
                {/*        alt={t("menu.upgrade")}*/}
                {/*        style={{ height: "110px" }}*/}
                {/*    ></img>*/}
                {/*</div>*/}

                <IonCard
                    style={{ backgroundColor: "#2c2c2c" }}
                    className="align-center"
                >
                    <img src={goProTrendsImage}></img>
                </IonCard>

                <div>
                    <GoProBenefits longTranslations={true} />
                </div>
            </IonContent>
            <IonFooter>
                <div className="align-center" style={{ marginTop: 0 }}>
                    <IonButton
                        fill="clear"
                        size="small"
                        className="no-thanks"
                        data-testid="registerUpgradeNoButton"
                        onClick={() =>
                            endOnboarding(username, dispatch, route, t)
                        }
                    >
                        {t("general.noThanks")}
                    </IonButton>
                </div>

                <div className="align-center">
                    <IonButton
                        expand="block"
                        data-testid="registerUpgradeYesButton"
                        onClick={() => {
                            trackEvent("RegistrationUpgradeSelectPlan", {});
                            setPlanSelectModalOpen(true);
                        }}
                    >
                        {t("menu.upgrade")}
                    </IonButton>
                </div>
            </IonFooter>
            <GoProModal
                openModal={planSelectModalOpen}
                onDismiss={paymentModalDismissed}
                showContent={false}
                breakpoint={0.4}
            />
        </IonPage>
    );
};

export default RegisterUpgrade;
