import { FC, useEffect, useState } from "react";
import { GpsStats, HalfEvent, MatchPhone } from "refsix-js-models";
import { IonCol, IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import mapEventNameToDisplayName from "../utils/MapEventToDisplayName";
import { useTranslation } from "react-i18next";
import {
    BarElement,
    Chart as ChartJS,
    ChartType,
    Tooltip,
    TooltipItem,
} from "chart.js";
import { hasFeatureAccess } from "../services/subscriptionService";
import DistanceComponent from "./DistanceComponent";
import { fiveMinutesStats } from "../services/statsService";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { injectDummyData } from "../services/dummyDataService";
import { ProTeaser } from "./ProTeaser";
import { lockClosed } from "ionicons/icons";
import BarChartComponent from "./charts/BarChartComponent";

ChartJS.register(BarElement, Tooltip);
interface DistanceCoveredCInterface {
    match: MatchPhone;
    playedSegments: HalfEvent[];
    calculatedStats: GpsStats;
}

const DistanceCoveredComponent: FC<DistanceCoveredCInterface> = ({
    match,
    playedSegments,
    calculatedStats,
}) => {
    const { t } = useTranslation();
    const [minutesPlayed, setMinutesPlayed] = useState<number[]>([]);
    const [distanceCovered, setDistanceCovered] = useState<number[]>([]);
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );
    const distanceThroughMatchAccess = hasFeatureAccess("distanceThroughMatch");
    const exampleId = "example-result1";

    useEffect(() => {
        const minutesStats = fiveMinutesStats(calculatedStats, 5, useImperial);
        if (minutesStats) {
            setMinutesPlayed(minutesStats[0]);
            setDistanceCovered(minutesStats[1]);
        }
    }, [calculatedStats]);

    function hasMoreThanOneSegment() {
        return playedSegments && playedSegments.length > 1;
    }

    let data = {
        labels: minutesPlayed,
        datasets: [
            {
                label: "",
                data: distanceCovered,
                backgroundColor: "#d5e830",
                borderColor: "#d5e830",
                borderWidth: 1,
                barThickness: 5,
            },
        ],
    };
    // Replace data values with dummy data but keep labels etc
    if (!distanceThroughMatchAccess && match._id !== exampleId)
        data = injectDummyData(data);

    const options = {
        scales: {
            x: {
                title: { display: true, text: t("general.minsFromKickoff") },
                ticks: {
                    maxRotation: 80,
                    minRotation: 80,
                },
            },
            y: {
                title: {
                    display: true,
                    text: t(
                        `graphs.DistanceThroughMatchGraph.distanceScale.${
                            useImperial ? "imperial" : "metric"
                        }`
                    ),
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem: TooltipItem<ChartType>) {
                        let value =
                            tooltipItem.dataset.data[tooltipItem.dataIndex];

                        return (
                            Number(value).toFixed(0) +
                            " " +
                            t(
                                `graphs.DistanceThroughMatchGraph.distanceUnit.${
                                    useImperial ? "imperial" : "metric"
                                }`
                            )
                        );
                    },
                    title: function (tooltipItem: TooltipItem<ChartType>[]) {
                        return (
                            tooltipItem[0].label +
                            " - " +
                            (parseInt(tooltipItem[0].label) + 5) +
                            " " +
                            t("general.min")
                        );
                    },
                },
            },
        },
        animation: {
            duration: 100,
        },
    };

    // const myBar = useMemo(() => {
    //     return <BarChartComponent
    //         data={data}
    //         barChartOptions={options}
    //         testId="distance-covered-bar-chart"
    //     />
    // }, [data, options])
    function halvesDistance(): number[] {
        const stats = match.stats;
        if (!stats) return [];

        switch (match.periodsNo) {
            case "1":
                return [stats.distanceHalvesTotal];
            case "3":
                return stats.distanceByThirdsTotal;
            case "4":
                return stats.distanceByQuartersTotal;
            case "2":
            default:
                return stats.distanceByHalvesTotal;
        }
    }

    return (
        <>
            <IonItem lines="none">
                <IonText>
                    {t("fixture.FixtureSummaryStats.totalDistance")}
                </IonText>
                <IonText slot="end">
                    <DistanceComponent
                        distanceInMeters={match.stats?.distanceTotal || 0}
                    />
                </IonText>
            </IonItem>

            {playedSegments &&
                playedSegments.map((seg, index) => {
                    return (
                        <IonItem
                            key={index}
                            hidden={!hasMoreThanOneSegment}
                            lines="none"
                        >
                            <IonText slot="start">
                                {mapEventNameToDisplayName(seg, t)}
                            </IonText>
                            <IonText slot="end">
                                {distanceThroughMatchAccess ? (
                                    <DistanceComponent
                                        distanceInMeters={
                                            halvesDistance()[index]
                                        }
                                    />
                                ) : (
                                    <IonIcon icon={lockClosed} />
                                )}
                            </IonText>
                        </IonItem>
                    );
                })}

            <IonItem lines="none">
                <IonLabel>
                    {t("fixture.FixtureSummaryStats.distanceThroughMatch")}
                </IonLabel>
            </IonItem>

            <ProTeaser
                showTeaser={
                    !distanceThroughMatchAccess && match._id !== exampleId
                }
                teaserName="stats-distance"
            >
                <IonItem lines="none" className="item-title">
                    <IonCol>
                        <BarChartComponent
                            data={data}
                            barChartOptions={options}
                            testId="distance-through-match-bar-chart"
                            className={"match-bar-chart"}
                        />
                    </IonCol>
                </IonItem>
            </ProTeaser>
        </>
    );
};

export default DistanceCoveredComponent;
