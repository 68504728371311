import * as Sentry from "@sentry/react";
import { Match, MatchPhone, TemplateConfig } from "refsix-js-models";
import { getMatchById, updateMatch } from "../../matchService";
import { store } from "../../../redux/store";
import {
    createNewMatchForPhone,
    getFixtureResultCount,
    processEvents,
    updateMatchForPhone,
} from "refsix-core";
import { mpUpdatePerson } from "../../analytics/mixpanelService";
import { prepareBaseResponse } from "./prepareBaseResponse";
import { MessageTypes } from "../messageTypesEnum";
import { WatchMessage } from "../watchInterface";
import {
    trackEvent,
    trackHistoricalMatchFinished,
} from "../../analytics/analyticsService";
import { updateUserActionsCheckList } from "../../userActionsCheckListService";
import OneSignal from "onesignal-cordova-plugin";
import { isAndroid, isIos } from "../../platformDetection";

export interface ResultReceived extends WatchMessage {
    id: string;
    match: Match;
    hasPerformanceData?: boolean | undefined;
    device?: any; // TODO I don't think we actually use this
    template?: any;
    workout?: any;
}

export async function handleMatchFromWatch(
    watchMatch: Match,
    template?: TemplateConfig
) {
    const updatedMatch = await processWatchMatch(watchMatch, template);
    const userActionsCheckList = store.getState().userActionsCheckList;
    if (!updatedMatch) {
        const errorMsg = `[handleMatchFromWatch()] Couldn't process match`;
        trackEvent("processWatchMatchFail", errorMsg);
        console.log(errorMsg);
        Sentry.captureMessage(errorMsg);
        return;
    }

    const username = store.getState().auth?.session?.user_id;
    if (username) {
        // do all the mixpanel stuff
        trackEvent("receiveFromWatchSuccess", {
            category: "watch",
            label: "watch",
        });

        if (!updatedMatch.matchFinished) {
            trackEvent("receivedWatchMatchNotFinished", {
                category: "watch",
                label: updatedMatch._id,
            });
        } else {
            await trackHistoricalMatchFinished(
                username,
                updatedMatch,
                watchMatch
            );
            mpUpdatePerson(
                getFixtureResultCount(store.getState().matches.matches)
            );

            if (userActionsCheckList.matchFinishedCountWatch) {
                updateUserActionsCheckList({
                    matchFinishedCountWatch:
                        userActionsCheckList.matchFinishedCountWatch + 1,
                });
                if (isIos() || isAndroid()) {
                    OneSignal.InAppMessages.addTrigger(
                        "firstMatchFinishedPhone",
                        "true"
                    );
                }
            } else {
                updateUserActionsCheckList({ matchFinishedCountWatch: 1 });
            }
        }

        if (watchMatch.matchCreatedTs) {
            if (!userActionsCheckList.matchCreatedCountWatch) {
                updateUserActionsCheckList({
                    createdMatchOnWatch: true,
                    matchCreatedCountWatch: 1,
                });
                if (isIos() || isAndroid()) {
                    OneSignal.InAppMessages.addTrigger(
                        "firstMatchFinishedWatch",
                        "true"
                    );
                }
            } else {
                updateUserActionsCheckList({
                    matchCreatedCountWatch:
                        userActionsCheckList.matchCreatedCountWatch + 1,
                });
            }
        }
    } else {
        // No usename in redux state
        const errorMsg = `[handleMatchFromWatch()] Missing username in redux state for sending match to mixpanel`;
        console.log(errorMsg);
        Sentry.captureMessage(errorMsg);
    }

    return updatedMatch;
}

export function _processExistingMatch(
    match: MatchPhone,
    watchMatch: Match
): MatchPhone {
    match = updateMatchForPhone(
        match,
        watchMatch,
        watchMatch.device?.watchAppVersion || "",
        false
    );
    try {
        match = processEvents(match);
    } catch (e: any) {
        Sentry.captureMessage(
            `[resultReceived._processExistingMatch()] failed to process events for ${
                watchMatch.matchId
            } ${JSON.stringify(e)}`
        );
    }
    return match;
}

export function _processNewMatch(
    watchMatch: Match,
    template: TemplateConfig
): MatchPhone {
    let match: MatchPhone = createNewMatchForPhone(watchMatch, template);
    try {
        match = processEvents(match);
    } catch (e) {
        Sentry.captureMessage(
            `[resultReceived._processNewMatch()] failed to process events for ${
                watchMatch.matchId
            } ${JSON.stringify(e)}`
        );
    }
    return match;
}

async function processWatchMatch(watchMatch: Match, template?: TemplateConfig) {
    const matchId = watchMatch.matchId.split("_")[0];

    let fixture = undefined;
    try {
        fixture = (await getMatchById(matchId)) as MatchPhone;
    } catch (error) {
        // this can be legit if the match was created on the watch
    }
    if (fixture && fixture.matchFinished) {
        console.log("Match already finished.");
        Sentry.addBreadcrumb({
            message:
                "[processWatchMatch()] Match was marked is finished in DB but trying to sync again",
        });
    }

    let updatedMatch: MatchPhone;
    if (fixture) {
        // Update a fixture that already exists in user's database
        updatedMatch = _processExistingMatch(fixture, watchMatch);
    } else {
        // this match was created on the watch, or the user had deleted it from their phone.
        // if deleted, pouchdb lets us put a new doc over the top without knowing the latest _rev
        template = template || TemplateConfig.fromMatch(watchMatch);
        updatedMatch = _processNewMatch(watchMatch, template);
    }

    if (!updatedMatch) {
        const errorMsg = `[processWatchMatch()] There was no match to update in the database`;
        console.log(errorMsg);
        Sentry.captureMessage(errorMsg);
        return;
    }

    return updateMatch(updatedMatch);
}

export async function handleResultReceived(
    request: ResultReceived,
    token: any
) {
    const message = {
        requestId: request.id,
    };
    if (typeof request.match !== "object") {
        console.log("Problem, didn't get match back");
        const errorMsg =
            "[WatchService._handleResult()] Problem, didn't get match back";
        trackEvent("receiveFromWatchFail", errorMsg);
        Sentry.captureMessage(errorMsg);
        return;
    }

    const result = await handleMatchFromWatch(request.match, request.template);

    if (!result) {
        const errorMsg =
            "[WatchService._handleResult()] Problem, didn't get match back from processing";
        trackEvent("receiveFromWatchFail", errorMsg);
        Sentry.captureMessage(errorMsg);
        return;
    }

    return prepareBaseResponse(
        message,
        MessageTypes.RESULT_RECEIVED,
        request,
        token
    );
}
