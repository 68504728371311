export const routes = {
    login: "/login",
    registerOrLogin: "/register-or-login",
    register: "/register",
    registerStepTwo: "/register/step-two",
    registerUpgrade: "/register/upgrade",
    registerFreeTrial: "/register/free-trial",
    upgrade: "/upgrade",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
    match: "/match",
    createMatch: "/create-match",
    addResult: "/add-result",
    addMultipleMatches: "/add-multiple-matches",
    addResultDetail: "/formDetail",
    mockWatchMessaging: "/mock-watch",
    teamPacks: "/manage/teamPacks",
    developerTools: "/developer-tools",
    pouchDebug: "/pouch-debug",
    pouchDebugMatch: "/pouch-debug-match",
    refsixMoreProfile: "/more/profile",
    refsixMoreNotifications: "/more/notifications",
    refsixMoreSubscriptions: "/more/subscriptions",
    refsixSubscriptionCancel: "/subscriptions/cancel",
    refsixMoreSettings: "/more/settings",
    refsixMoreWatchDebug: "/more/watch-debug",
    startWatchPhone: "/more/start-watch-phone",
    refsixMoreSupport: "/more/support",
    refsixTimerOptions: "/settings/timer-options",
    refsixMoreSupportContact: "/support/contact",
    refsixMoreIntegrations: "/more/integrations",
    refsixAssignrIntegration: "/integrations/assignr",
    refsixAssignrIntegrationAutoStart: "/integrations/assignr/auto-start",
    refsixDbuIntegration: "/integrations/dbu",
    refsixStackSportsIntegration: "/integrations/stack-sports",
    refsixRefAssistIntegration: "/integrations/refassist",
    refsixRefAssistFiles: "/more/refassist-files",
    refsixMoreSettingsMisconductCodes: "/settings/misconduct-codes",
    refsixMoreSettingsEditCustomMisconductCodes:
        "/misconduct-codes/edit-custom",
    refsixMoreSettingsTemplates: "/settings/templates",
    refsixMoreSettingsTemplatesTemplate: "/templates/template",
    refsixAddEditTeamSheet: "/add-edit-teamsheet",
    refsixRequestTeamSheet: "/request-teamsheet",
    refsixResultsAddEvent: "/results/event",
    refsixAddResult: "/add-result",
    refsixAddResultDetail: "/add-result/detail",
    refsixMoreSettingsManageWatches: "/settings/manage-watches",
    refsixMoreSettingsManageWatchesPair: "/settings/watches/pair",
    refsixMoreSettingsSyncCalendar: "/settings/calendar-sync",
    refsixMoreSettingsPushConsent: "/settings/push-consent",
    refsix: "/refsix",
    refsixFixtures: "/refsix/fixtures",
    refsixResults: "/refsix/results",
    refsixTrends: "/refsix/trends",
    refsixMore: "/refsix/more",
    refsixMoreIAP: "/refsix/more/iap",
    refsixOnboardingGettingStarted: "/onboarding/getting-started",
    refsixOnboardingFoundWatch: "/onboarding/found-watch",
    refsixOnboardingNoWatchFound: "/onboarding/no-watch-found",
    refsixOnboardingPairWatch: "/onboarding/pair-watch",
    refsixOnBoardingShareLocationData: "/onboarding/share-location-data",
    refsixOnBoardingShareHealthData: "/onboarding/share-health-data",
    refsixOnBoardingTimerSettings: "/onboarding/timer-settings",
    refsixOnBoardingTimerSettingsStepTwo: "/onboarding/timer-settings-step-two",
    refsixOnBoardingSetupComplete: "/onboarding/setup-complete",
    refsixOnBoardingGoToWatch: "/onboarding/go-to-watch",
    refsixResultsFilters: "/results/filters",
    refsixFixturesFilter: "/fixtures/filters",
    refsixTrendsFilter: "/trends/filters",
    review: "/review",
    migrateSystemB: "/migrate-system-b",
    referFriend: "/refer-friend",
    addKnvbMatch: "/add-knvb-match",
};

export const sentryDsn =
    "https://3d0df40f7d7b47c68245f46935b924cf@o946727.ingest.sentry.io/6295187";
