import request from "../../request";
import {
    Message,
    MessageType,
    RefAssistAvailability,
    RefAssistCreateAvailability,
    RefAssistFile,
} from "refsix-js-models";
import moment from "moment-timezone";
import { store } from "../../../redux/store";
import { setClosedDates, setOpenDates } from "../../../redux/actions/matches";
import { t } from "i18next";
import { setMessages } from "../../../redux/actions/messagesAction";
import { AxiosResponse } from "axios";
import { hasFeatureAccess } from "../../subscriptionService";

const CLOSED_DATES_URL = "/integrations/refassist/closed-dates";
const OPEN_DATES_URL = "/integrations/refassist/open-dates";

export async function createClosedDates(
    closedDatesList: RefAssistCreateAvailability[]
) {
    return await request.post(CLOSED_DATES_URL, closedDatesList);
}

export async function createOpenDates(
    openDatesList: RefAssistCreateAvailability[]
) {
    return await request.post(OPEN_DATES_URL, openDatesList);
}

export async function deleteClosedDates(closedDateId: number) {
    return await request.delete(`${CLOSED_DATES_URL}/${closedDateId}`);
}

export async function deleteOpenDates(openDateId: number) {
    return await request.delete(`${OPEN_DATES_URL}/${openDateId}`);
}

export async function getTimeZoneId() {
    const timezone = moment.tz.guess();
    return await request.get<number>(
        `/integrations/refassist/timezones?name=${encodeURIComponent(timezone)}`
    );
}

/**
 * Filter a list of leaves (from Refassist open or closed dates) by a date
 * @param selectedDate YYYY-MM-DD
 * @param availabilities
 */
export function filterAvailabilityByDate(
    selectedDate: string,
    availabilities: RefAssistAvailability[]
) {
    const selectedDay = moment(selectedDate, "YYYY-MM-DD");
    return availabilities.filter((availability) => {
        const availabilityDate = moment(availability.day, "DD/MM/YYYY");
        return selectedDay.isSame(availabilityDate, "day");
    });
}

export async function getAndStoreDownloadedClosedAndOpenDates() {
    try {
        if (hasFeatureAccess("refAssist:openDates")) {
            const openDates = (await request.get(OPEN_DATES_URL))
                .data as RefAssistAvailability[];
            store.dispatch(setOpenDates(openDates));
        }
    } catch (e) {
        console.log("Error trying to download or store open dates", e);
    }
    try {
        if (hasFeatureAccess("refAssist:closedDates")) {
            const closedDates = (await request.get(CLOSED_DATES_URL))
                .data as RefAssistAvailability[];
            store.dispatch(setClosedDates(closedDates));
        }
    } catch (e) {
        console.log("Error trying to download or store closed dates", e);
    }
}

/**
 * Gets RefAssist Alerts and Messages in refsix format.
 */
export async function getRefAssistNotifications() {
    const { data } = await request.get("/integrations/refassist/notifications");
    const notificationsResponse = data as Message[];
    return notificationsResponse.map((message: Message) => {
        if (message.type === MessageType.Alert) {
            return {
                ...message,
                alertType: message.title,
                title:
                    t(`refAssistAlerts.notification.${message.title}`) ||
                    message.title,
            };
        }
        return message;
    });
}
export async function getAndStoreRefAssistNotifications() {
    const notifications = await getRefAssistNotifications();
    store.dispatch(setMessages(notifications));
}

export async function getFiles(): Promise<AxiosResponse<RefAssistFile[]>> {
    return await request.get("/integrations/refassist/files");
}

export async function getFileURL(
    fileKey: string
): Promise<AxiosResponse<string>> {
    return await request.get(
        `/integrations/refassist/files/${encodeURIComponent(fileKey)}`
    );
}
