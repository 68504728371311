import {
    IonButton,
    IonCard,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonText,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Reason, ReasonType } from "refsix-js-models";
import { close } from "ionicons/icons";
import { HeaderComponent } from "../../../../components";
import { RefsixState } from "../../../../redux/models/refsixState";
import { routes } from "../../../../route/constants";
import { SegmentName } from "./misconduct";
import { updateSettings } from "../../../../services/settingsService";

interface ReasonWithTempId extends Reason {
    tempId: number;
}

export const EditCustomMisconduct: FC = () => {
    const { t } = useTranslation();
    const [alert] = useIonAlert();
    const router = useIonRouter();

    const settings = useSelector((state: RefsixState) => state.settings);

    const [customCodes, setCustomCodes] = useState<ReasonWithTempId[]>(
        settings.settings.customCodes[0].codes.map((customCode, i) => ({
            ...customCode,
            tempId: i,
        }))
    );

    const [selectedSegment, setSelectedSegment] = useState(SegmentName.Players);

    const isCurrentSegment = (customCode: Reason) =>
        selectedSegment === SegmentName.Officials
            ? !!customCode.teamOfficial
            : !customCode.teamOfficial;

    const filterCodes = (type: ReasonType) => {
        // filter codes by type and if in currently selected segment
        return customCodes.filter(
            (customCode) =>
                customCode.type === type && isCurrentSegment(customCode)
        );
    };

    const handleCodeChange = (code: string, reason: string, id: number) => {
        setCustomCodes((customCodes) =>
            customCodes.map((customCode) =>
                customCode.tempId === id
                    ? { ...customCode, code, reason }
                    : customCode
            )
        );
    };

    const handleAddCode = (type: ReasonType) => {
        setCustomCodes((customCodes) => [
            ...customCodes,
            {
                type,
                teamOfficial:
                    selectedSegment === SegmentName.Officials
                        ? true
                        : undefined,
                secondYellow: false,
                code: "",
                reason: "",
                tempId: customCodes[customCodes.length - 1].tempId + 1, // simple numeric id that is always +1 of last id
            },
        ]);
    };

    const handleSave = () => {
        // filter out any codes with incomplete code or reason, and then remove tempId props from all
        const filteredCodes = customCodes
            .filter((customCode) => customCode.code && customCode.reason)
            .map(({ tempId, ...rest }) => rest) as Reason[];

        // check for duplicate codes and alert with error if found
        const uniqueCodes = new Set(
            filteredCodes.map((customCode) => customCode.code)
        );
        if (uniqueCodes.size < filteredCodes.length) {
            return alert({
                header: t("settings.MisconductEdit.failure.title"),
                message: t("settings.MisconductEdit.failure.description"),
                buttons: [
                    {
                        text: t("help.OnboardingVideo.controls.ok"),
                        cssClass: "ok-error-button",
                    },
                ],
            });
        }

        // update settings if no duplicates found
        updateSettings({
            ...settings,
            settings: {
                ...settings.settings,
                customCodes: [
                    {
                        ...settings.settings.customCodes[0],
                        codes: filteredCodes,
                    },
                ],
            },
        });
        router.goBack();
    };

    const handleSecondYellowSelect = (id: number) => {
        const isTeamOfficial = !!customCodes.find(
            (customCode) => customCode.tempId === id
        )?.teamOfficial;

        setCustomCodes((customCodes) => {
            // set all secondYellow props for selected segment and red reason type, to false
            return (
                customCodes
                    .map((customCode) =>
                        !!customCode.teamOfficial === isTeamOfficial &&
                        customCode.type === ReasonType.red
                            ? { ...customCode, secondYellow: false }
                            : customCode
                    )
                    // set the secondYellow of the custom code with given id to true
                    .map((customCode) =>
                        customCode.tempId === id
                            ? { ...customCode, secondYellow: true }
                            : customCode
                    )
            );
        });
    };

    const handleConfirmDelete = (id: number) => {
        setCustomCodes((reasons) =>
            reasons.filter((reason) => reason.tempId !== id)
        );
    };

    const handleDelete = (id: number, code: string, reason: string) => {
        // prompt user to confirm delete if both fields complete, otherwise just delete
        if (code && reason) {
            alert({
                header: t("general.confirmDeletion.title"),
                message: t("general.confirmDeletion.description"),
                buttons: [
                    t("general.cancel"),
                    {
                        text: t("general.delete"),
                        handler: () => handleConfirmDelete(id),
                        cssClass: "delete-confirm-button",
                    },
                ],
            });
        } else {
            handleConfirmDelete(id);
        }
    };

    const renderCodes = (type: ReasonType) =>
        filterCodes(type).map((customCode, i) => (
            <IonItem lines="full" key={i} className="text-small code-row">
                <IonInput
                    type="text"
                    value={customCode.code}
                    placeholder="--"
                    onIonChange={(e) =>
                        handleCodeChange(
                            e.detail.value || "",
                            customCode.reason,
                            customCode.tempId
                        )
                    }
                    name={`code-${customCode.tempId}`}
                    maxlength={3}
                    className="small-input code-input"
                />
                <IonInput
                    type="text"
                    className="reason-input"
                    value={customCode.reason}
                    placeholder="--"
                    onIonChange={(e) =>
                        handleCodeChange(
                            customCode.code,
                            e.detail.value || "",
                            customCode.tempId
                        )
                    }
                    name={`reason-${customCode.tempId}`}
                />
                <IonButton
                    slot="end"
                    onClick={() =>
                        handleDelete(
                            customCode.tempId,
                            customCode.code,
                            customCode.reason
                        )
                    }
                    data-testid={`delete-${customCode.tempId}-button`}
                >
                    <IonIcon icon={close} />
                </IonButton>
            </IonItem>
        ));

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMore}
                title={t("settings.MisconductEdit.title")}
                titleTestId="edit-misconduct-page-header"
                headerEndText={t("general.save")}
                onClickEndButton={handleSave}
                endBtnTestId="save-button"
            />
            <IonContent>
                <IonCard className="info-card">
                    <IonItem className="text-small" lines="none">
                        <IonText>
                            {t("settings.MisconductEdit.sendUsCodes")}{" "}
                            <a href="mailto:support@refsix.com?subject=Misconduct%20codes%20request">
                                {t("settings.MisconductEdit.contactUs")}
                            </a>
                        </IonText>
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonSegment
                        onIonChange={(e) =>
                            setSelectedSegment(e.detail.value as SegmentName)
                        }
                        value={selectedSegment}
                    >
                        <IonSegmentButton
                            value={SegmentName.Players}
                            data-testid="players-segment-button"
                        >
                            <IonLabel>
                                {t("settings.TemplateSettings.players")}
                            </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={SegmentName.Officials}
                            data-testid="officials-segment-button"
                        >
                            <IonLabel>
                                {t("fixture.FixtureTeam.teamOfficials")}
                            </IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonCard>

                <IonCard data-testid="customCodesYellow">
                    <IonItem lines="full">
                        <IonLabel>
                            {t("fixture.FixtureSummary.yellowFull")}
                        </IonLabel>
                        <IonButton
                            type="button"
                            onClick={() => handleAddCode(ReasonType.yellow)}
                            data-testid={`add-yellow-button-${selectedSegment}`}
                            slot="end"
                        >
                            {t("general.add")}
                        </IonButton>
                    </IonItem>
                    {renderCodes(ReasonType.yellow)}
                </IonCard>

                <IonCard data-testid="customCodesRed">
                    <IonItem lines="full">
                        <IonLabel>{t("fixture.FixtureSummary.red")}</IonLabel>
                        <IonButton
                            type="button"
                            onClick={() => handleAddCode(ReasonType.red)}
                            data-testid={`add-red-button-${selectedSegment}`}
                            slot="end"
                        >
                            {t("general.add")}
                        </IonButton>
                    </IonItem>
                    {renderCodes(ReasonType.red)}
                </IonCard>

                <IonCard>
                    <IonItem lines="full" className="item-title">
                        <IonLabel>
                            {t("settings.MisconductEdit.secondYellow")}
                        </IonLabel>
                        <IonSelect
                            className="select-text"
                            onIonChange={(e) =>
                                handleSecondYellowSelect(
                                    parseInt(e.detail.value)
                                )
                            }
                            interface="popover"
                            value={
                                filterCodes(ReasonType.red).find(
                                    (customCode) => customCode.secondYellow
                                )?.tempId
                            }
                            data-testid="second-yellow-select"
                        >
                            {filterCodes(ReasonType.red).map(
                                (customCode, i) => (
                                    <IonSelectOption
                                        key={i}
                                        value={customCode.tempId}
                                    >
                                        {customCode.code} - {customCode.reason}
                                    </IonSelectOption>
                                )
                            )}
                        </IonSelect>
                    </IonItem>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
