import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { lockClosed } from "ionicons/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import "../css/forms.css";
import { trackProTeaser } from "../services/analytics/analyticsService";
import { routes } from "../route/constants";
import "./ProTeaser.css";
import { isAndroid, isIos } from "../services/platformDetection";
import { URLS } from "../constants/urls";

export enum TeaserStyle {
    default = "default",
    button = "button",
}

interface ProTeaserProps {
    showTeaser: boolean;
    teaserName: string;
    children: JSX.Element;
    isRefSixEligible?: boolean;
    teaserStyle?: TeaserStyle;
}

export const ProTeaser: FC<ProTeaserProps> = ({
    showTeaser,
    teaserName,
    children,
    isRefSixEligible,
    teaserStyle = TeaserStyle.default,
}) => {
    const { t } = useTranslation();
    const route = useIonRouter();

    function teaserClicked() {
        if (isIos() || isAndroid()) {
            if (isRefSixEligible) {
                route.push(routes.upgrade + "/plus");
                trackProTeaser(teaserName, "plus");
            } else {
                route.push(routes.upgrade + "/pro");
                trackProTeaser(teaserName, "pro");
            }
        } else {
            window.open(
                URLS.REFSIX_ACCOUNT +
                    "?utm_source=refsix&utm_medium=app&utm_campaign=in-app-mobile-web&utm_id=in-app-mobile-web",
                "_system"
            );
        }
    }

    return !showTeaser ? (
        children
    ) : (
        <div
            className="pro-teaser"
            data-testid="pro-teaser"
            onClick={() => teaserClicked()}
        >
            <div className="pro-teaser-click-blocker"></div>
            <div className="semi-opaque">{children}</div>

            {teaserStyle === TeaserStyle.default && (
                <div className="pro-lock">
                    <IonIcon icon={lockClosed} />
                    {isRefSixEligible ? "PLUS / PRO" : "PRO"}
                    <div className="find-more-text">
                        {t("utils.ProTeaser.findOutMore")}
                    </div>
                </div>
            )}
            {teaserStyle === TeaserStyle.button && (
                <div className="teaser-button-container">
                    {t("accountNotification.featureUnavailable.description")}
                    <div style={{ position: "relative", zIndex: 1000 }}>
                        <IonButton
                            className="pro-teaser-button"
                            onClick={teaserClicked}
                        >
                            {t("menu.upgrade")}
                        </IonButton>
                    </div>
                </div>
            )}
        </div>
    );
};
