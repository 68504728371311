export type SpeedCategory = {
    lowerBound: number;
    upperBound: number;
    level: number;
};

export type SpeedCategoriesBounds = {
    low: SpeedCategory;
    medium: SpeedCategory;
    high: SpeedCategory;
};

export const SprintCategoryConstants: SpeedCategoriesBounds = {
    low: {
        lowerBound: 4.5,
        upperBound: 5.47,
        level: 1,
    },
    medium: {
        lowerBound: 5.47,
        upperBound: 6.97,
        level: 2,
    },
    high: {
        lowerBound: 6.97,
        upperBound: 12,
        level: 3,
    },
};
