import { IntegrationPartner } from "./enums/integrationPartner";

export class Integration {
    partner: IntegrationPartner;
    homeTeamId?: string | number;
    awayTeamId?: string | number;
    poolId?: string | number;
    matchId: string | number;
    participantId?: string | number;
    scoreId?: string;

    constructor(
        partner: IntegrationPartner,
        homeTeamId: string | number,
        awayTeamId: string | number,
        poolId: string | number,
        matchId: string | number,
        participantId: string | number
    ) {
        this.partner = partner;
        this.homeTeamId = homeTeamId;
        this.awayTeamId = awayTeamId;
        this.poolId = poolId;
        this.matchId = matchId;
        this.participantId = participantId;
    }

    static fromJSON(json: any): Integration {
        return new Integration(
            json.partner,
            json.homeTeamId,
            json.awayTeamId,
            json.poolId,
            json.matchId,
            json.participantId
        );
    }
}
