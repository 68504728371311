import {TFunction} from "i18next";

// Various dummy values
export const dummyHomeVsAway = (translateFunc: TFunction) =>
    `${translateFunc("general.home")} v ${translateFunc("general.away")}`;
export const dummyDate = "MM YY";
export const dummyPositionsString = [
    "?",
    "?",
    "?",
    "?",
    "?",
    "?",
    "?",
    "?",
    "?",
];
export const dummyPositionsNumber = [0, 0, 0, 0, 0, 0, 0, 0, 0];

/**
 * Return an array of 1's of given length
 */
export function createArrayOfOnes(length: number): number[] {
    return Array(length).fill(1);
}

/**
 * Replace array values with double dashes, from the start index
 */
export function replaceWithDashes(array: any[], startIndex: number = 0) {
    return [
        ...array.slice(0, startIndex),
        ...Array(array.length - startIndex).fill("--"),
    ];
}

interface ChartData {
    datasets: {
        data: number[];
    }[];
}

/**
 * Replaces all datasets.data arrays with [4, 8, 4, 8, etc...] but matching real data lengths
 */
export function injectDummyData<T extends ChartData>(data: T): T {
    return {
        ...data,
        datasets: data.datasets.map((dataset) => ({
            ...dataset,
            data: Array(dataset.data.length)
                .fill(0)
                .map((_, i) => (i % 2 === 0 ? 4 : 8)),
        })),
    };
}
