import { FC, useEffect, useState } from "react";
import { HrData, HrPoint, MatchPhone } from "refsix-js-models";
import { IonCol, IonIcon, IonItem, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { HeartRateFilter } from "../utils/filters/HeartRateFilter";
import mapEventNameToDisplayName from "../utils/MapEventToDisplayName";
import { getPlayingSegments, HeartRateProcessingService } from "refsix-core";
import { Line } from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip,
} from "chart.js";
import { hasFeatureAccess } from "../services/subscriptionService";
import { lockClosed } from "ionicons/icons";
import { injectDummyData } from "../services/dummyDataService";

export interface HeartRateComponentInterface {
    match: MatchPhone;
    filteredHrData: HrData | undefined;
    useDummyData?: boolean;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip
);

const HeartRateGraphComponent: FC<HeartRateComponentInterface> = ({
    match,
    filteredHrData,
    useDummyData,
}) => {
    const { t } = useTranslation();

    const heartRateAccess = hasFeatureAccess("heartRate");

    const [heartRateData, setHeartRateData] = useState<HrPoint[]>();
    const [hrMinutes, setHrMinutes] = useState<number[]>([]);
    const [hrBpm, setHrBpm] = useState<number[]>([]);

    const playedSegments = match.matchEvents && getPlayingSegments(match);
    const exampleId = "example-result1";
    function hasMoreThanOneSegment() {
        return playedSegments && playedSegments.length > 1;
    }

    // We set the graph to hidden as default until we have some data
    const [hideGraphAndZones, setHideGraphAndZones] = useState<boolean>(true);

    useEffect(() => {
        if (filteredHrData) {
            const summaryHR = new HeartRateProcessingService().summaryHR(
                filteredHrData.heartRateValues
            );

            const minutesPlayedArray: number[] = [];
            const hrBpmArray: number[] = [];

            summaryHR.heartRateMinutes.forEach((hr) => {
                minutesPlayedArray.push(hr.minute);
                hrBpmArray.push(hr.value);
            });

            setHrMinutes(minutesPlayedArray);
            setHrBpm(hrBpmArray);

            // Show the graph or zones if we have enough data
            setHideGraphAndZones(summaryHR.heartRateMinutes.length < 2);
            setHeartRateData(filteredHrData.heartRateValues);
        }
    }, [filteredHrData]);

    let data = {
        labels: hrMinutes,
        datasets: [
            {
                label: "",
                data: hrBpm,
                borderColor: "#ff6384",
                borderWidth: 2,
                backgroundColor: "rgba(255,99,132,.2)",
            },
        ],
    };

    // Replace data values with dummy data but keep labels etc
    if (!heartRateAccess && match._id !== exampleId)
        data = injectDummyData(data);

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: t("general.minsFromKickoff"),
                },
                ticks: {
                    callback: function (value: number | any) {
                        return value % 5 === 0 ? value : null;
                    },
                },
                min: 0,
            },
            y: {
                title: {
                    display: true,
                    text: t("graphs.HeartRateTimeGraph.yAxis"),
                },
                ticks: {
                    callback: function (value: number | any) {
                        return value;
                    },
                },
                min: 0,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
            line: {
                fill: true,
            },
        },
        tooltips: {
            enabled: false,
        },
    };

    function renderHeartRateGraph() {
        return (
            <>
                <div
                    hidden={heartRateData !== undefined}
                    data-testid="error-hr-code-2"
                >
                    <IonItem lines="none">
                        <IonText>
                            {t("fixture.FixtureSummaryStats.errorHRCode2")}
                        </IonText>
                    </IonItem>
                </div>
                <div
                    hidden={!heartRateData}
                    data-testid="heart-rate-peak-average"
                >
                    <IonItem lines="none">
                        <IonText>
                            {t("fixture.FixtureSummaryStats.peakHeartRate")}
                        </IonText>
                        <IonText slot="end">
                            {HeartRateFilter(match.stats?.heartRateMax || 0)}
                        </IonText>
                    </IonItem>
                    <IonItem lines="none">
                        <IonText>
                            {t("fixture.FixtureSummaryStats.averageHeartRate")}
                        </IonText>
                        <IonText slot="end">
                            {heartRateAccess || match._id === exampleId ? (
                                HeartRateFilter(
                                    match.stats?.heartRateAverage || 0
                                )
                            ) : (
                                <IonIcon icon={lockClosed} />
                            )}
                        </IonText>
                    </IonItem>

                    {playedSegments &&
                        playedSegments.map((seg, index) => {
                            return (
                                <IonItem
                                    lines="none"
                                    key={index}
                                    hidden={!hasMoreThanOneSegment}
                                >
                                    <IonText slot="start">
                                        {mapEventNameToDisplayName(seg, t)}
                                    </IonText>
                                    {heartRateData && (
                                        <IonText slot="end">
                                            {heartRateAccess ||
                                            match._id === exampleId ? (
                                                HeartRateFilter(
                                                    new HeartRateProcessingService().averageHeartRateBySegment(
                                                        seg,
                                                        heartRateData
                                                    )
                                                )
                                            ) : (
                                                <IonIcon icon={lockClosed} />
                                            )}
                                        </IonText>
                                    )}
                                </IonItem>
                            );
                        })}

                    <>
                        <IonItem
                            lines="none"
                            hidden={!hideGraphAndZones}
                            data-testid="error-hr-code-1"
                        >
                            <IonText>
                                {t("fixture.FixtureSummaryStats.errorHRCode1")}
                            </IonText>
                        </IonItem>
                        <IonItem
                            lines="none"
                            className="item-title"
                            hidden={hideGraphAndZones}
                        >
                            <IonCol>
                                {/* HERE IS THE GRAPH */}
                                <Line
                                    data={data}
                                    options={options}
                                    className="bar-chart"
                                />
                            </IonCol>
                        </IonItem>
                    </>
                </div>
            </>
        );
    }

    return renderHeartRateGraph();
};

export default HeartRateGraphComponent;
