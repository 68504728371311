import { IDatabaseService } from "./iDatabaseService";
import { PouchDatabaseService } from "./pouchDatabase.service";
import { isOnline } from "../connectionUtils";
import PouchDB from "pouchdb-browser";
import { pouchBackOff, ProcessedDocs, processUserDocs } from "./pouchUtils";
import { Observable, Observer } from "rxjs";
import { MatchPhone, Templates } from "refsix-js-models";
import {
    Settings,
    UserActionsCheckListState,
} from "../../redux/models/refsixState";
import * as Sentry from "@sentry/react";
import { trackConflictResolve } from "../analytics/analyticsService";
import { resolveMatchConflicts } from "refsix-core";
import { setSessionExpired } from "../../redux/actions/auth";
import { store } from "../../redux/store";

export type DatabaseDocType = (
    | MatchPhone
    | Settings
    | Templates
    | UserActionsCheckListState
) & {
    _id?: string;
    _rev?: string;
    _conflicts?: string[];
};

type MatchPhoneDB = MatchPhone & {
    _id: string;
    _rev: string;
};

export class RefereeDatabaseService implements IDatabaseService {
    localDB: PouchDB.Database<DatabaseDocType>;
    remoteDB: any;
    sync: any; // pouch db sync job
    running: boolean = false;
    readyDeferred: Promise<any>; // TODO replace with observable
    private _stream: Observable<any>;
    databaseObserver?: Observer<any>;
    static DATABASE_UPDATED_PAYLOAD = "docsUpdated";
    static DATABASE_LOGOUT_ERROR_PAYLOAD = "dbLogoutError";

    constructor() {
        const self = this;
        this.readyDeferred = Promise.resolve(); // TODO: implement Promise.defer properly with Observables rxjs
        this._stream = new Observable<any>((databaseObserver) => {
            self.databaseObserver = databaseObserver;
        });
        this.localDB = new PouchDatabaseService().createLocalDatabase(
            "local",
            {}
        );

        // TODO this might effect the coach view working
    }

    _docIsMatch(doc: DatabaseDocType): boolean {
        return !!(
            doc._id &&
            doc._id.indexOf("r6_") !== 0 &&
            doc._id.indexOf("_") !== 0
        );
    }

    _mergeConflicts(allVersions: MatchPhone[]) {
        if (!allVersions.length) {
            console.error("Tried to merge 0 versions");
            return;
        }

        let merged = allVersions[0];
        for (let i = 1; i < allVersions.length; i++) {
            // merge each revision in turn;
            merged = resolveMatchConflicts(merged, allVersions[i]);
        }
        return merged;
    }

    /**
     * @param winningDoc current winning doc
     * @param mergeTrigger what has triggered the merge
     * @param db can provide a db if you want to get revisions from remote for example. Defaults to localDB
     */
    _resolveDocConflicts(
        winningDoc: MatchPhone,
        mergeTrigger: string,
        db?: PouchDB.Database<DatabaseDocType>
    ) {
        if (!winningDoc._id || !winningDoc._rev) {
            Sentry.captureMessage(
                `No id or rev for match when trying to merge conflicts`
            );
            console.log(`No id or rev for match`);
            return winningDoc;
        }
        const docId = winningDoc._id;
        const winningDocRev = winningDoc._rev;

        if (!winningDoc._conflicts) {
            console.log(`No conflicts found for ${winningDoc._id}`);
            return winningDoc;
        }

        const losingVersionPromises = winningDoc._conflicts.map((revision) => {
            const dbInstance = db || this.localDB;
            // return this.getDocById(docId, revision) as Promise<MatchPhoneDB>;
            return dbInstance.get<MatchPhoneDB>(docId, {
                rev: revision,
                conflicts: true,
            });
        });

        return Promise.all(losingVersionPromises)
            .then((losingVersions) => {
                const merged = this._mergeConflicts([
                    winningDoc,
                    ...losingVersions,
                ]);

                const docsToUpdate: MatchPhoneDB[] = [
                    // update winner
                    {
                        ...merged,
                        // put the merged document over the top of couchdb's winner
                        _rev: winningDocRev,
                        _conflicts: undefined,
                    } as MatchPhoneDB,

                    // delete losers
                    ...losingVersions.map((losingVersion) => {
                        return {
                            ...losingVersion,
                            _deleted: true,
                        } as MatchPhoneDB;
                    }),
                ];

                return this.localDB.bulkDocs(docsToUpdate);
            })
            .then(function (result) {
                trackConflictResolve(
                    "eventual",
                    docId,
                    winningDocRev,
                    mergeTrigger,
                    winningDoc.matchFinished
                );
                return Promise.resolve(result);
            })
            .catch(function (error) {
                Sentry.captureMessage(
                    `Unable to resolve eventual conflict by ${mergeTrigger} - ${JSON.stringify(
                        error
                    )}`
                );
                console.log("error getting conflicting revisions", error);
            });
    }

    _findConflicts(
        docsWithConflictsFlag: DatabaseDocType[],
        mergeTrigger: string
    ) {
        const docsWithConflicts: MatchPhone[] = [];
        docsWithConflictsFlag.forEach((doc: DatabaseDocType) => {
            // filter out settings, templates etc
            if (this._docIsMatch(doc) && doc._conflicts) {
                docsWithConflicts.push(doc as MatchPhone);
            }
        });
        // go and fetch all revisions
        const mergedDocsPromises = docsWithConflicts.map((winningDoc) => {
            return this._resolveDocConflicts(winningDoc, mergeTrigger);
        });
        return Promise.all(mergedDocsPromises);
    }

    startSequence(refDbUrl?: string, isNewLogin?: boolean): Observable<any> {
        const self = this;

        // @ts-ignore TODO figure out types
        self.remoteDB = new PouchDB(refDbUrl, { batch_size: 500 }); // look at batch

        let preSync;
        let docsWithConflicts: MatchPhone[] = [];

        // @ts-ignore
        if (window.Cypress) {
            /*
            This makes login a lot faster, under cypress testing, the session gets restored for every test which
            triggers the non-isNewLogin flow which does a lengthy replication from the start of history. This is slower
            and happens in the background so probably impacts the perfomance of app during tests too.
             */

            console.log("Testing under Cypress - forcing isNewLogin to true");
            isNewLogin = true;
        }

        if (isNewLogin) {
            /*
            When the user logs in, if they have a lot of matches, then a normal replication can be very slow as
            it downloads the whole revision tree from oldest to newest across many requests. We instead use allDocs
            to just get the latest revision from the remote database and insert that locally. This is much faster, but
            will not automatically handle docs that are in conflict (eventual conflict). We therefore need to check for
            conflicts and resolve them manually.
             */
            preSync = self.remoteDB
                .allDocs({
                    include_docs: true,
                    attachments: true,
                    conflicts: true,
                })
                .then((results: any) => {
                    console.log("all docs downloaded");
                    const docs = results.rows.map(function (doc: any) {
                        return doc.doc;
                    });

                    // check for conflicts and resolve
                    docs.forEach((doc: DatabaseDocType) => {
                        // filter out settings, templates etc
                        if (this._docIsMatch(doc) && doc._conflicts) {
                            docsWithConflicts.push(doc as MatchPhone);
                        }
                    });

                    return self.localDB.bulkDocs(docs, { new_edits: false });
                })
                .then(() => {
                    Sentry.addBreadcrumb({
                        category: "db-startup",
                        message: `Synced database at login with ${docsWithConflicts.length} conflicts`,
                    });
                    if (docsWithConflicts.length) {
                        console.log("Found conflicted docs on login");
                        // get the conflicting revisions from remote too and merge before we insert
                        return Promise.all(
                            docsWithConflicts.map((winningDoc) => {
                                return this._resolveDocConflicts(
                                    winningDoc,
                                    "startup-new-login",
                                    self.remoteDB
                                );
                            })
                        )
                            .then((result) => {
                                console.log("Handled conflicts on startup");
                            })
                            .catch((error) => {
                                Sentry.captureMessage(
                                    "Failed to handle manually resolving eventual conflicts after login"
                                );
                            });
                    } else {
                        return Promise.resolve();
                    }
                })
                .then(function (results: any) {
                    Sentry.addBreadcrumb({
                        category: "db-startup",
                        message: `Successfully loaded all docs after login and handled and conflicts`,
                    });
                    return Promise.resolve();
                })
                .catch((error: any) => {
                    Sentry.captureMessage(
                        `Failed to load all docs after login - ${JSON.stringify(
                            error
                        )}`
                    );
                    if (self.databaseObserver) {
                        self.databaseObserver.error(error);
                    }
                });
        } else {
            if (isOnline()) {
                Sentry.addBreadcrumb({
                    category: "db-startup",
                    message: `User already logged in - syncing database normally`,
                });
                preSync = Promise.all([
                    self.localDB.replicate.to(self.remoteDB, {
                        live: false,
                        retry: false,
                    }),
                    self.localDB.replicate.from(self.remoteDB, {
                        live: false,
                        retry: false,
                    }),
                ]);
            } else {
                preSync = Promise.resolve();
            }
        }

        const _done = () => {
            if (!self.databaseObserver) {
                console.log("databaseObserver isn't defined");
                return;
            }
            // first check for and merge conflicts

            this.localDB
                .allDocs({ conflicts: true, include_docs: true })
                .then(function (response) {
                    //{
                    //     "total_rows": 229,
                    //     "offset": 0,
                    //     "rows": [
                    //         {
                    //             "id": "01220fa9-5afa-c195-7ead-d3fe4bd15680",
                    //             "key": "01220fa9-5afa-c195-7ead-d3fe4bd15680",
                    //             "value": {
                    //                 "rev": "21-dc3e82d4cb939558046a4eb07af73188"
                    //             },
                    //             "doc": {
                    //                 "date": "2019-06-14T11:00:00.000Z"...
                    //             }
                    //         },
                    if (response.rows && response.rows.length) {
                        const docs = response.rows.map((row) => {
                            return row.doc;
                        });
                        self._findConflicts(
                            docs as DatabaseDocType[],
                            "startup"
                        )
                            .then(function () {
                                console.log(
                                    "Checked all docs for conflicts on startup"
                                );
                            })
                            .catch(function (error) {
                                console.log(
                                    "Failed to check all docs for conflicts on startup",
                                    error
                                );
                            });
                    }
                })
                .catch(function () {});

            self.databaseObserver.next(
                RefereeDatabaseService.DATABASE_UPDATED_PAYLOAD
            );

            _syncLive();
            // readyDeferred.resolve();
            self.readyDeferred = Promise.resolve();
        };

        preSync.then(_done).catch(function (err: any) {
            console.log(err);
            _done();
        });

        const _syncLive = () => {
            const options = {
                live: true,
                retry: true,
                batch_size: 20,
                back_off_function: pouchBackOff,
            };
            if (isNewLogin) {
                // stop full replication as we are up to date so don't need all history ever.
                // @ts-ignore
                options.pull = {
                    since: "now",
                };
            }
            self.sync = self.localDB.sync(self.remoteDB, options);

            self.localDB
                .changes({
                    since: "now",
                    live: true,
                    conflicts: true,
                    include_docs: true,
                })
                .on("change", (change) => {
                    if (!self.databaseObserver) {
                        console.log("databaseObserver isn't defined");
                        return;
                    }

                    if (
                        change.doc?._conflicts &&
                        this._docIsMatch(change.doc)
                    ) {
                        console.log(
                            "Eventual match conflict detected - attempting to merge",
                            change
                        );
                        self._resolveDocConflicts(
                            change.doc as MatchPhone,
                            "replication"
                        );
                    }

                    self.databaseObserver.next(
                        RefereeDatabaseService.DATABASE_UPDATED_PAYLOAD
                    );
                });

            self.sync.on("complete", function (info: any) {
                // handle 'complete' event i.e. everything went gravy --OR--
                // something went horrendously wrong (but pouch didn't fire 'error' or 'denied' event... :( )

                // check if you don't have access to the database (i.e. superlogin is happy but couch isn't)
                const pushError =
                    info &&
                    info.push &&
                    info.push.errors &&
                    info.push.errors.length &&
                    info.push.errors[0].status === 401;
                const pullError =
                    info &&
                    info.pull &&
                    info.pull.errors &&
                    info.pull.errors.length &&
                    info.pull.errors[0].status === 401;
                if (pushError || pullError) {
                    store.dispatch(setSessionExpired(true));

                    if (self.databaseObserver) {
                        self.databaseObserver.error(
                            RefereeDatabaseService.DATABASE_LOGOUT_ERROR_PAYLOAD
                        );
                    }
                }
            });

            self.sync.on("error", function (err: any) {
                console.log("Sync error", err);
                if (err.status === 401) {
                    store.dispatch(setSessionExpired(true));
                }
            });
        };

        return this._stream;
    }

    async destroySequence(): Promise<any> {
        try {
            await this.localDB.destroy();
        } catch (err) {
            Sentry.captureMessage(
                `Error destroying matches/referee DB: ${JSON.stringify(err)}`
            );
            console.log("Error destroying matches/referee DB");
        }
    }

    whenReady(): Promise<any> {
        throw new Error("Method not implemented.");
    }

    getLocalDatabase() {
        return this.localDB;
    }

    upsertDoc(
        doc: DatabaseDocType & PouchDB.Core.IdMeta
    ): Promise<PouchDB.UpsertResponse> {
        return this.localDB.upsert(doc._id, () => doc);
    }

    /**
     * @param docId the id of the document
     * @param revision optional document revision
     * @param conflicts optional true if you want to see if a document has conflicts
     * @return {*}
     */
    async getDocById<T>(
        docId: string,
        revision?: string,
        conflicts?: boolean
    ): Promise<(T & PouchDB.Core.IdMeta & PouchDB.Core.GetMeta) | null> {
        try {
            const doc = await this.localDB.get<T>(docId, {
                rev: revision,
                conflicts: conflicts,
            });
            return doc;
        } catch (err) {
            return null;
        }
    }

    getAllUserDocs(): Promise<ProcessedDocs> {
        return this.localDB
            .allDocs({
                include_docs: true,
            })
            .then(processUserDocs);
    }

    removeDoc(id: string, rev: string): Promise<any> {
        return this.localDB.remove(id, rev);
    }

    getStream(): Observable<any> {
        return this._stream;
    }
}
