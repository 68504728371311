import { ITeamPlayerMap, MatchPhone, Player } from "refsix-js-models";

export function findMostRecentTimestampInt(timestamps: string[]): number {
    var output = 0;

    timestamps.forEach(function (ts: string) {
        const intVal = parseInt(ts);
        if (intVal > output) {
            output = intVal;
        }
    });
    return output;
}

/**
 * Compares two matches and returns true if matchA should win. Used when picking the base match for merging a conflict
 * @param matchA
 * @param matchB
 */
export function matchAWins(matchA: MatchPhone, matchB: MatchPhone): boolean {
    const aEvents = matchA.matchEvents;
    const bEvents = matchB.matchEvents;

    // if b has events and a doesn't then b wins
    if (!aEvents && bEvents) {
        return false;
    }

    // if a has events and b doesn't then a wins or if neither have events then a wins
    if ((!bEvents && aEvents) || (!aEvents && !bEvents)) {
        return true;
    }

    if (aEvents && bEvents) {
        // @ts-ignore
        const aEventKeys = Object.keys(aEvents) || [];
        // @ts-ignore
        const bEventKeys = Object.keys(bEvents) || [];

        // pick the one with the most events
        if (aEventKeys.length !== bEventKeys.length) {
            return aEventKeys.length > bEventKeys.length;
        }

        const aLatestTimestamp = findMostRecentTimestampInt(aEventKeys);
        const bLatestTimestamp = findMostRecentTimestampInt(bEventKeys);

        // prefer the match with the most recent event
        if (aLatestTimestamp !== bLatestTimestamp) {
            return aLatestTimestamp > bLatestTimestamp;
        }
        // if equal fall through
    }

    // if not deterministically pick one
    if (matchA._rev && matchB._rev && matchA._rev !== matchB._rev) {
        // the winner should be the same regardless of which order A and B are
        // this should be similar to how couchdb picks a winner.
        return matchA._rev > matchB._rev;
    }

    // We should never get here in a real world case
    console.warn(
        "MatchConflictResolver Picking arbitrary winner as matches were identical based on our difference checks for " +
            matchA._id
    );
    return true;
}

/**
 * Takes two sets of players for the same team and combines them into a single list.
 * If a player from the loosing revision doesn't exist in the winning revision it will be added.
 * If a player in the winning revision has a default name and the loosing revision has a proper name, replace the name
 * @param winningPlayers Winning revision's players
 * @param losingPlayers Loosing revision's players
 */
export function mergePlayers(
    winningPlayers: Player[],
    losingPlayers: Player[]
): Player[] {
    const mergedPlayers = winningPlayers.slice(); // shallow copy of array

    if (mergedPlayers.length == 0) {
        return losingPlayers.slice();
    }

    losingPlayers.forEach(function (losingPlayer) {
        let existingIndex = -1;
        winningPlayers.forEach(function (winningPlayer, index) {
            if (winningPlayer.number === losingPlayer.number) {
                existingIndex = index;
            }
        });

        if (existingIndex >= 0) {
            // if a player with the same number exists then try and pick the best name
        } else {
            // add the player as not in the winning revision
            mergedPlayers.push(losingPlayer);
        }
    });

    return mergedPlayers;
}

export function combineAllPlayers(
    winner: MatchPhone,
    loser: MatchPhone
): ITeamPlayerMap {
    const winnerPlayers: ITeamPlayerMap = winner.players
        ? { ...winner.players }
        : {};
    const loserPlayers: ITeamPlayerMap = loser.players
        ? { ...loser.players }
        : {};

    // Ensure that there is a players object for all teams (e.g. if only one teamsheet on could be empty_
    winnerPlayers[winner.homeTeam] = winnerPlayers[winner.homeTeam] || [];
    winnerPlayers[winner.awayTeam] = winnerPlayers[winner.awayTeam] || [];
    loserPlayers[loser.homeTeam] = loserPlayers[loser.homeTeam] || [];
    loserPlayers[loser.awayTeam] = loserPlayers[loser.awayTeam] || [];

    return {
        [winner.homeTeam]: mergePlayers(
            winnerPlayers[winner.homeTeam],
            loserPlayers[loser.homeTeam]
        ),
        [winner.awayTeam]: mergePlayers(
            winnerPlayers[winner.awayTeam],
            loserPlayers[loser.awayTeam]
        ),
    };
}

export function resolveMatchConflicts(
    a: MatchPhone,
    b: MatchPhone
): MatchPhone {
    // Pick a winner based on the match events
    const aWins = matchAWins(a, b);

    const result = aWins ? MatchPhone.fromJSON(a) : MatchPhone.fromJSON(b);
    const winner = aWins ? a : b;
    const loser = aWins ? b : a;

    // Might help us with a match that had been deleted on the phone and then recovered from the watch
    result.competition =
        result.competition === "?" ? loser.competition : result.competition;
    result.venue = result.venue === "?" ? loser.venue : result.venue;

    // These fields can come from either match e.g. if one has the events and the other has tracking.
    result.matchFinished = a.matchFinished || b.matchFinished;
    // if the match is finished then we need to set the match in progress to false
    result.matchInProgress = result.matchFinished
        ? false
        : a.matchInProgress || b.matchInProgress;
    result.hasTracking = a.hasTracking || b.hasTracking; // if either is true we want to mark as true
    result.hasHeartRate = a.hasHeartRate || b.hasHeartRate;
    result.homeColor = winner.homeColor || loser.homeColor;
    result.awayColor = winner.awayColor || loser.awayColor;
    result.integration = winner.integration || loser.integration;
    result.assessment = winner.assessment || loser.assessment;

    result.earnings = winner.earnings || loser.earnings || {};
    // todo merge earnings sub properties

    result.players = combineAllPlayers(winner, loser);

    if (a.notes && b.notes && a.notes !== b.notes) {
        result.notes = a.notes + " \n" + b.notes;
    } else {
        result.notes = a.notes || b.notes;
    }
    const aKeywords = a.keywords || [];
    const bKeywords = b.keywords || [];

    // return a unique combination of keywords
    result.keywords = aKeywords
        .concat(bKeywords)
        .reduce(function (keywords: string[], keyword: string) {
            if (keywords.indexOf(keyword) < 0) {
                keywords.push(keyword);
            }
            return keywords;
        }, []);

    result.wellbeing = winner.wellbeing || loser.wellbeing;
    result.customMisconductCodes =
        winner.customMisconductCodes || loser.customMisconductCodes;

    result.matchOfficials = winner.matchOfficials || loser.matchOfficials;
    result.officialRole = winner.officialRole || loser.officialRole;

    result.appVersion = winner.appVersion || loser.appVersion;
    result.device = winner.device || loser.device;

    // force a rebuild of stats next time it gets loaded
    result.stats = undefined;

    a.refsixUpdateCount = a.refsixUpdateCount || 0;
    b.refsixUpdateCount = b.refsixUpdateCount || 0;
    result.refsixUpdateCount =
        Math.max(a.refsixUpdateCount, b.refsixUpdateCount) + 1;
    result.refsixCount = result.refsixUpdateCount;

    delete result._conflicts;
    result._deleted = false;

    return result;
}
