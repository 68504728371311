import { FC, useEffect } from "react";

interface UseBackButtonHandlerProps {
    handlerFunction: (
        event: PopStateEvent
    ) => void | ((event: PopStateEvent) => void);
    dependencies?: any[];
}

export const UseBackButtonHandler: FC<UseBackButtonHandlerProps> = ({
    handlerFunction,
    dependencies = [],
}) => {
    useEffect(() => {
        const backHandler = (event: PopStateEvent) => {
            handlerFunction(event);
        };

        window.addEventListener("popstate", backHandler);

        return () => {
            window.removeEventListener("popstate", backHandler);
        };
    }, [window.location.pathname]);

    return null;
};
