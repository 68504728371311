import { Middleware } from "redux";
import { CURRENT_STATS_VERSION, MatchPhone } from "refsix-js-models";
import { RefsixState } from "../models/refsixState";
import { LOADING_MATCHES, SET_MATCHES } from "../actions/types";
import { recalculateStats } from "../../services/matchService";
import { debounce, DebouncedFunction } from "ts-debounce";
import { some } from "lodash";
import * as Sentry from "@sentry/react";

const RECALC_STATS_DEBOUNCE_DELAY = 5000;

export const _recalcStats: DebouncedFunction<
    [store: any],
    () => Promise<void>
> = debounce(async (store): Promise<void> => {
    // find all matches that need an update to their stats
    const matches = store
        .getState()
        .matches.matches.filter((match: MatchPhone) => {
            // only update finished matches with an old stats version
            return shouldRecalculateStats(match);
        });

    if (!matches.length) {
        return;
    }

    const profile = store.getState().currentUser?.profile;

    if (!profile) {
        return;
    }

    const updatePromises = matches.map((match: MatchPhone) => {
        // check again just in case it changed
        if (shouldRecalculateStats(match)) {
            console.log(`Recalculating stats for ${match._id}`);
            // TODO would be more efficient to separate the calculation and the update and then do this with bulk docs
            Sentry.addBreadcrumb({
                category: "recalculateStatsMiddleware",
                message: `Recalculating stats for ${match._id} MF ${match.matchFinished} GPS ${match.hasTracking} HR ${match.hasHeartRate} GPS-P ${match.stats?.gpsProcessed} HR-P ${match.stats?.heartRateProcessed}`,
            });
            return recalculateStats(
                { ...match },
                profile,
                oldStatsVersion(match)
            );
        }
        return Promise.resolve(match);
    });

    try {
        await Promise.all(updatePromises);
        console.log("All match stats updated");
    } catch (err) {
        console.log("Error updating all match stats", err);
    }
}, RECALC_STATS_DEBOUNCE_DELAY);

export function oldStatsVersion(match: MatchPhone): boolean {
    return CURRENT_STATS_VERSION > (match.stats?.version || 0);
}
export function needsGpsCalc(match: MatchPhone): boolean {
    return !!(match.hasTracking && match.stats?.gpsProcessed !== 1);
}
export function needsHrCalc(match: MatchPhone): boolean {
    return !!(match.hasHeartRate && match.stats?.heartRateProcessed !== 1);
}

export function shouldRecalculateStats(match: MatchPhone): boolean {
    return (
        !!match.matchFinished && // forcing to boolean value (it can be undefined)
        // calculate stats if old version, or stats not defined
        (oldStatsVersion(match) ||
            // recalculate if match has gps but that has not been processed
            needsGpsCalc(match) ||
            // recalculate if match has heart rate but that has not been processed
            needsHrCalc(match))
    );
}

export const recalculateStatsMiddleware: Middleware<{}, RefsixState> =
    (store) => (next) => (action) => {
        // check if any matches need updating
        switch (action.type) {
            case SET_MATCHES:
                const matches: MatchPhone[] = action.matches;

                if (store.getState().matches.loading === false) {
                    const needsUpdate = some(matches, shouldRecalculateStats);

                    if (needsUpdate) {
                        _recalcStats(store);
                    }
                }
                // see if any of the matches needs an update

                break;
            case LOADING_MATCHES:
                // Run on app load when the app and matches have

                if (action.loading === false) {
                    const needsUpdate = some(
                        store.getState().matches.matches,
                        shouldRecalculateStats
                    );

                    if (needsUpdate) {
                        _recalcStats(store);
                    }
                }
                break;
            default:
            // do nothing
        }

        next(action);
    };
