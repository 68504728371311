import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "../hooks/match";
import { GpsMap, GpsMapType } from "../GpsMapComponent";
import { FitnessDataProvider } from "../hooks";
import { AggregatedStats } from "refsix-js-models";
import "./reviewSharedStyles.css";
import "./distanceReview.css";
import { ProTeaser, TeaserStyle } from "../ProTeaser";
import { hasFeatureAccess } from "../../services/subscriptionService";
import Stat, {
    Col,
    Content,
    MainHeader,
    MatchResult,
    Row,
    StatsContainer,
} from "./reviewCard";
import Distance, { DistanceUnit } from "../DistanceComponent";

interface DistanceReviewProps {
    matchId?: string;
    aggregatedStats: AggregatedStats;
}

const DistanceReview: FC<DistanceReviewProps> = ({
    matchId,
    aggregatedStats,
}) => {
    const { t } = useTranslation();
    const heatMapAccess = hasFeatureAccess("heatmap");
    const match = useMatch(matchId);

    if (!matchId || !match) {
        return null;
    }

    return (
        <div className="distance-review review">
            <div className="top-bit">
                <StatsContainer>
                    <Stat className={"highest-distance-in-match"}>
                        <Row>
                            <MainHeader>
                                {t("review.highestDistanceInMatch")}
                            </MainHeader>
                            <Content>
                                <MatchResult match={match} />
                            </Content>
                        </Row>
                    </Stat>
                    <Stat className={"total-distance"}>
                        <div className={"distance-container"}>
                            <span className={"distance"}>
                                <Distance
                                    distanceInMeters={
                                        match.stats?.distanceTotal || 0
                                    }
                                    decimalPlaces={1}
                                    showUnit={false}
                                />
                            </span>
                            <span className={"unit"} style={{ fontSize: 30 }}>
                                <DistanceUnit />
                            </span>
                        </div>
                    </Stat>

                    <div
                        className={"hardest-working-sprints"}
                        style={{ margin: "0 20px" }}
                    >
                        <Col>
                            <FitnessDataProvider matchId={matchId}>
                                <div>
                                    <ProTeaser
                                        showTeaser={
                                            !heatMapAccess &&
                                            match._id !== "example-result1"
                                        }
                                        teaserName="review-stats-heatmap"
                                        isRefSixEligible={false}
                                        teaserStyle={TeaserStyle.button}
                                    >
                                        <GpsMap
                                            match={match}
                                            mapType={GpsMapType.Heatmap}
                                            testId="review-heat-map"
                                            useDummyData={!heatMapAccess}
                                        />
                                    </ProTeaser>
                                </div>
                            </FitnessDataProvider>
                        </Col>
                    </div>
                </StatsContainer>
            </div>
        </div>
    );
};

export default DistanceReview;
