// EXAMPLE JSON options
// {
//     "experimentId": "yearlyToSeasonTest",
//     "experimentName": "Change yearly to season when upgrading",
//     "totalVariants": 2,
//     "marketLocale": ["en"],
//     "defaultVariant": 1
//   },

import { store } from "../redux/store";
import { getLanguageCode } from "./localeUtils";
import { setExperimentVariant } from "../redux/actions/experiementsActions";
import { trackEvent } from "../services/analytics/analyticsService";
import * as Sentry from "@sentry/react";
import { Experiment } from "../models/Experiment";

//Todo get test cases from somewhere external

// TODO get experiments json from somewhere
export function _shouldDoTest(experiment: Experiment) {
    if (experiment && experiment.marketLocale) {
        return experiment.marketLocale.indexOf(getLanguageCode()) > -1;
    }
    return true;
}

var _pickExperimentVariant = function (experimentId: string) {
    const experiments: Experiment[] =
        store.getState().experiments.activeExperiments;

    const currentExperiment = experiments.find(
        (experiment) => experiment.experimentId === experimentId
    );

    if (!currentExperiment) {
        Sentry.captureMessage(`Experiment ${experimentId} not found`);
        return;
    }

    if (_shouldDoTest(currentExperiment)) {
        // picks 1..n
        const assignedVariant =
            Math.floor(Math.random() * currentExperiment.variants.length) + 1;
        store.dispatch(setExperimentVariant(experimentId, assignedVariant));

        trackEvent("ExperimentInitiated", {
            ...currentExperiment,
            assignedVariant: assignedVariant,
            assignedVariantName:
                currentExperiment.variants[assignedVariant - 1],
        });

        return assignedVariant;
    }
    return currentExperiment.defaultVariant;
};

/**
 * Gets the variant assigned for the current user for the experiment
 * @param experimentId
 * @returns the variant number for the experiment
 */
export function getExperimentVariant(experimentId: string) {
    const savedVariants = store.getState().experiments.selectedVariants;

    // allow url override for testing
    const experimentParamName = `experiment-${experimentId}`;
    const variantOverride = new URLSearchParams(window.location.search).get(
        experimentParamName
    );
    if (variantOverride) {
        console.log(
            `Experiment overridden by url search param ${experimentId}=${variantOverride}`
        );
        return parseInt(variantOverride);
    }
    const variant =
        (savedVariants && savedVariants[experimentId]) ||
        _pickExperimentVariant(experimentId);
    return variant;
}
