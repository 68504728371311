import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonItem,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../components";
import { OnBoardingHeadingComponent } from "./onBoardingHeadingComponent";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { handleUpdateTimers } from "../../utils/timerSettingsHelper";
import { useUsername } from "../../components/hooks";
import { setShowOnBoardingFlow } from "../../redux/actions/appStatus";
import { routes } from "../../route/constants";

export const OnBoardingTimerSettingsStepTwo: FC = () => {
    const { t } = useTranslation();
    const settings = useSelector((root: RootState) => root.settings);
    const { elapsedHalfTimerFromZero } = settings.settings;
    const [shouldRestartTimer, setShouldRestartTimer] = useState<boolean>(
        elapsedHalfTimerFromZero
    );
    const route = useIonRouter();
    const username = useUsername();
    const dispatch = useDispatch();

    const handleNextClicked = () => {
        dispatch(setShowOnBoardingFlow(false));
        return route.push(
            routes.refsixOnBoardingSetupComplete,
            "root",
            "replace"
        );
    };

    return (
        <IonPage>
            <HeaderComponent
                titleTestId="timer-settings-header-2"
                title={t("settings.TimerSettings.title")}
            />
            <IonContent>
                <OnBoardingHeadingComponent
                    progressValue={0.6}
                    upperTitleInBold={t(
                        "help.onBoarding.shouldRestartTimerDesc"
                    )}
                />
                <IonCard color="transparent">
                    <IonRadioGroup
                        value={elapsedHalfTimerFromZero}
                        onIonChange={async (e) => {
                            setShouldRestartTimer(e.detail.value);
                            await handleUpdateTimers(
                                {
                                    elapsedHalfTimerFromZero: e.detail.value,
                                },
                                settings
                            );
                        }}
                    >
                        <IonItem lines="none" color="transparent">
                            <div className="timer-restart-wrapper">
                                <IonText>{t("general.yes")}</IonText>
                                <IonRadio
                                    legacy={true}
                                    data-testid="watch-face-classic"
                                    value={true}
                                    className={
                                        shouldRestartTimer === true
                                            ? "radio-button radio-no-text checked"
                                            : "radio-button radio-no-text"
                                    }
                                />
                            </div>
                        </IonItem>
                        <IonItem
                            lines="none"
                            color="transparent"
                            style={{ marginTop: 20 }}
                        >
                            <div className="timer-restart-wrapper">
                                <IonText>{t("general.no")}</IonText>
                                <IonRadio
                                    legacy={true}
                                    data-testid="watch-face-classic"
                                    value={false}
                                    className={
                                        shouldRestartTimer === false
                                            ? "radio-button radio-no-text checked"
                                            : "radio-button radio-no-text"
                                    }
                                />
                            </div>
                        </IonItem>
                    </IonRadioGroup>
                </IonCard>
            </IonContent>
            <IonFooter>
                <IonButton
                    expand="block"
                    data-testid="timer-settings-next-button-2"
                    onClick={() => handleNextClicked()}
                >
                    {t("general.next")}
                </IonButton>
            </IonFooter>
        </IonPage>
    );
};
