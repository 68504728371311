// ionic react functional component named CalendarSync

import { FC, useEffect, useState } from "react";
import {
    IonCard,
    IonCardContent,
    IonContent,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonProgressBar,
    IonText,
} from "@ionic/react";
import { useHasFeatureAccess, useUsername } from "../../../../components/hooks";
import request from "../../../../services/request";
import { HeaderComponent } from "../../../../components";
import { useTranslation } from "react-i18next";
import { checkboxOutline, copyOutline } from "ionicons/icons";
import { getLanguageCode, getLocale } from "../../../../utils/localeUtils";
import { Clipboard } from "@capacitor/clipboard";
import "./calendarSync.css";

import iosLogo from "../../../../assets/images/calendar/ios-calendar.svg";
import macLogo from "../../../../assets/images/calendar/mac-calendar.png";
import googleLogo from "../../../../assets/images/calendar/google-calendar.svg";
import outlookLogo from "../../../../assets/images/calendar/outlook.svg";
import { ProTeaser } from "../../../../components/ProTeaser";
import { trackEvent } from "../../../../services/analytics/analyticsService";
import { isUserPlusEligible } from "../../../../services/subscriptionService";

export const CalendarSync: FC = () => {
    const { t } = useTranslation();
    const username = useUsername();
    const [loading, setLoading] = useState(true);
    const [calendarUrlToken, setCalendarUrlToken] = useState<string>();
    const hasCalendarAccess = useHasFeatureAccess("calendarSync");
    const [copiedUrl, setCopiedUrl] = useState(false);

    useEffect(() => {
        const fn = async () => {
            try {
                const resp = await request.get(`/calendar/${username}`);
                setCalendarUrlToken((await resp.data) as string);
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
        };

        fn();
    }, [username]);

    const getFullCalendarURL = () => {
        return `${process.env.REACT_APP_REFSIX_CALENDAR_URL}/calendar/${calendarUrlToken}/cal.ics`;
    };

    const writeToClipboard = async (text: string) => {
        await Clipboard.write({
            string: text,
        });
    };

    const getFullLocale = () => {
        return getLocale().length === 2
            ? `${getLocale()}-${getLocale()}`
            : getLocale();
    };

    const translatedGoogleUrl = () => {
        return "https://support.google.com/calendar/answer/37100?hl=en&co=GENIE.Platform%3DDesktop&oco=1".replace(
            "hl=en",
            `hl=${getLanguageCode()}`
        );
    };

    const translateLocaleUrl = (url: string) => {
        return url.replace("en-gb", `${getFullLocale()}`);
    };

    const translatedIosUrl = () => {
        // apple doesn't support nl but does support nl-nl
        return translateLocaleUrl(
            "https://support.apple.com/en-gb/guide/iphone/iph3d1110d4/ios"
        );
    };

    const translatedMacUrl = () => {
        // apple doesn't support nl but does support nl-nl
        return translateLocaleUrl(
            "https://support.apple.com/en-gb/guide/calendar/icl1022/mac"
        );
    };

    const translateOutlookUrl = () => {
        return translateLocaleUrl(
            "https://support.microsoft.com/en-gb/office/import-or-subscribe-to-a-calendar-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c"
        );
    };

    const renderTextBox = () => {
        if (hasCalendarAccess) {
            if (calendarUrlToken) {
                return (
                    <IonItem
                        className="cal-url-item"
                        lines="full"
                        onClick={(e) => {
                            writeToClipboard(getFullCalendarURL())
                                .then(() => {
                                    trackEvent("CalendarSyncURLCopied", {});
                                    setCopiedUrl(true);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }}
                    >
                        <IonInput
                            readonly
                            style={{ direction: "rtl" }}
                            value={getFullCalendarURL()}
                        />
                        <IonIcon
                            icon={copiedUrl ? checkboxOutline : copyOutline}
                            slot="end"
                            color="primary"
                        ></IonIcon>
                    </IonItem>
                );
            } else {
                return (
                    <IonItem className="cal-url-item">
                        <IonLoading isOpen={loading} />
                        {loading && (
                            <IonProgressBar type="indeterminate"></IonProgressBar>
                        )}
                        {!loading && (
                            <IonLabel>{t("general.errorTryAgain")}</IonLabel>
                        )}
                    </IonItem>
                );
            }
        }
        return (
            <IonItem className="cal-url-item">
                <IonLabel>https://cal.refsix.com/calendar/cal.ics</IonLabel>
                <IonIcon icon={copyOutline} slot="end"></IonIcon>
            </IonItem>
        );
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                titleTestId={"manage-watches-page-header"}
                title={t("settings.SettingsMain.addFixtureToCalendar")}
            />
            <IonContent>
                <IonCard style={{ color: "white" }}>
                    <IonCardContent>
                        <IonText>{t("calendar.instructions")}</IonText>
                        <ProTeaser
                            showTeaser={!hasCalendarAccess}
                            teaserName="calendar-sync"
                            isRefSixEligible={isUserPlusEligible()}
                        >
                            <>
                                {renderTextBox()}
                                <IonText>{t("calendar.delay")}</IonText>
                            </>
                        </ProTeaser>
                    </IonCardContent>
                </IonCard>

                <IonCard style={{ color: "white" }}>
                    <IonCardContent>
                        <IonText style={{ marginBottom: 10 }}>
                            {t("calendar.howToSubscribe")}
                        </IonText>

                        <IonList style={{ marginTop: 20, borderRadius: 5 }}>
                            <IonItem
                                onClick={() =>
                                    window.open(
                                        translatedGoogleUrl(),
                                        "_system"
                                    )
                                }
                            >
                                <IonImg
                                    src={googleLogo}
                                    slot="start"
                                    className="calendar-icon"
                                />
                                <IonLabel>Google</IonLabel>
                            </IonItem>

                            <IonItem
                                onClick={() =>
                                    window.open(translatedIosUrl(), "_system")
                                }
                            >
                                <IonImg
                                    src={iosLogo}
                                    slot="start"
                                    className="calendar-icon"
                                />
                                <IonLabel>iOS</IonLabel>
                            </IonItem>

                            <IonItem
                                onClick={() =>
                                    window.open(translatedMacUrl(), "_system")
                                }
                            >
                                <IonImg
                                    src={macLogo}
                                    slot="start"
                                    className="calendar-icon"
                                />
                                <IonLabel>Mac</IonLabel>
                            </IonItem>

                            <IonItem
                                onClick={() =>
                                    window.open(
                                        translateOutlookUrl(),
                                        "_system"
                                    )
                                }
                            >
                                <IonImg
                                    src={outlookLogo}
                                    slot="start"
                                    className="calendar-icon"
                                />
                                <IonLabel>Outlook</IonLabel>
                            </IonItem>
                        </IonList>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
