import defaultCodes from "./content/fifa.json"; // FIFA codes are the fallback
import chile from "./content/chile.json";
import new_zealand from "./content/new_zealand.json";
import new_zealand_2020 from "./content/new_zealand_2020.json";
import netherlands from "./content/netherlands.json";
import netherlandsKnvbCup from "./content/netherlands-knvb-cup.json";
import belgium_nl from "./content/belgium_nl.json";
import japan from "./content/japan.json";
import portugal from "./content/portugal.json";
import australia from "./content/australia.json";
import australia_capital_football from "./content/australia_capital_football.json";
import australia_south from "./content/australia_south.json";
import norway from "./content/norway.json";
import italy from "./content/italy.json";
import france from "./content/france.json";
import slovakia from "./content/slovakia.json";
import sweden from "./content/sweden.json";
import denmark from "./content/denmark.json";
import denmark_dbu from "./content/denmark_dbu.json";
import denmark_amateur from "./content/denmark_amateur.json";
import us_nisoa from "./content/us_nisoa.json";
import us_ussf from "./content/us_ussf.json";
import us_nfhs from "./content/us_nfhs.json";
import england_fa_c1 from "./content/england_fa_c1.json";
import wales from "./content/wales.json";
import scotland from "./content/scotland.json";
import scotland_19 from "./content/scotland_19.json";
import england from "./content/england.json";
import germany from "./content/germany.json";
import austria from "./content/austria.json";
import austria_18 from "./content/austria_18.json";
import { MisconductCodes } from "refsix-js-models";

export const defaultMisconductCodes = defaultCodes;

export function getMisconductByLocale(
    locale: string,
    nationalFA: String | undefined = undefined
): string {
    switch (true) {
        case locale.includes("en-US"):
            return "us_ussf";
        case locale.includes("en-GB"):
            switch (nationalFA) {
                case "NIR":
                    return "fifa";
                case "SCO":
                    return "scotland_19";
                case "WAL":
                    return "wales";
                case "ENG":
                default:
                    return "england";
            }
        case locale.includes("at"):
            return "austria_18";
        case locale.includes("nl-BE"):
            return "belgium_nl";
        case locale.includes("cl"):
            return "chile";
        case locale.includes("dk"):
            return "denmark_amateur";
        case locale.includes("fr"):
            return "france";
        case locale.includes("de"):
            return "germany";
        case locale.includes("it"):
            return "italy";
        case locale.includes("ja"):
            return "japan";
        case locale.includes("nl"):
            return "netherlands";
        case locale.includes("nz"):
            return "new_zealand_2020";
        case locale.includes("nb"):
        case locale.includes("no"):
            return "norway";
        case locale.includes("pt"):
            return "portugal";
        case locale.includes("sk"):
            return "slovakia";
        case locale.includes("se"):
            return "sweden";
        default:
            return "fifa";
    }
}

const misconductCodesByCountry: MisconductCodes[] = [
    // MAKE SURE THIS IS RELEASED AND TESTED PROPERLY!!
    // https://refsix.atlassian.net/wiki/spaces/REF/pages/716079109/Misconduct+Codes

    // ORDER IS IMPORTANT!!!
    // if multiple matches, the most recently required one will be selected.

    chile,
    new_zealand,
    new_zealand_2020,
    netherlandsKnvbCup,
    netherlands,
    belgium_nl,
    japan,
    portugal,
    australia,
    australia_capital_football,
    australia_south,
    norway,
    italy,
    france,
    slovakia,
    sweden,
    denmark,
    denmark_dbu,
    denmark_amateur,

    us_nisoa,
    us_ussf,
    us_nfhs,
    defaultCodes,
    england_fa_c1,
    wales,
    scotland,
    scotland_19,
    england,

    germany,
    austria,
    austria_18,
] as unknown as MisconductCodes[]; // needed due to jsons containing strings instead of enums for ReasonType

export function getPreSetCodes() {
    return misconductCodesByCountry;
}

export function findNameById(id: string) {
    let misconductName = "Custom";
    misconductCodesByCountry.forEach(function (code) {
        if (id === code.id) {
            misconductName = code.name;
        }
    });

    return misconductName;
}

export function getPreSetCodesById(id: string) {
    for (let i = 0; i < misconductCodesByCountry.length; i++) {
        if (misconductCodesByCountry[i].id === id) {
            return misconductCodesByCountry[i];
        }
    }
}

export function findCodeByLocale(locale: string) {
    let misconductCode = "fifa";
    misconductCodesByCountry.forEach(function (code) {
        if (code.locale && locale.indexOf(code.locale) !== -1) {
            misconductCode = code.id;
        }
    });

    return misconductCode;
}
