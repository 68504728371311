import {IonContent, IonHeader, IonPage} from "@ionic/react";
import {HeaderComponent} from "../components";
import {routes} from "../route/constants";
import {t} from "i18next";
import {MigrateSystemBModal} from "../modals/migrateSystemBModals";
import React, {useState} from "react";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";

export const MigrateSystemB = () => {
  const history = useHistory();
  const [isOpen, setIsOpen ] = useState(true);
  const { t } = useTranslation();
  const onDismiss = () => {
    setIsOpen(false);
    if(history.length > 1) {
      history.goBack();
      return;
    }
    history.push(routes.refsixFixtures);
  }
  return (
    <IonPage>
      <IonHeader>
        <HeaderComponent
          showBackButton={true}
          title={t("sinbinChanges.title")}
        />
      </IonHeader>
      <IonContent>
        <MigrateSystemBModal isOpen={isOpen} onDismiss={onDismiss}/>

      </IonContent>
    </IonPage>

      )
}