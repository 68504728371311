import { FC, useEffect, useState } from "react";
import { HeaderComponent } from "../../../components";
import { routes } from "../../../route/constants";
import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonPage,
    IonRow,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { watchInstance, watchServiceInstance } from "../../../services/watch";
import { Observer } from "rxjs";
import moment from "moment";
import { checkmarkCircle, closeCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";

enum SendTestStatus {
    none = "none",
    pending = "pending",
    success = "success",
    fail = "fail",
}

export const WatchDebug: FC<{}> = () => {
    const { t } = useTranslation();
    const [updateCount, setUpdateCount] = useState<number>(0);
    const watchStatus = useSelector((state: RootState) => state.watchStatus);
    const [messages, setMessages] = useState<any[]>([]);
    const [sendTestStatus, setSendTestStatus] = useState<SendTestStatus>(
        SendTestStatus.none
    );

    const watchObserver: Observer<any> = {
        next: (payload: any) => {
            // TODO is there a bug here - pn ios this can fire thousands of times??
            // console.log(`[WatchDebug] Received ${payload.messageId}`);
            const newMessage = {
                timestamp: moment().format("LTS"),
                messageId: payload.messageId,
                id: payload.id,
                raw: payload,
            };
            setMessages([newMessage, ...messages]);
        },
        error: (error) => console.log(`[WatchDebug] The error was ${error}`), // TODO not sure what to do
        complete: () =>
            console.log("[WatchDebug] The subscription has completed"), // TODO not sure what to do
    };

    const onTestClick = async () => {
        setSendTestStatus(SendTestStatus.pending);
        try {
            const status = await watchServiceInstance?.sendTestMessage();
            setSendTestStatus(SendTestStatus.success);
        } catch (error) {
            setSendTestStatus(SendTestStatus.fail);
        }
    };

    useEffect(() => {
        setUpdateCount(updateCount + 1);

        return () => {
            watchSubscription?.unsubscribe();
        };
    }, [watchStatus]);

    const watchSubscription = watchInstance
        ?.messageReceivedStream()
        .subscribe(watchObserver);

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMore}
                title={t("more.watchDebug.btWatchDebug")}
            />
            <IonContent>
                <IonCard>
                    <IonCardContent>
                        {t("more.watchDebug.btWatchDebugDescription")}
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Watch Status</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <p style={{ marginBottom: "20px", color: "white" }}>
                            {t("more.watchDebug.updateCount")}: {updateCount}
                        </p>
                        <IonGrid>
                            {Object.keys(watchStatus).map((key: string) => {
                                return (
                                    <IonRow key={key}>
                                        <IonCol size="auto">{key}:</IonCol>
                                        <IonCol>
                                            {
                                                // @ts-ignore
                                                watchStatus[key] ? (
                                                    <IonIcon
                                                        icon={checkmarkCircle}
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    ></IonIcon>
                                                ) : (
                                                    <IonIcon
                                                        icon={closeCircle}
                                                        style={{ color: "red" }}
                                                    ></IonIcon>
                                                )
                                            }
                                        </IonCol>
                                    </IonRow>
                                );
                            })}
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            {t("more.watchDebug.messageReceivedLog")}
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonAccordionGroup>
                            {messages.map((message) => {
                                return (
                                    <IonAccordion
                                        key={message.id}
                                        value={message.id}
                                    >
                                        <IonItem slot="header" color="light">
                                            {message.timestamp} -{" "}
                                            {message.messageId}
                                        </IonItem>

                                        <pre
                                            style={{ fontSize: "10px" }}
                                            className="ion-padding"
                                            slot="content"
                                        >
                                            {JSON.stringify(
                                                message.raw,
                                                null,
                                                4
                                            )}
                                        </pre>
                                    </IonAccordion>
                                );
                            })}

                            {messages.length === 0 && (
                                <IonItem>
                                    {t("more.watchDebug.noMessagesReceived")}
                                </IonItem>
                            )}
                        </IonAccordionGroup>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            {t("more.watchDebug.sendMessage")}
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonButton onClick={onTestClick}>
                            {t("more.watchDebug.sendTestMessage")}
                        </IonButton>
                        <p>
                            {t("more.watchDebug.status")}:{sendTestStatus}
                        </p>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
