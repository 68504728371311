import { Line } from "react-chartjs-2";
import { FC } from "react";
import { Chart as ChartJS, ChartData, ChartOptions, Legend } from "chart.js";
import { injectDummyData } from "../../services/dummyDataService";

ChartJS.register(Legend);

interface LineCharComponentProps {
    data: ChartData<"line", number[]>;
    lineChartOptions: ChartOptions<"line">;
    fixedHeight?: number;
    testId: string;
    useDummyData?: boolean;
}

const LineChartComponent: FC<LineCharComponentProps> = ({
    data,
    lineChartOptions,
    fixedHeight,
    testId,
    useDummyData,
}) => {
    // Replace data values with dummy data but keep labels etc
    if (useDummyData) data = injectDummyData(data);

    return (
        <div
            className="line-chart"
            data-testid={testId}
            style={{ height: fixedHeight }}
        >
            <Line data={data} options={lineChartOptions} />
        </div>
    );
};

export default LineChartComponent;
