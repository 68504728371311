import { CLEAR_CURRENT_USER, SET_CURRENT_USER } from "../actions/types";
import { CurrentUser } from "../models/refsixState";
import { setCurrentUserAction } from "../actions/currentUser";

const initialState = new CurrentUser();

function currentUserReducer(state: CurrentUser = initialState, action: any) {
    switch (action.type) {
        case SET_CURRENT_USER:
            const setUserAction = action as setCurrentUserAction;
            return { ...state, profile: setUserAction.userProfile };
        case CLEAR_CURRENT_USER:
            return { ...state, currentUser: undefined };
        default:
            return state;
    }
}

export default currentUserReducer;
