import { GpsProcessingService } from "refsix-core";
import LatLon from "geodesy/latlon-spherical.js";
import { GpsCalibrationPoints, GpsPoint } from "refsix-js-models";

export type Corners = [GpsPoint, GpsPoint, GpsPoint];

interface Line {
    point1: LatLon;
    point2: LatLon;
    distance?: number;
}

/**
 * Converts GPS Point to new LatLon object
 */
function toLatLon(point: GpsPoint | LatLon) {
    return point instanceof LatLon
        ? point
        : new LatLon(point.latitude, point.longitude);
}

/**
 * Add distance property to the line object based on distance between its 2 points
 */
function addLineDistance(line: Line) {
    return {
        ...line,
        distance: line.point1.distanceTo(line.point2),
    };
}

/**
 * Calculates and returns the calibration points based on the 3 corner GPS points
 */
export function cornersToCalibrationPoints(
    corners: Corners
): GpsCalibrationPoints {
    const latLonCorners = corners.map(toLatLon);

    const lines: Line[] = [
        { point1: latLonCorners[0], point2: latLonCorners[1] },
        { point1: latLonCorners[0], point2: latLonCorners[2] },
        { point1: latLonCorners[1], point2: latLonCorners[2] },
    ];
    const sortedLines = lines
        .map(addLineDistance)
        .sort((a, b) => b.distance - a.distance);

    const hypotenuse = sortedLines[0];
    const touchLine = sortedLines[1];
    const midfield = hypotenuse.point1.midpointTo(hypotenuse.point2);

    return {
        leftCorner: {
            latitude: touchLine.point1.lat,
            longitude: touchLine.point1.lon,
        },
        rightCorner: {
            latitude: touchLine.point2.lat,
            longitude: touchLine.point2.lon,
        },
        midfield: {
            latitude: midfield.lat,
            longitude: midfield.lon,
        },
    };
}

/**
 * Returns a new array with small sample of the points, evenly distributed
 */
export function getSampleOfPoints(points: GpsPoint[]) {
    const frequencyFactor = 0.005;
    const frequency = Math.round(points.length * frequencyFactor);

    return points.reduce(
        (newPoints, point, index) =>
            index % frequency === 0 ? [...newPoints, point] : newPoints,
        [] as GpsPoint[]
    );
}

/**
 * Returns the center point of passed in points
 */
export function getCenterPoint(points: GpsPoint[]): LatLon | null {
    const gpsService = new GpsProcessingService();
    const summary = gpsService.positionSummary(points);

    return summary.center;
}
