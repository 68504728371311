import { FC } from "react";
import { AvailabilityComponent } from "./AvailabilityComponent";
import {
    MatchBottomCardComponent,
    MatchMiddleCardComponent,
    MatchUpperCardComponent,
} from "../index";
import AcceptDeclineComponent from "../AcceptDeclineComponent";
import { MatchOrAvailability } from "../../pages/tabs/fixtures";
import {
    IonCard,
    IonGrid,
    IonRow,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { setSelectedMatch } from "../../redux/actions/matches";
import { matchMissingInfo } from "../../services/matchService";
import { routes } from "../../route/constants";
import { useDispatch } from "react-redux";
import {
    deleteClosedDates,
    deleteOpenDates,
    getAndStoreDownloadedClosedAndOpenDates,
} from "../../services/integrations/refassist/refAssistService";
import { useTranslation } from "react-i18next";
import {
    AvailabilityType,
    MatchPhone,
    RefAssistAvailability,
    REFSIX_AVAILABILITY_PREFIX,
} from "refsix-js-models";
import { pushErrorMessage } from "../../redux/actions/errorMessagesActions";

export const MatchOrAvailabilityComponent: FC<{
    matchOrAvailability: MatchOrAvailability;
    loading(loading: boolean): void;
}> = ({ matchOrAvailability, loading }) => {
    const dispatch = useDispatch();
    const route = useIonRouter();
    const [present] = useIonAlert();
    const { t } = useTranslation();

    const canDeleteAvailability = (
        availability: RefAssistAvailability
    ): boolean => {
        return !!(
            availability.partnerId &&
            availability.partnerId.startsWith(REFSIX_AVAILABILITY_PREFIX)
        );
    };

    const handleDeleteAvailability = async () => {
        if (matchOrAvailability.closedDate || matchOrAvailability.openDate) {
            try {
                await present({
                    header: t("general.confirmDeletion.title"),
                    message: t("general.confirmDeletion.description"),
                    buttons: [
                        {
                            text: t("general.cancel"),
                            role: "cancel",
                        },
                        {
                            text: t("general.delete"),
                            handler: async () => {
                                loading(true);

                                try {
                                    if (matchOrAvailability.openDate) {
                                        await deleteOpenDates(
                                            matchOrAvailability.openDate
                                                .refAssistId
                                        );
                                    } else if (matchOrAvailability.closedDate) {
                                        await deleteClosedDates(
                                            matchOrAvailability.closedDate
                                                .refAssistId
                                        );
                                    } else {
                                    }
                                    await getAndStoreDownloadedClosedAndOpenDates();
                                    loading(false);
                                } catch (e) {
                                    console.log(
                                        "Error trying to delete closed date",
                                        e
                                    );
                                    loading(false);
                                    dispatch(
                                        pushErrorMessage({
                                            messageKey: "general.errorTryAgain",
                                            color: "danger",
                                        })
                                    );
                                }
                            },
                        },
                    ],
                });
            } catch (e) {
                console.log("Error trying to delete closed date", e);
            }
        }
    };

    const renderMatch = (match: MatchPhone) => {
        return (
            <IonCard
                key={match._id}
                data-testid="match-card"
                color={"medium"}
                style={{
                    backgroundColor: "#242424",
                }}
                className="fixture-card"
                onClick={() => {
                    dispatch(setSelectedMatch(match));
                    if (match && matchMissingInfo(match)) {
                        route.push(`${routes.createMatch}/${match._id}`);
                    } else if (match) {
                        route.push(
                            `${routes.match}/${match._id}/overview`,
                            "forward"
                        );
                    }
                }}
            >
                <IonGrid>
                    <IonRow>
                        <MatchUpperCardComponent match={match} />
                        <MatchMiddleCardComponent match={match} />
                        <MatchBottomCardComponent match={match} />
                        <AcceptDeclineComponent match={match} />
                    </IonRow>
                </IonGrid>
            </IonCard>
        );
    };

    const renderAvailability = (
        availability: RefAssistAvailability,
        availabilityType: AvailabilityType
    ) => {
        const className =
            availabilityType === AvailabilityType.closedDate
                ? "closed-date-card"
                : "open-date-card";
        return (
            <IonCard
                key={availability.refAssistId}
                className={"fixture-card " + className}
                onClick={() =>
                    canDeleteAvailability(
                        availability as RefAssistAvailability
                    ) && handleDeleteAvailability()
                }
            >
                <AvailabilityComponent
                    availability={availability}
                    availabilityType={availabilityType}
                />
            </IonCard>
        );
    };

    return (
        <>
            {matchOrAvailability.closedDate &&
                renderAvailability(
                    matchOrAvailability.closedDate,
                    AvailabilityType.closedDate
                )}

            {matchOrAvailability.openDate &&
                renderAvailability(
                    matchOrAvailability.openDate,
                    AvailabilityType.openDate
                )}

            {matchOrAvailability.match &&
                renderMatch(matchOrAvailability.match)}
        </>
    );
};
