import { SET_MESSAGES } from "../actions/types";
import { Messages } from "../models/refsixState";

const initialState: Messages = {
    messages: [],
};

export function messagesReducer(state: Messages = initialState, action: any) {
    switch (action.type) {
        case SET_MESSAGES:
            return {
                ...state,
                messages: action.messages,
            };
        default:
            return state;
    }
}
