import { FC } from "react";
import { useHistory } from "react-router";
import OneSignal, {
    InAppMessageClickEvent,
    NotificationClickEvent,
    NotificationWillDisplayEvent,
    OSNotification,
} from "onesignal-cordova-plugin";
import { isAndroid, isIos } from "../services/platformDetection";
import { useDispatch } from "react-redux";
import { pushPushMessage } from "../redux/actions/pushMessagesActions";
import * as Sentry from "@sentry/react";
import { trackEvent } from "../services/analytics/analyticsService";
import { getUTMParamsFromURL } from "../services/analytics/utmAttribution";
import { mpRegisterSuperProperties } from "../services/analytics/mixpanelService";

const trackOpenUrl = (action: any, url: string): void => {
    const utmParams = getUTMParamsFromURL(url);
    mpRegisterSuperProperties(utmParams);
    trackEvent("InAppMessageClicked", {
        ...action,
        ...utmParams,
        actionType: "open-url",
    });
};

export const OneSignalPushListener: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    if (!isIos() && !isAndroid()) {
        return null;
    }

    const handlePushLaunchURL = (pushEvent: any): void => {
        if (pushEvent.launchURL) {
            const url = new URL(pushEvent.launchURL);
            const slug = url.pathname;
            const utmParams = getUTMParamsFromURL(slug);
            mpRegisterSuperProperties(utmParams);
            trackEvent("PushNotificationClicked", {
                urlPath: slug,
                trigger: "native-notification",
                ...utmParams,
            });
            history.push(slug);
        }
    };

    OneSignal.Notifications.addEventListener(
        "click",
        (event: NotificationClickEvent) => {
            console.log("notificationOpenedCallback", event);
            if (event.notification) {
                handlePushLaunchURL(event.notification);
            }
        }
    );

    OneSignal.Notifications.addEventListener(
        "foregroundWillDisplay",
        (event: NotificationWillDisplayEvent) => {
            console.log("notificationWillDisplay", event);
            if (event.getNotification()) {
                const notification = event.getNotification() as OSNotification;
                dispatch(
                    pushPushMessage({
                        title: notification.title || "REFSIX",
                        body: notification.body,
                        launchUrl: notification.launchURL,
                    })
                );
            }
        }
    );

    OneSignal.InAppMessages.addEventListener(
        "click",
        (event: InAppMessageClickEvent) => {
            const clickName = event.result.actionId;
            if (clickName) {
                try {
                    const url = new URL(clickName);
                    if (url.hostname !== "app.refsix.com") {
                        window.open(clickName, "_system");
                        trackOpenUrl(event, clickName);
                        throw "Not a refsix URL";
                    }
                    const urlSlug = url.pathname;
                    const utmParams = getUTMParamsFromURL(urlSlug);
                    mpRegisterSuperProperties(utmParams);
                    trackEvent("InAppMessageClicked", {
                        ...event,
                        ...getUTMParamsFromURL(urlSlug),
                        urlPath: urlSlug,
                        actionType: "deep-link",
                    });
                    Sentry.addBreadcrumb({
                        message: `In app message clicked for page : ${clickName}`,
                    });
                    history.push(urlSlug);
                } catch (e) {
                    Sentry.captureMessage(
                        `In app message clicked, had clickName but not handled: ${clickName}`
                    );
                }
            } else if (event.result.url) {
                trackOpenUrl(event, event.result.url);
            }
        }
    );

    // Has been removed in V5.0.0
    //OneSignal.setLaunchURLsInApp(true);

    return null;
};
