import { FC } from "react";
import { MatchPhone } from "refsix-js-models";
import { IonCol, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface PartnerMatchIdProps {
    match: MatchPhone;
}

const PartnerMatchIdComponent: FC<PartnerMatchIdProps> = ({ match }) => {
    const { t } = useTranslation();

    if (!match.integration) return null;

    return (
        <>
            <IonCol size="3.5" className="col-no-padding">
                <IonText className="match-details-text">
                    {t("integrations.matchId")}
                </IonText>
            </IonCol>

            <IonCol>
                <IonText className="match-details-desc venue-name">
                    {match.integration.matchId}
                </IonText>
            </IonCol>
        </>
    );
};

export default PartnerMatchIdComponent;
