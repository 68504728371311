import { IonText } from "@ionic/react";
import { MatchPhone, OfficialRole } from "refsix-js-models";
import { FC } from "react";
import "../pages/tabs/fixtures.css";
import "./MatchMiddleCard.css";
import i18n from "react-i18next";
import { uniq } from "lodash";
import { CURRENT_USER_OFFICIAL } from "../constants/strings";

interface Match {
    match: MatchPhone;
}

const MatchMiddleCardComponent: FC<Match> = ({ match }) => {
    const matchOfficials = uniq(
        Object.values(match.matchOfficials || {})
    ).filter(
        (official) => official !== "" && official !== CURRENT_USER_OFFICIAL
    );

    if (matchOfficials.length < 1) return <></>;

    const officialRoles = sortRefereeRoles(match);

    return (
        <div className="match-officials separator">
            {officialRoles.map((official, index) => {
                if (official.name !== CURRENT_USER_OFFICIAL) {
                    return (
                        <div className="match-official" key={index}>
                            <div
                                className={`role-icons ${getRefereeRoleClass(
                                    official.role
                                )} small`}
                            />
                            <IonText
                                className={`text-ellipsis ${official.role}-name `}
                            >
                                {official.name}
                            </IonText>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export function sortRefereeRoles(match: MatchPhone) {
    const sortedMatchOfficials = [];

    if (match.matchOfficials) {
        match.matchOfficials.referee &&
            sortedMatchOfficials.push({
                role: "referee",
                name: match.matchOfficials.referee,
            });
        match.matchOfficials.assistant1 &&
            sortedMatchOfficials.push({
                role: "assistant1",
                name: match.matchOfficials.assistant1,
            });
        match.matchOfficials.assistant2 &&
            sortedMatchOfficials.push({
                role: "assistant2",
                name: match.matchOfficials.assistant2,
            });
        match.matchOfficials.fourthOfficial &&
            sortedMatchOfficials.push({
                role: "fourthOfficial",
                name: match.matchOfficials.fourthOfficial,
            });
        match.matchOfficials.observer &&
            sortedMatchOfficials.push({
                role: "observer",
                name: match.matchOfficials.observer,
            });
    }
    return sortedMatchOfficials;
}

// Returning an array of match officials sorted
export function sortAllRefereeRoles(match: MatchPhone, t: i18n.TFunction) {
    const sortedMatchOfficials = [];
    let refereeName: string | undefined = undefined;
    let assistant1Name: string | undefined = undefined;
    let assistant2Name: string | undefined = undefined;
    let observerName: string | undefined = undefined;
    let fourthOfficialName: string | undefined = undefined;

    if (match.matchOfficials?.referee) {
        refereeName = match.matchOfficials.referee;
    } else if (match.officialRole === OfficialRole.referee) {
        refereeName = "currentUser";
    }

    if (match.matchOfficials?.assistant1) {
        assistant1Name = match.matchOfficials.assistant1;
    } else if (match.officialRole === OfficialRole.assistant) {
        assistant1Name = "currentUser";
    }

    if (match.matchOfficials?.assistant2) {
        assistant2Name = match.matchOfficials.assistant2;
    } else if (match.officialRole === OfficialRole.assistant) {
        assistant2Name = "currentUser";
    }

    if (match.matchOfficials?.observer) {
        observerName = match.matchOfficials.observer;
    } else if (match.officialRole === OfficialRole.observer) {
        observerName = "currentUser";
    }

    if (match.matchOfficials?.fourthOfficial) {
        fourthOfficialName = match.matchOfficials.fourthOfficial;
    } else if (match.officialRole === OfficialRole.fourthOfficial) {
        fourthOfficialName = "currentUser";
    }

    sortedMatchOfficials.push(
        {
            role: "referee",
            name: refereeName,
        },
        {
            role: "assistant1",
            name: assistant1Name,
        },
        {
            role: "assistant2",
            name: assistant2Name,
        },
        {
            role: "fourthOfficial",
            name: fourthOfficialName,
        },
        {
            role: "observer",
            name: observerName,
        }
    );

    return sortedMatchOfficials;
}

export function getRefereeRoleClass(role: string) {
    switch (role) {
        case "referee":
            return "referee";
        case "assistant":
        case "assistant1":
        case "assistant2":
            return "assistant";
        case "fourthOfficial":
            return "fourth-official";
        case "observer":
            return "observer";
    }
}

export default MatchMiddleCardComponent;
