import { FC } from "react";
import { AggregatedStats, OfficialRole } from "refsix-js-models";
import { IonCard } from "@ionic/react";
import {
    HeadlineNumbersComponent,
    RoundBarComponent,
} from "../../../components";
import { useTranslation } from "react-i18next";
import { CurrencyFilter } from "../../../utils/CurrencyFilter";
import { ProTeaser } from "../../../components/ProTeaser";

interface FinanceProps {
    hasStatsAccess: boolean;
    aggregatedStats: AggregatedStats | undefined;
}

const Finance: FC<FinanceProps> = ({ hasStatsAccess, aggregatedStats }) => {
    const { t } = useTranslation();

    return (
        <>
            <IonCard data-testid="trends-segment-finance">
                <HeadlineNumbersComponent
                    testIdsPrefix="earnings"
                    useDummyData={!hasStatsAccess}
                    fromIndex={!hasStatsAccess ? 0 : undefined}
                    data={
                        aggregatedStats
                            ? [CurrencyFilter(aggregatedStats.earningsTotal)]
                            : []
                    }
                    title={t("fixture.FixtureSummaryEarnings.earnings")}
                    labels={[t("stats.stats.total")]}
                />
                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-finance-earnings-by-role"
                >
                    <RoundBarComponent
                        title={t("stats.stats.Earnings.earningsByRole")}
                        values={
                            aggregatedStats
                                ? [
                                      aggregatedStats?.earningsAsRefereeTotal,
                                      aggregatedStats?.earningsAsAssistantTotal,
                                      aggregatedStats?.earningsAsFourthOfficialTotal,
                                      aggregatedStats?.earningsAsObserverTotal,
                                  ]
                                : []
                        }
                        series={[
                            OfficialRole.referee,
                            OfficialRole.assistant,
                            OfficialRole.fourthOfficial,
                            OfficialRole.observer,
                        ]}
                        distanceBy=""
                        numberOfCategories={4}
                        unit={"currency"}
                        seriesIcon
                        testId="finance-bar-graph"
                        useDummyData={!hasStatsAccess}
                    />
                </ProTeaser>
            </IonCard>

            <ProTeaser
                showTeaser={!hasStatsAccess}
                teaserName="trends-finance-fees"
            >
                <IonCard>
                    <HeadlineNumbersComponent
                        testIdsPrefix="matchFees"
                        useDummyData={!hasStatsAccess}
                        fromIndex={!hasStatsAccess ? 0 : undefined}
                        data={
                            aggregatedStats
                                ? [
                                      CurrencyFilter(aggregatedStats.feesTotal),
                                      CurrencyFilter(
                                          aggregatedStats.feesAverage
                                      ),
                                  ]
                                : []
                        }
                        title={t("general.matchFees")}
                        labels={[
                            t("stats.stats.total"),
                            t("stats.stats.avgPerGame"),
                        ]}
                    />
                    <HeadlineNumbersComponent
                        testIdsPrefix="expenses"
                        useDummyData={!hasStatsAccess}
                        fromIndex={!hasStatsAccess ? 0 : undefined}
                        data={
                            aggregatedStats
                                ? [
                                      CurrencyFilter(
                                          aggregatedStats.expensesTotal
                                      ),
                                      CurrencyFilter(
                                          aggregatedStats.expensesAverage
                                      ),
                                  ]
                                : []
                        }
                        title={t("general.expenses")}
                        labels={[
                            t("stats.stats.total"),
                            t("stats.stats.avgPerGame"),
                        ]}
                    />
                </IonCard>
            </ProTeaser>
        </>
    );
};

export default Finance;
