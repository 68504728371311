import {
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLoading,
    IonPage,
    IonText,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../../../components";
import { RefAssistFile } from "refsix-js-models";
import { folder, reader } from "ionicons/icons";
import {
    getFiles,
    getFileURL,
} from "../../../../services/integrations/refassist/refAssistService";
import { pushErrorMessage } from "../../../../redux/actions/errorMessagesActions";
import { useDispatch } from "react-redux";

export const RefAssistFiles: FC = () => {
    const { t } = useTranslation();
    const [clickedDirectories, setClickedDirectories] = useState<string[]>([]);
    const [refAssistFiles, setRefAssistFiles] = useState<RefAssistFile[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        getFiles()
            .then((r) => {
                setRefAssistFiles(r.data);
                setLoading(false);
            })
            .catch((error) => {
                dispatch(
                    pushErrorMessage({
                        messageKey: "general.errorTryAgain",
                        color: "warning",
                    })
                );
                setLoading(false);
            });
    }, []);

    const handleFileOrFolderClicked = (file: RefAssistFile) => {
        if (file.isDirectory) {
            if (clickedDirectories.includes(file.fileKey)) {
                setClickedDirectories(
                    clickedDirectories.filter((dir) => dir !== file.fileKey)
                );
            } else {
                setClickedDirectories([...clickedDirectories, file.fileKey]);
            }
        } else {
            getFileURL(file.fileKey)
                .then((response) => {
                    window.open(response.data, "_system");
                })
                .catch((error) => {
                    dispatch(
                        pushErrorMessage({
                            messageKey: "general.errorTryAgain",
                            color: "warning",
                        })
                    );
                });
            console.log("File clicked:", file.fileKey);
        }
    };

    const renderFiles = (files: RefAssistFile[], nested: boolean) => {
        if (files.length === 0)
            return (
                <IonCard>
                    <IonCardContent>
                        <IonText>{t("files.noFiles")}</IonText>
                    </IonCardContent>
                </IonCard>
            );
        return files.map((file) => {
            if (file.isDirectory) {
                const isDirectoryClicked = clickedDirectories.includes(
                    file.fileKey
                );
                const nestedFiles = files.filter(
                    (f) =>
                        f.fileKey !== file.fileKey &&
                        f.fileKey.startsWith(file.fileKey + "/")
                );

                if (!nested && file.fileKey.includes("/")) {
                    return null;
                }

                return (
                    <div key={file.fileKey}>
                        <IonItem
                            lines="full"
                            onClick={() => handleFileOrFolderClicked(file)}
                            button
                        >
                            <IonCol size="1">
                                <IonIcon icon={folder} />
                            </IonCol>
                            <IonCol>
                                <IonText>{file.fileKey}</IonText>
                            </IonCol>
                        </IonItem>
                        {isDirectoryClicked && (
                            <div style={{ marginLeft: 20 }}>
                                {" "}
                                {renderFiles(nestedFiles, true)}
                            </div>
                        )}
                    </div>
                );
            } else {
                if (!nested && file.fileKey.includes("/")) {
                    return null;
                }
                return (
                    <IonItem
                        key={file.fileKey}
                        lines="full"
                        onClick={() => handleFileOrFolderClicked(file)}
                        button
                    >
                        <IonCol size="1">
                            <IonIcon icon={reader} />
                        </IonCol>
                        <IonCol>
                            <IonText>{file.fileKey}</IonText>
                        </IonCol>
                    </IonItem>
                );
            }
        });
    };

    return (
        <IonPage>
            <HeaderComponent showBackButton title={t("more.menu.files")} />
            <IonContent>
                <IonLoading isOpen={loading} />
                <IonCard>{renderFiles(refAssistFiles, false)}</IonCard>
            </IonContent>
        </IonPage>
    );
};
