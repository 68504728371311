import { FC, useState } from "react";
import {
    IonButton,
    IonCard,
    IonContent,
    IonPage,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../../../components";
import PairWatchModal from "../../../../modals/pairWatchModal";
import "./pairWatch.css";
import { SelectWatchComponent } from "../../../../components/SelectWatchComponent";

export interface selectWatch {
    name: string;
    description: string;
}

const PairWatch: FC = () => {
    const { t } = useTranslation();
    const [selectedWatch, setSelectedWatch] = useState<selectWatch>();
    const route = useIonRouter();
    const [showPairModal, setShowPairModal] = useState<boolean>(false);

    const watchSelected = () => {
        // user does not have a smartwatch, go back
        if (selectedWatch?.name === "none") {
            route.goBack();
        } else {
            setShowPairModal(true);
        }
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                title={t("settings.WatchSettings.selectWatchTitle")}
                titleTestId={"pair-watch-page-header"}
            />
            <IonContent>
                <SelectWatchComponent />
                <IonCard>
                    <IonButton
                        expand="block"
                        onClick={() => watchSelected()}
                        disabled={!selectedWatch}
                        data-testid="selected-watch-to-pair-button"
                    >
                        <IonText>
                            {t("settings.WatchSettings.continue")}
                        </IonText>
                    </IonButton>
                </IonCard>

                <PairWatchModal
                    openPairModal={showPairModal}
                    onDismiss={() => setShowPairModal(false)}
                />
            </IonContent>
        </IonPage>
    );
};

export default PairWatch;
