import { useEffect, useState } from "react";
import {
    IonAlert,
    IonButton,
    IonContent,
    IonIcon,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    useIonRouter,
} from "@ionic/react";
import {
    linkSharp as linkSharpIcon,
    qrCode as qrCodeIcon,
} from "ionicons/icons";
import { QRCode } from "react-qrcode-logo";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import { getSummary, postReferrerSetup } from "../../services/mgmService";
import {
    checkForActiveMgmSubscription,
    refreshSubscriptions,
} from "../../services/subscriptionService";
import { formatForCurrency } from "../../utils/CurrencySymbol";
import { getLocale } from "../../utils/localeUtils";
import { shareText } from "../../utils/share";
import { useProfile, useSubscription } from "../../components/hooks";
import { HeaderComponent } from "../../components";
import { routes } from "../../route/constants";
import { URLS } from "../../constants/urls";

import logoImg from "../../assets/images/icon/refsix-round-icon.svg";
import "./referFriend.css";
import { trackEvent } from "../../services/analytics/analyticsService";
import { MgmSummary } from "refsix-js-models";

const REFSIX_ACCOUNT_URL = process.env.REACT_APP_REFSIX_ACCOUNT_URL;

const ReferFriendPage = () => {
    const router = useIonRouter();
    const { t } = useTranslation();

    const subscription = useSubscription();
    const profile = useProfile();

    const [isSettingUpReferrer, setIsSettingUpReferrer] =
        useState<boolean>(false);

    const [showError, setShowError] = useState<boolean>(false);

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [summary, setSummaryData] = useState<MgmSummary | null>(null);
    const [couponId, setCouponId] = useState<string | null>(null);

    const referralUrlLink = `${REFSIX_ACCOUNT_URL}/#!/register/landing?coupon=${couponId}`; // Trigger = link (Default)
    const referralUrlQR = `${REFSIX_ACCOUNT_URL}/#!/register/landing?coupon=${couponId}&t=qr`; // Trigger = qr

    const getSummaryData = async () => {
        try {
            const summary = await getSummary();
            setSummaryData(summary);
            setCouponId(summary.couponId);
        } catch (error) {
            Sentry.captureException(error);
            setShowError(true);
            // To be able to share referral link when offline
            setCouponIdFromMgmSub();
        }
    };

    const setCouponIdFromMgmSub = () => {
        if (subscription) {
            const { subscriptions } = subscription;
            const mgmSub = checkForActiveMgmSubscription(subscriptions);

            if (mgmSub) {
                setCouponId(mgmSub.rawData?.couponId);
            }
        }
    };

    const setUpReferrer = async () => {
        setIsSettingUpReferrer(true);
        try {
            await postReferrerSetup(getLocale());
            await getSummaryData();
            setIsSettingUpReferrer(false);
        } catch (error) {
            Sentry.captureException(error);
            setIsSettingUpReferrer(false);
            setShowError(true);
        }

        // Now update the subscriptions so the app state has mgm sub now.
        try {
            await refreshSubscriptions();
        } catch (error) {
            console.log("Error refreshing subscriptions", error);
        }
    };

    const shareReferralLink = async () => {
        trackEvent("MGMLinkShared", { trigger: "link" });

        try {
            await shareText(
                `${t<string>("mgm.shareLinkText", {
                    firstName,
                    discountPercent: summary?.discountPercent,
                })}\n${referralUrlLink}`
            );
        } catch (error) {
            console.log("Error sharing referral link", error);
            Sentry.captureException(error);
        }
    };

    const handleBackClicked = () => {
        if (router.canGoBack()) {
            router.goBack();
        } else {
            router.push(routes.refsixFixtures, "root");
        }
    };

    useEffect(() => {
        if (subscription) {
            const { subscriptions } = subscription;
            const mgmSub = checkForActiveMgmSubscription(subscriptions);

            if (mgmSub) {
                getSummaryData();
            }

            if (!mgmSub && !isSettingUpReferrer) {
                setUpReferrer();
            }
        }
    }, [subscription]);

    useEffect(() => {
        if (profile) {
            const { firstName, lastName } = profile;
            setFirstName(firstName);
            setLastName(lastName);
        }
    }, [profile]);

    return (
        <IonPage>
            <IonAlert
                isOpen={showError}
                onDidDismiss={() => setShowError(false)}
                header={t("general.errorTryAgain")}
            />
            <IonLoading isOpen={isSettingUpReferrer} />
            {/* Hidden input with the referralUrl for cypress test */}
            <input
                type="hidden"
                value={referralUrlLink}
                data-testid="referral-url"
            />
            <HeaderComponent
                titleTestId="mgm-title"
                customStartButton
                customStartButtonOnClick={handleBackClicked}
                title={t("mgm.title")}
                headerEndText={t("mgm.tsAndCs")}
                endBtnTestId="ts-and-cs-button"
                onClickEndButton={() => {
                    window.open(URLS.MGM_TERMS, "_system");
                }}
            />
            <IonContent className="ion-padding">
                <IonRow className="row-container bottom-padding">
                    <IonText className="mgm-heading text-weight-bold">
                        {t("mgm.subtitle")}
                        <span className="gift-emoji">🎁</span>
                    </IonText>
                </IonRow>

                <IonRow className="row-container">
                    <IonText className="text-weight-bold">
                        {t("mgm.winBig")}
                    </IonText>
                </IonRow>

                <IonRow
                    className="row-container bottom-padding"
                    style={{ minHeight: 60 }}
                >
                    <IonText>
                        {summary?.kickbackAmount &&
                            summary?.currency &&
                            t("mgm.receiveCredit", {
                                kickback: formatForCurrency(
                                    summary.kickbackAmount,
                                    summary.currency,
                                    getLocale()
                                ),
                            })}
                    </IonText>
                </IonRow>

                <IonRow className="row-container">
                    <IonText className="text-weight-bold">
                        {t("mgm.winWin")}
                    </IonText>
                </IonRow>

                <IonRow className="row-container bottom-padding">
                    <IonText>
                        {t("mgm.yourFriend", {
                            discountPercent: summary?.discountPercent,
                        })}
                    </IonText>
                </IonRow>

                <IonRow className="row-container">
                    <IonText className="uppercase">{t("mgm.summary")}</IonText>
                </IonRow>

                <IonRow className="row-container text-weight-bold summary-box">
                    <IonText>{t("mgm.referralsComplete")}</IonText>
                    <IonText
                        className="align-right"
                        data-testid="referrals-complete"
                    >
                        {summary?.referralsComplete}
                    </IonText>
                </IonRow>

                <IonRow className="row-container text-weight-bold summary-box">
                    <IonText>{t("mgm.creditAvailable")}</IonText>
                    <IonText
                        className="align-right"
                        data-testid="credit-available"
                    >
                        {summary?.creditAvailable &&
                            summary?.currency &&
                            formatForCurrency(
                                summary.creditAvailable,
                                summary.currency,
                                getLocale()
                            )}
                    </IonText>
                </IonRow>

                <IonRow className="row-container">
                    <IonText className="text-small align-right">
                        <a
                            href={URLS.MGM_TERMS}
                            target="_system"
                            rel="noreferrer"
                            data-testid="how-to-redeem-link"
                        >
                            {t("mgm.howToRedeem")}
                        </a>
                    </IonText>
                </IonRow>

                <IonRow className="row-container buttons-container">
                    <IonButton
                        data-testid="share-referral-button"
                        expand="block"
                        className="width-100"
                        onClick={() => shareReferralLink()}
                    >
                        <IonIcon icon={linkSharpIcon} className="button-icon" />
                        {t("mgm.shareLink")}
                    </IonButton>
                    <IonButton
                        id="open-qr-modal"
                        data-testid="share-qr-code-button"
                        expand="block"
                        className="width-100"
                        onClick={() => {
                            trackEvent("MGMLinkShared", { trigger: "QRCode" });
                        }}
                    >
                        <IonIcon icon={qrCodeIcon} className="button-icon" />
                        {t("mgm.shareCode")}
                    </IonButton>
                </IonRow>

                <IonModal
                    trigger="open-qr-modal"
                    animated={true}
                    initialBreakpoint={0.75}
                    breakpoints={[0, 0.75]}
                    className="qr-modal"
                >
                    <IonContent className="ion-padding" color="primary">
                        <IonRow className="row-container">
                            <IonText
                                className="qr-modal-name text-weight-bold"
                                color="light"
                                data-testid="qr-modal-full-name"
                            >
                                {`${firstName} ${lastName}`}
                            </IonText>
                        </IonRow>

                        <IonRow className="row-container">
                            <div className="qr-modal-item">
                                <QRCode
                                    value={referralUrlQR}
                                    size={250}
                                    bgColor="#d9ea45"
                                    fgColor="#000000"
                                    logoImage={logoImg}
                                    logoWidth={50}
                                    logoHeight={50}
                                    removeQrCodeBehindLogo={true}
                                    logoPaddingStyle="circle"
                                    qrStyle="dots"
                                />
                            </div>
                        </IonRow>

                        <IonRow className="row-container">
                            <IonButton
                                data-testid="share-referral-link"
                                expand="block"
                                className="width-100"
                                onClick={() => shareReferralLink()}
                                color="dark"
                            >
                                <IonIcon
                                    icon={linkSharpIcon}
                                    className="button-icon"
                                />
                                {t("mgm.shareLinkInstead")}
                            </IonButton>
                        </IonRow>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default ReferFriendPage;
