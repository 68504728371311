import {
    createContext,
    FC,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { App } from "@capacitor/app";
import { useInstalledAppVersion } from "./index";
import { useDispatch, useSelector } from "react-redux";
import { setInstalledAppVersion } from "../../redux/actions/appStatus";

import * as self from "./appVersionHooks";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { RootState, store } from "../../redux/store";
import { INSTALLED_VERSION_UNKNOWN } from "../../redux/models/refsixState";

/**
 * Get the current version of the app using the redux state for speed state if set, if not get it from Capacitor plugin
 */
export const getCurrentVersionQuickly = async () => {
    const version = store.getState().appStatus.installedVersion;
    if (!version || version === INSTALLED_VERSION_UNKNOWN) {
        // get version asynchronously
        return await getCurrentInAppVersion();
    } else {
        // get version synchronously
        return version;
    }
};

export const getCurrentInAppVersion = async () => {
    const current = await CapacitorUpdater.current();
    if (current.bundle.version != "builtin") {
        return current.bundle.version;
    } else {
        return current.native;
    }
};
export const useCurrentInAppVersion = () => {
    const [currentInAppVersion, setCurrentInAppVersion] = useState<string>("");
    useEffect(() => {
        const run = async () => {
            setCurrentInAppVersion(await getCurrentInAppVersion());
        };
        run();
    }, []);

    return currentInAppVersion;
};

export const useCurrentAppVersion = () => {
    const [currentAppVersion, setCurrentAppVersion] = useState<
        string | undefined
    >();
    useEffect(() => {
        async function getCurrentAppVersion() {
            try {
                // Fails locally
                const info = await App.getInfo();
                setCurrentAppVersion(info.version);
            } catch (e) {}
        }

        getCurrentAppVersion();
    }, []);
    return currentAppVersion;
};
export const useHasNewVersion = (
    excludeVersions: string[] = ["DEV", "UNKNOWN"]
) => {
    const previousAppVersion = useInstalledAppVersion();
    const currentAppVersion = self.useCurrentAppVersion();
    const dispatch = useDispatch();
    const showOnBoardingFlow = useSelector(
        (state: RootState) => state.appStatus.showOnBoardingFlow
    );

    const [hasNewVersion, setHasNewVersion] = useState(false);

    // Save version to the store
    useEffect(() => {
        if (
            currentAppVersion &&
            previousAppVersion &&
            currentAppVersion !== previousAppVersion &&
            !excludeVersions.includes(currentAppVersion) &&
            !showOnBoardingFlow
        ) {
            //console.log("New version", previousAppVersion, currentAppVersion);
            // Dispatch
            dispatch(setInstalledAppVersion(currentAppVersion));
            setHasNewVersion(true);
        }
    }, [excludeVersions, previousAppVersion, currentAppVersion, dispatch]);
    return { hasNewVersion, previousAppVersion, currentAppVersion };
};

interface AppUpdateContextProps {
    updating: boolean;
    updateAvailable: boolean;
    reload: () => void;
}

export const AppUpdateContext = createContext<AppUpdateContextProps>({
    updateAvailable: false,
    updating: false,
    reload: () => {},
});
export const useAppUpdateContext = () => {
    return useContext(AppUpdateContext);
};
export const AppUpdateProvider: FC<{}> = ({ children }) => {
    // Setup callbacks for the updater
    const [updating, setUpdating] = useState(false);
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [latestVersion, setLatestVersion] = useState<string>("builtin");
    useEffect(() => {
        console.log("Setting up updater");
        CapacitorUpdater.addListener("download", (e) => {
            if (!updating) {
                setUpdating(true);
            }
        });
        CapacitorUpdater.addListener("downloadComplete", (e) => {
            setUpdating(false);
        });
        CapacitorUpdater.addListener("updateAvailable", (e) => {
            setLatestVersion(e.bundle.id);
            setUpdateAvailable(true);
        });
        CapacitorUpdater.addListener("downloadFailed", (e) => {
            setUpdating(false);
        });

        // Enable live updates
        console.log("Enabling live updates");
        CapacitorUpdater.notifyAppReady();

        // CapacitorUpdater.list().then(console.log)
        // CapacitorUpdater.getLatest().then(console.log).catch(console.log)
        // CapacitorUpdater.getLatest().then(console.log).catch(console.log)
        //CapacitorUpdater.
    }, []);

    const reload = useCallback(() => {
        (async () => {
            await CapacitorUpdater.set({ id: latestVersion });
        })();
    }, [latestVersion]);
    return (
        <AppUpdateContext.Provider
            value={{
                updating,
                updateAvailable,
                reload,
            }}
        >
            {children}
        </AppUpdateContext.Provider>
    );
};
