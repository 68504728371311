import { FC, useCallback, useEffect, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonIcon,
    IonItem,
    IonLoading,
    IonPage,
    IonText,
    IonAlert,
    useIonRouter,
} from "@ionic/react";
import { HeaderComponent } from "../../../../components";
import { useTranslation } from "react-i18next";
import { Browser } from "@capacitor/browser";
import refsixSquareLogo from "../../../../assets/images/icon/refsix-square-icon.png";
import assignrSquareLogo from "../../../../assets/images/icon/assignr-square-icon.png";
import {
    disconnectAssignrSubscription,
    getAssignrAccessToken,
    syncAssignrMatches,
} from "../../../../services/integrationService";
import { refreshSubscriptions } from "../../../../services/subscriptionService";
import { useSubscription } from "../../../../components/hooks";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";
import { IntegrationPartner, SubscriptionItems } from "refsix-js-models";
import { routes } from "../../../../route/constants";
import { Capacitor } from "@capacitor/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { trackEvent } from "../../../../services/analytics/analyticsService";
import { RouteComponentProps } from "react-router";
import { syncOutline } from "ionicons/icons";

const AUTH_URL = process.env.REACT_APP_ASSIGNR_AUTH_URL;
const CLIENT_ID = process.env.REACT_APP_ASSIGNR_CLIENT_ID as string;
const REDIRECT_URI = process.env.REACT_APP_ASSIGNR_REDIRECT_URI as string;
const SCOPE = "games.read account.read";

const AssignrIntegration: FC<{ autoStart: boolean }> = ({
    autoStart = false,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [syncStatus, setSyncStatus] = useState<string | null>(null);
    const [syncMatchesData, setSyncMatchesData] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSubscriptionValid, setIsSubscriptionValid] =
        useState<boolean>(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

    const subscriptions = useSubscription();
    const location = useLocation();
    const route = useIonRouter();
    const developerToolsEnabled = useSelector(
        (state: RootState) => state.developerTools.enabled
    );

    interface MatchData {
        gameID: string;
        startTime: string;
        homeTeam: string;
        awayTeam: string;
        venueId: string;
        venueName: string;
        siteId: string;
        siteName: string;
    }

    const getAssignrRedirectUri = () => {
        if (Capacitor.isNativePlatform()) {
            // use custom redirect URI e.g. refsix:/// in order for deep link to return to the app
            return REDIRECT_URI;
        } else {
            // use current host/port
            return window.location.origin + "/integrations/assignr";
        }
    };

    useEffect(() => {
        const assignrSubscription = subscriptions?.subscriptions.find(
            (subscription: SubscriptionItems) =>
                subscription.provider === "assignr"
        );
        if (
            assignrSubscription &&
            new Date(assignrSubscription.validUntil) > new Date()
        ) {
            setIsSubscriptionValid(true);
        } else {
            setIsSubscriptionValid(false);
        }
    }, [subscriptions]);

    useEffect(() => {
        refreshSubscriptions().catch((error) => {
            console.error("Error checking subscriptions:", error);
        });

        // Handle OAuth callback and get access token if authorization code is present

        const searchParams = location.search || location.location?.search;
        if (searchParams) {
            const parsedUrl = new URLSearchParams(searchParams); // Use URLSearchParams to parse the query string
            const code = parsedUrl.get("code"); // Extract the authorization code

            if (code) {
                setLoading(true);
                setErrorMessage(null); // Clear any previous error message
                getAssignrAccessToken(code, getAssignrRedirectUri())
                    .then((response) => {
                        console.log("Access Token Response:", response);
                        if (response.status === 200) {
                            trackEvent("AddIntegrationSuccess", {
                                partner: IntegrationPartner.assignr,
                            });

                            refreshSubscriptions()
                                .then(async (response) => {
                                    if (
                                        Capacitor.isPluginAvailable("Browser")
                                    ) {
                                        console.warn(
                                            "The @capacitor/browser plugin was not found"
                                        );
                                        Browser.close().then(async () => {
                                            if (route.canGoBack()) {
                                                route.goBack();
                                            } else {
                                                route.push(
                                                    routes.refsixAssignrIntegration
                                                );
                                            }
                                        });
                                    }
                                    await syncAssignrMatches();
                                    setLoading(false);
                                    setShowSuccessAlert(true);
                                })
                                .catch((error) => {
                                    console.error(
                                        "Error checking subscriptions:",
                                        error
                                    );
                                });
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        trackEvent("AddIntegrationFailed", {
                            partner: IntegrationPartner.assignr,
                        });

                        console.error(
                            "Error while fetching access token:",
                            error
                        );
                        Sentry.captureException(error);
                        setErrorMessage("Failed to get access token.");
                    });
            } else {
                console.error(
                    "Authorization code not found in search parameters."
                );
                setLoading(false);
                setErrorMessage("Authorization code not found.");
            }
        }
    }, [location]);

    // If autoStart is passed in the URL, start the OAuth flow
    const startOAuthFlow = useCallback(async () => {
        const authorizationUrl = `${AUTH_URL}?response_type=code&client_id=${encodeURIComponent(
            CLIENT_ID
        )}&redirect_uri=${encodeURIComponent(
            getAssignrRedirectUri()
        )}&scope=${encodeURIComponent(SCOPE)}`;
        await Browser.open({ url: authorizationUrl });
    }, []);

    useEffect(() => {
        if (autoStart) {
            startOAuthFlow();
        }
    }, [startOAuthFlow, autoStart]);

    const syncWithAssignr = async () => {
        setLoading(true);
        const syncMatchesResponse = await syncAssignrMatches();
        if (!syncMatchesResponse) {
            setSyncStatus("Sync Failed. Please re-connect with Assignr.");
            setLoading(false);
            return;
        }
        const matches = syncMatchesResponse.data as MatchData[];
        setSyncMatchesData(matches);
        setSyncStatus("Sync Successful!");
        setLoading(false);
    };

    const disconnectAssignr = async () => {
        setLoading(true);
        const response = await disconnectAssignrSubscription();
        try {
            if (response.status === 200) {
                trackEvent("RemoveIntegrationSuccess", {
                    partner: IntegrationPartner.assignr,
                });
                setLoading(false);
                refreshSubscriptions().then(() => {
                    setIsSubscriptionValid(false);
                });
            } else {
                trackEvent("RemoveIntegrationFailed", {
                    partner: IntegrationPartner.assignr,
                });
                setLoading(false);
                setErrorMessage("Failed to disconnect Assignr.");
            }
        } catch (error) {
            console.error("Error while disconnecting Assignr:", error);
            Sentry.captureException(error);
            setLoading(false);
            setErrorMessage("Failed to disconnect Assignr.");
        }
    };

    return (
        <IonPage>
            <HeaderComponent showBackButton defaultHref={"/"} title="Assignr" />
            <IonContent>
                <IonLoading isOpen={loading} />
                <IonAlert
                    isOpen={showSuccessAlert}
                    onDidDismiss={() => setShowSuccessAlert(false)}
                    message={t("integrations.successMessage")}
                    buttons={[t("help.OnboardingVideo.controls.ok")]}
                />
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        paddingTop: "36px",
                        paddingBottom: "21px",
                    }}
                >
                    <img
                        style={{ width: "64px" }}
                        src={refsixSquareLogo}
                        alt={"refsix logo"}
                    />
                    <IonText
                        style={{
                            margin: "0 20px",
                            fontSize: "30px",
                            fontWeight: "700",
                        }}
                    >
                        +
                    </IonText>
                    <img
                        style={{ width: "64px" }}
                        src={assignrSquareLogo}
                        alt={"assignr logo"}
                    />
                </div>
                <IonCard>
                    <IonCardContent>
                        <p style={{ color: "white", marginBottom: "16px" }}>
                            <IonText>
                                {t(
                                    "integrations.assignr.integrationDescription"
                                )}
                            </IonText>
                        </p>
                        <p style={{ color: "white", marginBottom: "16px" }}>
                            <IonText>
                                {t(
                                    "integrations.assignr.integrationDisclaimer"
                                )}
                            </IonText>
                        </p>
                        <a
                            href={"https://refsix.com/integrations"}
                            target="_system"
                        >
                            {t("integrations.assignr.learnMore")}
                        </a>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    {!isSubscriptionValid && (
                        <>
                            <IonButton
                                expand="block"
                                onClick={startOAuthFlow}
                                data-testid="button-integration-assignr-connect"
                            >
                                {t("integrations.assignr.startOAuthFlow")}
                            </IonButton>
                        </>
                    )}

                    {isSubscriptionValid && (
                        <>
                            <IonButton
                                expand="block"
                                data-testid="button-integration-assignr-disconnect"
                                onClick={disconnectAssignr}
                            >
                                {t("general.disconnect")}
                            </IonButton>
                            {developerToolsEnabled && (
                                <IonButton
                                    expand="block"
                                    onClick={syncWithAssignr}
                                    data-testid="button-integration-assignr-sync"
                                >
                                    <IonIcon
                                        slot="start"
                                        icon={syncOutline}
                                        style={{ marginRight: "8px" }}
                                    />
                                    Sync
                                </IonButton>
                            )}
                        </>
                    )}
                </IonCard>

                {/* Display sync status */}
                {syncStatus && (
                    <IonCard
                        color={
                            syncStatus === "Sync Successful!"
                                ? "success"
                                : "danger"
                        }
                    >
                        <IonCardContent
                            color={
                                syncStatus === "Sync Successful!"
                                    ? "success"
                                    : "danger"
                            }
                        >
                            <IonText>
                                <p>{syncStatus}</p>
                            </IonText>
                        </IonCardContent>
                    </IonCard>
                )}

                {/* Display synced matches */}
                {developerToolsEnabled && syncMatchesData.length > 0 && (
                    <IonCard>
                        <IonItem>
                            <IonText>
                                <h2>Synced Matches</h2>
                            </IonText>
                        </IonItem>
                        <IonItem>
                            <table
                                style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                    textAlign: "left",
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                borderBottom: "1px solid #ccc",
                                                padding: "8px",
                                            }}
                                        >
                                            Game ID
                                        </th>
                                        <th
                                            style={{
                                                borderBottom: "1px solid #ccc",
                                                padding: "8px",
                                            }}
                                        >
                                            Home Team
                                        </th>
                                        <th
                                            style={{
                                                borderBottom: "1px solid #ccc",
                                                padding: "8px",
                                            }}
                                        >
                                            Away Team
                                        </th>
                                        <th
                                            style={{
                                                borderBottom: "1px solid #ccc",
                                                padding: "8px",
                                            }}
                                        >
                                            Venue Name
                                        </th>
                                        <th
                                            style={{
                                                borderBottom: "1px solid #ccc",
                                                padding: "8px",
                                            }}
                                        >
                                            Site Name
                                        </th>{" "}
                                        {/* New Column for Site Name */}
                                        <th
                                            style={{
                                                borderBottom: "1px solid #ccc",
                                                padding: "8px",
                                            }}
                                        >
                                            Start Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {syncMatchesData.map((match, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: "8px" }}>
                                                {match.gameID}
                                            </td>
                                            <td style={{ padding: "8px" }}>
                                                {match.homeTeam}
                                            </td>
                                            <td style={{ padding: "8px" }}>
                                                {match.awayTeam}
                                            </td>
                                            <td style={{ padding: "8px" }}>
                                                {match.venueName}
                                            </td>
                                            <td style={{ padding: "8px" }}>
                                                {match.siteName}
                                            </td>{" "}
                                            {/* Displaying the Site Name */}
                                            <td style={{ padding: "8px" }}>
                                                {new Date(
                                                    match.startTime
                                                ).toLocaleString()}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </IonItem>
                    </IonCard>
                )}

                {/* Display error message */}
                {errorMessage && (
                    <IonCard color="danger">
                        <IonItem>
                            <IonText color="danger">
                                <p>{errorMessage}</p>
                            </IonText>
                        </IonItem>
                    </IonCard>
                )}
            </IonContent>
        </IonPage>
    );
};

export default AssignrIntegration;
