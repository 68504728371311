import React, { FC } from "react";
import { useFieldArray } from "react-hook-form";
import { IonIcon, IonItem, IonText } from "@ionic/react";
import { addCircle, removeCircle } from "ionicons/icons";
import { CustomIonFormProps } from "./components";
import { MatchEvent } from "refsix-js-models";

export type NumberCounterProps<T> = CustomIonFormProps & {
    itemFactory: () => T;
};
export const NumberCounter: FC<NumberCounterProps<Partial<MatchEvent>>> = ({
    name,
    itemFactory,
}) => {
    const { fields, prepend, remove } = useFieldArray({ name });
    return (
        <IonItem className="item-title" lines="none">
            <div className="add-remove-button-container">
                <IonIcon
                    className={
                        "icon-plus-minus" +
                        (fields.length == 0 ? "-disabled" : "")
                    }
                    icon={removeCircle}
                    size="large"
                    onClick={() => {
                        if (fields.length > 0) {
                            remove(0);
                        }
                    }}
                />
                <IonText>{fields.length}</IonText>
                <IonIcon
                    className="icon-plus-minus"
                    data-testid={name}
                    icon={addCircle}
                    size="large"
                    onClick={() => {
                        prepend(itemFactory());
                    }}
                />
            </div>
        </IonItem>
    );
};
