import { store } from "../redux/store";
import { getLocale } from "../utils/localeUtils";
import request from "./request";

export async function sendRequestTeamSheet(
    matchId: string,
    recipients: any,
    leagueEmail: string,
    teamPacks: any,
    matchDateString: string
) {
    const payload = {
        username: store.getState().auth.session?.user_id,
        matchId: matchId,
        recipients: recipients,
        locale: getLocale(),
        leagueEmail: leagueEmail,
        teamPacks: teamPacks,
        matchDateString: matchDateString,
    };

    return await request.post("/request-team-sheet/request", payload);
}
