export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGOUT = "USER_LOGOUT";
export const SET_SESSION = "SET_SESSION";
export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED";
export const SET_MATCHES = "SET_MATCHES";
export const SELECTED_MATCH = "SELECTED_MATCH";
export const LOADING_MATCHES = "LOADING_MATCHES";
export const SET_USERNAME = "SET_USERNAME";
export const SET_PROFILES = "SET_PROFILES";
export const ADD_PROFILE = "ADD_PROFILE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_USER_ACTIONS_CHECKLIST = "SET_USER_ACTIONS_CHECKLIST";
export const SET_INSTALLED_APP_VERSION = "SET_INSTALLED_APP_VERSION";
export const SET_PUSH_CONSENT_LAST_SHOWN = "SET_PUSH_CONSENT_LAST_SHOWN";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const UPDATE_FILTERS_MATCHES = "UPDATE_FILTERS_MATCHES";
export const UPDATE_FILTERS_RESULTS = "UPDATE_FILTERS_RESULTS";
export const SHOW_INTEGRATION_POPUP = "SHOW_INTEGRATION_POPUP";
export const PUSH_INTEGRATION_MESSAGE = "PUSH_INTEGRATION_MESSAGE";
export const POP_INTEGRATION_MESSAGE = "POP_INTEGRATION_MESSAGE";
export const SET_WATCH_STATUS = "SET_WATCH_STATUS";
export const SET_WATCH_STATUS_SUPPORTED = "SET_WATCH_STATUS_SUPPORTED";

export const SET_LOGGEDIN_OR_REGISTER_COMPLETE = "SET_CAN_SHOW_CHANGE_LOG";

export const SHOW_ONBOARDING_FLOW = "SHOW_ONBOARDING_FLOW";

export const SET_SHOW_WHATS_NEW = "SET_SHOW_WHATS_NEW";
export const SET_SESSION_EXPIRED_MODAL_DISMISSED =
    "SET_SHOULD_SHOW_SESSION_EXPIRED_MODAL";
export const SET_DB_UPDATED_AT = "SET_DB_UPDATED_AT";
export const PUSH_ERROR_MESSAGE = "PUSH_ERROR_MESSAGE";
export const POP_ERROR_MESSAGE = "POP_ERROR_MESSAGE";
export const PUSH_PUSH_MESSAGE = "PUSH_PUSH_MESSAGE";
export const POP_PUSH_MESSAGE = "POP_PUSH_MESSAGE";

export const SET_DEVELOPER_TOOLS_ENABLED = "SET_DEVELOPER_TOOLS_ENABLED";

export const SET_CLOSED_DATES = "SET_CLOSED_DATES";
export const SET_OPEN_DATES = "SET_OPEN_DATES";
export const SET_MESSAGES = "SET_MESSAGES";

export const SET_ACTIVE_EXPERIMENTS = "SET_ACTIVE_EXPERIMENTS";

export const SET_EXPERIMENT_VARIANT = "SET_EXPERIMENT_VARIANT";
export const SET_APPLE_LOGIN_CACHE_VALUE = "SET_APPLE_LOGIN_CACHE_VALUE";
