import { IonCard, IonText, useIonRouter } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import yearImage from "../../assets/images/review/2024-Icon.png";
import "./linkToReview.css";
import { routes } from "../../route/constants";

export const LinkToReview: FC = () => {
    const { t } = useTranslation();
    const router = useIonRouter();

    return (
        <IonCard
            className="border-white"
            data-testid="link-to-review"
            onClick={() => router.push(routes.review)}
        >
            <div className="centered-container-with-padding">
                <img src={yearImage} height={70} />
                <div className="grid-container-with-padding">
                    <IonText className="review-header-text">
                        {t("utils.YearInReview.your2019Review")}
                    </IonText>
                    <IonText>{t("utils.YearInReview.celebrate")}</IonText>
                </div>
            </div>
        </IonCard>
    );
};
