import { Fixture } from "./overview";
import { FC } from "react";
import { updateMatch } from "../../../services/matchService";
import { KeywordsComponent } from "../../../components";
import * as Sentry from "@sentry/react";
import { updateUserActionsCheckList } from "../../../services/userActionsCheckListService";
import ScrollableContentWithAds from "../../../components/ScrollableContentsWithAd";

const Keywords: FC<Fixture> = ({ match }) => {
    const updateKeywords = async (keywords: string[]) => {
        match.keywords = keywords;
        try {
            await updateMatch(match);
            updateUserActionsCheckList({ tagsAdded: true });
        } catch (e) {
            Sentry.captureMessage(
                `Failed trying to update match: ${JSON.stringify(e)}`
            );
            console.log("failed to update match", e);
            // TODO: show alert/toast
        }
    };

    return (
        <ScrollableContentWithAds>
            <KeywordsComponent
                match={match}
                onKeywordsChanged={(keywords) => updateKeywords(keywords)}
            />
        </ScrollableContentWithAds>
    );
};

export default Keywords;
