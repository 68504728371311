const kmToMilesConversionFactor = 0.62137119;
const metersToYardsConversionFactor = 1.0936132983;

export function metersToKmOrMiles(meters: number, useImperial: boolean) {
    return useImperial
        ? (meters / 1000) * kmToMilesConversionFactor
        : meters / 1000;
}

export function metersToYards(meters: number) {
    return meters * metersToYardsConversionFactor;
}

/**
 * Converting meters per seconds to either kilometers per hour or miles per hour
 */
export function metersPerSecondsToKphOrMph(
    meterPerSecond: number,
    useImperial: boolean
) {
    return metersToKmOrMiles(meterPerSecond * 60 * 60, useImperial).toFixed(1);
}
