import { updateMatch } from "./matchService";
import { addRawFitnessData } from "./fitnessDataSync/fitnessDataSyncService";
import { FitnessDataType } from "refsix-js-models";

export async function insertExampleResult(username: string) {
    try {
        const response = await fetch(
            "https://assets.refsix.com/app-content/example-result.json"
        );
        const data = await response.json();
        if (data) {
            data.match.date = new Date().toISOString();
            const gps = {
                ...data.gps,
                data: data.gps.geoPoints,
                geoPoints: null,
            };
            const hr = {
                ...data.hr,
                data: data.hr.heartRateValues,
                heartRateValues: null,
            };

            // No need to unit test these 3 functions, since they are already being tested elsewhere.
            await updateMatch(data.match);
            await addRawFitnessData(gps, username, FitnessDataType.GPS);
            await addRawFitnessData(hr, username, FitnessDataType.HeartRate);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}
