import { IntegrationsMessages } from "../models/refsixState";
import {
    POP_INTEGRATION_MESSAGE,
    PUSH_INTEGRATION_MESSAGE,
} from "../actions/types";
import { uniqWith, isEqual } from "lodash/fp";

// Deep equality check uniqWith
const uniqObj = uniqWith(isEqual);
const initialState: IntegrationsMessages = {
    messages: [],
};

export default function integrationMessageReducer(
    state = initialState,
    action: any
) {
    switch (action.type) {
        case PUSH_INTEGRATION_MESSAGE:
            // Only need to have a single message for each integration status
            const existingMessage = state.messages || [];
            return {
                messages: uniqObj([
                    ...existingMessage,
                    {
                        integration: action.integration,
                        message: action.message,
                    },
                ]),
            };
        case POP_INTEGRATION_MESSAGE:
            return {
                messages: state.messages.slice(1),
            };

        default:
            return state;
    }
}
