// a redux action for the ErrorMessages interface.

import { POP_PUSH_MESSAGE, PUSH_PUSH_MESSAGE } from "./types";
import { PushMessage } from "../models/refsixState";

export const pushPushMessage = (message: PushMessage) => {
    return {
        type: PUSH_PUSH_MESSAGE,
        message,
    };
};

export const popPushMessage = () => {
    return {
        type: POP_PUSH_MESSAGE,
    };
};
