import {
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonSelect,
    IonSelectOption,
    useIonRouter,
} from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HeaderComponent } from "../../../../components";
import { RefsixState } from "../../../../redux/models/refsixState";
import { routes } from "../../../../route/constants";
import {
    getDefaultTemplates,
    saveTemplates,
} from "../../../../services/templateService";

export const ManageTemplates: FC = () => {
    const { t } = useTranslation();
    const router = useIonRouter();

    const templates = useSelector(
        (state: RefsixState) =>
            state.templates.templates || getDefaultTemplates()
    );

    const handleDefaultTemplateChanged = (templateId: string) => {
        saveTemplates(
            { ...templates, defaultTemplate: templateId },
            templates.templates
        );
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMoreSettings}
                title={t("settings.TemplateSettings.title")}
                headerEndText={t("general.add")}
                onClickEndButton={() =>
                    router.push(routes.refsixMoreSettingsTemplatesTemplate)
                }
                titleTestId="templates-page-header"
                endBtnTestId="add-template-button"
            />
            <IonContent>
                <IonCard>
                    <IonItem className="item-title" lines="full">
                        <IonLabel>{t("general.default")}</IonLabel>
                        <IonSelect
                            className="select-text"
                            onIonChange={(e) =>
                                handleDefaultTemplateChanged(e.detail.value)
                            }
                            interface="popover"
                            value={templates?.defaultTemplate}
                        >
                            {templates?.templates.map((template, i) => (
                                <IonSelectOption value={template._id} key={i}>
                                    {template.name}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>
                </IonCard>

                <IonCard data-testid="templates-list">
                    {templates.templates.map((template, i) => (
                        <IonItem
                            key={i}
                            className="item-title"
                            lines={
                                i === templates.templates.length - 1
                                    ? "none"
                                    : "full"
                            }
                            button
                            onClick={() =>
                                router.push(
                                    `${routes.refsixMoreSettingsTemplatesTemplate}/${template._id}`
                                )
                            }
                            data-testid={`template-${i}`}
                        >
                            <IonLabel>{template.name}</IonLabel>
                        </IonItem>
                    ))}
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
