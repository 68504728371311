import {
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel,
    IonList,
    IonText,
} from "@ionic/react";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MatchPhone } from "refsix-js-models";
import { KeywordsComponent } from "./index";

interface NewTagsComponentProps {
    match: MatchPhone;
}

export const NewTagsComponent: FC<NewTagsComponentProps> = ({ match }) => {
    const { t } = useTranslation();
    const [tagsGroupOpen, setTagsGroupOpen] = useState<boolean>(false);
    const { reset } = useFormContext();
    const handleKeyWordAdded = (keywords: string[]) => {
        reset((formValues) => ({ ...formValues, keywords: keywords }));
    };
    return (
        <IonAccordionGroup>
            <IonAccordion onClick={() => setTagsGroupOpen(!tagsGroupOpen)}>
                <IonItem
                    className={
                        tagsGroupOpen
                            ? "item-title-container "
                            : "item-title-container rounded-corners-bottom"
                    }
                    lines="full"
                    data-testid="match-options-tags-accordian"
                    slot="header"
                >
                    <IonLabel position="fixed">
                        {t("general.keywords.title")}
                    </IonLabel>
                    <IonText className="optional-text row-segment-period">
                        {t("general.optional")}
                    </IonText>
                </IonItem>

                <IonList slot="content" className="rounded-corners-bottom">
                    <div className="separator" />
                    <KeywordsComponent
                        match={match}
                        onKeywordsChanged={(e) => handleKeyWordAdded(e)}
                    />
                </IonList>
            </IonAccordion>
        </IonAccordionGroup>
    );
};
