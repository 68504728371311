export enum SpreadSheetEnum {
    Competition = 1,
    Venue,
    Date,
    Time,
    Timezone,
    OfficialRole,
    HomeTeam,
    HomeTeamShort,
    HomeColor,
    AwayTeam,
    AwayTeamShort,
    AwayColor,
    TeamSize,
    SubsNo,
    PeriodsNo,
    Period1,
    Interval1,
    ExtraTimeAvailable,
    ExtraTimeHalfLength,
    PenaltiesAvailable,
    WithGoalScorers,
    MisconductCodeId,
    SinBinSystem,
    SinBinTimerLength,
    Referee,
    Assistant1,
    Assistant2,
    FourthOfficial,
    Observer,
    Fees,
    Expenses,
    Notes,
    Tag1,
    Tag2,
    Tag3,
    Tag4,
    Tag5,
}
