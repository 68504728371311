import {flow, uniq} from "lodash";
import {map} from "lodash/fp";

/**
 * Returns a new array with the items sorted alphanumerically (including non-ASCII)
 */
export function sort(array: string[]): string[] {
    return [...array].sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
    );
}

/**
 * Returns a new array with items sorted alphanumerically (including non-ASCII) and without duplicates
 */
export function uniqSort(array: string[]): string[] {
    return flow(uniq, sort)(array);
}

/**
 * Returns a function that will replace all undefined or null values in an array with 0s.
 */
export const cleanData = map((data: number | undefined | null) => data ?? 0);

/**
 * Deletes null properties as Apple Watch can crash on these. MUTATES OBJECT!
 * @param obj
 * @param recurse
 */
export function deleteNullProperties(obj: any, recurse: boolean) {
    for (var i in obj) {
        if (obj[i] === null) {
            delete obj[i];
        } else if (recurse && typeof obj[i] === "object") {
            deleteNullProperties(obj[i], recurse);
        }
    }
}
