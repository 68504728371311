import {
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonContent,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { AvailabilityType, MatchPhone, OfficialRole } from "refsix-js-models";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import {
    addTeamOfficials,
    createDefaultMatch,
    getPlayersFromTeamPack,
    transFormValuesOut,
    updateMatch,
} from "../../services/matchService";
import {
    AlertComponent,
    HeaderComponent,
    MatchDetailsComponent,
    MatchFormatComponent,
    MatchTeamDetailsComponent,
} from "../../components";
import "../../css/header.css";
import "./createMatch.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { MatchFormatMode } from "../../components/MatchFormatComponent";
import {
    getDefaultTemplates,
    getTemplateById,
} from "../../services/templateService";
import {
    transformInIntervals,
    transformInPeriods,
} from "../../utils/matchConfigUtil";
import { RefsixState } from "../../redux/models/refsixState";
import moment from "moment";
import {
    processUserActionsCheckListForMatchFinished,
    trackMatchCreated,
} from "../../services/analytics/analyticsService";
import { assign, omit } from "lodash";
import { mpUpdatePerson } from "../../services/analytics/mixpanelService";
import { getFixtureResultCount, updateTeamNameInEvents } from "refsix-core";

import { useFixtureResultCount, useMatch } from "../../components/hooks/match";
import { useTeamPacksContext } from "../../components/hooks/TeamPacks";
import {
    useHasFeatureAccess,
    useUserActionsCheckList,
} from "../../components/hooks";
import { hasFeatureAccess } from "../../services/subscriptionService";
import { AddAvailabilityWrapper } from "../addAvailability/addAvailability";
import { routes } from "../../route/constants";
import { updateUserActionsCheckList } from "../../services/userActionsCheckListService";
import "./oldCreateMatch.css";
import { NewMatchOfficialsComponent } from "../../components/NewMatchOfficialsComponent";
import { NewEarningsComponent } from "../../components/NewEarningsComponent";
import { NewNotesComponent } from "../../components/NewNotesComponent";
import { NewTagsComponent } from "../../components/NewTagsComponent";

function CreateMatchPage() {
    const route = useIonRouter();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const pageRef = useRef();
    const fixtureResultCount = useFixtureResultCount();
    const hasFeatureAccessAutoTeamSheet = useHasFeatureAccess("autoTeamSheet");

    // Alert user if changes have been made to the form when clicking back button
    const [showBackAlert, setShowBackAlert] = useState<boolean>(false);
    const [showClosedDateAlert, setShowClosedDateAlert] =
        useState<boolean>(false);
    const { downloadedPacks } = useTeamPacksContext();
    // Set the component's match state based on selector or create default
    const currentMatch = useMatch(id);
    const [savedMatchId, setSavedMatchId] = useState<string | null>(null);
    const [showAddClosedDate, setShowAddClosedDate] = useState<boolean>(false);
    const [createdMatch, setCreatedMatch] = useState<MatchPhone>();
    const [match, setMatch] = useState<MatchPhone>(createDefaultMatch());
    const [loading, setLoading] = useState<boolean>(false);

    const templates = useSelector(
        (state: RefsixState) =>
            state.templates.templates || getDefaultTemplates()
    );
    const refassistPartner = hasFeatureAccess("refAssistMember");
    const userActionsCheckList = useUserActionsCheckList();

    const [expandList, setExpandList] = useState<string>(
        match.integration &&
            match.timings &&
            (!match.timings.sinBinTimerLength ||
                !match.timings.period1 ||
                !match.periodsNo)
            ? "showMatchFormat"
            : ""
    );
    // Single useForm hook for entire form
    const formMethods = useForm<FieldValues>({
        mode: "all",
        defaultValues: match ?? {},
    });
    const {
        handleSubmit,
        reset,
        formState: { isDirty },
    } = formMethods;

    useMemo(() => {
        if (id && !currentMatch) {
            setLoading(true);
        }

        if (id && currentMatch) {
            setMatch(currentMatch);
            setLoading(false);

            setExpandList(
                currentMatch.integration &&
                    currentMatch.timings &&
                    !currentMatch.timings.sinBinTimerLength
                    ? "showMatchFormat"
                    : ""
            );
        }
    }, [currentMatch, id]);

    useEffect(() => {
        if (!match._id) {
            const template = getTemplateById(
                templates.defaultTemplate,
                templates.templates
            );

            if (template && template.config && template.config.timings) {
                reset({
                    ...template.config,
                    date: moment(match.date).format("YYYY-MM-DDTHH:mm"),
                    periods: transformInPeriods(template.config.timings),
                    interval: transformInIntervals(template.config.timings),
                    templateId: template._id,
                    officialRole: match.officialRole || OfficialRole.referee,
                    _id: undefined,
                });
            }
        } else {
            if (match && match.timings) {
                reset({
                    ...match,
                    date: moment(match.date).format("YYYY-MM-DDTHH:mm"),
                    periods: transformInPeriods(match.timings),
                    interval: transformInIntervals(match.timings),
                    officialRole: match.officialRole,
                });
            }
        }
    }, [match]);

    const handleUpdateUserActionsCheckList = (match: MatchPhone) => {
        const updatedUserActionsCheckList = {
            ...userActionsCheckList.userActionsCheckList,
        };

        const processedUserActions =
            processUserActionsCheckListForMatchFinished(
                match,
                userActionsCheckList,
                updatedUserActionsCheckList,
                !id
            );
        updateUserActionsCheckList(processedUserActions);
    };

    // TODO: Create a type for newMatch
    function handleCreateMatch(newMatch: any) {
        // newMatch can have some fields set to undefined if they are disabled in the UI, for these default to the value from the match object.

        const periods = transFormValuesOut(newMatch.periods, "period");
        const intervals = transFormValuesOut(newMatch.interval, "interval");

        newMatch.homeTeam = newMatch.homeTeam
            ? newMatch.homeTeam.trim()
            : match.homeTeam || t("fixture.FixtureNew.homeTeam");
        newMatch.awayTeam = newMatch.awayTeam
            ? newMatch.awayTeam.trim()
            : match.awayTeam || t("fixture.FixtureNew.awayTeam");

        newMatch.homeTeamShort =
            newMatch.homeTeamShort ||
            match.homeTeamShort ||
            t("fixture.FixtureNew.homeShort");
        newMatch.awayTeamShort =
            newMatch.awayTeamShort ||
            match.awayTeamShort ||
            t("fixture.FixtureNew.awayShort");

        newMatch.competition =
            newMatch.competition ||
            match.competition ||
            t("fixture.FixtureNew.competition");
        newMatch.venue =
            newMatch.venue || match.venue || t("fixture.FixtureNew.venue");

        // Not sure this is the best way to do it
        newMatch.teamSize = Number(newMatch.teamSize);
        newMatch.subsNo = Number(newMatch.subsNo);
        newMatch.earnings.fees = Number(newMatch.earnings.fees);
        newMatch.earnings.expenses = Number(newMatch.earnings.expenses);

        // Joining all the properties in timings
        newMatch.timings = assign(newMatch.timings, periods, intervals);

        // Removing unused properties
        newMatch = omit(newMatch, ["periods", "interval", "templateId"]);

        // convert local dateTime to UTC
        newMatch.date = newMatch.date
            ? moment(newMatch.date).toISOString()
            : match.date;

        newMatch.dateLocalisedString = match.dateLocalisedString;

        if (newMatch.timings.extraTimeHalfLength) {
            // Fix string to number
            newMatch.timings.extraTimeHalfLength = Number(
                newMatch.timings.extraTimeHalfLength
            );
        }

        if (newMatch.timings.sinBinTimerLength) {
            // Fix string to number
            newMatch.timings.sinBinTimerLength = Number(
                newMatch.timings.sinBinTimerLength
            );
        }

        newMatch.officialRole = newMatch.officialRole || match.officialRole;

        if (newMatch.matchOfficials) {
            if (newMatch.officialRole?.indexOf("assistant1") === 0) {
                delete newMatch.matchOfficials.assistant1;
            } else {
                if (
                    newMatch.officialRole !== undefined &&
                    newMatch.officialRole !== OfficialRole.assistant
                ) {
                    delete newMatch.matchOfficials[newMatch.officialRole];
                }
            }
        }

        //Adding team officials to each team's players
        newMatch.players = newMatch.players || {};

        // Don't create new players if editing match
        if (!match._id) {
            if (hasFeatureAccessAutoTeamSheet) {
                newMatch.players = {
                    [newMatch.homeTeam]: getPlayersFromTeamPack(
                        newMatch.homeTeam,
                        downloadedPacks
                    ),
                    [newMatch.awayTeam]: getPlayersFromTeamPack(
                        newMatch.awayTeam,
                        downloadedPacks
                    ),
                };
            }
        } else {
            // move existing players onto new team names
            newMatch.players = {
                [newMatch.homeTeam]:
                    match && match.players ? match.players[match.homeTeam] : [],
                [newMatch.awayTeam]:
                    match && match.players ? match.players[match.awayTeam] : [],
            };
        }

        newMatch.players = addTeamOfficials(
            newMatch.players,
            newMatch.homeTeam,
            newMatch.awayTeam
        );

        updateTeamNameInEvents(
            newMatch,
            match.homeTeam,
            match.awayTeam,
            newMatch.homeTeam,
            newMatch.homeTeamShort,
            newMatch.awayTeam,
            newMatch.awayTeamShort
        );

        updateMatch(newMatch).then(async (savedFixture) => {
            if (savedFixture) {
                trackMatchCreated(savedFixture);
                setCreatedMatch(savedFixture);

                handleUpdateUserActionsCheckList(savedFixture);
            }

            if (fixtureResultCount) {
                // Update Fixture count
                mpUpdatePerson(fixtureResultCount);
            }

            if (refassistPartner && savedFixture && savedFixture._id) {
                setSavedMatchId(savedFixture._id);
                setShowClosedDateAlert(true);
            } else {
                if (savedFixture) {
                    route.push(
                        `${routes.match}/${savedFixture._id}/overview`,
                        "root"
                    );
                }
            }
        });
    }

    const handBackClicked = () => {
        if (isDirty) {
            setShowBackAlert(true);
        } else {
            if (route.canGoBack()) {
                route.goBack();
            } else {
                route.push(routes.refsixFixtures, "root");
            }
        }
    };

    return (
        <IonPage ref={pageRef}>
            <HeaderComponent
                customStartButton
                customStartButtonOnClick={() => handBackClicked()}
                title={
                    match._id
                        ? t("fixture.Fixture.editFixture")
                        : t("fixture.FixtureList.addFixture")
                }
                headerEndText={
                    match._id
                        ? t("general.done")
                        : t("fixture.FixtureNew.create")
                }
                endBtnTestId="createMatchDoneBtn"
                onClickEndButton={handleSubmit(handleCreateMatch)}
            />
            <IonContent className="old-create-match">
                <IonLoading
                    isOpen={loading}
                    htmlAttributes={{ "data-testid": "editMatchLoading" }}
                />
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(handleCreateMatch)}>
                        <IonCard
                            className="match-card-container create-match-wrapper"
                            style={{
                                marginBottom: 5,
                            }}
                        >
                            <MatchDetailsComponent
                                match={match}
                                integration={!!match.integration}
                            />
                            <MatchTeamDetailsComponent
                                match={match}
                                integration={!!match.integration}
                            />

                            <IonRow className="row-container">
                                <div className="create-match-step">
                                    <IonText>3</IonText>
                                </div>
                                <IonText className="create-match-card-title">
                                    {t("fixture.FixtureNew.defineMatchFormat")}
                                </IonText>
                            </IonRow>
                            <IonAccordionGroup
                                onIonChange={() => {
                                    if (expandList === "") {
                                        setExpandList("showMatchFormat");
                                    }
                                }}
                                value={expandList}
                                multiple={true}
                                data-testid="accordion-group-match-format"
                            >
                                <IonAccordion value="showMatchFormat">
                                    <MatchFormatComponent
                                        integration={
                                            match.integration
                                                ? match.integration
                                                : undefined
                                        }
                                        mode={MatchFormatMode.Match}
                                    />
                                </IonAccordion>
                            </IonAccordionGroup>
                        </IonCard>

                        <IonCard style={{ marginTop: 0, marginBottom: 5 }}>
                            <NewMatchOfficialsComponent match={match} />
                        </IonCard>
                        <IonCard style={{ marginTop: 0, marginBottom: 5 }}>
                            <NewEarningsComponent />
                        </IonCard>
                        <IonCard style={{ marginTop: 0, marginBottom: 5 }}>
                            <NewNotesComponent />
                        </IonCard>
                        <IonCard style={{ marginTop: 0 }}>
                            <NewTagsComponent match={match} />
                        </IonCard>
                        <input type="submit" hidden={true} />
                    </form>
                </FormProvider>
                {/*
                Space at the bottom to allow for the scrolling input into view when keyboard opening
                */}
                <div style={{ height: "500px" }}></div>
            </IonContent>

            {showAddClosedDate && (
                <AddAvailabilityWrapper
                    closedDate={{
                        startDate: createdMatch
                            ? moment(createdMatch.date).format("YYYY-MM-DD")
                            : moment().format("YYYY-MM-DD"),
                        endDate: createdMatch
                            ? moment(createdMatch.date).format("YYYY-MM-DD")
                            : moment().format("YYYY-MM-DD"),
                        id:
                            createdMatch && createdMatch._id
                                ? createdMatch._id
                                : moment().format("YYYY-MM-DD"),
                        period: { start: "", end: "", name: "ExternalMatch" },
                    }}
                    openClosedDateModal={showAddClosedDate}
                    dismiss={() => {
                        setShowAddClosedDate(false);
                        createdMatch &&
                            route.push(
                                `${routes.match}/${createdMatch._id}/overview`,
                                "root"
                            );
                    }}
                    availabilityType={AvailabilityType.closedDate}
                />
            )}
            <AlertComponent
                testId={"create-match-alert"}
                onDonePressed={() => route.goBack()}
                showAlert={showBackAlert}
                title={t("fixture.FixtureEdit.check.title")}
                description={t("fixture.FixtureEdit.check.description")}
                buttonDone={t("help.OnboardingVideo.controls.ok")}
                showAlternativeButton
                buttonAltText={t("general.cancel")}
                onButtonAltClicked={() => setShowBackAlert(false)}
            />

            <AlertComponent
                testId={"add-closed-date-alert"}
                onDonePressed={() => setShowAddClosedDate(true)}
                showAlert={showClosedDateAlert}
                title={t("availability.closedDate.title")}
                description={t("availability.fixtureAddClosedDate")}
                buttonDone={t("general.yes")}
                showAlternativeButton={true}
                buttonAltText={t("general.no")}
                onButtonAltClicked={() =>
                    route.push(
                        `${routes.match}/${savedMatchId}/overview`,
                        "root"
                    )
                }
            />
        </IonPage>
    );
}

export default CreateMatchPage;
