export const REFSIX_AVAILABILITY_PREFIX = "r6_";
export const REFSIX_OPEN_DATE_PREFIX = REFSIX_AVAILABILITY_PREFIX + "od_";
export const REFSIX_CLOSED_DATE_PREFIX = REFSIX_AVAILABILITY_PREFIX + "cd_";

/**
 * Represents an open or closed date.
 * In RefAssist a closed date is a leave and an open date is a dayavailability.
 */
export class RefAssistAvailability {
    refAssistId: number;
    partnerId: string;
    timeZoneId: number | null;
    timeZoneName: string | null;
    day: string;
    isFullDay: boolean;
    sportDisciplineId: number; // used when we create
    comments?: string; // only for closed date
    holidayType?: string; // only for closed date
    start?: string; // only if isFullDay is false
    end?: string; // only if isFullDay is false

    constructor(
        refAssistId: number,
        partnerId: string,
        timeZoneId: number,
        timeZoneName: string,
        day: string,
        isFullDay: boolean,
        sportDisciplineId: number,
        comments?: string,
        holidayType?: string,
        start?: string,
        end?: string
    ) {
        this.refAssistId = refAssistId;
        this.partnerId = partnerId;
        this.timeZoneId = timeZoneId;
        this.timeZoneName = timeZoneName;
        this.day = day;
        this.isFullDay = isFullDay;
        this.sportDisciplineId = sportDisciplineId;
        this.comments = comments;
        this.holidayType = holidayType;
        this.start = start;
        this.end = end;
    }

    static fromJSON(json: any) {
        return new RefAssistAvailability(
            json.refAssistId,
            json.partnerId,
            json.timeZoneId,
            json.timeZoneName,
            json.day,
            json.isFullDay,
            json.sportDisciplineId,
            json.comments,
            json.holidayType,
            json.start,
            json.end
        );
    }
}

export type RefAssistCreateAvailability = Omit<
    RefAssistAvailability,
    "refAssistId" | "sportDisciplineId" | "timeZoneName"
>;
