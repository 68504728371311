import { IonContent, IonPage } from "@ionic/react";
import { ConfigPhone } from "refsix-js-models";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HeaderComponent } from "../../../../components";
import { RootState } from "../../../../redux/store";
import { routes } from "../../../../route/constants";
import { updateSettings } from "../../../../services/settingsService";
import { Preferences } from "./preferences";
import { Manage } from "./manage";
import TimerSettings from "./timerSettings";

function Settings() {
    const { t } = useTranslation();

    const settings = useSelector((root: RootState) => root.settings);

    const handleSettingsChange = (props: Partial<ConfigPhone>) => {
        updateSettings({
            ...settings,
            settings: {
                ...settings.settings,
                ...props,
            },
        });
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMore}
                title={t("general.settings")}
                titleTestId="settings-page-header"
            />
            <IonContent>
                <TimerSettings />
                <Manage />
                <Preferences onPreferenceChange={handleSettingsChange} />
            </IonContent>
        </IonPage>
    );
}

export default Settings;
