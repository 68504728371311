import { IonIcon, IonText } from "@ionic/react";
import { triangle } from "ionicons/icons";
import { MatchPhone, OfficialRole, SelectedTeam } from "refsix-js-models";
import { FC, useState } from "react";
import "../pages/tabs/fixtures.css";
import "./MatchUpperCardComponent.css";
import DistanceComponent from "./DistanceComponent";
import { useTranslation } from "react-i18next";
import DateFormatFilterComponent from "./DateFormatFilterComponent";
import AlertComponent from "./AlertComponent";
import TeamRowComponent from "./matchCard/TeamRowComponent";
import { ScoreType } from "./MatchScore";
import { MatchStatus } from "./matchCard/MatchStatus";
import { matchMissingInfo } from "../services/matchService";

import roleReferee from "../assets/images/official-role/referee.png";
import roleAssistant from "../assets/images/official-role/assistant.png";
import roleFourthOfficial from "../assets/images/official-role/fourth-official.png";
import roleObserver from "../assets/images/official-role/observer.png";

const roleIcons = {
    [OfficialRole.referee]: roleReferee,
    [OfficialRole.assistant]: roleAssistant,
    [OfficialRole.fourthOfficial]: roleFourthOfficial,
    [OfficialRole.observer]: roleObserver,
};

interface Match {
    match: MatchPhone;
}

const MatchUpperCardComponent: FC<Match> = ({ match }) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState<boolean>(false);

    return (
        <div style={{ width: "100%" }} className="match-upper-card separator">
            <div className="card-top-container">
                <IonText color="primary" className="match-date">
                    {match.dateLocalisedString && match.dateLocalisedString}
                    {!match.dateLocalisedString && (
                        <>
                            <DateFormatFilterComponent
                                value={match.date}
                                formatString={"ddd ll"}
                                timezone={match.timezone}
                            />

                            <DateFormatFilterComponent
                                value={match.date}
                                formatString={"time"}
                                timezone={match.timezone}
                            />
                        </>
                    )}
                </IonText>

                <div style={{ display: "flex" }}>
                    <MatchStatus match={match} />

                    {matchMissingInfo(match) && (
                        <IonIcon
                            icon={triangle}
                            size="small"
                            color="danger"
                            className="missing-info-triangle"
                            data-testid={`missing-info-triangle-${match._id}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setShowAlert(true);
                            }}
                        />
                    )}

                    <div
                        className="distance-role"
                        hidden={matchMissingInfo(match)}
                    >
                        {match.stats && match.stats?.distanceTotal !== 0 && (
                            <IonText
                                color="primary"
                                className="distance distance-total"
                            >
                                <DistanceComponent
                                    distanceInMeters={match.stats.distanceTotal}
                                />
                            </IonText>
                        )}
                        {match.matchInProgress && !match.matchFinished && (
                            <div className="live-indicator">
                                {t("fixture.FixtureSummary.matchLive")}
                            </div>
                        )}

                        {match.officialRole && (
                            <div className={`role-icons`}>
                                <img
                                    src={roleIcons[match.officialRole]}
                                    alt=""
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <TeamRowComponent
                match={match}
                side={SelectedTeam.home}
                scoreType={ScoreType.homeScoreAndKFTPM}
            ></TeamRowComponent>
            <TeamRowComponent
                match={match}
                side={SelectedTeam.away}
                scoreType={ScoreType.awayScoreAndKFTPM}
            ></TeamRowComponent>

            <AlertComponent
                testId={`missing-info-alert-${match._id}`}
                onDonePressed={() => setShowAlert(false)}
                showAlert={showAlert}
                description={t("integrations.missingInformation.description")}
                title={t("integrations.info")}
                buttonDone={t("help.OnboardingVideo.controls.ok")}
            />
        </div>
    );
};

export default MatchUpperCardComponent;
