import { FC, useEffect, useState } from "react";
import {
    IonCard,
    IonItem,
    IonLabel,
    IonToggle,
    useIonRouter,
} from "@ionic/react";
import { routes } from "../../../../route/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ConfigPhone } from "refsix-js-models";
import { updateSettings } from "../../../../services/settingsService";
import { useUserActionsCheckList } from "../../../../components/hooks";
import { updateUserActionsCheckList } from "../../../../services/userActionsCheckListService";
import { GoProOrPlusAlertComponent } from "../../../../components/upgrade/GoProOrPlusAlertComponent";
import { useHasFeatureAccess } from "../../../../components/hooks";

const TimerSettings: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const settings = useSelector((root: RootState) => root.settings);
    const { automaticallyBeginHalfTime, elapsedHalfTimerFromZero } =
        settings.settings;
    const [proAlert, setProAlert] = useState(false);
    const hasTimerAccess = useHasFeatureAccess("timers");
    const userActionsCheckListState = useUserActionsCheckList();

    useEffect(() => {
        // This was set as true for all users per default, but we want to change that to false for all users without timer access.
        if (!hasTimerAccess) {
            handleSettingsChange({
                elapsedHalfTimerFromZero: false,
            });
        }
    }, []);

    const handleSettingsChange = (props: Partial<ConfigPhone>) => {
        if (
            props.automaticallyBeginHalfTime &&
            !userActionsCheckListState.hTStartChanged
        ) {
            updateUserActionsCheckList({
                hTStartChanged: true,
            });
        } else if (
            props.elapsedHalfTimerFromZero &&
            !userActionsCheckListState.startFromZeroChanged
        ) {
            updateUserActionsCheckList({
                startFromZeroChanged: true,
            });
        }

        updateSettings({
            ...settings,
            settings: {
                ...settings.settings,
                ...props,
            },
        });
    };

    return (
        <>
            <IonCard>
                <IonItem
                    className="item-title-container rounded-corners-top"
                    lines="full"
                >
                    {t("settings.TimerSettings.title")}
                </IonItem>
                <IonItem
                    button
                    className="item-title"
                    data-testid="timer-options-button"
                    onClick={() => route.push(routes.refsixTimerOptions)}
                    lines="full"
                >
                    <IonLabel>
                        {t("settings.SettingsMain.timerSettings")}
                    </IonLabel>
                </IonItem>
                <IonItem className="item-title" lines="full">
                    <IonLabel>{t("general.automaticHalfTime")}</IonLabel>
                    <IonToggle
                        data-testid="automatic-half-time-toggle"
                        checked={automaticallyBeginHalfTime}
                        onIonChange={(e) =>
                            handleSettingsChange({
                                automaticallyBeginHalfTime: e.detail.checked,
                            })
                        }
                    />
                </IonItem>
                <IonItem className="item-title" lines="none">
                    <IonLabel>
                        {t("settings.TimerSettings.secondHalfReset")}
                    </IonLabel>
                    <IonToggle
                        checked={elapsedHalfTimerFromZero}
                        data-testid="timer-settings-elapsed-half-timer-from-zero"
                        onIonChange={(e) => {
                            if (hasTimerAccess) {
                                handleSettingsChange({
                                    elapsedHalfTimerFromZero: e.detail.checked,
                                });
                            } else {
                                setProAlert(true);
                            }
                        }}
                    />
                </IonItem>
            </IonCard>
            <GoProOrPlusAlertComponent
                showProAlert={proAlert}
                setShowProAlert={setProAlert}
                goProTestId={"pro-alert-elapsed-half-timer-from-zero"}
                teaserName={"timerSettings"}
            />
        </>
    );
};

export default TimerSettings;
