export const REFSIX_GREEN = "#d5e830";
export const YELLOW_CARD = "#F9D401";
export const RED_CARD = "#930303";
export const YELLOW_CARD_GRAPH = "#f9d401";
export const RED_CARD_GRAPH = "#f60f49";

export const COLORS_DISTANCE = [
    "#d5e830",
    "#f442d7",
    "#33cbb3",
    "#953091",
    "#2d685f",
    "#a569bd",
];
export const COLORS_SPEED_CATEGORY = [
    "#ffeb3b",
    "#ffc107",
    "#ff9000",
    "#ff3414",
];
export const COLORS_SPRINT_CATEGORY = ["#ffeb3b", "#ff9000", "#ff3414"];
export const COLORS_HEART_RATE_ZONES = [
    "#f5e737",
    "#ffce00",
    "#ff9a00",
    "#ff5a00",
    "#d00000",
];
export const COLORS_GOALS = ["#d5e830", "#f442d7"];
export const COLORS_WINS = ["#d5e830", "#33cbb3", "#f442d7"];
export const COLORS_HEART_RATE = ["#e91e02", "#f2bd0b"];
export const COLORS_YELLOW_CARDS = [
    "#FFC80F",
    "#FFD310",
    "#FFDF11",
    "#FFEB12",
    "#FFF714",
];
export const COLORS_RED_CARDS = [
    "#750200",
    "#970E11",
    "#BA1A23",
    "#DC2634",
    "#FF3346",
];

// https://htmlcolorcodes.com/color-chart/
