import { IonToast } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RefsixState } from "../redux/models/refsixState";
import { useTranslation } from "react-i18next";
import { popPushMessage } from "../redux/actions/pushMessagesActions";
import { useHistory } from "react-router";
import { appLaunchUrlToPath } from "../utils/AppUrlHelper";
import { useUserLoggedIn } from "./hooks";
import { getUTMParamsFromURL } from "../services/analytics/utmAttribution";
import { mpRegisterSuperProperties } from "../services/analytics/mixpanelService";
import { trackEvent } from "../services/analytics/analyticsService";

export const PushToaster: React.FC = () => {
    const { t } = useTranslation();
    const pushMessages = useSelector(
        (state: RefsixState) => state.pushMessages.messages
    );
    const toastEl = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const loggedIn = useUserLoggedIn();

    // react state hook for the translated error message to be displayed
    // const [translatedErrorMessage, setTranslatedErrorMessage] = useState("");

    useEffect(() => {
        if (pushMessages.length > 0) {
            const message = pushMessages[0];
            console.log("PushToaster: " + message);
        }
    }, [pushMessages]);

    useEffect(() => {
        if (toastEl.current) {
            console.log("toastEl.current: " + toastEl.current);
        }
    }, [toastEl]);

    if (pushMessages.length === 0) return <></>;

    return (
        <IonToast
            ref={toastEl}
            isOpen={pushMessages.length > 0}
            header={t("notifications.notification")}
            message={t(pushMessages[0].body)}
            position="top"
            onDidDismiss={() => {
                dispatch(popPushMessage());
            }}
            duration={5000}
            buttons={[
                {
                    text: t("settings.WatchSettings.continue"),
                    role: "cancel",
                    handler: () => {
                        // TODO with current routing deep links to logged in content will lead to black
                        if (loggedIn && pushMessages[0].launchUrl) {
                            const path = appLaunchUrlToPath(
                                pushMessages[0].launchUrl
                            );

                            if (path) {
                                const utmParams = getUTMParamsFromURL(path);
                                mpRegisterSuperProperties(utmParams);
                                trackEvent("PushNotificationClicked", {
                                    urlPath: path,
                                    trigger: "in-app-banner",
                                    ...utmParams,
                                });

                                history.push(path);
                            }
                        }
                        dispatch(popPushMessage());
                    },
                },
            ]}
            color={"primary"}
        />
    );
};
