import { FC } from "react";
import {
    IonButton,
    IonCard,
    IonCol,
    IonItem,
    IonModal,
    IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { MatchPhone } from "refsix-js-models";

interface MatchReportProps {
    match: MatchPhone;
    openMatchReportModal: boolean;

    onDismiss(): void;

    onSendMatchReport(): void;
}

const MatchReportModal: FC<MatchReportProps> = ({
    match,
    openMatchReportModal,
    onDismiss,
    onSendMatchReport,
}) => {
    const { t } = useTranslation();

    return (
        <IonModal
            isOpen={openMatchReportModal}
            onDidDismiss={() => onDismiss()}
            initialBreakpoint={1}
            showBackdrop
        >
            <IonCard className="ion-card-transparent">
                <IonItem lines="none" color="none">
                    <IonText>{t("integrations.stackSports.title")}</IonText>
                </IonItem>

                <IonItem lines="none" color="none">
                    <IonText>
                        {t("integrations.stackSports.submitReportDesc")}
                    </IonText>
                </IonItem>
                <IonItem lines="none" color="none">
                    <IonCol slot="end" className="align-text-end">
                        <IonText>{match.homeTeamShort}</IonText>
                    </IonCol>
                    <IonCol slot="end" className="align-text-end">
                        <IonText>
                            <IonText>{match.awayTeamShort}</IonText>
                        </IonText>
                    </IonCol>
                </IonItem>

                <IonItem lines="none" color="none">
                    <IonCol>
                        <IonText>{t("stats.stats.goalsScored")}</IonText>
                    </IonCol>
                    <IonCol slot="end" className="align-text-end">
                        {match.stats?.goalsHomeTotal}
                    </IonCol>
                    <IonCol slot="end" className="align-text-end">
                        {match.stats?.goalsAwayTotal}
                    </IonCol>
                </IonItem>
                <IonItem lines="none" color="none">
                    <IonCol>
                        <IonText>{t("general.yellow")}</IonText>
                    </IonCol>
                    <IonCol slot="end" className="align-text-end">
                        {match.stats?.yellowCardHomeTotal}
                    </IonCol>
                    <IonCol slot="end" className="align-text-end">
                        {match.stats?.yellowCardAwayTotal}
                    </IonCol>
                </IonItem>
                <IonItem lines="none" color="none">
                    <IonCol>
                        <IonText>{t("general.red")}</IonText>
                    </IonCol>
                    <IonCol slot="end" className="align-text-end">
                        {match.stats?.redCardHomeTotal}
                    </IonCol>
                    <IonCol slot="end" className="align-text-end">
                        {match.stats?.redCardAwayTotal}
                    </IonCol>
                </IonItem>

                <IonItem lines="none" color="none">
                    <IonCol size="6">
                        <IonButton expand="block" onClick={() => onDismiss()}>
                            <IonText>{t("general.cancel")}</IonText>
                        </IonButton>
                    </IonCol>
                    <IonCol size="6">
                        <IonButton
                            expand="block"
                            onClick={() => onSendMatchReport()}
                        >
                            <IonText>{t("fixture.FixtureShare.send")}</IonText>
                        </IonButton>
                    </IonCol>
                </IonItem>
            </IonCard>
        </IonModal>
    );
};

export default MatchReportModal;
