import { FC, useEffect, useRef, useState } from "react";

export type ScrollableContentProps = {
    children: React.ReactNode;
    bottomOffset?: number; // how many pixels to offset the bottom of the scrollable content
};

const ScrollableContent: FC<ScrollableContentProps> = ({
    children,
    bottomOffset,
}) => {
    const [height, setHeight] = useState<string>("1000px");

    const domElement = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        updateSectionHeight();
        window.addEventListener("resize", updateSectionHeight);
        return () => {
            // remove listeners when the component is destroyed
            window.removeEventListener("resize", updateSectionHeight);
        };
    }, [height, bottomOffset]);

    function updateSectionHeight() {
        if (height !== getContainerHeight()) {
            setHeight(getContainerHeight());
        }
    }

    function getContainerHeight(): string {
        if (domElement && domElement.current) {
            const offset = bottomOffset ? bottomOffset : 0;
            return (
                window.innerHeight -
                domElement.current.getBoundingClientRect().top -
                offset +
                "px"
            );
        }
        return `${window.innerHeight} px`;
    }

    return (
        <div
            style={{ overflowY: "scroll", height: height }}
            ref={domElement}
            className="scrollable-contents"
        >
            {children}
        </div>
    );
};

export default ScrollableContent;
