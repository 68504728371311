import {
    HalfEvent,
    HrData,
    HrSummary,
    MatchPhone,
    Profile,
    Stats,
} from "refsix-js-models";

import { HeartRateProcessingService } from "../fitness/HeartRateProcessingService";

export function processHR(
    stats: Stats,
    match: MatchPhone,
    hrData: HrData,
    matchSegments: HalfEvent[],
    profile: Profile
): Stats {
    let heartRateProcessingService = new HeartRateProcessingService();
    let sortedValues = heartRateProcessingService.sortHeartRate(hrData);

    let filteredValues = heartRateProcessingService.filterInvalidPoints(
        sortedValues,
        matchSegments
    );

    if (!filteredValues || !filteredValues.length) {
        console.log(`processHR no filtered values ${match._id}`);
        return stats;
    }
    hrData.heartRateValues = filteredValues;
    let heartRateSummary: HrSummary = heartRateProcessingService.summaryHR(
        hrData.heartRateValues
    );
    stats.heartRateAvailable[0] = 1;
    let periodsNumber = match.periodsNo || "2";
    stats.heartRateAvailable[parseInt(periodsNumber) - 1] = 1;
    stats.heartRateProcessed = 1;
    stats.heartRateMax = heartRateSummary.max ? heartRateSummary.max : 0;
    stats.heartRateAverage = heartRateSummary.average
        ? heartRateSummary.average
        : 0;
    stats.heartRateZoneDuration = heartRateProcessingService.buildHrZoneGraph(
        profile,
        match.date,
        filteredValues
    );
    stats.heartRateZone4And5 =
        stats.heartRateZoneDuration[3] + stats.heartRateZoneDuration[4];
    return stats;
}
