import { IonSelect } from "@ionic/react";
import { FC } from "react";
import { useController } from "react-hook-form";
import { CustomIonFormProps } from "../../pages/addResult/components";
import { SelectInterface } from "@ionic/core";
import "./R6IonSelect.css";

type WithTestId = { testid?: string };
type WithInterface = { interface?: SelectInterface };
type WithInterfaceOptions = { interfaceOptions?: any };

export const R6IonSelect: FC<
    Partial<
        CustomIonFormProps & WithTestId & WithInterface & WithInterfaceOptions
    >
> = ({
    className,
    interface: interfaceName,
    testid,
    name,
    rules,
    slot = "end",
    children,
    compareWith,
    interfaceOptions,
    label,
}) => {
    const { field } = useController({
        name: name || "",
        rules,
    });

    const mappedField = {
        ...field,
        onIonChange: field.onChange,
    };
    return (
        <IonSelect
            className={className || "select-text"}
            interface={interfaceName || "popover"}
            data-testid={testid || name}
            slot={slot}
            {...mappedField}
            compareWith={compareWith}
            interfaceOptions={interfaceOptions || {}}
            label={label}
        >
            {children}
        </IonSelect>
    );
};
