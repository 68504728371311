import { FC, useEffect, useRef, useState } from "react";
import { Config, MatchData, MatchPhone } from "refsix-js-models";
import { IonicSafeString, isPlatform, useIonRouter } from "@ionic/react";
import { BackgroundMode } from "@awesome-cordova-plugins/background-mode";
import {
    clearSavedData,
    getGPS,
    isMatchStarted,
    loadMatchFromLocalStorage,
    putMatchInLocalStorage,
} from "../services/phoneMatchWatchService";
import { useTranslation } from "react-i18next";
import { handleMatchFromWatch } from "../services/watch/messages/resultRecieved";
import "./PhoneWatch.css";
import { watchMatchIdToMatchId, watchMatchIdToRevision } from "refsix-core";
import { isEqual } from "lodash";
import { handleChunk } from "../services/watch/messages/gpsHrReceived";
import { MessageChunkType } from "../services/watch/TrackingChunkAssembler";
import * as Sentry from "@sentry/react";
import AlertComponent from "./AlertComponent";

const IFRAME_ID = "r6watchFrame";
const EVENT_PREFIX = "r6watch_";

export const PhoneWatch: FC<{
    match: MatchData;
    onFinished: (match: MatchPhone) => any;
}> = ({ match, onFinished }) => {
    const { t } = useTranslation();

    const [showConflictAlert, setShowConflictAlert] = useState<boolean>(false);
    const [conflictAlertConfig, setConflictAlertConfig] = useState<{
        goBack: boolean;
        message: string | IonicSafeString;
    }>({ goBack: false, message: "" });
    const iframe = useRef<HTMLIFrameElement>(null);
    const [ready, setReady] = useState<boolean>(false);
    const route = useIonRouter();

    const onMatchFinished = async () => {
        BackgroundMode.disable();
        const matchData = loadMatchFromLocalStorage() as MatchData;
        const gpsData = getGPS();

        const newMatch = await handleMatchFromWatch(
            // adding matchFinishedTs to trigger MatchFinished event
            { ...matchData.match, matchFinishedTs: Date.now() },
            matchData.template
        );

        if (gpsData && gpsData.length) {
            try {
                await handleChunk(MessageChunkType.gps, {
                    chunkCount: 1,
                    chunkNo: 1,
                    data: gpsData,
                    matchId: watchMatchIdToMatchId(matchData.match.matchId),
                    payloadType: "csv",
                });
            } catch (e) {
                Sentry.captureMessage(
                    `[PhoneWatch] unable to upload GPS ${JSON.stringify(e)}`
                );
            }
        }

        if (newMatch) {
            clearSavedData();
            onFinished(newMatch);
        }
    };

    const showConflictMessage = function (
        message: string | IonicSafeString,
        goBack: boolean
    ) {
        setConflictAlertConfig({ goBack: goBack, message: message });
        setShowConflictAlert(true);
    };

    const showSameMatchConflictMessage = () => {
        return showConflictMessage(
            t("fixture.FixtureReady.matchConflict.sameMatchRunning"),
            false
        );
    };

    const showDifferentMatchConflictMessage = (
        homeTeam: string,
        awayTeam: string
    ) => {
        // var teams = currentMatch.teams[0].teamName + ' v ' + currentMatch.teams[1].teamName;
        var template = `<p>${t(
            "fixture.FixtureReady.matchConflict.differentMatchRunning"
        )}</p><p>${t(
            "fixture.FixtureReady.matchConflict.matchRunning"
        )}</p><p>${homeTeam} V ${awayTeam}</p>`;
        return showConflictMessage(new IonicSafeString(template), true);
    };

    const addEventListener = (eventName: string, cb: () => void) => {
        iframe.current?.contentWindow?.addEventListener(
            `${EVENT_PREFIX}${eventName}`,
            cb
        );
    };
    const removeEventListener = (eventName: string, cb: () => void) => {
        iframe.current?.contentWindow?.removeEventListener(
            `${EVENT_PREFIX}${eventName}`,
            cb
        );
    };
    const hasConfigChanged = (watchConfig: Config) => {
        return isEqual(match.config, watchConfig);
    };

    const init = () => {
        const watchData = loadMatchFromLocalStorage();

        if (watchData) {
            // if there is a match already on the watch
            const currentMatch = watchData.match;
            const currentConfig = watchData.config;
            const currentMatchId = watchMatchIdToMatchId(currentMatch.matchId);
            const currentMatchRev = watchMatchIdToRevision(
                currentMatch.matchId
            );

            const sameMatch =
                currentMatchId === watchMatchIdToMatchId(match.match.matchId);

            if (sameMatch) {
                if (
                    currentMatchRev !==
                        watchMatchIdToRevision(match.match.matchId) ||
                    hasConfigChanged(currentConfig)
                ) {
                    showSameMatchConflictMessage();
                } else {
                    if (!isMatchStarted()) {
                        // send the match to the watch
                        putMatchInLocalStorage(match);
                    }
                    setReady(true);
                }
            } else {
                if (isMatchStarted()) {
                    showDifferentMatchConflictMessage(
                        currentMatch.teams[0].teamName,
                        currentMatch.teams[1].teamName
                    );
                } else {
                    // send the match to the watch
                    putMatchInLocalStorage(match);
                    setReady(true);
                }
            }
        } else {
            // no match already on the watch - send it down
            putMatchInLocalStorage(match);
            setReady(true);
        }
    };

    useEffect(() => {
        if (isPlatform("capacitor")) {
            BackgroundMode.on("activate").subscribe(() => {
                BackgroundMode.disableWebViewOptimizations();
            });
            BackgroundMode.enable();
        }

        init();

        // clean up on dismount
        return () => {
            if (isPlatform("capacitor")) {
                // Disabling background mode as we leave the page
                BackgroundMode.disable();
            }
        };
    }, []);

    // Setup events for iframe
    useEffect(() => {
        if (!ready && iframe.current && iframe.current.contentWindow) {
            iframe.current.contentWindow.document.body.style.backgroundColor =
                "black";
        }

        if (iframe.current && ready) {
            iframe.current.src = "/r6watch/index.html";
            iframe.current.onload = () => {
                addEventListener("matchFinished", onMatchFinished);
            };
        }
        return () => {
            removeEventListener("matchFinished", onMatchFinished);
        };
    }, [iframe.current, ready]);

    return (
        <>
            {showConflictAlert && (
                <AlertComponent
                    title={t("fixture.FixtureReady.matchConflict.title")}
                    description={conflictAlertConfig.message}
                    showAlternativeButton={true}
                    buttonAltText={t("general.cancel")}
                    buttonDone={t(
                        "fixture.FixtureReady.matchConflict.override"
                    )}
                    onDonePressed={() => {
                        putMatchInLocalStorage(match);
                        setReady(true);
                    }}
                    onButtonAltClicked={() => {
                        if (conflictAlertConfig.goBack) {
                            route.goBack();
                        } else {
                            setReady(true);
                        }
                    }}
                    testId={"conflict-alert"}
                    showAlert={showConflictAlert}
                />
            )}
            <iframe ref={iframe} id={IFRAME_ID} />
        </>
    );
};
