import { showKickOffSide } from "../../utils/eventsUtils";
import { EventName, MatchEvent } from "refsix-js-models";
import mapEventNameToDisplayName from "../../utils/MapEventToDisplayName";
import { DisplayEventType } from "../../models/DisplayEventType";
import TimeComponent from "../TimeComponent";
import { IonText } from "@ionic/react";
import { FC } from "react";
import "../components.css";
import { DisplayEvent } from "../../models/DisplayEvent";
import { useTranslation } from "react-i18next";

interface RenderMatchTimeLogInterface {
    matchEvent: MatchEvent;
    displayEvent: DisplayEvent;
    timeZone?: string;
}

export const RenderMatchTimeLog: FC<RenderMatchTimeLogInterface> = ({
    matchEvent,
    displayEvent,
    timeZone,
}) => {
    const { t } = useTranslation();

    return (
        <div className="log-half-container">
            <div className="kick-off-side-container">
                {showKickOffSide(matchEvent) === 0 && (
                    <div className="kick-off-side-dot left" />
                )}
            </div>
            <div className="log-middle-wrapper">
                {!(
                    !displayEvent.period.playing &&
                    displayEvent.event.eventName === EventName.half
                ) && (
                    <IonText className="log-minute-play-text">
                        {/* pre-formatted minute to display */}
                        {displayEvent.displayMinute}
                    </IonText>
                )}

                <IonText className="log-event-name-text">
                    {mapEventNameToDisplayName(matchEvent, t)}
                    {displayEvent.type === DisplayEventType.periodStart && (
                        <span> {t("model.WatchFixture.halfStartSuffix")}</span>
                    )}
                    {displayEvent.type === DisplayEventType.periodEnd && (
                        <span> {t("model.WatchFixture.halfEndSuffix")}</span>
                    )}
                </IonText>
                <IonText className="log-timestamp-text">
                    {displayEvent.type === DisplayEventType.periodEnd &&
                        displayEvent.period?.endTime && (
                            <TimeComponent
                                dateTimeInUTC={displayEvent.period.endTime}
                                timezone={timeZone}
                            />
                        )}
                    {displayEvent.type !== DisplayEventType.periodEnd && (
                        <TimeComponent
                            dateTimeInUTC={matchEvent.timestamp}
                            timezone={timeZone}
                        />
                    )}
                </IonText>
                {displayEvent.score &&
                    `(${displayEvent.score.home} - ${displayEvent.score.away})`}
            </div>
            <div className="kick-off-side-container">
                {showKickOffSide(matchEvent) === 1 && (
                    <div className="kick-off-side-dot right" />
                )}
            </div>
        </div>
    );
};
