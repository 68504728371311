// a react ionic page component to display the developer tools

import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import { routes } from "../../route/constants";
import React, { useState } from "react";
import { bug, cardOutline, cog, flower, save, watch } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { isIos } from "../../services/platformDetection";
import { useSubscription } from "../../components/hooks";
import { isUserPlusEligible } from "../../services/subscriptionService";
import * as Sentry from "@sentry/react";
import { MigrateSystemBModal } from "../../modals/migrateSystemBModals";

const DeveloperTools: React.FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const subscription = useSubscription();

    const [isOpen, setIsOpen] = useState(false);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref="/"
                            text={t("help.OnboardingVideo.controls.back")}
                        />
                    </IonButtons>
                    <IonTitle>Developer Tools</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent id="printme">
                <IonList>
                    <IonItem>
                        <IonText>
                            This page contains tools designed to help debug the
                            app.
                        </IonText>
                    </IonItem>
                    <IonItem routerLink={routes.mockWatchMessaging}>
                        <IonIcon icon={watch} slot="start" />
                        <IonLabel>Mock Watch</IonLabel>
                    </IonItem>
                    <IonItem routerLink={routes.refsixMoreWatchDebug}>
                        <IonIcon icon={watch} slot="start" />
                        <IonLabel>Watch Debugger</IonLabel>
                    </IonItem>
                    <IonItem routerLink={routes.pouchDebug}>
                        <IonIcon icon={save} slot="start" />
                        <IonLabel>PouchDB Changes</IonLabel>
                    </IonItem>
                    <IonItem routerLink={routes.pouchDebugMatch}>
                        <IonIcon icon={save} slot="start" />
                        <IonLabel>PouchDB Doc Revisions</IonLabel>
                    </IonItem>
                    <IonItem onClick={() => route.push(routes.registerUpgrade)}>
                        <IonIcon icon={cardOutline} slot="start" />
                        <IonLabel>Register upgrade flow</IonLabel>
                    </IonItem>
                    <IonItem
                        data-testid="developer-tools-onboarding-flow-button"
                        onClick={() =>
                            route.push(routes.refsixOnboardingGettingStarted)
                        }
                    >
                        <IonIcon icon={flower} slot="start" />
                        <IonLabel>OnBoarding flow</IonLabel>
                    </IonItem>
                    <IonItem
                        data-testid="developer-tools-onboarding-bt-flow-button"
                        onClick={() =>
                            route.push(routes.refsixOnBoardingShareLocationData)
                        }
                    >
                        <IonIcon icon={flower} slot="start" />
                        <IonLabel>OnBoarding Permissions</IonLabel>
                    </IonItem>
                    <IonItem
                        data-testid="developer-tools-onboarding-bt-flow-button"
                        onClick={() =>
                            route.push(
                                routes.refsixOnboardingFoundWatch +
                                    (isIos() ? "/apple" : "/google")
                            )
                        }
                    >
                        <IonIcon icon={flower} slot="start" />
                        <IonLabel>OnBoarding Bluetooth Watch</IonLabel>
                    </IonItem>
                    <IonItem
                        data-testid="developer-tools-upgrade-button"
                        onClick={() =>
                            route.push(
                                `${routes.upgrade}/${
                                    subscription?.isPlus ||
                                    !isUserPlusEligible()
                                        ? "pro"
                                        : "plus"
                                }`
                            )
                        }
                    >
                        <IonIcon icon={cardOutline} slot="start" />
                        <IonLabel>REFSIX PLUS UPGRADE</IonLabel>
                    </IonItem>
                    <IonItem
                        data-testid="developer-tools-crash"
                        onClick={() =>
                            Sentry.captureMessage(
                                "TEST: Developer tools crash button pressed"
                            )
                        }
                    >
                        <IonIcon icon={bug} slot="start" />
                        <IonLabel>Report crash</IonLabel>
                    </IonItem>
                    <IonItem
                        data-testid="developer-tools-migrateSinBinSystemB"
                        onClick={() => {
                            console.log("TEST: Migrate System B SinBin System");
                            setIsOpen(true);
                        }}
                    >
                        <IonIcon icon={cog} slot="start" />
                        <IonLabel>Migrate System B SinBin System</IonLabel>
                        <MigrateSystemBModal
                            isOpen={isOpen}
                            onDismiss={() => setIsOpen(false)}
                        />
                    </IonItem>
                    <IonItem
                        data-testid="developer-tools-crash"
                        onClick={async () => {
                            route.push(routes.review);
                        }}
                    >
                        {/*<IonIcon icon={} slot="start" />*/}
                        <IonLabel>Review</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default DeveloperTools;
