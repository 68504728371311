import { SET_USER_ACTIONS_CHECKLIST } from "../actions/types";
import { UserActionsCheckListState } from "../models/refsixState";
import { UserActionsCheckList } from "refsix-js-models";

const initialState = new UserActionsCheckListState();

function userActionsCheckListReducer(
    state: UserActionsCheckList = initialState,
    action: any
) {
    switch (action.type) {
        case SET_USER_ACTIONS_CHECKLIST:
            return { ...state, ...action.userActionsCheckList };
        default:
            return state;
    }
}

export default userActionsCheckListReducer;
