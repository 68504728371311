import {
    EventName,
    EventPoint,
    GpsCalibrationPoints,
    GpsPoint,
    HalfEvent,
    IncidentEvent,
    MatchEventPlottingData,
    MatchPhone,
} from "refsix-js-models";
import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import pitchImage from "../../assets/images/pitch.png";
import { useDimensions } from "../hooks";
import { useTranslation } from "react-i18next";
import {
    IonItem,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonText,
} from "@ionic/react";
import { hasFeatureAccess } from "../../services/subscriptionService";
import { matchEventsPosition } from "refsix-core";

interface CardMapInterface {
    playingSegments: HalfEvent[];
    geoPoints: GpsPoint[];
    calibrationPoints: GpsCalibrationPoints;
    match: MatchPhone;
    testId?: string;
    width?: number;
    height?: number;
}

const DEFAULT_WIDTH = 300;

const CardMapComponent: FC<CardMapInterface> = ({
    playingSegments,
    geoPoints,
    calibrationPoints,
    match,
    testId,
}) => {
    const { t } = useTranslation();
    const canvasRef = useRef<HTMLDivElement>(null);
    const [containerDiv, dims] = useDimensions();
    const segmentAll = "All";
    const [selectedSegment, setSelectedSegment] = useState<string>(segmentAll);
    const [data, setData] = useState<MatchEventPlottingData | null>(null);
    const cardMapAccess = hasFeatureAccess("cardmap");

    useEffect(() => {
        const segmentsPlottedData = matchEventsPosition(
            geoPoints,
            calibrationPoints,
            playingSegments,
            match.matchEvents,
            dims?.width || DEFAULT_WIDTH,
            null
        );
        if (segmentsPlottedData) {
            setData(segmentsPlottedData);
        }
    }, [dims, geoPoints, calibrationPoints, playingSegments, match]);

    const drawCard = (e: EventPoint, idx: number) => {
        if (e.event.eventName === EventName.incident && canvasRef.current) {
            const point = e.point;
            const event = e.event as IncidentEvent;
            const canvas = canvasRef.current;
            const WIDTH = 15,
                HEIGHT = 20,
                DIV_HEIGHT = canvas.clientHeight - HEIGHT;
            const initialTop = point.y - HEIGHT / 2;
            const top =
                initialTop > DIV_HEIGHT
                    ? DIV_HEIGHT
                    : initialTop < 0
                    ? 0
                    : initialTop;
            const left = point.x - WIDTH / 2;

            const divStyles: CSSProperties = {
                height: HEIGHT,
                top: top,
                left: left,
                position: "absolute",
            };
            return (
                <div key={idx} style={divStyles}>
                    <div className={"card " + event.card}>
                        <span className={"player-no "}>
                            {event.player.number}
                        </span>
                    </div>
                    <span className="minute">{event.minuteOfPlay}'</span>
                </div>
            );
        }
        return <></>;
    };
    const drawCards = () => {
        if (data) {
            return data.data
                .filter(
                    (eventContainer) =>
                        selectedSegment === segmentAll ||
                        selectedSegment === eventContainer.event.team.shortName
                )
                .map(drawCard);
        }
        return <></>;
    };

    const filterByColor = (color: string) => (event: IncidentEvent) =>
        event.card == color;

    const _numCardColor = (colorFilter: (event: IncidentEvent) => boolean) => {
        let count = 0;
        if (data) {
            count +=
                data.data?.filter((event) => colorFilter(event.event)).length ||
                0;
            count += data.extraData?.filter(colorFilter).length || 0;
        }
        return count;
    };
    const numYellow = _numCardColor(filterByColor("yellow"));
    const numRed = _numCardColor(filterByColor("red"));

    const extraData = () =>
        data?.extraData &&
        data?.extraData.length > 0 && (
            <>
                <IonItem className="no-gps-cards">
                    <IonText>{t("results.cardmap.title")}</IonText>
                </IonItem>
                <IonItem className="no-gps-cards">
                    {data?.extraData?.map((event, index) => (
                        <div key={index}>
                            <div className={"card " + event.card}>
                                <span className={"player-no "}>
                                    {event.player.number}
                                </span>
                            </div>
                            <span className="minute">
                                {event.minuteOfPlay}'
                            </span>
                        </div>
                    ))}
                </IonItem>
            </>
        );

    const teamSegment = () => {
        if (!match) return;
        const teams = [
            t("general.all"),
            match.homeTeamShort,
            match.awayTeamShort,
        ];
        return (
            <IonSegment
                onIonChange={(e) => {
                    console.log(e.detail.value);
                    setSelectedSegment(
                        (e.detail.value as string) || segmentAll
                    );
                }}
                value={selectedSegment}
            >
                {teams.map((team, idx) => (
                    <IonSegmentButton value={team} key={idx}>
                        <IonLabel>{team}</IonLabel>
                    </IonSegmentButton>
                ))}
            </IonSegment>
        );
    };

    return (
        <div className="card-map" data-testid={testId}>
            {cardMapAccess && (
                <>
                    <IonItem>
                        <IonText slot="start">{t("general.yellow")}</IonText>
                        <IonText slot="end">{numYellow}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonText slot="start">{t("general.red")}</IonText>
                        <IonText slot="end">{numRed}</IonText>
                    </IonItem>
                </>
            )}
            {teamSegment()}
            <div ref={containerDiv} className="map-container">
                <div className="responsive svg-pitch">
                    <img src={pitchImage} alt="Pitch image" />
                </div>
                <div
                    ref={canvasRef}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: dims?.width || DEFAULT_WIDTH,
                        height: dims?.height || DEFAULT_WIDTH,
                    }}
                >
                    {drawCards()}
                </div>
            </div>
            {extraData()}
        </div>
    );
};

export default CardMapComponent;
