import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { trackEvent } from "../../services/analytics/analyticsService";
import "./UpgradeBanner.css";
import { useIonRouter } from "@ionic/react";
import { routes } from "../../route/constants";
import { useIsBasic, useIsPro } from "../hooks";
import { getLanguageCode } from "../../utils/localeUtils";

export const UpgradeBannerText: FC<any> = () => {
    const { t } = useTranslation();
    const router = useIonRouter();

    const isPro = useIsPro();
    const isBasic = useIsBasic();

    const bannerTexts = [
        {
            name: "heatmap",
            translatedText: t("upgrade.proFeatureMarketing.heatmap"),
        },
        {
            name: "timers",
            translatedText: t("upgrade.proFeatureMarketing.timers"),
        },
        {
            name: "fitness",
            translatedText: t("upgrade.proFeatureMarketing.fitness"),
        },
        {
            name: "trends",
            translatedText: t("upgrade.proFeatureMarketing.trends"),
        },
        {
            name: "videoReplay",
            translatedText: t("upgrade.proFeatureMarketing.videoReplay"),
        },
    ];

    const getRandomInt = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    };

    const [selectedText, setSelectedText] = useState<any>(
        bannerTexts[getRandomInt(0, bannerTexts.length)]
    );

    const bannerClicked = () => {
        const trackingProps = {
            selectedBannerText: selectedText.name,
            translatedText: selectedText.translatedText,
            pageUrl: window.location.pathname,
            language: getLanguageCode(),
            bannerType: "text",
        };

        trackEvent("UpgradeBanner", trackingProps);
        router.push(`${routes.upgrade}/pro`);
    };

    if (isPro || isBasic) return null;

    return (
        <div className="upgrade-banner-text">
            <button onClick={bannerClicked}>
                <span>{selectedText.translatedText}</span>
                <span className="fake-button">{t("menu.upgrade")}</span>
            </button>
        </div>
    );
};
