import { FC, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCol,
    IonItem,
    IonLoading,
    IonModal,
    IonRow,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { setSession } from "../services/watch/watchPairingService";
import { watchModel } from "../models/watchModel";
import moment from "moment";
import { AlertComponent } from "../components";
import { addWatchDetails } from "../services/watch/watchHelpers";
import "./pairWatchModal.css";
import { AuthInputComponent } from "../components/AuthInputComponent";
import AppleImage from "../assets/images/watches/watchOS.png";
import WearOsImage from "../assets/images/watches/wearOS.png";
import GarminImage from "../assets/images/watches/garmin.png";
import SamsungImage from "../assets/images/watches/tizen.png";

interface PairWatchModalProps {
    openPairModal: boolean;
    onDismiss: () => void;
}

const PairWatchModal: FC<PairWatchModalProps> = ({
    openPairModal,
    onDismiss,
}) => {
    const { t } = useTranslation();
    const [authCode, setAuthCode] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const route = useIonRouter();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [codeMismatch, setCodeMismatch] = useState<boolean>(false);
    const [disablePairButton, setDisablePairButton] = useState<boolean>(true);

    const onChange = (value: string) => {
        setAuthCode(value);
        setCodeMismatch(false);
    };

    const handlePairWatch = async () => {
        if (!authCode) return;
        setLoading(true);
        let watchDetails: watchModel = { watchOS: "", model: "" };

        try {
            const setSessionResponse: any = await setSession(authCode);
            if (setSessionResponse) {
                watchDetails.watchOS = setSessionResponse.data.platform;
                watchDetails.model = setSessionResponse.data.watchModel;
                watchDetails.platform = setSessionResponse.data.platform;
                watchDetails.datePaired = moment().valueOf();
                watchDetails.sessionId = setSessionResponse.data.sessionId;
                await addWatchDetails(watchDetails);
                onDismiss();
                route.goBack();
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log("error trying to pair watch", e);
            setShowAlert(true);
            setCodeMismatch(true);
        }
    };

    return (
        <IonModal
            isOpen={openPairModal}
            onDidDismiss={() => {
                onDismiss();
                setAuthCode("");
                setCodeMismatch(false);
                setDisablePairButton(true);
            }}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            showBackdrop
            data-testid="pair-watch-modal"
        >
            <IonCard className="ion-card-transparent">
                <IonLoading isOpen={loading} />
                <IonItem className="teams-segments-container">
                    <IonRow>
                        <IonText>
                            {t("settings.WatchSettings.pairWatchHelp")}
                        </IonText>
                    </IonRow>
                </IonItem>
                <AuthInputComponent
                    authCode={authCode}
                    onChange={onChange}
                    disablePairButton={(enable) => setDisablePairButton(enable)}
                    codeMismatch={codeMismatch}
                />
                <div>
                    <IonButton
                        data-testid="pair-watch-button"
                        expand="block"
                        onClick={() => handlePairWatch()}
                        disabled={disablePairButton}
                    >
                        <IonText>
                            {t("settings.WatchSettings.pairWatch")}
                        </IonText>
                    </IonButton>
                </div>

                <IonCard>
                    <IonItem lines="none" color="transparent">
                        <div
                            style={{
                                display: "grid",
                                justifyContent: "center",
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            <IonCol>
                                <img src={GarminImage} height={15} />
                            </IonCol>
                            <IonCol>
                                <img src={SamsungImage} height={15} />
                            </IonCol>
                            <IonCol>
                                <img src={WearOsImage} height={15} />
                            </IonCol>
                            <IonCol>
                                <img src={AppleImage} height={15} />{" "}
                                {t("settings.WatchSettings.appleFamilyMember")}
                            </IonCol>
                        </div>
                    </IonItem>
                </IonCard>

                <AlertComponent
                    testId={"pair-watch-alert"}
                    onDonePressed={() => setShowAlert(false)}
                    showAlert={showAlert}
                    description={t("settings.WatchSettings.codeMismatch")}
                    buttonDone={t("help.OnboardingVideo.controls.ok")}
                />
            </IonCard>
        </IonModal>
    );
};

export default PairWatchModal;
