import {
    IonIcon,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimerConfigOptions } from "../pages/tabs/more/timerOptions";
import "./TimerOptionsComponent.css";
import { TimerType, WatchFace } from "refsix-js-models";
import { caretDown } from "ionicons/icons";
import {
    layoutToSelections,
    uniqueTimers,
    updateConfigTimers,
} from "../utils/timerSettingsHelper";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { trackEvent } from "../services/analytics/analyticsService";
import { useHasFeatureAccess } from "./hooks";

interface TimerOptionsComponentInterface {
    validationListener: (isValid: boolean) => void;
}

export const TimerOptionsComponent: FC<TimerOptionsComponentInterface> = ({
    validationListener,
}) => {
    const settings = useSelector((root: RootState) => root.settings);
    const [isValidLayout, setIsValidLayout] = useState<boolean>(true);
    const hasTimerAccess = useHasFeatureAccess("timers");
    const [currentTimerConfig, setCurrentTimerConfig] =
        useState<TimerConfigOptions>(
            layoutToSelections(settings.settings.layout) as TimerConfigOptions
        );
    const [watchFace, setWatchFace] = useState<WatchFace>(
        hasTimerAccess ? settings.settings.watchFace : WatchFace.classic
    );

    const handleTimersChange = (
        updatedTimers: TimerConfigOptions,
        watchFace: WatchFace
    ) => {
        setCurrentTimerConfig(updatedTimers);
        const isValid = uniqueTimers(updatedTimers);
        validationListener(isValid);
        setIsValidLayout(isValid);
        trackEvent("timerOptionsChanged", {});
        if (isValid) {
            updateConfigTimers(updatedTimers, watchFace, settings).catch(
                (err) => {
                    console.error(err);
                }
            );
        }
    };

    useEffect(() => {
        const timerConfig = layoutToSelections(settings.settings.layout);
        if (timerConfig) {
            if (hasTimerAccess) {
                setCurrentTimerConfig(timerConfig as TimerConfigOptions);
            } else {
                const updatedTimers = {
                    timer1: TimerType.elapsed,
                    timer2: TimerType.additional,
                    timer3: undefined,
                };

                setCurrentTimerConfig(updatedTimers);
                setWatchFace(WatchFace.classic);
                setTimerCount(2);
            }
        }
    }, [settings.settings, hasTimerAccess]);

    const { t } = useTranslation();
    const timers = [
        {
            name: t("settings.TimerSettings.elapsed"),
            description: t("settings.TimerSettings.elapsedDescription"),
            value: "elapsed",
        },
        {
            name: t("settings.TimerSettings.countUp"),
            description: t("settings.TimerSettings.countUpDescription"),
            value: "countup",
        },
        {
            name: t("settings.TimerSettings.countDown"),
            description: t("settings.TimerSettings.countDownDescription"),
            value: "countdown",
        },
        {
            name: t("settings.TimerSettings.additional"),
            description: t("settings.TimerSettings.additionalDescription"),
            value: "additional",
        },
    ];

    const [timerCount, setTimerCount] = useState<2 | 3>(
        currentTimerConfig.timer3 && hasTimerAccess ? 3 : 2
    );

    // const [watchFace, setWatchFace] = useState<WatchFace>(WatchFace.bigTimers);
    const [selectedTimer, setSelectedTimer] = useState<1 | 2 | 3 | null>(null);
    const [selectedTimerValue, setSelectedTimerValue] = useState<
        TimerType | undefined
    >(undefined);
    const [duplicateValue, setDuplicateValue] = useState<TimerType | null>(
        null
    );
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (!uniqueTimers(currentTimerConfig)) {
            const dupe = _.filter(
                Object.values(currentTimerConfig),
                (val, i, iteratee) => _.includes(iteratee, val, i + 1)
            );
            if (dupe.length > 0) {
                setDuplicateValue(dupe[0]);
            }
        } else {
            setDuplicateValue(null);
        }
    }, [currentTimerConfig]);

    const renderCurrentTimer = (timerType?: TimerType) => {
        return (
            <>
                <span>
                    {timerType === TimerType.countdown ? "45:00" : "00:00"}
                </span>
                <span
                    className={
                        timerType === duplicateValue
                            ? "duplicate-timer timer-label"
                            : "timer-label"
                    }
                >
                    {timerType
                        ? timers.find((timer) => timer.value === timerType)
                              ?.name
                        : t("settings.TimerSettings.none")}
                    <IonIcon icon={caretDown}></IonIcon>
                </span>
            </>
        );
    };

    const onTimerTap = (index: 1 | 2 | 3) => {
        return () => {
            setSelectedTimer(index);
            setShowModal(true);
            setSelectedTimerValue(currentTimerConfig[`timer${index}`]);
        };
    };

    return (
        <div className="timer-options-container">
            <p>{t("help.onBoarding.chooseWatchFace")}</p>
            <IonSegment
                mode="ios"
                value={watchFace}
                onIonChange={(e) => {
                    const value = e?.detail?.value as string;
                    setWatchFace(value as WatchFace);
                    handleTimersChange(currentTimerConfig, value as WatchFace);
                }}
                style={{ marginBottom: 20 }}
                className="segment"
            >
                <IonSegmentButton
                    value={WatchFace.classic}
                    className="segment-button"
                    data-testid="classic-face-select"
                >
                    {t("settings.TimerSettings.classicFace")}
                </IonSegmentButton>
                <IonSegmentButton
                    value={WatchFace.bigTimers}
                    className="segment-button"
                    data-testid="big-timers-face-select"
                >
                    {t("settings.TimerSettings.bigTimersFace")}
                </IonSegmentButton>
            </IonSegment>
            <p>{t("help.onBoarding.chooseNumberOfTimers")}</p>
            <IonSegment
                mode="ios"
                value={timerCount}
                onIonChange={(e) => {
                    const value = e?.detail?.value as string;
                    if (!value) return;
                    const noTimers = parseInt(value) as 2 | 3;
                    setTimerCount(noTimers);
                    if (noTimers === 2) {
                        handleTimersChange(
                            {
                                ...currentTimerConfig,
                                timer3: undefined,
                            },
                            watchFace
                        );
                    }
                }}
                style={{ marginBottom: 20 }}
                // className="fixture-segments"
            >
                <IonSegmentButton
                    value={2}
                    className="segment-button"
                    data-testid="two-timers"
                >
                    2
                </IonSegmentButton>
                <IonSegmentButton
                    value={3}
                    className="segment-button"
                    data-testid="three-timers"
                >
                    3
                </IonSegmentButton>
            </IonSegment>
            <p>{t("help.onBoarding.chooseTimers")}</p>
            <div className="watch-frame">
                <section id="mainScreen" className="main-screen-section">
                    <div
                        className={
                            watchFace === WatchFace.bigTimers
                                ? "main-screen big-timers"
                                : "main-screen"
                        }
                    >
                        {/*<div className="page-header">*/}
                        {/*    <p className="half-name">First Half</p>{" "}*/}
                        {/*    <p className="time">21:22</p>*/}
                        {/*</div>*/}
                        <div className="teams-group">
                            <div
                                className="team"
                                style={{
                                    borderBottom:
                                        "0.25rem solid rgb(43, 236, 255)",
                                }}
                            >
                                <p className="team-name">
                                    {t("fixture.FixtureNew.homeShort")}
                                </p>{" "}
                                <p className="team-score">0</p> {/**/}
                            </div>{" "}
                            <div
                                className="team"
                                style={{
                                    borderBottom:
                                        "0.25rem solid rgb(247, 19, 17)",
                                }}
                            >
                                <p className="team-name">
                                    {t("fixture.FixtureNew.awayShort")}
                                </p>{" "}
                                <p className="team-score">0</p> {/**/}
                            </div>
                        </div>
                        <div className="timers-group">
                            <div
                                role="button"
                                data-testid="timer-1-select"
                                className="main-timer timer"
                                onClick={onTimerTap(1)}
                            >
                                {renderCurrentTimer(currentTimerConfig.timer1)}
                            </div>
                            <div
                                className="timer-left-right-group"
                                hidden={timerCount === 2}
                            >
                                <div
                                    id="left"
                                    className="timer"
                                    data-testid="timer-2-select"
                                    onClick={onTimerTap(2)}
                                >
                                    {renderCurrentTimer(
                                        currentTimerConfig.timer2
                                    )}
                                </div>{" "}
                                <div
                                    id="right"
                                    className="timer"
                                    data-testid="timer-3-select"
                                    onClick={onTimerTap(3)}
                                >
                                    {renderCurrentTimer(
                                        currentTimerConfig.timer3
                                    )}
                                </div>
                            </div>
                            <div
                                hidden={timerCount === 3}
                                className="timer-secondary-group"
                            >
                                <div
                                    id="secondary"
                                    className="timer"
                                    data-testid="timer-secondary-select"
                                    onClick={onTimerTap(2)}
                                >
                                    {renderCurrentTimer(
                                        currentTimerConfig.timer2
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            id="progressBar"
                            className="progress-bar"
                            style={{ display: "none" }}
                        >
                            <div
                                className="inner-bar"
                                style={{ width: "0.37%" }}
                            />
                        </div>
                    </div>
                </section>
            </div>
            <IonModal
                isOpen={showModal}
                initialBreakpoint={1}
                breakpoints={[0, 1]}
                onDidDismiss={() => {
                    setShowModal(false);
                    setSelectedTimer(null);
                }}
                style={{ "--height": "auto" }}
                data-testid="select-timer-modal"
            >
                <IonRadioGroup
                    style={{ paddingBottom: "env(safe-area-inset-bottom)" }}
                    value={selectedTimerValue}
                    onIonChange={(e) => {
                        if (selectedTimer !== null) {
                            handleTimersChange(
                                {
                                    ...currentTimerConfig,
                                    [`timer${selectedTimer}`]: e.detail.value,
                                },
                                watchFace
                            );
                        }
                        setSelectedTimer(null);
                    }}
                    onClick={(e) => {
                        // dismiss here instead of onIonChange to allow selecting existing timer
                        setShowModal(false);
                    }}
                >
                    {timers.map((timer, index) => {
                        return (
                            <IonRadio
                                mode={"ios"}
                                key={index}
                                data-testid={`timer-${timer.value}`}
                                value={timer.value}
                                labelPlacement="start"
                                onClick={(e) => {
                                    console.log("radio click", e);
                                }}
                            >
                                <div className="timer-radio-label">
                                    <p>
                                        <strong>{timer.name}</strong>
                                    </p>
                                    <p style={{ whiteSpace: "normal" }}>
                                        {timer.description}
                                    </p>
                                </div>
                            </IonRadio>
                        );
                    })}
                </IonRadioGroup>
            </IonModal>
        </div>
    );
};
