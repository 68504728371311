import { FC, useEffect, useState } from "react";
import { Fixture } from "./overview";
import { NextMatchInComponent } from "../../../components";
import "./match.css";
import MatchLogComponent from "../../../components/MatchLogComponent";
import {
    EventName,
    GoalEvent,
    HalfEvent,
    MatchEvent,
    MatchPhone,
} from "refsix-js-models";
import { omit } from "lodash/fp";
import { IonButton, useIonRouter } from "@ionic/react";
import { routes } from "../../../route/constants";
import {
    computeHalfInfo,
    IndexedMap,
    recalculateStats,
    updateMatch,
} from "../../../services/matchService";
import { processEvents } from "refsix-core";
import { ComputedHalfInfo } from "../../../models/ComputedHalfInfo";
import { DisplayEvent } from "../../../models/DisplayEvent";
import { DisplayEventType } from "../../../models/DisplayEventType";
import * as Sentry from "@sentry/react";
import { store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import {
    getWatchMatchId,
    isMatchStarted,
} from "../../../services/phoneMatchWatchService";
import { useProfile } from "../../../components/hooks";
import { calculateMinuteAndAdditional } from "../../../services/eventService";
import ScrollableContentWithAds from "../../../components/ScrollableContentsWithAd";

const Match: FC<Fixture> = ({ match }) => {
    const [displayEvents, setDisplayEvents] = useState<DisplayEvent[]>([]);
    const { t } = useTranslation();
    const profile = useProfile();
    const scoreCount: { home: number; away: number } = {
        home: 0,
        away: 0,
    };

    useEffect(() => {
        if (match.matchEvents) {
            let eventsArray = match.matchEvents
                ? Object.values(match.matchEvents)
                : [];

            if (!match.eventsProcessed) {
                const processedMatch: MatchPhone = processEvents(match);
                if (processedMatch.matchEvents) {
                    eventsArray = Object.values(processedMatch.matchEvents);
                }
            }

            // let playingPeriods = getPlayingSegments(match);

            const periodsAndIntervals = eventsArray.filter((event) => {
                return (
                    event.eventName === EventName.half ||
                    event.eventName === EventName.penalties
                );
            }) as HalfEvent[];

            let playingPeriodInfo: IndexedMap =
                computeHalfInfo(periodsAndIntervals);

            setDisplayEvents(
                generateDisplayEvents(
                    eventsArray,
                    playingPeriodInfo,
                    match.matchAbandoned === true
                )
            );
        }
    }, [match.matchEvents]);

    const route = useIonRouter();
    // fix for deleting the match
    if (!match || !displayEvents) {
        return <div />;
    }

    function generateMinuteString(
        event: MatchEvent,
        currentHalfInfo: ComputedHalfInfo
    ) {
        const minuteAndAdditionalInfo = calculateMinuteAndAdditional(
            event,
            currentHalfInfo
        );

        return minuteAndAdditionalInfo.additionMinute
            ? `${minuteAndAdditionalInfo.minute}' + ${minuteAndAdditionalInfo.additionMinute}'`
            : `${minuteAndAdditionalInfo.minute}'`;
    }

    function matchAbandoned(displayEvents: DisplayEvent[]): DisplayEvent[] {
        const periodEnds = displayEvents.filter(
            (e) => e.type === DisplayEventType.periodEnd
        );

        const periodToRemove = periodEnds[periodEnds.length - 1];
        // get the correct end minute from the periodEnd event
        const endMinute = periodToRemove.displayMinute;

        const abandonEvents = displayEvents.filter(
            (e) => e.event.eventName === EventName.abandoned
        );

        if (!abandonEvents.length) {
            const message = `Match has matchAbandoned property but no abandoned event ${
                store.getState().auth?.session?.user_id
            }/${match._id}`;
            console.log(message);
            Sentry.captureMessage(message);
            return displayEvents;
        }

        // set correct display minute from half end event
        const abandonEvent = abandonEvents[0];
        abandonEvent.displayMinute = endMinute;

        // Remove the half end display event
        const indexToRemove = displayEvents.indexOf(periodToRemove);
        displayEvents.splice(indexToRemove, 1);
        return displayEvents;
    }

    const getHalfTimeScoreForPeriod = (
        period: number
    ): { home: number; away: number } => {
        if (match.matchEvents) {
            Object.values(match.matchEvents).forEach((event) => {
                if (event.eventName === EventName.goal) {
                    const goalEvent = event as GoalEvent;

                    if (goalEvent.half === period) {
                        if (
                            goalEvent.team.side === "home" ||
                            goalEvent.team.side === "away"
                        ) {
                            scoreCount[goalEvent.team.side]++;
                        }
                    }
                }
            });
        }

        return { ...scoreCount };
    };

    function generateDisplayEvents(
        events: MatchEvent[],
        playingPeriodInfo: IndexedMap,
        abandoned: boolean
    ): DisplayEvent[] {
        if (!playingPeriodInfo) {
            console.log("No playingPeriodInfo");
            return [];
        }

        let output: DisplayEvent[] = [];
        let sortedEvents = events.sort((a, b) => {
            return a.timestamp - b.timestamp;
        });

        let currentPeriod: HalfEvent;
        let currentPeriodInfo: ComputedHalfInfo;

        sortedEvents.forEach((matchEvent) => {
            if (
                matchEvent.eventName === EventName.half ||
                matchEvent.eventName === EventName.penalties
            ) {
                currentPeriod = matchEvent as HalfEvent;
                currentPeriodInfo = playingPeriodInfo[currentPeriod.index];
                let endTs =
                    currentPeriod.endTime ||
                    currentPeriod.timestamp + currentPeriod.length;
                // period start
                output.push({
                    event: matchEvent,
                    displayMinute: generateMinuteString(
                        matchEvent,
                        currentPeriodInfo
                    ),
                    type: DisplayEventType.periodStart,
                    period: currentPeriod,
                    sortingTs: matchEvent.timestamp,
                });

                // period end
                const endDisplayMinute =
                    currentPeriodInfo.additionalTime > 0
                        ? `${currentPeriodInfo.actualEndMinute}'+${currentPeriodInfo.additionalTime}'`
                        : `${currentPeriodInfo.actualEndMinute}'`;

                output.push({
                    event: matchEvent,
                    displayMinute: endDisplayMinute,
                    type: DisplayEventType.periodEnd,
                    period: currentPeriod,
                    sortingTs: endTs,
                    score: getHalfTimeScoreForPeriod(currentPeriod.index),
                });
            } else if (currentPeriodInfo) {
                output.push({
                    event: matchEvent,
                    displayMinute: matchEvent.additionalTime
                        ? `${matchEvent.minuteOfPlay}'+${matchEvent.additionalTime}'`
                        : `${matchEvent.minuteOfPlay}'`,
                    type: DisplayEventType.other,
                    period: currentPeriod,
                    sortingTs: matchEvent.timestamp,
                });
            } else {
                console.log(
                    `Event happened before the match kicked off so not displaying it ${matchEvent.timestamp}, ${matchEvent.eventName}`
                );
            }
        });
        output.sort((a, b) => {
            return a.sortingTs - b.sortingTs;
        });
        if (abandoned) {
            // if the match is abandoned we don't want to show the last half and the abandoned events
            return matchAbandoned(output);
        }
        return output;
    }

    if (!(match.matchFinished || match.matchInProgress)) {
        return (
            <ScrollableContentWithAds>
                <div style={{ textAlign: "center" }}>
                    <NextMatchInComponent match={match} />

                    <IonButton
                        style={{ marginTop: "10px" }}
                        onClick={() => {
                            route.push(
                                `${routes.startWatchPhone}/${match._id}`,
                                "none",
                                "replace"
                            );
                        }}
                        data-testid="resumeOnPhone"
                    >
                        {getWatchMatchId() === match._id && isMatchStarted()
                            ? t("fixture.Fixture.resumeOnPhone")
                            : t("fixture.Fixture.startOnPhone")}
                    </IonButton>
                </div>
            </ScrollableContentWithAds>
        );
    }

    return (
        <ScrollableContentWithAds>
            {displayEvents.length > 0 &&
                displayEvents
                    .sort((a, b) => {
                        return b.sortingTs - a.sortingTs;
                    })
                    .filter((displayEvent) => {
                        if (displayEvent.event.eventName === EventName.half) {
                            const halfEvent = displayEvent.event as HalfEvent;
                            return halfEvent.playing;
                        }

                        return true;
                    })
                    .map((displayEvent, index) => {
                        return (
                            <MatchLogComponent
                                key={index}
                                displayEvent={displayEvent}
                                timezone={match.timezone}
                                matchInProgress={match.matchInProgress}
                                edit={() => {
                                    route.push(
                                        routes.refsixResultsAddEvent +
                                            `/${match._id}/${displayEvent.event.timestamp}`
                                    );
                                }}
                                delete={async () => {
                                    const newMatch = {
                                        ...match,
                                        matchEvents: omit(
                                            [displayEvent.event.timestamp],
                                            match.matchEvents
                                        ),
                                    };
                                    const savedMatch = await updateMatch(
                                        newMatch
                                    );
                                    if (savedMatch && profile) {
                                        await recalculateStats(
                                            savedMatch,
                                            profile
                                        );
                                    }
                                }}
                            />
                        );
                    })}
        </ScrollableContentWithAds>
    );
};

export default Match;
