import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonHeader,
    IonLoading,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    RefresherEventDetail,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./overview.css";
import { useParams } from "react-router";
import { IntegrationPartner, MatchPhone } from "refsix-js-models";
import Overview from "./overview";
import { useEffect, useRef, useState } from "react";
import Teams from "./teams";
import { useTranslation } from "react-i18next";
import Earnings from "./earnings";
import Match from "./match";
import Stats from "./stats";
import "./optionsMenu.css";
import {
    allMatches,
    deleteMatch,
    duplicateMatch,
} from "../../../services/matchService";
import Wellbeing from "./wellbeing";
import "./fixturesSegments.css";
import {
    AlertComponent,
    HeaderComponent,
    ToastComponent,
} from "../../../components";
import Keywords from "./keywords";
import OptionsPopover from "./optionsPopover";
import MatchReportModal from "../../../modals/matchReportModal";
import { sendMatchReport } from "../../../services/integrationService";
import { routes } from "../../../route/constants";
import { mpUpdatePerson } from "../../../services/analytics/mixpanelService";
import { getFixtureResultCount } from "refsix-core";
import PouchDebugMatchRevs from "../../../components/debug/PouchDebugMatchRevs";
import ScrollableContent from "../../../components/ScrollableContents";
import {
    FitnessDataProvider,
    useFitnessDataContext,
    useUsername,
} from "../../../components/hooks";
import {
    trackEvent,
    trackPageView,
} from "../../../services/analytics/analyticsService";
import { useMatches } from "../../../components/hooks/match";
import { PlayerOfTheMatchWrapper } from "./playerOfTheMatch";
import { TeamBehaviorSegment } from "./teamBehavior";
import { hasFeatureAccess } from "../../../services/subscriptionService";
import { NotesWrapper } from "./notes";
import { CropMatchLength } from "./CropMatchLength";
import { MatchScore, ScoreType } from "../../../components/MatchScore";

enum Segment {
    overview = "overview",
    teams = "teams",
    match = "match-log",
    stats = "stats",
    earnings = "earnings",
    notes = "notes",
    tags = "tags",
    wellbeing = "wellbeing",
    playerOfTheMatch = "playerOfTheMatch",
    teamBehavior = "teamBehavior",
    revs = "revs",
}

const FixturesSegments: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    return (
        <FitnessDataProvider matchId={id || ""}>
            <FixtureSegment />
        </FitnessDataProvider>
    );
};

function FixtureSegment() {
    const route = useIonRouter();
    const { t } = useTranslation();
    const { page, id } = useParams<{ page: string; id: string }>();
    const segmentEl = useRef<HTMLIonSegmentElement>(null);
    const allUserMatches = useMatches();
    const playerOfTheMatchAccess = hasFeatureAccess("playerOfTheMatch");
    const teamBehaviorAccess = hasFeatureAccess("teamBehavior");
    const [showCropMatchModal, setShowCropMatchModal] =
        useState<boolean>(false);
    const match = useSelector((state: RootState) =>
        allMatches(state.matches.matches).find((match) => {
            if (match._id === id) {
                return match;
            }
        })
    ) as MatchPhone;
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastTitle, setToastTitle] = useState<string>("");
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [showPopover, setShowPopover] = useState<{
        open: boolean;
        event?: Event;
    }>({
        open: false,
        event: undefined,
    });
    const username = useUsername();
    const [displayAlert] = useIonAlert();
    const { reload } = useFitnessDataContext();

    const [openMatchReportModal, setOpenMatchReportModal] =
        useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const developerToolsEnabled = useSelector(
        (state: RootState) => state.developerTools.enabled
    );

    const isValidSegment = (value: string): value is Segment => {
        return Object.values(Segment).includes(value as Segment);
    };

    const [selectedSegment, setSelectedSegment] = useState<Segment>(
        isValidSegment(page) ? page : Segment.overview
    );

    const handleSegmentChange = (e: CustomEvent) => {
        const newSelectedSegment = e.detail.value as Segment;
        setSelectedSegment(newSelectedSegment);
        // update the address bar without redoing the react history and page animations.
        window.history.replaceState(
            null,
            "",
            `${routes.match}/${id}/${newSelectedSegment}`
        );
    };

    useEffect(() => {
        if (window.location) {
            trackPageView(window.location.pathname);
        }
    }, [selectedSegment]);

    useEffect(() => {
        console.log("page , segment", page, selectedSegment);
        // Check if 'page' is a valid segment
        if (page === selectedSegment) {
            console.log("page === selectedSegment");
            return;
        } else if (isValidSegment(page)) {
            console.log("isValidSegment(page)");
            setSelectedSegment(page);
        } else {
            console.log("else");
            setSelectedSegment(Segment.overview);
            route.push(
                `${routes.match}/${id}/${Segment.overview}`,
                "none",
                "replace"
            );
        }
    }, [page]);

    useEffect(() => {
        if (segmentEl && segmentEl.current && isValidSegment(page)) {
            setTimeout(() => {
                // get the child element of the segment button with matching value
                if (!segmentEl || !segmentEl.current) return;
                const segmentButton = segmentEl.current.querySelector(
                    `[value=${page}]`
                );
                // scroll segment into center of the view
                segmentButton?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "center",
                });
            }, 500);
        }
    }, [page, segmentEl, segmentEl.current]);

    if (!match) {
        return (
            <IonPage>
                <IonHeader>
                    <HeaderComponent
                        showBackButton={true}
                        title={t("general.matchDetails")}
                        backButtonTestId="match-not-exist-back"
                        defaultHref={routes.refsixFixtures}
                    />
                </IonHeader>
                <IonContent>
                    <IonCard>
                        <IonCardContent>
                            <div data-testid="matchNotExist">
                                <IonText style={{ color: "white" }}>
                                    {t("review.error")}
                                </IonText>
                            </div>
                        </IonCardContent>
                    </IonCard>
                </IonContent>
            </IonPage>
        );
    }

    async function deleteFixture() {
        setShowPopover({ open: false, event: undefined });
        try {
            await deleteMatch(match);
            if (allUserMatches) {
                mpUpdatePerson(getFixtureResultCount(allUserMatches));
            }
            handleBack();
        } catch (err) {
            setToastTitle(t("fixture.FixtureSegments.deleteMatchError"));
            setShowToast(true);
        }
    }

    const handleDuplicateMatch = async () => {
        await duplicateMatch(match);
        setShowPopover({ open: false });
        route.goBack();
    };

    // Checking if a stackSports match has been submitted or not
    const matchReportSubmitted = (): boolean => {
        return !!(
            match.integration &&
            match.integration.partner === IntegrationPartner.stack &&
            match.integration.scoreId
        );
    };

    const handleSubmitMatchReport = () => {
        if (matchReportSubmitted()) {
            return;
        }

        setOpenMatchReportModal(true);
    };

    const sendReport = async () => {
        if (!match._id || !username) return;

        setLoading(true);

        try {
            const sendMatchReportResponse = await sendMatchReport(
                match._id,
                username
            );

            if (sendMatchReportResponse) {
                const score = sendMatchReportResponse.data as any;

                if (score && score.data && score.data.id) {
                    setOpenMatchReportModal(false);
                    await displayAlert({
                        header: t("integrations.stackSports.alreadySubmitted"),
                        buttons: [
                            {
                                id: "confirm",
                                text: t("help.OnboardingVideo.controls.ok"),
                            },
                        ],
                    });
                }
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            await displayAlert({
                header: t("fixture.FixtureShare.error.title"),
                message: t("general.errorTryAgain"),
                buttons: [
                    {
                        id: "confirm",
                        text: t("help.OnboardingVideo.controls.ok"),
                    },
                ],
            });
            console.log("error trying to send match report", e);
        }
    };

    const refreshStatsForMatch = async (
        event?: CustomEvent<RefresherEventDetail>
    ) => {
        trackEvent("RefreshStatsPullDown", {
            matchId: match._id,
            hasTracking: match.hasTracking,
            hasHeartRate: match.hasHeartRate,
            gpsProcessed: match.stats?.gpsProcessed,
            heartRateProcessed: match.stats?.heartRateProcessed,
            statsVersion: match.stats?.version,
        });
        reload();
        event && event.detail.complete();
    };

    const handleBack = () => {
        if (match.matchFinished) {
            route.push(routes.refsixResults, "back");
        } else {
            route.push(routes.refsixFixtures, "back");
        }
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton={true}
                title={t("general.matchDetails")}
                showPopOverOptions={true}
                setShowPopover={setShowPopover}
            />

            <OptionsPopover
                setShowPopover={setShowPopover}
                showPopover={showPopover}
                match={match}
                handleDuplicateMatch={handleDuplicateMatch}
                setShowAlert={(show) => setShowAlert(show)}
                handleCropMatchClicked={() => {
                    setShowPopover({ open: false });
                    setShowCropMatchModal(true);
                }}
            />

            <IonContent scrollY={false}>
                <IonRefresher
                    slot="fixed"
                    onIonRefresh={(e) => refreshStatsForMatch(e)}
                >
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonCard className="card-container-team-names match-overview-card-margin">
                    <IonRow className="card-row-container">
                        <IonCol>
                            <div className="team-color-wrapper">
                                <div
                                    className="team-color-round"
                                    style={{
                                        backgroundColor: match?.homeColor,
                                    }}
                                >
                                    <IonText className="text-name-short">
                                        {match?.homeTeamShort}
                                    </IonText>
                                </div>
                            </div>
                            <div className="name-long-wrapper">
                                <IonText className="text-name-long">
                                    {match?.homeTeam}
                                </IonText>
                            </div>
                        </IonCol>

                        {match.matchFinished || match.matchInProgress ? (
                            <div>
                                {match.stats?.playedET[0] !== 0 &&
                                    match.stats?.playedPenalties[0] === 0 && (
                                        <div className="penalties-extra-time-container score-formatting">
                                            <IonText
                                                className="penalties-extra-time-text"
                                                data-testid="penalties-extraTime-text"
                                            >
                                                {t(
                                                    "fixture.FixtureSummary.atExtraTime"
                                                )}
                                            </IonText>
                                        </div>
                                    )}
                                {match.stats?.playedPenalties[0] === 1 && (
                                    <div className="penalties-extra-time-container score-formatting">
                                        <IonText
                                            className="penalties-extra-time-text"
                                            data-testid="penalties-extraTime-text"
                                        >
                                            {t(
                                                "fixture.FixtureSummary.atPenalties"
                                            )}
                                        </IonText>
                                    </div>
                                )}

                                {match.matchInProgress && !match.matchFinished && (
                                    <div
                                        data-testid="match-in-progress-indicator"
                                        className="penalties-extra-time-container score-formatting"
                                    >
                                        <IonText
                                            className="penalties-extra-time-text"
                                            data-testid="penalties-extraTime-text"
                                            style={{
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            {t(
                                                "fixture.FixtureSummary.matchLive"
                                            )}
                                        </IonText>
                                    </div>
                                )}

                                {match.matchAbandoned && (
                                    <div className="penalties-extra-time-container score-formatting">
                                        <IonText
                                            className="penalties-extra-time-text"
                                            data-testid="penalties-extraTime-text"
                                        >
                                            {t(
                                                "model.WatchFixture.abandonedDisplayName"
                                            )}
                                        </IonText>
                                    </div>
                                )}

                                <IonRow className="score-formatting">
                                    <IonText
                                        className="goals"
                                        data-testid="goalsHomeTotal"
                                    >
                                        <MatchScore
                                            match={match}
                                            scoreType={ScoreType.home}
                                            color="white"
                                        />
                                    </IonText>
                                    <IonText style={{ color: "white" }}>
                                        -
                                    </IonText>
                                    <IonText
                                        className="goals"
                                        data-testid="goalsAwayTotal"
                                    >
                                        <MatchScore
                                            match={match}
                                            scoreType={ScoreType.away}
                                            color="white"
                                        />
                                    </IonText>
                                </IonRow>
                                {match.stats?.playedPenalties[0] === 1 && (
                                    <IonRow className="score-formatting penalty-score">
                                        <IonText>
                                            {match.stats?.penaltyShotHomeScored}
                                        </IonText>
                                        <IonText>-</IonText>
                                        <IonText>
                                            {match.stats?.penaltyShotAwayScored}
                                        </IonText>
                                    </IonRow>
                                )}
                            </div>
                        ) : (
                            <IonText>-</IonText>
                        )}
                        <IonCol>
                            <div className="team-color-wrapper">
                                <div
                                    className="team-color-round"
                                    style={{
                                        backgroundColor: match?.awayColor,
                                    }}
                                >
                                    <IonText className="text-name-short">
                                        {match?.awayTeamShort}
                                    </IonText>
                                </div>
                            </div>
                            <div className="name-long-wrapper">
                                <IonText className="text-name-long">
                                    {match?.awayTeam}
                                </IonText>
                            </div>
                        </IonCol>
                        {/* TODO: we should use features to hide this */}
                        {match.matchFinished &&
                            match.integration?.partner ===
                                IntegrationPartner.stack && (
                                <IonCol size="12" className="row-timers">
                                    <IonButton
                                        size="small"
                                        onClick={() =>
                                            handleSubmitMatchReport()
                                        }
                                    >
                                        {matchReportSubmitted()
                                            ? t(
                                                  "integrations.stackSports.alreadySubmitted"
                                              )
                                            : t(
                                                  "integrations.stackSports.submit"
                                              )}
                                    </IonButton>
                                </IonCol>
                            )}
                    </IonRow>
                </IonCard>

                <IonCard className="item-segments-container">
                    <IonSegment
                        ref={segmentEl}
                        scrollable={true}
                        value={selectedSegment}
                        className="fixture-segments segment"
                        mode="ios"
                        onIonChange={(e) => handleSegmentChange(e)}
                        onClick={(e) => {
                            // scroll segment into center of the view on click
                            if (e.target) {
                                // @ts-ignore
                                e.target.scrollIntoView({
                                    behavior: "smooth",
                                    block: "end",
                                    inline: "center",
                                });
                            }
                        }}
                    >
                        <IonSegmentButton
                            value={Segment.overview}
                            className="segment-button"
                        >
                            {t("general.overview")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={Segment.teams}
                            className="segment-button"
                        >
                            {t("general.teams")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={Segment.match}
                            className="segment-button"
                            data-testid="match-segment-button"
                        >
                            {t("general.match")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={Segment.stats}
                            className="segment-button"
                        >
                            {t("general.stats")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            hidden={!playerOfTheMatchAccess}
                            value={Segment.playerOfTheMatch}
                            className="segment-button"
                        >
                            {t("general.awards")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            hidden={!teamBehaviorAccess}
                            value={Segment.teamBehavior}
                            className="segment-button"
                        >
                            {t("general.behaviour")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={Segment.earnings}
                            className="segment-button"
                        >
                            {t("fixture.FixtureSummaryEarnings.earnings")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={Segment.notes}
                            className="segment-button"
                        >
                            {t("general.notes")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={Segment.tags}
                            className="segment-button"
                        >
                            {t("general.keywords.title")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={Segment.wellbeing}
                            className="segment-button"
                        >
                            {t("general.wellbeing")}
                        </IonSegmentButton>
                        {developerToolsEnabled && (
                            <IonSegmentButton
                                value={Segment.revs}
                                className="segment-button"
                            >
                                Doc Revs
                            </IonSegmentButton>
                        )}
                    </IonSegment>
                </IonCard>

                {selectedSegment === Segment.overview && (
                    <Overview match={match} />
                )}
                {selectedSegment === Segment.teams && <Teams match={match} />}
                {selectedSegment === Segment.match && <Match match={match} />}
                {selectedSegment === Segment.stats && <Stats match={match} />}
                {selectedSegment === Segment.playerOfTheMatch && (
                    <PlayerOfTheMatchWrapper match={match} />
                )}
                {selectedSegment === Segment.teamBehavior && (
                    <TeamBehaviorSegment match={match} />
                )}
                {selectedSegment === Segment.earnings && (
                    <Earnings match={match} />
                )}
                {selectedSegment === Segment.notes && (
                    <NotesWrapper match={match} />
                )}
                {selectedSegment === Segment.wellbeing && (
                    <Wellbeing match={match} />
                )}
                {selectedSegment === Segment.tags && <Keywords match={match} />}
                {selectedSegment === Segment.revs && (
                    <ScrollableContent>
                        <IonContent>
                            {match._id && (
                                <PouchDebugMatchRevs matchId={match._id} />
                            )}
                        </IonContent>
                    </ScrollableContent>
                )}

                <IonLoading isOpen={loading} />

                <AlertComponent
                    testId="delete-match-alert"
                    showAlert={showAlert}
                    onDonePressed={() => deleteFixture()}
                    dismissAlert={() => setShowAlert(false)}
                    showAlternativeButton
                    buttonDone={t("fixture.FixtureIncident.confirm")}
                    title={`${t("general.delete")} ${t("general.match")}`}
                    subTitle={t("fixture.FixtureSegments.deleteMatchDesc")}
                    buttonAltText={t("general.cancel")}
                />

                <MatchReportModal
                    match={match}
                    openMatchReportModal={openMatchReportModal}
                    onDismiss={() => setOpenMatchReportModal(false)}
                    onSendMatchReport={() => sendReport()}
                />

                <ToastComponent
                    isOpen={showToast}
                    position="bottom"
                    message={toastTitle}
                    onDismiss={() => setShowToast(false)}
                />
                {showCropMatchModal && (
                    <CropMatchLength
                        isModalOpen={showCropMatchModal}
                        onDismiss={() => setShowCropMatchModal(false)}
                        match={match}
                    />
                )}
            </IonContent>
        </IonPage>
    );
}

export default FixturesSegments;
