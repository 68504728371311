import { useEffect, useState } from "react";
import { IonButton, IonCard } from "@ionic/react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import { checkForActiveMgmSubscription } from "../../services/subscriptionService";
import { getSummary } from "../../services/mgmService";
import { formatForCurrency } from "../../utils/CurrencySymbol";
import { getLocale } from "../../utils/localeUtils";
import { useSubscription } from "../../components/hooks";
import { URLS } from "../../constants/urls";

import "./mgmCredit.css";

export const MgmCredit = () => {
    const { t } = useTranslation();

    const subscription = useSubscription();

    const [mgmCredit, setMgmCredit] = useState<string | null>(null);

    useEffect(() => {
        if (subscription) {
            const { subscriptions } = subscription;
            const mgmSub = checkForActiveMgmSubscription(subscriptions);

            if (mgmSub) {
                getSummary()
                    .then((summary) => {
                        const { creditAvailable, currency } = summary;
                        if (creditAvailable) {
                            setMgmCredit(
                                formatForCurrency(
                                    creditAvailable,
                                    currency,
                                    getLocale()
                                )
                            );
                        }
                    })
                    .catch((error) => {
                        Sentry.captureException(error);
                    });
            }
        }
    }, [subscription]);

    return mgmCredit ? (
        <IonCard className="mgm-card">
            <span className="mgm-card-title">
                {t("upgrade.mgm.creditBalance", { amount: mgmCredit })}
            </span>
            <IonButton
                expand="block"
                onClick={() => {
                    window.open(URLS.MGM_TERMS, "_system");
                }}
            >
                {t("mgm.howToRedeem")}
            </IonButton>
        </IonCard>
    ) : null;
};
