import { IonText } from "@ionic/react";
import { MatchPhone } from "refsix-js-models";
import { FC } from "react";
import "../pages/tabs/fixtures.css";
import "./MatchBottomCardComponent.css";
import { MatchTags } from "./MatchTags";

interface Match {
    match: MatchPhone;
}

const MatchBottomCardComponent: FC<Match> = ({ match }) => {
    return (
        <div style={{ width: "100%" }}>
            <div className="bottom-element">
                <div className="comp-venue">
                    <IonText className="competition-name">
                        {match.competition}
                    </IonText>
                    <IonText className="venue-name">{match.venue}</IonText>
                </div>
                {match.keywords && match.keywords.length > 0 && (
                    <MatchTags match={match} />
                )}
            </div>
        </div>
    );
};

export default MatchBottomCardComponent;
