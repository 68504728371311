import { updateSettings } from "../services/settingsService";
import { Settings } from "../redux/models/refsixState";
import { ConfigPhone, TimerLayout, WatchFace } from "refsix-js-models";
import { TimerConfigOptions } from "../pages/tabs/more/timerOptions";

export async function handleUpdateTimers(
    props: Partial<ConfigPhone>,
    settings: Settings
) {
    try {
        await updateSettings({
            ...settings,
            settings: {
                ...settings.settings,
                ...props,
            },
        });
    } catch (e) {
        console.log("Error trying to update timers", e);
    }
}

export function uniqueTimers(config: TimerConfigOptions) {
    return (
        config.timer1 !== config.timer2 &&
        config.timer1 !== config.timer3 &&
        config.timer2 !== config.timer3
    );
}

export const updateConfigTimers = (
    timerConfig: TimerConfigOptions,
    watchFace: WatchFace,
    settings: Settings
): Promise<void> => {
    if (uniqueTimers(timerConfig)) {
        return updateSettings({
            ...settings,
            settings: {
                ...settings.settings,
                ...{ watchFace },
                ...{ layout: selectionsToLayout(timerConfig) },
            },
        });
    }
    throw "duplicate timers";
};

export const layoutToSelections = (layout: TimerLayout) => {
    if (layout && layout.left && layout.right && layout.main) {
        return {
            timer1: layout.main,
            timer2: layout.left,
            timer3: layout.right,
        } as TimerConfigOptions;
    } else if (layout && layout.main && layout.secondary) {
        return {
            timer1: layout.main,
            timer2: layout.secondary,
        } as TimerConfigOptions;
    } else {
        console.error("Incorrect layout");
    }
};

export function selectionsToLayout(selection: TimerConfigOptions) {
    if (selection.timer3) {
        return {
            main: selection.timer1,
            left: selection.timer2,
            right: selection.timer3,
        } as TimerLayout;
    } else {
        return {
            main: selection.timer1,
            secondary: selection.timer2,
        } as TimerLayout;
    }
}
