import { SubscriptionItems } from "refsix-js-models";
import { MGM_ENABLED_COUNTRIES } from "../../services/mgmService";
import { useProfile, useSubscription } from "./index";

/**
 * custom hook that calls useSubscriptions hook and then checks if there is a
 * subscription with the provider set to mgm
 */
export function useEnrolledInMgm(): boolean {
    const subscriptions = useSubscription();

    const mgmSub = subscriptions?.subscriptions.find(
        (subscription: SubscriptionItems) => subscription.provider === "mgm"
    );

    return !!mgmSub;
}

export function useIsMgmAvailableInCountry(): boolean {
    const profile = useProfile();
    if (profile && profile.country) {
        return MGM_ENABLED_COUNTRIES.includes(profile.country.toUpperCase());
    }
    return false;
}
