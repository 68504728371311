import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { IntegrationPartner, MatchPhone, SelectedTeam } from "refsix-js-models";
import { shirt } from "ionicons/icons";
import { makeShortName } from "refsix-core";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
    hasFeatureAccessFromState,
    isUserPlusEligible,
} from "../services/subscriptionService";
import AlertComponent from "./AlertComponent";
import { ColorPickerComponent, HeaderComponent } from "./index";
import { R6IonInput } from "./r6ionic/R6IonInput";
import { errorProps } from "../services/errorService";
import { isAndroid, isIos } from "../services/platformDetection";
import { URLS } from "../constants/urls";
import { trackProTeaser } from "../services/analytics/analyticsService";
import AutocompleteInput from "./AutocompleteInputItem";
import { routes } from "../route/constants";
import { useTeamList } from "./hooks/autocomplete/useTeamList";
import { updateUserActionsCheckList } from "../services/userActionsCheckListService";
import { GoProOrPlusAlertComponent } from "./upgrade/GoProOrPlusAlertComponent";

interface MatchDetailProps {
    match: MatchPhone;
    integration: boolean;
}

const MatchDetailsTeamComponent: FC<MatchDetailProps> = ({
    match,
    integration,
}) => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [showPickerColorModal, setShowColorPickerModal] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(SelectedTeam.none);
    const [proAlert, setProAlert] = useState<boolean>(false);
    const hasColorAccess = useSelector((state: RootState) => {
        return hasFeatureAccessFromState(state.subscriptions, "colors");
    });
    const { formState, setValue } = useFormContext();
    const competition = useWatch({ name: "competition" });
    //const teamList = useTeamList(competition);
    const { search, renderer, ready } = useTeamList(competition);
    const isAssignr = match.integration?.partner === IntegrationPartner.assignr;

    const watchHomeTeam =
        useWatch({
            name: "homeTeam",
        }) || t("fixture.FixtureNew.homeTeam");

    const watchHomeTeamShort =
        useWatch({
            name: "homeTeamShort",
        }) || t("fixture.FixtureNew.homeShort");

    const watchAwayTeam =
        useWatch({
            name: "awayTeam",
        }) || t("fixture.FixtureNew.awayTeam");

    const watchAwayColor = useWatch({
        name: "awayColor",
    });

    const watchHomeColor = useWatch({
        name: "homeColor",
    });

    // Team named and short team names for away and home team cannot be similar
    const checkTeamNameIsUniq = (value: string) => {
        return value !== watchHomeTeam;
    };

    const checkTeamNameShortIsUniq = (value: string) => {
        return value !== watchHomeTeamShort;
    };

    const ShirtColor: FC<{ team: SelectedTeam }> = ({ team }) => {
        if (team === SelectedTeam.none) return null;
        return (
            <Controller
                name={team + "Color"}
                render={({ field: { onChange } }) => (
                    <ColorPickerComponent
                        colorPicked={(color) => {
                            setShowColorPickerModal(false);
                            updateUserActionsCheckList({
                                teamColorsAdded: true,
                            });
                            return onChange(color);
                        }}
                    />
                )}
            />
        );
    };

    useEffect(() => {
        if (
            !formState.defaultValues?._rev &&
            watchHomeTeam &&
            formState.isDirty &&
            formState.dirtyFields.homeTeam &&
            !formState.dirtyFields.homeTeamShort
        ) {
            setValue("homeTeamShort", makeShortName(watchHomeTeam), {
                shouldValidate: true,
            });
        }
    }, [watchHomeTeam, setValue, formState]);

    useEffect(() => {
        if (
            !formState.defaultValues?._rev &&
            watchAwayTeam &&
            formState.isDirty &&
            formState.dirtyFields.awayTeam &&
            !formState.dirtyFields.awayTeamShort
        ) {
            setValue("awayTeamShort", makeShortName(watchAwayTeam), {
                shouldValidate: true,
            });
        }
    }, [watchAwayTeam, setValue, formState]);

    return (
        <IonCol style={{ paddingTop: 5, paddingBottom: 0 }}>
            <IonRow className="row-container">
                <div className="create-match-step">
                    <IonText>2</IonText>
                </div>
                <IonText className="create-match-card-title">
                    {t("fixture.FixtureNew.enterTeamDetails")}
                </IonText>
            </IonRow>
            <IonItem
                className="item-title-container rounded-corners-top"
                lines="full"
            >
                <IonLabel position="fixed">
                    {t("fixture.FixtureNew.homeTeam")}
                </IonLabel>
            </IonItem>
            {search && renderer && (
                <AutocompleteInput
                    label={t("fixture.FixtureNew.teamName")}
                    name="homeTeam"
                    placeholder={t("fixture.FixtureNew.homeTeam")}
                    errors={formState.errors}
                    setValue={setValue}
                    searchFn={search}
                    renderFn={renderer}
                    testId="homeTeamItem"
                    ready={!!search}
                    disabled={isAssignr}
                />
            )}
            <IonItem
                {...errorProps("homeTeamShort", formState.errors)}
                lines="full"
                data-testid="home-team-short-item-input"
            >
                <R6IonInput
                    name="homeTeamShort"
                    placeholder={t("fixture.FixtureNew.homeShort")}
                    autocapitalize="characters"
                    maxLength={3}
                    className="align-text-end"
                    label={t("fixture.FixtureNew.shortName")}
                />
            </IonItem>
            <IonItem
                className="item-title rounded-corners-bottom"
                lines="none"
                data-testid="homeColor"
                onClick={() => {
                    if (hasColorAccess) {
                        setShowColorPickerModal(true);
                        setSelectedTeam(SelectedTeam.home);
                    } else {
                        if (!isUserPlusEligible()) {
                            trackProTeaser("team-colours", "pro");
                        } else if (isUserPlusEligible()) {
                            trackProTeaser("team-colours", "plus");
                        }
                        setProAlert(true);
                    }
                }}
            >
                <IonLabel position="fixed">
                    {t("fixture.FixtureNew.shirtColour")}
                </IonLabel>
                <IonIcon
                    slot="end"
                    icon={shirt}
                    style={{
                        color: hasColorAccess ? watchHomeColor : "",
                    }}
                />
            </IonItem>

            <IonCol>
                <IonItem
                    className="item-title-container rounded-corners-top"
                    lines="full"
                >
                    <IonText>{t("fixture.FixtureNew.awayTeam")}</IonText>
                </IonItem>

                {search && renderer && (
                    <AutocompleteInput
                        label={t("fixture.FixtureNew.teamName")}
                        name="awayTeam"
                        placeholder={t("fixture.FixtureNew.awayTeam")}
                        errors={formState.errors}
                        setValue={setValue}
                        searchFn={search}
                        renderFn={renderer}
                        testId="awayTeamItem"
                        ready={!!search}
                        rules={{
                            validate: {
                                validateTeamName: checkTeamNameIsUniq,
                            },
                        }}
                        disabled={isAssignr}
                    />
                )}

                <IonItem
                    {...errorProps("awayTeamShort", formState.errors)}
                    lines="full"
                    data-testid="away-team-short-item-input"
                >
                    <R6IonInput
                        name="awayTeamShort"
                        rules={{
                            validate: {
                                validateTeamName: checkTeamNameShortIsUniq,
                            },
                        }}
                        placeholder={t("fixture.FixtureNew.awayShort")}
                        autocapitalize="characters"
                        maxLength={3}
                        className="align-text-end"
                        label={t("fixture.FixtureNew.shortName")}
                    />
                </IonItem>

                <IonItem
                    className="item-title rounded-corners-bottom"
                    lines="none"
                    data-testid="awayColor"
                    onClick={() => {
                        if (hasColorAccess) {
                            setShowColorPickerModal(true);
                            setSelectedTeam(SelectedTeam.away);
                        } else {
                            if (!isUserPlusEligible()) {
                                trackProTeaser("team-colours", "pro");
                            } else if (isUserPlusEligible()) {
                                trackProTeaser("team-colours", "plus");
                            }
                            setProAlert(true);
                        }
                    }}
                >
                    <IonLabel position="fixed">
                        {t("fixture.FixtureNew.shirtColour")}
                    </IonLabel>

                    <IonIcon
                        slot="end"
                        icon={shirt}
                        style={{
                            color: hasColorAccess ? watchAwayColor : "",
                        }}
                    />
                </IonItem>
            </IonCol>
            <IonModal
                isOpen={showPickerColorModal}
                onDidDismiss={() => setShowColorPickerModal(false)}
                initialBreakpoint={0.8}
                showBackdrop
            >
                <HeaderComponent
                    endBtnTestId="buttonColorClicked"
                    onClickEndButton={() => setShowColorPickerModal(false)}
                    headerEndText={t("upgrade.proFeatures.close")}
                />
                <IonContent>
                    <ShirtColor team={selectedTeam} />
                </IonContent>
            </IonModal>
            <GoProOrPlusAlertComponent
                showProAlert={proAlert}
                setShowProAlert={setProAlert}
                goProTestId={"team-details-pro-colours"}
                teaserName={"team-colours"}
            />
        </IonCol>
    );
};

export default MatchDetailsTeamComponent;
