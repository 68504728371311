import { ADD_PROFILE } from "./types";
import { UserProfile } from "../../models/userProfile";

export type addUserProfileAction = {
    type: string;
    userProfile: UserProfile;
};

export const addUserProfile = (
    userProfile: UserProfile
): addUserProfileAction => ({
    type: ADD_PROFILE,
    userProfile,
});
