import { SET_WATCH_STATUS, SET_WATCH_STATUS_SUPPORTED } from "../actions/types";
import { WatchBluetoothStatus } from "refsix-js-models";

export const initialWatchStatus: WatchBluetoothStatus = {
    isActivated: false,
    isComplicationEnabled: false,
    isPaired: false,
    isReachable: false,
    isSupported: false,
    isWatchAppInstalled: false,
};

function watchStatusReducer(
    state: WatchBluetoothStatus = initialWatchStatus,
    action: any
) {
    switch (action.type) {
        case SET_WATCH_STATUS:
            return action.watchStatus;
        case SET_WATCH_STATUS_SUPPORTED:
            return {
                ...state,
                isSupported: action.supported,
            };
        default:
            return state;
    }
}

export default watchStatusReducer;
