import { SET_TEMPLATES } from "./types";
import { Templates } from "refsix-js-models";

export interface SetTemplatesAction {
    type: string;
    templates: Templates;
}

export const setTemplates = (templates: Templates): SetTemplatesAction => ({
    type: SET_TEMPLATES,
    templates,
});
