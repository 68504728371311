import { isPlatform } from "@ionic/react";

export function isBrowser() {
    // return (window.cordova && window.cordova.platformId === 'browser') || !window.cordova;
    // TODO: actually check platform
    return true;
}

export function isNotBrowser() {
    // return window.cordova && window.cordova.platformId !== 'browser';
    return false; // TODO: actually check platform
}

export function isIos(): boolean {
    return !isPlatform("mobileweb") && isPlatform("ios");
}

export function isAndroid(): boolean {
    return !isPlatform("mobileweb") && isPlatform("android");
}
