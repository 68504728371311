import React, { FC } from "react";
import { IonText, useIonPopover } from "@ionic/react";
import { EventName, HalfEvent, SelectedTeam } from "refsix-js-models";
import "./components.css";
import "../pages/tabs/fixturesSegments/teams.css";
import "./MatchLogComponent.css";

import { DisplayEvent } from "../models/DisplayEvent";
import { DisplayEventType } from "../models/DisplayEventType";
import { RenderMatchEventForTeam } from "./match/RenderMatchEventForTeam";
import { RenderMatchTimeLog } from "./match/RenderMatchTimeLog";
import { EventToolbar } from "../pages/tabs/fixturesSegments/EventToolbar";

interface Event {
    displayEvent: DisplayEvent;
    delete: () => void;
    edit: () => void;
    timezone?: string;
    matchInProgress?: boolean;
}

let counter = 0;
const useId = () => {
    return "" + counter++;
};

const MatchLogComponent: FC<Event> = ({
    displayEvent,
    edit,
    delete: deleteEvent,
    timezone,
    matchInProgress,
}) => {
    const popoverId = useId();
    const [present, dismiss] = useIonPopover(EventToolbar, {
        popoverId: popoverId,
        deleteEvent: deleteEvent,
        editEvent: edit,
        dismiss: () => dismiss(),
    });
    const event = displayEvent.event;

    const isFutureEndHalf =
        displayEvent.type === DisplayEventType.periodEnd &&
        displayEvent.event.eventName === EventName.half &&
        (displayEvent.event as HalfEvent).endTime &&
        // @ts-ignore
        (displayEvent.event as HalfEvent).endTime > Date.now();

    const shouldRenderMatchTimeLogComponent =
        event.eventName === EventName.half ||
        event.eventName === EventName.penalties ||
        event.eventName === EventName.abandoned;

    function displayPopover(clickEvent: any) {
        // disable editing of half events
        if (
            !matchInProgress &&
            displayEvent.type === DisplayEventType.other &&
            displayEvent.event.eventName !== EventName.abandoned
        ) {
            present({ event: clickEvent });
        }
    }

    if (matchInProgress && isFutureEndHalf) {
        return <></>;
    }

    return (
        <div
            data-testid="fixture-segment-match"
            className="match-log-container"
            onClick={(clickEvent) => displayPopover(clickEvent)}
        >
            <div
                data-testid="logSeparator"
                className="match-log-event-separator-wrapper"
            >
                <div className="match-log-event-separator" />
            </div>

            <div className="card-middle-container">
                <div className="team-name-color-container">
                    <RenderMatchEventForTeam
                        side={SelectedTeam.home}
                        event={event}
                    />
                </div>

                <div>
                    {shouldRenderMatchTimeLogComponent ? (
                        <RenderMatchTimeLog
                            timeZone={timezone}
                            matchEvent={event}
                            displayEvent={displayEvent}
                        />
                    ) : (
                        <div className="match-log-time-container">
                            <IonText>{displayEvent.displayMinute}</IonText>
                        </div>
                    )}
                </div>

                <div className="team-name-color-container-right">
                    <RenderMatchEventForTeam
                        side={SelectedTeam.away}
                        event={event}
                    />
                </div>
            </div>
        </div>
    );
};

export default MatchLogComponent;
