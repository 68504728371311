import { MatchPhone } from "refsix-js-models";

export function shouldShowLinkYearInReview(
    currentDate: Date,
    matches: MatchPhone[]
): boolean {
    const yearToReview = 2024;
    const startDate = new Date("2025-01-01T00:00:00");
    const endDate = new Date("2025-01-31T23:59:59");
    let count = 0;

    // this operation is quite heave if a lot of matches, so made it a bit more efficient
    // by breaking out of loop as soon as minimum number of matches found
    for (let i = 0; i < matches.length; i++) {
        if (
            matches[i].matchFinished &&
            new Date(matches[i].date).getFullYear() === yearToReview
        ) {
            count++;
            if (count > 5) {
                break;
            }
        }
    }

    const hasMoreThanFiveMatches = count > 5;

    return (
        startDate <= currentDate &&
        currentDate <= endDate &&
        hasMoreThanFiveMatches
    );
}
