import { EventName } from "./enums/eventName";

export class MatchEvent {
    timestamp: number;
    eventName: EventName;
    half: number;
    minuteOfPlay: number;
    additionalTime?: number;

    constructor(
        timestamp: number,
        eventName: EventName,
        half: number,
        minuteOfPlay: number,
        additionalTime?: number
    ) {
        this.timestamp = timestamp;
        this.eventName = eventName;
        this.half = half;
        this.minuteOfPlay = minuteOfPlay;
        this.additionalTime = additionalTime;
    }
}
