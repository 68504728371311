export function countryToCurrency(country: string) {
    switch (country) {
        case "JP":
        case "JA": // for short locale with language only
            return "JPY";
        case "AU":
            return "AUD";
        case "US":
            return "USD";
        case "NZ":
            return "NZD";
        case "DK":
        case "DA": // for short locale with language only
            return "DKK";
        case "AT":
        case "BE":
        case "CY":
        case "NL":
        case "EE":
        case "FI":
        case "FR":
        case "DE":
        case "GR":
        case "IE":
        case "LV":
        case "LT":
        case "LU":
        case "MT":
        case "MC":
        case "PT":
        case "SM":
        case "SK":
        case "SI":
        case "ES":
        case "VA":
            return "EUR";
        default:
            return "GBP";
    }
}

export function amountInLowestDenomination(amount: number, currency: string) {
    return currency.toLowerCase() !== "jpy" ? amount * 100 : amount;
}

export function amountInDecimal(amount: number, currency: string) {
    return currency.toLowerCase() !== "jpy"
        ? (amount / 100).toFixed(2)
        : amount;
}

export function flipNumberSign(amount: number) {
    return amount === 0 ? amount : -amount;
}
