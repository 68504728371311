import { FC, useCallback, useState } from "react";
import { SocialLoginProps } from "./Google";
import {
    login,
    Provider,
    providers,
    socialLogin,
} from "../../../services/authService";
import {
    IonAlert,
    IonLoading,
    useIonRouter,
} from "@ionic/react";
import request from "../../../services/request";
import React from "react";
import { useTranslation } from "react-i18next";
import { isIos } from "../../../services/platformDetection";
import { routes } from "../../../route/constants";

export const SocialAuthLogin: FC<SocialLoginProps> = ({
    login,
    requestType,
    children,
}) => {
    console.log("SocialAuthLogin", children);
    return <>{children}</>;
};

type SocialCallbackProps = {
    profile: any;
    auth: any;
    provider: Provider;
};

type AlertLinkProps = {
    type: "linkLocal" | "cantLink" | "socialLoginGoogle" | "socialLoginApple";
    isOpen?: boolean;
    dismiss?: (data?: any) => void;
    data?: SocialCallbackProps;
};

const SocialLinkAlert: React.FC<AlertLinkProps> = ({
    isOpen = false,
    dismiss,
    data,
    type,
}) => {
    const route = useIonRouter();

    const [passwordError, setPasswordError] = useState<boolean>(false);
    const { t } = useTranslation();
    switch (type) {
        case "linkLocal": {
            const msg = [
                t("login.login.socialLogin.link", { provider: data?.provider }),
            ];
            if (passwordError) msg.push(t("login.login.failure.title"));

            return (
                <IonAlert
                    isOpen={isOpen}
                    title={t("login.login.password")}
                    subHeader={t("register.register.confirmPassword")}
                    message={msg.join("<br/><br/>")}
                    inputs={[
                        {
                            name: "password",
                            type: "password",
                            placeholder: t("login.login.password"),
                            cssClass: passwordError ? "color-danger" : "",
                        },
                    ]}
                    buttons={[
                        {
                            text: t("general.cancel"),
                            role: "cancel",
                            handler: () => dismiss && dismiss(false),
                        },
                        {
                            text: t("help.OnboardingVideo.controls.ok"),
                            handler: (ev) => {
                                const fn = async (password: string) => {
                                    if (!data) {
                                        return;
                                    }
                                    const { profile, auth, provider } = data;
                                    try {
                                        const response = await login(
                                            profile.email,
                                            password
                                        );
                                        const data: any = response.data;

                                        const reqHeader = {
                                            headers: {
                                                Authorization: `Bearer ${data.token}:${data.password}`,
                                            },
                                        };
                                        const resp = await request.post<any>(
                                            `/auth/link/${provider}/token`,
                                            auth,
                                            reqHeader
                                        );
                                        dismiss && dismiss(resp);
                                    } catch (err) {
                                        setPasswordError(true);
                                    }
                                };
                                setPasswordError(false);
                                fn(ev.password);
                                return false;
                            },
                        },
                    ]}
                ></IonAlert>
            );
        }
        case "cantLink": {
            return (
                <IonAlert
                    isOpen={isOpen}
                    header={t("feedback.feedback.failure.title")}
                    message={t("login.login.failure.title")}
                    buttons={[
                        {
                            text: t("general.resetPassword"),
                            role: "ok",
                            handler: () => {
                                dismiss && dismiss(false);
                                route.push(routes.forgotPassword);
                            },
                        },
                    ]}
                ></IonAlert>
            );
        }
        case "socialLoginGoogle": {
            return (
                <IonAlert
                    isOpen={isOpen}
                    header={t("feedback.feedback.failure.title")}
                    message={t("login.login.socialLogin.alreadyLinked", {
                        provider: "Google",
                    })}
                    buttons={[
                        {
                            text: t("general.cancel"),
                            role: "cancel",
                            handler: () => dismiss && dismiss(false),
                        },
                    ]}
                ></IonAlert>
            );
        }
        case "socialLoginApple": {
            return (
                <IonAlert
                    isOpen={isOpen}
                    header={t("feedback.feedback.failure.title")}
                    message={t("login.login.socialLogin.alreadyLinked", {
                        provider: "Apple",
                    })}
                    buttons={[
                        {
                            text: t("general.cancel"),
                            role: "cancel",
                            handler: () => dismiss && dismiss(false),
                        },
                    ]}
                ></IonAlert>
            );
        }
    }
};

type UseSocialAuthLoginReturnProps = {
    socialLogin: (profile: any, auth: any, provider: Provider) => Promise<void>;
    session: any;
    alerts: any;
    isLoading?: boolean;
};

// create a call back function, that takes the user profile and auth object, and a provider
// and calls the login function
// If the login fails because the user already exists, we should show a popup to the user
// to ask them to login with their password
// We then call the link function with the user profile and auth object
export const useSocialAuthLogin = (): UseSocialAuthLoginReturnProps => {
    const [session, setSession] = useState<any>(null);
    const [data, setData] = useState<SocialCallbackProps>();
    const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<any>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const _login = async (profile: any, auth: any, provider: Provider) => {
        setIsLoading(true);
        setData({ profile, auth, provider });

        try {
            const localSession = await socialLogin(profile, auth, provider);
            setSession(localSession);
        } catch (e) {
            const err = e as any;
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.error === "Email already in use" &&
                err.response.data.status === 409
            ) {
                const userProvidersReq = await providers(profile);
                const userProviders = userProvidersReq.data || ([] as string[]);
                if (userProviders.length === 0) {
                    //console.log("No providers");
                    setIsAlertOpen(false);
                    setIsLoading(false);
                    return;
                }
                if (
                    userProviders.length === 1 &&
                    userProviders[0] === "local"
                ) {
                    setAlertType("linkLocal");
                    setIsAlertOpen(true);
                    setIsLoading(false);
                    return;
                } else {
                    // Show popup with login button to existing provider
                    const existingProviders = userProviders.filter(
                        (p) => p !== provider && p !== "local"
                    );
                    if (existingProviders.length === 0) {
                        setIsAlertOpen(false);
                        setIsLoading(false);
                        return;
                    } else {
                        if (existingProviders.includes('apple') && !isIos()) {
                            // We can't login with apple on android
                            setAlertType("cantLink");
                            setIsAlertOpen(true);
                            setIsLoading(false);
                        } else {
                            if (existingProviders.includes("google")) {
                                setAlertType("socialLoginGoogle");
                            } else if (existingProviders.includes("apple")) {
                                setAlertType("socialLoginApple");
                            }
                            // Linking multiple providers
                            setIsAlertOpen(true);
                            setIsLoading(false);
                            return;
                        }
                    }
                }
            }
        }
    };
    const dismiss = useCallback(
        async (res: any) => {
            if (res !== false && data) {
                const { profile, auth, provider } = data;
                const localSession = await socialLogin(profile, auth, provider);
                setSession(localSession);
            }
            console.log("dismiss", data);
            setIsAlertOpen(false);
            setIsLoading(false);
        },
        [data]
    );
    return {
        socialLogin: _login,
        session,
        alerts: [
            <SocialLinkAlert
                key="socialAlert"
                type={alertType}
                isOpen={isAlertOpen}
                dismiss={dismiss}
                data={data}
            />,
        ],
        isLoading,
    };
};
