import { checkWatchVersion } from "../versionMismatch";
import { store } from "../../../redux/store";
import { setWatchStatus } from "../../../redux/actions/watchStatusAction";

export type DeviceInfo = {
    make: string;
    model: string;
    watchAppVersion?: string;
};

export async function deviceInfoResponse(deviceInfo: DeviceInfo) {
    if (deviceInfo.watchAppVersion) {
        checkWatchVersion(deviceInfo.watchAppVersion).catch((error) => {});
    }
    store.dispatch(
        setWatchStatus({
            ...store.getState().watchStatus,
            make: deviceInfo.make,
            model: deviceInfo.model,
        })
    );
}
