import request from "../services/request";
import { Player } from "refsix-js-models";

export interface TeamSheetScanRequest {
    image: string; // base64 encoded image
    locale: string; // The user's locale
    country: string; // The user's country
    startingPlayersCount: number; // The number of starting players
    benchPlayersCount: number; // The number of substitute/bench players
    matchId?: string; // The match ID
}

export const postTeamSheetScan = async (
    requestPayload: TeamSheetScanRequest
) => {
    return await request.post<Player[], TeamSheetScanRequest>(
        "/team-sheet-scan",
        requestPayload,
        { timeout: 30000 }
    );
};

export const toTitleCase = (text: string) => {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

export const cleanPlayers = (players: Player[]): Player[] => {
    // remove the player numbers from the player names. If the player has teamOfficial set to true,
    // then set the number incrementally starting from 1000
    let teamOfficialCount = 1000;
    return players.map((player) => {
        return {
            ...player,
            name: toTitleCase(player.name.trim()),
            number: player.teamOfficial ? teamOfficialCount++ : player.number,
        };
    });
};
