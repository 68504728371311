import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    useIonRouter,
} from "@ionic/react";

import { HeaderComponent } from "../../components";
import { MatchPhone, OfficialRole } from "refsix-js-models";
import "./addResult.css";
import {
    createDefaultMatch,
    recalculateStats,
    timingEvents,
    updateMatch,
} from "../../services/matchService";
import { makeShortName, processEvents, processMatch } from "refsix-core";
import { find } from "lodash";
import { useProfile, useTemplates } from "../../components/hooks";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "./dateTimePicker";
import { TemplateSelect } from "./templateSelect";
import { OfficialRoleSelect } from "./officialRoleSelect";
import { routes } from "../../route/constants";
import { resultAdded } from "../../services/analytics/analyticsService";
import AutocompleteInput from "../../components/AutocompleteInputItem";
import { useTeamList } from "../../components/hooks/autocomplete/useTeamList";
import {
    competitionsList2Renderer,
    useCompetitionList,
} from "../../components/hooks/autocomplete/useCompetitionList";

export type QuickResult = {
    competition: string;
    date: string;
    officialRole: OfficialRole;
    homeTeam: string;
    awayTeam: string;
    templateId: string;
};

export const QuickResultForm: FC<{}> = () => {
    const { t } = useTranslation();
    const templates = useTemplates();
    const formMethods = useForm<QuickResult>({
        defaultValues: {
            date: moment()
                .subtract(1, "day")
                .format(moment.HTML5_FMT.DATETIME_LOCAL),
            officialRole: OfficialRole.referee,
            templateId: templates?.defaultTemplate,
        },
    });

    const compList = useCompetitionList();

    const competition = formMethods.watch("competition");
    const { search, renderer } = useTeamList(competition);

    const router = useIonRouter();

    const profile = useProfile();

    const onSubmit = (data: QuickResult, e: any) => {
        const template =
            templates && find(templates.templates, { _id: data.templateId });
        if (!template) {
            return;
        }
        const timings = template.config.timings;
        const newMatchEvents = timingEvents(
            timings,
            moment(data.date),
            template.config.extraTimeAvailable,
            template.config.penaltiesAvailable
        );
        const newMatch: MatchPhone = {
            ...createDefaultMatch(),
            ...template?.config,
            ...data,
            homeTeamShort: makeShortName(data.homeTeam),
            awayTeamShort: makeShortName(data.awayTeam),
            matchFinished: true,
            matchInProgress: false,
            matchAbandoned: false,
            eventsProcessed: false,
            matchEvents: newMatchEvents,
            playedPenalties: template.config.penaltiesAvailable,
            playedExtraTime: template.config.extraTimeAvailable,
            // Fixme: Add ability to add players in the edit event instead
            players: {
                [data.homeTeam]: [],
                [data.awayTeam]: [],
            },
        };

        const processedMatch = processEvents(newMatch);
        processedMatch.stats = processMatch(processedMatch);

        updateMatch(processedMatch).then((matchPhone) => {
            if (matchPhone && profile) {
                // Update analytics
                resultAdded(matchPhone);
                recalculateStats(matchPhone, profile).then(() => {
                    router.push(
                        `${routes.addResultDetail}/${matchPhone._id}`,
                        undefined,
                        "replace"
                    );
                });
            }
        });
    };

    const { errors } = formMethods.formState;

    const appendErrorStyle = (
        name: keyof QuickResult,
        additionalClasses: string = ""
    ) =>
        errors[name]
            ? { className: `danger ${additionalClasses}` }
            : { className: additionalClasses };
    return (
        <IonPage>
            <HeaderComponent
                showBackButton={true}
                title={t("general.addMatch")}
                headerEndText={t("general.next")}
                onClickEndButton={formMethods.handleSubmit(onSubmit)}
                endBtnTestId="addResultDoneBtn"
            />
            <IonContent>
                <FormProvider {...formMethods}>
                    <IonCard>
                        {compList.search && (
                            <AutocompleteInput
                                label={t("fixture.FixtureNew.competition")}
                                name="competition"
                                errors={errors}
                                setValue={formMethods.setValue}
                                searchFn={compList.search}
                                renderFn={competitionsList2Renderer(t)}
                                testId="competition-item-input"
                                ready={!!(compList.ready && compList.search)}
                                rules={{
                                    required: true,
                                }}
                            />
                        )}
                        <IonItem
                            {...appendErrorStyle("date", "item-title")}
                            lines={"full"}
                        >
                            <IonLabel position="fixed" className={"item-title"}>
                                {t("stats.stats.Filters.date")}
                            </IonLabel>
                            <DateTimePicker name={"date"} />
                        </IonItem>
                        <IonItem
                            {...appendErrorStyle("officialRole", "item-title")}
                            lines={"full"}
                        >
                            <IonLabel position="fixed" className={"item-title"}>
                                {t("stats.stats.Filters.officialRole")}
                            </IonLabel>
                            <OfficialRoleSelect
                                name="officialRole"
                                rules={{ required: true }}
                                slot="end"
                                className="align-text-end"
                            />
                        </IonItem>
                        {search && renderer && (
                            <AutocompleteInput
                                label={t("fixture.FixtureNew.homeTeam")}
                                name="homeTeam"
                                errors={errors}
                                setValue={formMethods.setValue}
                                searchFn={search}
                                renderFn={renderer}
                                testId=""
                                ready={!!search}
                                rules={{
                                    required: true,
                                }}
                            />
                        )}
                        {search && renderer && (
                            <AutocompleteInput
                                label={t("fixture.FixtureNew.awayTeam")}
                                name="awayTeam"
                                errors={errors}
                                // @ts-ignore Not sure why it is being fussy
                                setValue={formMethods.setValue}
                                searchFn={search}
                                renderFn={renderer}
                                testId=""
                                ready={!!search}
                                rules={{
                                    required: true,
                                }}
                            />
                        )}
                        <IonItem
                            {...appendErrorStyle("templateId", "item-title")}
                            lines={"full"}
                        >
                            <IonLabel position="fixed" className={"item-title"}>
                                {t("fixture.FixtureTemplate.selectTemplate")}
                            </IonLabel>
                            <TemplateSelect
                                name="templateId"
                                rules={{ required: true }}
                                slot={"end"}
                                className="align-text-end"
                            />
                        </IonItem>
                    </IonCard>
                </FormProvider>
            </IonContent>
        </IonPage>
    );
};
