import { FC } from "react";
import { useTranslation } from "react-i18next";
import { MatchHeader } from "../../pages/addResult/matchHeader";
import { useMatch } from "../hooks/match";
import { IonSpinner, IonText } from "@ionic/react";
import { GpsMap, GpsMapType } from "../GpsMapComponent";
import { FitnessDataProvider } from "../hooks";
import { AggregatedStats } from "refsix-js-models";
import "./reviewSharedStyles.css";
import "./mostCardsReview.css";
import { ProTeaser, TeaserStyle } from "../ProTeaser";
import { hasFeatureAccess } from "../../services/subscriptionService";
import Stat, {
    StatsContainer,
    Content,
    Footer,
    Header,
    MatchCardsResult,
    Icon,
    Row,
    CardsContainer,
    Red,
    CardIcon,
    Yellow,
    MainHeader,
    Col,
} from "./reviewCard";

interface DistanceReviewProps {
    matchId?: string;
    aggregatedStats: AggregatedStats;
}
const MostCardsReview: FC<DistanceReviewProps> = ({
    matchId,
    aggregatedStats,
}) => {
    const { t } = useTranslation();
    const cardMapAccess = hasFeatureAccess("cardmap");
    const match = useMatch(matchId);

    if (!matchId || !match) {
        return <IonSpinner name="circular"></IonSpinner>;
    }

    return (
        <div className={`most-cards-review review`}>
            <div className="top-bit">
                <StatsContainer>
                    <Stat className={"total-cards"}>
                        <Row>
                            <MainHeader>
                                {t("review.mostCardsInGame")}
                            </MainHeader>
                            <Content>
                                <CardsContainer>
                                    <Red>
                                        <Col className={"card-name"}>
                                            <CardIcon />
                                            <Header>{t("review.red")}</Header>
                                        </Col>
                                        <Content>
                                            {match.stats?.redCardTotal || 0}
                                        </Content>
                                        {/*<Footer>*/}
                                        {/*    {aggregatedStats.redCardAverage.toFixed(*/}
                                        {/*        1*/}
                                        {/*    )}{" "}*/}
                                        {/*    {t("stats.stats.avgPerGame")}*/}
                                        {/*</Footer>*/}
                                    </Red>
                                    <Yellow>
                                        <Col className={"card-name"}>
                                            <CardIcon />
                                            <Header>
                                                {t("review.yellow")}
                                            </Header>
                                        </Col>
                                        <Content>
                                            {match.stats?.yellowCardTotal || 0}
                                        </Content>
                                        {/*<Footer>*/}
                                        {/*    {aggregatedStats.yellowCardAverage.toFixed(*/}
                                        {/*        1*/}
                                        {/*    )}{" "}*/}
                                        {/*    {t("stats.stats.avgPerGame")}*/}
                                        {/*</Footer>*/}
                                    </Yellow>
                                </CardsContainer>
                            </Content>
                        </Row>
                    </Stat>
                    <Stat className={"most-cards-match"}>
                        <Content>
                            <MatchCardsResult match={match} />
                        </Content>
                    </Stat>
                    <Stat className={"card-map"}>
                        {/* card map */}
                        <FitnessDataProvider matchId={matchId}>
                            <div className="fixture-card ion-card-transparent">
                                <ProTeaser
                                    showTeaser={
                                        !cardMapAccess &&
                                        match._id !== "example-result1"
                                    }
                                    teaserName="review-card-map"
                                    isRefSixEligible={false}
                                    teaserStyle={TeaserStyle.button}
                                >
                                    <GpsMap
                                        match={match}
                                        mapType={GpsMapType.CardMap}
                                        testId="review-card-map"
                                        useDummyData={!cardMapAccess}
                                    />
                                </ProTeaser>
                            </div>
                        </FitnessDataProvider>
                    </Stat>
                </StatsContainer>
            </div>
        </div>
    );
};

export default MostCardsReview;
