import { getDatabaseService } from "./matchService";
import { IDatabaseService } from "./database/iDatabaseService";
import {
    UserActionsCheckList,
    UserActionsCheckListDoc,
} from "refsix-js-models";
import { USER_ACTIONS_CHECKLIST_ID } from "./database/pouchUtils";
import { setUserActionsCheckList } from "../redux/actions/userActionsCheckList";
import { store } from "../redux/store";

export function updateUserActionsCheckList(props: UserActionsCheckList) {
    store.dispatch(
        setUserActionsCheckList({
            ...store.getState().userActionsCheckList,
            ...props,
        })
    );
}

export async function updateUserActionsCheckListInDB(
    userActionsCheckList: UserActionsCheckList
) {
    const dbService = getDatabaseService() as IDatabaseService;
    try {
        const userActionsCheckListDoc: UserActionsCheckListDoc = {
            userActionsCheckList: userActionsCheckList,
            _id: USER_ACTIONS_CHECKLIST_ID,
        };

        await dbService.upsertDoc(userActionsCheckListDoc);
    } catch (err: any) {
        console.log("Error updating user actions checklist in DB: ", err);
    }
}
