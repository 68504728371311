import {
    IonCard,
    IonContent,
    IonGrid,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { filterResults, getFilteredResults } from "../../services/matchService";
import {
    MatchBottomCardComponent,
    MatchMiddleCardComponent,
    MatchUpperCardComponent,
    NoMatchesComponent,
} from "../../components";
import HeaderComponent from "../../components/HeaderComponent";
import { setSelectedMatch } from "../../redux/actions/matches";
import React, { useEffect, useState } from "react";
import { MatchPhone } from "refsix-js-models";
import Filters from "../filters/filters";
import { MatchType } from "../filters/enums";
import { filter } from "ionicons/icons";
import { useSubscription } from "../../components/hooks";
import { routes } from "../../route/constants";
import { UpgradeBanner } from "../../components/upgrade/UpgradeBanner";
import { useHistory } from "react-router";
import { UseBackButtonHandler } from "../../components/hooks/useBackButtonHandler";

const MATCHES_PER_PAGE = 10;

function Results() {
    const { t } = useTranslation();

    const loadingMatches = useSelector(
        (state: RootState) => state.matches.loading
    );
    const history = useHistory();

    const allMatches = useSelector((state: RootState) => state.matches.matches);
    const subscription = useSubscription();
    const [allResults, setAllResults] = useState<MatchPhone[]>([]);

    const filters = useSelector(
        (state: RootState) => state.filters.filterResults
    );

    const filteredResults = useSelector((state: RootState) => {
        const filters = state.filters.filterResults;
        return filters
            ? getFilteredResults(filters, state.matches.matches)
            : filterResults(state.matches.matches) || [];
    });

    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [displayedMatches, setDisplayedMatches] = useState<MatchPhone[]>([]);

    const route = useIonRouter();
    const dispatch = useDispatch();

    const pushData = () => {
        const max = Math.min(
            displayedMatches.length + MATCHES_PER_PAGE,
            filteredResults.length
        );
        setDisplayedMatches(filteredResults.slice(0, max));
    };

    const loadData = (ev: any) => {
        pushData();
        ev.target.complete();
    };

    useEffect(() => {
        if (window.location.search === "?showFilters=true") {
            setFilterModal(true);
        }
    }, [window.location]);

    useEffect(() => {
        // Reset the list to show new results (a filter will exist here)
        if (filters) {
            const newlyFilteredMatches = getFilteredResults(
                filters,
                allResults
            );
            setDisplayedMatches(
                newlyFilteredMatches.slice(0, MATCHES_PER_PAGE)
            );
        }
    }, [filters]);

    useEffect(() => {
        setAllResults(filterResults(allMatches));
        if (loadingMatches === false) {
            pushData();
        }
    }, [allMatches, loadingMatches]);

    const handleCloseModal = () => {
        setFilterModal(false);
        window.history.replaceState({}, "", routes.refsixResults);
    };

    const handleBackButton = () => {
        if (
            !window.location.search.includes("?showFilters=true") &&
            window.location.pathname === routes.refsixResults
        ) {
            handleCloseModal();
        }
    };

    function matchElements() {
        return displayedMatches.length > 0 ? (
            displayedMatches.map((match: MatchPhone, index: number) => {
                return (
                    <div key={match._id}>
                        {index % 3 === 0 && <UpgradeBanner />}
                        <IonCard
                            color={"medium"}
                            style={{ backgroundColor: "#242424" }}
                            className="fixture-card"
                            onClick={() => {
                                dispatch(setSelectedMatch(match));
                                route.push(
                                    `${routes.match}/${match._id}/overview`,
                                    "forward"
                                );
                            }}
                        >
                            <IonGrid>
                                <IonRow>
                                    <MatchUpperCardComponent match={match} />

                                {match.matchOfficials !== undefined &&
                                    match.matchOfficials.assistant1 !== "" && (
                                        <MatchMiddleCardComponent
                                            match={match}
                                        />
                                    )}

                                <MatchBottomCardComponent match={match} />
                            </IonRow>
                        </IonGrid>
                    </IonCard>
                    </div>
                );
            })
        ) : (
            <IonCard className="no-upcoming-matches">
                <IonText data-testid="noEndedFixturesTxt">
                    {t("results.results.noResults")}
                </IonText>
            </IonCard>
        );
    }

    return (
        <IonPage data-testid="results">
            <HeaderComponent
                titleTestId="results-page-header"
                title={t("general.results")}
                endBtnIconName={filter}
                endBtnTestId={"resultsFilterBtn"}
                onClickEndButton={() => {
                    setFilterModal(true);
                    history.push(routes.refsixResults + "?showFilters=true");
                }}
                showProBadge={subscription?.isPro}
                showPlusBadge={subscription?.isPlus}
            />
            <IonContent>
                <UseBackButtonHandler handlerFunction={handleBackButton} />
                {filteredResults.length < allResults.length && (
                    <IonItem color="transparent" lines="none">
                        <IonText
                            className="filter-result-text"
                            data-testid="filter-result-text"
                        >
                            <span
                                className="filter-results-number"
                                data-testid="filter-results-number"
                            >
                                {filteredResults.length}/{allResults.length}
                            </span>
                            {t("stats.stats.Filters.resultsBasedOnFilters")}
                        </IonText>
                    </IonItem>
                )}

                {!loadingMatches && allResults.length > 0 && (
                    <div data-testid="results-page-allresults">
                        {matchElements()}
                        <IonInfiniteScroll
                            onIonInfinite={loadData}
                            threshold="100px"
                            disabled={
                                filteredResults.length ===
                                displayedMatches.length
                            }
                        >
                            <IonInfiniteScrollContent
                                loadingSpinner="bubbles"
                                loadingText="Loading more data..."
                            />
                        </IonInfiniteScroll>
                    </div>
                )}

                {!loadingMatches && !allResults.length && (
                    <NoMatchesComponent
                        page="results"
                        notMatchesTitle={t("results.results.noResults")}
                    />
                )}
                {filterModal && (
                    <IonModal
                        isOpen={filterModal}
                        onDidDismiss={() => handleCloseModal()}
                        initialBreakpoint={1}
                    >
                        <Filters
                            matches={allResults}
                            matchType={MatchType.Result}
                            closeModal={() => handleCloseModal()}
                        />
                    </IonModal>
                )}
            </IonContent>
        </IonPage>
    );
}

export default Results;
