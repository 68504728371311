import { forEach, values } from "lodash";
import { isPlatform } from "@ionic/react";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { useUsername } from "./hooks";
import { SubscriptionService } from "../services/subscriptionService";
import { curry, filter, flow } from "lodash/fp";
import "cordova-plugin-purchase";
import * as Sentry from "@sentry/react";
import { getCurrentUsername } from "../utils/username";

// define types badly exported by the library
enum LogLevel {
    /** Disable all logging (default) */
    QUIET = 0,
    /** Show only error messages */
    ERROR = 1,
    /** Show warnings and errors */
    WARNING = 2,
    /** Also show information messages */
    INFO = 3,
    /** Enable internal debugging messages. */
    DEBUG = 4,
}
enum ProductType {
    /** Type: An consumable product, that can be purchased multiple time */
    CONSUMABLE = "consumable",
    /** Type: A non-consumable product, that can purchased only once and the user keeps forever */
    NON_CONSUMABLE = "non consumable",
    /** @deprecated use PAID_SUBSCRIPTION */
    FREE_SUBSCRIPTION = "free subscription",
    /** Type: An auto-renewable subscription */
    PAID_SUBSCRIPTION = "paid subscription",
    /** Type: An non-renewing subscription */
    NON_RENEWING_SUBSCRIPTION = "non renewing subscription",
    /** Type: The application bundle */
    APPLICATION = "application",
}
enum Platform {
    /** Apple AppStore */
    APPLE_APPSTORE = "ios-appstore",
    /** Google Play */
    GOOGLE_PLAY = "android-playstore",
    /** Windows Store */
    WINDOWS_STORE = "windows-store-transaction",
    /** Braintree */
    BRAINTREE = "braintree",
    /** Test platform */
    TEST = "test",
}

export type JSONProduct = {
    name: string;
    planFeatures: string;
    period: "month" | "year";
    trial: boolean;
    platform: string;
    active: boolean;
};

const getIapPlatform = (product: JSONProduct): Platform => {
    switch (product.platform) {
        case "ios":
            return Platform.APPLE_APPSTORE;
        case "android":
            return Platform.GOOGLE_PLAY;
        default:
            console.log("Unknown iap product platform", product.platform);
            return Platform.TEST;
    }
};

export const getR6Products = async () => {
    const filterByPlatform = curry((p: string, c: JSONProduct[]) =>
        filter({ platform: p }, c)
    );
    const getPlatform = (): string => (isPlatform("ios") ? "ios" : "android");

    try {
        const productsReq = await fetch(
            process.env["REACT_APP_PRODUCTLIST_URL"] ||
                "https://iap.refsix.com/iap.json"
        );

        const processProducts: (p: JSONProduct[]) => JSONProduct[] = flow(
            filterByPlatform(getPlatform())
        );

        const products = (await productsReq.json()) as {
            [k: string]: JSONProduct;
        };

        return processProducts(values(products));
    } catch (e) {}
    return [];
};

const r6Products = getR6Products();

let initHasRun = false;
export const initIAPStore = async () => {
    if (initHasRun) return;
    initHasRun = true;

    if (!CdvPurchase || !CdvPurchase.store || !LogLevel) {
        console.error("Store is not defined");
        Sentry.captureMessage(
            "initIAPStore - Store is not defined when trying to be initialised"
        );
        return;
    }
    const store = CdvPurchase.store;
    store.verbosity = LogLevel.DEBUG;
    if (!process.env["REACT_APP_PURCHASE_VALIDATION"]) {
        throw "No Validation URL provided";
    }
    store.validator = process.env["REACT_APP_PURCHASE_VALIDATION"] || "";

    store.error((err: any) => {
        console.error("Store Error " + JSON.stringify(err));
    });

    const _purchaseVerified = function (receipt: CdvPurchase.VerifiedReceipt) {
        receipt
            .finish()
            .then(() => {
                const ss = SubscriptionService.getInstance();
                ss.refreshSubscription();
            })
            .catch((err) => {
                Sentry.addBreadcrumb({
                    category: "purchase",
                    message: `Error verifying purchase ${err}`,
                });
            });
    };

    const registerProduct = (iapProduct: JSONProduct) => {
        if (store.get(iapProduct.name)) return;

        // Add product to store
        store.register({
            id: iapProduct.name,
            type: ProductType.PAID_SUBSCRIPTION,
            platform: getIapPlatform(iapProduct),
        });
    };
    const initProducts = (products: JSONProduct[]) => {
        console.log("INIT Products");
        forEach(products, registerProduct);
    };

    initProducts(await r6Products);

    store
        .when()
        .approved((transaction) => {
            transaction.verify();
        })

        .verified((receipt) => {
            _purchaseVerified(receipt);
        });

    store.initialize();
    store.update();
};

export const useProductStore = () => {
    const username = useUsername();
    const [deviceReady, setDeviceReady] = useState(false);
    const [prods, setProds] = useState<JSONProduct[]>([]);

    useEffect(() => {
        const listener = async function () {
            setDeviceReady(true);
        };

        document.addEventListener("deviceready", listener, false);

        return () => {
            document.removeEventListener("deviceready", listener, false);
        };
    }, [document]);

    useEffect(() => {
        console.log("IAP - Use effect called");
        if (deviceReady && username) {
            console.log("IAP - Device ready and username set");
            initIAPStore();
            initR6Products();
        }
    }, [deviceReady, username]);

    const initR6Products = async () => {
        setProds(await r6Products);
    };

    return prods;
};

type StoreProviderType = {
    products: JSONProduct[];
    refresh: (() => Promise<any>) | null;
    store: any | null;
};

const storeProvider: StoreProviderType = {
    products: [],
    refresh: null,
    store: null,
};

export const InAppPurchaseStoreContext =
    createContext<StoreProviderType>(storeProvider);

export const useIAPStoreContext = () => {
    return useContext(InAppPurchaseStoreContext);
};

export const InAppPurchaseStoreProvider: FC<{}> = ({ children }) => {
    const products = useProductStore();
    const userId = useUsername();

    useEffect(() => {
        if (userId) {
            if (CdvPurchase.store) {
                CdvPurchase.store.applicationUsername = getCurrentUsername;
            } else {
                // if not initialised yet then wait for the device ready event
                document.addEventListener("deviceready", function () {
                    CdvPurchase.store.applicationUsername = getCurrentUsername;
                });
            }
        }
    }, [userId]);

    return (
        <InAppPurchaseStoreContext.Provider
            value={{
                products: products || [],
                refresh: () => CdvPurchase.store.update(),
                store: CdvPurchase.store,
            }}
        >
            {children}
        </InAppPurchaseStoreContext.Provider>
    );
};
