// a react ionic page that mocks the watch messaging
import React, { FC, useState } from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import shortUUID from "short-uuid";
import { MatchHeader } from "../addResult/matchHeader";
import {
    FitnessDataProvider,
    useFitnessDataContext,
    useUsername,
} from "../../components/hooks";
import gpsMockDataRaw from "./data/mockWatchGPS.json";
import hrMockDataRaw from "./data/mockWatchHR.json";
import mockWatchMatch from "./data/mockWatchResult.json";
import { createNewMatchForPhone } from "refsix-core";
import { MessageTypes } from "../../services/watch/messageTypesEnum";
import { mockWatchInstance } from "../../services/watch";
import { updateMatch } from "../../services/matchService";
import { Match, MatchPhone, TemplateConfig } from "refsix-js-models";
import { useMatch } from "../../components/hooks/match";
import { useTranslation } from "react-i18next";
import { routes } from "../../route/constants";

type MockMatchData = {
    message: any;
    gps1: any;
    gps2: any;
    hr: any;
};

export const MockWatchMessaging: React.FC<RouteComponentProps> = ({}) => {
    // function to generate new watch gps, heart rate, and match data

    const { t } = useTranslation();
    const [currentMatchId, setCurrentMatchId] = useState("");
    const currentMatch = useMatch(currentMatchId);
    const username = useUsername();
    const route = useIonRouter();

    const generateCurrentData = (matchId: string): MockMatchData => {
        const gpsMessage1 = {
            ...gpsMockDataRaw[0],
            matchId: matchId,
            id: shortUUID().uuid() as string,
        };

        const gpsMessage2 = {
            ...gpsMockDataRaw[1],
            matchId: matchId,
            id: shortUUID().uuid() as string,
        };
        const hrMessage = {
            ...hrMockDataRaw,
            matchId: matchId,
            id: shortUUID().uuid() as string,
        };

        const matchMessage = {
            ...mockWatchMatch,
            id: shortUUID().uuid() as string, // this is the message, NOT the match id
            matchId: matchId,
            match: { ...mockWatchMatch.match, matchId: matchId },
        };

        return {
            message: matchMessage,
            gps1: gpsMessage1,
            gps2: gpsMessage2,
            hr: hrMessage,
        };
    };

    const generateLegacyData = (matchId: string): MockMatchData => {
        const gpsMessage1 = {
            ...gpsMockDataRaw[0],
            matchId: matchId,
            id: shortUUID().uuid() as string,
            messageId: undefined,
            payloadType: MessageTypes.GPS_RECEIVED,
        };

        const gpsMessage2 = {
            ...gpsMockDataRaw[1],
            matchId: matchId,
            id: shortUUID().uuid() as string,
            messageId: undefined,
            payloadType: MessageTypes.GPS_RECEIVED,
        };

        const hrMessage = {
            ...hrMockDataRaw,
            matchId: matchId,
            id: shortUUID().uuid() as string,
            messageId: undefined,
            payloadType: MessageTypes.HR_RECEIVED,
        };

        const matchMessage = {
            ...mockWatchMatch,
            id: shortUUID().uuid() as string, // this is the message, NOT the match id
            matchId: matchId,
            match: { ...mockWatchMatch.match, matchId: matchId },
        };

        return {
            message: matchMessage,
            gps1: gpsMessage1,
            gps2: gpsMessage2,
            hr: hrMessage,
        };
    };

    // Has GPS and HR payloads like they were in app version <6.0.0
    const generateLegacyWatchData = async () => {
        const matchId: string = shortUUID().uuid();

        if (!username) {
            console.log("No username");
        }

        const { message, gps1, gps2, hr } = generateLegacyData(matchId);

        try {
            setCurrentMatchId(matchId);

            mockWatchInstance?.messageReceived(hr);
            mockWatchInstance?.messageReceived(gps1);
            mockWatchInstance?.messageReceived(gps2);
            mockWatchInstance?.messageReceived(message);
        } catch (e: any) {
            console.log("Error handling mock watch data", e);
        }

        console.log("All messages sent for phone handling");
    };

    const generateWatchData = async (matchId?: string) => {
        matchId = matchId || shortUUID().uuid();

        if (!username) {
            console.log("No username");
        }

        const { message, gps1, gps2, hr } = generateCurrentData(matchId);

        try {
            setCurrentMatchId(matchId);

            setTimeout(() => mockWatchInstance?.messageReceived(hr), 1000);
            setTimeout(() => mockWatchInstance?.messageReceived(gps1), 2000);
            setTimeout(() => mockWatchInstance?.messageReceived(gps2), 3000);
            setTimeout(() => mockWatchInstance?.messageReceived(message), 7000);
        } catch (e: any) {
            console.log("Error handling mock watch data", e);
        }

        console.log("All messages sent for phone handling");
    };

    const generateWatchDataExistingMatch = async () => {
        const matchId: string = shortUUID().uuid();

        if (!username) {
            console.log("No username");
        }

        const { message, gps1, gps2, hr } = generateCurrentData(matchId);

        const result = message.match as Match;

        const template = TemplateConfig.fromJSON({
            timings: {
                period1: 45,
                period2: 45,
                interval1: 15,
                extraTimeHalfLength: 15,
                sinBinTimerLength: 10,
            },
            periodsNo: "2",
            withGoalScorers: false,
            sinBinSystem: "systemA",
            misconductCodeId: "england",
            teamSize: 11,
            subsNo: 5,
            extraTimeAvailable: true,
            penaltiesAvailable: true,
        });

        const fixture: MatchPhone = {
            ...createNewMatchForPhone(result, template),
            matchEvents: undefined,
            device: undefined,
            matchFinished: false,
        };

        await updateMatch(fixture);

        console.log("Fixture added to db");

        return await generateWatchData(matchId);
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref="/"
                            text={t("help.OnboardingVideo.controls.back")}
                        />
                    </IonButtons>
                    <IonTitle>Mock Watch Messaging</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Mock Watch Messaging</IonCardTitle>
                        <IonCardSubtitle>
                            Generate and send mock watch data to the phone
                        </IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonButton
                            data-testid="sendCurrentData"
                            onClick={() => generateWatchData()}
                        >
                            Send create match on watch data
                        </IonButton>
                        <IonButton onClick={generateWatchDataExistingMatch}>
                            Send match result for existing fixture
                        </IonButton>
                        <IonButton
                            onClick={generateLegacyWatchData}
                            data-testid="sendLegacyData"
                        >
                            Send Legacy Mock Watch Data
                        </IonButton>

                        {currentMatchId && (
                            <div>
                                <IonText data-testid="currentMatchId">
                                    Current Match ID: {currentMatchId}
                                </IonText>
                            </div>
                        )}
                    </IonCardContent>
                </IonCard>

                {currentMatch && (
                    <div
                        data-testid="matchSummary"
                        onClick={() => {
                            route.push(
                                `${routes.match}/${currentMatch._id}/overview`,
                                "forward"
                            );
                        }}
                    >
                        <MatchHeader match={currentMatch} />
                    </div>
                )}

                {currentMatch && (
                    <IonCard data-testid="matchDetails">
                        {currentMatch && currentMatch._id && (
                            <FitnessDataProvider matchId={currentMatch._id}>
                                <CurrentMatchInfo currentMatch={currentMatch} />
                            </FitnessDataProvider>
                        )}
                    </IonCard>
                )}
            </IonContent>
        </IonPage>
    );
};

const CurrentMatchInfo: FC<{ currentMatch: MatchPhone }> = ({
    currentMatch,
}) => {
    const { gpsData, hrData, matchId, reload } = useFitnessDataContext();

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Detailed Match Info</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <p>Match id: {matchId}</p>
                <p data-testid="gpsCount">
                    GPS Data:{" "}
                    {gpsData?.geoPoints
                        ? `${gpsData.geoPoints.length} points`
                        : "No data"}
                </p>
                <IonButton onClick={reload}>Reload</IonButton>
                <p data-testid="gpsProcessed">
                    GPS Processed:{" "}
                    {currentMatch.stats?.gpsProcessed === 1 ? "Yes" : "No"}
                </p>
                <p data-testid="hrCount">
                    HR Data:{" "}
                    {hrData?.heartRateValues
                        ? `${hrData.heartRateValues.length} points`
                        : "No data"}
                </p>
                <IonButton onClick={reload}>Reload</IonButton>
                <p data-testid="hrProcessed">
                    HR Processed:{" "}
                    {currentMatch.stats?.heartRateProcessed === 1
                        ? "Yes"
                        : "No"}
                </p>
            </IonCardContent>
        </IonCard>
    );
};
