import { AggregatedStats, MatchPhone } from "refsix-js-models";
import React, { FC, useEffect, useState } from "react";
import { IonCard, IonCol, IonItem, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import {
    COLORS_SPEED_CATEGORY,
    COLORS_SPRINT_CATEGORY,
} from "../../../constants/StatsColorPalette";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
    lastMatches,
    matchesWithMost,
    topMatches,
} from "../../../services/matchService";
import moment from "moment";
import "./speed.css";
import {
    BarChartComponent,
    HeadlineNumbersComponent,
    ListOfTopComponent,
    RoundBarComponent,
    SprintLegendKeysComponent,
} from "../../../components";
import { distanceString } from "../../../components/DistanceComponent";
import { FiltersModel } from "../../../redux/models/filtersModel";
import { barChartOptions } from "./barChartOptions";
import { distanceSmallString } from "../../../components/DistanceSmallComponent";
import { ProTeaser } from "../../../components/ProTeaser";
import { LIST_OF_TOP_DATE_FORMAT } from "../../../components/ListOfTopComponent";

interface SpeedProps {
    matches: MatchPhone[];
    hasStatsAccess: boolean;
    filters: FiltersModel;
    aggregatedStats?: AggregatedStats;
}

// TODO Replace with proper charts.js type that this duplicates
type Datasets = {
    data: number[];
    backgroundColor: string;
    borderWidth: number;
    barThickness: number;
    label?: string;
};

const Speed: FC<SpeedProps> = ({
    matches,
    hasStatsAccess,
    filters,
    aggregatedStats,
}) => {
    const { t } = useTranslation();
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );
    const count = 10;
    const statsType = "gpsAvailable";
    const dateFormat = LIST_OF_TOP_DATE_FORMAT;

    const sprintCategoryNames: string[] = [
        t("fixture.FixtureSummaryStats.sprintmapKey.low"),
        t("fixture.FixtureSummaryStats.sprintmapKey.medium"),
        t("fixture.FixtureSummaryStats.sprintmapKey.high"),
    ];
    const speedCategoryNames = [
        t("graphs.SpeedCategoryDistanceGraph.stand"),
        t("general.walk"),
        t("general.jog"),
        t("general.run"),
        t("general.sprint"),
    ];
    const sprintCategoryColors = COLORS_SPRINT_CATEGORY;
    const speedCategoryColors = COLORS_SPEED_CATEGORY;

    const [labels, setLabels] = useState<string[]>([]);
    const [sprintsBarData, setSprintsBarData] = useState<Datasets[]>([]);
    const [speedBarDatasets, setSpeedBarDatasets] = useState<Datasets[]>([]);

    useEffect(() => {
        getLast10Matches();
    }, [matches, filters, useImperial]);

    async function getLast10Matches() {
        // Getting the last 10 matches
        const getMatches = lastMatches(matches, filters, count, statsType);
        const matchesSorted = topMatches(getMatches, useImperial);
        setLabels(matchesSorted.barLabels);

        // Setting the Data for last 10 matches sprints
        setSprintsBarData([
            {
                data: matchesSorted.sprintsLow,
                backgroundColor: sprintCategoryColors[0],
                borderWidth: 1,
                barThickness: 20,
                label: sprintCategoryNames[0],
            },
            {
                data: matchesSorted.sprintsMedium,
                backgroundColor: sprintCategoryColors[1],
                borderWidth: 1,
                barThickness: 20,
                label: sprintCategoryNames[1],
            },
            {
                data: matchesSorted.sprintsHigh,
                backgroundColor: sprintCategoryColors[2],
                borderWidth: 1,
                barThickness: 20,
                label: sprintCategoryNames[2],
            },
        ]);

        // Setting the Data for last 10 matches distance
        setSpeedBarDatasets([
            {
                data: matchesSorted.speedWalk,
                backgroundColor: speedCategoryColors[0],
                borderWidth: 1,
                barThickness: 20,
                label: speedCategoryNames[1],
            },
            {
                data: matchesSorted.speedJog,
                backgroundColor: speedCategoryColors[1],
                borderWidth: 1,
                barThickness: 20,
                label: speedCategoryNames[2],
            },
            {
                data: matchesSorted.speedRun,
                backgroundColor: speedCategoryColors[2],
                borderWidth: 1,
                barThickness: 20,
                label: speedCategoryNames[3],
            },
            {
                data: matchesSorted.speedSprint,
                backgroundColor: speedCategoryColors[3],
                borderWidth: 1,
                barThickness: 20,
                label: speedCategoryNames[4],
            },
        ]);
    }

    const matchesWithMostSprints = matchesWithMost(
        matches,
        "sprintsMediumAndHigh",
        5,
        filters
    );

    const data = {
        labels: labels,
        datasets: sprintsBarData,
    };

    const speedBarData = {
        labels: labels,
        datasets: speedBarDatasets,
    };

    const series = [
        t("graphs.SpeedCategoryDistanceGraph.stand"),
        t("general.walk"),
        t("general.jog"),
        t("general.run"),
        t("general.sprint"),
    ];

    const unit = useImperial
        ? t("general.distanceUnit.small.imperial")
        : t("general.distanceUnit.small.metric");

    const speedCategoryDurations = aggregatedStats
        ? [
              aggregatedStats?.speedCategoryDurations[0],
              aggregatedStats?.speedCategoryDurations[1],
              aggregatedStats?.speedCategoryDurations[2],
              aggregatedStats?.speedCategoryDurations[3],
              aggregatedStats?.speedCategoryDurations[4] +
                  aggregatedStats?.speedCategoryDurations[5],
          ]
        : [];

    const speedCategoryDistance = aggregatedStats
        ? [
              aggregatedStats?.speedCategoryDistances[1],
              aggregatedStats?.speedCategoryDistances[2],
              aggregatedStats?.speedCategoryDistances[3],
              aggregatedStats?.speedCategoryDistances[4] +
                  aggregatedStats?.speedCategoryDistances[5],
          ]
        : [];

    return (
        <>
            <IonCard data-testid="trends-segment-speed">
                <IonItem lines="none">
                    <IonCol>
                        <IonText>{t("stats.stats.Sprints.sprints")}</IonText>
                    </IonCol>
                </IonItem>
                {/* Getting sprints total by category*/}
                {aggregatedStats && (
                    <ProTeaser
                        showTeaser={!hasStatsAccess}
                        teaserName="trends-speed-totals"
                    >
                        <>
                            <HeadlineNumbersComponent
                                testIdsPrefix="total"
                                data={aggregatedStats.sprintsTotal}
                                title={t("stats.stats.total")}
                                labels={sprintCategoryNames}
                                useDummyData={!hasStatsAccess}
                                fromIndex={!hasStatsAccess ? 0 : undefined}
                            />
                            <HeadlineNumbersComponent
                                data={aggregatedStats.sprintsTotalAverage.map(
                                    (val) => val.toFixed(2)
                                )}
                                title={t("fixture.FixtureSummaryStats.average")}
                                labels={sprintCategoryNames}
                                useDummyData={!hasStatsAccess}
                                fromIndex={!hasStatsAccess ? 0 : undefined}
                            />
                            <HeadlineNumbersComponent
                                data={aggregatedStats.sprintsDistanceTotal.map(
                                    (val) =>
                                        distanceString(val, useImperial, t, 2)
                                )}
                                title={t("fixture.FixtureSummaryStats.average")}
                                labels={sprintCategoryNames}
                                useDummyData={!hasStatsAccess}
                                fromIndex={!hasStatsAccess ? 0 : undefined}
                            />
                            <HeadlineNumbersComponent
                                data={aggregatedStats.sprintsDistanceTotalAverage.map(
                                    (val) =>
                                        distanceSmallString(
                                            val,
                                            useImperial,
                                            t,
                                            1
                                        )
                                )}
                                title={t(
                                    "stats.stats.Distance.averageDistance"
                                )}
                                labels={sprintCategoryNames}
                                useDummyData={!hasStatsAccess}
                                fromIndex={!hasStatsAccess ? 0 : undefined}
                            />
                        </>
                    </ProTeaser>
                )}

                <SprintLegendKeysComponent />

                {/*Getting last 10 matches BAR*/}
                <IonItem lines="none">
                    <IonCol>
                        <IonText className="text-small">
                            {t("stats.stats.last10Matches")}
                        </IonText>
                    </IonCol>
                </IonItem>

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-speed-last-10"
                >
                    <IonItem lines="none">
                        <IonCol>
                            <BarChartComponent
                                data={data}
                                barChartOptions={barChartOptions(
                                    matches,
                                    t("stats.stats.Sprints.graphLabel"),
                                    useImperial
                                )}
                                testId="bar-char-sprints"
                                useDummyData={!hasStatsAccess}
                            />
                        </IonCol>
                    </IonItem>
                </ProTeaser>

                {/*Getting last 10 matches LIST*/}
                <IonItem lines="none" data-testId="topMatches">
                    <IonCol>
                        <IonText className="text-small">
                            {t("stats.stats.Distance.topMatches")}
                        </IonText>
                    </IonCol>
                </IonItem>

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-speed-top-matches"
                >
                    <>
                        {matchesWithMostSprints.map((match, index) => {
                            return (
                                <ListOfTopComponent
                                    matchId={match._id}
                                    key={index}
                                    stat={
                                        match.stats &&
                                        match.stats[
                                            "sprintsMediumAndHigh"
                                        ].toString()
                                    }
                                    teamNames={`${match.homeTeam} v ${match.awayTeam}`}
                                    date={moment(match.date).format(dateFormat)}
                                    useDummyData={!hasStatsAccess}
                                />
                            );
                        })}
                    </>
                </ProTeaser>
            </IonCard>

            <IonCard>
                <IonItem lines="none">
                    <IonCol>
                        <IonText>
                            {t("stats.stats.Sprints.activityBreakdown")}
                        </IonText>
                    </IonCol>
                </IonItem>

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-speed-activity-breakdown"
                >
                    <>
                        <RoundBarComponent
                            title={t(
                                "fixture.FixtureSummaryStats.timeDuration"
                            )}
                            values={speedCategoryDurations}
                            series={series}
                            distanceBy="duration"
                            numberOfCategories={5}
                            unit="%"
                            useDummyData={!hasStatsAccess}
                        />

                        <RoundBarComponent
                            title={t(
                                "fixture.FixtureSummaryStats.distanceCovered"
                            )}
                            values={speedCategoryDistance}
                            series={series.slice(1, 5)}
                            distanceBy="distance"
                            numberOfCategories={4}
                            unit="distance"
                            useDummyData={!hasStatsAccess}
                        />
                    </>
                </ProTeaser>

                <IonItem lines="none">
                    <IonCol>
                        <IonText className="text-small">
                            {t("stats.stats.last10Matches")}
                        </IonText>
                    </IonCol>
                </IonItem>

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-speed-last10-distance"
                >
                    <IonItem lines="none">
                        <IonCol>
                            <BarChartComponent
                                data={speedBarData}
                                barChartOptions={barChartOptions(
                                    matches,
                                    `${t(
                                        `graphs.SpeedCategoryDistanceGraph.distanceScale.${
                                            useImperial ? "imperial" : "metric"
                                        }`
                                    )}`,
                                    useImperial,
                                    "distance"
                                )}
                                testId="bar-chart-speed"
                                useDummyData={!hasStatsAccess}
                            />
                        </IonCol>
                    </IonItem>
                </ProTeaser>
            </IonCard>
        </>
    );
};

export default Speed;
