import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { CustomIonFormProps } from "./components";

export const DateTimePicker: FC<CustomIonFormProps> = ({ testId, name }) => {
    return (
        <Controller
            render={({ field }) => {
                return (
                    <input
                        date-testid={testId || name}
                        className="date-input with-time"
                        type="datetime-local"
                        slot={"end"}
                        {...field}
                    />
                );
            }}
            name={name}
        />
    );
};
