import { TeamListItem } from "./autocomplete/useTeamList";
import { useTeamPacksContext } from "./TeamPacks";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { TeamPackData, TeamPackDivisionTeamName } from "refsix-js-models";
import _, { flow } from "lodash/fp";

export const useTeamPackCompetitionTeams: (comp: string) => TeamListItem[] = (
    competition: string
) => {
    const { downloadedPacks, ready } = useTeamPacksContext();
    const { t } = useTranslation();
    const teams = useMemo(() => {
        if (!ready) return [];
        const getAllTeamsForCompFromTeamPacks: (d: TeamPackData[]) => any =
            flow(
                _.flatMap((t) => t.teams),
                _.find((p) => p.name === competition),
                _.getOr([], "team"),
                _.map((t: TeamPackDivisionTeamName) => ({
                    league: competition,
                    name: t.fullName,
                }))
            );
        return getAllTeamsForCompFromTeamPacks(downloadedPacks);
    }, [downloadedPacks, competition, ready]);
    return teams;
};
