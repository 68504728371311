import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonIcon,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    useIonRouter,
    useIonViewDidLeave,
    useIonViewWillEnter,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { HeaderImageComponent } from "../../components";
import { routes } from "../../route/constants";
import { PlanType, UpgradeToPlusOrPro } from "./upgradeToPlusOrPro";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    useIsBasic,
    useIsPro,
    useSubscription,
    useUserActionsCheckList,
} from "../../components/hooks";
import { startAds, stopAds } from "../../services/AdMobService";
import {
    checkForActiveFreeTrialSubscription,
    checkIfBeenPro,
    isUserPlusEligible,
} from "../../services/subscriptionService";
import * as Sentry from "@sentry/react";
import {
    JSONProduct,
    useProductStore,
} from "../../components/InAppPurchaseStore";
import { filter, first } from "lodash";
import { getCurrentUsername } from "../../utils/username";
import { updateUserActionsCheckList } from "../../services/userActionsCheckListService";
import OneSignal from "onesignal-cordova-plugin";
import { isAndroid, isIos } from "../../services/platformDetection";
import { trackEvent } from "../../services/analytics/analyticsService";
import { MgmCredit } from "./mgmCredit";

enum UpgradeType {
    pro = "pro",
    plus = "plus",
}

export interface StoreProductsInterface {
    basicProd: JSONProduct | undefined;
    monthProd: JSONProduct | undefined;
    yearProd: JSONProduct | undefined;
}

export const SelectProOrPlus: FC = () => {
    const route = useIonRouter();
    const { t } = useTranslation();
    const { upgradeType } = useParams<{ upgradeType: UpgradeType }>();
    const [selectedSegment, setSelectedSegment] = useState<string>(
        upgradeType === UpgradeType.plus ? UpgradeType.plus : UpgradeType.pro
    );
    const isBasic = useIsBasic();
    const isPro = useIsPro();
    const store = CdvPurchase.store;
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [selectedSubscription, setSelectedSubscription] = useState<PlanType>(
        selectedSegment === UpgradeType.plus
            ? PlanType.basicMonth
            : PlanType.yearTrial
    );
    const subscription = useSubscription();
    const userActionsCheckList = useUserActionsCheckList();
    const products = useProductStore();

    useIonViewDidLeave(() => {
        startAds();
    });

    useIonViewWillEnter(() => {
        stopAds();
    });

    useEffect(() => {
        if (upgradeType && upgradeType === UpgradeType.plus) {
            setSelectedSegment(UpgradeType.plus);
        } else {
            setSelectedSegment(UpgradeType.pro);
        }
    }, [upgradeType]);

    useEffect(() => {
        if (
            isPro &&
            subscription &&
            !(
                checkForActiveFreeTrialSubscription(subscription.subscriptions)
                    .length > 0
            ) &&
            !developerToolsEnabled
        ) {
            route.push(routes.refsixFixtures);
        }
    }, []);

    const updateSelectedSubscription = (newSubscription: PlanType) => {
        setSelectedSubscription(newSubscription);
    };

    const developerToolsEnabled = useSelector(
        (state: RootState) => state.developerTools.enabled
    );
    const handleGoBack = () => {
        if (route.canGoBack()) {
            route.goBack();
        } else {
            route.push(routes.refsixFixtures);
        }
    };

    // Get the basic product
    const basicProd: JSONProduct | undefined = useMemo(
        () =>
            first(
                filter(products, {
                    period: "month",
                    planFeatures: "basic",
                    active: true,
                })
            ),
        [products]
    );

    // Get the month product
    const monthProd: JSONProduct | undefined = useMemo(
        () =>
            first(
                filter(products, {
                    period: "month",
                    active: true,
                    trial: false,
                })
            ),
        [products]
    );

    // Get the year trial product or year product
    // if user already been pro, use year product
    const yearProd: JSONProduct | undefined = useMemo(
        () =>
            first(
                filter(products, {
                    period: "year",
                    // switch between trial and non-trial plans d
                    trial: !checkIfBeenPro(),
                    active: true,
                })
            ),
        [products]
    );

    const startOrder = () => {
        if (!store) {
            Sentry.captureMessage(
                "Tried to order in-app purchase but no store object defined"
            );
            return;
        }

        if (!yearProd || !monthProd || !basicProd) {
            Sentry.captureMessage(
                "Tried to order in-app purchase but products not found"
            );
            return;
        }

        const isRegisterFreeTrial = subscription
            ? checkForActiveFreeTrialSubscription(subscription?.subscriptions)
                  .length > 0
            : false;

        trackEvent("UpgradeButton", {
            // Selected subscription is the plan type e.g. year, month, basic
            plan: selectedSubscription,
            // If the user is on a free trial
            isRegisterFreeTrial: isRegisterFreeTrial,
        });

        if (!userActionsCheckList.initiatedPurchase) {
            updateUserActionsCheckList({ initiatedPurchase: true });
        }
        if (isIos() || isAndroid()) {
            OneSignal.InAppMessages.addTrigger("initiatedPurchase", "true");
        }

        let product: any = yearProd;
        switch (selectedSubscription) {
            case PlanType.basicMonth:
                product = basicProd;
                break;
            case PlanType.month:
                product = monthProd;
                break;
            default:
            case PlanType.yearTrial:
                product = yearProd;
                break;
        }

        if (!product || !product.name) {
            Sentry.captureMessage(
                `Tried to order in-app purchase but no product was selected or it had no name`
            );
            return;
        }

        const productStore = store && store.get(product.name);
        const productOffers = productStore ? productStore.offers : null;
        if (productOffers) {
            store.applicationUsername = getCurrentUsername;
            store.order(productOffers[0]);
            setShowLoader(true);

            store.when().approved(() => {
                setShowLoader(false);
                route.push(routes.refsixFixtures);
            });
        }

        store.error((error: any) => {
            if (error) {
                if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
                    console.log(
                        "Purchase flow has been cancelled by user: " +
                            error.message
                    );

                    Sentry.addBreadcrumb({
                        category: "innAppPurchase",
                        message: `Purchase flow has been cancelled by user ${error.message}`,
                    });
                } else {
                    console.log("Other issue: " + error.message);

                    Sentry.addBreadcrumb({
                        category: "innAppPurchase",
                        message: `Purchase flow issue ${error.message}`,
                    });
                }
            }

            setShowLoader(false);
        });
    };
    const productsData: StoreProductsInterface = useMemo(
        () => ({
            basicProd,
            monthProd,
            yearProd,
        }),
        [basicProd, monthProd, yearProd]
    );

    return (
        <IonPage>
            <HeaderImageComponent>
                <IonButtons
                    style={{ position: "absolute", right: 10 }}
                    slot="end"
                    onClick={() => handleGoBack()}
                >
                    <IonIcon icon={closeOutline} size="large" />
                </IonButtons>
            </HeaderImageComponent>
            <IonContent className="go-pro-modal">
                <div
                    style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
                    hidden={
                        !isUserPlusEligible() ||
                        isBasic ||
                        upgradeType === "pro"
                    }
                    data-testid="upgrade-segment"
                >
                    <IonSegment
                        mode="ios"
                        value={selectedSegment}
                        onIonChange={(e) =>
                            setSelectedSegment(e.detail.value as string)
                        }
                    >
                        <IonSegmentButton
                            value={UpgradeType.plus}
                            className="segment-button"
                            data-testid="upgrade-plus-segment"
                        >
                            <IonLabel>
                                {t("upgrade.proFeaturesContent.tableHeader4")}
                            </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                            value={UpgradeType.pro}
                            className="segment-button"
                            data-testid="upgrade-pro-segment"
                        >
                            <IonLabel>
                                {t("upgrade.proFeaturesContent.tableHeader3")}
                            </IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </div>
                <UpgradeToPlusOrPro
                    plusSegmentSelected={
                        isUserPlusEligible() &&
                        selectedSegment === UpgradeType.plus
                    }
                    showLoader={showLoader}
                    selectedSubscription={selectedSubscription}
                    setSelectedSubscription={updateSelectedSubscription}
                    products={productsData}
                />

            </IonContent>
            <IonFooter>
                <div className="align-center">
                    <IonButton
                        className="ion-align-items-center"
                        onClick={startOrder}
                    >
                        {t("billing.subscribe")}
                    </IonButton>
                </div>
            </IonFooter>
        </IonPage>
    );
};
