import { find, get, last } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { JSONProduct, useIAPStoreContext } from "../InAppPurchaseStore";
import { useTranslation } from "react-i18next";
import { CurrencyFilter } from "../../utils/CurrencyFilter";
import { IonItem, IonLabel, IonRadio } from "@ionic/react";
import { PlanType } from "../../pages/refsixPlus/upgradeToPlusOrPro";

interface RenderInAppPurchaseButtonProps {
    userSelectedProduct: JSONProduct;
    buttonTitle: string;
    planType: PlanType;
}

export const InAppPurchaseComponent: FC<RenderInAppPurchaseButtonProps> = ({
    userSelectedProduct,
    buttonTitle,
    planType,
}) => {
    const { t } = useTranslation();
    const iapContext = useIAPStoreContext();
    const [productPricingPhase, setProductPricingPhase] = useState<
        CdvPurchase.PricingPhase | undefined
    >(undefined);

    const getPricingPhaseForSelectedProduct = useCallback(():
        | CdvPurchase.PricingPhase
        | undefined => {
        if (iapContext.store.products) {
            const productStore: any = find(
                iapContext.store.products,
                (product) => {
                    return product.id === userSelectedProduct.name;
                }
            );
            const productOffers = productStore ? productStore.offers : null;

            const productPricingPhases = get(
                productOffers,
                "[0].pricingPhases"
            );
            return last(productPricingPhases);
        }

        return undefined;
    }, [iapContext.store.products, userSelectedProduct]);

    useEffect(() => {
        if (iapContext.store && userSelectedProduct) {
            setProductPricingPhase(getPricingPhaseForSelectedProduct());
        }
    }, [
        iapContext.store,
        userSelectedProduct,
        getPricingPhaseForSelectedProduct,
    ]);

    const formatProductPrice = useCallback(() => {
        if (userSelectedProduct.period === "year") {
            return userSelectedProduct.trial
                ? t("upgrade.upgrade.freeTrailPriceAnnual", {
                      value: productPricingPhase
                          ? productPricingPhase.price
                          : null,
                  })
                : `${
                      productPricingPhase ? productPricingPhase.price : ""
                  } / ${t("stats.stats.year").toLowerCase()}`;
        } else if (userSelectedProduct.period === "month") {
            return userSelectedProduct.trial
                ? t("upgrade.upgrade.freeTrailPriceMonthly", {
                      value: productPricingPhase
                          ? productPricingPhase.price
                          : null,
                  })
                : `${
                      productPricingPhase ? productPricingPhase.price : ""
                  } / ${t("stats.stats.month").toLowerCase()}`;
        }
    }, [userSelectedProduct, productPricingPhase, t]);

    const calculateAnnualPriceAsMonthly = useCallback((): string => {
        if (iapContext.store && userSelectedProduct) {
            const annualProduct = iapContext.store.get(
                userSelectedProduct.name
            );
            if (!annualProduct || !productPricingPhase) {
                return "";
            }

            const annualPriceMonthly =
                productPricingPhase.priceMicros / 1000000 / 12;

            return CurrencyFilter(annualPriceMonthly);
        }

        return "";
    }, [iapContext.store, userSelectedProduct, productPricingPhase]);

    return (
        <IonItem className="" lines="none">
            <IonLabel>
                <p className="ion-text-start">
                    <b>{buttonTitle}</b>
                </p>
                <p className="ion-text-start">
                    <span>{formatProductPrice()}</span>
                </p>
                {planType === PlanType.yearTrial && (
                    <p className="ion-text-start">
                        (<span>{calculateAnnualPriceAsMonthly()}</span>{" "}
                        {t("upgrade.upgrade.perMonth")})
                    </p>
                )}
            </IonLabel>
            <IonRadio legacy={true} mode="ios" value={planType}></IonRadio>
        </IonItem>
    );
};
