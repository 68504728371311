export const SpeedCategoryConstants =
    // JSCR_HI_International_published.pdf page 2
    {
        stand: {
            min: 0,
            max: 0.17,
        },
        walk: {
            min: 0.17,
            max: 1.97,
        },
        jog: {
            min: 1.97,
            max: 3.97,
        },
        run: {
            min: 3.97,
            max: 5.47,
        },
        fastRun: {
            min: 5.47,
            max: 6.97,
        },
        sprint: {
            min: 6.97,
            max: 12,
        },
    };
