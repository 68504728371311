import {
    AnyAction,
    applyMiddleware,
    combineReducers,
    createStore,
} from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { StateType } from "typesafe-actions";
import currentUserReducer from "./reducers/currentUserReducer";
import userProfilesReducer from "./reducers/userProfilesReducer";
import { RefsixState } from "./models/refsixState";
import thunk from "redux-thunk";
import authReducer from "./reducers/authReducer";
import matchesReducer from "./reducers/matchesReducer";
import userReducer from "./reducers/userReducer";
import subscriptionsReducer from "./reducers/subscriptionsReducer";
import settingsReducer from "./reducers/settingsReducer";
import templatesReducer from "./reducers/templatesReducer";
import filterReducer from "./reducers/filtersReducer";
import { USER_LOGOUT } from "./actions/types";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import { recalculateStatsMiddleware } from "./middleware/recalculateStatsMiddleware";
import watchStatusReducer from "./reducers/watchStatusReducer";
import appStatusReducer from "./reducers/appStatusReducer";
import { startDatabaseMiddleware } from "./middleware/startDatabaseMiddleware";
import { errorMessagesReducer } from "./reducers/errorMessagesReducer";
import { developerToolsReducer } from "./reducers/developerToolsReducer";
import { messagesReducer } from "./reducers/messagesReducer";
import { pushMessagesReducer } from "./reducers/pushMessagesReducer";
import experiementsReducer from "./reducers/experiementsReducer";
import { watchStatusMiddleware } from "./middleware/watchStatusMiddleware";
import userActionsCheckListReducer from "./reducers/userActionsCheckListReducer";
import { userActionsCheckListMiddleware } from "./middleware/userActionsCheckListMiddleware";
import { trackingMiddleware } from "./middleware/trackingMiddleware";
import { capacitorStore } from "../services/capacitorStore";
import { isAndroid, isIos } from "../services/platformDetection";
import storage from "redux-persist/lib/storage";
import integrationMessageReducer from "./reducers/integrationMessageReducer";

const appReducer = combineReducers<RefsixState>({
    appStatus: appStatusReducer,
    auth: authReducer,
    matches: matchesReducer,
    profiles: userProfilesReducer,
    currentUser: currentUserReducer,
    user: userReducer,
    subscriptions: subscriptionsReducer,
    settings: settingsReducer,
    templates: templatesReducer,
    filters: filterReducer,
    watchStatus: watchStatusReducer,
    errorMessages: errorMessagesReducer,
    developerTools: developerToolsReducer,
    messages: messagesReducer,
    pushMessages: pushMessagesReducer,
    experiments: experiementsReducer,
    userActionsCheckList: userActionsCheckListReducer,
    integrationsMessages: integrationMessageReducer,
});

// reducers type
export type AppReducerType = ReturnType<typeof appReducer>;

const rootReducer = (state: AppReducerType | any, action: AnyAction) => {
    if (action.type === USER_LOGOUT) {
        const { user, appStatus } = state;

        persistConfig.storage.removeItem("persist:root");

        // We are keeping the info about users email/username on logout
        state = {
            user,
            appStatus,
        };
    }

    return appReducer(state, action);
};

const persistConfig = {
    key: "root",
    storage: isIos() || isAndroid() ? capacitorStore : storage,
    // Don't persist the matches as we have a separate database for these.
    blacklist: [
        "matches",
        "watchStatus",
        "errorMessages",
        "developerTools",
        "integrationsMessages",
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    {},
    composeWithDevToolsDevelopmentOnly(
        applyMiddleware(thunk, startDatabaseMiddleware),
        applyMiddleware(thunk, recalculateStatsMiddleware),
        applyMiddleware(thunk, watchStatusMiddleware),
        applyMiddleware(thunk, userActionsCheckListMiddleware),
        applyMiddleware(thunk, trackingMiddleware)
    )
);

export const persistor = persistStore(store);

export type RootState = StateType<typeof appReducer>;
