import { MatchPhone } from "refsix-js-models";
import { getMatchEventsInNumbers } from "../utils";

/**
 * @return Plain js object with properties to be sent to the analytics platform
 */
export function prepareBasicMatchProperties(match: MatchPhone): any {
    const matchEvent: any = {};
    matchEvent.matchId = match._id;
    matchEvent.officialRole = match.officialRole;
    matchEvent.playedExtraTime = !!match.playedExtraTime;
    matchEvent.playedPenalties = !!match.playedPenalties;
    matchEvent.competition = match.competition;
    matchEvent.homeTeam = match.homeTeam;
    matchEvent.awayTeam = match.awayTeam;
    matchEvent.misconductCodes = match.misconductCodeId;
    if (match.integration) {
        matchEvent.integrationPartner = match.integration.partner;
        matchEvent.integrationMatchId = match.integration.matchId;
    }
    if (typeof match.date !== "undefined") {
        matchEvent.kickOffDate = new Date(match.date).toISOString();
    }
    matchEvent.sinBinSystem = match.sinBinSystem;
    return matchEvent;
}

/**
 * @return Plain js object with properties to be sent to the analytics platform
 */
export function prepareMatchFinishedProperties(match, method): any {
    const matchEvent: any = prepareBasicMatchProperties(match);
    matchEvent.abandoned = match.matchAbandoned;
    matchEvent.hasGPS = match.hasTracking;
    matchEvent.hasHR = match.hasHeartRate;
    if (match.device) {
        if (match.device.watchAppVersion) {
            matchEvent.watchAppVersion = match.device.watchAppVersion;
        }
        if (match.device.os) {
            matchEvent.platform = match.device.os.name;
        }
        if (match.device.permissions) {
            matchEvent.gpsDisabled = match.device.permissions.gpsDisabled;
            matchEvent.gpsPermissionsEnabled =
                match.device.permissions.gpsPermissionsEnabled;
            matchEvent.hrPermissionsEnabled =
                match.device.permissions.hrPermissionsEnabled;
        }
        matchEvent.watchModel = match.device.model;
    }

    const matchEventsInNumbers = getMatchEventsInNumbers(match);
    matchEvent.subsRecorded = matchEventsInNumbers.subs;
    matchEvent.goals = matchEventsInNumbers.goals;
    matchEvent.yellowCards = matchEventsInNumbers.yellowCards;
    matchEvent.redCards = matchEventsInNumbers.redCards;
    matchEvent.sinBins = matchEventsInNumbers.sinBins;

    if (match.sinBinSystem) {
        matchEvent.sinBinSystem = match.sinBinSystem;
    }

    matchEvent.method = method;
    return matchEvent;
}

/**
 * @return Plain js object with properties to be sent to the analytics platform
 */
export function prepareMatchCreatedProperties(
    match: MatchPhone,
    device: string
): any {
    let kickOff: string | undefined = undefined;
    if (typeof match.date !== "undefined") {
        kickOff = new Date(match.date).toISOString();
    }
    return {
        matchId: match._id,
        kickOffDate: kickOff,
        competition: match.competition,
        homeTeam: match.homeTeam,
        awayTeam: match.awayTeam,
        device: device,
        sinBinSystem: match.sinBinSystem,
    };
}

/**
 * Mixpanel insert_id is unique for a particular user e.g. only one event with that insert_id will exist on a user's account
 * The max length is 36 chars (UUID v4 length).
 * This function attempts to squish and prefix the match id to make unique and repeatable insert_id value
 * @param prefix keep short e.g. 2-3 chars max
 * @param matchId probably a uuid v4 or shorter with - characters in it
 * @private
 */
export function makeInsertId(prefix: string, matchId: string): string {
    const insertId = matchId.replace(/-/g, "");
    return prefix + insertId;
}
