import { SET_ACTIVE_EXPERIMENTS, SET_EXPERIMENT_VARIANT } from "./types";
import { Experiment } from "../../models/Experiment";

export const setActiveExperiments = (experiments: Experiment[]) => {
    return {
        type: SET_ACTIVE_EXPERIMENTS,
        experiments,
    };
};

export const setExperimentVariant = (
    experimentId: string,
    selectedVariant: number
) => {
    return {
        type: SET_EXPERIMENT_VARIANT,
        experimentId,
        selectedVariant,
    };
};
