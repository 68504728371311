import { SET_CURRENT_USER } from "./types";
import { UserProfile } from "../../models/userProfile";

export type setCurrentUserAction = {
    type: string;
    userProfile: UserProfile;
};

export const setCurrentUser = (
    userProfile: UserProfile
): setCurrentUserAction => ({
    type: SET_CURRENT_USER,
    userProfile,
});
