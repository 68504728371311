import {
    SET_DB_UPDATED_AT,
    SET_INSTALLED_APP_VERSION,
    SET_LOGGEDIN_OR_REGISTER_COMPLETE,
    SET_PUSH_CONSENT_LAST_SHOWN,
    SET_SESSION_EXPIRED_MODAL_DISMISSED,
    SET_SHOW_WHATS_NEW,
    SHOW_ONBOARDING_FLOW,
} from "./types";

export const setLoggedInOrRegisterComplete = (complete: boolean = true) => {
    return {
        type: SET_LOGGEDIN_OR_REGISTER_COMPLETE,
        value: complete,
    };
};
export const setInstalledAppVersion = (installedVersion: string) => ({
    type: SET_INSTALLED_APP_VERSION,
    installedVersion,
});

export const setPushConsentPromptLastShown = (
    pushConsentPromptLastShown: number
) => ({
    type: SET_PUSH_CONSENT_LAST_SHOWN,
    pushConsentPromptLastShown: pushConsentPromptLastShown,
});

export const setShowOnBoardingFlow = (show: boolean) => ({
    type: SHOW_ONBOARDING_FLOW,
    value: show,
});

export const setShowWhatsNew = (show: boolean) => ({
    type: SET_SHOW_WHATS_NEW,
    value: show,
});

export const setSessionExpiredModalDismissed = (value: boolean) => ({
    type: SET_SESSION_EXPIRED_MODAL_DISMISSED,
    value,
});

export const setDbUpdatedAt = (value: number) => ({
    type: SET_DB_UPDATED_AT,
    value,
});
