import request from "../request";

interface watchesInfoModel {
    hasWearOs: boolean;
    hasTizen: boolean;
    hasFitbit: boolean;
    hasGarmin: boolean;
    hasAppleWatch: boolean;
}

export function setSession(code: string) {
    return request.post("/watch-login/set-session", { publicCode: code });
}

export function unpairWatch(
    sessionId: string,
    platform: string,
    watchModel: string,
    watchesInfo: watchesInfoModel
) {
    return request.post("/watch-login/unpair-watch", {
        sessionId: sessionId,
        platform: platform,
        watchModel: watchModel,
        watchesInfo: watchesInfo,
    });
}
