import shortUUID from "short-uuid";
import {MessageTypes} from "../messageTypesEnum";
import {generateWatchSession} from "../../sessionRefresh";

// [Log] token request – {authTokenCreation: 1669980062163, authTokenExpiry: 1685532062163, authToken: "I9hb5vp8SauQ0IOnhS8_rQ:qyzf2D5STQ-44Y9zQIUS0w"} (vendors~main.chunk.js, line 124623)

export async function tokenMessage(request: any) {
    const watchToken = await generateWatchSession();
    const id = shortUUID().uuid();
    return {
        requestId: request.id,
        messageId: MessageTypes.TOKEN_RESPONSE,
        id: id, // watchOS expecting this
        _id: id, // wearOS is expecting this
        token: watchToken,
    };
}
