import { FC, useEffect, useState } from "react";
import {
    IonCard,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { HeaderComponent } from "../../../../components";
import { useTranslation } from "react-i18next";
import {
    Controller,
    FieldValues,
    FormProvider,
    useForm,
} from "react-hook-form";
import {
    getSubscriptions,
    refreshSubscriptions,
} from "../../../../services/subscriptionService";
import { IntegrationPartner, SubscriptionItems } from "refsix-js-models";
import { find } from "lodash";
import {
    handleFixturesFromDBU,
    saveStackSportsUser,
    stackSportsLogUserIn,
    syncStackSportsMatches,
} from "../../../../services/integrationService";
import { routes } from "../../../../route/constants";
import { trackEvent } from "../../../../services/analytics/analyticsService";

interface StackSportsAttemptToLoginUser {
    ChallengeParameters: {};
    AuthenticationResult: {
        AccessToken: string;
        ExpiresIn: number;
        TokenType: string;
        RefreshToken: string;
        IdToken: string;
    };
}

const StackSportsIntegration: FC = () => {
    const { t } = useTranslation();
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const route = useIonRouter();
    const [displayUpdatesAlert] = useIonAlert();

    const formMethods = useForm<FieldValues>({
        mode: "all",
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const {
        formState: { errors },
        handleSubmit,
        reset,
    } = formMethods;

    useEffect(() => {
        getSubscriptions()
            .then((res) => {
                if (res) {
                    getSubsCredentials(res.subscriptions);
                }
            })
            .catch((error) => {
                console.log(
                    "Error trying to get subscriptions for stack sports",
                    error
                );
            });
    }, []);

    const getSubsCredentials = (subscriptions: SubscriptionItems[]) => {
        const rawData = find(subscriptions, [
            "provider",
            "stacksports",
        ])?.rawData;

        if (rawData) {
            reset((formValues) => ({
                ...formValues,
                email: rawData.email,
            }));
        }
    };

    const handleSyncStackSports = async (data: any) => {
        console.log("data", data);
        if (inputsDisabled) {
            setInputsDisabled(false);

            return;
        }

        setLoading(true);
        setInputsDisabled(true);

        try {
            const logUserInResponse = await stackSportsLogUserIn(
                data.email,
                data.password
            );

            console.log("logUserInResponse", logUserInResponse);

            // We can safely assert that this will be the type we get back from StackSports
            const loginData =
                logUserInResponse.data as StackSportsAttemptToLoginUser;

            if (loginData) {
                const authData = loginData.AuthenticationResult;
                await saveUser(data.email, authData.AccessToken);
            }
        } catch (e) {
            setLoading(false);
            await generalError();
        }
    };

    const saveUser = async (email: string, accessToken: string) => {
        try {
            const saveUserResponse = await saveStackSportsUser(
                email,
                accessToken
            );
            if (saveUserResponse) {
                await refreshSubscriptions();
                await downloadMatches();
            }
        } catch (e) {
            console.log("error trying to save stack sports user", e);
            setLoading(false);
            await generalError();
        }
    };

    const handleUpdateMatches = async (data: any) => {
        const updates = handleFixturesFromDBU(data);
        if (updates) {
            await displayUpdatesAlert({
                header: t("integrations.matches.updatesTitle"),
                message: `${t("integrations.matches.matchesAdded")} ${
                    updates.added.length
                } <br>
                    ${t("integrations.matches.matchesDeleted")} ${
                    updates.deleted.length
                }
                                        <br>
                    ${t("integrations.matches.matchesUpdated")} ${
                    updates.updated.length
                }`,
                buttons: [
                    {
                        text: t("general.cancel"),
                    },
                    {
                        handler: () => {
                            route.push(routes.refsixFixtures, "root");
                        },
                        id: "confirm",
                        text: t("help.OnboardingVideo.controls.ok"),
                    },
                ],
            });
            setLoading(false);
        }
    };

    const downloadMatches = async () => {
        try {
            const downloadMatchesResponse = await syncStackSportsMatches();
            if (downloadMatchesResponse) {
                await handleUpdateMatches(downloadMatchesResponse.data);
                trackEvent("AddIntegrationSuccess", {
                    partner: IntegrationPartner.stack,
                });
            }
        } catch (e) {
            setLoading(false);
            trackEvent("AddIntegrationFailed", {
                partner: IntegrationPartner.stack,
            });
            console.log("error trying to download matches", e);
            await generalError();
        }
    };

    const generalError = async () => {
        await displayUpdatesAlert({
            header: t("integrations.integrationsFailure.title"),
            message: t("general.errorTryAgain"),
            buttons: [
                {
                    handler: () => {},
                    id: "confirm",
                    text: t("help.OnboardingVideo.controls.ok"),
                },
            ],
        });
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={"/"}
                title={t("integrations.stackSports.title")}
                titleTestId="stack-sports-integrations-page-header"
                headerEndText={
                    inputsDisabled ? t("general.edit") : t("general.save")
                }
                onClickEndButton={
                    inputsDisabled
                        ? () => setInputsDisabled(false)
                        : handleSubmit(handleSyncStackSports)
                }
                endBtnTestId="stack-sports-btn-save"
            />
            <IonContent>
                <IonLoading isOpen={loading} />
                <IonCard color="transparent">
                    <FormProvider {...formMethods}>
                        <form onSubmit={handleSubmit(handleSyncStackSports)}>
                            <IonItem
                                className={
                                    errors && !inputsDisabled && errors["email"]
                                        ? "item-title danger"
                                        : "item-title"
                                }
                                lines="full"
                            >
                                <IonLabel>
                                    {t("register.register.email")}
                                </IonLabel>
                                <Controller
                                    name="email"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, ...rest },
                                    }) => (
                                        <IonInput
                                            placeholder={t(
                                                "register.register.email"
                                            )}
                                            disabled={inputsDisabled}
                                            onIonChange={onChange}
                                            autocapitalize="off"
                                            type="email"
                                            {...rest}
                                        />
                                    )}
                                />
                            </IonItem>
                            <IonItem
                                className={
                                    errors &&
                                    !inputsDisabled &&
                                    errors["password"]
                                        ? "item-title danger"
                                        : "item-title"
                                }
                            >
                                <IonLabel>
                                    {t("register.register.password")}
                                </IonLabel>
                                <Controller
                                    name="password"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onBlur, onChange, ...rest },
                                    }) => (
                                        <IonInput
                                            placeholder={t(
                                                "register.register.password"
                                            )}
                                            disabled={inputsDisabled}
                                            onIonChange={onChange}
                                            {...rest}
                                        />
                                    )}
                                />
                            </IonItem>
                        </form>
                    </FormProvider>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default StackSportsIntegration;
