import { IonCol, IonItem, IonText, useIonRouter } from "@ionic/react";
import { t } from "i18next";
import { FC } from "react";
import { dummyDate, dummyHomeVsAway } from "../services/dummyDataService";
import { routes } from "../route/constants";

export const LIST_OF_TOP_DATE_FORMAT = "L";

interface ListOfTopComponentProps {
    matchId?: string;
    stat?: string | number;
    teamNames?: string;
    date?: string;
    testId?: string;
    useDummyData?: boolean;
    valueSize?: "small" | "medium" | "large";
}

const ListOfTopComponent: FC<ListOfTopComponentProps> = ({
    matchId,
    stat,
    teamNames,
    date,
    testId,
    useDummyData,
    valueSize = "small",
}) => {
    if (useDummyData) {
        teamNames = dummyHomeVsAway(t);
        date = dummyDate;
    }

    const route = useIonRouter();

    function matchClicked() {
        route.push(`${routes.match}/${matchId}/overview`, "forward");
    }

    let valueWidth: string;
    switch (valueSize) {
        default:
        case "small":
            valueWidth = "1";
            break;
        case "medium":
            valueWidth = "2";
            break;
        case "large":
            valueWidth = "2.5";
            break;
    }

    return (
        <IonItem
            button
            lines="full"
            className="top-matches-container"
            onClick={() => matchClicked()}
            data-testid={testId}
        >
            <IonCol size={valueWidth} className="text-small">
                <IonText color="primary">{stat}</IonText>
            </IonCol>
            <IonCol
                size={String(8.9 - Number(valueWidth))}
                className="text-small"
            >
                <IonText>{teamNames}</IonText>
            </IonCol>
            <IonCol size="3.1" className="text-small align-right">
                <IonText>{date}</IonText>
            </IonCol>
        </IonItem>
    );
};

export default ListOfTopComponent;
