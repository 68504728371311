import {
    LOADING_MATCHES,
    SELECTED_MATCH,
    SET_CLOSED_DATES,
    SET_MATCHES,
    SET_OPEN_DATES,
} from "../actions/types";
import { Matches } from "../models/refsixState";

const initialState = new Matches();

function matchesReducer(state: Matches = initialState, action: any) {
    switch (action.type) {
        case SET_MATCHES:
            return { ...state, matches: action.matches };
        case SELECTED_MATCH:
            return { ...state, match: action.match };
        case LOADING_MATCHES:
            return { ...state, loading: action.loading };
        case SET_CLOSED_DATES:
            return { ...state, closedDates: action.closedDates };
        case SET_OPEN_DATES:
            return { ...state, openDates: action.openDates };
        default:
            return state;
    }
}

export default matchesReducer;
