import {
    IonCard,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DatePreset, FiltersModel } from "../../redux/models/filtersModel";
import { RootState } from "../../redux/store";
import { MatchType } from "./enums";

interface FiltersDatePickerProps {
    matchType: MatchType;
    filters: FiltersModel;
    updateFilter: (props: Partial<FiltersModel>) => void;
}

export function FiltersDatePicker({
    matchType,
    filters,
    updateFilter,
}: FiltersDatePickerProps) {
    const { t } = useTranslation();

    const { startDate, endDate, datePreset } = filters;
    const seasonDate = useSelector(
        (state: RootState) => state.settings.settings.seasonDate
    );

    useEffect(() => {
        if (!datePreset) {
            if (matchType === MatchType.Fixture) {
                updateFilter({ datePreset: DatePreset.Custom });
            } else {
                updateFilter({ datePreset: DatePreset.Career });
            }
        }
    }, []);

    function handleDatePresetChange(value: DatePreset) {
        switch (value) {
            case DatePreset.Career:
                updateFilter({
                    startDate: moment(0).toISOString(),
                    endDate: moment().endOf("day").toISOString(),
                    datePreset: value,
                });
                break;
            case DatePreset.Season:
                updateFilter({
                    startDate: moment(seasonDate).toISOString(),
                    endDate: moment().endOf("day").toISOString(),
                    datePreset: value,
                });
                break;
            case DatePreset.Year:
                updateFilter({
                    startDate: moment().startOf("year").toISOString(),
                    endDate: moment().endOf("year").toISOString(),
                    datePreset: value,
                });
                break;
            case DatePreset.Month:
                updateFilter({
                    startDate: moment().startOf("month").toISOString(),
                    endDate: moment().endOf("month").toISOString(),
                    datePreset: value,
                });
                break;
            case DatePreset.Custom:
                updateFilter({
                    startDate: moment().subtract(1, "day").toISOString(),
                    endDate: moment().toISOString(),
                    datePreset: value,
                });
                break;
        }
    }

    return (
        <>
            {/* For a result, allow the user to choose a date preset */}
            {(matchType === MatchType.Result ||
                matchType === MatchType.Trends) && (
                <IonCard>
                    <IonItem lines="full">
                        <IonLabel>{t("stats.stats.Filters.date")}</IonLabel>
                    </IonItem>
                    <IonItem className="item-title" lines="none">
                        <IonLabel className="create-match-official-role">
                            {t("overview.options")}
                        </IonLabel>
                        <IonSelect
                            data-testid="date-preset-select"
                            className="select-text"
                            value={datePreset}
                            placeholder={datePreset}
                            onIonChange={(e) =>
                                handleDatePresetChange(
                                    e.detail.value as DatePreset
                                )
                            }
                            interface="popover"
                        >
                            <IonSelectOption value={DatePreset.Career}>
                                {t("stats.stats.career")}
                            </IonSelectOption>
                            )
                            <IonSelectOption value={DatePreset.Season}>
                                {t("stats.stats.season")}
                            </IonSelectOption>
                            )
                            <IonSelectOption value={DatePreset.Year}>
                                {t("stats.stats.year")}
                            </IonSelectOption>
                            )
                            <IonSelectOption value={DatePreset.Month}>
                                {t("stats.stats.month")}
                            </IonSelectOption>
                            )
                            <IonSelectOption value={DatePreset.Custom}>
                                {t("general.custom")}
                            </IonSelectOption>
                            )
                        </IonSelect>
                    </IonItem>
                </IonCard>
            )}

            <IonCard>
                <IonItem lines="full">
                    <IonLabel>{t("stats.stats.Filters.dateRange")}</IonLabel>
                </IonItem>
                <IonItem
                    disabled={datePreset !== DatePreset.Custom}
                    className="item-title"
                >
                    <IonLabel className="inner-label">
                        {t("stats.stats.Filters.from")}
                    </IonLabel>
                    <input
                        data-testid="filter-from-date"
                        className="date-input"
                        type="date"
                        value={
                            datePreset === DatePreset.Career
                                ? "∞"
                                : moment(startDate).format("YYYY-MM-DD")
                        }
                        max={
                            endDate !== ""
                                ? moment(endDate).format("YYYY-MM-DD")
                                : moment().add(10, "years").format("YYYY-MM-DD")
                        }
                        onChange={(e) =>
                            updateFilter({
                                startDate: moment(
                                    e.currentTarget.value
                                ).toISOString(),
                            })
                        }
                    />
                </IonItem>
                <IonItem
                    disabled={datePreset !== DatePreset.Custom}
                    className="item-title"
                >
                    <IonLabel className="inner-label">
                        {t("stats.stats.Filters.to")}
                    </IonLabel>
                    <input
                        data-testid="filter-to-date"
                        className="date-input"
                        type="date"
                        value={moment(endDate).format("YYYY-MM-DD")}
                        min={
                            startDate !== ""
                                ? moment(startDate).format("YYYY-MM-DD")
                                : moment().format("YYYY-MM-DD")
                        }
                        onChange={(e) =>
                            updateFilter({
                                endDate: moment(
                                    e.currentTarget.value
                                ).toISOString(),
                            })
                        }
                    />
                </IonItem>
            </IonCard>
        </>
    );
}
