import { Experiments } from "../models/refsixState";
import {
    SET_ACTIVE_EXPERIMENTS,
    SET_EXPERIMENT_VARIANT,
} from "../actions/types";
import builtInExperiments from "../../experiements.json";
import { Experiment } from "../../models/Experiment";

const initialState: Experiments = {
    activeExperiments: builtInExperiments as Experiment[],
    selectedVariants: {},
};

function experiementsReducer(state: Experiments = initialState, action: any) {
    state = state || initialState;

    switch (action.type) {
        case SET_ACTIVE_EXPERIMENTS:
            return { ...state, activeExperiments: action.experiments };

        case SET_EXPERIMENT_VARIANT:
            return {
                ...state,
                selectedVariants: {
                    ...state.selectedVariants,
                    [action.experimentId]: action.selectedVariant,
                },
            };
        default:
            return state;
    }
}

export default experiementsReducer;
