import { Observable, Observer, Subscription } from "rxjs";
import {
    EventName,
    FitnessDataType,
    GoalEvent,
    GpsData,
    HalfEvent,
    HrData,
    MatchData,
    MatchPhone,
    OfficialRole,
    Player,
    RefAssistAvailability,
    SelectedTeam,
    SinBinSystem,
    Stats,
    TeamPackData,
    Timings,
} from "refsix-js-models";
import { RefereeDatabaseService } from "./database/refereeDatabase.service";
import { store } from "../redux/store";
import { setLoadingMatches, setMatches } from "../redux/actions/matches";
import { setSettings } from "../redux/actions/settings";
import { setTemplates } from "../redux/actions/templates";
import { IDatabaseService } from "./database/iDatabaseService";
import { trackConflictResolve, trackEvent } from "./analytics/analyticsService";
import {
    copyObject,
    generatePlayersForTeamFromTeamPack,
    getFixtureResultCount,
    hasTeam,
    metersToKmOrMiles,
    periodStructure,
    processGPS,
    processHR,
    processMatch,
    reduceMatchStats,
    resolveMatchConflicts,
} from "refsix-core";
import { createDefaultTeamOfficials } from "./TeamOfficialsService";
import {
    FiltersModel,
    FiltersResultsModel,
} from "../redux/models/filtersModel";
import _, {
    compact,
    flatten,
    forEach,
    groupBy,
    map,
    reduce,
    sortBy,
    times,
    uniq,
    words,
    zip,
} from "lodash";
import { isNull, omitBy } from "lodash/fp";
import { getFitnessData } from "./fitnessDataSync/fitnessDataSyncService";
import moment, { Moment } from "moment";
import { MatchType } from "../pages/filters/enums";
import { UserProfile } from "../models/userProfile";
import { ProcessedDocs } from "./database/pouchUtils";
import { buildMatchForWatch } from "refsix-core";
import { hasFeatureAccess } from "./subscriptionService";
import * as Sentry from "@sentry/react";
import { mpUpdatePerson } from "./analytics/mixpanelService";
import { MatchOrAvailability } from "../pages/tabs/fixtures";
import { ComputedHalfInfo } from "../models/ComputedHalfInfo";
import { setUserActionsCheckList } from "../redux/actions/userActionsCheckList";
import { setDbUpdatedAt } from "../redux/actions/appStatus";
import { isIos } from "./platformDetection";

export enum PeriodsType {
    Halves = 2,
    Thirds = 3,
    Quarters = 4,
}

export interface IndexedMap {
    [index: number]: ComputedHalfInfo;
}

const DATABASE_NOT_INITIALISED = "Database not initialised";
const OFFICIAL_ROLES = [
    "referee",
    "assistant1",
    "assistant2",
    "fourthOfficial",
    "observer",
];

const GPS_MISSING_MESSAGE =
    "GPS data not found in S3, marking hasTracking to false";
const HR_MISSING_MESSAGE =
    "Heart rate data not found in S3, marking hasHeartRate to false";

const HR_NOT_PROCESSED_ERR = "HR didn't process";
const GPS_NOT_PROCESSED_ERR = "GPS didn't process";

function loadConfigsFromDatabase(
    refereeDatabaseService: IDatabaseService
): void {
    refereeDatabaseService.getAllUserDocs().then((config: ProcessedDocs) => {
        store.dispatch(setMatches(config.matches));
        store.dispatch(setSettings(config.settings));
        if (config.templates) {
            store.dispatch(setTemplates(config.templates));
        }
        if (config.userActionsCheckList) {
            store.dispatch(
                setUserActionsCheckList(
                    config.userActionsCheckList.userActionsCheckList
                )
            );
        }

        if (store.getState().matches.loading) {
            store.dispatch(setLoadingMatches(false));
        }
    });
}

let databaseService: IDatabaseService | undefined = undefined;

/**
 * For testing only. Use startDatabase() in real applications.
 */
export function _setDatabaseService(dbService: IDatabaseService) {
    console.log("For testing only. Use startDatabase() in real applications.");
    databaseService = dbService;
}

export function getDatabaseService() {
    return databaseService;
}

export async function destroyDatabaseService() {
    if (databaseService) {
        const dbService = databaseService;
        databaseService = undefined;
        return dbService.destroySequence();
    }
    return Promise.resolve();
}

// TODO setting, template

export function startDatabase(isNewLogin: boolean) {
    if (databaseService) {
        console.log("Database already started");
        return;
    }
    // show app-wide loading spinner
    store.dispatch(setLoadingMatches(true));
    const session = store.getState().auth.session;

    if (!session || !session.userDBs || !session.userDBs.supertest) {
        console.log("User is missing a database url on their session", session);
        return;
    }

    //console.log(store.getState().auth.session?.userDBs.supertest);
    let userDatabaseUrl = store.getState().auth.session?.userDBs.supertest;
    //userDatabaseUrl = "http://admin:testing@0.0.0.0:5984/";

    let databaseStream: Observable<any>;
    let databaseSubscription: Subscription;
    databaseService = new RefereeDatabaseService();

    databaseStream = databaseService.getStream();

    let databaseObserver: Observer<any> = {
        next: (value) => {
            store.dispatch(setDbUpdatedAt(moment().valueOf()));
            if (!databaseService) {
                console.error("Database server not defined");
                return;
            }
            loadConfigsFromDatabase(databaseService);
        },
        error: (error) => console.log(`The error was ${error}`), // TODO not sure what to do
        complete: () => console.log("The subscription has completed"), // TODO not sure what to do
    };

    databaseSubscription = databaseStream.subscribe(databaseObserver);

    databaseService.startSequence(userDatabaseUrl, isNewLogin);
}
const _isResult = (match: MatchPhone) => match.matchFinished;
const _isMatch = (match: MatchPhone) => !match.matchFinished;
export function filterResults(matches: MatchPhone[]): MatchPhone[] {
    let filteredMatches = matches.filter(_isResult);

    return matchesSortedByDate(filteredMatches).reverse();
}

export function filterMatches(matches: MatchPhone[]): MatchPhone[] {
    let filteredMatches = matches.filter(_isMatch);

    return matchesSortedByDate(filteredMatches);
}

export function allMatches(matches: MatchPhone[]): MatchPhone[] {
    return matchesSortedByDate(matches);
}

export function matchesSortedByDate(matches: MatchPhone[]): MatchPhone[] {
    return matches.sort(
        (matchA, matchB) =>
            new Date(matchA.date as string).getTime() -
            new Date(matchB.date as string).getTime()
    );
}

export function getCompetitions(matches: MatchPhone[]) {
    return uniq(
        matches.map(function (match) {
            return match.competition || "";
        })
    );
}

export function getVenues(matches: MatchPhone[]) {
    return uniq(
        matches.map(function (match) {
            return match.venue || "";
        })
    );
}

export function getHomeTeams(matches: MatchPhone[]) {
    return uniq(
        matches.map(function (match) {
            return match.homeTeam;
        })
    );
}

export function getAwayTeams(matches: MatchPhone[]) {
    return uniq(
        matches.map(function (match) {
            return match.awayTeam;
        })
    );
}

export function getMatchOfficials(matches: MatchPhone[]) {
    let allReferees = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.referee
                ? match.matchOfficials?.referee
                : "";
        })
    );
    let allAssistant1 = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.assistant1
                ? match.matchOfficials?.assistant1
                : "";
        })
    );
    let allAssistant2 = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.assistant2
                ? match.matchOfficials?.assistant2
                : "";
        })
    );
    let allFourthOfficials = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.fourthOfficial
                ? match.matchOfficials?.fourthOfficial
                : "";
        })
    );
    let allObservers = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.observer
                ? match.matchOfficials?.observer
                : "";
        })
    );

    return [
        ...allReferees,
        ...allAssistant1,
        ...allAssistant2,
        ...allFourthOfficials,
        ...allObservers,
    ].filter(function (el) {
        return el !== "" && el !== "currentUser";
    });
}

export function getFilteredResults(
    filters: FiltersModel,
    matches: MatchPhone[]
) {
    return filterResults(matches).filter(function (match: MatchPhone) {
        return matchesFilter(
            match,
            MatchType.Result,
            datePresetChange(filters)
        );
    });
}

function datePresetChange(filters: FiltersModel): FiltersModel {
    switch (filters.datePreset) {
        case "custom":
            return filters;
        case "career":
            filters.startDate = moment(0).toISOString();
            filters.endDate = moment().endOf("day").toISOString();
            return filters;
        case "season":
            filters.startDate = moment(
                store.getState().settings.settings.seasonDate
            ).toISOString();
            filters.endDate = moment().endOf("day").toISOString();
            return filters;
        case "month":
            filters.startDate = moment().startOf("month").toISOString();
            filters.endDate = moment().endOf("month").toISOString();
            return filters;
        case "year":
            filters.startDate = moment().startOf("year").toISOString();
            filters.endDate = moment().endOf("year").toISOString();
            return filters;
        default:
            return filters;
    }
}

export function getFilteredMatches(
    filters: FiltersModel,
    matches: MatchPhone[]
) {
    return filterMatches(matches).filter(function (match) {
        return matchesFilter(match, MatchType.Fixture, filters);
    });
}

export async function getMatchById(matchId: string) {
    return databaseService?.getDocById(matchId);
}

export function checkKeywordsInFixture(
    fixture: MatchPhone,
    keywords: string[]
) {
    if (fixture.keywords) {
        // We currently return once a single match is found.
        // To make this match strictly this should be replaced with a reduce/forEach.
        for (let index = 0; index < fixture.keywords.length; index++) {
            if (keywords.includes(fixture.keywords[index])) {
                return true;
            }
        }
    }
    return false;
}

export function checkItemInFixture<T>(
    match: MatchPhone,
    items: T[],
    itemKey: keyof MatchPhone
): boolean {
    if (match[itemKey]) {
        for (let index = 0; index < items.length; index++) {
            if (items.includes(match[itemKey])) {
                return true;
            }
        }
    }
    return false;
}

export function checkOfficialsInFixture(
    match: MatchPhone,
    officials: string[]
) {
    if (match.matchOfficials) {
        // We currently return once a single match is found.
        // To make this match strictly this should be replaced with a reduce/forEach.

        for (let index = 0; index < officials.length; index++) {
            let referee = match.matchOfficials.referee || "";
            let assistant1 = match.matchOfficials.assistant1 || "";
            let assistant2 = match.matchOfficials.assistant2 || "";
            let fourthOfficial = match.matchOfficials.fourthOfficial || "";
            let observer = match.matchOfficials.observer || "";

            if (
                officials.indexOf(referee) > -1 ||
                officials.indexOf(assistant1) > -1 ||
                officials.indexOf(assistant2) > -1 ||
                officials.indexOf(fourthOfficial) > -1 ||
                officials.indexOf(observer) > -1
            ) {
                return true;
            }
        }
    }
    return false;
}

export function checkTeamsInFixture(match: MatchPhone, teams: string[]) {
    if (match.homeTeam || match.awayTeam) {
        // We currently return once a single match is found.
        // To make this match strictly this should be replaced with a reduce/forEach.
        for (let index = 0; index < teams.length; index++) {
            if (
                teams.includes(match.homeTeam) ||
                teams.includes(match.awayTeam)
            ) {
                return true;
            }
        }
    }
    return false;
}

function matchesFilter(
    match: MatchPhone,
    matchType: MatchType,
    filters: FiltersModel
): boolean {
    let role = match.officialRole || OfficialRole.referee;
    let filtersRole: any;

    if (filters && filters.roles !== undefined) {
        filtersRole = filters.roles as any;
    }

    if (filters.competition) {
        if (
            filters.competition.length > 0 &&
            !checkItemInFixture(match, filters.competition, "competition")
        ) {
            return false;
        }
    }

    if (filters.venue) {
        if (
            filters.venue.length > 0 &&
            !checkItemInFixture(match, filters.venue, "venue")
        ) {
            return false;
        }
    }

    if (
        match.homeTeam !== undefined &&
        match.homeTeam !== "" &&
        match.awayTeam !== undefined &&
        match.awayTeam !== ""
    ) {
        if (
            filters.team.length > 0 &&
            !checkTeamsInFixture(match, filters.team)
        ) {
            return false;
        }
    }

    if (filters.officials !== undefined) {
        if (
            filters.officials.length > 0 &&
            !checkOfficialsInFixture(match, filters.officials)
        ) {
            return false;
        }
    }

    if (filtersRole !== undefined) {
        if (!filtersRole[role]) {
            return false;
        }

        // @ts-ignore
        // TODO userid is missing in MatchPhone
        //if (filtersRole.referee && match.userId !== filtersRole.referee) {
        //  return false;
        // }
    }

    if (match.date !== undefined) {
        let matchDate = new Date(match.date).getTime();
        if (
            filters.startDate &&
            (matchDate < moment(filters.startDate).valueOf() ||
                (filters.endDate &&
                    matchDate > moment(filters.endDate).valueOf()))
        ) {
            return false;
        }
    }

    if (filters.keywords !== undefined) {
        if (
            filters.keywords.length > 0 &&
            !checkKeywordsInFixture(match, filters.keywords)
        ) {
            return false;
        }
    }

    if (!match.stats) {
        return true;
    }

    if (matchType === MatchType.Result) {
        const resultFilters = filters as FiltersResultsModel;
        if (resultFilters.stats !== undefined) {
            if (
                resultFilters.stats.yellowCards.value >
                match.stats.yellowCardTotal
            ) {
                return false;
            }
            if (resultFilters.stats.redCards.value > match.stats.redCardTotal) {
                return false;
            }
            if (resultFilters.stats.goals.value > match.stats.goalsTotal) {
                return false;
            }
        }

        if (resultFilters.penalties && match.stats.playedPenalties[0] === 0) {
            return false;
        }

        if (resultFilters.extraTime && match.stats.playedET[0] === 0) {
            return false;
        }

        if (resultFilters.abandoned && !match.matchAbandoned) {
            return false;
        }
    }

    return true;
}

function _incrementUpdateCount(match: MatchPhone) {
    // TODO remove losing property
    match.refsixCount = match.refsixUpdateCount = match.refsixUpdateCount
        ? match.refsixUpdateCount + 1
        : 1;
    return match;
}

interface PouchUpdateResult {
    rev: string;
}

export function createDefaultMatch(): MatchPhone {
    return {
        competition: "",
        venue: "",
        periodsNo: "2",
        teamSize: undefined,
        subsNo: undefined,
        officialRole: OfficialRole.referee,
        date: moment().startOf("hour").add(1, "hour").toISOString(),
        homeTeam: "",
        awayTeam: "",
        homeTeamShort: "",
        awayTeamShort: "",
        homeColor: "",
        awayColor: "",
        extraTimeAvailable: false,
        penaltiesAvailable: false,
        withGoalScorers: false,
        sinBinSystem: SinBinSystem.none,
        misconductCodeId: "fifa",
        refsixCount: 0,
        refsixUpdateCount: 0,
        matchFinished: false,
        eventsProcessed: false,
        results: {},
        timings: undefined,
    };
}

export function getRefereeRolesToRecord(roleToRemove: string) {
    roleToRemove === OfficialRole.assistant && (roleToRemove = "assistant1");
    return OFFICIAL_ROLES.filter(function (item) {
        return item !== roleToRemove;
    });
}

async function _updateCompleted(
    newFixture: MatchPhone,
    doc: PouchUpdateResult
) {
    newFixture._rev = doc.rev;
    return newFixture;
}

export function addTeamOfficials(
    players: { [key: string]: Player[] },
    homeName: string,
    awayName: string
) {
    players[homeName] = addTeamOfficialsToPlayers(players, homeName);
    players[awayName] = addTeamOfficialsToPlayers(players, awayName);
    return players;
}

export function addTeamOfficialsToPlayers(
    players: { [key: string]: Player[] },
    teamName: string
) {
    let hasTeamOfficials = checkPlayersForTeamOfficials(players[teamName]);

    if (!hasTeamOfficials) {
        players[teamName] = updatePlayers(
            players[teamName],
            createDefaultTeamOfficials()
        );
    }

    return players[teamName];
}

export function checkPlayersForTeamOfficials(players: Player[]): boolean {
    if (!players) {
        return false;
    }

    let hasTeamOfficial = false;

    for (let i = 0; i < players.length; i++) {
        if (players[i].teamOfficial) {
            hasTeamOfficial = true;
            break;
        }
    }
    return hasTeamOfficial;
}

export function updatePlayers(
    teamPlayers: Player[],
    teamOfficials: Player[]
): Player[] {
    let players = teamPlayers || [];
    return players.concat(teamOfficials);
}

export function getKeywords(matches: MatchPhone[]): string[] {
    let keywordMap = {} as any;

    matches.forEach(function (fixture) {
        if (fixture.keywords) {
            fixture.keywords.forEach(function (keyword) {
                keywordMap[keyword] = true;
            });
        }
    });
    return Object.keys(keywordMap);
}

export function keywordInSelectedKeywords(
    keyword: string,
    selectedKeywords: string[]
): boolean {
    return selectedKeywords.indexOf(keyword) >= 0;
}

export function triggerKeyword(
    keyword: string,
    selectedKeywords: string[]
): boolean {
    if (selectedKeywords.indexOf(keyword) >= 0) {
        let keywordIndex = selectedKeywords.indexOf(keyword);
        if (keywordIndex >= 0) {
            return true;
        }
    }
    return false;
}

export function keywordInAllKeywords(
    keyword: string,
    userKeywords: string[]
): boolean {
    return userKeywords.indexOf(keyword) >= 0;
}

export function addKeyWord(
    keyword: string,
    userKeywords: string[]
): boolean | undefined {
    if (!keywordInAllKeywords(keyword, userKeywords) && keyword !== "") {
        return true;
    } else if (keywordInAllKeywords(keyword, userKeywords)) {
        console.log("keyword already in keywords");
        return false;
    }
}

// TODO i don't think this belongs here.
export function onKeyPress(event: any): boolean {
    return event.key === "Enter";
}

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
    );
}

export async function duplicateMatch(match: MatchPhone) {
    const newMatch = copyObject(match);
    delete newMatch._id;
    delete newMatch._rev;
    delete newMatch.teamSheetRequests;

    try {
        await updateMatch(newMatch);
        mpUpdatePerson(getFixtureResultCount(store.getState().matches.matches));

        // TODO: Add fixture to calendar
    } catch (err) {
        Sentry.captureMessage(
            `Error duplicating match: ${JSON.stringify(err)}`
        );
        console.log("Error duplicating match: ", { err });
        throw err;
    }
}

export async function setMatchFlag(
    matchId: string,
    key: string,
    value: boolean
) {
    const match = await getMatchById(matchId);
    if (!match) {
        throw "Match not found";
    }
    return updateMatch({ ...(match as MatchPhone), [key]: value });
}

export function getPlayersFromTeamPack(
    teamName: string,
    downloadedPacks: TeamPackData[]
): Player[] {
    return generatePlayersForTeamFromTeamPack(
        downloadedPacks,
        teamName,
        store.getState().settings.settings.hide13
    );
}

export function trackHighRevisionMatchInSentry(
    err: any,
    newMatch: MatchPhone,
    revisionCount: number
) {
    Sentry.setContext("highRevMatch", {
        id: newMatch._id,
        revCount: revisionCount,
        rev: newMatch._rev,
        matchFinished: newMatch.matchFinished,
        hasTracking: newMatch.hasTracking,
        hasHeartRate: newMatch.hasHeartRate,
        hasStats: !!newMatch.stats,
        statsVersion: newMatch.stats?.version,
        statsGpsProcessed: newMatch.stats?.gpsProcessed,
        statsHeartRateProcessed: newMatch.stats?.heartRateProcessed,
        stack: err.stack,
    });
    Sentry.captureException(err);
}

export async function updateMatch(newMatch: MatchPhone) {
    newMatch._id = newMatch._id || guid();

    if (!databaseService) {
        return Promise.reject(DATABASE_NOT_INITIALISED);
    }
    _incrementUpdateCount(newMatch);

    // TODO to be deleted when we figure out the revisions bug
    if (newMatch._rev && !newMatch._id.startsWith("r6_")) {
        const revisionCount = parseInt(newMatch._rev.split("-")[0]);
        if (revisionCount >= 50 && revisionCount % 50 === 0) {
            try {
                throw new Error("Match with high revision count");
            } catch (err) {
                trackHighRevisionMatchInSentry(err, newMatch, revisionCount);
            }
        }
    }

    return databaseService
        .upsertDoc(newMatch as MatchPhone & PouchDB.Core.IdMeta)
        .then((doc) => _updateCompleted(newMatch, doc))
        .catch(function (err: any) {
            // TODO: check if this is a match not settings...
            if (err.name === "conflict") {
                // There was a conflict, so we need to merge our document with the latest in the db

                if (!newMatch._id) {
                    const errorMessage =
                        "Match with conflict has no id, which should be impossible";
                    console.error(errorMessage);
                    return Promise.reject(errorMessage);
                }
                let mergedFixture: MatchPhone;
                return databaseService
                    ?.getDocById<MatchPhone>(newMatch._id)
                    .then((databaseFixture) => {
                        mergedFixture = resolveMatchConflicts(
                            databaseFixture as MatchPhone,
                            newMatch
                        );

                        const profile = store.getState().currentUser?.profile;
                        if (!profile) {
                            return Promise.resolve(mergedFixture);
                        }

                        return updateMatchStats(
                            mergedFixture,
                            profile,
                            true
                        ).catch(function () {
                            return Promise.resolve(mergedFixture);
                        }); // don't error
                    })
                    .then(function () {
                        if (!databaseService) {
                            return Promise.reject(DATABASE_NOT_INITIALISED);
                        }
                        return databaseService
                            .getLocalDatabase()
                            .put(mergedFixture);
                    })
                    .then(function (doc) {
                        const matchId = newMatch._id || "";
                        const matchRev = newMatch._rev || "";
                        trackConflictResolve(
                            "immediate",
                            matchId,
                            matchRev,
                            "updateFixture",
                            newMatch.matchFinished
                        );
                        return _updateCompleted(mergedFixture, doc);
                    });
            }
            console.log("Couldn't update fixture " + err);
            return Promise.reject();
        });
}

export async function deleteMatch(match: MatchPhone) {
    if (!databaseService) {
        return Promise.reject(DATABASE_NOT_INITIALISED);
    }
    if (!match._id || !match._rev) {
        return Promise.reject("Match ID or Rev missing");
    }

    const response = await databaseService
        .getLocalDatabase()
        .remove(match._id, match._rev);
    if (response.ok) {
        return Promise.resolve(response);
    } else {
        return Promise.reject();
    }
}

export function getFilteredStats(filters: FiltersModel, matches: MatchPhone[]) {
    // TODO move updating stats elsewhere
    return reduceMatchStats(
        matches.filter((match) => {
            if (match.matchFinished && !match.matchAbandoned) {
                return matchesFilter(match, MatchType.Result, filters);
            }
            return false;
        }),
        moment(filters.startDate).valueOf(),
        moment(filters.endDate).valueOf()
    );
}

export function updateMatchStats(
    match: MatchPhone,
    userProfile: UserProfile,
    statsNeedUpdating: boolean
): Promise<boolean> {
    let updated = false;
    const performancePromises = [];
    const matchSegments = getPlayingSegments(match);

    if (statsNeedUpdating) {
        match.stats = processMatch(match);
        updated = true;
    }

    if (
        match.hasTracking !== false &&
        (statsNeedUpdating || !match.stats?.gpsProcessed)
    ) {
        performancePromises.push(
            getFitnessData(
                match._id as string,
                userProfile.username,
                FitnessDataType.GPS,
                false
            )
                .then((gpsData) => {
                    match.hasTracking = true;
                    processGPS(
                        match.stats as Stats,
                        match,
                        gpsData as GpsData,
                        matchSegments
                    );
                    if (match.stats?.gpsProcessed === 0) {
                        throw GPS_NOT_PROCESSED_ERR;
                    }
                    updated = true;
                    return Promise.resolve();
                })
                .catch((err: any) => {
                    if (
                        err.isAxiosError &&
                        err.response &&
                        err.response.status === 404
                    ) {
                        const msg = `${GPS_MISSING_MESSAGE} ${match._id}`;
                        console.log(msg);
                        Sentry.addBreadcrumb({ message: msg });
                        match.hasTracking = false;
                        updated = true; // need to update the record as match has changed
                    }
                    return Promise.resolve();
                })
        );
    }

    if (
        match.hasHeartRate !== false &&
        (statsNeedUpdating || !match.stats?.heartRateProcessed)
    ) {
        performancePromises.push(
            getFitnessData(
                match._id as string,
                userProfile.username,
                FitnessDataType.HeartRate,
                false
            )
                .then((heartRateData) => {
                    match.hasHeartRate = true;
                    processHR(
                        match.stats as Stats,
                        match,
                        heartRateData as HrData,
                        matchSegments,
                        userProfile
                    );
                    if (match.stats?.heartRateProcessed === 0) {
                        throw HR_NOT_PROCESSED_ERR;
                    }
                    updated = true;
                    return Promise.resolve();
                })
                .catch((err: any) => {
                    if (
                        err.isAxiosError &&
                        err.response &&
                        err.response.status === 404
                    ) {
                        const msg = `${HR_MISSING_MESSAGE} ${match._id}`;
                        console.log(msg);
                        Sentry.addBreadcrumb({ message: msg });
                        match.hasHeartRate = false;
                        updated = true; // need to update the record as match has changed
                    } else if (err === HR_NOT_PROCESSED_ERR) {
                        console.log(HR_NOT_PROCESSED_ERR);
                        match.hasHeartRate = false;
                        updated = true; // need to update the record as match has changed
                    }
                    return Promise.resolve();
                })
        );
    }

    return Promise.all(performancePromises).then(() =>
        Promise.resolve(updated)
    );
}

export async function recalculateStats(
    match: MatchPhone,
    profile: UserProfile,
    statsNeedUpdating: boolean = true // whether the stats are out of date
) {
    const updated = await updateMatchStats(match, profile, statsNeedUpdating);
    if (updated) {
        return await updateMatch(match);
    }
}

function getPlayingSegments(match: MatchPhone) {
    const halfEvents: HalfEvent[] = [];
    const matchCopy = copyObject(match);

    if (matchCopy.matchEvents) {
        for (const prop in matchCopy.matchEvents) {
            if (matchCopy.matchEvents.hasOwnProperty(prop)) {
                const event = matchCopy.matchEvents[prop];
                const eventName = event.eventName
                    ? event.eventName.trim()
                    : event.eventName;
                if (eventName === EventName.half) {
                    const halfEvent = event as HalfEvent;
                    if (halfEvent.playing) {
                        halfEvent.length = millisToMins(halfEvent.length);
                        halfEvents.push(halfEvent);
                    }
                }
            }
        }
    }
    return halfEvents.sort(function (a, b) {
        return a.timestamp - b.timestamp;
    });
}

function millisToMins(millis: number) {
    return millis && !isNaN(millis) ? Math.round(millis / 60000) : millis;
}

export function calculateScore(match: MatchPhone): [number, number] {
    if (!match) {
        return [0, 0];
    }
    const events = Object.values(match.matchEvents || {});
    const goals = events.filter((event) => {
        return event.eventName === "Goal";
    });
    return goals.reduce(
        (result, goal): [number, number] => {
            const goalEvent = goal as GoalEvent;
            if (goalEvent.team.side === SelectedTeam.home) {
                result[0]++;
            } else if (goalEvent.team.side === SelectedTeam.away) {
                result[1]++;
            }
            return result;
        },
        [0, 0]
    );
}

/**
 * Returns the matches with the most of the given property, e.g. `yellowCardTotal`.
 */
export function matchesWithMost(
    matches: MatchPhone[],
    property: keyof Stats,
    count: number,
    filters: FiltersModel
): MatchPhone[] {
    const filteredMatches = matches.filter((match) => {
        if (match.matchFinished && match.stats) {
            return (
                matchesFilter(match, MatchType.Result, filters) &&
                match.stats &&
                match.stats[property]
            );
        }
        return false;
    });
    return getMatchesSubset(
        filteredMatches,
        matchesWithMostSortFn.bind(null, property),
        count,
        true
    );
}

/**
 * Returns a sliced subset (shallow copy) based on the count, sort function and whether
 * or not to slice from the start or the end.
 */
function getMatchesSubset(
    matches: MatchPhone[],
    sortFn: (a: MatchPhone, b: MatchPhone) => number,
    count: number,
    fromStart: boolean
): MatchPhone[] {
    if (matches.length > 1) {
        var start = fromStart ? 0 : Math.max(matches.length - count, 0);
        var end = fromStart ? count : matches.length;
        return matches.sort(sortFn).slice(start, end);
    }
    return matches;
}

/**
 * Sort function to sort based on highest number from property values.
 * To be used as arg to `Array.prototype.sort()`, therefore `property` needs binding before being passed in.
 */
function matchesWithMostSortFn(
    property: keyof Stats,
    a: MatchPhone,
    b: MatchPhone
): number {
    if (a.stats && b.stats) {
        return (b.stats[property] as number) - (a.stats[property] as number);
    } else {
        return 0;
    }
}

/**
 * Getting matches including gpsAvailable | heartRateAvailable
 */
function getMatchesWithGPSorHR(
    matches: MatchPhone[],
    filters: FiltersModel,
    statsType: "gpsAvailable" | "heartRateAvailable"
): MatchPhone[] {
    statsType = statsType || "gpsAvailable";
    return matches.filter(function (match) {
        if (
            match.matchFinished &&
            match.stats &&
            match.stats[statsType] &&
            match.stats[statsType][0]
        ) {
            return matchesFilter(match, MatchType.Result, filters);
        }
        return false;
    });
}

/**
 * Getting latest matches by count
 */
export function lastMatches(
    matches: MatchPhone[],
    filters: FiltersModel,
    count: number,
    statsType: "gpsAvailable" | "heartRateAvailable"
): MatchPhone[] {
    const filteredResults = getMatchesWithGPSorHR(matches, filters, statsType);
    if (filteredResults.length < count) {
        count = filteredResults.length;
    }
    return filteredResults.slice(0, count).reverse();
}

export interface TopMatchesProps {
    barLabels: string[];
    sprintsLow: number[];
    sprintsMedium: number[];
    sprintsHigh: number[];
    speedWalk: number[];
    speedJog: number[];
    speedRun: number[];
    speedSprint: number[];
    distanceBySegment: number[][];
    periodsNumbers: number[];
}
/**
 * Stats returned for top matches
 */
export function topMatches(
    matches: MatchPhone[],
    useImperial: boolean
): TopMatchesProps {
    const barLabels: string[] = [];
    const sprintsLow: number[] = [];
    const sprintsMedium: number[] = [];
    const sprintsHigh: number[] = [];
    const speedWalk: number[] = [];
    const speedJog: number[] = [];
    const speedRun: number[] = [];
    const speedSprint: number[] = [];
    const distanceBySegment: number[][] = [];
    const periodsNumbers: number[] = [];
    const distToText = (dist: number) =>
        Number(metersToKmOrMiles(dist, useImperial).toFixed(2));

    matches.forEach((match) => {
        barLabels.push(`${match.homeTeamShort} - ${match.awayTeamShort}`);

        sprintsLow.push(match.stats?.sprintsTotal[0] || 0);
        sprintsMedium.push(match.stats?.sprintsTotal[1] || 0);
        sprintsHigh.push(match.stats?.sprintsTotal[2] || 0);

        const distanceWalked = match.stats?.speedCategoryDistances[1] || 0;
        const distanceJogged = match.stats?.speedCategoryDistances[2] || 0;
        const distanceRun = match.stats?.speedCategoryDistances[3] || 0;
        const distanceSprints = match.stats?.speedCategoryDistances[4] || 0;

        speedWalk.push(distToText(distanceWalked));
        speedJog.push(distToText(distanceJogged));
        speedRun.push(distToText(distanceRun));
        speedSprint.push(distToText(distanceSprints));

        switch (match.periodsNo) {
            case "2": {
                distanceBySegment.push(
                    match.stats?.distanceByHalvesTotal.map(distToText) || []
                );
                break;
            }
            case "3": {
                distanceBySegment.push(
                    match.stats?.distanceByThirdsTotal.map(distToText) || []
                );
                break;
            }
            case "4": {
                distanceBySegment.push(
                    match.stats?.distanceByQuartersTotal.map(distToText) || []
                );
                break;
            }
            default: {
                distanceBySegment.push(
                    match.stats?.distanceByHalvesTotal.map(distToText) || []
                );
            }
        }

        periodsNumbers.push(match?.periodsNo ? parseInt(match.periodsNo) : 2);
    });

    return {
        barLabels,
        sprintsLow,
        sprintsMedium,
        sprintsHigh,
        speedWalk,
        speedJog,
        speedRun,
        speedSprint,
        distanceBySegment,
        periodsNumbers,
    };
}

export function addTeam(
    teamName: string,
    players: Player[],
    match: MatchPhone
): MatchPhone {
    const newMatch: MatchPhone = MatchPhone.fromJSON(match);
    newMatch.players = newMatch.players || {};
    newMatch.players[teamName] = players;
    return newMatch;
}

// Returning string edit or add if team has teamSheet or not
export function checkForTeamSheet(
    team: string,
    match: MatchPhone,
    t: Function
): string {
    const hasTeamSheet = hasTeam(match, team);

    if (hasTeamSheet) {
        return t("general.edit");
    }

    return t("general.add");
}

type TimingEvent = {
    type: string;
    half: number;
    length: number;
};

export const markAsResult = (match: MatchPhone) => {
    const matchEvents =
        match.matchEvents && Object.keys(match.matchEvents).length > 0
            ? match.matchEvents
            : timingEvents(match.timings!, moment(match.date));

    trackEvent("MarkAsResult", { live: match.matchInProgress || false });

    return {
        ...match,
        matchEvents,
        matchInProgress: false,
        matchFinished: true,
    };
};
export const timingEvents = (
    matchTimings: Timings,
    startTime: Moment,
    hasExtraTime = false,
    hasPenalties = false
) => {
    // create list of match events based on the template config timings object
    const nonNull = omitBy(isNull); // Remove keys where the value is null
    const timings = map(nonNull(matchTimings), (length, k) => {
        const [type, half] = words(k);
        return {
            type,
            half: parseInt(half),
            length: length || 45,
        } as TimingEvent;
    });
    const groupedTimings = groupBy(timings, "type");
    const intervals = sortBy(groupedTimings["interval"], "half");
    const periods = sortBy(groupedTimings["period"], "half");
    const allEvents = compact(flatten(zip(periods, intervals)));
    type ValidPeriodLength = 1 | 2 | 3 | 4;
    const halfNames = periodStructure[periods.length as ValidPeriodLength];
    let idx = 0;
    const generateHalfEvent = (
        event: TimingEvent,
        startTs: Moment,
        endTs: Moment,
        name: string
    ) => {
        const res = {
            injuryTimeInInjuryTimeAccumulated: 0,
            endTime: endTs.valueOf(),
            index: idx++,
            playing: true,
            name: name,
            injuryTimeAccumulated: 0,
            kickOffSide: 0,
            isExtraTime: false,
            minuteOfPlay: 0,
            timestamp: startTs.valueOf(),
            eventName: "Half",
            length: moment.duration(event.length, "minutes").asMilliseconds(),
            endMinute: event.length,
        };
        return res;
    };
    const generateHalfTimeEvent = (
        event: TimingEvent,
        startTs: Moment,
        endTs: Moment,
        name: string
    ) => {
        const res = {
            eventName: "Half",
            injuryTimeInInjuryTimeAccumulated: 0,
            endMinute: event.length,
            injuryTimeAccumulated: 0,
            timestamp: startTs.valueOf(),
            minuteOfPlay: 0,
            name: name,
            isExtraTime: false,
            endTime: endTs.valueOf(),
            index: idx++,
            length: moment.duration(event.length, "minutes").asMilliseconds(),
            playing: false,
        };
        return res;
    };

    const generateExtraTimeEvent = (
        event: TimingEvent,
        startTs: Moment,
        endTs: Moment,
        name: string
    ) => {
        return {
            ...generateHalfEvent(event, startTs, endTs, name),
            isExtraTime: true,
        };
    };
    const generateKFTPMEvent = (startTs: Moment, endTs: Moment) => {
        const res = {
            endTime: endTs.valueOf(),
            minuteOfPlay: 0,
            timestamp: startTs.valueOf(),
            eventName: EventName.penalties,
            index: idx++,
            length: moment.duration(10, "minutes").asMilliseconds(),
        };
        return res;
    };
    let generateEvents: any = {};
    let currentTs = startTime;
    forEach(zip(allEvents, halfNames), ([event, periodName]) => {
        if (!event || !periodName) return;
        const startTime = moment(currentTs);
        const endTime = moment(startTime).add(event.length, "minutes");
        const timestamp = startTime.valueOf();
        if (event.type == "period") {
            generateEvents[timestamp] = generateHalfEvent(
                event,
                startTime,
                endTime,
                `${periodName}`
            );
        } else if (event.type == "interval") {
            generateEvents[timestamp] = generateHalfTimeEvent(
                event,
                startTime,
                endTime,
                periodName
            );
        }
        currentTs = moment(endTime).add(1, "second");
    });
    if (hasExtraTime) {
        const extraTimeLength = matchTimings.extraTimeHalfLength || 15;
        times(2, (i) => {
            const startTime = moment(currentTs);
            const endTime = moment(startTime).add(extraTimeLength, "minutes");
            const timestamp = startTime.valueOf();
            generateEvents[timestamp] = generateExtraTimeEvent(
                { type: "period", length: extraTimeLength, half: 0 },
                startTime,
                endTime,
                `ET${i + 1}`
            );
            currentTs = moment(endTime).add(1, "second");
        });
    }
    if (hasPenalties) {
        const startTime = moment(currentTs);
        const endTime = moment(startTime).add(10, "minutes");
        const timestamp = startTime.valueOf();
        generateEvents[timestamp] = generateKFTPMEvent(startTime, endTime);
        currentTs = moment(endTime).add(1, "second");
    }
    return generateEvents;
};

export function prepareFixturesForWatch(): MatchData[] {
    const state = store.getState();
    let upcoming = filterMatches(state.matches.matches);
    const settings = state.settings.settings;

    const builtMatchData: MatchData[] = [];

    upcoming.forEach(function (fixture, i) {
        try {
            const matchData = buildMatchForWatch(
                fixture,
                settings,
                hasFeatureAccess("timers"),
                true,
                createDefaultTeamOfficials(),
                // TODO when we build wearOS support for this then we can set to true
                isIos() // we are assuming that the watch has been updated at the same time as phone
            );
            builtMatchData.push(matchData);
        } catch (e: any) {
            Sentry.captureException(e);
        }
    });
    builtMatchData.sort(function (a, b) {
        return a.match.kickOffTime && b.match.kickOffTime
            ? new Date(a.match.kickOffTime).getTime() -
                  new Date(b.match.kickOffTime).getTime()
            : -1;
    });
    return builtMatchData;
}

// TODO: unit test?
// Converting created periods and intervals using the form to look like periods and intervals on MatchPhone timings
export function transFormValuesOut(
    list: number[],
    type: "period" | "interval"
): Timings {
    return reduce(
        list,
        (acc: any, v, idx) => {
            acc[`${type}${idx + 1}`] = Number(v);
            if (!acc[`${type}${idx + 1}`]) {
                acc[`${type}${idx + 1}`] = acc[`${type}${1}`];
            }
            return acc;
        },
        {}
    );
}

export function matchMissingInfo(match: MatchPhone): boolean {
    const hasSinBins = !(
        !match.sinBinSystem || match.sinBinSystem === SinBinSystem.none
    );

    return (
        !match.timings ||
        (hasSinBins && !match.timings?.sinBinTimerLength) ||
        !match.timings.period1 ||
        (match.periodsNo !== "1" && !match.timings.interval1) ||
        !match.officialRole
    );
}

/**
 * It is important to send in filtered data here as if not my might wrongly try to calculate stats when none needed
 * @param profile
 * @param match
 * @param hrData FILTERED hrData
 * @param gpsData FILTERED gpsData
 */
export async function recalculateStatsForHrAndGps(
    profile: UserProfile,
    match: MatchPhone,
    hrData?: HrData,
    gpsData?: GpsData
) {
    let matchToUpdate = match;
    let needsStatsUpdate = false;
    if (
        hrData &&
        hrData.heartRateValues &&
        hrData.heartRateValues.length &&
        !match?.hasHeartRate
    ) {
        needsStatsUpdate = true;
        matchToUpdate = { ...match, hasHeartRate: true };
    }
    if (
        gpsData &&
        gpsData.geoPoints &&
        gpsData.geoPoints.length &&
        !match?.hasTracking
    ) {
        needsStatsUpdate = true;
        matchToUpdate = { ...match, hasTracking: true };
    }

    if (needsStatsUpdate) {
        return recalculateStats(matchToUpdate, profile, true);
    }
}

// Given a team, iterate through matches that have that team, and return the list of players that have played for that team
export const getPlayersForTeam = (
    team: string,
    matches: MatchPhone[]
): string[] =>
    _(matches)
        .filter((m) => m.homeTeam === team || m.awayTeam === team)
        .map((m) => {
            if (m.players && m.players[team]) {
                return _(m.players[team]).map("name").value();
            }
            return [];
        })
        .flatten()
        .uniq()
        .value();

export function filterMatchesBasedOnSelectedDate(
    allFixtures: MatchPhone[],
    date: string | string[] | undefined | null
): MatchPhone[] {
    if (date) {
        const formattedDate = moment(date, "YYYY-MM-DD").toDate();

        return allFixtures.filter((match) => {
            const matchDate = moment(match.date, "YYYY-MM-DD").toDate();
            return moment(formattedDate).isSame(matchDate, "day");
        });
    }

    return [];
}

export function createMatchOrAvailability(
    matches?: MatchPhone[],
    closedDates?: RefAssistAvailability[],
    openDates?: RefAssistAvailability[]
): MatchOrAvailability[] {
    const matchOrAvailability: MatchOrAvailability[] = [];

    if (matches) {
        matchOrAvailability.push(
            ...matches.map((match) => ({
                timestamp: moment(match.date).format("HH:mm"),
                match: match,
            }))
        );
    }

    if (closedDates) {
        matchOrAvailability.push(
            ...closedDates.map((closedDate) => ({
                timestamp: closedDate.start || "",
                closedDate: closedDate,
            }))
        );
    }

    if (openDates) {
        matchOrAvailability.push(
            ...openDates.map((openDate) => ({
                timestamp: openDate.start || "",
                openDate: openDate,
            }))
        );
    }

    return matchOrAvailability;
}

export function computeHalfInfo(playingPeriods: HalfEvent[]): IndexedMap {
    const output: IndexedMap = {};
    playingPeriods.forEach((halfEvent, index) => {
        // if endTime not set, default to assume full scheduled half length
        let endTs = halfEvent.endTime || halfEvent.timestamp + halfEvent.length;

        let startMinute: number = halfEvent.minuteOfPlay;

        let scheduledDurationMins = millisToMins(halfEvent.length);

        let actualDurationMins = millisToMins(endTs - halfEvent.timestamp);

        let additionalTime = Math.max(
            actualDurationMins - scheduledDurationMins,
            0
        );

        let endMinute =
            additionalTime > 0
                ? startMinute + scheduledDurationMins
                : startMinute + actualDurationMins;

        output[halfEvent.index] = {
            startMinute: startMinute,
            actualEndMinute: endMinute,
            scheduledDurationMinutes: scheduledDurationMins,
            actualDurationMinutes: actualDurationMins,
            additionalTime: additionalTime,
            scheduledEndMinute: startMinute + scheduledDurationMins,
            playing: halfEvent.playing,
        };
    });
    return output;
}

export function isSystemB(match: MatchPhone) {
    return match.sinBinSystem === SinBinSystem.systemB;
}

export function filterSystemBMatches(matches: MatchPhone[]): MatchPhone[] {
    return matches.filter(_isMatch).filter(isSystemB);
}
export function toSystemB2024(match: MatchPhone) {
    const newMatch = copyObject(match);
    newMatch.sinBinSystem = SinBinSystem.systemB2024;
    return newMatch;
}

export function updateMatches(matches: MatchPhone[]) {
    return Promise.all(matches.map(updateMatch));
}

export function migrateSystemBMatches(matches: MatchPhone[]) {
    const systemBMatches = filterSystemBMatches(matches);
    return updateMatches(systemBMatches.map(toSystemB2024));
}
export function hasSystemBMatches(matches: MatchPhone[]) {
    return filterSystemBMatches(matches).length > 0;
}
