import {
    SET_SESSION,
    SET_SESSION_EXPIRED,
    USER_LOGGED_IN,
    USER_LOGOUT,
} from "../actions/types";
import { Auth } from "../models/refsixState";

const initialState = new Auth();

function authReducer(state: Auth = initialState, action: any) {
    switch (action.type) {
        case USER_LOGGED_IN:
            return {
                ...state,
                loggedIn: action.loggedIn,
                session: action.session,
            };
        case SET_SESSION:
            return {
                ...state,
                session: action.session,
            };
        case USER_LOGOUT:
            return { loggedIn: false };
        case SET_SESSION_EXPIRED:
            return {
                ...state,
                sessionExpired: action.expired,
            };
        default:
            return state;
    }
}

export default authReducer;
