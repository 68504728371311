import { FC } from "react";
import "./reviewFooter.css";
import refsixLogo from "../../assets/images/refsix-words-h80px.png";

import { AppStoreLogo, PlayStoreLogo } from "../StoreLogo";

export const ReviewFooter: FC<{}> = () => {
    return (
        <div className="story-footer">
            <AppStoreLogo />
            <RefsixLogo />
            <PlayStoreLogo />
        </div>
    );
};
export const RefsixLogo: FC = () => {
    return <img className="footer-logo" src={refsixLogo}></img>;
};
