import { WatchInterface } from "./watchInterface";
import { Observable, Observer } from "rxjs";

export class MockWatch implements WatchInterface {
    _stream: Observable<any>;
    messageObservers: Observer<any>[] = [];

    constructor() {
        console.log("Mock Watch init");
        this._stream = new Observable<any>((messageObserver) => {
            this.messageObservers.push(messageObserver);
        });
    }

    public messageReceived(data: any) {
        console.log(`MockWatch message received ${data.messageId}`);
        if (!this.messageObservers.length) {
            return console.error(
                "[MockWatch] Received message but observer is undefined"
            );
        }
        this.messageObservers.forEach((observer) => {
            observer.next(data);
        });
        return Promise.resolve();
    }

    messageReceivedStream(): Observable<any> {
        return this._stream;
    }

    sendMessage(payload: any): Promise<any> {
        console.log("Sent message to mock watch", payload);
        return Promise.resolve({});
    }
}
