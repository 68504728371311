import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonItem,
    IonLabel,
    IonPage,
    IonProgressBar,
    IonText,
    useIonRouter,
} from "@ionic/react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../components";
import { Geolocation } from "@capacitor/geolocation";
import { routes } from "../../route/constants";
import { useMatch } from "../../components/hooks/match";
import { GpsMap, GpsMapType } from "../../components/GpsMapComponent";
import { trackEvent } from "../../services/analytics/analyticsService";
import "./onBoarding.css";
import { isAndroid, isIos } from "../../services/platformDetection";

const demoMatchId = "example-result1";

export const OnBoardingShareLocationData: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();

    const demoMatch = useMatch(demoMatchId);

    const askForPermissionToUseLocation = async () => {
        if (isIos() || isAndroid()) {
            const permission = await Geolocation.checkPermissions();
            if (permission.location !== "granted") {
                await Geolocation.requestPermissions();
            }

            if (permission.location === "granted") {
                trackEvent("shareLocationData", {});
            }
        }
        route.push(
            routes.refsixOnBoardingShareHealthData,
            "forward",
            "replace"
        );
    };

    return (
        <IonPage>
            <HeaderComponent title={t("help.onBoarding.performanceData")} />
            <IonContent>
                <IonCard color="transparent">
                    <IonProgressBar style={{ marginTop: 10 }} value={0.3} />
                </IonCard>
                <IonCard color="transparet">
                    <IonItem lines="none" color="transparet">
                        <IonLabel
                            className="align-center text-weight-bold"
                            color="primary"
                        >
                            {t("help.onBoarding.shareLocationData")}
                        </IonLabel>
                    </IonItem>
                    <p style={{ fontSize: 16 }}>
                        <IonText className="align-center">
                            {t("help.onBoarding.needLocationAccess")}
                        </IonText>
                    </p>
                    <p style={{ fontSize: 16 }}>
                        <IonText>
                            {t("help.onBoarding.needWhileInUseAccess")}
                        </IonText>
                    </p>
                </IonCard>

                {demoMatch && (
                    <IonCard className="onboarding-heatmap">
                        <GpsMap
                            match={demoMatch}
                            mapType={GpsMapType.Heatmap}
                            useDummyData={true}
                            testId="onboarding-heatmap"
                        ></GpsMap>
                    </IonCard>
                )}
            </IonContent>
            <IonFooter>
                <IonButton
                    data-testid="share-location-data-next-button"
                    expand="block"
                    onClick={() => askForPermissionToUseLocation()}
                >
                    {t("general.next")}
                </IonButton>
            </IonFooter>
        </IonPage>
    );
};
