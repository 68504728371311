export function getUTMParamsFromURL(url: string) {
    const params = new URL(url, "https://app.refsix.com").searchParams;
    const utmParams = {
        utm_campaign: params.get("utm_campaign"),
        utm_source: params.get("utm_source"),
        utm_medium: params.get("utm_medium"),
        utm_term: params.get("utm_term"),
        utm_content: params.get("utm_content"),
    };
    return utmParams;
}
