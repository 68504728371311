import { Stats } from "refsix-js-models";

type HasStats = {
    stats?: Stats;
    _id?: string;
};

const _byStat =
    (stat: keyof Stats, dir = "desc") =>
    (a: HasStats, b: HasStats) => {
        const aStat = a.stats?.[stat] || 0;
        const bStat = b.stats?.[stat] || 0;
        const comp = aStat > bStat ? -1 : 1;
        return dir === "desc" ? comp : comp * -1;
    };

const _byFn =
    (fn: (a: HasStats, b: HasStats) => number) => (a: HasStats, b: HasStats) =>
        fn(a, b);

const byMostDistance = (a: HasStats, b: HasStats) =>
    _byStat("distanceTotal", "desc")(a, b);
const byMostCards = (a: HasStats, b: HasStats) =>
    _byFn((a, b) => {
        const aCards =
            (a.stats?.redCardTotal || 0) + (a.stats?.yellowCardTotal || 0);
        const bCards =
            (b.stats?.redCardTotal || 0) + (b.stats?.yellowCardTotal || 0);
        return aCards > bCards ? -1 : 1;
    })(a, b);

const byMostScoring = (a: HasStats, b: HasStats) =>
    _byStat("goalsTotal", "desc")(a, b);

const bySprintsTotal = (a: HasStats, b: HasStats) =>
    _byFn((a, b) => {
        const aSprints = a.stats?.sprintsTotal.reduce((a, b) => a + b, 0) || 0;
        const bSprints = b.stats?.sprintsTotal.reduce((a, b) => a + b, 0) || 0;
        return aSprints > bSprints ? -1 : 1;
    })(a, b);

const orderByAndFirst = (
    arr: HasStats[],
    by: (a: HasStats, b: HasStats) => number
) => {
    if (!arr.length) return undefined;
    if (arr.length === 1) return arr[0]._id;
    const sorted = arr.sort(by);
    return sorted[0]._id;
};
export const highestDistance = (arr: HasStats[]) =>
    orderByAndFirst(arr, byMostDistance);
export const mostCards = (arr: HasStats[]) => orderByAndFirst(arr, byMostCards);
export const highestScoring = (arr: HasStats[]) =>
    orderByAndFirst(arr, byMostScoring);
export const hardestWorking = (arr: HasStats[]) =>
    orderByAndFirst(arr, bySprintsTotal);
export const compact = <T>(arr: T[]) => arr.filter(Boolean);
