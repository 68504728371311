import { useEffect, useState } from "react";
import { Keyboard, KeyboardInfo, KeyboardPlugin } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";

export interface AvailableResult {
    isAvailable: boolean;
}

interface KeyboardResult extends AvailableResult {
    isOpen: boolean;
    keyboardHeight: number;
    keyboard: KeyboardPlugin;
}

/**
 * This is based on the capacitor-community plugin, but fixes the unhandled error when trying to access the keyboard
 * on web.
 */
export function useSafeKeyboard(): KeyboardResult {
    const [isOpen, setIsOpen] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    useEffect(() => {
        const showCallback = (ki: KeyboardInfo) => {
            const keyboardHeight = ki.keyboardHeight;
            setIsOpen(true);
            setKeyboardHeight(keyboardHeight);
        };
        const hideCallback = () => {
            setIsOpen(false);
            setKeyboardHeight(0);
        };
        if (Capacitor.isPluginAvailable("Keyboard")) {
            Keyboard.addListener("keyboardDidShow", showCallback);
            Keyboard.addListener("keyboardDidHide", hideCallback);
        }

        return () => {
            if (Capacitor.isPluginAvailable("Keyboard")) {
                Keyboard.removeAllListeners();
            }
        };
    }, [setIsOpen, setKeyboardHeight]);

    return {
        isOpen,
        keyboardHeight,
        isAvailable: true,
        keyboard: Keyboard,
    };
}
