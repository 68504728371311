import { SubscriptionItems } from "./subscriptionItems";

export class SubscriptionDetails {
    subscriptions: SubscriptionItems[] = [];
    features: string[] = [];
    signUpTimestamp?: number;
    beenPro? = false;
    beenBasic? = false;

    constructor(
        subscriptions: SubscriptionItems[],
        features: string[],
        signUpTimestamp?: number,
        beenPro?: boolean,
        beenBasic?: boolean
    ) {
        this.subscriptions = subscriptions;
        this.features = features;
        this.signUpTimestamp = signUpTimestamp;
        this.beenPro = beenPro;
        this.beenBasic = beenBasic;
    }
}
