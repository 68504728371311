import shortUUID from "short-uuid";
import { MessageTypes } from "../messageTypesEnum";
import { checkWatchVersion } from "../versionMismatch";

export type WatchWakeUpRequest = {
    id: string;
    messageId: MessageTypes.WAKE_UP;
    version: string;
};

export async function wakeUpMessage(request: WatchWakeUpRequest) {
    await checkWatchVersion(request.version);

    return {
        requestId: request.id,
        messageId: MessageTypes.WAKE_UP_RESPONSE,
        _id: shortUUID().uuid(),
    };
}
