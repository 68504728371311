import { FC } from "react";
import { useSelector } from "react-redux";
import { formatTime } from "refsix-core";
import { RootState } from "../redux/store";

interface TimeProps {
    dateTimeInUTC: string | number;
    timezone?: string;
}

const Time: FC<TimeProps> = ({ dateTimeInUTC, timezone }) => {
    const { use12HourTime } = useSelector(
        (root: RootState) => root.settings.settings
    );

    return <>{formatTime(dateTimeInUTC, use12HourTime, timezone)}</>;
};

export default Time;
