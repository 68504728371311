import { ProcessedValuesInterface } from "../components/HeartRateZonesComponent";
import { REFSIX_GREEN } from "../constants/StatsColorPalette";
import { GpsMinutesStats, GpsStats } from "../../../models";
import request from "./request";
import { DecimalFilter, metersToYards } from "refsix-core";

let processedValues: ProcessedValuesInterface[] = [];
const minWidth = 30;

function getTotalDuration(distanceBy: number[]) {
    return distanceBy.reduce(function (sum, val) {
        return sum + (val || 0);
    }, 0);
}

function calcPercentage(val: number, sumOfValues: number) {
    return sumOfValues > 0 ? (val / sumOfValues) * 100 : 0;
}

export function getProcessedValues(
    distanceValues: number[],
    series: string[],
    distanceBy?: string,
    colorOverride?: string | string[]
) {
    processedValues = [];
    let stats = { sumOfValues: 0, largestWidth: 0 };

    stats = distanceValues.reduce(function (acc, val) {
        acc.sumOfValues += val;
        return acc;
    }, stats);

    // calculate base values
    distanceValues.forEach(function (val, index) {
        const width = calcPercentage(val, stats.sumOfValues);
        let value =
            (distanceValues[index] / getTotalDuration(distanceValues)) * 100;

        // TODO support any colour array
        let color = REFSIX_GREEN;
        if (typeof colorOverride === "string") {
            color = colorOverride;
        } else if (Array.isArray(colorOverride)) {
            // keep repeating the last colour if there are more values than colours
            const colorIndex = Math.min(colorOverride.length - 1, index);
            color = colorOverride[colorIndex];
        }

        processedValues[index] = {
            name: series ? series[index] : "",
            value: distanceBy === "duration" ? value : distanceValues[index],
            width: width,
            class: "",
            color,
        };
        stats.largestWidth = Math.max(stats.largestWidth, width);
    });

    // scale the bars to fit the space
    processedValues.forEach(function (val) {
        val.width =
            Math.round(
                (val.width + minWidth) * (100 / (stats.largestWidth + minWidth))
            ) || minWidth;
    });

    return processedValues;
}

export function fiveMinutesStats(
    stats: GpsStats,
    stepSize: number,
    useImperial: boolean
) {
    if (stats.minuteStats) {
        const minutesPlayedArray: number[] = [];
        const distanceCoveredArray: number[] = [];

        const fiveMinStats = stats.minuteStats.reduce(function (
            stat: GpsMinutesStats[],
            val,
            index
        ) {
            val.distance = useImperial
                ? metersToYards(val.distance || 0)
                : val.distance;

            if (index % stepSize === 0) {
                stat.push({
                    distance: 0,
                    minuteFromKickoff: val.minuteFromKickoff,
                });
            }

            // Adding every fifth value to get the correct sum in the distance graph
            if (val.distance && val.distance > 0) {
                const currentIntervalStat: any = stat[stat.length - 1];
                currentIntervalStat.distance += val.distance;
            }

            return stat;
        },
        []);

        fiveMinStats.forEach((stat) => {
            minutesPlayedArray.push(stat.minuteFromKickoff || 0);

            distanceCoveredArray.push(DecimalFilter()(stat.distance || 0, 0));
        });

        return [minutesPlayedArray, distanceCoveredArray];
    }
}

/**
 * Makes a post request to update all stats for the given user
 */
export async function generateAllStatsOnServer(username: string) {
    await request.post("/update-stats/" + username, {}, { timeout: 60000 });
}
