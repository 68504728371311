import { SubscriptionItems } from "refsix-js-models";

// assume if the provider is apple, google, or stripe, it's a paid subscription.
// Register free trial and complementary subscriptions use the provider refsix.
export const PAID_SUBSCRIPTION_PROVIDERS = ["apple", "google", "stripe"];

export function isSubscriptionPaid(provider: string): boolean {
    return PAID_SUBSCRIPTION_PROVIDERS.includes(provider);
}

export function hasPaidSubscription(
    subscriptions: SubscriptionItems[]
): boolean {
    for (const subscription of subscriptions) {
        if (isSubscriptionPaid(subscription.provider)) {
            return true;
        }
    }
    return false;
}
