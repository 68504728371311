import moment from "moment-timezone";

/**
 * Returns just the time, formatted to either 12h (e.g. 10:30pm) or 24h (e.g. 22:30)
 */
export function formatTime(
    dateTimeInUTC: string | number,
    use12HourTime: boolean,
    timezone?: string
) {
    if (timezone) {
        return use12HourTime
            ? moment(dateTimeInUTC).tz(timezone).format("h:mma")
            : moment(dateTimeInUTC).tz(timezone).format("HH:mm");
    } else {
        return use12HourTime
            ? moment(dateTimeInUTC).format("h:mma")
            : moment(dateTimeInUTC).format("HH:mm");
    }
}

/**
 * Converts seconds to mm:ss e.g. 3700 seconds becomes "61:40"
 */
export function secondsToMinuteClock(secs: number) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.round(secs - minutes * 60);
    const displayMinutes = minutes > 99 ? minutes : ("0" + minutes).slice(-2);

    return displayMinutes + ":" + ("0" + seconds).slice(-2);
}
