import {
    IonCard,
    IonContent,
    IonItem,
    IonList,
    IonLoading,
    IonModal,
    IonRadio,
    IonSearchbar,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../components/HeaderComponent";
import { OnBoardingHeadingComponent } from "../pages/onBoarding/onBoardingHeadingComponent";
import { knvbFootballClubs } from "../components/knvb/footballClubs";
import { R6IonRadioGroup } from "../components/r6ionic/R6IonRadioGroup";

interface SearchClubModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
}

export const SearchClubModal: FC<SearchClubModalProps> = ({
    showModal,
    setShowModal,
}) => {
    const MAX_RESULTS = 50;
    const { t } = useTranslation();
    const [filteredClubs, setFilteredClubs] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const handleInput = (ev: Event) => {
        let query = "";
        const target = ev.target as HTMLIonSearchbarElement;
        if (target) query = target.value!.toLowerCase();

        setSearchQuery(target.value!);
        setFilteredClubs(
            knvbFootballClubs
                .filter((d) => d.toLowerCase().indexOf(query) > -1)
                .slice(0, MAX_RESULTS)
        );
    };

    useEffect(() => {
        setShowLoading(true);
        const loadClubs = async () => {
            // Simulate an API call or any asynchronous operation to fetch data
            await new Promise((resolve) => setTimeout(resolve, 1000));

            setFilteredClubs(knvbFootballClubs.slice(0, MAX_RESULTS));

            setShowLoading(false);
        };

        loadClubs();
    }, []);

    return (
        <IonModal
            isOpen={showModal}
            onDidDismiss={() => setShowModal(false)}
            data-testid="select-club-modal"
        >
            <HeaderComponent
                titleTestId="register-select-club-header"
                title={t("register.register.selectClub")}
                headerEndText={t("general.done")}
                onClickEndButton={() => setShowModal(false)}
                endBtnTestId="select-club-modal-done-button"
            />
            <OnBoardingHeadingComponent progressValue={0.2} />
            <IonSearchbar
                data-testid="search-club-input"
                style={{ marginTop: 10, borderRadius: 1 }}
                onIonInput={handleInput}
                value={searchQuery}
            />
            <IonContent className="radio-list-card">
                <IonLoading
                    data-testid="select-club-modal-loading-spinner"
                    isOpen={showLoading}
                />
                <IonList className="radio-list-card">
                    <IonCard className="ion-card-transparent">
                        <R6IonRadioGroup name="refereeClub">
                            {filteredClubs.map((club, index) => {
                                return (
                                    <IonItem
                                        key={club}
                                        lines="none"
                                        className="item-radio-list"
                                    >
                                        <IonRadio value={club}>{club}</IonRadio>
                                    </IonItem>
                                );
                            })}
                        </R6IonRadioGroup>
                    </IonCard>
                </IonList>
            </IonContent>
        </IonModal>
    );
};
