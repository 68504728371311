import { DecimalFilter } from "../filters";
import moment from "moment";
import { HRZoneConstants as HR_zoneConstants } from "./HRZoneConstants";
import { HalfEvent, HrData, HrPoint } from "refsix-js-models";

export interface HrProcessedPoint {
    value?: number;
    timestamp?: number;
}

export class HeartRateProcessingService {
    private previousHR?: any = null;

    constructor() {}

    sortHeartRate(data) {
        data.heartRateValues = data.heartRateValues
            .filter(function (heartRate) {
                return (
                    heartRate !== null &&
                    typeof heartRate !== "undefined" &&
                    heartRate.value !== 0
                );
            })
            .sort(function (a, b) {
                return a.time - b.time;
            });

        return data;
    }

    _round(val) {
        return DecimalFilter()(val, 0);
    }

    _toMins(time) {
        return this._round(time / 1000 / 60);
    }

    _summariseHeartRateMinute(heartRateValues) {
        var minuteData: any[] = [];
        var outputMinute = 0;
        var initialTimestamp = heartRateValues[0] && heartRateValues[0].time;
        var prev;

        for (var i = 0; i < heartRateValues.length; i++) {
            let currentMinute: number = this._toMins(
                heartRateValues[i].time - initialTimestamp
            );

            while (outputMinute < currentMinute) {
                minuteData[outputMinute] = {
                    minute: outputMinute,
                    value: prev.value,
                };
                outputMinute++;
            }

            if (outputMinute === currentMinute) {
                minuteData[outputMinute] = {
                    minute: currentMinute,
                    value: heartRateValues[i].value,
                };
                outputMinute++;
                prev = {
                    minute: currentMinute,
                    value: heartRateValues[i].value,
                };
            }
        }
        return minuteData;
    }

    _maxHeartRate(heartRateValues) {
        return heartRateValues.reduce(function (max, heartRate) {
            return Math.max(heartRate.value, max);
        }, 0);
    }

    _averageHeartRate(heartRateValues) {
        return Math.round(
            heartRateValues.reduce(function (sum, heartRate) {
                return sum + heartRate.value;
            }, 0) / heartRateValues.length
        );
    }

    averageHeartRateBySegment(segment, heartRateValues) {
        var heartRateInSegment: any[] = [];
        for (var i = 0; i < heartRateValues.length; i++) {
            if (
                heartRateValues[i].time < segment.endTime &&
                heartRateValues[i].time > segment.timestamp
            ) {
                heartRateInSegment.push({ value: heartRateValues[i].value });
            }
        }
        return this._averageHeartRate(heartRateInSegment);
    }

    summaryHR(heartRateValues) {
        return {
            max: this._maxHeartRate(heartRateValues),
            average: this._averageHeartRate(heartRateValues),
            heartRateMinutes: this._summariseHeartRateMinute(heartRateValues),
        };
    }

    _minuteOffsetToSegment(matchTimings, heartRateTimestamp) {
        if (matchTimings && matchTimings.length && heartRateTimestamp) {
            for (var i = 0; i < matchTimings.length; i++) {
                var segment = matchTimings[i];
                if (
                    segment.timestamp <= heartRateTimestamp &&
                    segment.endTime >= heartRateTimestamp
                ) {
                    return i;
                }
            }
        }
        return undefined;
    }

    /**
     * Checks if HR timestamp is during active play.
     * @param matchTimings
     * @param hrTimestamp
     * @returns {boolean}
     * @private
     */
    _isPointDuringPlay(matchTimings, hrTimestamp) {
        var segment = this._minuteOffsetToSegment(matchTimings, hrTimestamp);
        return typeof segment === "number" && segment >= 0;
    }

    /**
     * Basic validation that the gps timestamp is during play and the accuracy is within tolerance.
     * @private
     */
    _hasValidTimestampAndIsUnique(matchTimings, heartRate) {
        if (!heartRate) {
            return false;
        }
        if (
            this.previousHR &&
            this.previousHR.time === heartRate.time &&
            this.previousHR.value === heartRate.value
        ) {
            return false;
        } else {
            this.previousHR = heartRate;
            var timestamp = heartRate.time;
            return this._isPointDuringPlay(matchTimings, timestamp);
        }
    }

    filterInvalidHrData(
        rawData: HrData,
        matchTimings: HalfEvent[]
    ): HrData | null {
        const heartRateValues = this.filterInvalidPoints(rawData, matchTimings);
        // filterInvalidPoints function already mutates this rawData object, but as we want to more to immutability in
        // the future I will do this again just to be safe.
        return { ...rawData, heartRateValues: heartRateValues || [] };
    }

    filterInvalidPoints(
        rawData: HrData,
        matchTimings: HalfEvent[]
    ): HrPoint[] | null {
        const self = this;
        if (!rawData.heartRateValues) {
            console.log("no heart rate");
            return null;
        }
        const filteredPoints = rawData.heartRateValues.filter(function (
            heartRate
        ) {
            return self._hasValidTimestampAndIsUnique(matchTimings, heartRate);
        });

        return filteredPoints;
    }

    _getCurrentHrZone(hrVal, maxHR): number | undefined {
        var percentageOfMax = hrVal / maxHR;
        if (percentageOfMax < 0.5) {
            return undefined;
        } else if (percentageOfMax >= 1) {
            return HR_zoneConstants.length - 1;
        }
        for (var i = 0; i < HR_zoneConstants.length; i++) {
            if (
                percentageOfMax >= HR_zoneConstants[i].min &&
                percentageOfMax < HR_zoneConstants[i].max
            ) {
                return i;
            }
        }
        return undefined;
    }

    _calculateAge(dob, matchDate) {
        var eventDate = moment(matchDate);
        var birthDate = moment(dob);
        return eventDate.diff(birthDate, "years");
    }

    buildHrZoneGraph(profile, matchDate, hrData) {
        var self = this;
        //Defaulting age to 30 if they didn't specify one
        var age = 30;
        if (profile && profile.dob) {
            age = this._calculateAge(new Date(profile.dob), matchDate);
        }
        var maxHR = 220 - age;
        var zoneDurations = [0, 0, 0, 0, 0];
        var previousZone = 0;
        var timeSpentInZone = 0;
        var currentZone: number | undefined = 0;
        hrData.forEach(function (hrPoint, i) {
            currentZone = self._getCurrentHrZone(hrPoint.value, maxHR);
            if (currentZone !== undefined) {
                if (currentZone !== previousZone) {
                    zoneDurations[previousZone] =
                        zoneDurations[previousZone] + timeSpentInZone;
                    timeSpentInZone = 0;
                } else if (i > 0) {
                    timeSpentInZone += hrPoint.time - hrData[i - 1].time;
                }
                if (i === hrData.length - 1) {
                    zoneDurations[previousZone] =
                        zoneDurations[previousZone] + timeSpentInZone;
                }
                previousZone = currentZone;
            }
        });
        return zoneDurations.map(function (zoneDuration) {
            return zoneDuration / 1000;
        });
    }
}

export default HeartRateProcessingService;
