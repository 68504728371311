import {
    SET_SESSION,
    SET_SESSION_EXPIRED,
    USER_LOGGED_IN,
    USER_LOGOUT,
} from "./types";
import { LoginSession } from "../models/loginSession";

export const userLoggedIn = (loggedIn: Boolean, session?: LoginSession) => ({
    type: USER_LOGGED_IN,
    loggedIn,
    session,
});

export const setSession = (session: LoginSession) => ({
    type: SET_SESSION,
    session,
});

export const userLogout = () => ({
    type: USER_LOGOUT,
});

export const setSessionExpired = (expired: boolean) => ({
    type: SET_SESSION_EXPIRED,
    expired,
});
