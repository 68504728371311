// react component that returns an ion card with a message

import { FC } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonText,
    useIonRouter,
} from "@ionic/react";
import moment from "moment";
import { mailOutline, notificationsOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { routes } from "../route/constants";
import { Message, MessageType, RefAssistAlertType } from "refsix-js-models";

export const MessageComponent: FC<Message> = (message) => {
    const { t } = useTranslation();
    const router = useIonRouter();

    const getTitle = () => {
        switch (message.type) {
            case MessageType.Alert:
                return message.title;
            case MessageType.Message:
                return t("messages.message");
            default:
                return "";
        }
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle style={{ fontSize: 22 }}>
                    <IonIcon
                        icon={
                            message.type === "alert"
                                ? notificationsOutline
                                : mailOutline
                        }
                        style={{ color: "#d5e830" }}
                    />{" "}
                    <IonText>{getTitle()}</IonText>
                </IonCardTitle>
                <IonCardSubtitle>
                    {moment(message.date).format("LLL")}
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonText style={{ color: "white" }}>
                    <p
                        dangerouslySetInnerHTML={{ __html: message.message }}
                    ></p>
                </IonText>
                {message.matchId && (
                    <IonButton
                        size="small"
                        onClick={() =>
                            router.push(
                                `${routes.match}/${message.matchId}/overview`
                            )
                        }
                    >
                        {t("messages.viewMatch")}
                    </IonButton>
                )}
                {message.type === MessageType.Alert &&
                    message.alertType ===
                        RefAssistAlertType.NewFileAvailable && (
                        <IonButton
                            size="small"
                            onClick={() =>
                                router.push(`${routes.refsixRefAssistFiles}`)
                            }
                        >
                            {t("messages.viewFiles")}
                        </IonButton>
                    )}
            </IonCardContent>
        </IonCard>
    );
};
