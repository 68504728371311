import {
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonSegmentButton,
    IonSelectOption,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { values } from "lodash/fp";
import { MatchEvent, MatchPhone, Player, SelectedTeam } from "refsix-js-models";
import { HeaderComponent } from "../../../components";
import { RootState } from "../../../redux/store";
import {
    filterHalfObjectIncludingPenalties,
    getHalfEventTranslation,
    getMinuteStrings,
} from "../../../services/eventService";
import { R6IonSelect } from "../../../components/r6ionic/R6IonSelect";

import { recalculateStats, updateMatch } from "../../../services/matchService";
import { IonFormSegment } from "../../../components/r6ionic/R6IonSegment";
import { useProfile, useUserActionsCheckList } from "../../../components/hooks";
import {
    EditEventForm,
    EventConverter,
    EventType,
} from "../../../utils/eventsUtils";
import "./editEvent.css";
import { getAllStartingPlayers, getAllSubs } from "../../../utils/playersUtils";
import { routes } from "../../../route/constants";
import { omit } from "lodash";
import { R6IonInput } from "../../../components/r6ionic/R6IonInput";
import { findCodeByCountryId } from "../../../services/misconductService";
import { updateUserActionsCheckList } from "../../../services/userActionsCheckListService";

enum NewPlayerRole {
    Player = -10001,
    Official = -10002,
}
export const EditEvent: FC = () => {
    const { t } = useTranslation();
    const router = useIonRouter();
    const profile = useProfile();

    const { matchId, eventTs } = useParams<{
        matchId: string;
        eventTs?: string;
    }>();
    // Have we been given an event Ts to edit?
    const isEdit = !!eventTs;
    const match = useSelector(({ matches }: RootState) =>
        matches.matches.find((match) => match._id === matchId)
    ) as MatchPhone;

    const events = values((match && match.matchEvents!) || {});

    const event: MatchEvent | undefined =
        match && match.matchEvents && match.matchEvents[eventTs || ""];
    const halfEvents = filterHalfObjectIncludingPenalties(events);

    const eventConverter = EventConverter(match);
    const defaultValues = {
        "eventType-select": EventType.Goal,
        "team-select": SelectedTeam.home,
    };
    const formMethods = useForm<EditEventForm>({
        defaultValues: event ? eventConverter.input(event) : defaultValues,
    });
    const userActionsCheckList = useUserActionsCheckList();
    const {
        handleSubmit,
        formState: { errors },
        watch,
        getFieldState,
    } = formMethods;

    const watchedPeriodSelect = watch("period-select");
    const teamSelect = watch("team-select");
    const eventTypeSelect = watch("eventType-select");
    const playerSelect = watch("player-select");
    const playerOnSelect = watch("player-on-select");
    const playerOffSelect = watch("player-off-select");

    const selectedEventIsAny = (...eventTypes: EventType[]) =>
        eventTypes.some((eventType) => eventType === eventTypeSelect);

    const getPlayersForSelectedTeam = () => {
        try {
            return teamSelect === SelectedTeam.home
                ? match.players![match.homeTeam]
                : match.players![match.awayTeam];
        } catch (e) {
            return [];
        }
    };
    const setPlayersForSelectedTeam = (
        match: MatchPhone,
        players: Player[]
    ) => {
        if (teamSelect === SelectedTeam.home) {
            if (match.players![match.homeTeam]) {
                match.players![match.homeTeam].splice(0, 0, ...players);
            } else {
                match.players![match.homeTeam] = players;
            }
        } else if (teamSelect === SelectedTeam.away) {
            if (match.players![match.awayTeam]) {
                match.players![match.awayTeam].splice(0, 0, ...players);
            } else {
                match.players![match.awayTeam] = players;
            }
        }
        return match;
    };
    const handleSave = async (data: EditEventForm) => {
        const res = eventConverter.output(data);

        // Has the minutes or selected period been updated
        if (
            isEdit &&
            !getFieldState("period-select").isDirty &&
            !getFieldState("minute-select").isDirty
        ) {
            // just set the timestamp back to the original if not
            res.timestamp = parseInt(eventTs);
        }
        const newPlayers: Player[] = [];
        if (
            playerSelect == NewPlayerRole.Player ||
            playerSelect == NewPlayerRole.Official
        ) {
            // We need to create a player/official
            const isTeamOfficial = playerSelect == NewPlayerRole.Official;
            const newPlayer = new Player(
                data["player-name"],
                data["player-number"],
                false,
                true,
                isTeamOfficial
            );
            newPlayers.push(newPlayer);
            (res as any).player = newPlayer;
        }
        if (
            playerOnSelect == NewPlayerRole.Player ||
            playerOnSelect == NewPlayerRole.Official
        ) {
            // We need to create a player/official
            const isTeamOfficial = playerOnSelect == NewPlayerRole.Official;
            const newPlayer = new Player(
                data["player-on-name"],
                data["player-on-number"],
                false,
                false,
                isTeamOfficial
            );
            newPlayers.push(newPlayer);
            (res as any).playerOn = newPlayer;
        }
        if (
            playerOffSelect == NewPlayerRole.Player ||
            playerOffSelect == NewPlayerRole.Official
        ) {
            // We need to create a player/official
            const isTeamOfficial = playerOffSelect == NewPlayerRole.Official;
            const newPlayer = new Player(
                data["player-off-name"],
                data["player-off-number"],
                false,
                true,
                isTeamOfficial
            );
            newPlayers.push(newPlayer);
            (res as any).playerOff = newPlayer;
        }

        const newMatch = {
            players: {
                [match.homeTeam]: [] as Player[],
                [match.awayTeam]: [] as Player[],
            },
            ...match,
            matchEvents: {
                ...omit(match.matchEvents, eventTs || ""), // delete the previous event
                [res.timestamp]: res, // and add it back in
            },
        };
        setPlayersForSelectedTeam(newMatch, newPlayers);

        const savedMatch = await updateMatch(newMatch);
        if (savedMatch && profile) {
            if (!userActionsCheckList.editMatchEvent) {
                updateUserActionsCheckList({ editMatchEvent: true });
            }
            recalculateStats(savedMatch, profile);
            if (router.canGoBack()) {
                router.goBack();
            } else {
                router.push(`${routes.match}/${match._id}/overview`, "root");
            }
        }
        return res;
    };
    const misconductCodes = () => {
        if (match.misconductCodeId === "custom") {
            return match.customMisconductCodes || [];
        } else if (match.misconductCodeId) {
            return findCodeByCountryId(match.misconductCodeId)?.codes || [];
        }
        return [];
    };

    // customMisconductCodes
    return (
        <FormProvider {...formMethods}>
            <IonPage>
                <HeaderComponent
                    showBackButton
                    title={t("fixture.Fixture.addIncident")}
                    headerEndText={t("general.save")}
                    onClickEndButton={handleSubmit(handleSave)}
                />
                <IonContent>
                    <IonCard>
                        <IonFormSegment
                            name={"team-select"}
                            value={SelectedTeam.home}
                        >
                            <IonSegmentButton
                                className="segment-button"
                                value={SelectedTeam.home}
                                data-testid="home-team-segment"
                            >
                                <IonLabel>{match.homeTeam}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton
                                className="segment-button"
                                value={SelectedTeam.away}
                                data-testid="away-team-segment"
                            >
                                <IonLabel>{match.awayTeam}</IonLabel>
                            </IonSegmentButton>
                        </IonFormSegment>
                    </IonCard>

                    <IonCard>
                        <IonFormSegment
                            name={"eventType-select"}
                            value={EventType.Goal}
                        >
                            <IonSegmentButton
                                className="segment-button-type"
                                value={EventType.Goal}
                                data-testid="goal-event-segment"
                            >
                                <IonLabel>
                                    {t("fixture.FixtureIncident.goal")}
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton
                                className="segment-button-type"
                                value={EventType.Yellow}
                                data-testid="yellow-event-segment"
                            >
                                <IonLabel>
                                    {t("fixture.FixtureIncident.yellow")}
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton
                                className="segment-button-type"
                                value={EventType.Red}
                                data-testid="red-event-segment"
                            >
                                <IonLabel>
                                    {t("fixture.FixtureIncident.red")}
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton
                                className="segment-button-type"
                                value={EventType.Subs}
                                data-testid="subs-event-segment"
                            >
                                <IonLabel>{t("general.subs")}</IonLabel>
                            </IonSegmentButton>
                        </IonFormSegment>
                    </IonCard>
                    <IonCard>
                        <IonItem
                            lines="none"
                            className={`item-title ${
                                errors["period-select"] && "danger"
                            }`}
                        >
                            <IonLabel>
                                {t("fixture.FixtureIncident.period")}
                            </IonLabel>
                            <R6IonSelect
                                name="period-select"
                                rules={{ required: true }}
                                slot={"end"}
                            >
                                {halfEvents.map((event, idx) => {
                                    return (
                                        <IonSelectOption
                                            value={event.index}
                                            key={idx}
                                        >
                                            {match.periodsNo &&
                                                t(
                                                    getHalfEventTranslation(
                                                        parseInt(
                                                            match.periodsNo
                                                        ),
                                                        event
                                                    )
                                                )}
                                        </IonSelectOption>
                                    );
                                })}
                            </R6IonSelect>
                        </IonItem>
                    </IonCard>
                    {halfEvents[watchedPeriodSelect] &&
                        halfEvents[watchedPeriodSelect].playing && (
                            <IonCard>
                                <IonItem
                                    lines="none"
                                    className={`item-title ${
                                        errors["minute-select"] && "danger"
                                    }`}
                                >
                                    <IonLabel>
                                        {t("fixture.FixtureIncident.minute")}
                                    </IonLabel>
                                    <R6IonSelect
                                        name="minute-select"
                                        rules={{ required: true }}
                                        slot={"end"}
                                    >
                                        {getMinuteStrings(
                                            halfEvents[watchedPeriodSelect]
                                        ).map((minute, idx) => (
                                            <IonSelectOption
                                                value={minute}
                                                key={idx}
                                            >
                                                {minute + "'"}
                                            </IonSelectOption>
                                        ))}
                                    </R6IonSelect>
                                </IonItem>
                            </IonCard>
                        )}

                    {selectedEventIsAny(EventType.Goal) && (
                        <IonCard>
                            <IonItem
                                lines="none"
                                className={`item-title ${
                                    errors["goal-select"] && "danger"
                                }`}
                            >
                                <IonLabel>
                                    {t("fixture.FixtureIncident.goal")}
                                </IonLabel>
                                <R6IonSelect
                                    name="goal-select"
                                    rules={{ required: true }}
                                    slot={"end"}
                                >
                                    <IonSelectOption value={"1"}>
                                        {t(
                                            "fixture.FixtureIncident.normalGoal"
                                        )}
                                    </IonSelectOption>
                                    <IonSelectOption value={"2"}>
                                        {t("fixture.FixtureIncident.ownGoal")}
                                    </IonSelectOption>
                                    <IonSelectOption value={"3"}>
                                        {t("fixture.FixtureIncident.penalty")}
                                    </IonSelectOption>
                                    <IonSelectOption value={"4"}>
                                        {t("fixture.FixtureIncident.freeKick")}
                                    </IonSelectOption>
                                </R6IonSelect>
                            </IonItem>
                        </IonCard>
                    )}

                    {selectedEventIsAny(
                        EventType.Goal,
                        EventType.Yellow,
                        EventType.Red
                    ) && (
                        <IonCard>
                            <IonItem
                                lines="none"
                                className={`item-title ${
                                    errors["player-select"] && "danger"
                                }`}
                            >
                                <IonLabel>
                                    {t("fixture.FixtureIncident.player")}
                                </IonLabel>
                                <R6IonSelect
                                    name="player-select"
                                    rules={
                                        {
                                            // required:
                                            //     eventTypeSelect !== EventType.Goal,
                                        }
                                    }
                                    slot={"end"}
                                >
                                    {getPlayersForSelectedTeam().map(
                                        (player, idx) => (
                                            <IonSelectOption
                                                value={player.number}
                                                key={idx}
                                            >
                                                {player.name}
                                            </IonSelectOption>
                                        )
                                    )}
                                    <IonSelectOption
                                        value={NewPlayerRole.Player}
                                    >
                                        --{" "}
                                        {t("fixture.FixtureIncident.playerNew")}{" "}
                                        --
                                    </IonSelectOption>
                                    <IonSelectOption
                                        value={NewPlayerRole.Official}
                                    >
                                        --{" "}
                                        {t(
                                            "fixture.FixtureIncident.teamOfficialNew"
                                        )}{" "}
                                        --
                                    </IonSelectOption>
                                </R6IonSelect>
                            </IonItem>
                            {(playerSelect == NewPlayerRole.Player ||
                                playerSelect == NewPlayerRole.Official) && (
                                <IonItem>
                                    <R6IonInput
                                        name={"player-number"}
                                        placeholder={"--"}
                                        inputMode={"numeric"}
                                    />
                                    <R6IonInput
                                        name={"player-name"}
                                        placeholder={t(
                                            "fixture.FixtureTeam.player"
                                        )}
                                        inputMode={"text"}
                                    />
                                </IonItem>
                            )}
                        </IonCard>
                    )}

                    {selectedEventIsAny(EventType.Subs) && (
                        <>
                            <IonCard>
                                <IonItem
                                    lines="none"
                                    className={`item-title ${
                                        errors["player-on-select"] && "danger"
                                    }`}
                                >
                                    <IonLabel>
                                        {t("fixture.FixtureIncident.playerOn")}
                                    </IonLabel>
                                    <R6IonSelect
                                        name="player-on-select"
                                        rules={{
                                            required: true,
                                        }}
                                        slot={"end"}
                                    >
                                        {getAllSubs(
                                            getPlayersForSelectedTeam()
                                        ).map((player, idx) => (
                                            <IonSelectOption
                                                value={player.number}
                                                key={idx}
                                            >
                                                {player.name}
                                            </IonSelectOption>
                                        ))}
                                        <IonSelectOption
                                            value={NewPlayerRole.Player}
                                        >
                                            --{" "}
                                            {t(
                                                "fixture.FixtureIncident.playerNew"
                                            )}{" "}
                                            --
                                        </IonSelectOption>
                                        <IonSelectOption
                                            value={NewPlayerRole.Official}
                                        >
                                            --{" "}
                                            {t(
                                                "fixture.FixtureIncident.teamOfficialNew"
                                            )}{" "}
                                            --
                                        </IonSelectOption>
                                    </R6IonSelect>
                                </IonItem>
                                {(playerOnSelect == NewPlayerRole.Player ||
                                    playerOnSelect ==
                                        NewPlayerRole.Official) && (
                                    <IonItem>
                                        <R6IonInput
                                            name={"player-on-number"}
                                            placeholder={"--"}
                                            inputMode={"numeric"}
                                        />
                                        <R6IonInput
                                            name={"player-on-name"}
                                            placeholder={t(
                                                "fixture.FixtureTeam.player"
                                            )}
                                            inputMode={"text"}
                                        />
                                    </IonItem>
                                )}
                            </IonCard>

                            <IonCard>
                                <IonItem
                                    lines="none"
                                    className={`item-title ${
                                        errors["player-off-select"] && "danger"
                                    }`}
                                >
                                    <IonLabel>
                                        {t("fixture.FixtureIncident.playerOff")}
                                    </IonLabel>
                                    <R6IonSelect
                                        name="player-off-select"
                                        rules={{
                                            required: true,
                                        }}
                                        slot={"end"}
                                    >
                                        {getAllStartingPlayers(
                                            getPlayersForSelectedTeam()
                                        ).map((player, idx) => (
                                            <div key={idx}>
                                                <IonSelectOption
                                                    value={player.number}
                                                    key={idx}
                                                >
                                                    {player.name}
                                                </IonSelectOption>
                                            </div>
                                        ))}
                                        <IonSelectOption
                                            value={NewPlayerRole.Player}
                                        >
                                            --{" "}
                                            {t(
                                                "fixture.FixtureIncident.playerNew"
                                            )}{" "}
                                            --
                                        </IonSelectOption>
                                        <IonSelectOption
                                            value={NewPlayerRole.Official}
                                        >
                                            --{" "}
                                            {t(
                                                "fixture.FixtureIncident.teamOfficialNew"
                                            )}{" "}
                                            --
                                        </IonSelectOption>
                                    </R6IonSelect>
                                </IonItem>
                                {(playerOffSelect == NewPlayerRole.Player ||
                                    playerOffSelect ==
                                        NewPlayerRole.Official) && (
                                    <IonItem>
                                        <R6IonInput
                                            name={"player-off-number"}
                                            placeholder={"--"}
                                            inputMode={"numeric"}
                                        />
                                        <R6IonInput
                                            name={"player-off-name"}
                                            placeholder={t(
                                                "fixture.FixtureTeam.player"
                                            )}
                                            inputMode={"text"}
                                        />
                                    </IonItem>
                                )}
                            </IonCard>
                        </>
                    )}

                    {selectedEventIsAny(EventType.Yellow, EventType.Red) && (
                        <IonCard>
                            <IonItem
                                lines="none"
                                className={`item-title ${
                                    errors["reason-select"] && "danger"
                                }`}
                            >
                                <IonLabel>
                                    {t("fixture.FixtureIncident.reason")}
                                </IonLabel>
                                <R6IonSelect
                                    name="reason-select"
                                    rules={{
                                        required: true,
                                    }}
                                    slot={"end"}
                                >
                                    {misconductCodes()
                                        .filter(
                                            (code) =>
                                                eventTypeSelect.toString() ==
                                                code.type
                                        )
                                        .map((code, idx) => (
                                            <div key={idx}>
                                                <IonSelectOption
                                                    value={code.code}
                                                    key={code.code}
                                                >
                                                    {code.code} {code.reason}
                                                </IonSelectOption>
                                                {code.subCodes?.map(
                                                    (subCode, i) => (
                                                        <IonSelectOption
                                                            key={subCode.code}
                                                            value={subCode.code}
                                                        >
                                                            <IonText className="indent-left text-small">
                                                                {subCode.code} -
                                                                {subCode.reason}
                                                            </IonText>
                                                        </IonSelectOption>
                                                    )
                                                )}
                                            </div>
                                        ))}
                                </R6IonSelect>
                            </IonItem>
                        </IonCard>
                    )}
                </IonContent>
            </IonPage>
        </FormProvider>
    );
};
