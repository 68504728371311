// ionic react functional component named CalendarSync

import { FC, useEffect, useState } from "react";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonList,
    IonPage,
    IonText,
} from "@ionic/react";
import { HeaderComponent } from "../../../../components";
import { useTranslation } from "react-i18next";
import {
    getPushNotificationStatus,
    PushNotificationStatus,
    requestPushConsent,
} from "../../../../services/OneSignal";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { useAppState } from "@capacitor-community/app-react";

export const PushConsentPage: FC = () => {
    const { t } = useTranslation();
    const [deviceInfo, setDeviceInfo] = useState<any>(null);
    const { state } = useAppState();

    const updateDeviceInfo = () => {
        getPushNotificationStatus()
            .then((status) => {
                setDeviceInfo(status);
            })
            .catch((err) => {
                console.log("Push notifications not supported", err);
            });
    };

    const hasPermission = () => {
        return deviceInfo === PushNotificationStatus.Authorized;
    };

    useEffect(() => {
        if (state) {
            // if they have to leave the app to enable via settings then refresh
            updateDeviceInfo();
        }
    }, [state]);

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                titleTestId={"push-consent-page"}
                title={t("notifications.pushNotifications")}
            />
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonText>
                            {t("notifications.pushNotifications")}
                        </IonText>
                        <IonIcon
                            style={
                                hasPermission()
                                    ? { color: "green" }
                                    : { color: "red" }
                            }
                            icon={
                                hasPermission()
                                    ? checkmarkCircleOutline
                                    : closeCircleOutline
                            }
                            slot={"end"}
                        ></IonIcon>
                        {!hasPermission() && (
                            <IonButton
                                slot="end"
                                style={{ marginLeft: 10 }}
                                onClick={() => requestPushConsent()}
                            >
                                {t("general.allow")}
                            </IonButton>
                        )}
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};
