import { useEffect } from "react";
import { useIonAlert, useIonLoading } from "@ionic/react";
import {
    refreshSubscriptions,
    SubscriptionService,
} from "../../services/subscriptionService";
import { WaitPromise } from "../../utils/promises";
import { isPlatform } from "@ionic/core";
import { useTranslation } from "react-i18next";
import "cordova-plugin-purchase";
import { isAndroid, isIos } from "../../services/platformDetection";
import { getCurrentUsername } from "../../utils/username";
import { trackEvent } from "../../services/analytics/analyticsService";

export const useStoreRefreshSubscriptions: () => () => void = () => {
    const { t } = useTranslation();
    const store = CdvPurchase.store;
    const [displayAlert] = useIonAlert();
    const [presentLoader, dismissLoader] = useIonLoading();

    async function presentSuccessAlert() {
        return await displayAlert({
            header: t("myaccount.refresh.success.title"),
            message: t("myaccount.refresh.success.description"),
            buttons: [
                {
                    text: t("help.OnboardingVideo.controls.ok"),
                    role: "confirm",
                },
            ],
        });
    }

    // useEffect to call dismissLoader when the component unmounts
    useEffect(() => {
        return () => {
            dismissLoader();
        };
    }, []);

    return async () => {
        // If on a native device with IAP
        store.applicationUsername = getCurrentUsername;
        trackEvent("myAccountService", {
            category: "restore",
            label: "restore",
        });
        const res = store.restorePurchases();
        if (store && (isIos() || isAndroid())) {
            await displayAlert({
                header: isPlatform("ios")
                    ? t("myaccount.refreshTextIos")
                    : t("myaccount.refreshTextAndroid"),
                buttons: [
                    {
                        text: t("help.OnboardingVideo.controls.ok"),
                        role: "confirm",
                        handler: async () => {
                            await presentLoader();
                            const refreshSubs = () => {
                                const ss = SubscriptionService.getInstance();
                                return ss
                                    .refreshSubscription()
                                    .catch(async (x) => {
                                        await dismissLoader();
                                        await displayAlert({
                                            header: t(
                                                "myaccount.refresh.restoreFailure.title"
                                            ),
                                            message: t(
                                                "myaccount.refresh.restoreFailure.description"
                                            ),
                                            buttons: [
                                                {
                                                    text: t(
                                                        "help.OnboardingVideo.controls.ok"
                                                    ),
                                                    role: "confirm",
                                                },
                                            ],
                                        });
                                        console.log(JSON.stringify(x));
                                    });
                            };
                            // TODO check if works now promise based
                            res.then(async () => {
                                await WaitPromise(5 * 1000);
                                await refreshSubs();
                                await dismissLoader();
                                await presentSuccessAlert();
                            });
                            // TODO check if works now promise based
                            res.catch(async () => {
                                await WaitPromise(5 * 1000);
                                await refreshSubs();
                                await dismissLoader();
                                await displayAlert({
                                    header: t(
                                        "myaccount.refresh.failure.title"
                                    ),
                                    message: t(
                                        "myaccount.refresh.failure.description"
                                    ),
                                    buttons: [
                                        {
                                            text: t(
                                                "help.OnboardingVideo.controls.ok"
                                            ),
                                            role: "confirm",
                                        },
                                    ],
                                });
                            });
                        },
                    },
                ],
                onDidDismiss: (e: CustomEvent) => {},
            });
        } else {
            // In the browser, no iap native stuff
            try {
                await presentLoader();
                await refreshSubscriptions();
                await dismissLoader();
                await presentSuccessAlert();
            } catch (e: any) {
                console.log("Error refreshing subs online", e);
                await dismissLoader();
            }
        }
    };
};
