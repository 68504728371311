import { FC } from "react";
import { IonText } from "@ionic/react";
import { MatchScore, ScoreType } from "../MatchScore";
import { MatchPhone, SelectedTeam } from "refsix-js-models";
import "./TeamRowComponent.css";
import { hasFeatureAccess } from "../../services/subscriptionService";
import { CardIndicator } from "../CardIndicatorComponent";

interface TeamRowComponentProps {
    match: MatchPhone;
    side: SelectedTeam.home | SelectedTeam.away;
    scoreType: ScoreType;
}

const TeamRowComponent: FC<TeamRowComponentProps> = ({
    match,
    side,
    scoreType,
}) => {
    const hasColorAccess = hasFeatureAccess("colors");
    const teamName =
        side === SelectedTeam.home ? match.homeTeam : match.awayTeam;
    const teamColor =
        side === SelectedTeam.home ? match.homeColor : match.awayColor;
    const hasTeamColor = teamColor && teamColor.length;

    return (
        <div
            color="transparent"
            className={`team-row ${side}-team-row`}
            style={
                hasTeamColor && hasColorAccess
                    ? {
                          borderLeft: `5px solid ${teamColor}`,
                      }
                    : { borderLeft: "none" }
            }
        >
            <div
                className="name-cards"
                style={
                    hasTeamColor && hasColorAccess
                        ? {
                              paddingLeft: `5px`,
                          }
                        : { paddingLeft: "0" }
                }
            >
                <div className="team-name">
                    <IonText>{teamName}</IonText>
                </div>
                <CardIndicator match={match} side={side}></CardIndicator>
            </div>

            <div className="team-score">
                <MatchScore
                    match={match}
                    scoreType={scoreType}
                    color="primary"
                />
            </div>
        </div>
    );
};

export default TeamRowComponent;
