import pako from "pako";

export class InflateData {
    atob: Function;

    constructor(atob: (data: string) => string) {
        this.atob = atob;
    }

    _base64ToArrayBuffer(base64: string) {
        const binary_string = atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    inflateData(gzippedData: string) {
        const compressed = this._base64ToArrayBuffer(gzippedData);
        return pako.inflate(compressed, { to: "string" });
    }
}
