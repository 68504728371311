import React, { FC } from "react";
import {
    IonButton,
    IonContent,
    IonItem,
    IonPage,
    IonText,
    useIonRouter,
} from "@ionic/react";
import RefSixImage from "../../assets/images/refsix-words-h80px.png";
import "./password.css";
import { useTranslation } from "react-i18next";
import { routes } from "../../route/constants";

const ForgotPasswordComplete: FC<{
    redirectTo?: string;
    buttonText?: string;
}> = ({ redirectTo, buttonText }) => {
    const { t } = useTranslation();
    const route = useIonRouter();
    return (
        <IonPage>
            <IonContent>
                <div className="middle">
                    <div className="match-log-event-separator-wrapper">
                        <img
                            alt=""
                            src={RefSixImage}
                            width="50%"
                            height="50%"
                        />
                    </div>
                </div>
                <IonItem lines="none" color="transparent">
                    <IonText>{t("login.ForgotPassword.confirmation")}</IonText>
                </IonItem>
                <IonButton
                    onClick={() =>
                        route.push(redirectTo || routes.login, "root")
                    }
                    className="login-button"
                    expand="block"
                >
                    {t(buttonText || "general.login")}
                </IonButton>
            </IonContent>
        </IonPage>
    );
};

export default ForgotPasswordComplete;
