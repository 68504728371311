import { setShowOnBoardingFlow } from "../../redux/actions/appStatus";
import { routes } from "../../route/constants";
import { UseIonRouterResult } from "@ionic/react";
import { Dispatch } from "react";
import { TFunction } from "react-i18next";

export const endOnboarding = async (
    username: string | undefined,
    dispatch: Dispatch<any>,
    route: UseIonRouterResult,
    t: TFunction
) => {
    dispatch(setShowOnBoardingFlow(false));
    return route.push(routes.refsixFixtures, "root", "replace");
};
