import {
    IonButton,
    IonCard,
    IonContent,
    IonItem,
    IonList,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonText,
    IonTextarea,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../../../components";
import refsixProLogo from "../../../../assets/images/go-pro/REFSIXPROInBadge.png";
import { FC, useEffect, useMemo, useState } from "react";
import { trackEvent } from "../../../../services/analytics/analyticsService";
import { Capacitor } from "@capacitor/core";
import { useProfile } from "../../../../components/hooks";
import { useParams } from "react-router";
import { routes } from "../../../../route/constants";
import { updateUserActionsCheckList } from "../../../../services/userActionsCheckListService";
import { store } from "../../../../redux/store";

export const CancelSubscription: FC = () => {
    const router = useIonRouter();
    const { t } = useTranslation();
    const profile = useProfile();
    const { provider } = useParams<{ provider: string }>();
    const userActionsCheckList = store.getState().userActionsCheckList;
    const cancellationReasons = useMemo(
        () => [
            t("cancellation.reasons.season-ended"),
            t("cancellation.reasons.injury"),
            t("cancellation.reasons.retirement"),
            t("cancellation.reasons.too-expensive"),
            t("cancellation.reasons.missing-feature"),
            t("cancellation.reasons.technical-issues"),
            t("cancellation.reasons.other"),
        ],
        []
    );

    const [feedbackText, setFeedbackText] = useState<string>("");
    const [cancellationReason, setCancellationReason] = useState<string>("");

    useEffect(() => {
        if (!userActionsCheckList.visitedCancellationFlow) {
            updateUserActionsCheckList({
                visitedCancellationFlow: true,
            });
        }
    }, []);

    const handleDisableCancelButton = (): boolean => {
        if (
            cancellationReason === t("cancellation.reasons.other") &&
            feedbackText.length <= 0
        ) {
            return true;
        } else if (!cancellationReason) {
            return true;
        }

        return false;
    };

    const cancelSubscriptionHandle = async () => {
        const hasGoogle = provider === "google";
        const hasApple = provider === "apple";
        const hasStripe = provider === "stripe";

        trackEvent("CancellationReason", {
            reasonCode: cancellationReason,
            reasonText: feedbackText,
            hasGoogleSubscription: hasGoogle,
            hasAppleSubscription: hasApple,
            hasStripeSubscription: hasStripe,
            hasInAppPurchase: hasApple || hasGoogle,
        });

        if (Capacitor.getPlatform() === "android" && hasGoogle) {
            window.open(
                "https://play.google.com/store/account/subscriptions",
                "_system"
            );
        } else if (Capacitor.getPlatform() === "ios" && hasApple) {
            window.open(
                "itms-apps://apps.apple.com/account/subscriptions",
                "_system"
            );
        } else {
            // Send email to support
            const subject = "Cancel Subscription";
            let body =
                "Username: " +
                profile?.username +
                "\nReason: " +
                "" +
                "\nSubscription: ";
            if (feedbackText && feedbackText.length > 0) {
                body = feedbackText + "\n\n" + body;
            }

            body += hasStripe ? "Website " : "";
            body += hasGoogle ? "PlayStore " : "";
            body += hasApple ? "AppStore " : "";
            body = body.trim() + "\n\n";

            const url =
                "mailto:contact@refsix.com?subject=" +
                encodeURIComponent(subject) +
                "&body=" +
                encodeURIComponent(body);
            window.open(url, "_system");
            router.push(routes.refsixFixtures, "root");
        }
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                title={t("myaccount.cancelSubscription")}
                titleTestId="reason-cancel-subscription-header"
            />
            <IonContent>
                <IonCard color="transparent">
                    <div className="align-center">
                        <img
                            src={refsixProLogo}
                            alt={t("menu.upgrade")}
                            style={{ height: "110px" }}
                        ></img>
                    </div>
                </IonCard>

                <IonCard>
                    <IonItem lines="none">
                        <IonText className="text-small">
                            {t("cancellation.whyCancelHeading")}
                        </IonText>
                    </IonItem>

                    <IonList>
                        <IonRadioGroup
                            value={cancellationReason}
                            onIonChange={(val) =>
                                setCancellationReason(val.detail.value)
                            }
                        >
                            {cancellationReasons.map((reason, index) => {
                                return (
                                    <IonItem lines="none" key={index}>
                                        <IonRadio
                                            justify="space-between"
                                            className="radio-button"
                                            mode="ios"
                                            data-testid={`cancel-subscription-reason-${index}`}
                                            value={reason}
                                        >
                                            {`${reason}`}
                                        </IonRadio>
                                    </IonItem>
                                );
                            })}
                        </IonRadioGroup>
                    </IonList>
                </IonCard>
                <IonCard>
                    <IonItem lines="none">
                        <IonTextarea
                            rows={5}
                            data-testid="cancel-subscription-textarea"
                            placeholder={t("cancellation.reasonText")}
                            onIonChange={(e) =>
                                setFeedbackText(e.detail.value!)
                            }
                        ></IonTextarea>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonButton
                        data-testid="continue-cancel-subscription-button"
                        disabled={handleDisableCancelButton()}
                        expand="block"
                        onClick={() => cancelSubscriptionHandle()}
                    >
                        <IonText>
                            {t("cancellation.continueCancelling")}
                        </IonText>
                    </IonButton>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
