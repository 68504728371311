import {
    EventName,
    GoalEvent,
    HalfEvent,
    IncidentEvent,
    MatchEvent,
    MatchPhone,
    OfficialRole,
    PenaltyShotEvent,
    ReasonType,
    SelectedTeam,
    Stats,
} from "refsix-js-models";

export function processMatch(match: MatchPhone) {
    let stats = new Stats();
    let sinBinsGiven = [];
    let periodsNumber = match.periodsNo || "2";
    if (match.playedExtraTime) {
        stats.playedET[0] = 1;
        stats.playedET[parseInt(periodsNumber) - 1] = 1;
    }

    if (match.playedPenalties) {
        stats.playedPenalties[0] = 1;
        stats.playedPenalties[parseInt(periodsNumber) - 1] = 1;
    }
    if (match.matchEvents) {
        var sortedEvents = Object.values(match.matchEvents).sort(function (
            eventA: MatchEvent,
            eventB: MatchEvent
        ) {
            return eventA.timestamp - eventB.timestamp;
        });

        sortedEvents.forEach(function (event: MatchEvent) {
            return processMatchEvent(stats, event, match, sinBinsGiven);
        });
        if (sinBinsGiven.length > 0) {
            sortedEvents.forEach(function (event: MatchEvent) {
                if (event.eventName === EventName.goal) {
                    let goalEvent = <GoalEvent>event;
                    return processPossibleGoalDuringSinBin(
                        stats,
                        goalEvent,
                        sinBinsGiven
                    );
                }
            });
        }
    }
    processFees(stats, match);
    finaliseMatch(stats);

    return stats;
}

export function processFees(stats: Stats, match: MatchPhone) {
    if (match.earnings) {
        stats.feesTotal = match.earnings.fees ? match.earnings.fees : 0;
        stats.expensesTotal = match.earnings.expenses
            ? match.earnings.expenses
            : 0;
        stats.earningsTotal = stats.feesTotal + stats.expensesTotal;

        switch (match.officialRole) {
            case OfficialRole.assistant:
                stats.earningsAsAssistantTotal = stats.earningsTotal;
                break;
            case OfficialRole.fourthOfficial:
                stats.earningsAsFourthOfficialTotal = stats.earningsTotal;
                break;
            case OfficialRole.observer:
                stats.earningsAsObserverTotal = stats.earningsTotal;
                break;
            default:
                stats.earningsAsRefereeTotal = stats.earningsTotal;
                break;
        }
    }
}

export function finaliseMatch(stats: Stats) {
    if (stats.goalsHomeTotal > stats.goalsAwayTotal) {
        stats.winnerHome = 1;
    } else if (stats.goalsHomeTotal < stats.goalsAwayTotal) {
        stats.winnerAway = 1;
    } else {
        stats.winnerDraw = 1;
    }

    // TODO remove, this is just for legacy app support
    stats.yellow = stats.yellowCardTotal;
    stats.red = stats.redCardTotal;
    stats.goals = stats.goalsTotal;

    return stats;
}

let _getEventIndex = function (name: String, periodsNumber: number) {
    switch (name.trim()) {
        case "1st Period":
        case "First Half":
        case "1st Third":
        case "1st Quarter":
            return 0;
        case "Second Half":
        case "2nd Third":
        case "2nd Quarter":
            return 1;
        case "3rd Third":
        case "3rd Quarter":
            return 2;
        case "4th Quarter":
            return 3;
        case "ET1":
            return periodsNumber;
        case "ET2":
            return periodsNumber + 1;
    }

    console.error("ERROR getting event index");
};

export function processMatchEvent(
    stats: Stats,
    matchEvent: MatchEvent,
    match: MatchPhone,
    sinBinsGiven: any[]
) {
    switch (matchEvent.eventName) {
        case EventName.goal:
            let goalEvent = <GoalEvent>matchEvent;
            processGoal(stats, goalEvent);
            break;
        // case EventName.substitution:
        //     let substitutionEvent = <SubstitutionEvent>matchEvent;
        //     break;
        // case EventName.penaltyShot:
        // let penaltyShotEvent = <PenaltyShotEvent>matchEvent;
        // break;
        case EventName.penaltyShot:
            let penaltiesEvent = <PenaltyShotEvent>matchEvent;
            processPenalties(stats, penaltiesEvent);
            break;
        case EventName.half:
            let halfEvent = <HalfEvent>matchEvent;
            let periodsNumber = match.periodsNo || "2";
            if (halfEvent.playing) {
                processInjuryTime(stats, halfEvent, parseInt(periodsNumber));
                processMinutesPlayed(
                    stats,
                    halfEvent,
                    match.officialRole || OfficialRole.referee
                );
            }
            break;
        case EventName.incident:
            let incident = <IncidentEvent>matchEvent;
            processIncident(stats, incident, match, sinBinsGiven);
            break;
    }
}

function processPossibleGoalDuringSinBin(
    stats: Stats,
    goalEvent: GoalEvent,
    sinBinsGiven: any[]
) {
    sinBinsGiven.forEach(function (sinBin) {
        if (!sinBin.leavesTs && !sinBin.entersTs) {
            console.log("ERROR no enter timestamp nor leave timestamp value");
            return;
        }
        if (
            ((!sinBin.leavesTs && goalEvent.timestamp >= sinBin.entersTs) ||
                (goalEvent.timestamp >= sinBin.entersTs &&
                    goalEvent.timestamp <= sinBin.leavesTs)) &&
            goalEvent.half === sinBin.half
        ) {
            if (goalEvent.team.side === sinBin.team) {
                stats.sinBinsGoalPlayerTeam++;
            } else if (goalEvent.team.side !== sinBin.team) {
                stats.sinBinsGoalOppositeTeam++;
            }
        }
    });
    return stats;
}

function _generateIncidentStats(
    cardColour: ReasonType,
    stats: Stats,
    event: IncidentEvent,
    match: MatchPhone
) {
    stats[cardColour + "CardTotal"]++;

    if (event.team.side === SelectedTeam.home) {
        stats[cardColour + "CardHomeTotal"]++;
    } else if (event.team.side === SelectedTeam.away) {
        stats[cardColour + "CardAwayTotal"]++;
    }

    let prefix = match.misconductCodeId
        ? match.misconductCodeId + "-"
        : "fifa-";
    let reasonCode: string = event.reason;
    if (typeof event.reason === "object") {
        // @ts-ignore
        reasonCode = event.reason.code;
    }
    let propName = prefix + reasonCode;

    if (!stats[cardColour + "CardCodes"][propName]) {
        stats[cardColour + "CardCodes"][propName] = 0;
    }
    stats[cardColour + "CardCodes"][propName]++;
}

function _updateLeaveTimestamp(sinBinsGiven: any[], event: IncidentEvent) {
    return sinBinsGiven.map(function (sinBin) {
        if (
            !sinBin.leavesTs &&
            sinBin.team === event.team.side &&
            sinBin.player === event.player.number
        ) {
            sinBin.leavesTs = event.timestamp;
        }
        return sinBin;
    });
}

function _processSinBin(
    stats: Stats,
    event: IncidentEvent,
    match: MatchPhone,
    sinBinsGiven: any[]
) {
    stats.sinBinsTotal++;
    if (match.timings && match.timings.sinBinTimerLength) {
        stats.sinBinsMinutesSpent += match.timings.sinBinTimerLength;
    }
    let entersTs = event.playerEntersSinBinTimestamp;
    let leavesTs = event.playerLeavesSinBinTimestamp;
    if (
        !entersTs &&
        !leavesTs &&
        match.timings &&
        match.timings.sinBinTimerLength
    ) {
        entersTs = event.timestamp;
        leavesTs =
            event.timestamp + match.timings.sinBinTimerLength * 1000 * 60;
    }
    sinBinsGiven.push({
        entersTs: entersTs,
        leavesTs: leavesTs,
        team: event.team.side,
        player: event.player.number,
        half: event.half,
    });
}

export function processIncident(
    stats: Stats,
    event: IncidentEvent,
    match: MatchPhone,
    sinBinsGiven: any[]
) {
    switch (event.card) {
        case ReasonType.yellow:
            _generateIncidentStats(ReasonType.yellow, stats, event, match);
            if (event.sinBin) {
                _processSinBin(stats, event, match, sinBinsGiven);
                // stats.sinBinsByTime
            } else if (event.duringSinBin) {
                sinBinsGiven = _updateLeaveTimestamp(sinBinsGiven, event);
            }
            break;
        case ReasonType.red:
            _generateIncidentStats(ReasonType.red, stats, event, match);
            if (event.duringSinBin) {
                sinBinsGiven = _updateLeaveTimestamp(sinBinsGiven, event);
            }
            break;
    }
    return stats;
}

export function processGoal(stats: Stats, event: GoalEvent) {
    stats.goalsTotal++;
    if (event.team.side === SelectedTeam.home) {
        stats.goalsHomeTotal++;
    } else if (event.team.side === SelectedTeam.away) {
        stats.goalsAwayTotal++;
    }
    return stats;
}

export function processPenalties(stats: Stats, event: PenaltyShotEvent) {
    if (event.team.side === SelectedTeam.home) {
        if (event.goal) {
            stats.penaltyShotHomeScored++;
        } else {
            stats.penaltyShotHomeMissed++;
        }
    } else if (event.team.side === SelectedTeam.away) {
        if (event.goal) {
            stats.penaltyShotAwayScored++;
        } else {
            stats.penaltyShotAwayMissed++;
        }
    }
    return stats;
}

export function processInjuryTime(
    stats: Stats,
    event: HalfEvent,
    periodsNumber: number
) {
    if (event.endTime) {
        let timePlayed = event.endTime - event.timestamp;
        if (timePlayed < event.length) {
            return stats;
        }
        let injuryTimeMillis = Math.abs(timePlayed - event.length);
        // In the future this should use the proper injury time value, there shouldn't be a need to calculate it
        if (injuryTimeMillis > 600000) {
            console.log("INFO injury time greater than 10 minutes");
            return stats;
        }
        stats.injuryTimeTotal += injuryTimeMillis;

        let property = "injuryTimeByHalvesTotal";
        if (periodsNumber === 3) {
            property = "injuryTimeByThirdsTotal";
            stats.injuryTimeThirdsTotal += injuryTimeMillis;
        } else if (periodsNumber === 4) {
            property = "injuryTimeByQuartersTotal";
            stats.injuryTimeQuartersTotal += injuryTimeMillis;
        } else {
            stats.injuryTimeHalvesTotal += injuryTimeMillis;
        }
        let eventIndex = _getEventIndex(event.name, periodsNumber);
        stats[property][eventIndex] = injuryTimeMillis;
    } else {
        console.log("ERROR no endTime when trying to calculate injuryTime");
    }

    return stats;
}

export function processMinutesPlayed(
    stats: Stats,
    event: HalfEvent,
    officialRole: OfficialRole
) {
    if (event.endTime) {
        let timePlayed = (event.endTime - event.timestamp) / 1000 / 60;
        stats.minutesPlayed += timePlayed;

        switch (officialRole) {
            case OfficialRole.assistant:
                stats.minutesPlayedAsAssistant += timePlayed;
                break;
            case OfficialRole.fourthOfficial:
                stats.minutesPlayedAsFourthOfficial += timePlayed;
                break;
            case OfficialRole.observer:
                stats.minutesPlayedAsObserver += timePlayed;
                break;
            default:
                stats.minutesPlayedAsReferee += timePlayed;
                break;
        }
    } else {
        console.log("ERROR no endTime when trying to calculate minutesPlayed");
    }
    return stats;
}
