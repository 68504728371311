import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MatchHeader } from "../../pages/addResult/matchHeader";
import { useMatch } from "../hooks/match";
import { IonSpinner, IonText } from "@ionic/react";
import { GpsMap, GpsMapType } from "../GpsMapComponent";
import { FitnessDataProvider, useFitnessDataContext } from "../hooks";
import { AggregatedStats, Sprint } from "refsix-js-models";
import "./reviewSharedStyles.css";
import { GpsProcessingService } from "refsix-core";
import exampleMatch from "../../support/example-result.json";
import { hasFeatureAccess } from "../../services/subscriptionService";
import { ProTeaser, TeaserStyle } from "../ProTeaser";
import "./hardestMatchReview.css";
import Stat, {
    StatsContainer,
    Content,
    Header,
    MainHeader,
    Row,
    MatchResult,
    MatchSprintsBySpeed,
} from "./reviewCard";

interface DistanceReviewProps {
    matchId?: string;
    aggregatedStats: AggregatedStats;
}

const HardestMatchReview: FC<DistanceReviewProps> = ({
    matchId,
    aggregatedStats,
}) => {
    if (!matchId) {
        return null;
    }

    return (
        <FitnessDataProvider matchId={matchId}>
            <HardestMatchReviewInternal
                matchId={matchId}
                aggregatedStats={aggregatedStats}
            />
        </FitnessDataProvider>
    );
};

const HardestMatchReviewInternal: FC<DistanceReviewProps> = ({
    matchId,
    aggregatedStats,
}) => {
    const { t } = useTranslation();

    const match = useMatch(matchId);
    const sprintMapAccess = hasFeatureAccess("sprints");
    const { gpsData, hrData, loading, reload } = useFitnessDataContext();
    const [sprintsData, setSprintsData] = useState<Sprint<number>[]>();

    useEffect(() => {
        if (match) {
            if (gpsData && gpsData.matchTimings) {
                const gpsService = new GpsProcessingService();

                const sprintsData = gpsService.calculateSprints(
                    gpsData.matchTimings,
                    gpsData.geoPoints
                );
                if (sprintMapAccess) {
                    setSprintsData(sprintsData.sprints);
                } else {
                    setSprintsData(exampleMatch.gps.sprints);
                }
            }
        }
    }, [gpsData, match, gpsData?.matchTimings, loading]);

    if (!matchId || !match) {
        return <IonSpinner name="circular"></IonSpinner>;
    }

    return (
        <div className="hardest-match-review review">
            <div className="top-bit">
                <StatsContainer>
                    <Stat className={"hardest-working-match"}>
                        <Row>
                            <MainHeader>
                                {t("review.hardestWorkingGame")}
                            </MainHeader>
                            <Content>
                                <MatchResult match={match} />
                            </Content>
                        </Row>
                    </Stat>
                    <Stat className={"hardest-working-sprints"}>
                        <Row>
                            <MatchSprintsBySpeed stats={match.stats} />
                            {/* card map */}
                            <div className="fixture-card ion-card-transparent sprint-map">
                                <h2 style={{ marginBottom: 10, fontSize: 25 }}>
                                    {t("stats.stats.Sprints.sprints")}
                                </h2>
                                <ProTeaser
                                    showTeaser={
                                        !sprintMapAccess &&
                                        match._id !== "example-result1"
                                    }
                                    isRefSixEligible={false}
                                    teaserName="review-stats-sprint-map"
                                    teaserStyle={TeaserStyle.button}
                                >
                                    <GpsMap
                                        match={match}
                                        mapType={GpsMapType.SprintMap}
                                        sprintsData={sprintsData}
                                        useDummyData={!sprintMapAccess}
                                        testId="reviewsprintmap"
                                    ></GpsMap>
                                </ProTeaser>
                            </div>
                        </Row>
                    </Stat>
                </StatsContainer>
            </div>
        </div>
    );
};

export default HardestMatchReview;
