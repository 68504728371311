import { useState, useEffect } from 'react';
import { Network, ConnectionStatus } from '@capacitor/network';
import { AvailableResult, notAvailable } from './models';
import { isFeatureAvailable } from './feature-check';
import { Capacitor } from '@capacitor/core';

interface NetworkStatusResult extends AvailableResult {
    networkStatus?: ConnectionStatus;
}

export const availableFeatures = {
    getStatus: isFeatureAvailable('Network', 'getStatus'),
};

if (!Capacitor.isPluginAvailable('Network')) {
    console.warn('The @capacitor/network plugin was not found, did you forget to install it?');
}



export function useStatus(): NetworkStatusResult {

    const [networkStatus, setStatus] = useState<ConnectionStatus>();

    useEffect(() => {
        async function getStatus() {
            const status = await Network.getStatus();
            setStatus(status);
        }
        getStatus();
    }, [Network, setStatus]);

    useEffect(() => {
        Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
            setStatus(status);
        });


    }, [Network, setStatus]);

    if (!availableFeatures.getStatus) {
        return notAvailable;
    }

    return {
        networkStatus,
        isAvailable: true,
    };
}