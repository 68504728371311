import { Reason } from "./reason";
import { ReasonType } from "./enums/reasonType";

export class MisconductCodes {
    id: string;
    name: string;
    locale?: string;
    systemB?: boolean;
    codes: Reason[];

    constructor(
        id: string,
        name: string,
        codes: Reason[],
        locale?: string,
        systemB?: boolean
    ) {
        this.id = id;
        this.name = name;
        this.locale = locale;
        this.systemB = systemB;
        this.codes = codes;
    }

    static fromJSON(json: any): MisconductCodes {
        let codes: Reason[] = [];
        if (json.codes) {
            codes = json.codes.map(function (code: any) {
                return Reason.fromJSON(code);
            });
        }

        return new MisconductCodes(
            json.id,
            json.name,
            codes,
            json.locale,
            json.systemB
        );
    }

    static defaultCodes(): MisconductCodes {
        const yellow = new Reason(
            "Y1",
            "Yellow",
            ReasonType.yellow,
            false,
            false,
            undefined,
            false
        );
        const red = new Reason(
            "R1",
            "Red",
            ReasonType.red,
            true,
            false,
            undefined,
            false
        );
        const teamYellow = new Reason(
            "TY1",
            "Yellow",
            ReasonType.yellow,
            false,
            false,
            undefined,
            true
        );
        const teamRed = new Reason(
            "TR1",
            "Red",
            ReasonType.red,
            true,
            false,
            undefined,
            true
        );
        return new MisconductCodes(
            "custom",
            "Custom",
            [yellow, red, teamYellow, teamRed],
            "en",
            undefined
        );
    }
}
