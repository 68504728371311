import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../components";
import { OnBoardingHeadingComponent } from "./onBoardingHeadingComponent";
import { routes } from "../../route/constants";
import { useDispatch, useSelector } from "react-redux";
import { setShowOnBoardingFlow } from "../../redux/actions/appStatus";
import appleWatchFrame from "../../assets/images/watches/apple-watch-a9-45mm.png";
import { RootState } from "../../redux/store";
import { useProfile, useUsername } from "../../components/hooks";

export const OnBoardingGoToWatch: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const dispatch = useDispatch();
    const watchStatus = useSelector((state: RootState) => state.watchStatus);
    const username = useUsername();
    const userProfile = useProfile();
    const isDanishUser = userProfile?.country?.toUpperCase() === "DK";

    useEffect(() => {
        if (watchStatus.isReachable) {
            finished();
        }
    }, [watchStatus]);

    const finished = () => {
        return route.push(
            routes.refsixOnBoardingSetupComplete,
            "root",
            "replace"
        );
    };

    return (
        <IonPage className="onboarding-complete-page">
            <HeaderComponent
                titleTestId="go-to-watch-header"
                title={t("help.onBoarding.setupComplete")}
                endBtnIconName={"close"}
                headerEndText={t("general.skip")}
                onClickEndButton={() => {
                    finished();
                }}
                endBtnTestId="go-to-watch-skip"
            />
            <IonContent>
                <OnBoardingHeadingComponent
                    progressValue={1}
                    upperTitleInBold={t("help.onBoarding.goToWatch")}
                />
                <div
                    style={{
                        position: "relative",
                        textAlign: "center",
                        marginTop: 20,
                    }}
                    data-testid="onboarding-go-to-watch"
                >
                    <img
                        src={appleWatchFrame}
                        alt="Apple Wach"
                        style={{
                            position: "absolute",
                            zIndex: 2,
                            width: 320,
                            left: 0,
                            right: 0,
                            margin: "0 auto",
                        }}
                    />

                    <div style={{ zIndex: 1, paddingTop: 128 }}>
                        <video
                            autoPlay
                            playsInline
                            muted
                            loop
                            style={{ width: 227 }}
                        >
                            <source
                                src={
                                    "https://assets.refsix.com/videos/apple-watch-s9-open-refsix.mp4"
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
