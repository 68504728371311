import {
    IonCard,
    IonCol,
    IonContent,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonPage,
    IonText,
} from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../../../components";
import { useSettings } from "../../../../components/hooks";
import "./teamPacks.css";
import { useSelector } from "react-redux";
import { ConfigPhone } from "refsix-js-models";
import { updateSettings } from "../../../../services/settingsService";
import { RootState } from "../../../../redux/store";
import { useTeamPacksContext } from "../../../../components/hooks/TeamPacks";
import _ from "lodash";

const TeamPacks: FC = () => {
    const { t } = useTranslation();
    const settings = useSelector((root: RootState) => root.settings);
    const userSettings = useSettings();

    const {
        downloadedPacks,
        availablePacks,
        subscribeToPack,
        deletePacks,
        deletePack,
    } = useTeamPacksContext();

    const handleSettingsChange = async (props: Partial<ConfigPhone>) => {
        try {
            await updateSettings({
                ...settings,
                settings: {
                    ...settings.settings,
                    ...props,
                },
            });
        } catch (e) {
            console.error("Error trying to update settings", e);
        }
    };

    return (
        <IonPage>
            <HeaderComponent
                title={t("settings.SettingsMain.manageTeamPacks")}
                showBackButton
            />
            <IonContent>
                <IonCard className="ion-card-transparent">
                    <IonItem lines="none">
                        <IonCol>
                            <IonText className="text-small">
                                {t("settings.TeamPackSettings.description")}
                            </IonText>
                        </IonCol>
                    </IonItem>

                    <IonItem lines="full">
                        <IonCol>
                            <IonLabel>
                                {t("settings.TeamPackSettings.downloaded")}
                            </IonLabel>
                        </IonCol>
                    </IonItem>

                    {_.map(downloadedPacks, (pack, index) => {
                        return (
                            <IonItemSliding key={index}>
                                <IonItem
                                    key={index}
                                    lines="none"
                                    className="team-packs-container"
                                >
                                    <IonCol>
                                        <div className="padding-small">
                                            <IonLabel className="text-weight-bold">
                                                {pack.displayName}
                                            </IonLabel>
                                            <IonText className="text-small">
                                                {pack.description}
                                            </IonText>
                                        </div>
                                    </IonCol>
                                </IonItem>
                                <IonItemOptions side="end">
                                    {deletePack ? (
                                        <IonItemOption
                                            color="danger"
                                            onClick={() =>
                                                deletePack(pack.name)
                                            }
                                        >
                                            {t("general.delete")}
                                        </IonItemOption>
                                    ) : null}
                                </IonItemOptions>
                            </IonItemSliding>
                        );
                    })}

                    <IonItem hidden={_.size(downloadedPacks) > 0} lines="none">
                        <IonCol>
                            <IonText className="text-small">
                                {t("settings.TeamPackSettings.noneDownloaded")}
                            </IonText>
                        </IonCol>
                    </IonItem>

                    <IonItem lines="full">
                        <IonCol>
                            <IonLabel>
                                {t("settings.TeamPackSettings.available")}
                            </IonLabel>
                        </IonCol>
                    </IonItem>

                    {_.map(availablePacks, (pack, index) => {
                        return (
                            <IonItem
                                lines="none"
                                className="team-packs-container"
                                onClick={() => {
                                    if (subscribeToPack && userSettings) {
                                        subscribeToPack(pack);
                                        handleSettingsChange({
                                            teamPacks: _.uniq([
                                                ...userSettings.teamPacks,
                                                pack.name,
                                            ]),
                                        });
                                    }
                                }}
                            >
                                <IonCol>
                                    <div className="padding-small">
                                        <IonLabel className="text-weight-bold">
                                            {pack.displayName}
                                        </IonLabel>
                                        <IonText className="text-small">
                                            {pack.description}
                                        </IonText>
                                    </div>
                                </IonCol>
                            </IonItem>
                        );
                    })}
                    <IonItem hidden={_.size(availablePacks) > 0} lines="none">
                        <IonCol>
                            <IonText className="text-small">
                                {t("settings.TeamPackSettings.noneToDownload")}
                            </IonText>
                        </IonCol>
                    </IonItem>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default TeamPacks;
