import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { getLocale } from "./utils/localeUtils";

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: false,
        lng: getLocale(),
        resources: {
            en: { translation: require("./translations/en-GB.json") },
            "en-US": { translation: require("./translations/en-US.json") },
            da: { translation: require("./translations/da.json") },
            de: { translation: require("./translations/de.json") },
            es: { translation: require("./translations/es.json") },
            fr: { translation: require("./translations/fr.json") },
            ja: { translation: require("./translations/ja.json") },
            nl: { translation: require("./translations/nl.json") },
            nb: { translation: require("./translations/nb.json") },
            pt: { translation: require("./translations/pt.json") },
            // TODO add Polish when ready
            // pl: { translation: require("./translations/pl.json") },
            he: { translation: require("./translations/he.json") },
            iw: { translation: require("./translations/he.json") }, // Also hebrew
        },
        react: { useSuspense: true },
    });
i18next.services.formatter?.add("capitalise", (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
})
// We can use this if we don't have all the translations ready and just
// fall back to some generic or similar string
export function getStringOrFallback(key: string, fallback: string) {
    const value = i18next.t(key);
    if (!value) {
        return i18next.t(fallback);
    }
    return value;
}
