import {
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel,
    IonList,
    IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { R6IonInput } from "./r6ionic/R6IonInput";

export const NewEarningsComponent = () => {
    const { t } = useTranslation();

    const earningsFields = [
        {
            label: t("general.matchFees"),
            inputs: {
                name: "earnings.fees",
                placeHolder: t("general.matchFees"),
                testId: "matchFees",
            },
        },
        {
            label: t("general.expenses"),
            inputs: {
                name: "earnings.expenses",
                placeHolder: t("general.expenses"),
                testId: "matchExpenses",
            },
        },
    ];

    return (
        <IonAccordionGroup>
            <IonAccordion>
                <IonItem
                    className="item-title-container"
                    lines="full"
                    data-testid="match-options-earnings-accordian"
                    slot="header"
                >
                    <IonLabel position="fixed">
                        {t("fixture.FixtureSummaryEarnings.earnings")}
                    </IonLabel>
                    <IonText className="optional-text row-segment-period">
                        {t("general.optional")}
                    </IonText>
                </IonItem>
                <IonList slot="content">
                    {earningsFields.map((field, index) => {
                        const { label, inputs } = field;
                        return (
                            <div key={index}>
                                <div className="separator" />
                                <IonItem className={"item-title"} lines="full">
                                    <IonLabel position="fixed">
                                        {label}
                                    </IonLabel>
                                    <R6IonInput
                                        name={inputs.name}
                                        className="align-text-end"
                                        inputMode="numeric"
                                        type="number"
                                        placeholder={inputs.placeHolder}
                                    />
                                </IonItem>
                            </div>
                        );
                    })}
                </IonList>
            </IonAccordion>
        </IonAccordionGroup>
    );
};
