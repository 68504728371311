import { IonCard } from "@ionic/react";
import { FC } from "react";
import R6AuthInput from "./r6ionic/R6AuthInput";

interface AuthInputComponentProps {
    onChange: (value: string) => void;
    authCode: string;
    codeMismatch: boolean;

    disablePairButton(enable: boolean): void;
}

export const AuthInputComponent: FC<AuthInputComponentProps> = ({
    onChange,
    authCode,
    codeMismatch,
    disablePairButton,
}) => {
    return (
        <IonCard color="transparent">
            <R6AuthInput
                maxLength={1}
                valueLength={8}
                onChange={onChange}
                value={authCode}
                codeMismatch={codeMismatch}
                disablePairButton={(enable) => disablePairButton(enable)}
            />
        </IonCard>
    );
};
