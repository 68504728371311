export const appLaunchUrlToPath = (
    appLaunchUrl: string
): string | undefined => {
    const url = new URL(appLaunchUrl);
    const path = url.pathname;
    if (url.search) {
        return path + url.search;
    }
    return path;
};
