import request from "../request";
import { getDatabase } from "../database/fitnessDatabases.service";
import config, { FitnessDataSyncConfig } from "./config";
import { RawFitnessData } from "./types";
import {
    FitnessData,
    FitnessDataType,
    GpsCalibrationPoints,
    HalfEvent,
} from "refsix-js-models";
import * as Sentry from "@sentry/react";

export const fitnessDataFilter = (dataType: FitnessDataType) =>
    config[dataType].filterFn;

/**
 * Returns GPS or HR data
 */
export async function getFitnessData(
    matchId: string,
    userId: string,
    dataType: FitnessDataType,
    forceDownload?: boolean
) {
    return getOrDownloadData(matchId, userId, dataType, forceDownload);
}

/**
 * Returns GPS or HR data, filtered via the filter function in config
 */
export async function getFilteredFitnessData(
    matchId: string,
    matchTimings: HalfEvent[],
    username: string,
    dataType: FitnessDataType,
    forceDownload?: boolean
) {
    const { filterFn } = config[dataType];
    const trackingData = await getOrDownloadData(
        matchId,
        username,
        dataType,
        forceDownload
    );
    return trackingData ? filterFn(trackingData, matchTimings) : null;
}

/**
 * Processes (using process function from config) raw GPS or HR data, adds to local DB and syncs to server
 */
export async function addRawFitnessData(
    trackingData: RawFitnessData,
    username: string,
    dataType: FitnessDataType
) {
    const database = getDatabase(dataType);
    const dataConfig = config[dataType];

    const processedData = dataConfig.processFn({ ...trackingData, username });
    delete (processedData as any).data; // TODO: move to process function

    await addDataToDB(processedData._id, processedData, database, {
        synced: false,
    });
    await runSync(database, dataConfig);
}

/**
 * Adds processed HR or GPS data to local DB and syncs to server (e.g. a user importing HR data)
 */
export async function addFitnessData(
    processedData: FitnessData,
    dataType: FitnessDataType
) {
    const database = getDatabase(dataType);
    const dataConfig = config[dataType];

    await addDataToDB(processedData._id, processedData, database, {
        synced: false,
    });
    await runSync(database, dataConfig);
}

/**
 * Removes GPS or HR data from local DB and server
 */
export async function removeFitnessData(
    matchId: string,
    userId: string,
    dataType: FitnessDataType
) {
    const database = getDatabase(dataType);
    const { urlPath } = config[dataType];

    await removeDataFromDB(matchId, database);
    await deleteData(userId, matchId, urlPath);
}

/**
 * Adds calibration points to GPS data, updates local DB and syncs to server
 */
export async function setCalibrationPoints(
    matchId: string,
    userId: string,
    calibrationPoints: GpsCalibrationPoints
) {
    const database = getDatabase(FitnessDataType.GPS);
    const dataConfig = config[FitnessDataType.GPS];

    const trackingData = await getOrDownloadData(
        matchId,
        userId,
        FitnessDataType.GPS
    );
    if (trackingData) {
        await addDataToDB(matchId, trackingData, database, {
            synced: false,
            calibrationPoints,
        });
        await runSync(database, dataConfig);
    }
}

/**
 * Destroys DB and recreates it
 */
export async function removeDatabase(dataType: FitnessDataType) {
    try {
        await removeDatabase(dataType);
    } catch (err) {
        Sentry.captureMessage(
            `Error removing database: ${JSON.stringify(err)}`
        );
        console.log("Error removing database: ", err);
    }
}

/**
 * Downloads and processes GPS or HR data if `forcedownload` is true, otherwise returns from DB
 */
async function getOrDownloadData(
    matchId: string,
    userId: string,
    dataType: FitnessDataType,
    forceDownload: boolean = false
) {
    const database = getDatabase(dataType);
    if (!forceDownload) {
        try {
            return await getDataFromLocalDB(matchId, database);
        } catch (e) {
            console.log("Not in DB, downloading");
        }
    }
    return await downloadAndProcessData(
        matchId,
        userId,
        database,
        config[dataType]
    );
}

/**
 * Downloads data, adds to local DB, and returns it sorted (using sort function of config)
 */
async function downloadAndProcessData(
    matchId: string,
    userId: string,
    database: PouchDB.Database<FitnessData>,
    config: FitnessDataSyncConfig
) {
    const { urlPath, sortFn } = config;
    try {
        const data = await downloadData(userId, matchId, urlPath);
        await addDataToDB(data._id, data, database, { synced: true });
        const dataFromDB = (await getDataFromLocalDB(
            data._id,
            database
        )) as FitnessData;
        return sortFn(dataFromDB);
    } catch (error: any) {
        if (
            error.status != 404 &&
            (!error.message || error.message.indexOf("404") === -1)
        ) {
            Sentry.captureMessage(
                `Error downloading and processing data: ${JSON.stringify(
                    error
                )}`
            );
        }
        console.log("Error downloading and processing data: ", error);
        throw error;
    }
}

/**
 * Syncs all local unsynced data
 */
export async function runSync(
    database: PouchDB.Database<FitnessData>,
    config: FitnessDataSyncConfig
) {
    try {
        await Promise.all(
            (
                await getUnsyncedData(database)
            ).docs.map((data) => syncData(data, database, config))
        );
    } catch (error) {
        Sentry.captureMessage(`Error syncing data: ${JSON.stringify(error)}`);
        console.log("Error syncing data: ", error);
    }
}

export async function runSyncForAllData() {
    console.log("Running sync for all data");

    const gpsDB = getDatabase(FitnessDataType.GPS);
    const gpsDBConf = config[FitnessDataType.GPS];
    await runSync(gpsDB, gpsDBConf);

    const hrDB = getDatabase(FitnessDataType.HeartRate);
    const hrDBConf = config[FitnessDataType.HeartRate];
    await runSync(hrDB, hrDBConf);
}
/**
 * Returns all local data with `synced=false`
 */
async function getUnsyncedData(database: PouchDB.Database<FitnessData>) {
    const unsynced = await database.find({
        selector: {
            synced: false,
        },
    });
    return unsynced;
}

/**
 * Uploads data to server and adds to local DB (with `synced` set to true)
 */
async function syncData(
    data: FitnessData,
    database: PouchDB.Database<FitnessData>,
    config: FitnessDataSyncConfig
) {
    // upload then mark as synced in local DB
    const { urlPath } = config;
    await uploadData(data, urlPath);
    await addDataToDB(data._id, data, database, { synced: true });
}

/**
 * Returns data from local DB
 */
async function getDataFromLocalDB(
    matchId: string,
    database: PouchDB.Database<FitnessData>
) {
    try {
        return await database.get(matchId);
    } catch (error: any) {
        if (error.status != 404) {
            Sentry.captureMessage(
                `Error getting fitness data from DB: ${JSON.stringify(error)}`
            );
        }
        console.log("Error getting fitness data from DB: ", error);
        throw error;
    }
}

/**
 * Adds data (structure passed via generic) to local DB, with option to override fields
 */
async function addDataToDB<FitnessData>(
    id: string,
    data: FitnessData,
    database: PouchDB.Database<FitnessData>,
    overrideFields?: Partial<FitnessData>
) {
    try {
        await database.upsert(id, () =>
            overrideFields ? { ...data, ...overrideFields } : data
        );
    } catch (error) {
        Sentry.captureMessage(
            `Error adding fitness data to DB: ${JSON.stringify(error)}`
        );
        console.log("Error adding fitness data to DB: ", error);
        throw error;
    }
}

/**
 * Removes data from local DB
 */
async function removeDataFromDB(
    matchId: string,
    database: PouchDB.Database<FitnessData>
) {
    try {
        const data = await database.get(matchId);
        await database.remove(data);
    } catch (error) {
        Sentry.captureMessage(
            `Error removing fitness data from DB: ${JSON.stringify(error)}`
        );
        console.log("Error removing fitness data from DB: ", error);
        throw error;
    }
}

/**
 * Downloads and returns data from server
 */
async function downloadData(userId: string, matchId: string, urlPath: string) {
    try {
        return (
            await request.get<FitnessData>(`/${urlPath}/${userId}/${matchId}`)
        ).data;
    } catch (error: any) {
        if (
            error.status != 404 &&
            (!error.message || error.message.indexOf("404") === -1)
        ) {
            Sentry.captureMessage(
                `Error downloading fitness data: ${JSON.stringify(error)}`
            );
        }
        console.log("Error downloading fitness data: ", error);

        throw error;
    }
}
/**
 * Uploads data to server
 */
async function uploadData(data: FitnessData, urlPath: string) {
    const { username, _id } = data;
    try {
        await request.put(`/${urlPath}/${username}/${_id}`, data);
    } catch (error) {
        Sentry.captureMessage(
            `Error uploading fitness data: ${JSON.stringify(error)}`
        );
        console.log("Error uploading fitness data: ", error);
    }
}

/**
 * Deletes data from server
 */
async function deleteData(userId: string, matchId: string, urlPath: string) {
    try {
        await request.delete(`/${urlPath}/${userId}/${matchId}`);
    } catch (error) {
        Sentry.captureMessage(
            `Error deleting fitness data: ${JSON.stringify(error)}`
        );
        console.log("Error deleting fitness data: ", error);
    }
}
