import React, { FC, useRef, useState } from "react";
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonIcon,
    IonInput,
    IonItem,
    IonPage,
    IonSpinner,
    IonText,
    useIonRouter,
    useIonViewDidEnter,
} from "@ionic/react";
import { HeaderComponent } from "../../components";
import { useTranslation } from "react-i18next";
import { routes } from "../../route/constants";
import request from "../../services/request";
import { MatchPhone } from "refsix-js-models";
import { MatchHeader } from "../addResult/matchHeader";
import { matchesSortedByDate, updateMatch } from "../../services/matchService";
import "./addKnvbMatch.css";
import { searchOutline } from "ionicons/icons";
import { trackMatchCreated } from "../../services/analytics/analyticsService";
import { mpUpdatePerson } from "../../services/analytics/mixpanelService";
import {
    useFixtureResultCount,
    useMatchIds,
} from "../../components/hooks/match";
import * as Sentry from "@sentry/react";

export const AddKnvbMatch: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [knvbMatchNumber, setKnvbMatchNumber] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [matches, setMatches] = useState<MatchPhone[]>([]);
    const [selectedMatch, setSelectedMatch] = useState<MatchPhone | null>(null);
    const [addingMatch, setAddingMatch] = useState<boolean>(false);
    const fixtureResultCount = useFixtureResultCount();
    const existingMatchIds = useMatchIds();
    const searchInput = useRef<HTMLIonInputElement>(null);

    useIonViewDidEnter(() => {
        searchInput.current?.setFocus();
    });

    const search = async (event: any) => {
        event.preventDefault(); // prevent form submission
        setSelectedMatch(null);
        setLoading(true);
        setErrorMessage(null);
        console.log("Search for KNVB match with number: " + knvbMatchNumber);

        try {
            const response = await request.get<MatchPhone[]>(
                `/integrations/knvb/matches/?matchId=${knvbMatchNumber}`
            );
            const results = await response.data;
            // filter out existing match ids
            const filteredResults = results.filter(
                (match) => !match._id || !existingMatchIds.includes(match._id)
            );
            setMatches(matchesSortedByDate(filteredResults));
            if (!results.length) {
                setErrorMessage(t("fixture.FixtureList.noFixtures"));
            } else if (results.length === 1) {
                setSelectedMatch(results[0]);
            }
        } catch (e) {
            setMatches([]);
            setErrorMessage(t("general.errorTryAgain"));
        }

        setLoading(false);
    };

    const addMatch = async () => {
        if (selectedMatch) {
            setAddingMatch(true);
            try {
                const savedFixture = await updateMatch(selectedMatch);
                if (savedFixture) {
                    trackMatchCreated(savedFixture);
                    mpUpdatePerson(fixtureResultCount);
                    route.push(
                        `${routes.match}/${savedFixture._id}/overview`,
                        "root"
                    );
                } else {
                    setErrorMessage(t("general.errorTryAgain"));
                }
            } catch (e) {
                setErrorMessage(t("general.errorTryAgain"));
                Sentry.captureException(e);
            }
        }
        setAddingMatch(false);
    };

    return (
        <IonPage>
            <HeaderComponent
                titleTestId="knvb-add-match-header"
                title={"KNVB " + t("general.match")}
                showBackButton={true}
            />
            <IonContent className="add-knvb-match">
                <IonCard>
                    <form onSubmit={search}>
                        <IonItem>
                            <IonInput
                                ref={searchInput}
                                autofocus={true}
                                label={"KNVB Wedstrijdnr:"}
                                placeholder={"Wedstrijdnr"}
                                onIonInput={(e) =>
                                    setKnvbMatchNumber(e.detail.value!)
                                }
                            ></IonInput>

                            <IonButton
                                slot="end"
                                onClick={search}
                                color="primary"
                            >
                                <IonIcon
                                    aria-hidden="true"
                                    icon={searchOutline}
                                    // slot="end"
                                    onClick={search}
                                    // color="primary"
                                ></IonIcon>
                            </IonButton>
                        </IonItem>
                        <input type="submit" hidden={true} />
                    </form>
                </IonCard>
                <IonCard
                    className="ion-card-transparent"
                    style={{ margin: "0 25px" }}
                >
                    <IonText>
                        Het wedstrijdnummer is te vinden op Voetbal.nl of in de
                        KNVB Wedstrijdzaken app.
                    </IonText>
                </IonCard>

                {errorMessage && (
                    <>
                        <p className="ion-text-center">{errorMessage}</p>
                        <div className="ion-text-center">
                            <IonButton
                                onClick={() => {
                                    route.push(
                                        routes.createMatch,
                                        "forward",
                                        "replace"
                                    );
                                }}
                            >
                                {t("fixture.FixtureList.addFixture")}
                            </IonButton>
                        </div>
                    </>
                )}

                <div style={{ textAlign: "center", margin: 50 }}>
                    <IonSpinner hidden={!loading} name={"crescent"} />
                </div>

                {matches.map((match) => (
                    <div
                        key={match._id}
                        className={
                            selectedMatch?._id === match._id ? "selected" : ""
                        }
                        onClick={async () => {
                            setSelectedMatch(match);
                        }}
                    >
                        <MatchHeader match={match} />
                    </div>
                ))}
            </IonContent>
            <IonFooter>
                {selectedMatch && (
                    <IonButton
                        disabled={addingMatch}
                        expand={"block"}
                        style={{ marginBottom: "env(safe-area-inset-bottom)" }}
                        onClick={addMatch}
                    >
                        {t("general.addMatch")}
                    </IonButton>
                )}
            </IonFooter>
        </IonPage>
    );
};
