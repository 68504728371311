import { TimerType } from "./timerType";

export class TimerLayout {
    readonly main: TimerType;
    readonly secondary?: TimerType;
    readonly left?: TimerType;
    readonly right?: TimerType;

    // constructor(map: any) {
    //     this.main = map.main;
    //     this.secondary = map.secondary;
    //     this.left = map.left;
    //     this.right = map.right;
    // }

    constructor(
        main: TimerType,
        secondary?: TimerType,
        left?: TimerType,
        right?: TimerType
    ) {
        this.main = main;
        this.secondary = secondary;
        this.left = left;
        this.right = right;
    }

    isValid(): boolean {
        // Main must be not null and it must have either the secondary value and
        // no left and right or no secondary value and both a left and right
        let hasOnlySecondary =
            this.secondary != null && this.left == null && this.right == null;
        let hasOnlyLeftAndRight =
            this.secondary == null && this.left != null && this.right != null;

        return this.main && (hasOnlySecondary || hasOnlyLeftAndRight);
    }

    static fromJSON(json: any) {
        return new TimerLayout(
            json.main,
            json.secondary,
            json.left,
            json.right
        );
    }
}
