import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../components";
import "./onBoarding.css";
import { isAndroid, isIos } from "../../services/platformDetection";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { routes } from "../../route/constants";
import { OnBoardingWatchRender } from "./onBoardingWatchRender";
import { OnBoardingHeadingComponent } from "./onBoardingHeadingComponent";

export const OnboardingFindingYourWatch: FC = () => {
    const { t } = useTranslation();
    const watchStatus = useSelector((state: RootState) => state.watchStatus);
    const route = useIonRouter();

    useEffect(() => {
        let goToRoute: string = routes.refsixOnboardingNoWatchFound;
        if (isIos() && watchStatus.isPaired) {
            goToRoute = routes.refsixOnboardingFoundWatch + "/apple";
        } else if (isAndroid() && watchStatus.isPaired) {
            goToRoute = routes.refsixOnboardingFoundWatch + "/google";
        }

        const t = setTimeout(() => {
            route.push(goToRoute, "forward", "replace");
        }, 3000);
        return () => clearTimeout(t);
    }, []);

    return (
        <IonPage>
            <HeaderComponent
                titleTestId="onboarding-finding-watch"
                title={t("help.onBoarding.step0a")}
            />
            <IonContent>
                <OnBoardingHeadingComponent progressValue={0.2} />
                <div style={{ marginTop: 150 }}>
                    <OnBoardingWatchRender
                        text={t("help.onBoarding.findingWatch")}
                        showProgressBar={true}
                    />
                </div>
            </IonContent>
        </IonPage>
    );
};
