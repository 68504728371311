import { uniq } from "lodash";
import { ConfigPhoneDBWrapper } from "refsix-js-models";

/**
 * Returns a merged version, prioritising all `newSettingsWrapper` properties,
 * but uniquely concatinates all arrays from both objects
 */
export function resolveSettingsConflicts(
    newSettingsWrapper: ConfigPhoneDBWrapper,
    dbSettingsWrapper: ConfigPhoneDBWrapper
): ConfigPhoneDBWrapper {
    const { settings: newSettings } = newSettingsWrapper;
    const { settings: dbSettings } = dbSettingsWrapper;

    const newMisconductCodes = newSettings.misconductCodes
        ? [...newSettings.misconductCodes]
        : [];
    const dbMisconductCodes = dbSettings.misconductCodes
        ? [...dbSettings.misconductCodes]
        : [];

    return {
        ...newSettingsWrapper,
        settings: {
            ...newSettings,
            misconductCodes: uniq([
                ...newMisconductCodes,
                ...dbMisconductCodes,
            ]),
            customCodes: [
                {
                    ...newSettings.customCodes[0],
                    codes: uniq([
                        ...newSettings.customCodes[0].codes,
                        ...dbSettings.customCodes[0].codes,
                    ]),
                },
            ],
            watches: uniq([...newSettings.watches, ...dbSettings.watches]),
            teamPacks: uniq([
                ...newSettings.teamPacks,
                ...dbSettings.teamPacks,
            ]),
        },
    };
}
