import {
    EventName,
    GoalEvent,
    MatchEvent,
    SelectedTeam,
} from "refsix-js-models";

export const teamMatchEventsFilters = (
    events: MatchEvent[],
    side: SelectedTeam
): MatchEvent[] => {
    const oppositeSide =
        side === SelectedTeam.home ? SelectedTeam.away : SelectedTeam.home;

    return events.filter((event) => {
        if (event.eventName === EventName.goal) {
            const goalEvent = event as GoalEvent;
            if (goalEvent.ownGoal) {
                return goalEvent.team.side === oppositeSide ? event : false;
            }
        }
        // @ts-ignore child classes do have team
        return event.team && event.team.side === side;
    });
};
