import { FC, useState } from "react";
import {
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonToggle,
    useIonRouter,
} from "@ionic/react";
import { HeaderComponent } from "../../components";
import { FormProvider, useForm } from "react-hook-form";
import { MatchHeader } from "./matchHeader";
import { NumberCounter } from "./numberCounter";
import { useTranslation } from "react-i18next";
import {
    first,
    flatten,
    keyBy,
    keys,
    max,
    orderBy,
    reduce,
    values,
    map,
    sum,
} from "lodash";
import {
    EventName,
    IncidentEvent,
    MatchEvent,
    MatchPhone,
    PenaltiesEvent,
    ReasonType,
    SelectedTeam,
} from "refsix-js-models";
import { makeShortName, processMatch } from "refsix-core";
import { recalculateStats, updateMatch } from "../../services/matchService";
import { useProfile } from "../../components/hooks";
import { RouteComponentProps } from "react-router-dom";
import { routes } from "../../route/constants";
import { useMatch } from "../../components/hooks/match";

type AddResultDetail = {
    "goals-home": MatchEvent[];
    "goals-away": MatchEvent[];
    "yellow-home": MatchEvent[];
    "yellow-away": MatchEvent[];
    "red-home": MatchEvent[];
    "red-away": MatchEvent[];
    "penalty-home": MatchEvent[];
    "penalty-away": MatchEvent[];
};

interface AddResultDetailsFormProps
    extends RouteComponentProps<{
        id: string;
    }> {}

const AddResultDetailsForm: FC<AddResultDetailsFormProps> = ({ match }) => {
    const matchPhone = useMatch(match.params.id);
    const [tempMatchPhone, setTempMatchPhone] = useState(matchPhone);
    const profile = useProfile();
    const router = useIonRouter();
    const { t } = useTranslation();
    const [showPenalties, setShowPenalties] = useState(false);
    const formMethods = useForm<AddResultDetail>({
        defaultValues: {
            "goals-home": [],
            "goals-away": [],
            "yellow-home": [],
            "yellow-away": [],
            "red-home": [],
            "red-away": [],
            "penalty-home": [],
            "penalty-away": [],
        },
    });

    let ts = 1;
    if (matchPhone) {
        const firstEvent = first(orderBy(matchPhone.matchEvents, "timestamp"));
        if (firstEvent) {
            ts = firstEvent.timestamp + 1;
        }
    }

    const eventKeys = keys(matchPhone?.matchEvents).map((key) => parseInt(key));
    const endEvent = matchPhone?.matchEvents?.[""+max(eventKeys)] as any;
    let maxTs = (endEvent.endTime) + 1000
    const nextTs = () => ts++;
    const endMinuteOfPlay =
        sum(map(matchPhone?.timings, (val, key) => {
            if(key.startsWith("period")){
                return val;
            } else {
                return 0;
            }
        }))

    const addTeam = (team: SelectedTeam) => {
        const teamName =
            (team == SelectedTeam.home
                ? matchPhone?.homeTeam
                : matchPhone?.awayTeam) || "";
        const teamNameShort = makeShortName(teamName);
        return {
            team: {
                side: team,
                teamName: teamName,
                shortName: teamNameShort,
            },
        };
    };
    const addGoal = (team: SelectedTeam, penalty = false) => {
        return {
            timestamp: nextTs(),
            eventName: EventName.goal,
            ownGoal: false,
            penalty,
            freeKick: false,
            minuteOfPlay: 0,
            half: 0,
            player: {
                name: t("fixture.FixtureTeam.unknownPlayer"),
                number: 1,
                captain: false,
                starting: false,
            },
            ...addTeam(team),
        };
    };

    const addIncident = (team: SelectedTeam) => {
        return {
            eventName: EventName.incident,
            half: 0,
            minuteOfPlay: 0,
            timestamp: nextTs(),
            reason: "",
            player: {
                name: t("fixture.FixtureTeam.unknownPlayer"),
                number: 1,
                captain: false,
                starting: false,
            },
            duringSinBin: false,
            sinBin: false,
            placeholderEvent: true,
        };
    };

    const addYellow = (team: SelectedTeam) => {
        return {
            ...addIncident(team),
            card: ReasonType.yellow,
            ...addTeam(team),
        };
    };
    const addRed = (team: SelectedTeam): IncidentEvent => {
        return {
            ...addIncident(team),
            card: ReasonType.red,
            ...addTeam(team),
        };
    };
    const addPenaltyEvent = (): PenaltiesEvent => {
        const startTs = maxTs;
        const endTs = startTs + (10 * 60 * 1000);
        return {
            "timestamp": startTs,
            "eventName": EventName.penalties,
            "minuteOfPlay": endMinuteOfPlay,
            "endTime": endTs,
            "half": 2,
        }
    }
    const addPenaltyShot = (team: SelectedTeam, goal: boolean = true) => {
        return {
            timestamp: maxTs,
            eventName: EventName.penaltyShot,
            minuteOfPlay: 90,
            player: {
                name: t("fixture.FixtureTeam.unknownPlayer"),
                number: 1,
                captain: false,
                starting: false,
            },
            goal,
            hasPosition: false,
            ...addTeam(team),

        };
    }
    const processDataToMatch = (
        data: AddResultDetail,
        matchPhone: MatchPhone
    ) => {
        const hasPenalties = data["penalty-home"].length > 0 || data["penalty-away"].length > 0;
        const events = [
            ...(hasPenalties ? [addPenaltyEvent()] : []),
            ...flatten(values(data)),
            ...values(matchPhone.matchEvents),
        ];
        const dedupedEvents = incrementDuplicates(events);
        // Generate list of events based on scores
        const newMatch: MatchPhone = {
            ...matchPhone,
            matchEvents: {
                ...keyBy(dedupedEvents, "timestamp"),
            },
            playedPenalties: hasPenalties,
        };
        return newMatch;
    };
    const onUpdate = (data: AddResultDetail | any) => {
        if (!matchPhone) {
            return;
        }
        const newMatch = processDataToMatch(data, matchPhone);
        newMatch.stats = processMatch(newMatch);

        setTempMatchPhone(newMatch);
    };
    formMethods.watch(onUpdate);
    const onSubmit = (data: AddResultDetail, e: any) => {
        if (matchPhone) {
            const firstEvent = first(
                orderBy(matchPhone.matchEvents, "timestamp")
            );
            if (firstEvent) {
                ts = firstEvent.timestamp + 1;
            }
        } else {
            // Error!
            return;
        }
        //matchPhone.date;
        const newMatch = processDataToMatch(data, matchPhone);

        updateMatch(newMatch).then((matchPhone) => {
            if (matchPhone && profile) {
                recalculateStats(matchPhone, profile).then(() => {
                    router.push(
                        `${routes.match}/${matchPhone._id}/overview`,
                        undefined,
                        "replace"
                    );
                });
            }
        });
    };

    return (
        <IonPage>
            <HeaderComponent
                title={t("general.addMatch")}
                headerEndText={t("general.save")}
                onClickEndButton={formMethods.handleSubmit(onSubmit)}
                titleTestId="add-result-details-title"
                endBtnTestId="saveResultBtn"
            />
            <IonContent>
                <FormProvider {...formMethods}>
                    {tempMatchPhone && <MatchHeader match={tempMatchPhone} />}
                    <IonCard>
                        <IonItem
                            className="item-title-container rounded-corners-bottom"
                            lines="none"
                        >
                            <IonLabel>{t("stats.stats.goalsScored")}</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("general.home")}</IonLabel>
                            <NumberCounter
                                name="goals-home"
                                itemFactory={() => addGoal(SelectedTeam.home)}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("general.away")}</IonLabel>
                            <NumberCounter
                                name="goals-away"
                                itemFactory={() => addGoal(SelectedTeam.away)}
                            />
                        </IonItem>
                    </IonCard>
                    <IonCard>
                        <IonItem
                            className="item-title-container rounded-corners-bottom"
                            lines="none"
                        >
                            <IonLabel>{t("general.yellow")}</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("general.home")}</IonLabel>
                            <NumberCounter
                                name="yellow-home"
                                itemFactory={() => addYellow(SelectedTeam.home)}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("general.away")}</IonLabel>
                            <NumberCounter
                                name="yellow-away"
                                itemFactory={() => addYellow(SelectedTeam.away)}
                            />
                        </IonItem>
                    </IonCard>
                    <IonCard>
                        <IonItem
                            className="item-title-container rounded-corners-bottom"
                            lines="none"
                        >
                            <IonLabel>{t("general.red")}</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("general.home")}</IonLabel>
                            <NumberCounter
                                name="red-home"
                                itemFactory={() => addRed(SelectedTeam.home)}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("general.away")}</IonLabel>
                            <NumberCounter
                                name="red-away"
                                itemFactory={() => addRed(SelectedTeam.away)}
                            />
                        </IonItem>
                    </IonCard>
                    { matchPhone?.penaltiesAvailable ? <IonCard>
                        <IonItem
                            className="item-title-container rounded-corners-bottom"
                            lines="none"
                        >

                            <IonToggle onIonChange={(el) => {
                                setShowPenalties(!!el.detail.checked);
                            }}>{t("fixture.FixtureSummary.penalties")}</IonToggle>
                        </IonItem>
                        {showPenalties && (<>
                        <IonItem>
                            <IonLabel>{t("general.home")}</IonLabel>
                            <NumberCounter
                                name="penalty-home"
                                itemFactory={() => addPenaltyShot(SelectedTeam.home, true)}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>{t("general.away")}</IonLabel>
                            <NumberCounter
                                name="penalty-away"
                                itemFactory={() => addPenaltyShot(SelectedTeam.away, true)}
                            />
                        </IonItem>
                        </>)}
                    </IonCard> : null }
                </FormProvider>
            </IonContent>
        </IonPage>
    );
};

function incrementDuplicates(events: MatchEvent[]): MatchEvent[] {
    const uniqueTimestamps: number[] = [];
    return reduce(
        events,
        (updatedEvents: MatchEvent[], event) => {
            let { timestamp: ts } = event;
            while (uniqueTimestamps.includes(ts)) {
                ts += 1;
            }
            uniqueTimestamps.push(ts);
            updatedEvents.push({ ...event, timestamp: ts });
            return updatedEvents;
        },
        []
    );
}

export default AddResultDetailsForm;
