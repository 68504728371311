import { StatsLegacy } from "./statsLegacy";
import { GpsPoint } from "./fitness/gpsPoint";

export interface ICardCodeMap {
    [cardCode: string]: number;
}

export interface IPlayerCardMap {
    [playerName: string]: number;
}

export const CURRENT_STATS_VERSION = 18;

export class Stats extends StatsLegacy {
    version: number = CURRENT_STATS_VERSION;
    gpsCalibratedTotal: number = 0;
    minutesPlayed: number = 0;
    minutesPlayedAsReferee: number = 0;
    minutesPlayedAsAssistant: number = 0;
    minutesPlayedAsFourthOfficial: number = 0;
    minutesPlayedAsObserver: number = 0;
    speedMax: number = 0;
    speedAverage: number = 0;
    goalsTotal: number = 0;
    goalsHomeTotal: number = 0;
    goalsAwayTotal: number = 0;
    penaltyShotHomeScored: number = 0;
    penaltyShotHomeMissed: number = 0;
    penaltyShotAwayScored: number = 0;
    penaltyShotAwayMissed: number = 0;
    winnerHome: number = 0;
    winnerAway: number = 0;
    winnerDraw: number = 0;
    playedET: number[] = [0, 0, 0, 0];
    playedPenalties: number[] = [0, 0, 0, 0];
    duration: number = 0;
    injuryTimeTotal: number = 0;
    injuryTimeHalvesTotal: number = 0;
    injuryTimeThirdsTotal: number = 0;
    injuryTimeQuartersTotal: number = 0;
    injuryTimeByHalvesTotal: number[] = [0, 0];
    injuryTimeByThirdsTotal: number[] = [0, 0, 0];
    injuryTimeByQuartersTotal: number[] = [0, 0, 0, 0];
    distanceTotal: number = 0;
    distanceAsRefereeTotal: number = 0;
    distanceAsAssistantTotal: number = 0;
    distanceHalvesTotal: number = 0;
    distanceThirdsTotal: number = 0;
    distanceQuartersTotal: number = 0;
    distanceByHalvesTotal: number[] = [0, 0];
    distanceByThirdsTotal: number[] = [0, 0, 0];
    distanceByQuartersTotal: number[] = [0, 0, 0, 0];

    sprintsMediumAndHigh: number = 0;
    sprintsTotal: number[] = [0, 0, 0];
    sprintsHalvesTotal: number[] = [0, 0, 0];
    sprintsThirdsTotal: number[] = [0, 0, 0];
    sprintsQuartersTotal: number[] = [0, 0, 0];
    sprintsDistanceTotal: number[] = [0, 0, 0];
    sprintsDistanceHalvesTotal: number[] = [0, 0, 0];
    sprintsDistanceThirdsTotal: number[] = [0, 0, 0];
    sprintsDistanceQuartersTotal: number[] = [0, 0, 0];
    sprintsByHalvesTotal: number[][] = [
        [0, 0, 0],
        [0, 0, 0],
    ];
    sprintsByThirdsTotal: number[][] = [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
    ];
    sprintsByQuartersTotal: number[][] = [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
    ];
    sprintsByHalvesDistanceTotal: number[][] = [
        [0, 0, 0],
        [0, 0, 0],
    ];
    sprintsByThirdsDistanceTotal: number[][] = [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
    ];
    sprintsByQuartersDistanceTotal: number[][] = [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
    ];
    speedCategoryDurations: number[] = [0, 0, 0, 0, 0, 0];
    speedCategoryDistances: number[] = [0, 0, 0, 0, 0, 0];

    feesTotal: number = 0;
    expensesTotal: number = 0;
    earningsTotal: number = 0;
    earningsAsRefereeTotal: number = 0;
    earningsAsAssistantTotal: number = 0;
    earningsAsFourthOfficialTotal: number = 0;
    earningsAsObserverTotal: number = 0;

    heartRateAverage: number = 0;
    heartRateMax: number = 0;
    heartRate15Mins: [number?] = [];
    heartRateZoneDuration: number[] = [0, 0, 0, 0, 0];
    heartRateZone4And5: number = 0;
    redCardTotal: number = 0;
    yellowCardTotal: number = 0;
    yellowCardByTime: [number?] = [];
    yellowCardByHomePlayer: IPlayerCardMap = {};
    yellowCardByAwayPlayer: IPlayerCardMap = {};
    redCardByHomePlayer: IPlayerCardMap = {};
    redCardByAwayPlayer: IPlayerCardMap = {};
    yellowCardHomeTotal: number = 0;
    yellowCardAwayTotal: number = 0;
    redCardHomeTotal: number = 0;
    redCardAwayTotal: number = 0;
    yellowCardPositions: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    redCardPositions: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    yellowCardCodes: ICardCodeMap = {};
    redCardCodes: ICardCodeMap = {};
    sinBinsTotal: number = 0;
    sinBinsMinutesSpent: number = 0;
    sinBinsByTime: [number?] = [];
    sinBinsGoalPlayerTeam: number = 0;
    sinBinsGoalOppositeTeam: number = 0;
    gpsCenterPoint: GpsPoint = { time: 0, latitude: 0, longitude: 0 };
    //gpsAvailable in [all, halves, thirds, quarters]
    gpsAvailable: number[] = [0, 0, 0, 0];
    //heartRateAvailable in [all, halves, thirds, quarters]
    heartRateAvailable: number[] = [0, 0, 0, 0];
    gpsProcessed: number = 0;
    heartRateProcessed: number = 0;

    constructor() {
        super();
    }
}
