import { FC, useEffect, useState } from "react";
import { IonButton, IonCard, IonIcon, IonInput, IonItem } from "@ionic/react";
import {
    addKeyWord,
    allMatches,
    getKeywords,
    keywordInSelectedKeywords,
    onKeyPress,
    triggerKeyword,
} from "../services/matchService";
import { addCircle, closeCircle } from "ionicons/icons";
import { MatchPhone } from "refsix-js-models";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useTranslation } from "react-i18next";

interface KeywordsComponentProps {
    match: MatchPhone;

    onKeywordsChanged(keywords: string[]): void;
}

const KeywordsComponent: FC<KeywordsComponentProps> = ({
    match,
    onKeywordsChanged,
}) => {
    const { t } = useTranslation();
    const matches = useSelector((state: RootState) =>
        allMatches(state.matches.matches)
    );
    const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
    const [newKeyword, setNewKeyword] = useState<string>("");
    const [userKeywords, setUserKeywords] = useState<string[]>(
        getKeywords(matches) || []
    );
    const [invalidKeyword, setNewKeywordInvalid] = useState<boolean>(false);

    useEffect(() => {
        if (match.keywords && match.keywords.length > 0) {
            setSelectedKeywords([...match.keywords]);
        }
    }, []);

    const handleUpdateKeywords = (keywords: string[]) => {
        onKeywordsChanged(keywords);
    };

    const handleKeywordSelected = (keyword: string) => {
        const alreadySelected = triggerKeyword(keyword, selectedKeywords);
        if (alreadySelected) {
            const filteredArray = selectedKeywords.filter(
                (item) => item !== keyword
            );
            setSelectedKeywords(filteredArray);
            handleUpdateKeywords(filteredArray);
        } else {
            setSelectedKeywords((selectedKeywords) => [
                ...selectedKeywords,
                keyword,
            ]);
            handleUpdateKeywords([...selectedKeywords, keyword]);
        }
    };

    const handleAddKeyWord = (keyword: string) => {
        const add = addKeyWord(keyword, userKeywords);
        if (add) {
            setUserKeywords((userKeywords) => [...userKeywords, keyword]);
            setSelectedKeywords((selectedKeywords) => [
                ...selectedKeywords,
                keyword,
            ]);
            handleUpdateKeywords([...selectedKeywords, keyword]);
            setNewKeyword("");
        } else {
            setNewKeywordInvalid(true);
        }
    };

    const handleKeyPress = (event: any, keyword: string) => {
        const pressed = onKeyPress(event);
        if (pressed) {
            handleAddKeyWord(keyword);
            event.preventDefault();
        }
    };

    return (
        <IonCard data-testid="fixture-segment-tags">
            <div className="tags-view line">
                {userKeywords.map((keyword, index) => {
                    return (
                        <div className="keyword-wrapper" key={index}>
                            <IonButton
                                className={
                                    keywordInSelectedKeywords(
                                        keyword,
                                        selectedKeywords
                                    )
                                        ? "tag selected button-small"
                                        : "tag button-small"
                                }
                                onClick={() => handleKeywordSelected(keyword)}
                            >
                                {keyword}
                                <IonIcon
                                    slot="end"
                                    icon={
                                        keywordInSelectedKeywords(
                                            keyword,
                                            selectedKeywords
                                        )
                                            ? closeCircle
                                            : addCircle
                                    }
                                />
                            </IonButton>
                        </div>
                    );
                })}
            </div>
            <IonItem
                className={
                    "item-title-container" && invalidKeyword ? "danger" : ""
                }
                lines="none"
                data-testid="keywordsInput"
            >
                <IonInput
                    placeholder={t("general.keywords.placeholder")}
                    value={newKeyword}
                    onKeyPress={(e) => handleKeyPress(e, newKeyword)}
                    onIonChange={(e) => {
                        setNewKeywordInvalid(false);
                        setNewKeyword(e.detail.value!);
                    }}
                    name="newKeyword"
                />
                <IonButton
                    className="add-keyword-button"
                    data-testid="buttonAddKeyword"
                    slot="end"
                    onClick={() => handleAddKeyWord(newKeyword)}
                >
                    <IonIcon slot="end" icon={addCircle} />
                </IonButton>
            </IonItem>
        </IonCard>
    );
};

export default KeywordsComponent;
