import { AggregatedStats, MatchPhone, OfficialRole } from "refsix-js-models";
import { FC, useEffect, useState } from "react";
import { IonCard, IonCol, IonItem, IonText } from "@ionic/react";
import {
    ComparisonBarComponent,
    HeadlineNumbersComponent,
    ListOfTopComponent,
    RoundBarComponent,
} from "../../../components";
import { useTranslation } from "react-i18next";
import { matchesWithMost } from "../../../services/matchService";
import {
    COLORS_GOALS,
    COLORS_WINS,
} from "../../../constants/StatsColorPalette";
import moment from "moment";
import { FiltersModel } from "../../../redux/models/filtersModel";
import { ProTeaser } from "../../../components/ProTeaser";
import { LIST_OF_TOP_DATE_FORMAT } from "../../../components/ListOfTopComponent";

interface TrendsMatchesProps {
    matches: MatchPhone[];
    hasStatsAccess: boolean;
    filters: FiltersModel;
    aggregatedStats?: AggregatedStats;
}

const Matches: FC<TrendsMatchesProps> = ({
    matches,
    hasStatsAccess,
    aggregatedStats,
    filters,
}) => {
    const { t } = useTranslation();
    // This needs to be string[] or number[], depending if user is free or pro
    const [homeAwayGoalsTotal, setHomeAwayGoalsTotal] = useState<number[]>([]);
    const [matchesByRole, setMatchesByRole] = useState<number[]>([0, 0, 0, 0]);
    const [homeAwayResults, setHomeAwayResults] = useState<number[]>([0, 0, 0]);
    const dateFormat = LIST_OF_TOP_DATE_FORMAT;

    useEffect(() => {
        if (aggregatedStats) {
            if (hasStatsAccess) {
                setHomeAwayGoalsTotal([
                    aggregatedStats.goalsHomeTotal,
                    aggregatedStats.goalsAwayTotal,
                ]);
                setHomeAwayResults([
                    aggregatedStats.winnerHome,
                    aggregatedStats.winnerDraw,
                    aggregatedStats.winnerAway,
                ]);
            }
        }

        if (
            aggregatedStats?.matchesAsReferee ||
            aggregatedStats?.matchesAsAssistant ||
            aggregatedStats?.matchesAsFourthOfficial ||
            aggregatedStats?.matchesAsObserver
        ) {
            setMatchesByRole([
                aggregatedStats?.matchesAsReferee,
                aggregatedStats?.matchesAsAssistant,
                aggregatedStats?.matchesAsFourthOfficial,
                aggregatedStats?.matchesAsObserver,
            ]);
        }
    }, [aggregatedStats, hasStatsAccess]);

    const matchesWithMostGoals = matchesWithMost(
        matches,
        "goalsTotal",
        5,
        filters
    );

    // Calculating the percentage of each wins to draw the comprasion bar
    function calculateResultsPercentage(total: number): number[] {
        return [
            (homeAwayResults[0] / total) * 100,
            (homeAwayResults[1] / total) * 100,
            (homeAwayResults[2] / total) * 100,
        ];
    }

    return (
        <>
            <IonCard data-testid="matches-card">
                <HeadlineNumbersComponent
                    testIdsPrefix="matches"
                    data={
                        aggregatedStats
                            ? [
                                  aggregatedStats?.matchesTotal.toFixed(0),
                                  aggregatedStats?.matchesAveragePerMonth.toFixed(
                                      2
                                  ),
                              ]
                            : []
                    }
                    title={t("stats.stats.Matches.totalMatches")}
                    labels={[
                        t("stats.stats.matches"),
                        t("stats.stats.Matches.avgPerMonth"),
                    ]}
                    useDummyData={!hasStatsAccess}
                    fromIndex={!hasStatsAccess ? 1 : undefined}
                />

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-matches-by-role"
                >
                    <RoundBarComponent
                        title={t("stats.stats.Matches.byRole")}
                        values={matchesByRole}
                        series={[
                            OfficialRole.referee,
                            OfficialRole.assistant,
                            OfficialRole.fourthOfficial,
                            OfficialRole.observer,
                        ]}
                        distanceBy=""
                        numberOfCategories={4}
                        seriesIcon
                        useDummyData={!hasStatsAccess}
                    />
                </ProTeaser>
            </IonCard>

            <IonCard>
                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-matches-goals"
                >
                    <>
                        <HeadlineNumbersComponent
                            testIdsPrefix="goals"
                            data={
                                aggregatedStats
                                    ? [
                                          aggregatedStats?.goalsTotal.toFixed(
                                              0
                                          ),
                                          aggregatedStats?.goalsAverage.toFixed(
                                              2
                                          ),
                                      ]
                                    : []
                            }
                            title={t("stats.stats.goalsScored")}
                            labels={[
                                t("stats.stats.total"),
                                t("stats.stats.avgPerGame"),
                            ]}
                            useDummyData={!hasStatsAccess}
                            fromIndex={!hasStatsAccess ? 1 : undefined}
                        />
                        <HeadlineNumbersComponent
                            testIdsPrefix="home-away-goals"
                            data={homeAwayGoalsTotal}
                            title={t("stats.stats.Matches.goalsBySide")}
                            labels={[
                                t("stats.stats.homeGoals"),
                                t("stats.stats.awayGoals"),
                            ]}
                            colors={COLORS_GOALS}
                            useDummyData={!hasStatsAccess}
                            fromIndex={!hasStatsAccess ? 1 : undefined}
                        />
                        <ComparisonBarComponent
                            values={
                                hasStatsAccess ? homeAwayGoalsTotal : [50, 50]
                            }
                            colors={COLORS_GOALS}
                        />
                    </>
                </ProTeaser>

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-matches-most-goals"
                >
                    <>
                        <IonItem lines="none" data-testid="matches-title">
                            <IonCol>
                                <IonText className="text-small">
                                    {t(
                                        "stats.stats.Matches.matchesWithMostGoals"
                                    )}
                                </IonText>
                            </IonCol>
                        </IonItem>

                        {matchesWithMostGoals.map((match, index) => {
                            return (
                                <ListOfTopComponent
                                    matchId={match._id}
                                    key={index}
                                    stat={
                                        match.stats &&
                                        match.stats["goalsTotal"].toString()
                                    }
                                    teamNames={`${match.homeTeam} v ${match.awayTeam}`}
                                    date={moment(match.date).format(dateFormat)}
                                    useDummyData={!hasStatsAccess}
                                />
                            );
                        })}
                    </>
                </ProTeaser>
            </IonCard>

            <IonCard data-testid="results-card">
                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-matches-results"
                >
                    <>
                        <HeadlineNumbersComponent
                            testIdsPrefix="results"
                            data={homeAwayResults}
                            title={t("general.results")}
                            labels={[
                                t("stats.stats.homeWins"),
                                t("stats.stats.draws"),
                                t("stats.stats.awayWins"),
                            ]}
                            colors={COLORS_WINS}
                            useDummyData={!hasStatsAccess}
                            fromIndex={!hasStatsAccess ? 1 : undefined}
                        />
                        <ComparisonBarComponent
                            values={
                                hasStatsAccess
                                    ? calculateResultsPercentage(
                                          aggregatedStats?.matchesTotal || 0
                                      )
                                    : [33, 33, 33]
                            }
                            colors={COLORS_WINS}
                        />
                    </>
                </ProTeaser>
            </IonCard>
        </>
    );
};

export default Matches;
