import { RefsixState } from "../models/refsixState";
import { SET_WATCH_STATUS } from "../actions/types";
import { Middleware } from "redux";
import { isAndroid, isIos } from "../../services/platformDetection";
import { mpUpdatePerson } from "../../services/analytics/mixpanelService";

export const watchStatusMiddleware: Middleware<{}, RefsixState> =
    (store) => (next) => (action) => {
        // check if connected to watch
        switch (action.type) {
            case SET_WATCH_STATUS:
                const currentlyPaired = store.getState().watchStatus.isPaired;
                const watchStatus = action.watchStatus;
                // we only want to do this if isPaired changed to true.
                if (watchStatus.isPaired && !currentlyPaired) {
                    if (isIos()) {
                        mpUpdatePerson({ hasAppleWatch: true });
                    } else if (isAndroid()) {
                        mpUpdatePerson({ hasWearOs: true });
                    }
                }
                break;
        }

        next(action);
    };
