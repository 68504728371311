import {
    IntegrationPartner,
    MatchPhone,
    OfficialRole,
    SinBinSystem,
    Timings,
} from "refsix-js-models";
import moment from "moment-timezone";
import Ajv, { ErrorObject, JSONSchemaType } from "ajv";
import addFormats from "ajv-formats";
import { makeShortName } from "../utils";

const ajv = new Ajv({ allErrors: true });
addFormats(ajv);

export const knvbMatchesUploadJsonSchema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    // We can validate either a single match object or an array of match objects
    // Useful for tests
    type: "array",
    items: { $ref: "#/$defs/KnvbMatch" },
    $defs: {
        KnvbMatch: {
            type: "object",
            properties: {
                MATCH_ID: {
                    type: "number",
                    description:
                        "Unique identifier for the match, included in '_id' as 'knvb-MATCH_ID-SPORTLINK_UNIQUE_MATCH_ID'.",
                },
                CLASS: {
                    type: "string",
                    minLength: 1,
                    description: "Competition class (e.g., Poule Midweeks).",
                },
                DESIGNATION: {
                    type: "string",
                    minLength: 1,
                    description:
                        "The level of the match (e.g. B1100 Mannen KNVB beker Amateurs 1/2 finales).",
                },
                MATCH_DATE: {
                    type: "string",
                    format: "iso-date-time",
                    description:
                        "The date of the match, with time always set to '00:00:00.000'.",
                },
                MATCH_STARTTIME: {
                    type: "string",
                    format: "iso-time",
                    description:
                        "The start time of the match in Central European Standard Time (Europe/Amsterdam).",
                },
                HOME_CLUB_NAME: {
                    type: "string",
                    minLength: 1,
                    description:
                        "Name of the home club, concatenated with HOME_TEAM_NAME for 'homeTeam' mapping.",
                },
                HOME_TEAM_NAME: {
                    type: "string",
                    description:
                        "Name of the home team, concatenated with HOME_CLUB_NAME for 'homeTeam' mapping.",
                },
                AWAY_CLUB_NAME: {
                    type: "string",
                    minLength: 1,
                    description:
                        "Name of the away club, concatenated with AWAY_TEAM_NAME for 'awayTeam' mapping.",
                },
                AWAY_TEAM_NAME: {
                    type: "string",
                    description:
                        "Name of the away team, concatenated with AWAY_CLUB_NAME for 'awayTeam' mapping.",
                },
                SPORTLINK_UNIQUE_MATCH_ID: {
                    type: "number",
                    description:
                        "Unique identifier from Sportlink, included in '_id' as 'knvb-MATCH_ID-SPORTLINK_UNIQUE_MATCH_ID'.",
                },
                ACCOMMODATION_NAME: {
                    type: "string",
                    description: "Name of the venue where the match is played.",
                },
                TEAMGROOTTE: {
                    type: "number",
                    minimum: 1,
                    maximum: 20,
                    description: "Team size, minimum 1 and maximum 20.",
                },
                BENCH_SIZE: {
                    type: "number",
                    minimum: 0,
                    maximum: 20,
                    description:
                        "Number of substitutes allowed, minimum 0 and maximum 20.",
                },
                NUMBER_OF_PERIODS: {
                    type: "number",
                    minimum: 1,
                    maximum: 4,
                    description:
                        "Number of periods in the match, minimum 1 and maximum 4.",
                },
                PERIOD_LENGTH: {
                    type: "number",
                    minimum: 1,
                    maximum: 180,
                    description:
                        "Length of each period in minutes, minimum 1 and maximum 180.",
                },
                INTERVAL_LENGTH: {
                    type: "number",
                    minimum: 1,
                    maximum: 90,
                    description:
                        "Length of the interval between periods in minutes, minimum 1 and maximum 90.",
                },
                EXTRA_TIME: {
                    type: ["boolean", "null"],
                    description:
                        "Indicates whether extra time is available. True/False/Blank.",
                },
                PENALTY_SHOOTOUT: {
                    type: ["boolean", "null"],
                    description:
                        "Indicates whether penalties are available. True/False/Blank.",
                },
                TEMPORARY_DISMISSAL: {
                    type: "boolean",
                    description:
                        "Indicates whether temporary dismissal is used. True/False.",
                },
            },
            required: [
                "MATCH_ID",
                "CLASS",
                "MATCH_DATE",
                "MATCH_STARTTIME",
                "HOME_CLUB_NAME",
                "HOME_TEAM_NAME",
                "AWAY_CLUB_NAME",
                "AWAY_TEAM_NAME",
                "SPORTLINK_UNIQUE_MATCH_ID",
                "ACCOMMODATION_NAME",
                "TEAMGROOTTE",
                "BENCH_SIZE",
                "NUMBER_OF_PERIODS",
                "PERIOD_LENGTH",
                "INTERVAL_LENGTH",
                "TEMPORARY_DISMISSAL",
                "PENALTY_SHOOTOUT",
            ],

            additionalProperties: true,
            allOf: [
                {
                    if: {
                        properties: {
                            TEMPORARY_DISMISSAL: { const: true },
                        },
                    },
                    then: {
                        properties: {
                            TD_LENGTH: {
                                type: ["number"],
                                minimum: 1,
                                maximum: 90,
                                description:
                                    "Length of the temporary dismissal timer in minutes. Blank if TEMPORARY_DISMISSAL is False.",
                            },
                            TD_SYSTEM: {
                                type: ["string"],
                                enum: ["System A", "System B", "None"],
                                description:
                                    "Temporary dismissal system in use. Blank if TEMPORARY_DISMISSAL is False.",
                            },
                        },
                        required: ["TD_LENGTH", "TD_SYSTEM"],
                    },
                },
                {
                    if: {
                        properties: {
                            EXTRA_TIME: { const: true },
                        },
                    },
                    then: {
                        properties: {
                            ET_PERIOD_LENGTH: {
                                type: ["number"],
                                minimum: 1,
                                maximum: 90,
                                description:
                                    "Length of each extra time period in minutes. 0 if EXTRA_TIME is False, Blank if EXTRA_TIME is Blank.",
                            },
                        },
                        required: ["ET_PERIOD_LENGTH"],
                    },
                },
            ],
        },
    },
};

const validate = ajv.compile(knvbMatchesUploadJsonSchema);

export const knvbMatchesUploadCsvSchemaValidation = (
    csvJson
): ErrorObject[] | null | undefined => {
    const isValid = validate(csvJson);
    return isValid ? null : validate.errors;
};
export const knvbMatchesUploadCsvTransformRow = (row) => {
    return Object.entries(row).reduce((acc, [propName, value]) => {
        acc[propName] = knvbMatchesUploadCsvTransform(value, propName);
        return acc;
    }, {});
};

export const knvbMatchesUploadCsvTransform = (value, propName) => {
    // Map of field transformations based on schema
    const boolOrNull = (v) => {
        const lcase = (v && v.toLowerCase()) || null;
        return lcase === "true" ? true : lcase === "false" ? false : null;
    };
    const toInt = (v) => parseInt(v, 10);

    const fieldTransformations = {
        MATCH_ID: toInt,
        CLASS: (v) => v,
        DESIGNATION: (v) => v,
        MATCH_DATE: (v) => v,
        MATCH_STARTTIME: (v) => v,
        HOME_CLUB_NAME: (v) => v,
        HOME_TEAM_NAME: (v) => v,
        AWAY_CLUB_NAME: (v) => v,
        AWAY_TEAM_NAME: (v) => v,
        SPORTLINK_UNIQUE_MATCH_ID: toInt,
        ACCOMMODATION_NAME: (v) => v,
        TEAMGROOTTE: toInt,
        BENCH_SIZE: toInt,
        NUMBER_OF_PERIODS: toInt,
        PERIOD_LENGTH: toInt,
        INTERVAL_LENGTH: toInt,
        EXTRA_TIME: boolOrNull,
        ET_PERIOD_LENGTH: (v) => (v ? parseInt(v, 10) : null),
        PENALTY_SHOOTOUT: boolOrNull,
        TEMPORARY_DISMISSAL: boolOrNull,
        TD_LENGTH: (v) => (v ? parseInt(v, 10) : null),
        TD_SYSTEM: (v) => v || null,
    };

    // Apply the transformation if it exists, otherwise return the original value
    return fieldTransformations[propName]
        ? fieldTransformations[propName](value)
        : value;
};

const createTimings: (inp: {
    NUMBER_OF_PERIODS: number;
    PERIOD_LENGTH: number;
    INTERVAL_LENGTH: number;
    ET_PERIOD_LENGTH: number | null;
    TD_LENGTH: number | null;
    EXTRA_TIME: boolean | null;
    TEMPORARY_DISMISSAL: boolean | null;
}) => Timings = ({
    NUMBER_OF_PERIODS,
    PERIOD_LENGTH,
    INTERVAL_LENGTH,
    ET_PERIOD_LENGTH,
    TD_LENGTH,
    EXTRA_TIME,
    TEMPORARY_DISMISSAL,
}) => {
    const timings: Timings = {
        period1: PERIOD_LENGTH,
    };

    // Add additional periods
    for (let i = 2; i <= NUMBER_OF_PERIODS; i++) {
        timings[`period${i}` as keyof Timings] = PERIOD_LENGTH;
    }

    // Add intervals
    if (NUMBER_OF_PERIODS > 1) {
        for (let i = 1; i < NUMBER_OF_PERIODS; i++) {
            timings[`interval${i}` as keyof Timings] = INTERVAL_LENGTH;
        }
    }

    // Add extra time if applicable
    if (EXTRA_TIME && ET_PERIOD_LENGTH && ET_PERIOD_LENGTH > 0) {
        timings.extraTimeHalfLength = ET_PERIOD_LENGTH;
    }

    // Add sin bin timer length if applicable
    if (TEMPORARY_DISMISSAL && TD_LENGTH && TD_LENGTH > 0) {
        timings.sinBinTimerLength = TD_LENGTH;
    }

    return timings;
};

// the following designations only should map to "netherlands-knvb-cup". everything else is "netherlands"
const CUP_DESIGNATIONS = [
    "B1080 Mannen KNVB beker Amateurs 1/8 finales",
    "B1090 Mannen KNVB beker Amateurs 1/4 finales",
    "B1100 Mannen KNVB beker Amateurs 1/2 finales",
    "B1110 Mannen KNVB beker Amateurs finale",
].map((d) => d.toLowerCase());

export const knvbDesignationToMisconductCodeId: (
    designation: string | undefined
) => string = (designation) => {
    // check for the above designations in a case-insensitive way
    if (designation && CUP_DESIGNATIONS.includes(designation.toLowerCase())) {
        return "netherlands-knvb-cup";
    }

    return "netherlands";
};

export const knvbMatchesCsvToJson = (csvJson): MatchPhone[] => {
    return csvJson.map((match) => {
        const homeTeamName = `${match.HOME_CLUB_NAME} ${match.HOME_TEAM_NAME}`;
        let awayTeamName = `${match.AWAY_CLUB_NAME} ${match.AWAY_TEAM_NAME}`;

        // If home and away team have the same name append '2' to away team.
        if (homeTeamName === awayTeamName) {
            awayTeamName = awayTeamName + " (2)";
        }
        const homeTeamNameShort = makeShortName(homeTeamName);
        let awayTeamNameShort = makeShortName(awayTeamName);

        if (awayTeamNameShort === homeTeamNameShort) {
            awayTeamNameShort = makeShortName(awayTeamName).substr(0, 2) + "2";
        }

        const players = {
            [homeTeamName]: [],
            [awayTeamName]: [],
        };

        const newMatch: MatchPhone = {
            // MATCH_ID: _id # "Include in _id as knvb-MATCH_ID-SPORTLINK_UNIQUE_MATCH_ID e.g. knvb-95347-18579689"
            // SPORTLINK_UNIQUE_MATCH_ID: sportlinkId # "Unique identifier from Sportlink, included in '_id' as 'knvb-MATCH_ID-SPORTLINK_UNIQUE_MATCH_ID'"
            _id: `knvb-${match.MATCH_ID}-${match.SPORTLINK_UNIQUE_MATCH_ID}`,
            officialRole: OfficialRole.referee,
            // CLASS: competition # "Competition class (e.g. Poule Midweeks)"
            competition: match.CLASS,
            // MATCH_DATE: date # "Date of the match, with time always set to '00:00:00.000'"
            // MATCH_STARTTIME: date # "Start time of the match in Central European Standard Time (Europe/Amsterdam)"
            // Use moment to combine date and time # Date looks like "2025-05-10T23:00:00.000Z", start time looks like "10:30:00.000"
            date: moment(match.MATCH_DATE)
                .tz("Europe/Amsterdam")
                .startOf("day")
                .add(moment.duration(match.MATCH_STARTTIME))
                .toISOString(),
            // HOME_CLUB_NAME: homeTeam # "Name of the home club, concatenated with HOME_TEAM_NAME for 'homeTeam' mapping"
            // HOME_TEAM_NAME: homeTeam # "Name of the home team, concatenated with HOME_CLUB_NAME for 'homeTeam' mapping"
            homeTeam: homeTeamName,
            // AWAY_CLUB_NAME: awayTeam # "Name of the away club, concatenated with AWAY_TEAM_NAME for 'awayTeam' mapping"
            // AWAY_TEAM_NAME: awayTeam # "Name of the away team, concatenated with AWAY_CLUB_NAME for 'awayTeam' mapping"
            awayTeam: awayTeamName,
            homeTeamShort: homeTeamNameShort,
            awayTeamShort: awayTeamNameShort,
            // ACCOMMODATION_NAME: venue # "Name of the venue where the match is played"
            venue: match.ACCOMMODATION_NAME,
            // TEAMGROOTTE: teamSize # "Team size, minimum 1 and maximum 20"
            teamSize: match.TEAMGROOTTE,
            // BENCH_SIZE: subsNo # "Number of substitutes allowed, minimum 0 and maximum 20"
            subsNo: match.BENCH_SIZE,
            // NUMBER_OF_PERIODS: periodsNo # "Number of periods in the match, minimum 1 and maximum 4"
            periodsNo: `${match.NUMBER_OF_PERIODS}`,
            withGoalScorers: true,
            extraTimeAvailable: match.EXTRA_TIME || false,
            penaltiesAvailable: match.PENALTY_SHOOTOUT || false,
            matchFinished: false,
            eventsProcessed: false,
            timings: createTimings(match),
            integration: {
                partner: IntegrationPartner.knvb,
                matchId: match.MATCH_ID,
                poolId: match.SPORTLINK_UNIQUE_MATCH_ID,
            },
            misconductCodeId: knvbDesignationToMisconductCodeId(
                match.DESIGNATION
            ),
            sinBinSystem:
                match.TEMPORARY_DISMISSAL && match.TD_SYSTEM === "System A"
                    ? SinBinSystem.systemA
                    : SinBinSystem.none,
            players,
        };
        return newMatch;
    });
};
