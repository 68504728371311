import React, { FC } from "react";
import { IonContent, IonicSafeString, IonToast } from "@ionic/react";

interface ToastInterface {
    isOpen?: boolean;
    onDismiss?: () => void;
    message?: string | IonicSafeString;
    position?: "bottom" | "middle" | "top";
}

const ToastComponent: FC<ToastInterface> = ({
    isOpen = false,
    onDismiss,
    message,
    position,
}) => {
    return (
        <IonContent>
            <IonToast
                isOpen={isOpen}
                onDidDismiss={onDismiss}
                position={position}
                message={message}
                color="light"
                duration={1000}
            />
        </IonContent>
    );
};

export default ToastComponent;
