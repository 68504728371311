import {UserProfile} from "../../models/userProfile";
import {formatProfile, updateProfile} from "../profileService";
import {generateAllStatsOnServer} from "../statsService";

/**
 * Adds DOB to profile, updates the profile on the server & redux store, then generates
 * all stats on the server
 */
export async function saveDob(dob: string, userProfile: UserProfile) {
    const formattedProfile = formatProfile({ ...userProfile, dob });

    await updateProfile(formattedProfile.username, formattedProfile);
    await generateAllStatsOnServer(formattedProfile.username);
}
