export enum MessageType {
    Alert = "alert",
    Message = "message",
}

export class Message {
    id: string | number;
    type: MessageType;
    title?: string;
    from?: string;
    message: string;
    date: string; // TODO should this be a date?
    read: boolean;
    readDate?: string;
    matchId?: string;
    alertType?: string;

    constructor(
        id: string | number,
        type: MessageType,
        title: string | undefined,
        from: string | undefined,
        message: string,
        date: string,
        read: boolean,
        readDate: string | undefined,
        matchId: string | undefined,
        alertType: string | undefined
    ) {
        this.id = id;
        this.type = type;
        this.title = title;
        this.message = message;
        this.date = date;
        this.read = read;
        this.readDate = readDate;
        this.matchId = matchId;
        this.alertType = alertType;
    }

    static fromJSON(json: any) {
        return new Message(
            json.id,
            json.type,
            json.title,
            json.from,
            json.message,
            json.date,
            json.read,
            json.readDate,
            json.matchId,
            json.alertType
        );
    }
}
