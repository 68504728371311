import React, { FC } from "react";
import "./DividerText.css";

/**
 * Component for the divider text, wraps the text e.g. <DividerText>The text to display</DividerText>
 * @constructor
 */
export const DividerText: FC = ({ children }) => {
    return (
        <div className="social-login-divider">
            <div className="divider-line"></div>
            <div>{children}</div>
            <div className="divider-line"></div>
        </div>
    );
};
