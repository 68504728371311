import { IEventMap } from "./interfaces/eventMap";

export class Player {
    readonly name: string;
    readonly number: number;
    readonly captain: boolean;
    readonly starting: boolean;
    readonly teamOfficial?: boolean;
    readonly role?: string; // Really it is TeamOfficialRoles
    events?: IEventMap; // deprecated
    integrationId?: string | number;
    missingRealNumber?: boolean; // indicator that integration didn't provide a number for this player

    constructor(
        name: string,
        number: number,
        captain: boolean,
        starting: boolean,
        teamOfficial?: boolean,
        role?: string,
        integrationId?: string | number,
        missingRealNumber?: boolean
    ) {
        this.name = name;
        this.number = number;
        this.captain = captain;
        this.starting = starting;
        this.teamOfficial = teamOfficial;
        this.role = role;
        this.integrationId = integrationId;
        this.missingRealNumber = missingRealNumber;
    }

    static fromJSON(json: any) {
        return new Player(
            json.name,
            json.number,
            json.captain,
            json.starting,
            json.teamOfficial,
            json.role,
            json.integrationId,
            json.missingRealNumber
        );
    }
}
