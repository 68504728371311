// a redux action for the ErrorMessages interface.

import { POP_ERROR_MESSAGE, PUSH_ERROR_MESSAGE } from "./types";
import { TranslatableErrorMessage } from "../models/refsixState";

export const pushErrorMessage = (message: TranslatableErrorMessage) => {
    return {
        type: PUSH_ERROR_MESSAGE,
        message,
    };
};

export const popErrorMessage = () => {
    return {
        type: POP_ERROR_MESSAGE,
    };
};
