import { Bar } from "react-chartjs-2";
import { FC } from "react";
import { Chart as ChartJS, ChartData, ChartOptions, Legend } from "chart.js";
import { injectDummyData } from "../../services/dummyDataService";

ChartJS.register(Legend);

interface BarCharComponentProps {
    data: ChartData<"bar", number[]>;
    barChartOptions: ChartOptions<"bar">;
    testId: string;
    useDummyData?: boolean;
    className?: string;
}

const BarChartComponent: FC<BarCharComponentProps> = ({
    data,
    barChartOptions,
    testId,
    useDummyData,
    className = "bar-chart",
}) => {
    // Replace data values with dummy data but keep labels etc
    if (useDummyData) data = injectDummyData(data);

    return (
        <div className={className} data-testid={testId}>
            <Bar data={data} options={barChartOptions} />
        </div>
    );
};

export default BarChartComponent;
