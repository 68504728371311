import { FC } from "react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface DateFormatFilterProps {
    value: string;
    formatString: string;
    timezone?: string;
}

const DateFormatFilterComponent: FC<DateFormatFilterProps> = ({
    value,
    formatString,
    timezone,
}) => {
    let timeFormatted: string = "";
    let dateFormatted: string = "";
    let zoneFormat: string = "";
    const use12Hour = useSelector(
        (state: RootState) => state.settings.settings.use12HourTime
    );

    if (formatString === "time") {
        let timeFormatString = use12Hour ? "h:mma" : "HH:mm";
        if (timezone) {
            timeFormatString += " z";
            timeFormatted = moment(value).tz(timezone).format(timeFormatString);
        } else {
            timeFormatted = moment(value).format(timeFormatString);
        }
    } else {
        timeFormatted = timezone
            ? moment(value).tz(timezone).format(formatString)
            : moment(value).format(formatString);
    }

    return <> {`${dateFormatted} ${timeFormatted} ${zoneFormat}`}</>;
};

export default DateFormatFilterComponent;
