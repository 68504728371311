import { SET_SUBSCRIPTIONS } from "../actions/types";
import { SubscriptionState } from "../models/subscriptionState";

const initialState = new SubscriptionState(
    [],
    [],
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    false
);

function subscriptionsReducer(
    state: SubscriptionState = initialState,
    action: any
) {
    switch (action.type) {
        case SET_SUBSCRIPTIONS:
            return action.subscriptions;
        default:
            return state;
    }
}

export default subscriptionsReducer;
