import { IonText } from "@ionic/react";
import { FC } from "react";
import { MatchPhone } from "refsix-js-models";
import _ from "lodash";

interface MatchTagsInput {
    match: MatchPhone;
}
export const MatchTags: FC<MatchTagsInput> = ({ match }) => {
    return (
        <div className="match-tags">
            {_.uniq(
                match.keywords?.map((tag: string) => (
                    <IonText key={tag} style={{ color: "white" }}>
                        #{tag}{" "}
                    </IonText>
                ))
            )}
        </div>
    );
};
