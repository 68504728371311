import {SET_APPLE_LOGIN_CACHE_VALUE, SET_SETTINGS} from "./types";
import { Settings } from "../models/refsixState";

export const setSettings = (settings: Settings) => ({
    type: SET_SETTINGS,
    settings,
});
export const setAppleLoginCacheValue = (key: string, value: any) => ({
    type: SET_APPLE_LOGIN_CACHE_VALUE,
    key,
    value,
});
