import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonPage,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect } from "react";
import "../login/login.css";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { updateProfile } from "../../services/profileService";
import { routes } from "../../route/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { HeaderComponent, UserInfoComponent } from "../../components";
import {
    setLoggedInOrRegisterComplete,
    setShowOnBoardingFlow,
} from "../../redux/actions/appStatus";
import { mpCreateUserProfile } from "../../services/analytics/mixpanelService";
import { UserProfile } from "../../models/userProfile";
import { OnBoardingHeadingComponent } from "../onBoarding/onBoardingHeadingComponent";
import { startFreeTrial } from "../../services/subscriptionService";
import { useIsPro, useUsername } from "../../components/hooks";
import { trackEvent } from "../../services/analytics/analyticsService";
import { ConfigPhone } from "refsix-js-models";
import { getMisconductByLocale } from "refsix-core";
import { getCountryCode, getLocale } from "../../utils/localeUtils";
import { updateSettings } from "../../services/settingsService";

const RegisterStepTwo: FC = () => {
    const { t } = useTranslation();
    const profile = useSelector((root: RootState) => root.currentUser?.profile);
    const settings = useSelector((root: RootState) => root.settings);
    const route = useIonRouter();
    const dispatch = useDispatch();
    const username = useUsername();
    const isPro = useIsPro();
    const formMethods = useForm<FieldValues>({
        mode: "all",
        defaultValues: {
            ...profile,
            // Prefill the country with the user's locale if it hasn't been set yet
            country: profile?.country || getCountryCode(getLocale()),
        },
    });
    const profileExists = profile !== undefined;
    useEffect(() => {
        if (profileExists) {
            formMethods.reset(profile);
        }
    }, [profileExists]);
    const { handleSubmit } = formMethods;

    const giveRegistrationFreeTrial = async (username: string) => {
        try {
            await startFreeTrial(
                username,
                t("upgrade.upgrade.freeTrailHeading"),
                14
            );
            trackEvent("RegistrationFreeTrialStarted", {});
        } catch (error: any) {
            console.log(
                "Error when trying to give free trial during registration"
            );
        }
    };

    // when the component loads the try and apply the free trial
    useEffect(() => {
        if (username && !isPro) {
            console.log("Trying to give free trial during registration");
            giveRegistrationFreeTrial(username).catch((e) => {
                console.error(e);
            });
        }
    }, [username]);

    // just for use by the shouldGoStraightToFreeTrial variant

    const submitUpdate = async (profileFields: FieldValues) => {
        if (profile?.username) {
            const updatedProfile: UserProfile = {
                ...profile,
                ...profileFields,
                biometrics: {},
            };
            try {
                await updateProfile(profile.username, updatedProfile);

                // Create user profile in
                mpCreateUserProfile(updatedProfile, false);

                dispatch(setLoggedInOrRegisterComplete(true));
                dispatch(setShowOnBoardingFlow(true));

                // update the misconduct code on the settings
                console.log(
                    "detected default misconduct code of ",
                    getMisconductByLocale(
                        getLocale(),
                        updatedProfile.nationalFA
                    )
                );

                const updatedConfig: ConfigPhone = {
                    ...settings.settings,
                    misconductCode: getMisconductByLocale(
                        getLocale(),
                        updatedProfile.nationalFA
                    ),
                };

                await updateSettings({ ...settings, settings: updatedConfig });

                // do onboarding flow
                route.push(
                    routes.refsixOnboardingGettingStarted,
                    "forward",
                    "replace"
                );
            } catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <IonPage>
                    <HeaderComponent title={t("myaccount.profileHeader")} />

                    <IonContent>
                        <OnBoardingHeadingComponent
                            progressValue={0.1}
                            upperTitleInBold={t(
                                "register.registerOrLogin.letUsKnow"
                            )}
                        />

                        <IonCard className="match-card-container">
                            <UserInfoComponent />
                        </IonCard>
                    </IonContent>
                    <IonFooter>
                        <IonButton
                            expand="block"
                            onClick={handleSubmit(submitUpdate)}
                            className="login-button"
                            data-testid="stepTwoButton"
                        >
                            {t("general.next")}
                        </IonButton>
                    </IonFooter>
                </IonPage>
            </form>
        </FormProvider>
    );
};

export default RegisterStepTwo;
