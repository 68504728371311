import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { CountryLeagueSelectorComponent } from "./CountryLeagueSelectorComponent";
import { errorProps } from "../services/errorService";
import { IonItem, IonLabel, IonSelectOption } from "@ionic/react";
import { R6IonInput } from "./r6ionic/R6IonInput";
import "./userInfoComponent.css";
import { SearchClubModal } from "../modals/searchClubModal";
import { R6IonSelect } from "./r6ionic/R6IonSelect";

const UserInfoComponent: FC = () => {
    const { t } = useTranslation();
    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();
    const [showSelectClubOption, setShowSelectClubOption] =
        useState<boolean>(false);
    const [showSearchClubModal, setShowSearchClubModal] =
        useState<boolean>(false);

    const watchCountry = watch("country");
    const watchRefereeLevel = watch("refereeLevel");

    const knvbRefereeLevels = [
        "KNVB-scheidsrechter",
        "Pupillenscheidsrechter/spelbegeleider met diploma",
        "Pupillenscheidsrechter/spelbegeleider zonder diploma",
        "Verenigingsscheidsrechter met diploma",
        "Verenigingsscheidsrechter zonder diploma",
        "Other",
    ];

    useEffect(() => {
        if (watchRefereeLevel && watchRefereeLevel !== "KNVB-scheidsrechter") {
            setShowSelectClubOption(true);
        } else {
            setValue("refereeClub", undefined);
            setShowSelectClubOption(false);
        }
    }, [watchRefereeLevel]);

    return (
        <>
            <IonItem lines="full" {...errorProps("firstName", errors)}>
                <R6IonInput
                    name="firstName"
                    rules={{
                        required: t("utils.ErrorMessages.required").toString(),
                    }}
                    className="align-right"
                    label={t("general.firstName")}
                />
            </IonItem>
            <IonItem lines="full" {...errorProps("lastName", errors)}>
                <R6IonInput
                    name="lastName"
                    rules={{
                        required: t("utils.ErrorMessages.required").toString(),
                    }}
                    className="align-right"
                    label={t("general.lastName")}
                />
            </IonItem>
            <CountryLeagueSelectorComponent />
            <IonItem lines="full" {...errorProps("refereeLevel", errors)}>
                {watchCountry === "NL" && (
                    <>
                        <IonLabel>
                            {t("register.register.refereeLevel")}
                        </IonLabel>
                        <R6IonSelect
                            className="select-text"
                            slot="end"
                            name="refereeLevel"
                            interface="action-sheet"
                            interfaceOptions={{
                                cssClass: "positioning-select-sheet",
                            }}
                        >
                            {knvbRefereeLevels.map((level, index) => (
                                <IonSelectOption
                                    key={level}
                                    value={level}
                                    class="text-wrap"
                                >
                                    <span style={{ fontSize: 55 }}>
                                        {level}
                                    </span>
                                </IonSelectOption>
                            ))}
                        </R6IonSelect>
                    </>
                )}
                {watchCountry !== "NL" && (
                    <R6IonInput
                        name="refereeLevel"
                        rules={{
                            required: t(
                                "utils.ErrorMessages.required"
                            ).toString(),
                        }}
                        type="text"
                        inputMode={"text"}
                        className="align-right"
                        label={t("register.register.refereeLevel")}
                    />
                )}
            </IonItem>
            {showSelectClubOption && watchCountry === "NL" && (
                <IonItem lines="full" {...errorProps("refereeClub", errors)}>
                    <R6IonInput
                        label={t("register.register.selectClub")}
                        name="refereeClub"
                        onClick={() => setShowSearchClubModal(true)}
                        readonly={true}
                        className="align-right"
                        placeholder={t("register.register.selectClub")}
                    />
                </IonItem>
            )}
            {showSearchClubModal && (
                <SearchClubModal
                    showModal={showSearchClubModal}
                    setShowModal={setShowSearchClubModal}
                />
            )}
        </>
    );
};

export default UserInfoComponent;
