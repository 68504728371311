import {
    ChunkMessage,
    MessageChunkType,
    TrackingChunkAssembler,
} from "../TrackingChunkAssembler";
import * as Sentry from "@sentry/react";

export async function handleChunk(
    type: MessageChunkType,
    request: ChunkMessage
) {
    console.log(
        `${type} received chunk ${request.chunkNo}/${request.chunkCount}`
    );
    Sentry.addBreadcrumb({
        message: `[WatchService._receiveMessage()] ${type} received chunk ${request.chunkNo}/${request.chunkCount}`,
    });
    await TrackingChunkAssembler.getInstance().receivedChunk(type, request);
    return;
}
