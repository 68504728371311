import { ChartOptions, ChartType, TooltipItem } from "chart.js";
import { TFunction } from "react-i18next";
import { COLORS_DISTANCE } from "../../../constants/StatsColorPalette";
import { PeriodsType, TopMatchesProps } from "../../../services/matchService";

interface ChartDatasets {
    data: number[];
    label: string;
    borderColor: string;
    backgroundColor: string;
    borderWidth: number;
    showLine: boolean;
    capBezierPoints: boolean;
    tension: number;
}

function getDataset(
    data: number[],
    color: string,
    label: string
): ChartDatasets {
    return {
        data,
        label,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        showLine: true,
        capBezierPoints: true,
        tension: 0.2,
    };
}

/**
 * Returns an array of chart datasets based on the types of periods and
 * if there's extra time. Big clunky function but it works...
 */
export function getDatasets(
    { distanceBySegment, periodsNumbers }: TopMatchesProps,
    t: TFunction
): ChartDatasets[] {
    const hasThirds = periodsNumbers.includes(PeriodsType.Thirds);
    const hasQuarters = periodsNumbers.includes(PeriodsType.Quarters);

    let hasExtraTime = false;
    periodsNumbers.forEach((periodsNo, idx) => {
        if (distanceBySegment[idx].length > periodsNo) {
            hasExtraTime = true;
        }
    });

    const datasets = [];

    // Get all first period distance values
    datasets.push(
        getDataset(
            distanceBySegment.map((vals) => vals[0]),
            COLORS_DISTANCE[0],
            t("results.heatmap.first")
        )
    );
    // Get all second half distance values
    datasets.push(
        getDataset(
            distanceBySegment.map((vals) => vals[1]),
            COLORS_DISTANCE[1],
            t("results.heatmap.second")
        )
    );
    // Only if we have matches with third periods
    if (hasThirds) {
        datasets.push(
            getDataset(
                distanceBySegment.map((vals, idx) =>
                    periodsNumbers[idx] === 3 || periodsNumbers[idx] === 4
                        ? vals[2]
                        : 0
                ),
                COLORS_DISTANCE[2],
                t("results.heatmap.third")
            )
        );
    }
    // Only if we have matches with fourth periods
    if (hasQuarters) {
        datasets.push(
            getDataset(
                distanceBySegment.map((vals, idx) =>
                    periodsNumbers[idx] === 4 ? vals[3] : 0
                ),
                COLORS_DISTANCE[3],
                t("results.heatmap.fourth")
            )
        );
    }
    // Only if we have matches with extra time
    if (hasExtraTime) {
        datasets.push(
            getDataset(
                distanceBySegment.map((vals, idx) =>
                    periodsNumbers[idx] === 1 && vals.length > 1
                        ? vals[1]
                        : periodsNumbers[idx] === 2 && vals.length > 2
                        ? vals[2]
                        : periodsNumbers[idx] === 3 && vals.length > 3
                        ? vals[3]
                        : periodsNumbers[idx] === 4 && vals.length > 4
                        ? vals[4]
                        : 0
                ),
                COLORS_DISTANCE[4],
                t("results.heatmap.extraTime1")
            )
        );
        datasets.push(
            getDataset(
                distanceBySegment.map((vals, idx) =>
                    periodsNumbers[idx] === 1 && vals.length > 2
                        ? vals[2]
                        : periodsNumbers[idx] === 2 && vals.length > 3
                        ? vals[3]
                        : periodsNumbers[idx] === 3 && vals.length > 4
                        ? vals[4]
                        : periodsNumbers[idx] === 4 && vals.length > 5
                        ? vals[5]
                        : 0
                ),
                COLORS_DISTANCE[5],
                t("results.heatmap.extraTime2")
            )
        );
    }

    return datasets;
}

export function getDistanceLineBarOptions(
    useImperial: boolean,
    t: Function
): ChartOptions<"bar"> {
    return {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                fill: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    maxRotation: 80,
                    minRotation: 80,
                    color: "white",
                },
                stacked: true,
            },
            y: {
                title: {
                    display: true,
                    text: t(
                        `graphs.SpeedCategoryDistanceGraph.distanceScale.${
                            useImperial ? "imperial" : "metric"
                        }`
                    ),
                    color: "white",
                },
                ticks: {
                    color: "white",
                },
                stacked: true,
            },
        },
        interaction: {
            mode: "index",
        },
        plugins: {
            legend: {
                position: "bottom" as const,
                display: true,
                labels: {
                    boxWidth: 12,
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem: TooltipItem<ChartType>) {
                        const unit = useImperial ? " mi." : " km";
                        return tooltipItem.parsed.y.toString() + unit;
                    },
                },
            },
        },
    };
}
