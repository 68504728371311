import { IonCard, IonCol, IonItem, IonText } from "@ionic/react";
import { t } from "i18next";
import moment from "moment";
import { FC } from "react";
import { AggregatedStats, MatchPhone } from "refsix-js-models";
import {
    HeadlineNumbersComponent,
    ListOfTopComponent,
} from "../../../../components";
import { ProTeaser } from "../../../../components/ProTeaser";
import { FiltersModel } from "../../../../redux/models/filtersModel";
import { matchesWithMost } from "../../../../services/matchService";
import { CardType } from "../../../../services/misconductService";
import { CardPositions } from "./cardPositions";
import { MisconductCodes } from "./misconductCodes";
import {
    RED_CARD_GRAPH,
    YELLOW_CARD_GRAPH,
} from "../../../../constants/StatsColorPalette";
import { LIST_OF_TOP_DATE_FORMAT } from "../../../../components/ListOfTopComponent";

interface MisconductProps {
    matches: MatchPhone[];
    hasStatsAccess: boolean;
    aggregatedStats: AggregatedStats | undefined;
    filters: FiltersModel;
}

export const Misconduct: FC<MisconductProps> = ({
    matches,
    hasStatsAccess,
    aggregatedStats,
    filters,
}) => {
    const matchesWithMostYellows = matchesWithMost(
        matches,
        "yellowCardTotal",
        5,
        filters
    );
    const matchesWithMostReds = matchesWithMost(
        matches,
        "redCardTotal",
        5,
        filters
    );
    const matchesWithMostSinbins = matchesWithMost(
        matches,
        "sinBinsTotal",
        5,
        filters
    );

    return (
        <>
            <IonCard data-testid="trends-segment-misconduct">
                <HeadlineNumbersComponent
                    title={t("general.yellow")}
                    testIdsPrefix="yellow-cards"
                    data={[
                        aggregatedStats?.yellowCardTotal || 0,
                        aggregatedStats?.yellowCardAverage.toFixed(2) || 0,
                    ]}
                    labels={[
                        t("stats.stats.total"),
                        t("stats.stats.avgPerGame"),
                    ]}
                    useDummyData={!hasStatsAccess}
                    fromIndex={!hasStatsAccess ? 1 : undefined}
                />
                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-matches-most-yellows"
                >
                    <>
                        <IonItem lines="none">
                            <IonCol>
                                <IonText className="text-small">
                                    {t(
                                        "stats.stats.Discipline.matchesWithTheMost"
                                    )}
                                </IonText>
                            </IonCol>
                        </IonItem>
                        {matchesWithMostYellows.map((match, i) => (
                            <ListOfTopComponent
                                matchId={match._id}
                                key={i}
                                stat={
                                    match.stats && match.stats.yellowCardTotal
                                }
                                teamNames={`${match.homeTeam} v ${match.awayTeam}`}
                                date={moment(match.date).format(
                                    LIST_OF_TOP_DATE_FORMAT
                                )}
                                testId={`most-yellow-cards-item-${i}`}
                                useDummyData={!hasStatsAccess}
                            />
                        ))}
                    </>
                </ProTeaser>
                {aggregatedStats && aggregatedStats?.sinBinsTotal > 0 && (
                    <>
                        <HeadlineNumbersComponent
                            title={t("trends.misconduct.temporaryDismissals")}
                            testIdsPrefix="sin-bins"
                            data={[
                                aggregatedStats?.sinBinsTotal || 0,
                                aggregatedStats?.sinBinsAverage.toFixed(2) || 0,
                            ]}
                            labels={[
                                t("stats.stats.total"),
                                t("stats.stats.avgPerGame"),
                            ]}
                            useDummyData={!hasStatsAccess}
                            fromIndex={!hasStatsAccess ? 1 : undefined}
                        />
                        <ProTeaser
                            showTeaser={!hasStatsAccess}
                            teaserName="trends-matches-most-sinbins"
                        >
                            <>
                                <IonItem lines="none">
                                    <IonCol>
                                        <IonText className="text-small">
                                            {t(
                                                "stats.stats.Discipline.matchesWithTheMost"
                                            )}
                                        </IonText>
                                    </IonCol>
                                </IonItem>
                                {matchesWithMostSinbins.map((match, i) => (
                                    <ListOfTopComponent
                                        matchId={match._id}
                                        key={i}
                                        stat={
                                            match.stats &&
                                            match.stats.sinBinsTotal
                                        }
                                        teamNames={`${match.homeTeam} v ${match.awayTeam}`}
                                        date={moment(match.date).format(
                                            LIST_OF_TOP_DATE_FORMAT
                                        )}
                                        testId={`most-sinbins-item-${i}`}
                                        useDummyData={!hasStatsAccess}
                                    />
                                ))}
                            </>
                        </ProTeaser>
                    </>
                )}
            </IonCard>

            <IonCard>
                <HeadlineNumbersComponent
                    title={t("general.red")}
                    testIdsPrefix="red-cards"
                    data={[
                        aggregatedStats?.redCardTotal || 0,
                        aggregatedStats?.redCardAverage.toFixed(2) || 0,
                    ]}
                    labels={[
                        t("stats.stats.total"),
                        t("stats.stats.avgPerGame"),
                    ]}
                    useDummyData={!hasStatsAccess}
                    fromIndex={!hasStatsAccess ? 1 : undefined}
                />

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-matches-most-reds"
                >
                    <>
                        <IonItem lines="none">
                            <IonCol>
                                <IonText className="text-small">
                                    {t(
                                        "stats.stats.Discipline.matchesWithTheMost"
                                    )}
                                </IonText>
                            </IonCol>
                        </IonItem>
                        {matchesWithMostReds.map((match, i) => (
                            <ListOfTopComponent
                                matchId={match._id}
                                key={i}
                                stat={match.stats && match.stats.redCardTotal}
                                teamNames={`${match.homeTeam} v ${match.awayTeam}`}
                                date={moment(match.date).format(
                                    LIST_OF_TOP_DATE_FORMAT
                                )}
                                testId={`most-red-cards-item-${i}`}
                                useDummyData={!hasStatsAccess}
                            />
                        ))}
                    </>
                </ProTeaser>
            </IonCard>

            <ProTeaser
                showTeaser={!hasStatsAccess}
                teaserName="trends-yellow-card-positions"
            >
                <CardPositions
                    title={t("stats.stats.Discipline.yellowPitchPosition")}
                    positionsTotals={
                        (aggregatedStats &&
                            aggregatedStats.yellowCardPositions) ||
                        []
                    }
                    positionsPercentages={
                        (aggregatedStats &&
                            aggregatedStats.yellowCardPositionsPercentage) ||
                        []
                    }
                    testIdPrefix="yellow"
                    useDummyData={!hasStatsAccess}
                />
            </ProTeaser>
            <ProTeaser
                showTeaser={!hasStatsAccess}
                teaserName="trends-red-card-positions"
            >
                <CardPositions
                    title={t("stats.stats.Discipline.redPitchPosition")}
                    positionsTotals={
                        (aggregatedStats && aggregatedStats.redCardPositions) ||
                        []
                    }
                    positionsPercentages={
                        (aggregatedStats &&
                            aggregatedStats.redCardPositionsPercentage) ||
                        []
                    }
                    testIdPrefix="red"
                    useDummyData={!hasStatsAccess}
                />
            </ProTeaser>

            <ProTeaser
                showTeaser={!hasStatsAccess}
                teaserName="trends-yellow-card-categories"
            >
                <MisconductCodes
                    title={t("stats.stats.Discipline.yellowCardCategories")}
                    aggregatedStats={aggregatedStats}
                    cardType={CardType.Yellow}
                    graphDataTestId="yellow-codes-graph"
                    useDummyData={!hasStatsAccess}
                    graphColorOverride={YELLOW_CARD_GRAPH}
                />
            </ProTeaser>
            <ProTeaser
                showTeaser={!hasStatsAccess}
                teaserName="trends-red-card-categories"
            >
                <MisconductCodes
                    title={t("stats.stats.Discipline.redCardCategories")}
                    aggregatedStats={aggregatedStats}
                    cardType={CardType.Red}
                    graphDataTestId="red-codes-graph"
                    useDummyData={!hasStatsAccess}
                    graphColorOverride={RED_CARD_GRAPH}
                />
            </ProTeaser>
        </>
    );
};
