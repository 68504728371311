import { FC } from "react";
import { IonCard, IonItem, IonText, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { routes } from "../route/constants";
import AppleImage from "../assets/images/watches/watchOS.png";
import WearImage from "../assets/images/watches/wearOS.png";
import GarminImage from "../assets/images/watches/garmin.png";
import SamsungImage from "../assets/images/watches/tizen.png";
import "./SelectWatchComponent.css";
import { trackEvent } from "../services/analytics/analyticsService";
import { useUsername } from "./hooks";
import { useDispatch } from "react-redux";
import { setShowOnBoardingFlow } from "../redux/actions/appStatus";
import { updateUserActionsCheckList } from "../services/userActionsCheckListService";

export const SelectWatchComponent: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const MP_EVENT = "PairingSelectWatch";
    const username = useUsername();
    const dispatch = useDispatch();

    const renderWatchButton = (
        image: any,
        alt: string,
        platform: string,
        subtitle?: string
    ) => {
        return (
            <IonItem
                data-testid={`pair-watch-list-${platform}`}
                className="item-pair-watch-list"
                lines="none"
                onClick={() => {
                    trackEvent(MP_EVENT, {
                        watchPlatform: platform,
                        hasWatch: true,
                    });
                    route.push(
                        routes.refsixOnboardingPairWatch + `/${platform}`
                    );
                }}
                button
            >
                <img
                    src={image}
                    height={20}
                    alt={alt}
                    style={{ margin: "20px 0" }}
                />
                {subtitle && <IonText>{subtitle}</IonText>}
            </IonItem>
        );
    };

    const renderWatchList = () => {
        return (
            <>
                <IonCard color="transparent">
                    {renderWatchButton(GarminImage, "Garmin", "garmin")}
                    {renderWatchButton(SamsungImage, "Samsung", "samsung")}
                    {renderWatchButton(WearImage, "WearOS", "wearos")}
                    {renderWatchButton(
                        AppleImage,
                        "Apple Watch Family Member",
                        "apple",
                        t("settings.WatchSettings.appleFamilyMember")
                    )}
                </IonCard>
                <IonCard color="transparent">
                    <IonItem
                        data-testid="no-watch-item"
                        className="item-pair-watch-list"
                        button
                        lines="none"
                        onClick={() => {
                            trackEvent(MP_EVENT, {
                                watchPlatform: "none",
                                hasWatch: false,
                            });
                            dispatch(setShowOnBoardingFlow(false));
                            return route.push(
                                routes.refsixOnBoardingSetupComplete,
                                "root",
                                "replace"
                            );
                        }}
                    >
                        <IonText className="text-large">
                            {t("help.onBoarding.noWatch")}
                        </IonText>
                    </IonItem>
                </IonCard>
            </>
        );
    };

    return <>{renderWatchList()}</>;
};
