import { FC, useEffect } from "react";
import { runSyncForAllData } from "../services/fitnessDataSync/fitnessDataSyncService";
import { availableFeatures, useAppState } from "@capacitor-community/app-react";
import { handleRefreshIntegrationMatches } from "../services/integrations/integrationSyncUpdates";
import { Capacitor } from "@capacitor/core";

export const AppStateManagement: FC<{}> = ({ children }) => {
    const { state } = useAppState();
    useEffect(() => {
        const run = async () => {
            if (state) {
                console.log("App is active, running sync");
                // sync gps and heart rate to s3
                await runSyncForAllData();
                // check for update from integrations
                await handleRefreshIntegrationMatches();
            }
        };
        console.log(availableFeatures);
        // If we have state or if we are running on web, run the sync
        if (availableFeatures.appState || Capacitor.getPlatform() === "web") {
            run();
        }
    }, [state]);

    return <>{children}</>;
};
