import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPlayingSegments } from "refsix-core";
import {
    GpsCalibrationPoints,
    GpsPoint,
    MatchPhone,
    Sprint,
} from "refsix-js-models";
import {
    CardMapComponent,
    HeatMapComponent,
    OverlayMapComponent,
    SprintMapComponent,
} from ".";
import { useFitnessDataContext } from "./hooks";
import { UserProfile } from "../models/userProfile";
import { RootState } from "../redux/store";
import { setCalibrationPoints as saveCalibrationPoints } from "../services/fitnessDataSync/fitnessDataSyncService";
import { recalculateStats } from "../services/matchService";
import pitchImage from "../assets/images/pitch.png";
import { t } from "i18next";
import { IonButton, IonIcon, IonText } from "@ionic/react";
import { CalibrationModal } from "../pages/tabs/fixturesSegments/calibration/calibrationModal";
import exampleMatch from "../support/example-result.json";
import "./GpsMapComponent.css";
import {
    calibrationCompleted,
    calibrationStarted,
    trackEvent,
} from "../services/analytics/analyticsService";
import { CompareHeatmapsModal } from "../modals/compareHeatmapsModal";
import { swapHorizontal } from "ionicons/icons";
import { isFeatureAvailableCurrentLanguage } from "../utils/FeatureToggle";

export enum GpsMapType {
    Heatmap,
    SprintMap,
    CardMap,
    recalibrate,
    Compare,
}

interface GpsMapProps {
    match: MatchPhone;
    mapType: GpsMapType;
    sprintsData?: Sprint<number>[];
    testId?: string;
    useDummyData?: boolean;
}

export const GpsMap: FC<GpsMapProps> = ({
    match,
    mapType,
    sprintsData,
    testId,
    useDummyData,
}) => {
    const userProfile = useSelector(
        (state: RootState) => state.currentUser?.profile
    ) as UserProfile;

    const newMatch = useDummyData
        ? (exampleMatch.match as unknown as MatchPhone)
        : match;

    const [geoPoints, setGeoPoints] = useState<GpsPoint[]>();
    const [calibrationPoints, setCalibrationPoints] =
        useState<GpsCalibrationPoints>();
    const [showCalibrationModal, setShowCalibrationModal] = useState(false);

    const playedSegments =
        (newMatch.matchEvents && getPlayingSegments(newMatch)) || [];
    const [showCompareHeatMapsModal, setShowCompareHeatMapsModal] =
        useState(false);

    const { gpsData, loading, reload } = useFitnessDataContext();

    useEffect(() => {
        if (useDummyData) {
            setGeoPoints(exampleMatch.gps.geoPoints);
            setCalibrationPoints(
                exampleMatch.gps.calibrationPoints as GpsCalibrationPoints
            );
        } else if (gpsData && gpsData.geoPoints) {
            setGeoPoints(gpsData.geoPoints);
            setCalibrationPoints(gpsData.calibrationPoints);
        }
    }, [gpsData]);

    useEffect(() => {
        ///reload();
    }, [match.stats, reload]);

    const handleCornersSubmitted = (points: GpsCalibrationPoints) => {
        setShowCalibrationModal(false);

        if (newMatch._id) {
            saveCalibrationPoints(
                newMatch._id,
                userProfile.username,
                points
            ).then(() => recalculateStats(newMatch, userProfile));
        }
    };

    const renderMap = () => {
        if (
            (mapType === GpsMapType.Heatmap ||
                mapType === GpsMapType.Compare) &&
            hasAllDataForHeatmap()
        ) {
            return (
                <HeatMapComponent
                    playingSegments={playedSegments}
                    calibrationPoints={
                        calibrationPoints as GpsCalibrationPoints
                    }
                    geoPoints={geoPoints || []}
                    periodsNo={newMatch.periodsNo as string}
                    testId={testId}
                />
            );
        }
        if (mapType === GpsMapType.SprintMap && hasAllDataForSprintMap()) {
            return (
                <SprintMapComponent
                    playingSegments={playedSegments}
                    calibrationPoints={
                        calibrationPoints as GpsCalibrationPoints
                    }
                    geoPoints={geoPoints || []}
                    periodsNo={newMatch.periodsNo as string}
                    sprints={sprintsData as Sprint<number>[]}
                    match={newMatch}
                    testId={testId}
                />
            );
        }
        if (mapType === GpsMapType.CardMap && hasAllDataForCardMap()) {
            return (
                <CardMapComponent
                    playingSegments={playedSegments}
                    calibrationPoints={
                        calibrationPoints as GpsCalibrationPoints
                    }
                    geoPoints={geoPoints || []}
                    match={newMatch}
                    testId={testId}
                />
            );
        }
    };

    const renderCalibrationOverlay = () => {
        const title =
            mapType === GpsMapType.Heatmap || mapType === GpsMapType.Compare
                ? t("fixture.FixtureSummaryStats.viewHeatmap")
                : mapType === GpsMapType.CardMap
                ? t("fixture.FixtureSummaryStats.viewCardmap")
                : t("fixture.FixtureSummaryStats.viewSprintmap");
        return (
            <OverlayMapComponent
                imageUrl={pitchImage}
                title={title}
                showButton={true}
                onButtonClick={() => {
                    setShowCalibrationModal(true);
                    calibrationStarted(mapType.toString(), match);
                }}
                btnTestId="heatMapOverlayBtn"
            />
        );
    };
    const renderNoGpsDataOverlay = () => (
        <OverlayMapComponent
            imageUrl={pitchImage}
            title={t("fixture.FixtureSummaryStats.errorCode2")}
        />
    );

    const hasNoGpsData = () => !useDummyData && !gpsData && !loading;

    const hasAllDataForHeatmap = () =>
        !!(
            playedSegments &&
            geoPoints &&
            calibrationPoints &&
            newMatch.periodsNo
        );
    const hasAllDataForSprintMap = () =>
        !!(
            playedSegments &&
            geoPoints &&
            calibrationPoints &&
            newMatch.periodsNo &&
            sprintsData
        );
    const hasAllDataForCardMap = () =>
        !!(
            playedSegments &&
            geoPoints &&
            calibrationPoints &&
            newMatch.periodsNo &&
            newMatch.matchEvents
        );
    const hasAllDataForMap = () =>
        ((mapType === GpsMapType.Heatmap || mapType === GpsMapType.Compare) &&
            hasAllDataForHeatmap()) ||
        (mapType === GpsMapType.SprintMap && hasAllDataForSprintMap()) ||
        (mapType === GpsMapType.CardMap && hasAllDataForCardMap());

    return (
        <div
            className={
                hasNoGpsData()
                    ? "gps-map no-gps-data"
                    : hasAllDataForMap()
                    ? "gps-map "
                    : "gps-map needs-calibration"
            }
        >
            {hasNoGpsData()
                ? renderNoGpsDataOverlay()
                : hasAllDataForMap()
                ? renderMap()
                : renderCalibrationOverlay()}
            {calibrationPoints && mapType !== GpsMapType.Compare && (
                <div
                    className="recalibrate-item item-no-padding-end"
                    style={{
                        justifyContent: isFeatureAvailableCurrentLanguage(
                            "compareHeatmapsButton"
                        )
                            ? "space-between"
                            : "flex-end",
                    }}
                >
                    {mapType === GpsMapType.Heatmap &&
                        isFeatureAvailableCurrentLanguage(
                            "compareHeatmapsButton"
                        ) && (
                            <IonButton
                                size="small"
                                data-testid="compare-heatmaps-button"
                                onClick={() => {
                                    setShowCompareHeatMapsModal(true);
                                    trackEvent("HeatmapCompare", {});
                                }}
                            >
                                <IonIcon icon={swapHorizontal} slot={"start"} />
                                {t(
                                    "fixture.FixtureSummaryStats.compareHeatMaps"
                                )}
                            </IonButton>
                        )}

                    <IonButton
                        fill="clear"
                        size="small"
                        data-testid="adjust-pitch-button"
                        style={{ justifySelf: "flex-end" }}
                        onClick={() => {
                            mapType = GpsMapType.recalibrate;
                            setShowCalibrationModal(true);
                            calibrationStarted(mapType.toString(), match);
                        }}
                    >
                        <IonText className="recalibrate">
                            {t("fixture.FixtureSummaryStats.adjustPitch")}
                        </IonText>
                    </IonButton>
                </div>
            )}

            <CompareHeatmapsModal
                showModal={showCompareHeatMapsModal}
                match={match}
                onCloseModal={() => setShowCompareHeatMapsModal(false)}
            />

            <CalibrationModal
                display={showCalibrationModal}
                gpsPoints={geoPoints || []}
                onCornersSubmited={(points) => {
                    calibrationCompleted(mapType.toString(), match);
                    handleCornersSubmitted(points);
                }}
                onCloseModal={() => setShowCalibrationModal(false)}
            />
        </div>
    );
};
