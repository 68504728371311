import { matchPath, Redirect, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import {
    IonApp,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonLabel,
    IonLoading,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
    barChart,
    barChartOutline,
    calendar,
    calendarOutline,
    fileTrayFull,
    fileTrayFullOutline,
    menu,
    menuOutline,
    refresh,
} from "ionicons/icons";
import Login from "../pages/login/login";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import "./tabbarStyle.css";
import CreateMatchPage from "../pages/match/createMatch";
import { getProfile } from "../services/profileService";
import { LoginSession } from "../redux/models/loginSession";
import { addUserProfile } from "../redux/actions/addUserProfile";
import { setCurrentUser } from "../redux/actions/currentUser";
import { SubscriptionService } from "../services/subscriptionService";
import FixturesSegments from "../pages/tabs/fixturesSegments/fixturesSegments";
import React, { FC, useEffect, useState } from "react";
import { PageViewAnalytics } from "../services/analytics/pageViewAnalytics";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { routes, sentryDsn } from "./constants";
import Settings from "../pages/tabs/more/settings/settings";
import { Profile } from "../pages/tabs/more/profile";
import Fixtures from "../pages/tabs/fixtures";
import Results from "../pages/tabs/results";
import Trends from "../pages/tabs/trends";
import More from "../pages/tabs/more";
import { InAppPurchaseStoreProvider } from "../components/InAppPurchaseStore";
import { AppUpdateComponent } from "../components";
import { Support } from "../pages/tabs/more/support";
import { ContactUs } from "../pages/tabs/more/contactUs";
import { MisconductCodes } from "../pages/tabs/more/settings/misconduct";
import { EditCustomMisconduct } from "../pages/tabs/more/settings/editCustomMisconduct";
import { ManageTemplates } from "../pages/tabs/more/settings/templates";
import { Template } from "../pages/tabs/more/settings/template";
import TimerOptions from "../pages/tabs/more/timerOptions";
import ForgotPassword from "../pages/password/forgotPassword";
import RegisterStepTwo from "../pages/register/registerStepTwo";
import Register from "../pages/register/register";
import ForgotPasswordComplete from "../pages/password/forgotPasswordComplete";
import { EditEvent } from "../pages/tabs/fixturesSegments/editEvent";
import { QuickResultForm } from "../pages/addResult/addResult";
import { useTranslation } from "react-i18next";
import { WatchDebug } from "../pages/tabs/more/watchDebug";
import AssignrIntegration from "../pages/tabs/more/integrations/assignrIntegration";
import DbuIntegration from "../pages/tabs/more/integrations/dbuIntegration";
import Watches from "../pages/tabs/more/settings/watches";
import PairWatch from "../pages/tabs/more/settings/pairWatch";
import { isAndroid, isIos } from "../services/platformDetection";
import PhoneMatchWatchPage from "../pages/match/phoneMatchWatch";
import AddResultDetailsForm from "../pages/addResult/AddResultDetailsForm";
import StackSportsIntegration from "../pages/tabs/more/integrations/stackSportsIntegration";
import * as rtlDetect from "rtl-detect";
import { Integrations } from "../pages/tabs/more/integrations";
import { mpDisable, mpInit } from "../services/analytics/mixpanelService";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { checkAuthSession } from "../services/sessionRefresh";
import { getCurrentVersionQuickly } from "../components/hooks/appVersionHooks";
import { FabButtonComponent } from "../components/FabButtonComponent";
import { Subscriptions } from "../pages/tabs/more/subscriptions";
import RequestTeamSheet from "../pages/teamsheets/requestTeamSheet";
import { AppStateManagement } from "../components/AppStateManagement";
import { MockWatchMessaging } from "../pages/debug/MockWatchMessaging";
import DeveloperTools from "../pages/debug/DeveloperTools";
import PouchDebug from "../pages/debug/PouchDebug";
import PouchDebugMatch from "../pages/debug/PouchDebugMatch";
import { TeamSheetWrapper } from "../pages/teamsheets/newTeamSheet";
import TeamPacks from "../pages/tabs/more/settings/teamPacks";
import { TeamPacksProvider } from "../components/hooks/TeamPacks";
import _ from "lodash";
import { UploadDownloadFiles } from "../pages/upload/uploadDownloadFiles";
import { AdMobService } from "../services/AdMobService";
import RegisterUpgrade from "../pages/register/registerUpgrade";
import RegisterFreeTrial from "../pages/register/registerFreeTrial";
import { CalendarSync } from "../pages/tabs/more/settings/calendarSync";
import AppUrlListener from "../components/AppUrlListener";
import { Notifications } from "../pages/tabs/more/notifications";
import { RefAssistFiles } from "../pages/tabs/more/integrations/refAssistFiles";
import RefAssistIntegration from "../pages/tabs/more/integrations/refAssistIntegration";
import { oneSignalSetUser } from "../services/OneSignal";
import PushConsentModal from "../modals/pushConsentModal";
import { OneSignalPushListener } from "../components/OneSignalPushListener";
import { PushToaster } from "../components/PushToaster";
import { checkIfTemplateOnDBExists } from "../services/templateService";
import { createSettingsIfDoesNotExist } from "../services/settingsService";
import { PushConsentPage } from "../pages/tabs/more/settings/pushConsentPage";
import { CancelSubscription } from "../pages/tabs/more/subscription/cancelSubscription";
import { OnboardingWatchFound } from "../pages/onBoarding/onboardingWatchFound";
import { OnBoardingSelectWatch } from "../pages/onBoarding/onBoardingSelectWatch";
import { OnBoardingPairWatch } from "../pages/onBoarding/onBoardingPairWatch";
import { OnBoardingShareLocationData } from "../pages/onBoarding/onBoardingShareLocationData";
import { OnBoardingShareHealthDataWrapper } from "../pages/onBoarding/onBoardingShareHealthData";
import { OnBoardingTimerSettings } from "../pages/onBoarding/onBoardingTimerSettings";
import { OnBoardingTimerSettingsStepTwo } from "../pages/onBoarding/onBoardingTimerSettingsStepTwo";
import { OnBoardingSetupComplete } from "../pages/onBoarding/onBoardingSetupComplete";
import { OnboardingFindingYourWatch } from "../pages/onBoarding/OnboardingFindingYourWatch";
import { SelectProOrPlus } from "../pages/refsixPlus/selectProOrPlus";
import { INSTALLED_VERSION_UNKNOWN } from "../redux/models/refsixState";
import ReviewPage from "../pages/reviewPage";
import { RegisterOrLogin } from "../pages/register/registerOrLogin";
import { MigrateSystemB } from "../pages/migrateSystemB";
import { OnBoardingGoToWatch } from "../pages/onBoarding/onBoardingGoToWatch";
import ReferFriendPage from "../pages/mgm/referFriend";
import { AddKnvbMatch } from "../pages/match/addKnvbMatch";

enum TabSelected {
    fixtures = "fixtures",
    results = "results",
    trends = "trends",
    more = "more",
}

const routePaths = Object.values(routes).map((path) => ({ path }));
const history = createBrowserHistory();
const textDirection = rtlDetect.getLangDir(navigator.language);

const AppInit = async () => {
    Sentry.init({
        dsn: sentryDsn,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(
                    history as any,
                    routePaths,
                    matchPath
                ),
            }),
        ],
        tracesSampleRate: 0, // 20% of transactions sampled
        environment: process.env.NODE_ENV,
        normalizeDepth: 10, // depth of redux state to log
    });

    Sentry.configureScope(function (scope) {
        scope.addEventProcessor((event) => {
            return new Promise(async (resolve) => {
                try {
                    const version = await getCurrentVersionQuickly();
                    const eventWithAppVersion = {
                        ...event,
                        release:
                            version === INSTALLED_VERSION_UNKNOWN
                                ? undefined
                                : version,
                    };
                    return resolve(eventWithAppVersion);
                } catch (e) {
                    console.log("Error getting app version", e);
                    return resolve(event);
                }
            });
        });
    });

    AdMobService.getInstance();
};

AppInit();

function AuthStackScreen() {
    const userStorageEmail = useSelector(
        (state: RootState) => state.user.usernameOrEmail
    );

    return (
        <IonApp className={textDirection}>
            <IonReactRouter history={history}>
                <AppUrlListener></AppUrlListener>
                <OneSignalPushListener />
                <PageViewAnalytics />
                <IonRouterOutlet>
                    <Route
                        path={routes.registerOrLogin}
                        component={RegisterOrLogin}
                    />
                    <Route path={routes.register} component={Register} />
                    <Route path={routes.login} component={Login} />
                    <Route
                        path={routes.forgotPassword}
                        render={() => <ForgotPassword />}
                    />
                    <Route
                        path={routes.resetPassword}
                        component={ForgotPasswordComplete}
                    />
                    <Route
                        path={routes.mockWatchMessaging}
                        component={MockWatchMessaging}
                    />
                    <Route
                        path={routes.developerTools}
                        component={DeveloperTools}
                    />
                    <Route path={routes.pouchDebug} component={PouchDebug} />
                    <Route
                        path={routes.pouchDebugMatch}
                        component={PouchDebugMatch}
                    />
                    <Redirect
                        exact
                        to={
                            userStorageEmail
                                ? routes.login
                                : routes.registerOrLogin
                        }
                        from={"/"}
                    />
                    <Redirect to={routes.login} from={routes.refsixMore} />
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
}
const TabBarScreens: FC<{}> = () => {
    const [tabSelected, setTabSelected] = useState<string>(
        window.location.pathname === routes.refsixFixtures
            ? TabSelected.fixtures
            : window.location.pathname === routes.refsixTrends + "/matches"
            ? TabSelected.trends
            : TabSelected.results
    );
    const { t } = useTranslation();

    const loadingMatches = useSelector(
        (state: RootState) => state.matches.loading
    );

    useEffect(() => {
        // Initialise mixpanel once logged in
        mpInit();
        // See if auth session expires soon and needs updating
        checkAuthSession().catch(() => {});
        // Live updates
        CapacitorUpdater.notifyAppReady();

        // Disable mixpanel when this component gets unmounted
        return () => {
            mpDisable();
        };
    }, []);

    useEffect(() => {
        if (!loadingMatches) {
            // Creating settings for user if they don't exist
            createSettingsIfDoesNotExist();
            // Check templates
            checkIfTemplateOnDBExists();
        }
    }, [loadingMatches]);

    const renderProviderNative = (children: JSX.Element) => {
        //return children;
        if (!isIos() && !isAndroid()) return children;
        return _.reduce(
            [InAppPurchaseStoreProvider],
            (acc, C) => {
                return <C>{acc}</C>;
            },
            children
        );
    };
    const renderProvider = (children: JSX.Element) => {
        //return children;
        return _.reduce(
            [TeamPacksProvider],
            (acc, C) => {
                return <C>{acc}</C>;
            },
            children
        );
    };
    const renderAllProviders = (children: JSX.Element) => {
        return renderProviderNative(renderProvider(children));
    };

    return renderAllProviders(
        <IonApp className={textDirection}>
            <AppUpdateComponent />
            <AppStateManagement />
            {/* LoadingMatches can be undefined when the app is first booting so checking not false */}
            <IonLoading
                isOpen={loadingMatches !== false}
                htmlAttributes={{ "data-testid": "loadingMatchesSpinner" }}
            ></IonLoading>
            <PushConsentModal />
            <IonReactRouter history={history}>
                <AppUrlListener />
                <OneSignalPushListener />
                <PushToaster />
                <PageViewAnalytics />
                <IonRouterOutlet>
                    <Route
                        path={routes.migrateSystemB}
                        component={MigrateSystemB}
                    />
                    <Route
                        path={routes.registerStepTwo}
                        component={RegisterStepTwo}
                    />
                    <Route
                        path={routes.registerUpgrade}
                        component={RegisterUpgrade}
                    />
                    <Route
                        path={routes.registerFreeTrial}
                        component={RegisterFreeTrial}
                    />
                    <Route
                        path={routes.refsixOnboardingGettingStarted}
                        component={OnboardingFindingYourWatch}
                    />
                    <Route
                        path={
                            routes.refsixOnboardingFoundWatch +
                            "/:watchPlatform"
                        }
                        component={OnboardingWatchFound}
                    />
                    <Route
                        path={routes.refsixOnboardingNoWatchFound}
                        component={OnBoardingSelectWatch}
                    />
                    <Route
                        path={
                            routes.refsixOnboardingPairWatch + "/:watchPlatform"
                        }
                        component={OnBoardingPairWatch}
                    />
                    <Route
                        path={routes.refsixOnBoardingShareLocationData}
                        component={OnBoardingShareLocationData}
                    />
                    <Route
                        path={routes.refsixOnBoardingShareHealthData}
                        component={OnBoardingShareHealthDataWrapper}
                    />
                    <Route
                        path={routes.refsixOnBoardingTimerSettings}
                        component={OnBoardingTimerSettings}
                    />
                    <Route
                        path={routes.refsixOnBoardingSetupComplete}
                        component={OnBoardingSetupComplete}
                    />
                    <Route
                        path={routes.refsixOnBoardingTimerSettingsStepTwo}
                        component={OnBoardingTimerSettingsStepTwo}
                    />
                    <Route
                        path={routes.refsixOnBoardingGoToWatch}
                        component={OnBoardingGoToWatch}
                    />
                    <Route
                        path={routes.upgrade + "/:upgradeType"}
                        component={SelectProOrPlus}
                    />
                    <Redirect
                        exact
                        to={routes.upgrade + "/pro"}
                        from={routes.upgrade}
                    />
                    <Route
                        path={routes.match + "/:id/:page"}
                        component={FixturesSegments}
                    />
                    <Redirect
                        from="/overview/:id"
                        to={routes.match + "/:id/overview"}
                    />
                    <Route
                        path={routes.createMatch}
                        component={CreateMatchPage}
                    />
                    <Route
                        path={routes.createMatch + "/:id"}
                        component={CreateMatchPage}
                    />
                    <Route
                        path={routes.refsixMoreProfile}
                        component={Profile}
                    />
                    <Route
                        path={routes.refsixMoreNotifications}
                        component={Notifications}
                    />
                    <Route
                        path={routes.refsixMoreSubscriptions}
                        component={Subscriptions}
                    />
                    <Route
                        path={routes.refsixSubscriptionCancel + "/:provider"}
                        component={CancelSubscription}
                    />
                    <Route
                        path={routes.refsixMoreSettings}
                        component={Settings}
                    />
                    <Route
                        path={routes.refsixMoreWatchDebug}
                        component={WatchDebug}
                    />
                    <Route
                        path={routes.mockWatchMessaging}
                        component={MockWatchMessaging}
                    />
                    <Route
                        path={routes.developerTools}
                        component={DeveloperTools}
                    />
                    <Route path={routes.pouchDebug} component={PouchDebug} />
                    <Route
                        path={routes.pouchDebugMatch}
                        component={PouchDebugMatch}
                    />
                    <Route
                        path={routes.refsixMoreSupport}
                        component={Support}
                    />
                    <Route
                        path={routes.refsixTimerOptions}
                        component={TimerOptions}
                    />
                    <Route
                        path={routes.refsixMoreSupportContact}
                        component={ContactUs}
                    />
                    <Route
                        path={routes.refsixMoreSettingsMisconductCodes}
                        component={MisconductCodes}
                    />
                    <Route
                        path={
                            routes.refsixMoreSettingsEditCustomMisconductCodes
                        }
                        component={EditCustomMisconduct}
                    />
                    <Route
                        path={routes.refsixMoreSettingsTemplates}
                        component={ManageTemplates}
                    />
                    <Route
                        path={routes.refsixMoreSettingsTemplatesTemplate}
                        component={Template}
                    />
                    <Route
                        path={
                            routes.refsixMoreSettingsTemplatesTemplate + "/:id"
                        }
                        component={Template}
                    />
                    <Route
                        path={routes.refsixAddEditTeamSheet + "/:id/:team"}
                        component={TeamSheetWrapper}
                    />
                    <Route
                        path={
                            routes.refsixResultsAddEvent + "/:matchId/:eventTs?"
                        }
                        component={EditEvent}
                    />
                    <Route
                        path={routes.refsixMoreIntegrations}
                        component={Integrations}
                    />
                    <Route
                        path={routes.refsixDbuIntegration}
                        component={DbuIntegration}
                    />
                    <Route
                        path={routes.refsixAssignrIntegration}
                        component={AssignrIntegration}
                    />
                    <Route
                        path={routes.refsixAssignrIntegrationAutoStart}
                        render={(props) => (
                            <AssignrIntegration autoStart={true} />
                        )}
                    />
                    <Route
                        path={routes.refsixStackSportsIntegration}
                        component={StackSportsIntegration}
                    />
                    <Route
                        path={routes.refsixRefAssistIntegration}
                        component={RefAssistIntegration}
                    />
                    <Route
                        path={routes.refsixRefAssistFiles}
                        component={RefAssistFiles}
                    />

                    <Route
                        path={routes.refsixAddResult}
                        component={QuickResultForm}
                    />
                    <Route
                        path={routes.addMultipleMatches}
                        component={UploadDownloadFiles}
                    />
                    <Route
                        path={routes.addKnvbMatch}
                        component={AddKnvbMatch}
                    />
                    <Route
                        exact
                        path={routes.addResultDetail + "/:id"}
                        component={AddResultDetailsForm}
                    />
                    <Route
                        exact
                        path={routes.refsixMoreSettingsManageWatches}
                        component={Watches}
                    />
                    <Route
                        exact
                        path={routes.refsixMoreSettingsManageWatchesPair}
                        component={PairWatch}
                    />
                    <Route
                        exact
                        path={routes.refsixMoreSettingsSyncCalendar}
                        component={CalendarSync}
                    />
                    <Route
                        exact
                        path={routes.refsixMoreSettingsPushConsent}
                        component={PushConsentPage}
                    />

                    <Route
                        exact
                        path={routes.startWatchPhone + "/:id"}
                        component={PhoneMatchWatchPage}
                    />
                    <Route
                        exact
                        path={routes.teamPacks}
                        component={TeamPacks}
                    />
                    <Route
                        exact
                        path={routes.refsixRequestTeamSheet + "/:id"}
                        component={RequestTeamSheet}
                    />
                    <Route exact path={routes.review} component={ReviewPage} />
                    <Redirect
                        exact
                        to={routes.refsixFixtures}
                        from={routes.refsixFixturesFilter}
                    />
                    <Redirect
                        exact
                        to={routes.refsixResults}
                        from={routes.refsixResultsFilters}
                    />
                    <Redirect
                        exact
                        to={routes.refsixTrends + "/matches"}
                        from={routes.refsixTrendsFilter}
                    />
                    <Route
                        path={routes.refsix}
                        render={() => (
                            <div className="showTabs">
                                <IonTabs>
                                    <IonRouterOutlet>
                                        <Route
                                            exact
                                            path={routes.refsixFixtures}
                                            component={Fixtures}
                                        />

                                        <Route
                                            exact
                                            path={routes.refsixResults}
                                            component={Results}
                                        />
                                        <Route
                                            exact
                                            path={routes.refsixTrends}
                                            component={Trends}
                                        />
                                        <Route
                                            exact
                                            path={
                                                routes.refsixTrends + "/:page"
                                            }
                                            component={Trends}
                                        />
                                        <Route
                                            exact
                                            path={routes.refsixMore}
                                            component={More}
                                        />
                                    </IonRouterOutlet>
                                    <IonTabBar slot="bottom" id="ionTabBar">
                                        <IonTabButton
                                            tab={TabSelected.fixtures}
                                            href={routes.refsixFixtures}
                                        >
                                            <IonIcon
                                                icon={calendarOutline}
                                                className="tab-icon-unselected"
                                            />
                                            <IonIcon
                                                icon={calendar}
                                                className="tab-icon-selected"
                                            />
                                            <IonLabel>
                                                {t("general.fixtures")}
                                            </IonLabel>
                                        </IonTabButton>
                                        <IonTabButton
                                            data-testid="tab-results"
                                            tab={TabSelected.results}
                                            href={routes.refsixResults}
                                        >
                                            <IonIcon
                                                icon={fileTrayFullOutline}
                                                className="tab-icon-unselected"
                                            />
                                            <IonIcon
                                                icon={fileTrayFull}
                                                className="tab-icon-selected"
                                            />
                                            <IonLabel>
                                                {t("general.results")}
                                            </IonLabel>
                                        </IonTabButton>
                                        <IonTabButton />
                                        <IonTabButton
                                            data-testid="tab-trends"
                                            tab={TabSelected.trends}
                                            href={routes.refsixTrends}
                                        >
                                            <IonIcon
                                                icon={barChartOutline}
                                                className="tab-icon-unselected"
                                            />
                                            <IonIcon
                                                icon={barChart}
                                                className="tab-icon-selected"
                                            />
                                            <IonLabel>
                                                {t("general.trends")}
                                            </IonLabel>
                                        </IonTabButton>
                                        <IonTabButton
                                            data-testid="tab-more"
                                            tab={TabSelected.more}
                                            href={routes.refsixMore}
                                        >
                                            <IonIcon
                                                icon={menuOutline}
                                                className="tab-icon-unselected"
                                            />
                                            <IonIcon
                                                icon={menu}
                                                className="tab-icon-selected"
                                            />
                                            <IonLabel>
                                                {t(
                                                    "fixture.FixtureSummaryEarnings.more"
                                                )}
                                            </IonLabel>
                                            {/* TODO mage message count show here (not trivial) */}
                                            {/*<IonLabel>*/}
                                            {/*    <MessageCount*/}
                                            {/*        slot={undefined}*/}
                                            {/*    />*/}
                                            {/*</IonLabel>*/}
                                        </IonTabButton>
                                    </IonTabBar>
                                </IonTabs>
                                <FabButtonComponent />
                            </div>
                        )}
                    />
                    <Route
                        path={routes.referFriend}
                        component={ReferFriendPage}
                        exact
                    />
                    <Route
                        path={routes.login}
                        render={() => <Redirect to={routes.refsixFixtures} />}
                        exact
                    />
                    <Redirect exact to={routes.refsixFixtures} from={"/"} />
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
};

function Root() {
    const { t } = useTranslation();
    const userLoggedIn = useSelector((state: RootState) => state.auth.loggedIn);
    const session: LoginSession | undefined = useSelector(
        (state: RootState) => state.auth.session
    );
    const dispatch = useDispatch();

    if (userLoggedIn) {
        // TODO should we track this instance in a provider or something?
        const subService = SubscriptionService.getInstance();
        subService.initialize(userLoggedIn);
        if (session) {
            getProfile(session.user_id)
                .then(function (profile) {
                    oneSignalSetUser(session.user_id, profile.email);
                    dispatch(setCurrentUser(profile));
                    dispatch(addUserProfile(profile));
                })
                .catch((error) => console.log("Couldn't set profile", error));
        }
    }
    return (
        <Sentry.ErrorBoundary
            fallback={
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            {t("general.errorTryAgain")}
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonButton
                            onClick={() => (window.location.pathname = "/")}
                        >
                            <IonIcon slot="icon-only" icon={refresh}></IonIcon>
                        </IonButton>
                    </IonCardContent>
                </IonCard>
            }
        >
            {userLoggedIn ? <TabBarScreens /> : <AuthStackScreen />}
        </Sentry.ErrorBoundary>
    );
}

export default Root;
