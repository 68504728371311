import { Fixture } from "./overview";
import { FC, useEffect, useState } from "react";
import {
    IonCard,
    IonCol,
    IonGrid,
    IonItem,
    IonRow,
    IonText,
} from "@ionic/react";
import { TFunction, useTranslation } from "react-i18next";
import {
    GpsStats,
    HrData,
    MatchPhone,
    OfficialRole,
    Sprint,
} from "refsix-js-models";
import "./stats.css";
import {
    DistanceCoveredComponent,
    HeartRateComponent,
    SpeedCategoryComponent,
} from "../../../components";
import { getPlayingSegments, GpsProcessingService } from "refsix-core";
import { useFitnessDataContext } from "../../../components/hooks";
import { GpsMap, GpsMapType } from "../../../components/GpsMapComponent";
import { ProTeaser } from "../../../components/ProTeaser";
import { hasFeatureAccess } from "../../../services/subscriptionService";
import exampleMatch from "../../../support/example-result.json";
import ScrollableContentWithAds from "../../../components/ScrollableContentsWithAd";

export function notStatsDesc(match: MatchPhone, t: TFunction) {
    return (
        <IonGrid>
            <IonRow className="match-detail-row">
                {!match.matchFinished && (
                    <div style={{ marginTop: 10 }}>
                        <IonText>{t("stats.stats.noData")}</IonText>
                    </div>
                )}
            </IonRow>
        </IonGrid>
    );
}

const Stats: FC<Fixture> = ({ match }) => {
    const { t } = useTranslation();
    const heatMapAccess = hasFeatureAccess("heatmap");
    const sprintMapAccess = hasFeatureAccess("sprints");
    const cardMapAccess = hasFeatureAccess("cardmap");
    const heartRateAccess = hasFeatureAccess("heartRate");

    const playedSegments =
        (match.matchEvents && getPlayingSegments(match)) || [];
    const [calculatedStats, setCalculatedStats] = useState<GpsStats | {}>({});
    const [filteredHrData, setFilteredHrData] = useState<HrData>();
    const [sprintsData, setSprintsData] = useState<Sprint<number>[]>();

    const { gpsData, hrData, loading, reload } = useFitnessDataContext();

    useEffect(() => {
        // Reload stats if match has gps or heart rate data, but hasTracking or hasHeartRate is false
        reload();
    }, []);

    useEffect(() => {
        if (match.matchFinished) {
            if (gpsData && gpsData.matchTimings) {
                const gpsService = new GpsProcessingService();
                const stats = gpsService.calculateStats(
                    gpsData.matchTimings,
                    gpsData.geoPoints,
                    gpsData.accuracyThreshold
                ) as GpsStats;
                const sprintsData = gpsService.calculateSprints(
                    gpsData.matchTimings,
                    gpsData.geoPoints
                );
                setCalculatedStats(stats);
                if (sprintMapAccess) {
                    setSprintsData(sprintsData.sprints);
                } else {
                    setSprintsData(exampleMatch.gps.sprints);
                }
            }
        }
    }, [gpsData, match.stats, match.matchFinished, gpsData?.matchTimings]);

    useEffect(() => {
        if (match.matchFinished) {
            if (hrData) {
                if (
                    hrData.heartRateValues &&
                    hrData.heartRateValues.length > 0
                ) {
                    setFilteredHrData(hrData);
                }
            }
        }
    }, [hrData, match.stats, match.matchFinished]);

    // show or hide maps based on gpsData
    function renderMaps() {
        return (
            <>
                {/* Generating distance covered graph for the match*/}
                <IonCard
                    className="match-card-container"
                    data-testid="fixture-segment-stats"
                >
                    <IonCol>
                        <IonItem className="match-details-title" lines="none">
                            <IonText>
                                {t("fixture.FixtureSummaryStats.distance")}
                            </IonText>
                        </IonItem>

                        <DistanceCoveredComponent
                            match={match}
                            playedSegments={playedSegments}
                            calculatedStats={calculatedStats}
                        />
                    </IonCol>
                </IonCard>

                {/* Generating heatmap for the match*/}
                <IonCard className="card-background" data-testid="heatmap">
                    <IonCol>
                        <IonItem lines="none">
                            <IonText className="match-details-title">
                                {t("general.heatmap")}
                            </IonText>
                        </IonItem>
                        <ProTeaser
                            showTeaser={
                                !heatMapAccess &&
                                match._id !== "example-result1"
                            }
                            teaserName="stats-heatmap"
                        >
                            <GpsMap
                                match={match}
                                mapType={GpsMapType.Heatmap}
                                useDummyData={
                                    !heatMapAccess &&
                                    match._id !== "example-result1"
                                }
                                testId="heatmap"
                            ></GpsMap>
                        </ProTeaser>
                    </IonCol>
                </IonCard>

                {/* Generating sprintmap for the match*/}
                <IonCard className="card-background">
                    <IonCol>
                        <IonItem className="teams-segments-container">
                            <IonText className="match-details-title">
                                {t("fixture.FixtureSummaryStats.sprintmap")}
                            </IonText>
                        </IonItem>

                        <ProTeaser
                            showTeaser={
                                !sprintMapAccess &&
                                match._id !== "example-result1"
                            }
                            teaserName="stats-sprint-map"
                        >
                            <GpsMap
                                match={match}
                                mapType={GpsMapType.SprintMap}
                                sprintsData={sprintsData}
                                useDummyData={
                                    !sprintMapAccess &&
                                    match._id !== "example-result1"
                                }
                                testId="sprintmap"
                            ></GpsMap>
                        </ProTeaser>
                    </IonCol>
                </IonCard>

                {/* Generating cardmap for the match*/}
                <IonCard className="card-background">
                    <IonCol>
                        <IonItem className="teams-segments-container">
                            <IonText className="match-details-title">
                                {t("fixture.FixtureSummaryStats.misconduct")}
                            </IonText>
                        </IonItem>
                        <ProTeaser
                            showTeaser={
                                !cardMapAccess &&
                                match._id !== "example-result1"
                            }
                            teaserName="stats-card-map"
                        >
                            <GpsMap
                                match={match}
                                mapType={GpsMapType.CardMap}
                                useDummyData={
                                    !cardMapAccess &&
                                    match._id !== "example-result1"
                                }
                                testId="cardmap"
                            ></GpsMap>
                        </ProTeaser>
                    </IonCol>
                </IonCard>
            </>
        );
    }

    function renderHrGraphs() {
        // If user doesn't have data but is a PRO user.
        if (!loading && !hrData) {
            return (
                <IonCard>
                    <IonItem lines="none">
                        <IonCol>
                            <IonRow>
                                <IonText className="match-details-title">
                                    {t("general.heartRate")}
                                </IonText>
                            </IonRow>
                            <IonRow>
                                <IonText>
                                    {t(
                                        "fixture.FixtureSummaryStats.errorHRCode2"
                                    )}
                                </IonText>
                            </IonRow>
                        </IonCol>
                    </IonItem>
                </IonCard>
            );
        }

        // Show HeartRate component if we have HR data
        return (
            <IonCard
                className="card-background"
                data-testid="stats-heart-rate-card"
            >
                <IonCol>
                    <IonItem lines="none">
                        <IonText className="match-details-title">
                            {t("general.heartRate")}
                        </IonText>
                    </IonItem>
                    <HeartRateComponent
                        match={match}
                        filteredHrData={filteredHrData}
                        useDummyData={
                            !heartRateAccess && match._id !== "example-result1"
                        }
                    />
                </IonCol>
            </IonCard>
        );
    }

    function renderSpeedCategory() {
        // If user doesn't have data.
        if (!loading && !gpsData) {
            return (
                <IonCard>
                    <IonItem lines="none">
                        <IonCol>
                            <IonRow>
                                <IonText className="match-details-title">
                                    {t(
                                        "fixture.FixtureSummaryStats.performanceTracking"
                                    )}
                                </IonText>
                            </IonRow>
                            <IonRow>
                                <IonText>
                                    {`${t(
                                        "fixture.FixtureSummaryStats.performanceNotAvailable"
                                    )} ${t(
                                        "fixture.FixtureSummaryStats.errorCode2"
                                    )}`}
                                </IonText>
                            </IonRow>
                        </IonCol>
                    </IonItem>
                </IonCard>
            );
        }

        // Show SpeedCategoryComponent if we have GPSData and user is PRO user.
        return (
            <IonCard className="card-background">
                <IonCol>
                    <IonItem className="teams-segments-container">
                        <IonText className="match-details-title">
                            {t("general.speed")}
                        </IonText>
                    </IonItem>
                    <SpeedCategoryComponent match={match} />
                </IonCol>
            </IonCard>
        );
    }

    function renderMisconduct() {
        return (
            <IonCard>
                <IonItem lines="none">
                    <IonText className="match-details-title">
                        {t("fixture.FixtureSummaryStats.misconduct")}
                    </IonText>
                </IonItem>

                <IonItem lines="none">
                    <IonGrid>
                        <IonCol>
                            <div className="container-flex">
                                <IonText>{t("general.yellow")}</IonText>
                                <IonText>
                                    {match.stats?.yellowCardTotal}
                                </IonText>
                            </div>

                            {/* Hide sin bin total if number is 0*/}
                            {match.stats?.sinBinsTotal !== 0 && (
                                <IonCol>
                                    <div className="container-flex">
                                        <IonText className="create-match-card-title">
                                            {t(
                                                "fixture.FixtureSummaryStats.sinBins"
                                            )}
                                        </IonText>
                                        <IonText>
                                            {match.stats?.sinBinsTotal}
                                        </IonText>
                                    </div>
                                </IonCol>
                            )}
                        </IonCol>

                        <IonCol>
                            <div className="container-flex">
                                <IonText>{t("general.red")}</IonText>
                                <IonText>{match.stats?.redCardTotal}</IonText>
                            </div>
                        </IonCol>
                    </IonGrid>
                </IonItem>
            </IonCard>
        );
    }

    return (
        <ScrollableContentWithAds>
            {!match.matchFinished ? (
                <IonCard>
                    <IonItem>{notStatsDesc(match, t)}</IonItem>
                </IonCard>
            ) : OfficialRole.fourthOfficial === match.officialRole ||
              OfficialRole.observer === match.officialRole ? (
                <IonCard>
                    <IonItem>
                        <IonCol>
                            <div className="no-gps-data-card">
                                <IonText className="match-details-title">
                                    {t(
                                        "fixture.FixtureSummaryStats.performanceTracking"
                                    )}
                                </IonText>
                                <IonText>
                                    {t("stats.stats.notDataRecorded", {
                                        official: match.officialRole,
                                    })}
                                </IonText>
                            </div>
                        </IonCol>
                    </IonItem>
                </IonCard>
            ) : (
                <>
                    {/*Generate Distance covered Bar if match has GPSData if not we show misconduct screen */}
                    {gpsData ? renderMaps() : renderMisconduct()}

                    {/* Generating Heart Rate graphs or show no HR data is available if user has access to view HR data */}
                    {renderHrGraphs()}

                    {/* Generate performance tracking bars if user has GPSData and Access to view the data */}
                    {renderSpeedCategory()}
                </>
            )}
        </ScrollableContentWithAds>
    );
};

export default Stats;
