import PouchDB from "pouchdb-browser";
import pouchMemoryPlugin from "pouchdb-adapter-memory";
import findPlugin from "pouchdb-find";
import upsertPlugin from "pouchdb-upsert";
import cordovaSqlitePlugin from "pouchdb-adapter-cordova-sqlite";
import { isAndroid, isIos } from "../platformDetection";

export class PouchDatabaseService {
    constructor() {
        PouchDB.plugin(pouchMemoryPlugin);
        PouchDB.plugin(findPlugin);
        PouchDB.plugin(upsertPlugin);
        if (isIos() || isAndroid()) {
            PouchDB.plugin(cordovaSqlitePlugin);
        }
    }

    createLocalDatabase<Type>(name: string, options: any) {
        let opts = options || {};
        if (!opts.adapter) {
            opts.adapter =
                isIos() || isAndroid() ? "cordova-sqlite" : undefined; // use indexedDB in browser (undefined)
        }

        opts.iosDatabaseLocation = opts.iosDatabaseLocation || "Library";
        opts.androidDatabaseImplementation =
            opts.androidDatabaseImplementation || 2;
        return new PouchDB<Type>(name, opts);
    }
}
