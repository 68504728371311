import _ from "lodash";
import { SinBinSystem, Timings } from "refsix-js-models";
import * as XLSX from "xlsx";
import { SpreadSheetEnum } from "./enum/SpreadSheetEnum";
import { SpreadsheetDataType } from "./types/spreadsheetDataType";
import moment from "moment-timezone";

const jsonRequiredValues = {
    1: "competition",
    2: "venue",
    3: "date",
    4: "time",
    6: "officialRole",
    7: "homeTeam",
    8: "homeTeamShort",
    10: "awayTeam",
    11: "awayTeamShort",
    13: "teamSize",
    14: "subsNo",
    16: "period1",
    17: "interval1",
    19: "extraTimeHalfLength",
    22: "misconductCodeId",
    24: "sinBinTimerLength",
};

export function generatesTimings(data: SpreadsheetDataType): Timings {
    const period1 = data[SpreadSheetEnum.Period1];
    const periodsNo = data[SpreadSheetEnum.PeriodsNo];
    const interval1 = data[SpreadSheetEnum.Interval1];
    const sinBinTimerLength = data[SpreadSheetEnum.SinBinTimerLength];
    const extraTimeHalfLength = data[SpreadSheetEnum.ExtraTimeHalfLength];

    const timings: any = {
        period1,
        sinBinTimerLength,
        extraTimeHalfLength,
    };

    if (periodsNo >= 2 && periodsNo <= 4) {
        for (let i = 2; i <= periodsNo; i++) {
            timings[`period${i}`] = period1;
        }
        for (let i = 1; i <= periodsNo - 1; i++) {
            timings[`interval${i}`] = interval1;
        }
    }

    const filteredTimings = _.omitBy(
        timings,
        (value) => value === undefined || value === null
    );
    if (sinBinTimerLength) {
        filteredTimings.sinBinTimerLength = sinBinTimerLength;
    }

    return Timings.fromJSON(filteredTimings);
}

export function convertValueToBoolean(value: string | undefined): boolean {
    return !!(value && value.trim().toLowerCase() === "yes");
}

export function formatNumberToDateOrTime(
    value: number,
    format: string
): string {
    return XLSX.SSF.format(format, value);
}

export const makeMomentFromDateTimeStrings = (
    date: string,
    time: string,
    timezone?: string
): moment.Moment => {
    if (timezone) {
        // if timezone is specified then use it to convert to UTC
        return moment.tz(`${date}T${time}`, timezone);
    }
    // default to using local time
    return moment(`${date}T${time}`);
};

export function makeMomentFromXlsxRow(row: SpreadsheetDataType): moment.Moment {
    const dateStr = formatNumberToDateOrTime(
        Number(row[SpreadSheetEnum.Date]),
        "YYYY-MM-DD"
    );

    // Convert the number to an actual time
    const timeStr = formatNumberToDateOrTime(
        Number(row[SpreadSheetEnum.Time]),
        "HH:MM"
    );

    const timezone = row[SpreadSheetEnum.Timezone] || undefined;

    return makeMomentFromDateTimeStrings(dateStr, timeStr, timezone);
}

export function validateExcelFile(data: SpreadsheetDataType[]): {
    [key: number]: string[];
} {
    const invalidMatches: { [key: number]: string[] } = {};

    data.forEach((match, index) => {
        const requiredFields: number[] = [
            SpreadSheetEnum.Competition,
            SpreadSheetEnum.Venue,
            SpreadSheetEnum.Date,
            SpreadSheetEnum.Time,
            SpreadSheetEnum.OfficialRole,
            SpreadSheetEnum.HomeTeam,
            SpreadSheetEnum.HomeTeamShort,
            SpreadSheetEnum.AwayTeam,
            SpreadSheetEnum.AwayTeamShort,
            SpreadSheetEnum.TeamSize,
            SpreadSheetEnum.SubsNo,
            SpreadSheetEnum.Period1,
            SpreadSheetEnum.MisconductCodeId,
        ];

        if (typeof match[SpreadSheetEnum.Date] !== "number") {
            requiredFields.push(SpreadSheetEnum.Date);
        }

        if (typeof match[SpreadSheetEnum.Time] !== "number") {
            requiredFields.push(SpreadSheetEnum.Time);
        }

        if (!makeMomentFromXlsxRow(match).isValid()) {
            requiredFields.push(SpreadSheetEnum.Date);
            requiredFields.push(SpreadSheetEnum.Time);
        }

        if (
            match[SpreadSheetEnum.PeriodsNo] &&
            Number(match[SpreadSheetEnum.PeriodsNo]) >= 2
        ) {
            requiredFields.push(SpreadSheetEnum.Interval1);
        }

        if (
            match[SpreadSheetEnum.ExtraTimeAvailable] &&
            convertValueToBoolean(match[SpreadSheetEnum.ExtraTimeAvailable])
        ) {
            requiredFields.push(SpreadSheetEnum.ExtraTimeHalfLength);
        }

        if (
            match[SpreadSheetEnum.SinBinSystem] &&
            match[SpreadSheetEnum.SinBinSystem] !== "None" &&
            match[SpreadSheetEnum.SinBinSystem] !== ""
        ) {
            requiredFields.push(SpreadSheetEnum.SinBinTimerLength);
        }

        const missingFields: string[] = [];
        requiredFields.forEach((field) => {
            const fieldValue = match[field];

            if (!fieldValue || String(fieldValue).trim() === "") {
                missingFields.push(jsonRequiredValues[field]);
            }
        });

        if (missingFields.length > 0) {
            // Add missing fields to the object
            invalidMatches[index] = missingFields;
        }
    });

    return invalidMatches;
}

export function convertToActualSinBinSystem(
    sinBin: string | undefined
): SinBinSystem {
    switch (sinBin) {
        case "None":
            return SinBinSystem.none;
        case "System A":
            return SinBinSystem.systemA;
        case "System B":
            return SinBinSystem.systemB;
        case "System B 2024+":
            return SinBinSystem.systemB2024;
        default:
            return SinBinSystem.none;
    }
}
