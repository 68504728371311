import React, { FC } from "react";
import { MatchPhone } from "refsix-js-models";
import { IonCard, IonGrid, IonRow } from "@ionic/react";
import {
    MatchBottomCardComponent,
    MatchMiddleCardComponent,
    MatchUpperCardComponent,
} from "../../components";

export const MatchHeader: FC<{ match: MatchPhone }> = ({ match }) => {
    return (
        <IonCard color={"medium"} className="fixture-card match-header">
            <IonGrid>
                <IonRow>
                    <MatchUpperCardComponent match={match} />

                    {match.matchOfficials !== undefined &&
                        match.matchOfficials.assistant1 !== "" && (
                            <MatchMiddleCardComponent match={match} />
                        )}

                    <MatchBottomCardComponent match={match} />
                </IonRow>
            </IonGrid>
        </IonCard>
    );
};
