import { FC, useEffect } from "react";
import { IonContent, IonPage, IonText } from "@ionic/react";
import { HeaderComponent } from "../../../components";
import { routes } from "../../../route/constants";
import { useTranslation } from "react-i18next";
import { MessageComponent } from "../../../components/messageComponent";
import { getAndStoreRefAssistNotifications } from "../../../services/integrations/refassist/refAssistService";
import { Message } from "refsix-js-models";
import { useSelector } from "react-redux";

export const Notifications: FC = () => {
    const { t } = useTranslation();

    const messages = useSelector((state: any) => state.messages.messages);

    useEffect(() => {
        getAndStoreRefAssistNotifications().catch((error) => {
            console.error(error);
        });
    }, []);

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMore}
                title={t("notifications.notifications")}
                titleTestId="integrations-page-header"
            />
            <IonContent>
                {messages.length === 0 && (
                    <IonText>{t("messages.noMessages")}</IonText>
                )}

                {messages.map((message: Message, i: number) => (
                    <MessageComponent key={i} {...message} />
                ))}
            </IonContent>
        </IonPage>
    );
};
