import { MatchEvent } from "../matchEvent";
import { EventName } from "../enums/eventName";
import { Player } from "../player";
import { Team } from "../team";

export class PenaltyShotEvent extends MatchEvent {
    team: Team;
    player: Player;
    goal: boolean;

    constructor(
        timestamp: number,
        eventName: EventName,
        half: number,
        minuteOfPlay: number,
        team: Team,
        player: Player,
        goal: boolean
    ) {
        super(timestamp, eventName, half, minuteOfPlay);
        this.team = team;
        this.player = player;
        this.goal = goal;
    }

    static fromJSON(json: any) {
        return new PenaltyShotEvent(
            json.timestamp,
            json.eventName,
            json.half,
            json.minuteOfPlay,
            json.team,
            json.player,
            json.goal
        );
    }
}
