import { SetTemplatesAction } from "../actions/templates";
import { SET_TEMPLATES } from "../actions/types";
import { Templates } from "../models/refsixState";

const initialState = new Templates();

function templatesReducer(
    state: Templates = initialState,
    action: SetTemplatesAction
) {
    switch (action.type) {
        case SET_TEMPLATES:
            return { ...state, templates: action.templates };
        default:
            return state;
    }
}

export default templatesReducer;
