import { MatchEvent } from "../matchEvent";
import { EventName } from "../enums/eventName";

export class AbandonEvent extends MatchEvent {
    name: string;

    constructor(
        timestamp: number,
        eventName: EventName,
        half: number,
        minuteOfPlay: number,
        name: string
    ) {
        super(timestamp, eventName, half, minuteOfPlay);
        this.name = name;
    }

    static fromJSON(json: any) {
        return new AbandonEvent(
            json.timestamp,
            json.eventName,
            json.half,
            json.minuteOfPlay,
            json.name
        );
    }
}
