import { AppStatus } from "../models/refsixState";
import {
    SET_INSTALLED_APP_VERSION,
    SET_LOGGEDIN_OR_REGISTER_COMPLETE,
    SET_PUSH_CONSENT_LAST_SHOWN,
    SET_SHOW_WHATS_NEW,
    SHOW_ONBOARDING_FLOW,
    SET_SESSION_EXPIRED_MODAL_DISMISSED,
    SET_DB_UPDATED_AT,
} from "../actions/types";

const appStatusReducer = (state: AppStatus = new AppStatus(), action: any) => {
    switch (action.type) {
        case SET_INSTALLED_APP_VERSION:
            return { ...state, installedVersion: action.installedVersion };
        case SET_LOGGEDIN_OR_REGISTER_COMPLETE:
            return {
                ...state,
                loginOrRegisterComplete: action.value,
            };
        case SET_PUSH_CONSENT_LAST_SHOWN:
            return {
                ...state,
                pushConsentPromptLastShown: action.pushConsentPromptLastShown,
            };
        case SHOW_ONBOARDING_FLOW:
            return {
                ...state,
                showOnBoardingFlow: action.value,
            };
        case SET_SHOW_WHATS_NEW:
            return {
                ...state,
                showWhatsNew: action.value,
            };
        case SET_SESSION_EXPIRED_MODAL_DISMISSED:
            return {
                ...state,
                sessionExpiredModalDismissed: action.value,
            };
        case SET_DB_UPDATED_AT:
            return {
                ...state,
                dbUpdatedAt: action.value,
            };
        default:
            return state;
    }
};

export default appStatusReducer;
