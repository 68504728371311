import { IonIcon, IonItem } from "@ionic/react";
import { closeCircle, swapVerticalSharp } from "ionicons/icons";
import { FC, useCallback, useEffect, useState } from "react";
import { useFormContext, Validate } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    TeamSheetFormValues,
    TeamSheetPlayerType,
} from "../pages/teamsheets/newTeamSheet";
import { Player } from "refsix-js-models";
import { UseList } from "./hooks/autocomplete/types";
import { TeamPlayerListItem } from "./hooks/autocomplete/useTeamPlayerList";

interface PlayerItemComponentProps {
    idx: number;
    playerFieldType: TeamSheetPlayerType;
    disablePlayersInList: boolean;
    validate: Validate<any, TeamSheetFormValues>;
    isDeleteDisabled: boolean;
    showRemovePlayerAlert: () => void;
    isMovePlayerButtonVisible: boolean;
    onMovePlayerButtonHandler: () => void;
    teamPlayerList: UseList<TeamPlayerListItem[]>;
    isMoveTarget: boolean;
}

export const PlayerItemComponent: FC<PlayerItemComponentProps> = ({
    playerFieldType,
    idx,
    disablePlayersInList,
    validate,
    isDeleteDisabled,
    showRemovePlayerAlert,
    isMovePlayerButtonVisible,
    onMovePlayerButtonHandler,
    teamPlayerList,
    isMoveTarget,
}) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext<TeamSheetFormValues>();

    const playerWatch = watch(`${playerFieldType}.${idx}`);
    const [showOverride, setShowOverride] = useState(true);
    const [currentPlayerFocused, setCurrentPlayerFocused] = useState<any>(null);
    const [showAutoComplete, setShowAutoComplete] = useState(false);

    useEffect(() => {
        if (currentPlayerFocused && currentPlayerFocused === playerWatch) {
            setShowAutoComplete(true);
        } else {
            setShowAutoComplete(false);
        }
    }, [currentPlayerFocused, playerWatch]);

    const save = useCallback(
        (value: string) => {
            setValue(`${playerFieldType}.${idx}.name`, value);
            setShowOverride(false);
        },
        [setValue, playerFieldType, idx]
    );

    return (
        <div key={idx}>
            <IonItem
                lines="full"
                key={idx}
                disabled={disablePlayersInList}
                onClick={(event) => {
                    if (isMoveTarget) {
                        onMovePlayerButtonHandler();
                        event.currentTarget.focus();
                    }
                }}
            >
                <div className="input-container">
                    <input
                        className={
                            errors?.[playerFieldType]?.[idx]?.number
                                ? "danger borderless-input transparent-input input-number"
                                : "borderless-input transparent-input input-number"
                        }
                        {...register(`${playerFieldType}.${idx}.number`, {
                            validate: {
                                isNumberUnique: validate,
                            },
                            valueAsNumber: true,
                            max: 99,
                            required: true,
                            maxLength: 99,
                        })}
                        inputMode="numeric"
                        type="number"
                        placeholder="--"
                        readOnly={isMoveTarget}
                    />
                    <input
                        className={
                            errors?.[playerFieldType]?.[idx]?.name
                                ? "danger borderless-input transparent-input input-name"
                                : "borderless-input transparent-input input-name"
                        }
                        {...register(`${playerFieldType}.${idx}.name`, {
                            //required: true,
                        })}
                        placeholder={`${t("fixture.FixtureTeam.player")} ${
                            playerWatch.number || ""
                        }`}
                        onFocus={() => setCurrentPlayerFocused(playerWatch)}
                        readOnly={isMoveTarget}
                    />

                    <div className="player-item-row-reorder-delete-container">
                        <div
                            className={
                                isDeleteDisabled
                                    ? "delete-button-wrapper disabled"
                                    : "delete-button-wrapper"
                            }
                            style={
                                isDeleteDisabled ? { visibility: "hidden" } : {}
                            }
                            color="transparent"
                            onClick={
                                isDeleteDisabled
                                    ? () => {}
                                    : showRemovePlayerAlert
                            }
                            data-testid="removePlayerButton"
                        >
                            <IonIcon
                                icon={closeCircle}
                                style={{ color: "white" }}
                            />
                        </div>
                        <div
                            className="delete-button-wrapper"
                            color="transparent"
                            hidden={!isMovePlayerButtonVisible}
                            onClick={(event) => {
                                if (document.activeElement) {
                                    const input =
                                        (document.activeElement as HTMLInputElement) ||
                                        HTMLTextAreaElement;
                                    input.blur();
                                }
                                onMovePlayerButtonHandler();
                                event.currentTarget.focus();
                            }}
                            data-testid="movePlayerButton"
                        >
                            <IonIcon
                                icon={swapVerticalSharp}
                                style={{ color: "white" }}
                            />
                        </div>
                    </div>
                </div>
            </IonItem>
            <div
                style={{
                    whiteSpace: "nowrap",
                    overflowX: "scroll",
                }}
            >
                {showAutoComplete &&
                    showOverride &&
                    teamPlayerList.renderer &&
                    teamPlayerList.search &&
                    teamPlayerList.renderer(
                        teamPlayerList.search(playerWatch.name),
                        save
                    )}
            </div>
        </div>
    );
};
