import {
    SpeedCategory,
    SprintCategoryConstants,
} from "../fitness/SprintCategoryConstants";

export function SprintCategoryFilter(): Function {
    return function (speed?: number): SpeedCategory | undefined {
        if (typeof speed !== "number" || isNaN(speed)) {
            return;
        }

        const _isBetween = function (key: string, speed: number) {
            return (
                speed >= SprintCategoryConstants[key].lowerBound &&
                speed < SprintCategoryConstants[key].upperBound
            );
        };

        if (_isBetween("low", speed)) {
            return SprintCategoryConstants.low;
        } else if (_isBetween("medium", speed)) {
            return SprintCategoryConstants.medium;
        } else if (_isBetween("high", speed)) {
            return SprintCategoryConstants.high;
        }
    };
}
