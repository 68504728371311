import { prepareBaseResponse } from "./prepareBaseResponse";
import { MessageTypes } from "../messageTypesEnum";
import {ConfigPhone, MatchData, MisconductCodes, SinBinSystem, Template, Templates} from "refsix-js-models";
import * as Sentry from "@sentry/react";
import { store } from "../../../redux/store";
import { getDefaultTemplates } from "../../templateService";
import { WatchMessage } from "../watchInterface";
import {isWatchSystemB2024Compatible} from "../watchHelpers";
import {cloneDeep} from "lodash";

interface ConfigurationResponse extends WatchMessage {
    configuration: {
        templateDoc: Templates;
        settings: ConfigPhone;
        customCodes: MisconductCodes;
    };
}
const transformTemplateSystemB2024 = (template: Template) => {
    if(template.config && template.config.sinBinSystem === SinBinSystem.systemB2024){
        const newTemplate = cloneDeep(template);
        newTemplate.config.sinBinSystem = SinBinSystem.none;
        return newTemplate;
    }
    return template;
}

export async function getConfiguration(
    data: any,
    token: any
): Promise<ConfigurationResponse | undefined> {
    const message = {
        requestId: data.id,
    };
    const state = store.getState();
    const templates = state.templates.templates || getDefaultTemplates();
    const settings = state.settings.settings || ConfigPhone.defaultConfig();
    if(!isWatchSystemB2024Compatible(data.version)){
        console.log("Watch isn't compatible with system B2024, converting templates");
        templates.templates = templates.templates.map(transformTemplateSystemB2024)
    }

    try {
        const response: any = await prepareBaseResponse(
            message,
            MessageTypes.CONFIGURATION_RESPONSE,
            data,
            token
        );
        return {
            ...response,
            configuration: {
                templateDoc: {
                    templates: templates.templates,
                    defaultTemplate: templates.defaultTemplate,
                },
                settings: settings,
                customCodes: settings.customCodes[0],
            },
        };
    } catch (error: any) {
        Sentry.captureMessage(
            `[WatchService._handleMatchList()] Problem building match list 
                ${error.message}`
        );
        return undefined;
    }
}
