import {
    EventName,
    GoalEvent,
    IncidentEvent,
    MatchEvent,
    MatchPhone,
    PenaltyShotEvent,
    Player,
    SelectedTeam,
    SubstitutionEvent,
} from "refsix-js-models";
import i18n from "react-i18next";
import { filter } from "lodash";
import { teamMatchEventsFilters } from "./filters/teamMatchEventsFilters";

export function sortPlayersByNumber(players: Player[]): Player[] {
    return players.sort((a, b) => a.number - b.number);
}

export function getAllPlayerForATeam(
    match: MatchPhone,
    side: SelectedTeam
): Player[] {
    let players: Player[];
    if (side === "home") {
        players = match.players ? match.players[match.homeTeam] : [];
    } else {
        players = match.players ? match.players[match.awayTeam] : [];
    }

    return players;
}

// TODO: Use lodash to optimize the function and rename to filterStartingPlayers
// example: _.filter(players, {player: { starting: true, teamOfficial: false}})
export function getAllStartingPlayers(players: Player[]): Player[] {
    return sortPlayersByNumber(
        filter(players, (player) => player.starting && !player.teamOfficial)
    );
}

export function getAllSubs(players: Player[]): Player[] {
    return sortPlayersByNumber(
        filter(players, (player) => !player.starting && !player.teamOfficial)
    );
}

export function getAllTeamOfficials(players: Player[]): Player[] {
    return sortPlayersByNumber(filter(players, { teamOfficial: true }));
}

export default function getPlayerName(player: Player, t: i18n.TFunction) {
    return player.name
        ? player.name
        : t("fixture.FixtureTeam.player") + " " + player.number;
}

export function getMatchEventIcon(event: MatchEvent) {
    switch (event.eventName) {
        case EventName.goal:
            let goalEvent = event as GoalEvent;

            if (goalEvent.ownGoal) {
                return "ownGoal";
            }

            return "goal";
        case EventName.incident:
            let incidentEvent = event as IncidentEvent;
            if (incidentEvent.card === "yellow") {
                return "yellow";
            } else if (incidentEvent.card === "red") {
                return "red";
            }

            return "";
        case EventName.substitution:
            return "sub";
        case EventName.penaltyShot:
            let penaltyShotEvent = event as PenaltyShotEvent;
            if (penaltyShotEvent.goal) {
                return "penaltyShotScored";
            }

            return "penaltyShotMissed";
    }
}

export interface PlayerEventsByTeam {
    [SelectedTeam.home]: PlayerWithEvents[];
    [SelectedTeam.away]: PlayerWithEvents[];
}

export interface PlayerWithEvents {
    player: Player;
    events: MatchEvent[];
}

export function generatePlayersEventsForTeam(
    events: MatchEvent[],
    teamPlayers: Player[]
): PlayerWithEvents[] {
    return teamPlayers.map((player) => {
        const eventsForPlayer = events
            .filter((event: MatchEvent) => {
                if (event.eventName === EventName.substitution) {
                    const subEvent = event as SubstitutionEvent;
                    return (
                        subEvent.playerOn.number === player.number ||
                        subEvent.playerOff.number === player.number
                    );
                } else {
                    return (
                        // @ts-ignore
                        event.player && event.player.number === player.number
                    );
                }
            })
            .sort((a: MatchEvent, b: MatchEvent) => {
                return a.timestamp - b.timestamp;
            });

        return {
            player,
            events: eventsForPlayer,
        };
    });
}

export function generatePlayersEventsStructure(
    match: MatchPhone
): PlayerEventsByTeam {
    const output: PlayerEventsByTeam = {
        home: [],
        away: [],
    };

    if (!match.players) {
        return output;
    }

    const matchEvents = match.matchEvents || {};

    const events = Object.values(matchEvents);

    // Home team
    if (
        Array.isArray(match.players[match.homeTeam]) &&
        match.players[match.homeTeam].length > 0
    ) {
        output.home = generatePlayersEventsForTeam(
            teamMatchEventsFilters(events, SelectedTeam.home),
            match.players[match.homeTeam]
        );
    }

    // Away team
    if (
        Array.isArray(match.players[match.awayTeam]) &&
        match.players[match.awayTeam].length > 0
    ) {
        output.away = generatePlayersEventsForTeam(
            teamMatchEventsFilters(events, SelectedTeam.away),
            match.players[match.awayTeam]
        );
    }
    return output;
}

export function addEventToPlayer(player: any, event: any, timestamp: any) {
    if (player) {
        player.events = player.events || {};
        return (player.events[timestamp] = event);
    }
}

export function findPlayerByNumber(playerNumber: number) {
    return function (el: any) {
        return el.number === playerNumber;
    };
}

export function isStarting(players: Player[] | number[], teamSize: number) {
    return players.length < teamSize;
}
