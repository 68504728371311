// a react component that shows an ionic toast if for the first message in the redux errorMessages message arrau

import { IonToast } from "@ionic/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RefsixState } from "../redux/models/refsixState";
import { useTranslation } from "react-i18next";
import { popErrorMessage } from "../redux/actions/errorMessagesActions";

export default function ErrorToaster() {
    const { t } = useTranslation();
    const errorMessages = useSelector(
        (state: RefsixState) => state.errorMessages.messages
    );
    const dispatch = useDispatch();

    // react state hook for the translated error message to be displayed
    // const [translatedErrorMessage, setTranslatedErrorMessage] = useState("");

    useEffect(() => {
        if (errorMessages.length > 0) {
            const message = errorMessages[0];
            console.log("ErrorToaster: " + message);
        }
    }, [errorMessages]);

    if (errorMessages.length === 0) return <></>;

    return (
        <IonToast
            isOpen={errorMessages.length > 0}
            message={t(errorMessages[0].messageKey)}
            position="bottom"
            buttons={[
                {
                    text: t("help.OnboardingVideo.controls.ok"),
                    role: "cancel",
                    handler: () => {
                        dispatch(popErrorMessage());
                    },
                },
            ]}
            color={errorMessages[0].color || "warning"}
        />
    );
}
