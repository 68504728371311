import { MatchEvent } from "../matchEvent";
import { EventName } from "../enums/eventName";
import { Player } from "../player";
import { Team } from "../team";

export class SubstitutionEvent extends MatchEvent {
    team: Team;
    playerOff: Player;
    playerOn: Player;

    constructor(
        timestamp: number,
        eventName: EventName,
        half: number,
        minuteOfPlay: number,
        team: Team,
        playerOff: Player,
        playerOn: Player
    ) {
        super(timestamp, eventName, half, minuteOfPlay);
        this.team = team;
        this.playerOff = playerOff;
        this.playerOn = playerOn;
    }

    static fromJSON(json: any) {
        return new SubstitutionEvent(
            json.timestamp,
            json.eventName,
            json.half,
            json.minuteOfPlay,
            json.team,
            json.playerOff,
            json.playerOn
        );
    }
}
