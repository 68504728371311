import { SubscriptionDetails, SubscriptionItems } from "refsix-js-models";

export class SubscriptionState extends SubscriptionDetails {
    // TODO add basic?
    isPro: boolean;
    isPlus: boolean;
    updatedAt?: number; // unix epoch in millis
    refreshTs?: number;
    expiryTs?: number;

    constructor(
        subscriptions: SubscriptionItems[],
        features: string[],
        signUpTimestamp: number | undefined,
        beenPro: boolean = false,
        beenBasic: boolean = false,
        updatedAt: number | undefined,
        refreshTs: number | undefined,
        expiryTs: number | undefined,
        isPro: boolean = false,
        isPlus: boolean = false
    ) {
        super(subscriptions, features, signUpTimestamp, beenPro, beenBasic);
        this.updatedAt = updatedAt;
        this.isPro = isPro;
        this.isPlus = isPlus;
        this.refreshTs = refreshTs;
        this.expiryTs = expiryTs;
    }
}
