import {
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonModal,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";
import { FC, useState } from "react";
import { MatchPhone } from "refsix-js-models";
import { FitnessDataProvider } from "../components/hooks";
import { GpsMap, GpsMapType } from "../components/GpsMapComponent";
import { useTranslation } from "react-i18next";
import { useMatches } from "../components/hooks/match";
import { matchesSortedByDate } from "../services/matchService";
import pitchImage from "../assets/images/pitch.png";
import { HeaderComponent } from "../components";

interface CompareHeatmapsModalProps {
    showModal: boolean;
    match: MatchPhone;
    onCloseModal: () => void;
}

export const CompareHeatmapsModal: FC<CompareHeatmapsModalProps> = ({
    showModal,
    match,
    onCloseModal,
}) => {
    const selectSheetOptions = {
        cssClass: "positioning-select-sheet",
    };
    const { t } = useTranslation();
    const matches = useMatches();
    const [secondMatch, setSecondMatch] = useState<MatchPhone>();
    const sortedMatches = matchesSortedByDate(matches || [])
        .reverse()
        .filter((match) => match.hasTracking);

    return (
        <IonModal
            isOpen={showModal}
            onDidDismiss={() => {
                onCloseModal();
                setSecondMatch(undefined);
            }}
            showBackdrop
        >
            <HeaderComponent
                showLeftSideBtn
                onLeftBtnClicked={onCloseModal}
                title={t("fixture.FixtureSummaryStats.compareHeatMaps")}
                leftSideBtnTxt={t("general.cancel")}
            />
            <IonContent>
                <IonCard>
                    <IonItem lines="none" color="transparent">
                        <IonLabel>
                            {`${match.homeTeam} v ${match.awayTeam}`}
                        </IonLabel>
                    </IonItem>
                    <FitnessDataProvider matchId={match._id as string}>
                        <GpsMap
                            match={match}
                            mapType={GpsMapType.Compare}
                            testId="heatmap1"
                        />
                    </FitnessDataProvider>
                </IonCard>
                <IonCard>
                    <IonItem lines="none">
                        <IonSelect
                            className="select-text"
                            placeholder={t(
                                "stats.stats.Positioning.selectAFixture"
                            )}
                            onIonChange={(e) => {
                                if (matches) {
                                    const match = matches.find(
                                        (match) => match._id === e.detail.value
                                    ) as MatchPhone;
                                    setSecondMatch(match);
                                }
                            }}
                            interface="action-sheet"
                            interfaceOptions={selectSheetOptions}
                            data-testid="comparison-heatmap-2"
                        >
                            {sortedMatches.map((match, i) => (
                                <IonSelectOption key={i} value={match._id}>
                                    {`${match.homeTeam} v ${match.awayTeam}`}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>
                    {secondMatch ? (
                        <FitnessDataProvider
                            matchId={secondMatch._id as string}
                        >
                            <GpsMap
                                match={secondMatch}
                                mapType={GpsMapType.Compare}
                                testId="heatmap2"
                            />
                        </FitnessDataProvider>
                    ) : (
                        <div className="responsive svg-pitch">
                            <img src={pitchImage} alt="Pitch image" />
                        </div>
                    )}
                </IonCard>
            </IonContent>
        </IonModal>
    );
};
