import { FC } from "react";
import { useTranslation } from "react-i18next";
import { MatchHeader } from "../../pages/addResult/matchHeader";
import { useMatch } from "../hooks/match";
import { IonSpinner } from "@ionic/react";
import { AggregatedStats } from "refsix-js-models";
import "./reviewSharedStyles.css";
import "./highestScoringMatch.css";
import { hasFeatureAccess } from "../../services/subscriptionService";
import ball from "../../assets/images/review/ball.png";
import Stats from "../../pages/tabs/fixturesSegments/stats";
import Stat, {
    Col,
    Content,
    Header,
    Icon,
    Info,
    MainHeader,
    MatchResult,
    Row,
    StatsContainer,
} from "./reviewCard";
import Distance, { DistanceUnit } from "../DistanceComponent";

interface HighestScoringMatchReviewProps {
    matchId?: string;
    aggregatedStats: AggregatedStats;
}

const HighestScoringMatchReview: FC<HighestScoringMatchReviewProps> = ({
    matchId,
    aggregatedStats,
}) => {
    const { t } = useTranslation();
    const match = useMatch(matchId);

    if (!match) {
        return <IonSpinner name="circular"></IonSpinner>;
    }
    console.table(match);
    return (
        <div className="highest-scoring-review review">
            {/* Match Summary*/}
            <StatsContainer>
                <Stat className={"highest-scoring"}>
                    <Row>
                        <MainHeader>
                            <div>{t("review.highestScoringGame")}</div>
                        </MainHeader>
                        <Content>
                            <MatchResult match={match} />
                        </Content>
                    </Row>
                </Stat>
                <Info>
                    <Col>
                        {t("stats.stats.HeartRate.averageAcrossMatches")}

                        <div>
                            <span style={{ fontSize: 40, fontWeight: 900 }}>
                                {aggregatedStats.goalsAverage.toFixed(2)}
                                &nbsp;
                            </span>

                            <span
                                style={{
                                    fontSize: 20,
                                    textTransform: "lowercase",
                                }}
                            >
                                {t("stats.stats.goalsScored")}
                            </span>
                        </div>
                    </Col>
                    <Col>
                        <img className="ball" src={ball} />
                    </Col>
                </Info>
            </StatsContainer>
        </div>
    );
};

export default HighestScoringMatchReview;
