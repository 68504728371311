import { FC, useEffect, useState } from "react";
import { useIonModal } from "@ionic/react";
import { trackEvent } from "../services/analytics/analyticsService";
import { now } from "moment";
import { NewFeatureContent } from "./NewFeatureContent";
import { useHasNewVersion } from "./hooks/appVersionHooks";
import { useLoginOrRegisterComplete, useShouldShowWhatsNew } from "./hooks";

const AppUpdateComponent: FC<{}> = ({ children }) => {
    const [openedAt, setOpenedAt] = useState<number>(0);

    const { hasNewVersion, previousAppVersion, currentAppVersion } =
        useHasNewVersion();
    const canShowChangeLog = useLoginOrRegisterComplete();
    const shouldShowWhatsNew = useShouldShowWhatsNew();

    const trackAndClose = () => {
        trackEvent("WhatsNewViewed", {
            previousVersion: previousAppVersion,
            newVersion: currentAppVersion,
            durationViewed: (now() - openedAt) / 1000,
        });
        if (dismiss) dismiss();
    };

    const [show, dismiss] = useIonModal(NewFeatureContent(trackAndClose), {
        showBackdrop: true,
    });

    useEffect(() => {
        if (hasNewVersion && canShowChangeLog && shouldShowWhatsNew) {
            setOpenedAt(now());
            show({
                onWillDismiss: trackAndClose,
            });
        }
    }, [canShowChangeLog, hasNewVersion]);

    return null;
};

export default AppUpdateComponent;
