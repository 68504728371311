import { Player, TeamPackData, TeamPackTeamPlayers } from "refsix-js-models";

export const generatePlayersForTeamFromTeamPack = (
    teamPacks: TeamPackData[],
    teamName: string,
    hide13: boolean = false
): Player[] => {
    const players: TeamPackTeamPlayers[] = teamPacks.reduce(
        (teamPackPlayers: TeamPackTeamPlayers[], teamPack) =>
            teamPackPlayers.concat(teamPack.players),
        []
    );
    const team: TeamPackTeamPlayers | undefined = players.find(
        (player) => player.name === teamName
    );

    if (team) {
        return createPlayers(
            team.players.length,
            true,
            false,
            0,
            hide13,
            team.players
        );
    }

    return [];
};

export function createPlayers(
    teamSize: number,
    isStarting: boolean,
    isTeamOfficial: boolean,
    startingOffset: number = 0,
    hide13: boolean = false,
    stringPlayersArray?: string[]
): Player[] {
    const players: Player[] = [];

    for (let i = 1; i <= teamSize; i++) {
        const number = isStarting ? i : i + startingOffset;
        const name = stringPlayersArray ? stringPlayersArray[i - 1] : "";
        players.push(
            new Player(
                name,
                number + (number >= 13 && hide13 ? 1 : 0),
                false,
                isStarting,
                isTeamOfficial
            )
        );
    }
    return players;
}
