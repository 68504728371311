export enum RefAssistAlertType {
    UserAlert = "UserAlert",
    AdministratorAlert = "AdministratorAlert",
    InconsistentRefereeAssignment = "InconsistentRefereeAssignment",
    InconsistentObserverAssignment = "InconsistentObserverAssignment",
    BackupNotification = "BackupNotification",
    AutoCancellation = "AutoCancellation",
    IncreasedTransportDistance = "IncreasedTransportDistance",
    CoachingAdviceNotification = "CoachingAdviceNotification",
    InconsistentInstructorAssignment = "InconsistentInstructorAssignment",
    AssignmentRejected = "AssignmentRejected",
    AssignmentAccepted = "AssignmentAccepted",
    InconsistentCoachAssignment = "InconsistentCoachAssignment",
    AppointmentPublishedNotification = "AppointmentPublishedNotification",
    AppointmentCancellationNotification = "AppointmentCancellationNotification",
    CoachAssignmentPublishedNotification = "CoachAssignmentPublishedNotification",
    CoachAssignmentCancelledNotification = "CoachAssignmentCancelledNotification",
    ObservationPublishedNotification = "ObservationPublishedNotification",
    ObservationCancellationNotification = "ObservationCancellationNotification",
    RefereeChangedForObservation = "RefereeChangedForObservation",
    RefereeRemovedForObservation = "RefereeRemovedForObservation",
    ObserverReportRejection = "ObserverReportRejection",
    DocumentAvailableForRefereeNotification = "DocumentAvailableForRefereeNotification",
    MatchStartDateChanged = "MatchStartDateChanged",
    MatchVenueChanged = "MatchVenueChanged",
    MatchTeamsChanged = "MatchTeamsChanged",
    NewUserCreated = "NewUserCreated",
    UserRolesAuthorisationsUpdated = "UserRolesAuthorisationsUpdated",
    NewCourseSubmit = "NewCourseSubmit",
    CourseStatusChanged = "CourseStatusChanged",
    InstructorAppointmentPublishedNotification = "InstructorAppointmentPublishedNotification",
    InstructorAppointmentCancelledNotification = "InstructorAppointmentCancelledNotification",
    CourseDateChanged = "CourseDateChanged",
    CourseCancellationRequest = "CourseCancellationRequest",
    LeaveRequestRejected = "LeaveRequestRejected",
    NewLeavesToBeApproved = "NewLeavesToBeApproved",
    CourseInformationReminder = "CourseInformationReminder",
    CheckListOverDueReminder = "CheckListOverDueReminder",
    NewCourseAttachment = "NewCourseAttachment",
    ExpenseRejected = "ExpenseRejected",
    NoteOfExpenses = "NoteOfExpenses",
    NewFileAvailable = "NewFileAvailable",
    FormComponentValueNotification = "FormComponentValueNotification",
    NewCourseInvitation = "NewCourseInvitation",
    NewCourseAssignment = "NewCourseAssignment",
    CourseAssignmentCancelled = "CourseAssignmentCancelled",
    CourseCancelled = "CourseCancelled",
    CourseInfoChanged = "CourseInfoChanged",
    ReminderForAwardVoting = "ReminderForAwardVoting",
    ReminderForMatchReport = "ReminderForMatchReport",
}
