import React, { FC } from "react";
import { IonHeader, IonToolbar } from "@ionic/react";
import RefSixImage from "../assets/images/refsix-words-h80px.png";

interface HeaderImageProps {
    image?: string;
    width?: string;
    height?: string;
    children?: React.ReactNode;
}

const HeaderImageComponent: FC<HeaderImageProps> = ({
    image = RefSixImage,
    width = "30%",
    height = "50%",
    children,
}) => {
    return (
        <IonHeader data-testid="login-page-header">
            <IonToolbar mode="ios">
                <div className="header-image">
                    <img alt="" src={image} width={width} height={height} />
                </div>
                {children}
            </IonToolbar>
        </IonHeader>
    );
};
export default HeaderImageComponent;
