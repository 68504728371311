import React, { useCallback, useEffect } from "react";
import {
    deletePack,
    deletePacks,
    getAvailablePacks,
    getLocalPacks,
    onStart,
    subscribeToPack,
    TeamPackInfo,
} from "../../services/TeamPackService";
import { useProfile, useSettings } from "./index";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { TeamPackData } from "refsix-js-models";
import { getNationalFA } from "../../constants/nationalFA";

export interface TeamPacksContextType {
    downloadedPacks: TeamPackData[];
    availablePacks: TeamPackInfo[];
    subscribeToPack?: (pack: TeamPackInfo) => void;
    deletePacks?: () => void;
    deletePack?: (pack: string) => void;
    ready?: boolean;
}

const defaultTeamPacksContext: TeamPacksContextType = {
    downloadedPacks: [],
    availablePacks: [],
};
export const TeamPacksContext = React.createContext<TeamPacksContextType>(
    defaultTeamPacksContext
);

export const TeamPacksProvider: React.FC = ({ children }) => {
    const userProfile = useProfile();
    const settings = useSelector((root: RootState) => root.settings);
    const userSettings = useSettings();
    const country: string | "" =
        userProfile && userProfile.country
            ? userProfile.country.toLowerCase()
            : "";
    const nationalFA: string | "" =
        userProfile && userProfile.nationalFA
            ? userProfile.nationalFA.toLowerCase()
            : "";
    const refereeClubName: string | "" =
        userProfile && userProfile.refereeClub ? userProfile.refereeClub : "";

    const [downloaded, setDownloaded] = React.useState<TeamPackData[]>([]);
    const [available, setAvailable] = React.useState<any[]>([]);
    const [ready, setReady] = React.useState<boolean>(false);

    const subscribe = useCallback((pack: TeamPackInfo) => {
        const fn = async () => {
            await subscribeToPack(pack.name);
            setDownloaded(await getLocalPacks());
            setAvailable(await getAvailablePacks());
        };

        fn();
    }, []);
    const clearPacks = useCallback(() => {
        const fn = async () => {
            await deletePacks();
            setDownloaded(await getLocalPacks());
            setAvailable(await getAvailablePacks());
        };
        fn();
    }, []);
    const clearPack = useCallback((pack: string) => {
        const fn = async () => {
            await deletePack(pack);
            setDownloaded(await getLocalPacks());
            setAvailable(await getAvailablePacks());
        };
        fn();
    }, []);

    const nationalFAs = getNationalFA()[country];

    useEffect(() => {
        const init = async () => {
            await onStart(country, nationalFA, refereeClubName);
            setDownloaded(await getLocalPacks());
            setAvailable(await getAvailablePacks());
            setReady(true);
        };
        if (country !== "" && (nationalFA !== "" || !nationalFAs)) {
            setReady(false);
            init();
        }
    }, [nationalFAs, country, nationalFA, refereeClubName]);

    return (
        <TeamPacksContext.Provider
            value={{
                downloadedPacks: downloaded,
                availablePacks: available,
                subscribeToPack: subscribe,
                deletePacks: clearPacks,
                deletePack: clearPack,
                ready: ready,
            }}
        >
            {children}
        </TeamPacksContext.Provider>
    );
};
export const useTeamPacksContext = () => {
    return React.useContext(TeamPacksContext);
};
