import {
    IonButton,
    IonCard,
    IonContent,
    IonFab,
    IonFabButton,
    IonIcon,
    IonItem,
    IonPage,
    IonText,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { createPlayers, hasTeam, updatePlayersInEvents } from "refsix-core";
import {
    FieldArrayWithId,
    FormProvider,
    useFieldArray,
    useForm,
    useFormContext,
    Validate,
} from "react-hook-form";
import { MatchPhone, Player, SelectedTeam } from "refsix-js-models";
import getPlayerName, {
    getAllStartingPlayers,
    getAllSubs,
    getAllTeamOfficials,
    sortPlayersByNumber,
} from "../../utils/playersUtils";
import React, { FC, useEffect, useRef, useState } from "react";
import "./teamSheet.css";
import { useTranslation } from "react-i18next";
import { addTeam, updateMatch } from "../../services/matchService";
import { map, some } from "lodash";
import { getDefaultTeamOfficials } from "../../services/TeamOfficialsService";
import { PlayerItemComponent } from "../../components/PlayerItemComponent";
import { useMatch } from "../../components/hooks/match";
import { useSettings } from "../../components/hooks";
import { useTeamPlayerList } from "../../components/hooks/autocomplete/useTeamPlayerList";
import { routes } from "../../route/constants";
import { updateUserActionsCheckList } from "../../services/userActionsCheckListService";
import { trackEvent } from "../../services/analytics/analyticsService";
import { RenderTeamOfficialsPlayers } from "./renderTeamOfficialsPlayers";
import { useTeamOfficialList } from "../../components/hooks/autocomplete/useTeamOfficialList";
import { camera } from "ionicons/icons";
import { TeamSheetScan } from "./teamSheetScan";
import { HeaderComponent } from "../../components";

export interface TeamSheetFormValues {
    starting: Player[];
    subs: Player[];
    teamOfficials: Player[];
}

export type TeamSheetPlayerType = keyof TeamSheetFormValues;
const teamSheetPlayerTypes: TeamSheetPlayerType[] = [
    "starting",
    "subs",
    "teamOfficials",
];

export type TeamSheetFormPlayer = FieldArrayWithId<
    TeamSheetFormValues,
    "starting" | "subs" | "teamOfficials",
    "id"
>;

export const TeamSheetWrapper: FC<{}> = () => {
    const { id, team } = useParams<{ id: string; team: string }>();
    const teamName = decodeURI(team);
    const match = useMatch(id);
    const [hasTeamSheet, setHasTeamSheet] = useState<boolean>(false);
    const teamSize = match?.teamSize || 11;
    const subsNo = match?.subsNo || 3;
    const [formValues, setFormValues] = useState<
        TeamSheetFormValues | undefined
    >();
    const settings = useSettings();

    useEffect(() => {
        if (match && match.players && teamName) {
            setHasTeamSheet(hasTeam(match, teamName));
        }
    }, [match, match?.players, teamName]);
    useEffect(() => {
        if (hasTeamSheet && match && match.players && teamName) {
            setFormValues({
                starting: getAllStartingPlayers(match.players[teamName]),
                subs: getAllSubs(match.players[teamName]),
                teamOfficials: getAllTeamOfficials(match.players[teamName]),
            });
        }
    }, [hasTeamSheet]);

    const defaultValues = {
        starting: createPlayers(teamSize, true, false, 0, settings?.hide13),
        subs: createPlayers(subsNo, false, false, teamSize, settings?.hide13),
        teamOfficials:
            !hasTeamSheet && match && match.players
                ? getAllTeamOfficials(match.players[teamName])
                : [],
    };

    return match && match.players ? (
        <NewTeamSheet
            match={match}
            teamName={teamName}
            defaultValues={defaultValues}
            values={formValues}
        />
    ) : null;
};

const NewTeamSheet: FC<{
    match: MatchPhone;
    defaultValues: TeamSheetFormValues;
    values: TeamSheetFormValues | undefined;
    teamName: string;
}> = ({ match, defaultValues, teamName, values }) => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [scanningTeamSheet, setScanningTeamSheet] = useState<boolean>(false);
    const formMethods = useForm<TeamSheetFormValues>({
        mode: "all",
        defaultValues,
        values,
    });
    const history = useHistory();

    const getTeamSide = () => {
        return teamName === match.homeTeam
            ? SelectedTeam.home
            : SelectedTeam.away;
    };

    const { handleSubmit } = formMethods;
    const onSubmit = async (data: TeamSheetFormValues) => {
        // Add name to players if empty
        const createPlayer = (
            player: Player,
            index: number,
            isStarting: boolean,
            isCaptain: boolean
        ) => {
            return {
                ...player,
                starting: isStarting,
                name: getPlayerName(player, t),
                number: player.number,
                captain: isCaptain,
                teamOfficial: false,
            };
        };

        const startingPlayers = data.starting.map((player, index) =>
            createPlayer(player, index, true, index === 0)
        );

        const subsPlayers = data.subs.map((player, index) =>
            createPlayer(player, index, false, false)
        );

        const players = [
            ...sortPlayersByNumber(startingPlayers),
            ...sortPlayersByNumber(subsPlayers),
            ...sortPlayersByNumber(data.teamOfficials),
        ];

        const newMatch = addTeam(teamName, players, match);

        newMatch.refsixCount = (newMatch.refsixCount ?? 0) + 1;
        newMatch.refsixUpdateCount = (newMatch.refsixUpdateCount ?? 0) + 1;

        Promise.all([
            updateMatch(newMatch),
            updatePlayersInEvents(newMatch, players),
            updateUserActionsCheckList({ hasAddedTeamSheet: true }),
        ]).then(() => {
            trackEvent("teamSheetAdded", {
                side: teamName === match.homeTeam ? "home" : "away",
                matchId: newMatch._id,
            });
            route.push(`${routes.match}/${newMatch._id}/overview`, "root");
        });
    };

    return (
        <IonPage>
            <HeaderComponent
                title={t("fixture.Fixture.teamsheet")}
                showBackButton
                headerEndText={t("general.save")}
                endBtnTestId="save-team-sheet"
                onClickEndButton={handleSubmit(onSubmit)}
            />
            <IonContent>
                <FormProvider {...formMethods}>
                    <PlayersComponent
                        match={match}
                        onSubmit={handleSubmit(onSubmit)}
                        teamName={teamName}
                    />
                </FormProvider>
                <TeamSheetScan
                    teamSide={getTeamSide()}
                    matchId={match._id}
                    isOpen={scanningTeamSheet}
                    onSuccess={() => {
                        setScanningTeamSheet(false);
                        // force the page to reload
                        history.go(0);
                    }}
                    onDismiss={() => {
                        setScanningTeamSheet(false);
                    }}
                ></TeamSheetScan>
                <IonFab
                    slot="fixed"
                    vertical="bottom"
                    horizontal="end"
                    style={{
                        marginBottom: "calc(env(safe-area-inset-bottom) / 2)",
                        marginRight: 10,
                    }}
                >
                    <IonFabButton
                        onClick={() => setScanningTeamSheet(true)}
                        className="rainbow-button"
                    >
                        <IonIcon icon={camera}></IonIcon>
                    </IonFabButton>
                </IonFab>
                {/* Some space so you can scroll past the fab */}
                <div style={{ height: 60 }}></div>
            </IonContent>
        </IonPage>
    );
};

export const PlayersComponent: FC<{
    match: MatchPhone;
    onSubmit: () => void;
    teamName: string;
}> = ({ match, teamName, onSubmit }) => {
    const [startingPlayerPosition, setStartingPlayerPosition] = useState<
        number | null
    >(null);
    const [subPlayerPosition, setSubPlayerPosition] = useState<number | null>(
        null
    );

    const startingPlayersHeading = useRef<HTMLIonItemElement | null>(null);
    const subPlayersHeading = useRef<HTMLIonItemElement | null>(null);

    const { watch } = useFormContext<TeamSheetFormValues>();

    const {
        fields: startingFields,
        remove: removeStarting,
        append: appendStarting,
        insert: insertStarting,
    } = useFieldArray<TeamSheetFormValues>({
        name: "starting",
    });

    const {
        fields: subsFields,
        remove: removeSub,
        append: appendSub,
        insert: insertSub,
    } = useFieldArray<TeamSheetFormValues>({
        name: "subs",
    });

    const {
        fields: officialFields,
        remove: removeOfficial,
        append: appendOfficial,
    } = useFieldArray<TeamSheetFormValues>({
        name: "teamOfficials",
    });

    const playerListMap: Record<TeamSheetPlayerType, Player[]> = {
        starting: watch("starting"),
        subs: watch("subs"),
        teamOfficials: watch("teamOfficials"),
    };

    // Generate a list of all the players in the form
    const allPlayersWatch = [
        ...playerListMap.starting.map((player) => player.name),
        ...playerListMap.subs.map((player) => player.name),
    ];

    const { t } = useTranslation();

    const [presentAlert] = useIonAlert();
    const teamPlayerList = useTeamPlayerList(teamName, allPlayersWatch);
    const teamOfficialList = useTeamOfficialList();
    const [currentPlayerFocused, setCurrentPlayerFocused] = useState<any>(null);

    const handlePlayerMove = (
        playerFieldType: TeamSheetPlayerType,
        idx: number
    ) => {
        if (startingPlayerPosition !== null && playerFieldType === "subs") {
            const startingPlayerToRemove =
                playerListMap.starting[startingPlayerPosition];
            const subPlayerToRemove = subsFields[idx];

            // remove starting player from list
            removeStarting(startingPlayerPosition);

            // remove sub player from list
            removeSub(idx);

            // Swap and add players
            insertStarting(startingPlayerPosition, subPlayerToRemove, {
                shouldFocus: false,
            });
            insertSub(idx, startingPlayerToRemove, { shouldFocus: false });

            setStartingPlayerPosition(null);
            setSubPlayerPosition(null);
        } else if (
            subPlayerPosition !== null &&
            playerFieldType === "starting"
        ) {
            const subPlayerToRemove = playerListMap.subs[subPlayerPosition];
            const startingPlayerToRemove = playerListMap.starting[idx];

            // remove starting player from list
            removeStarting(idx);

            // remove sub player from list
            removeSub(subPlayerPosition);

            // Swap and add players
            insertStarting(idx, subPlayerToRemove, { shouldFocus: false });
            insertSub(subPlayerPosition, startingPlayerToRemove, {
                shouldFocus: false,
            });

            setStartingPlayerPosition(null);
            setSubPlayerPosition(null);
        } else if (idx === startingPlayerPosition) {
            setStartingPlayerPosition(null);
        } else if (idx === subPlayerPosition) {
            setSubPlayerPosition(null);
        } else if (playerFieldType === "starting") {
            setStartingPlayerPosition(idx);
            subPlayersHeading.current?.scrollIntoView({
                behavior: "smooth",
            });
        } else if (playerFieldType === "subs") {
            setSubPlayerPosition(idx);
            startingPlayersHeading.current?.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    const validatePlayerNumberAreUniq: (
        player: Player,
        key: TeamSheetPlayerType,
        idx: number
    ) => Validate<any, TeamSheetFormValues> =
        (player, key, idx) => (fieldValue) => {
            const number = parseInt(fieldValue);
            const inte = map(
                teamSheetPlayerTypes,
                (playerType: TeamSheetPlayerType) => {
                    const playerList = playerListMap[playerType];
                    // if key and playerType are the same, we need to check that we are not comparing the same player
                    if (key === playerType) {
                        return some(
                            playerList,
                            (p: TeamSheetFormPlayer, otherIdx) => {
                                return p.number === number && idx !== otherIdx;
                            }
                        );
                    }
                    return some(playerList, (p: TeamSheetFormPlayer) => {
                        return p.number === number;
                    });
                }
            );
            return !some(inte);
        };

    const handleAddPlayer = (player: "starting" | "subs" | "teamOfficials") => {
        if (player === "starting") {
            const number = startingFields.length + 1;
            const newPlayer = new Player("", number, false, true, false);
            appendStarting(newPlayer);
        } else if (player === "subs") {
            const number = startingFields.length + subsFields.length + 1;
            const newPlayer = new Player("", number, false, false, false);
            appendSub(newPlayer);
        } else if (player === "teamOfficials") {
            const number = Number(`100${officialFields.length}`);
            const newPlayer = new Player(
                "",
                number,
                false,
                false,
                true,
                getDefaultTeamOfficials()[0].value
            );
            appendOfficial(newPlayer);
        }
    };

    const renderAddPlayerButton = (
        player: "starting" | "subs" | "teamOfficials"
    ) => {
        if (
            player === "starting" &&
            match.teamSize &&
            startingFields.length >= match.teamSize
        ) {
            return <></>;
        }
        return (
            <IonItem
                lines="none"
                className="rounded-corners-bottom"
                data-testid="itemAddPlayer"
            >
                <IonButton
                    className="item-transparent"
                    onClick={() => handleAddPlayer(player)}
                    data-testid="addPlayerButton"
                >
                    <IonText color="primary">{t("general.add")}</IonText>
                </IonButton>
            </IonItem>
        );
    };

    const removePlayerFromList = async (
        playerFieldType: "starting" | "subs" | "teamOfficials",
        index: number
    ) => {
        const playerToRemove = watch(`${playerFieldType}.${index}`);

        await presentAlert({
            id: "AlertRemovePlayer",
            header: t("general.confirmRemoval"),
            message: t("fixture.FixtureTeam.confirmRemoval.description", {
                name: getPlayerName(playerToRemove, t),
            }),
            buttons: [
                {
                    text: t("general.cancel"),
                },
                {
                    handler: () => {
                        if (playerFieldType === "starting") {
                            removeStarting(index);
                            if (subsFields.length > 0) {
                                const subPlayer = subsFields[0];
                                appendStarting(subPlayer);
                                removeSub(0);
                            }
                        } else if (playerFieldType === "subs") {
                            removeSub(index);
                        } else if (playerFieldType === "teamOfficials") {
                            removeOfficial(index);
                        }
                    },
                    id: "confirmRemovePlayer",
                    text: t("help.OnboardingVideo.controls.ok"),
                },
            ],
        });
    };

    // disable players in list
    // if starting player selected -> disable starting list
    // if sub player selected -> disable sub list
    const disablePlayersInList = (
        playerFieldType: TeamSheetPlayerType,
        idx: number
    ): boolean => {
        if (playerFieldType === "starting" && startingPlayerPosition !== null) {
            return startingPlayerPosition !== idx;
        } else if (playerFieldType === "subs" && subPlayerPosition !== null) {
            return subPlayerPosition !== idx;
        }

        return false;
    };

    return (
        <form onSubmit={onSubmit}>
            <IonCard>
                <IonItem lines="none" ref={startingPlayersHeading}>
                    <IonText>{t("fixture.FixtureTeam.lineUp")}</IonText>
                </IonItem>
                {playerListMap.starting.map((player, idx) => (
                    <PlayerItemComponent
                        idx={idx}
                        playerFieldType="starting"
                        key={idx}
                        disablePlayersInList={disablePlayersInList(
                            "starting",
                            idx
                        )}
                        isMoveTarget={
                            !!(
                                !disablePlayersInList("starting", idx) &&
                                subPlayerPosition
                            )
                        }
                        validate={validatePlayerNumberAreUniq(
                            player,
                            "starting",
                            idx
                        )}
                        isDeleteDisabled={
                            !!(startingPlayerPosition || subPlayerPosition)
                        }
                        showRemovePlayerAlert={() =>
                            removePlayerFromList("starting", idx)
                        }
                        isMovePlayerButtonVisible={subsFields.length > 0}
                        onMovePlayerButtonHandler={() =>
                            handlePlayerMove("starting", idx)
                        }
                        teamPlayerList={teamPlayerList}
                    />
                ))}
                {renderAddPlayerButton("starting")}
            </IonCard>
            <IonCard>
                <IonItem
                    lines="none"
                    className="rounded-corners-top"
                    ref={subPlayersHeading}
                >
                    <IonText>{t("fixture.FixtureTeam.substitutes")}</IonText>
                </IonItem>
                {playerListMap.subs.map((player, idx) => (
                    <PlayerItemComponent
                        idx={idx}
                        playerFieldType="subs"
                        key={idx}
                        disablePlayersInList={disablePlayersInList("subs", idx)}
                        isMoveTarget={
                            !!(
                                !disablePlayersInList("subs", idx) &&
                                startingPlayerPosition
                            )
                        }
                        validate={validatePlayerNumberAreUniq(
                            player,
                            "subs",
                            idx
                        )}
                        isDeleteDisabled={
                            !!(startingPlayerPosition || subPlayerPosition)
                        }
                        showRemovePlayerAlert={() =>
                            removePlayerFromList("subs", idx)
                        }
                        isMovePlayerButtonVisible={subsFields.length > 0}
                        onMovePlayerButtonHandler={() =>
                            handlePlayerMove("subs", idx)
                        }
                        teamPlayerList={teamPlayerList}
                    />
                ))}
                {renderAddPlayerButton("subs")}
            </IonCard>
            <IonCard>
                <IonItem lines="none">
                    <IonText>{t("fixture.FixtureTeam.teamOfficials")}</IonText>
                </IonItem>
                {playerListMap.teamOfficials.map((player, idx) => (
                    <RenderTeamOfficialsPlayers
                        player={player}
                        idx={idx}
                        playerFieldType="teamOfficials"
                        teamOfficialList={teamOfficialList}
                        showAutoComplete={false}
                        onFocused={() => {
                            setCurrentPlayerFocused(player);
                        }}
                        removePlayerFromList={removePlayerFromList}
                    />
                ))}
                {renderAddPlayerButton("teamOfficials")}
            </IonCard>
        </form>
    );
};
