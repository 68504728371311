import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./storyPlayer.css";
import * as R from "ramda";
import { IonButton, IonModal } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ReviewShareModal } from "../../pages/reviewShareModal";
import { ReviewHeader } from "./reviewHeader";
import { ReviewFooter } from "./reviewFooter";
import { trackEvent } from "../../services/analytics/analyticsService";
import { mpUpdatePersonOnce } from "../../services/analytics/mixpanelService";
import lines from "../../assets/images/review/lines-feed.png";

interface StoryContextProps {
    numStories: number;
    currentStoryIndex: number;
    storyNames: string[];
    shareNames?: string[];
    pause: () => void;
    play: () => void;
    next: () => void;
    previous: () => void;
    playing: boolean;
    setCurrentStory: (idx: number) => void;
}
const defaultStoryContext: StoryContextProps = {
    currentStoryIndex: 0,
    numStories: 0,
    storyNames: [],
    shareNames: [],
    playing: false,
    pause: () => {},
    play: () => {},
    next: () => {},
    previous: () => {},
    setCurrentStory: (idx) => {},
};
export const StoryContext =
    React.createContext<StoryContextProps>(defaultStoryContext);

export const StoryPlayer: FC<{
    storyNames: string[];
    shareNames?: string[];
}> = ({ children, storyNames, shareNames }) => {
    const [currentStory, setCurrentStory] = useState<number>(0);
    const numChildren = React.Children.count(children);
    const [playing, setPlaying] = useState<boolean>(true);

    useEffect(() => {
        if (storyNames.length > 0) {
            trackEvent("ReviewPageView", {
                page: storyNames[currentStory],
            });
        }
    }, [storyNames, currentStory]);
    const contextValue: StoryContextProps = {
        currentStoryIndex: currentStory,
        numStories: numChildren,
        storyNames,
        shareNames,
        playing,
        pause: () => {
            setPlaying(false);
        },
        play: () => {
            setPlaying(true);
        },
        next: () => {
            setCurrentStory((currentStory) => {
                if (currentStory === numChildren - 1) {
                    return currentStory;
                } else {
                    return currentStory + 1;
                }
            });
        },
        previous: () => {
            setCurrentStory((currentStory) => {
                if (currentStory === 0) {
                    return 0;
                } else {
                    return currentStory - 1;
                }
            });
        },
        setCurrentStory: (idx: number) => {
            setCurrentStory(idx);
        },
    };
    return (
        <StoryContext.Provider value={contextValue}>
            <StoryPlayerController>{children}</StoryPlayerController>
        </StoryContext.Provider>
    );
};
export const StoryPlayerController: FC = ({ children }) => {
    const { currentStoryIndex, storyNames, shareNames, pause, play } =
        useContext(StoryContext);
    const { t } = useTranslation();
    const [shareModalOpen, setShareModalOpen] = React.useState(false);
    const modalEl = React.useRef<HTMLIonModalElement>(null);
    const currentComponent =
        React.Children.toArray(children)[currentStoryIndex];

    const shareNameToStoryComponent = (currentIndex: number) => {
        const shareName =
            shareNames?.[currentIndex] || storyNames[currentIndex];
        const storyIndex = storyNames?.indexOf(shareName);
        if (storyIndex === undefined || storyIndex === -1) {
            return null;
        }
        return React.Children.toArray(children)[storyIndex];
    };

    const currentShareComponent = shareNameToStoryComponent(currentStoryIndex);

    const getShareName = (storyName: string) => {
        return shareNames && shareNames[currentStoryIndex]
            ? shareNames[currentStoryIndex]
            : storyNames[currentStoryIndex];
    };
    return (
        <div className="story-player">
            <img src={lines} className="skewed-lines" />

            <div className="story-player-header"></div>
            <ProgressIndicator />
            <StoryHeader></StoryHeader>
            <div className="stories">
                <NavigationPanel />
                {React.Children.map(children, (child, idx) => {
                    return (
                        <div
                            className={`story-container ${
                                idx === currentStoryIndex
                                    ? "current-story"
                                    : "hidden-story"
                            }`}
                        >
                            {child}
                        </div>
                    );
                })}
                {/*<div className={"current-story"}>{currentComponent}</div>*/}
            </div>
            <div className={"story-player-share"}>
                <IonButton
                    expand="block"
                    onClick={() => {
                        trackEvent("ReviewShareModal", {
                            page: storyNames[currentStoryIndex],
                        });
                        mpUpdatePersonOnce({ sharedReview2023: true });
                        setShareModalOpen(true);
                        pause();
                    }}
                >
                    {t("review.share")}
                </IonButton>
            </div>
            <IonModal
                isOpen={shareModalOpen}
                ref={modalEl}
                onDidDismiss={() => setShareModalOpen(false)}
            >
                <ReviewShareModal
                    dismiss={() =>
                        modalEl.current &&
                        modalEl.current.dismiss &&
                        modalEl.current.dismiss() &&
                        play()
                    }
                    storyName={getShareName(storyNames[currentStoryIndex])}
                    isOpen={shareModalOpen}
                >
                    <ReviewHeader></ReviewHeader>

                    <img src={lines} className="skewed-lines" />
                    <div>{currentShareComponent}</div>
                    <ReviewFooter></ReviewFooter>
                </ReviewShareModal>
            </IonModal>
        </div>
    );
};
export const NavigationPanel: FC = () => {
    const { next, previous, currentStoryIndex, numStories, pause, play } =
        useContext(StoryContext);
    const isLast = currentStoryIndex === numStories - 1;
    //if (true) return null;
    return (
        <div
            className="navigation-panel"
            onBlur={() => {
                return pause;
            }}
        >
            <div className={"left"} onClick={previous}></div>
            {!isLast && <div className={"right"} onClick={next}></div>}
        </div>
    );
};
export const ProgressIndicator = () => {
    const {
        currentStoryIndex,
        numStories,
        next,
        previous,
        playing,
        setCurrentStory,
    } = useContext(StoryContext);
    const currentAnimation = useRef<HTMLDivElement | null>(null);

    // Set a callback on the end of the animation to move to the next story
    useEffect(() => {
        let current = currentAnimation.current;
        if (current) {
            current.addEventListener("animationend", next, false);
            return () => {
                if (current) {
                    current.removeEventListener("animationend", next, false);
                }
            };
        }
    }, [next, currentAnimation]);
    return (
        <div className="progress-indicator">
            {R.range(0, numStories).map((idx) => {
                // Have we already been, are we the current, or are we yet to come?
                const isCurrent = idx === currentStoryIndex;
                const status =
                    idx < currentStoryIndex
                        ? "past"
                        : isCurrent
                        ? `current`
                        : "future";
                return (
                    <div
                        key={idx}
                        className={`progress-indicator-dot ${status}`}
                    >
                        <div
                            {...(isCurrent && { ref: currentAnimation })}
                            className={`progress-indicator-dot-inner ${status}`}
                            {...(isCurrent &&
                                !playing && {
                                    style: { animationPlayState: "paused" },
                                })}
                        ></div>
                    </div>
                );
            })}
        </div>
    );
};

export const Story: FC = ({ children }) => {
    return <div className="story">{children}</div>;
};

export const StoryHeader: FC = ({ children }) => {
    const { t } = useTranslation();
    const titleAsSpans = (title: string) => {
        return title.split(" ").map((word, idx) => (
            <span key={idx} className={"title-span"}>
                {word}{" "}
            </span>
        ));
    };
    return (
        <div className="story-header">
            <div className="thingy-container white-thing">
                {/* the green horizontal homer head */}
                <div className={"border-thing"}></div>

                <div className={"top-logo"}>2024</div>
            </div>

            <div className={"top-logo-title"}>
                <div>{titleAsSpans(t("review.yearInReview"))}</div>
            </div>
        </div>
    );
};
