import {
    IonCard,
    IonContent,
    IonGrid,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLoading,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    RefresherEventDetail,
    useIonRouter,
} from "@ionic/react";
import {
    createMatchOrAvailability,
    filterMatches,
    filterMatchesBasedOnSelectedDate,
    getFilteredMatches,
    matchMissingInfo,
} from "../../services/matchService";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";
import "./fixtures.css";
import {
    AlertComponent,
    MatchBottomCardComponent,
    MatchMiddleCardComponent,
    MatchUpperCardComponent,
    NextMatchInComponent,
    NoMatchesComponent,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMatch } from "../../redux/actions/matches";
import HeaderComponent from "../../components/HeaderComponent";
import "../../route/tabbarStyle.css";
import { MatchPhone, RefAssistAvailability } from "refsix-js-models";
import React, { useEffect, useRef, useState } from "react";
import Filters from "../filters/filters";
import { MatchType } from "../filters/enums";
import { calendar, filter, list } from "ionicons/icons";
import { hasFeatureAccess } from "../../services/subscriptionService";
import { RefsixState } from "../../redux/models/refsixState";
import { setShowIntegrationPopup } from "../../redux/actions/user";
import { routes } from "../../route/constants";
import AcceptDeclineComponent from "../../components/AcceptDeclineComponent";
import {
    useSubscription,
    useUserActionsCheckList,
} from "../../components/hooks";
import { handleRefreshIntegrationMatches } from "../../services/integrations/integrationSyncUpdates";
import moment from "moment";
import { Calendar } from "../../components/calendar/calendar";
import { MatchOrAvailabilityComponent } from "../../components/refassist/MatchOrAvailabilityComponent";
import {
    filterAvailabilityByDate,
    getAndStoreDownloadedClosedAndOpenDates,
} from "../../services/integrations/refassist/refAssistService";
import { UpgradeBanner } from "../../components/upgrade/UpgradeBanner";
import { updateUserActionsCheckList } from "../../services/userActionsCheckListService";
import { LinkToReview } from "../../components/review/linkToReview";
import { shouldShowLinkYearInReview } from "../../utils/review/review";
import { useHistory } from "react-router";
import { UseBackButtonHandler } from "../../components/hooks/useBackButtonHandler";
import { OnBoardingManager } from "../../components/OnBoardingManager";

const MATCHES_PER_PAGE = 10;

export type MatchOrAvailability = {
    timestamp: string;
    match?: MatchPhone;
    closedDate?: RefAssistAvailability;
    openDate?: RefAssistAvailability;
};

function Fixtures() {
    const { t } = useTranslation();

    const loadingMatches = useSelector(
        (state: RootState) => state.matches.loading
    );

    const subscription = useSubscription();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertTitle, setAlertTitle] = useState<string>("");
    const [alertDesc, setAlertDesc] = useState<string>("");
    const [allFixtures, setAllFixtures] = useState<MatchPhone[]>([]);
    const [isCalendarHidden, setIsCalendarHidden] = useState<boolean>(true);
    const [userSelectedDate, setUserSelectedDate] = useState(
        moment().format("YYYY-MM-DD")
    );
    const dateTimeRef = useRef<HTMLIonDatetimeElement>(null);
    const allMatches = useSelector((state: RootState) => state.matches.matches);
    const allClosedDates = useSelector(
        (state: RootState) => state.matches.closedDates
    );
    const allOpenDates = useSelector(
        (state: RootState) => state.matches.openDates
    );
    const [matchOrAvailability, setMatchOrAvailability] = useState<
        MatchOrAvailability[]
    >([]);
    const history = useHistory();

    const filters = useSelector(
        (state: RootState) => state.filters.filterMatches
    );

    const filteredFixtures = useSelector((state: RootState) => {
        const filters = state.filters.filterMatches;
        return filters
            ? getFilteredMatches(filters, state.matches.matches)
            : filterMatches(state.matches.matches);
    });
    const [loadingCalendar, setLoadingCalendar] = useState<boolean>(false);

    const showIntegrationAlert = useSelector(
        (state: RefsixState) => state.user.integrationPopup
    );

    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [displayedMatches, setDisplayedMatches] = useState<MatchPhone[]>([]);
    const route = useIonRouter();
    const dispatch = useDispatch();

    const matchesPerPage = () => {
        const countOverride = new URLSearchParams(window.location.search).get(
            "count"
        );
        if (countOverride) {
            return parseInt(countOverride);
        }
        return MATCHES_PER_PAGE;
    };

    const pushData = () => {
        if (isCalendarHidden) {
            const max = Math.min(
                displayedMatches.length + matchesPerPage(),
                filteredFixtures.length
            );
            setDisplayedMatches(filteredFixtures.slice(0, max));
        } else {
            // updateCalendarForMatchesAndClosedDates();
        }
    };

    const loadData = (ev: any) => {
        pushData();
        ev.target.complete();
    };

    useEffect(() => {
        if (window.location.search === "?showFilters=true") {
            setFilterModal(true);
        }
    }, [window.location]);

    useEffect(() => {
        // Reset the list to show new matches
        if (filters) {
            const newlyFilteredMatches = getFilteredMatches(
                filters,
                allFixtures
            );
            setDisplayedMatches(
                newlyFilteredMatches.slice(0, matchesPerPage())
            );
        }
    }, [filters]);

    useEffect(() => {
        setAllFixtures(filterMatches(allMatches));
        if (!loadingMatches && !loadingCalendar) {
            pushData();
        }
    }, [allMatches, loadingMatches, isCalendarHidden, loadingCalendar]);

    useEffect(() => {
        if (!isCalendarHidden) {
            setLoadingCalendar(true);
            updateCalendarForMatchesAndClosedDates();
        }
    }, [isCalendarHidden]);

    // If user is from Denmark -> ask to link DBU account
    useEffect(() => {
        if (showIntegrationAlert && !loadingMatches) {
            setAlertTitle(t("integrations.dbuIntegrationPopup.title"));
            setAlertDesc(
                t("integrations.dbuIntegrationPopup.descriptionMessage")
            );
            setShowAlert(true);
        }
    }, [showIntegrationAlert, loadingMatches]);
    const userActionsCheckList = useUserActionsCheckList();

    const filteredAvailability = (
        userSelectedDate: string,
        allClosedDates: RefAssistAvailability[],
        allOpenDates: RefAssistAvailability[]
    ) => {
        return {
            closedDates: filterAvailabilityByDate(
                userSelectedDate,
                allClosedDates
            ),
            openDates: filterAvailabilityByDate(userSelectedDate, allOpenDates),
        };
    };

    const reloadAvailability = (
        userSelectedDate: string,
        allFixtures: MatchPhone[],
        allClosedDates: RefAssistAvailability[],
        allOpenDates: RefAssistAvailability[]
    ) => {
        const matches = filterMatchesBasedOnSelectedDate(
            allFixtures,
            userSelectedDate
        );
        setMatchOrAvailability(
            createMatchOrAvailability(
                matches,
                filterAvailabilityByDate(userSelectedDate, allClosedDates),
                filterAvailabilityByDate(userSelectedDate, allOpenDates)
            )
        );
    };

    useEffect(() => {
        if (allClosedDates && !isCalendarHidden) {
            reloadAvailability(
                userSelectedDate,
                allFixtures,
                allClosedDates,
                allOpenDates
            );
        }
    }, [allClosedDates, allOpenDates]);

    const updateCalendarForMatchesAndClosedDates = async () => {
        await getAndStoreDownloadedClosedAndOpenDates();

        const { closedDates, openDates } = filteredAvailability(
            userSelectedDate,
            allClosedDates,
            allOpenDates
        );

        hideTopHeaderButtonsInCalendar();

        const filteredMatches = filterMatchesBasedOnSelectedDate(
            allMatches,
            userSelectedDate
        );

        setMatchOrAvailability(
            createMatchOrAvailability(filteredMatches, closedDates, openDates)
        );
        setLoadingCalendar(false);
    };

    const handleRefreshMatches = async (
        event?: CustomEvent<RefresherEventDetail>
    ) => {
        const errors = await handleRefreshIntegrationMatches(event);

        if (errors && errors.length > 0) {
            errors.forEach((integrationName) => {
                generalError(
                    `${t("general.integrations")} - ${integrationName}`,
                    t("general.errorTryAgain")
                );
            });
        }
    };

    const generalError = (title: string, desc: string) => {
        setAlertTitle(title);
        setAlertDesc(desc);
        setShowAlert(true);
    };

    function matchElements() {
        return (
            displayedMatches.length > 0 &&
            displayedMatches.map(function (match, index): React.ReactNode {
                return (
                    <div key={match._id} className="match-header">
                        {index % 3 === 0 && <UpgradeBanner />}
                        <IonCard
                            key={index}
                            data-testid="match-card"
                            color={"medium"}
                            style={{ backgroundColor: "#242424" }}
                            className="fixture-card"
                            onClick={() => {
                                dispatch(setSelectedMatch(match));
                                if (matchMissingInfo(match)) {
                                    route.push(
                                        `${routes.createMatch}/${match._id}`
                                    );
                                } else {
                                    route.push(
                                        `${routes.match}/${match._id}/overview`,
                                        "forward"
                                    );
                                }
                            }}
                        >
                            <IonGrid>
                                <IonRow>
                                    <MatchUpperCardComponent match={match} />
                                    <MatchMiddleCardComponent match={match} />
                                    <MatchBottomCardComponent match={match} />
                                    <AcceptDeclineComponent match={match} />
                                </IonRow>
                            </IonGrid>
                        </IonCard>
                    </div>
                );
            })
        );
    }

    // function to add 3 numbers

    const hasIntegrationAccess = (): boolean => {
        return (
            hasFeatureAccess("dbuMember") ||
            hasFeatureAccess("refAssistMember") ||
            hasFeatureAccess("stackSportsMember")
        );
    };

    const hideTopHeaderButtonsInCalendar = () => {
        const element = dateTimeRef.current;
        if (element) {
            const shadowRoot = element.shadowRoot;
            if (shadowRoot) {
                const buttons = shadowRoot.querySelectorAll(
                    ".calendar-action-buttons"
                );
                buttons.forEach((button) => {
                    (button as HTMLElement).style.display = "none";
                });
            }
        }
    };

    const showNoMatchesComponent = (): boolean => {
        // If we are in calendar view and there are no matches or availabilities -> show no matches component
        if (
            !loadingCalendar &&
            !loadingMatches &&
            !isCalendarHidden &&
            matchOrAvailability.length <= 0
        ) {
            return true;
        }

        // If we are in list view and there are no matches -> show no matches component
        return (
            !loadingCalendar &&
            !loadingMatches &&
            allFixtures.length <= 0 &&
            isCalendarHidden
        );
    };

    const getNoMatchesString = (): string => {
        if (isCalendarHidden) {
            return t("fixture.FixtureList.noFixtures");
        } else {
            return t("fixture.FixtureList.noFixturesSelectedDate");
        }
    };
    const handleCloseModal = () => {
        setFilterModal(false);
        window.history.replaceState({}, "", routes.refsixFixtures);
    };

    const handleBackButton = () => {
        if (
            !window.location.search.includes("?showFilters=true") &&
            window.location.pathname === routes.refsixFixtures
        ) {
            handleCloseModal();
        }
    };

    return (
        <IonPage>
            <OnBoardingManager />
            <HeaderComponent
                titleTestId="headerTitleId"
                title={t("stats.stats.matches")}
                endBtnIconName={filter}
                endBtnTestId={"fixturesFilterBtn"}
                integration={hasIntegrationAccess()}
                onClickEndButton={() => {
                    setFilterModal(true);
                    history.push(routes.refsixFixtures + "?showFilters=true");
                }}
                showProBadge={subscription?.isPro}
                showPlusBadge={subscription?.isPlus}
                showCalendarListViewButton={true}
                onCalendarListViewClick={() => {
                    setIsCalendarHidden(!isCalendarHidden);
                    if (!userActionsCheckList.calenderViewed) {
                        updateUserActionsCheckList({ calendarViewed: true });
                    }
                }}
                calendarListViewIcon={isCalendarHidden ? calendar : list}
                showMgmButton={true}
            />
            {!isCalendarHidden && (
                <Calendar
                    allFixtures={allFixtures}
                    setUserSelectedMonth={(month) => setUserSelectedDate(month)}
                    setSelectedDate={userSelectedDate}
                    dateTimeRef={dateTimeRef}
                    setMatchOrAvailability={setMatchOrAvailability}
                />
            )}
            <IonContent>
                <UseBackButtonHandler handlerFunction={handleBackButton} />
                <IonLoading isOpen={loadingCalendar} showBackdrop />
                <IonRefresher
                    slot="fixed"
                    onIonRefresh={(e) => handleRefreshMatches(e)}
                >
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>

                {isCalendarHidden && <NextMatchInComponent />}

                {shouldShowLinkYearInReview(new Date(), allMatches) && (
                    <LinkToReview />
                )}

                {filteredFixtures.length < allFixtures.length && (
                    <IonItem color="transparent" lines="none">
                        <IonText
                            className="filter-result-text"
                            data-testid="filtered-matches-text"
                        >
                            <span
                                className="filter-results-number"
                                data-testid="filter-fixtures-number"
                            >
                                {filteredFixtures.length}/{allFixtures.length}
                            </span>
                            {t("stats.stats.Filters.resultsBasedOnFilters")}
                        </IonText>
                    </IonItem>
                )}

                {!isCalendarHidden &&
                    matchOrAvailability
                        .sort((a, b) => {
                            const timestampA = a.timestamp;
                            const timestampB = b.timestamp;
                            return timestampA.localeCompare(timestampB);
                        })
                        .map((matchOrAvailability, index) => {
                            return (
                                <div key={index}>
                                    <MatchOrAvailabilityComponent
                                        matchOrAvailability={
                                            matchOrAvailability
                                        }
                                        loading={setLoadingCalendar}
                                    />
                                </div>
                            );
                        })}
                {!loadingCalendar &&
                    !loadingMatches &&
                    allFixtures.length > 0 &&
                    isCalendarHidden && (
                        <div>
                            {matchElements()}
                            <IonInfiniteScroll
                                onIonInfinite={loadData}
                                threshold="100px"
                                disabled={
                                    filteredFixtures.length ===
                                    displayedMatches.length
                                }
                            >
                                <IonInfiniteScrollContent
                                    loadingSpinner="bubbles"
                                    loadingText="Loading more data..."
                                />
                            </IonInfiniteScroll>
                        </div>
                    )}

                {showNoMatchesComponent() ? (
                    <NoMatchesComponent
                        page="fixtures"
                        notMatchesTitle={getNoMatchesString()}
                        selectedDate={userSelectedDate}
                    />
                ) : (
                    <></>
                )}

                <IonModal
                    isOpen={filterModal}
                    onDidDismiss={() => handleCloseModal()}
                    initialBreakpoint={1}
                    showBackdrop
                >
                    <Filters
                        matches={allFixtures}
                        matchType={MatchType.Fixture}
                        closeModal={() => handleCloseModal()}
                    />
                </IonModal>
                <AlertComponent
                    testId={"dbu-alert"}
                    onDonePressed={() => {
                        if (showIntegrationAlert) {
                            route.push(routes.refsixDbuIntegration);
                            dispatch(setShowIntegrationPopup(false));
                        } else {
                            setShowAlert(false);
                        }
                    }}
                    showAlert={showAlert}
                    title={alertTitle}
                    buttonDone={
                        showIntegrationAlert
                            ? t("general.yes")
                            : t("help.OnboardingVideo.controls.ok")
                    }
                    description={alertDesc}
                    buttonAltText={t("general.later")}
                    showAlternativeButton={showIntegrationAlert}
                    dismissAlert={() =>
                        dispatch(setShowIntegrationPopup(false))
                    }
                />
            </IonContent>
        </IonPage>
    );
}

export default Fixtures;
