export class Timings {
    period1: number;
    period2?: number;
    period3?: number;
    period4?: number;
    interval1?: number;
    interval2?: number;
    interval3?: number;
    extraTimeHalfLength?: number;
    sinBinTimerLength?: number;

    constructor(
        period1: number,
        period2?: number,
        interval1?: number,
        period3?: number,
        period4?: number,
        interval2?: number,
        interval3?: number,
        extraTimeHalfLength?: number,
        sinBinTimerLength?: number
    ) {
        this.period1 = period1;
        this.period2 = period2;
        this.period3 = period3;
        this.period4 = period4;
        this.interval1 = interval1;
        this.interval2 = interval2;
        this.interval3 = interval3;
        this.extraTimeHalfLength = extraTimeHalfLength;
        this.sinBinTimerLength = sinBinTimerLength;
    }

    static fromJSON(json: any): Timings {
        return new Timings(
            json.period1,
            json.period2,
            json.interval1,
            json.period3,
            json.period4,
            json.interval2,
            json.interval3,
            json.extraTimeHalfLength,
            json.sinBinTimerLength
        );
    }
}
