import { AlertButton, IonAlert, IonContent, IonModal } from "@ionic/react";
import { FC, useEffect, useRef } from "react";
import { useTemplates } from "../components/hooks";
import { useMatches } from "../components/hooks/match";
import {
    hasSystemBMatches,
    migrateSystemBMatches,
} from "../services/matchService";
import {
    hasSystemBTemplates,
    migrateSystemBTemplates,
} from "../services/templateService";
import { IonAlertCustomEvent } from "@ionic/core/dist/types/components";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../services/analytics/analyticsService";

export type MigrateSystemBModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
};

export const MigrateSystemBModal: FC<MigrateSystemBModalProps> = ({
    isOpen,
    onDismiss,
}) => {
    const matches = useMatches() || [];
    const templates = useTemplates();
    const hasMatches = hasSystemBMatches(matches);
    const hasTemplates = templates ? hasSystemBTemplates(templates) : false;
    const { t } = useTranslation();

    let message = "";
    let state = "";
    let buttons: AlertButton[];
    if (!hasMatches && !hasTemplates) {
        message = t("sinbinChanges.noChangeNeeded");
        buttons = [
            {
                text: t("help.OnboardingVideo.controls.ok"),
                role: "confirm",
            },
        ];
        state = "noChangeNeeded";
    } else if (!hasMatches && hasTemplates) {
        message = t("sinbinChanges.templates");
        buttons = [
            {
                text: t("sinbinChanges.no"),
                role: "cancel",
            },
            {
                text: t("sinbinChanges.yes.templates"),
                role: "confirm",
            },
        ];
        state = "templates";
    } else if (hasMatches && !hasTemplates) {
        message = t("sinbinChanges.matches");
        buttons = [
            {
                text: t("sinbinChanges.no"),
                role: "cancel",
            },
            {
                text: t("sinbinChanges.yes.matches"),
                role: "confirm",
            },
        ];
        state = "matches";
    } else {
        message = t("sinbinChanges.matchesAndTemplates");
        buttons = [
            {
                text: t("sinbinChanges.no"),
                role: "cancel",
            },
            {
                text: t("sinbinChanges.yes.matchesAndTemplates"),
                role: "confirm",
            },
        ];
        state = "matchesAndTemplates";
    }
    const handleDismiss: (
        event: IonAlertCustomEvent<OverlayEventDetail<any>>
    ) => void = async ({ detail }) => {
        if (detail.role === "confirm") {
            trackEvent(`systemBMigrationAlertCompleted`, { label: state });
            if (hasMatches) {
                await migrateSystemBMatches(matches);
            }
            if (hasTemplates && templates) {
                await migrateSystemBTemplates(templates);
            }
        } else {
            trackEvent(`systemBMigrationAlertSkipped`, { label: state });
        }
        onDismiss();
    };
    // Track opening of the modal
    useEffect(() => {
        if (isOpen && state !== "") {
            trackEvent(`systemBMigrationAlertShown`, { label: state });
        }
    }, [isOpen, state]);

    return (
        <IonAlert
            isOpen={isOpen}
            onDidDismiss={handleDismiss}
            buttons={buttons}
            header={t("sinbinChanges.title")}
            message={message}
        />
    );
};
