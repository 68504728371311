export const HRZoneConstants = [
    {
        min: 0.5,
        max: 0.6,
    },
    {
        min: 0.6,
        max: 0.7,
    },
    {
        min: 0.7,
        max: 0.8,
    },
    {
        min: 0.8,
        max: 0.9,
    },
    {
        min: 0.9,
        max: 1,
    },
];
