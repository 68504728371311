import { RefsixState } from "../models/refsixState";
import { SET_SESSION_EXPIRED } from "../actions/types";
import { Middleware } from "redux";
import { trackEvent } from "../../services/analytics/analyticsService";
import moment from "moment";

export const trackingMiddleware: Middleware<{}, RefsixState> =
    (store) => (next) => (action) => {
        // check if connected to watch
        switch (action.type) {
            case SET_SESSION_EXPIRED:
                const currentlyExpired = store.getState().auth.sessionExpired;

                if (!currentlyExpired && action.expired) {
                    const dbLastUpdated =
                        store.getState().appStatus.dbUpdatedAt;
                    // track the event
                    trackEvent("SessionExpired", {
                        dbLastUpdated: dbLastUpdated
                            ? moment(dbLastUpdated).toISOString()
                            : undefined,
                        dbDaysLoggedOut: dbLastUpdated
                            ? Math.floor(
                                  moment().diff(moment(dbLastUpdated), "days")
                              )
                            : undefined,
                    });
                }
                break;
        }

        next(action);
    };
