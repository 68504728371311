import { LoginSession } from "./loginSession";
import { UserProfile } from "../../models/userProfile";
import { SubscriptionState } from "./subscriptionState";
import {
    ConfigPhone,
    MatchPhone,
    Message,
    RefAssistAvailability,
    Templates as TemplatesModel,
    UserActionsCheckList,
    WatchBluetoothStatus,
} from "refsix-js-models";
import { FiltersModel } from "./filtersModel";
import { initialWatchStatus } from "../reducers/watchStatusReducer";
import { Experiment, VariantMap } from "../../models/Experiment";
import { getLocale } from "../../utils/localeUtils";
import { getMisconductByLocale } from "refsix-core";

export const INSTALLED_VERSION_UNKNOWN = "UNKNOWN";

export class AppStatus {
    installedVersion = INSTALLED_VERSION_UNKNOWN;
    loginOrRegisterComplete = false;
    pushConsentPromptLastShown?: number; // unix epoch of last time we showed the push consent prompt
    showOnBoardingFlow = false;
    showWhatsNew = false;
    sessionExpiredModalDismissed? = false;
    dbUpdatedAt?: number;
}
export class Auth {
    loggedIn = false;
    session?: LoginSession;
    sessionExpired? = false;
}

export class Matches {
    matches: MatchPhone[] = [];
    match?: MatchPhone;
    loading?: boolean;
    closedDates: RefAssistAvailability[] = [];
    openDates: RefAssistAvailability[] = [];
}

export class Settings {
    settings: ConfigPhone = {
        ...ConfigPhone.defaultConfig(),
        misconductCode: getMisconductByLocale(getLocale()),
    };
    appleLoginCache?: { [k: string]: any } = {};
}

export class UserActionsCheckListState {
    userActionsCheckList?: UserActionsCheckList = undefined;
}

export class Templates {
    templates?: TemplatesModel = undefined;
}

export class User {
    usernameOrEmail = "";
    integrationPopup = false;
}

export class CurrentUser {
    profile?: UserProfile;
}

export interface ProfileMap {
    [username: string]: UserProfile;
}

export class UserProfiles {
    profiles: ProfileMap = {};
}

export class Filters {
    filterMatches?: FiltersModel;
    filterResults?: FiltersModel;
}

export interface TranslatableErrorMessage {
    // the translation keys to look up.
    messageKey: string;
    color: string;
}

export interface ErrorMessages {
    messages: TranslatableErrorMessage[];
}

export interface PushMessage {
    title: string;
    body: string;
    launchUrl?: string;
}

export interface PushMessages {
    messages: PushMessage[];
}

export interface DeveloperTools {
    enabled: boolean;
}

export interface Messages {
    messages: Message[];
}

export interface Experiments {
    activeExperiments: Experiment[];
    selectedVariants: VariantMap;
}
export enum IntegrationMessageType {
    MISSING_CREDENTIALS = "missing credentials",
    SERVICE_UNAVAILABLE = "service unavailable",
}

export type IntegrationsMessage = {
    integration: string;
    message: IntegrationMessageType;
};

export type IntegrationsMessages = {
    messages: IntegrationsMessage[];
};

export class RefsixState {
    appStatus: AppStatus;
    auth: Auth;
    matches: Matches;
    user: User;
    currentUser?: CurrentUser;
    profiles: UserProfiles;
    subscriptions: SubscriptionState;
    settings: Settings;
    templates: Templates;
    userActionsCheckList: UserActionsCheckList;
    filters: Filters;
    watchStatus: WatchBluetoothStatus;
    errorMessages: ErrorMessages;
    pushMessages: PushMessages;
    developerTools: DeveloperTools;
    messages: Messages = { messages: [] };
    experiments: Experiments = {
        activeExperiments: [],
        selectedVariants: {},
    };
    integrationsMessages: IntegrationsMessages = { messages: [] };

    constructor(
        appStatus: AppStatus = new AppStatus(),
        auth: Auth = new Auth(),
        matches: Matches = new Matches(),
        currentUser: CurrentUser | undefined = undefined,
        profiles: UserProfiles = new UserProfiles(),
        user: User = new User(),
        subscriptions: SubscriptionState = new SubscriptionState(
            [],
            [],
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false
        ),
        settings: Settings = new Settings(),
        templates: Templates = new Templates(),
        userActionsCheckList: UserActionsCheckList = new UserActionsCheckListState(),
        filters: Filters = new Filters(),
        watchStatus: WatchBluetoothStatus = initialWatchStatus,
        developerTools: DeveloperTools = { enabled: false },
        messages: Messages = { messages: [] },
        expiriments: Experiments = {
            activeExperiments: [],
            selectedVariants: {},
        },
        integrationsMessages: IntegrationsMessages = { messages: [] }
    ) {
        this.appStatus = appStatus;
        this.auth = auth;
        this.matches = matches;
        this.user = user;
        this.currentUser = currentUser;
        this.profiles = profiles;
        this.subscriptions = subscriptions;
        this.settings = settings;
        this.templates = templates;
        this.userActionsCheckList = userActionsCheckList;
        this.filters = filters;
        this.watchStatus = watchStatus;
        this.errorMessages = { messages: [] };
        this.pushMessages = { messages: [] };
        this.developerTools = developerTools;
        this.messages = messages;
        this.experiments = expiriments;
        this.integrationsMessages = integrationsMessages;
    }
}
