import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { IonInput } from "@ionic/react";
import { CustomIonFormProps } from "../../pages/addResult/components";
import "./R6IonInput.css";

export const R6IonInput: FC<CustomIonFormProps> = ({
    name,
    testId,
    rules = {},
    slot = "",
    className = "",
    type,
    inputMode,
    onFocus,
    onBlur: customOnBlur,
    disabled = false,
    placeholder,
    autocapitalize = "words",
    maxLength,
    id,
    min,
    max,
    step,
    debounce,
    onClick,
    readonly,
    label,
}) => {
    return (
        <Controller
            disabled={disabled}
            rules={rules}
            render={({ field: { onChange, onBlur, ref, ...rest } }) => (
                <IonInput
                    id={id}
                    data-testid={testId || name}
                    color="medium" // this helps with the cursor being more visible on light backgrounds
                    onIonChange={onChange}
                    onIonInput={onChange}
                    type={type}
                    inputMode={inputMode}
                    onIonFocus={onFocus}
                    onIonBlur={(e) => {
                        onBlur();
                        customOnBlur && customOnBlur();
                    }}
                    maxlength={maxLength}
                    min={min}
                    max={max}
                    step={step}
                    slot={slot}
                    autocapitalize={autocapitalize}
                    className={className}
                    placeholder={placeholder}
                    debounce={debounce}
                    onClick={onClick}
                    readonly={readonly}
                    label={label}
                    {...rest}
                />
            )}
            name={name}
        />
    );
};
