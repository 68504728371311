import React, { FC, useState } from "react";
import { Fixture } from "./overview";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonIcon,
    IonItem,
    IonLoading,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./teams.css";
import { IntegrationPartner, MatchPhone, SelectedTeam } from "refsix-js-models";
import {
    generatePlayersEventsStructure,
    PlayerWithEvents,
} from "../../../utils/playersUtils";
import { getSelectedTeam } from "../../../utils/teamUtils";
import { downloadDBUTeamSheet } from "../../../services/integrationService";
import { addTeam, updateMatch } from "../../../services/matchService";
import { isAwayReady, isHomeReady } from "refsix-core";
import { routes } from "../../../route/constants";
import moment from "moment";
import { RenderTeamSheetForTeam } from "../../../components/teams/RenderTeamSheetForTeam";
import ScrollableContentWithAds from "../../../components/ScrollableContentsWithAd";
import { updateUserActionsCheckList } from "../../../services/userActionsCheckListService";
import { GoProAlertComponent } from "../../../components/GoProAlertComponent";
import { isAndroid, isIos } from "../../../services/platformDetection";
import { trackProTeaser } from "../../../services/analytics/analyticsService";
import { URLS } from "../../../constants/urls";
import {
    hasFeatureAccess,
    isUserPlusEligible,
} from "../../../services/subscriptionService";
import { camera } from "ionicons/icons";
import { useProfile } from "../../../components/hooks";
import { TeamSheetScan } from "../../teamsheets/teamSheetScan";

const Teams: FC<Fixture> = ({ match }) => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [selectedValue, setSelectedValue] = useState<
        SelectedTeam.home | SelectedTeam.away
    >(SelectedTeam.home);
    const [proAlert, setProAlert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [scanningTeamSheet, setScanningTeamSheet] = useState<boolean>(false);
    const hasRequestTeamSheetAccess = hasFeatureAccess("requestTeamsheet");

    // fix for deleting the match
    if (!match) {
        return <div />;
    }

    let playersEventsByTeam = generatePlayersEventsStructure(match);

    // Getting selected team
    let selectedTeam = getSelectedTeam(selectedValue);

    // Getting all players for selected team
    const playersEvents = playersEventsByTeam[selectedValue];
    // Getting all players starting in
    let starting: PlayerWithEvents[] = playersEvents.filter(
        (playerWithEvents) =>
            playerWithEvents.player.starting &&
            !playerWithEvents.player.teamOfficial
    ) as PlayerWithEvents[];

    // Getting all players not starting in
    let subs: PlayerWithEvents[] = playersEvents.filter(
        (playerWithEvents) =>
            !playerWithEvents.player.starting &&
            !playerWithEvents.player.teamOfficial
    ) as PlayerWithEvents[];

    // Getting all team officials
    let teamOfficials: PlayerWithEvents[] = playersEvents.filter(
        (playerWithEvents) => playerWithEvents.player.teamOfficial
    ) as PlayerWithEvents[];

    function handleTeamChanged(team: string) {
        if (team === SelectedTeam.home) {
            setSelectedValue(SelectedTeam.home);
        } else {
            setSelectedValue(SelectedTeam.away);
        }
    }

    const scanTeamSheet = async () => {
        setScanningTeamSheet(true);
    };

    const downloadTeamSheet = async () => {
        // Abort if no match id
        if (!match._id) return;
        setLoading(true);
        try {
            updateUserActionsCheckList({ downloadTeamSheet: true });
            const downloadTeamSheetResponse = await downloadDBUTeamSheet(
                match._id
            );
            if (downloadTeamSheetResponse) {
                // TODO: fix any here (This might be DBU or other integrations type)
                const teamSheet = downloadTeamSheetResponse.data as any;

                if (teamSheet) {
                    let newMatch: MatchPhone = MatchPhone.fromJSON(match);

                    const homeTeamSheet = teamSheet.homeTeamSheet;
                    const awayTeamSheet = teamSheet.awayTeamSheet;

                    if (
                        homeTeamSheet.length === 0 &&
                        awayTeamSheet.length === 0
                    ) {
                        setLoading(false);
                        alert(t("integrations.teamSheets.noTeamSheet"));
                        return;
                    }

                    if (homeTeamSheet.length > 0) {
                        newMatch = addTeam(
                            newMatch.homeTeam,
                            homeTeamSheet,
                            newMatch
                        );
                    }

                    if (awayTeamSheet.length > 0) {
                        newMatch = addTeam(
                            newMatch.awayTeam,
                            awayTeamSheet,
                            newMatch
                        );
                    }

                    await updateMatch(newMatch);
                    setLoading(false);
                }
            }
        } catch (e) {
            console.log("error trying to download teamsheet", e);
            alert(
                t("integrations.integrationsFailure.title") +
                    "</br>" +
                    t("integrations.integrationsFailure,serverError")
            );
            setLoading(false);
        }
    };

    const handleAddTeamsheet = () => {
        if (selectedTeam === SelectedTeam.home) {
            route.push(
                `${routes.refsixAddEditTeamSheet}/${match._id}/${match.homeTeam}`
            );
        } else {
            route.push(
                `${routes.refsixAddEditTeamSheet}/${match._id}/${match.awayTeam}`
            );
        }
    };

    const teamSheetStatusString = (side: SelectedTeam) => {
        if (
            !match.teamSheetRequests ||
            (side !== SelectedTeam.none && !match.teamSheetRequests[side])
        ) {
            return "";
        }

        if (
            match.teamSheetRequests &&
            side !== SelectedTeam.none &&
            match?.teamSheetRequests[side]?.completed
        ) {
            if (match.teamSheetRequests[side]?.completedDate) {
                const completedDate =
                    match.teamSheetRequests[side]?.completedDate;
                if (completedDate) {
                    const dateString =
                        moment(completedDate).format("DD MMM YYYY");
                    return (
                        <IonText>
                            {t("requestTeamSheets.receivedOn", {
                                date: dateString,
                            })}
                        </IonText>
                    );
                }
            }
        } else {
            return t("requestPayments.requestSent");
        }
    };

    const handleRequestTeamsheetButtonClicked = () => {
        if (hasRequestTeamSheetAccess) {
            route.push(`${routes.refsixRequestTeamSheet}/${match._id}`);
        } else {
            setProAlert(true);
        }
    };

    return (
        <ScrollableContentWithAds>
            <IonItem
                className="teams-segments-container"
                lines="none"
                data-testid="fixture-segment-teams"
            >
                <IonSegment
                    data-testid="segmentController"
                    value={selectedValue}
                    onIonChange={(e) =>
                        e.detail.value &&
                        handleTeamChanged(e.detail.value as string)
                    }
                >
                    <IonSegmentButton value="home">
                        {t("general.home")}
                    </IonSegmentButton>
                    <IonSegmentButton value="away">
                        {t("general.away")}
                    </IonSegmentButton>
                </IonSegment>
            </IonItem>

            <IonLoading isOpen={loading} />

            {/* TODO use features to drive this */}
            {match.integration &&
                match.integration?.partner === IntegrationPartner.dbu && (
                    <IonItem lines="none" color="none">
                        <IonCol>
                            <IonButton
                                expand="block"
                                onClick={() => downloadTeamSheet()}
                            >
                                <IonText>
                                    {t("integrations.downloadTeamSheet")}
                                </IonText>
                            </IonButton>
                        </IonCol>
                    </IonItem>
                )}

            <IonCard
                hidden={
                    selectedTeam === SelectedTeam.home
                        ? isHomeReady(match)
                        : isAwayReady(match)
                }
            >
                <IonCardContent>
                    {teamSheetStatusString(selectedTeam) && (
                        <IonText className="align-center">
                            {teamSheetStatusString(selectedTeam)}
                        </IonText>
                    )}

                    {!teamSheetStatusString(selectedTeam) && (
                        <IonText>
                            {t("fixture.Fixture.noTeamSheetAdded")}
                        </IonText>
                    )}
                    <IonRow>
                        <IonButton
                            style={{ marginTop: 20, flex: 1 }}
                            data-testid="addTeamSheetButton"
                            expand="block"
                            onClick={() => handleAddTeamsheet()}
                        >
                            <IonText>{`${t("general.add")} ${t(
                                "fixture.Fixture.teamsheet"
                            )}`}</IonText>
                        </IonButton>

                        <IonButton
                            style={{ marginTop: 20 }}
                            data-testid="scanTeamSheetButton"
                            onClick={() => scanTeamSheet()}
                            className="rainbow-button"
                        >
                            <IonIcon icon={camera} style={{ fontSize: 28 }} />
                        </IonButton>
                    </IonRow>

                    <IonButton
                        style={{ marginTop: 10 }}
                        data-testid="requestTeamSheetButton"
                        hidden={
                            match.integration?.partner ===
                                IntegrationPartner.dbu ||
                            !!(
                                match.teamSheetRequests &&
                                match.teamSheetRequests[selectedValue]
                            )
                        }
                        expand="block"
                        onClick={() => handleRequestTeamsheetButtonClicked()}
                    >
                        <IonText className="text-transform">
                            {t("requestTeamSheets.requestTeamSheet")}
                        </IonText>
                    </IonButton>
                </IonCardContent>
            </IonCard>

            <IonCard
                hidden={
                    selectedTeam === SelectedTeam.home
                        ? !isHomeReady(match)
                        : !isAwayReady(match)
                }
            >
                <div
                    data-testid="teamSheetStatus-with-teamSheet"
                    className="container"
                    style={{ padding: "0px 15px" }}
                    hidden={teamSheetStatusString(selectedTeam) === ""}
                >
                    <IonText className="align-center">
                        {teamSheetStatusString(selectedTeam)}
                    </IonText>
                </div>

                <RenderTeamSheetForTeam
                    playersWithEvents={starting}
                    headerTitle={t("fixture.FixtureTeam.lineUp")}
                />
                <RenderTeamSheetForTeam
                    playersWithEvents={subs}
                    headerTitle={t("fixture.FixtureTeam.substitutes")}
                />

                <RenderTeamSheetForTeam
                    playersWithEvents={teamOfficials}
                    isNumbersHidden={true}
                    headerTitle={t("fixture.FixtureTeam.teamOfficials")}
                />
            </IonCard>
            <GoProAlertComponent
                testId={"request-teamsheet"}
                showGoProAlert={proAlert}
                onDismissGoProAlert={() => setProAlert(false)}
                buttonLeanMoreText={t(
                    "accountNotification.featureUnavailable.learnMore"
                )}
                buttonLeanMoreClicked={() => {
                    setProAlert(false);
                    if (isIos() || isAndroid()) {
                        if (!isUserPlusEligible()) {
                            route.push(routes.upgrade + "/pro");
                            trackProTeaser("requestTeamsheet", "pro");
                        } else if (isUserPlusEligible()) {
                            route.push(routes.upgrade + "/plus");
                            trackProTeaser("requestTeamsheet", "plus");
                        }
                    } else {
                        window.open(
                            URLS.REFSIX_ACCOUNT +
                                "?utm_source=refsix&utm_medium=app&utm_campaign=in-app-mobile-web&utm_id=in-app-mobile-web",
                            "_system"
                        );
                    }
                }}
            />
            <TeamSheetScan
                teamSide={selectedValue}
                matchId={match._id}
                isOpen={scanningTeamSheet}
                onSuccess={() => {
                    setScanningTeamSheet(false);
                    handleAddTeamsheet();
                }}
                onDismiss={() => {
                    setScanningTeamSheet(false);
                }}
            ></TeamSheetScan>
        </ScrollableContentWithAds>
    );
};

export default Teams;
