import { WatchInterface } from "./watchInterface";
import { Observable, Observer } from "rxjs";
import { deleteNullProperties } from "../../utils/arrayUtils";
import { copyObject } from "refsix-core";
import * as Sentry from "@sentry/react";
import { setWatchStatus } from "../../redux/actions/watchStatusAction";
import { store } from "../../redux/store";
import { handleChunk } from "./messages/gpsHrReceived";
import { MessageChunkType } from "./TrackingChunkAssembler";
import shortUUID from "short-uuid";

interface WearOSResponse {
    data: string;
}

interface WearOSFitnessMessageStats {
    _id: string;
    matchId: string;
    geoPoints: string;
    heartRateValues: string;
}

interface WearOSFitnessMessage {
    stats: WearOSFitnessMessageStats;
}

export class WearOsWatch implements WatchInterface {
    _stream: Observable<any>;
    messageObservers: Observer<any>[] = [];
    // The androidWear plugin returns this ID that we always need to reference
    handle?: string;

    constructor() {
        this._stream = new Observable<any>((messageObserver) => {
            this.messageObservers.push(messageObserver);
        });

        if (window.AndroidWear) {
            window.AndroidWear.onConnect(
                (response: any) => {
                    const message = `WearOS Connection established with handle ${response.handle}`;
                    console.log(message);
                    Sentry.addBreadcrumb({ message: message });

                    if (window.AndroidWear) {
                        window.AndroidWear.sendData(
                            response.handle,
                            JSON.stringify({
                                messageId: "deviceInfoRequest",
                                _id: shortUUID().uuid(),
                            }),
                            (resp: any) => {},
                            (err: any) => {}
                        );
                    }

                    // update watch status
                    store.dispatch(
                        setWatchStatus({
                            isSupported: true,
                            isActivated: true,
                            isComplicationEnabled: false,
                            isPaired: true,
                            isWatchAppInstalled: true,
                            isReachable: true,
                        })
                    );
                    this.handle = response.handle;

                    // @ts-ignore
                    window.AndroidWear.onDataReceived(
                        this.handle,
                        (message: WearOSResponse) => {
                            this.messageReceived(JSON.parse(message.data));
                        }
                    );
                },
                (error: any) => {
                    const message = `Error establishing connection to WearOS ${JSON.stringify(
                        error
                    )}`;
                    Sentry.addBreadcrumb({ message: message });
                    console.log(message, error);

                    // update watch status
                    store.dispatch(
                        setWatchStatus({
                            isSupported: false,
                            isActivated: false,
                            isComplicationEnabled: false,
                            isPaired: false,
                            isWatchAppInstalled: false,
                            isReachable: false,
                        })
                    );
                }
            );

            if (window.WearDataApi) {
                window.WearDataApi.addListener(
                    async (payload: WearOSFitnessMessage) => {
                        try {
                            console.log(
                                "WearOS DataAPI received something...",
                                payload
                            );
                            await this.performanceDataReceived(payload);
                        } catch (e) {
                            const errorMessage = `WearOS DataAPI error processing fitness data ${JSON.stringify(
                                e
                            )}`;
                            console.log(errorMessage, e);
                            Sentry.addBreadcrumb({ message: errorMessage });
                        }
                    }
                );
            }
        }
    }

    private messageReceived(data: any) {
        console.log(`WearOS message received ${data.messageId}`);
        if (!this.messageObservers.length) {
            return Sentry.captureMessage(
                "[WearOS] Received message but observer is undefined"
            );
        }
        this.messageObservers.forEach((observer) => {
            observer.next(data);
        });
        return Promise.resolve();
    }

    messageReceivedStream(): Observable<any> {
        return this._stream;
    }

    sendMessage(payload: any): Promise<any> {
        payload = copyObject(payload);
        // mutates existing obj to remove nulls that can crash WearOS
        deleteNullProperties(payload, true);
        // return this.WearDataApi.sendMessageToWatch(payload);

        if (!window.AndroidWear || !this.handle) {
            return Promise.reject(
                "AndroidWear plugin not installed or connected"
            );
        } else {
            return new Promise((resolve, reject) => {
                // @ts-ignore
                window.AndroidWear.sendData(
                    // @ts-ignore
                    this.handle,
                    JSON.stringify(payload),
                    (response: any) => {
                        resolve(response);
                    },
                    (error: any) => {
                        reject(error);
                    }
                );
            });
        }
    }

    performanceDataReceived(locationPayload: WearOSFitnessMessage) {
        console.log("Retrieving gps and heart rate data");
        const matchId = locationPayload.stats.matchId.split("_")[0];

        const promises = [];

        if (
            locationPayload.stats.geoPoints &&
            locationPayload.stats.geoPoints.length
        ) {
            promises.push(
                handleChunk(MessageChunkType.gps, {
                    chunkCount: 1,
                    chunkNo: 1,
                    data: locationPayload.stats.geoPoints,
                    matchId: matchId,
                    payloadType: "csv",
                })
            );
        }
        if (
            locationPayload.stats.heartRateValues &&
            locationPayload.stats.heartRateValues.length
        ) {
            promises.push(
                handleChunk(MessageChunkType.hr, {
                    chunkCount: 1,
                    chunkNo: 1,
                    data: locationPayload.stats.heartRateValues,
                    matchId: matchId,
                    payloadType: "csv",
                })
            );
        }
        return Promise.all(promises);

        // if (
        //     locationPayload.stats &&
        //     locationPayload.stats.geoPoints &&
        //     locationPayload.stats.geoPoints.length
        // ) {
        //     documentId = locationPayload.stats.matchId.split("_")[0];
        //     messageId = locationPayload.stats._id;
        // } else {
        //     console.log("No gps data");
        // }
        //
        // if (
        //     locationPayload.stats &&
        //     locationPayload.stats.heartRateValues &&
        //     locationPayload.stats.heartRateValues.length
        // ) {
        //     documentId = locationPayload.stats.matchId.split("_")[0];
        //     messageId = locationPayload.stats._id;
        //     FitnessDataSyncService.getSyncService(
        //         HEART_RATE_DATA_TYPE
        //     ).addMatchTracking({
        //         _id: documentId,
        //         format: "csv",
        //         data: locationPayload.stats.heartRateValues,
        //     });
        //     hasPerformanceData = true;
        // } else {
        //     console.log("No heart rate data");
        // }
        //
        // if (hasPerformanceData) {
        //     WatchService.receivedAndroidPerformanceData(documentId, messageId);
        // }
    }
}
