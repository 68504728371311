export enum DatePreset {
    Career = "career",
    Season = "season",
    Year = "year",
    Month = "month",
    Custom = "custom",
}

export interface FiltersFixtureModel {
    startDate: string;
    endDate: string;
    datePreset?: DatePreset;
    competition: string[];
    venue: string[];
    team: string[];
    officials: string[];
    roles: {
        referee: boolean;
        assistant: boolean;
        fourthOfficial: boolean;
        observer: boolean;
    };
    keywords: string[];
}

export interface FiltersResultsModel extends FiltersFixtureModel {
    stats: {
        yellowCards: {
            value: number;
        };
        redCards: {
            value: number;
        };
        goals: {
            value: number;
        };
    };
    extraTime: boolean;
    penalties: boolean;
    abandoned: boolean;
}

export type FiltersModel = FiltersFixtureModel | FiltersResultsModel;
