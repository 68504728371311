import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";

// put it somewhere non-volatile but not visible to the user
// iOS docs: Use the Library subdirectories for any files you don’t want exposed to the user. Your app should not use these directories for user data files.
const SESSION_STORAGE_DIR = Directory.Library;

export class CapacitorStore {
    /**
     * Get an item from the filesystem cache.
     * @param key - The key to identify the file.
     * @returns A promise with the content of the file, or an error if not found.
     */
    async getItem(key: string): Promise<string | undefined> {
        console.log(`Reading redux to filesystem for key ${key}`);
        try {
            const result = await Filesystem.readFile({
                path: key,
                directory: SESSION_STORAGE_DIR,
                encoding: Encoding.UTF8, // Ensure encoding is specified
            });
            // Ensure result.data is of expected type
            return result.data as string;
        } catch (error) {
            console.error(
                `Error reading file with key ${key} from ${SESSION_STORAGE_DIR}:`,
                error
            );
            // return undefined; // TODO reinstate when removing fallback
        }
        // fallback to read from cache directory - v6.8.0 wrote to Cache director by mistake
        // TODO remove this code after a few releases
        try {
            console.log(
                `Reading redux to filesystem for key ${key} from Cache directory as a fallback`
            );
            const result = await Filesystem.readFile({
                path: key,
                directory: Directory.Cache,
                encoding: Encoding.UTF8, // Ensure encoding is specified
            });
            // Ensure result.data is of expected type
            return result.data as string;
        } catch (error) {
            console.error(
                `Error reading file with key ${key} from Cache directory:`,
                error
            );
            return undefined;
        }
    }

    /**
     * Set an item in the filesystem cache.
     * @param key - The key to identify the file.
     * @param value - The content to store in the file.
     */
    async setItem(key: string, value: string): Promise<void> {
        console.log(`Writing redux to filesystem for key ${key}`);
        await Filesystem.writeFile({
            path: key,
            data: value,
            directory: SESSION_STORAGE_DIR,
            encoding: Encoding.UTF8,
        });
    }

    /**
     * Remove an item from the filesystem cache.
     * @param key - The key identifying the file to remove.
     */
    async removeItem(key: string): Promise<void> {
        try {
            await Filesystem.deleteFile({
                path: key,
                directory: SESSION_STORAGE_DIR,
            });
        } catch (error) {
            console.error(`Error deleting file with key ${key}:`, error);
        }
    }

    /**
     * Clear all items from the filesystem cache.
     */
    async clear(): Promise<void> {
        try {
            await Filesystem.rmdir({
                path: "",
                directory: SESSION_STORAGE_DIR,
                recursive: true,
            });
        } catch (error) {
            console.error("Error clearing the cache:", error);
        }
    }

    /**
     * Get all keys in the filesystem cache.
     * @returns A promise with an array of file names.
     */
    async getAllKeys(): Promise<string[] | undefined> {
        try {
            const result = await Filesystem.readdir({
                path: "",
                directory: SESSION_STORAGE_DIR,
            });

            // Extract file names from FileInfo objects
            return result.files.map((file) => file.name);
        } catch (error) {
            console.error("Error getting all keys from cache:", error);
            return undefined;
        }
    }
}

export const capacitorStore = new CapacitorStore();
