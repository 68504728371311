import { getCountryCode, getLocale } from "./localeUtils";
import { getAllISOCodes } from "iso-country-currency";

export function CurrencyFilter(value: number): string {
    const locale = getLocale();
    const countryCode = getCountryCode();
    const allISOCodes = getAllISOCodes();
    const currency = allISOCodes.find((cur) => countryCode === cur.iso);
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency ? currency.currency : "GBP",
    }).format(value);
}
