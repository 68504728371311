import { WatchInterface } from "./watchInterface";
import { Observable, Observer } from "rxjs";
import { deleteNullProperties } from "../../utils/arrayUtils";
import { copyObject } from "refsix-core";
import { AppleWatchConnectivity } from "refsix-apple-watch-connectivity";
import * as Sentry from "@sentry/react";

export class AppleWatch implements WatchInterface {
    _stream: Observable<any>;
    messageObservers: Observer<any>[] = [];

    constructor() {
        console.log("Apple Watch init");
        this._stream = new Observable<any>((messageObserver) => {
            this.messageObservers.push(messageObserver);
        });

        AppleWatchConnectivity.addMessagedListener(
            this.messageReceived.bind(this)
        );

        // We did this in the old app, so just replicating here.
        // App.addListener("appStateChange", ({ isActive }) => {
        //     if (isActive) {
        //         AppleWatchConnectivity.addMessagedListener(
        //             this.messageReceived
        //         );
        //     }
        // });
    }

    private messageReceived(data: any) {
        console.log(`AppleWatch message received ${data.messageId}`);
        if (!this.messageObservers.length) {
            return Sentry.captureMessage(
                "[AppleWatch] Received message but observer is undefined"
            );
        }
        this.messageObservers.forEach((observer) => {
            observer.next(data);
        });
    }

    messageReceivedStream(): Observable<any> {
        return this._stream;
    }

    sendMessage(payload: any): Promise<any> {
        payload = copyObject(payload);
        // mutates existing obj to remove nulls that can crash apple watch
        deleteNullProperties(payload, true);
        return AppleWatchConnectivity.sendMessageToWatch(payload);
    }
}
