export const knvbFootballClubs: string[] = [
    "ASV Arkel",
    "FC Delta Sports ’95",
    "SHH Herten",
    "VV Hattem",
    "AAC-Olympia",
    "Aardenburg",
    "Aarlanderveen",
    "Abbenbroek",
    "Abbenes",
    "ABC Hekwerk",
    "Abcoude fc",
    "Abdissenbosch",
    "ABS",
    "Achilles '12",
    "Achilles '29",
    "Achilles 1894",
    "Achilles E",
    "Achilles Reek",
    "Achilles Veen",
    "Acht",
    "Achterveld SV",
    "Achtmaal",
    "Actief",
    "Activia",
    "ACV",
    "AD '69",
    "Adelaars zvv",
    "ADIO",
    "ADO '20",
    "ADO Den Haag",
    "Aduard 2000",
    "Advendo",
    "Advendo '57",
    "Aengwirden",
    "Aeolus",
    "AFC",
    "AFC 34",
    "AFC Ajax",
    "AFC IJburg",
    "AFC Quick 1890",
    "AGB",
    "AGOVV",
    "AGSV",
    "AH'78",
    "AHB",
    "Ajax B",
    "Akkrum",
    "Al-Andalus",
    "Albatross",
    "Alblasserdam",
    "ALC",
    "Alcides",
    "Alcmaria Victrix",
    "Alem",
    "Alexandria",
    "Alexandria'66",
    "Alkmaarsche Boys",
    "All Green's",
    "All Stars Baarn",
    "Allen Weerbaar",
    "Alliance",
    "Alliance '22 sv.",
    "Almelo",
    "Almen",
    "Almere City",
    "Almere FC",
    "Almkerk",
    "Alpha Tours",
    "Alphen",
    "Alphense Boys",
    "Alphia",
    "Altena",
    "Alteveer",
    "Altior",
    "Altius",
    "Altweerterheide",
    "Alverna",
    "Always Forward",
    "Ameide",
    "Amelandia",
    "America",
    "Amicitia VMC",
    "Ammerstolse S.V.",
    "Amstelglorie",
    "Amstelveen/Heemraad",
    "Amstenrade",
    "Amsterdamsche Footballclub Ares",
    "Amsvorde",
    "AMVJ",
    "Anadolu '90",
    "Andarinyo",
    "Angeren",
    "Angerlo Vooruit",
    "Anjum",
    "Ankaraspor FC",
    "Annen",
    "Annour/MADIBA",
    "Antibarbari",
    "Apeldoornse Boys",
    "APGS",
    "Apollo 68",
    "Apollo'69",
    "APWC",
    "Aquila",
    "Ara Cora Kingdom",
    "ARC",
    "Argon",
    "Ariston'80",
    "Arnemuiden",
    "Arnhemia",
    "Arnhemse Boys Schuytgraaf",
    "Arum",
    "AS'80",
    "ASC",
    "ASC '62",
    "ASC De Volewijckers",
    "ASH",
    "Asperen",
    "Assendelft",
    "Asser Boys",
    "Astrantia",
    "ASV '57",
    "ASV '65",
    "ASV 55",
    "ASV Arsenal",
    "ASV Blauw-Wit",
    "asv Dronten",
    "ASV'33",
    "ASVB",
    "ASW",
    "ASWH",
    "ATC '65",
    "ATIK",
    "Atletico Club Amsterdam",
    "Audacia",
    "Aurora",
    "Austerlitz",
    "Avanti",
    "Avanti W.",
    "Avanti'31",
    "AVC",
    "avc Heracles",
    "Avenhorn zvv/K&S",
    "Avereest",
    "Avesteyn",
    "AVIOS/DBV",
    "AVV",
    "AVW '66",
    "AWC",
    "Ayyildiz Spor",
    "AZ",
    "AZC",
    "AZC/VELVET MEN",
    "AZSV",
    "AZV (Akersloot)",
    "AZV (Amstelveen)",
    "AZVN",
    "B en O Team",
    "B.V.V.",
    "Baardwijk",
    "Baarn sv",
    "Bakhuizen",
    "Bakkeveen",
    "Balk",
    "Barbaros",
    "Barendrecht",
    "Bareveld",
    "Bargeres",
    "Baronie",
    "BAS",
    "Batavia",
    "Batavia '90",
    "BATO",
    "Bavel",
    "Bavos",
    "Be Fair",
    "Be Fit",
    "Be Quick '28",
    "Be Quick 1887",
    "Be Quick Dokkum",
    "Be Quick Z.",
    "Be Ready",
    "Be'79",
    "Beek Vooruit",
    "Beekbergen",
    "Beemster",
    "Beerse Boys",
    "Beetgum",
    "Beijneshal Sport Combinatie Haarlem",
    "Beilen",
    "Bekkerveld",
    "Belfeldia",
    "Bellingwolde",
    "Bennekom",
    "Benschop",
    "Bentelo",
    "Berg'28",
    "Bergambacht",
    "Bergeijk",
    "Bergentheim",
    "Berghem Sport",
    "Beringe/Second Go Electro",
    "Berkdijk",
    "Berkel",
    "Berkhout",
    "Berkum",
    "Bernardus",
    "BES",
    "Best Vooruit",
    "Beuningse Boys",
    "Bevelanders",
    "Beverwijk",
    "BEVO",
    "BEW",
    "BFC",
    "Bieslo",
    "Bilt De FC",
    "Binnenmaas, FC",
    "Black Boys",
    "Bladella",
    "Blankenham",
    "Blauw Geel '55",
    "Blauw Geel'15",
    "Blauw Geel'38/JUMBO",
    "Blauw Wit",
    "Blauw Wit '34",
    "Blauw Wit '66",
    "Blauw Wit (W)",
    "Blauw Wit'81",
    "Blauw-Zwart",
    "Blauwhuis",
    "Blerick",
    "Blija",
    "Blijdorp",
    "Blokkers de",
    "Blokzijl",
    "Blue Boys",
    "BMC",
    "BMR",
    "BMT",
    "BNC",
    "Bodegraven",
    "Boeimeer",
    "Boekel Sport",
    "Boekoel",
    "BOL",
    "Bolnes",
    "Bon Boys",
    "Borger",
    "Born ZVV",
    "Borne",
    "Borssele",
    "Boskant",
    "Botafogo",
    "Botlek, SC",
    "Boxtel",
    "Boys The / L'Aiuto Adm. Dienstv.",
    "BOZV/Autobedrijf Haisch",
    "Braakhuizen",
    "Brabantia",
    "Brakkenstein",
    "Brederodes",
    "Bredevoort",
    "Breskens",
    "BRESS",
    "Breukelen FC",
    "Brevendia",
    "Brielle, VV",
    "Bristol Team/Zonkr8",
    "Broekland",
    "Broekster Boys",
    "Brouwershaven",
    "Bruchterveld",
    "Bruheze",
    "Bruse Boys",
    "BSC",
    "BSC '68",
    "BSC Unisson",
    "BSM sv",
    "BSV Limburgia/Kamerland",
    "BSVV",
    "Buinen",
    "Buinerveen",
    "Buitenboys sc.",
    "Buitenpost",
    "Buitenveldert sc.",
    "Bunde",
    "Bunnik '73",
    "Buren",
    "Buurse",
    "BVC '12",
    "BVC Bloemendaal",
    "BVCB",
    "BVV '31",
    "BVV'27",
    "BWO",
    "BZC / Zuiderpark",
    "BZC'13",
    "BZC'14",
    "BZS",
    "BZSV de Blauwwitters",
    "BZVV",
    "BZVV/Du Commerce",
    "Cabauw",
    "Cadzand",
    "Caesar",
    "Callantsoog",
    "Candia '66",
    "Capelle",
    "Casteren",
    "Castricum FC",
    "CDW",
    "CEC",
    "Centrum Boys",
    "CFM/Transito",
    "Chaam",
    "Charlois, sv",
    "CHC",
    "Chevremont",
    "CION",
    "Cito",
    "CJVV",
    "CKC",
    "Clinge",
    "Cluzona",
    "Cluzona '74",
    "Coal",
    "Cobu Boys",
    "Colijnsplaatse Boys",
    "Colmschate '33",
    "Columbia",
    "Con Zelo",
    "Concordia",
    "Concordia-W",
    "Constantia",
    "Conventus'03",
    "Corenos",
    "Corn Boys",
    "Counters (The)",
    "Creil-Bant",
    "Crescentia",
    "csv Apeldoorn",
    "CSV'28",
    "CSVC",
    "CSW",
    "CTO '70 vv",
    "CVC Reeuwijk",
    "CVO",
    "CVV Zwervers",
    "CVVO",
    "CWO",
    "d' Olde Veste'54",
    "Daalhof",
    "Daarlerveen",
    "Dalen",
    "Dalfsen",
    "Damacota",
    "DAVO",
    "DAW Schaijk",
    "DBGC",
    "DBN'22",
    "DBS",
    "DBSV",
    "DCG Rksv",
    "DCS",
    "DCV",
    "DDV '23",
    "De Alblas",
    "De Bataven",
    "De Blesse",
    "De Bocht'80",
    "De Esch",
    "De Fendert",
    "De Fivel",
    "De Gastronoom",
    "De Gastronoom Arnhem",
    "De Gazelle",
    "De Graafschap",
    "De Haantjes",
    "De Hazendans/ODV",
    "De Heracliden",
    "De Herauten",
    "De Hoven",
    "De Kabouter",
    "De Keelkampers/ Transpo Nuth",
    "De Kieviten",
    "De Kilo ZVV",
    "De Lauwers",
    "De Leeuw",
    "De Lenco's",
    "De Markiezaten",
    "De Meeuwen",
    "De Meipoort",
    "De Monnik",
    "De Noormannen",
    "De Paasberg",
    "De Raven",
    "De Schutters",
    "De Ster",
    "De Storm'22",
    "De Sweach",
    "De Tegenpartij",
    "De Treffer '16",
    "De Treffers",
    "De Tubanters 1897",
    "De Tukkers",
    "De Valk",
    "De Veluwse Boys",
    "De Walde",
    "De Walden",
    "De Weebosch",
    "De Weide",
    "De Wherevogels",
    "De Wilper Boys",
    "De Zuiderburen",
    "De Zwaluw",
    "De Zweef",
    "De Zwerver",
    "DEES",
    "Delden",
    "Delfia",
    "Delfstrahuizen",
    "Deltasport",
    "DEM (RKVV)",
    "Demi Sport",
    "Den Bommel",
    "Den Dam",
    "Den Dungen",
    "Den Ham",
    "Den Hoek",
    "DEO",
    "DES",
    "Des Adamsnood",
    "DES Swalmen",
    "DESK",
    "DESM",
    "DESS",
    "DESTO",
    "DESZ",
    "DETO",
    "Deurne",
    "DEV Doorn",
    "DEV-Arcen",
    "DEVO",
    "DEVO'58",
    "DFC",
    "DFO '20",
    "DGAC",
    "DHC",
    "DHL",
    "DHSC",
    "DHV",
    "DIA",
    "Die Haghe",
    "Diemen sv.",
    "Diepenheim",
    "Diepenveen",
    "Dierense Boys",
    "Dieze West",
    "Dijk De asv",
    "Dijkhuis (FC)",
    "Dilettant",
    "DIO '30",
    "DIO Groningen",
    "DIO sv.",
    "DIOS sv.",
    "DIOSA",
    "DIOZ",
    "Dirkshorn",
    "Dissel de",
    "DIVA'83",
    "DKB",
    "DKM z.v.v./ By The Way Reclame",
    "DOB",
    "DoCoS",
    "Dodewaard",
    "Doetinchem",
    "Dokkum",
    "Dommelen",
    "Domstad Majella",
    "Dongen",
    "DONK",
    "Donkerbroek",
    "DOS '37",
    "DOS Kampen",
    "DOS'63",
    "DOSC",
    "DOSKO",
    "DOSKO'32",
    "DOSL",
    "DOVO",
    "Drachten",
    "Drachtster Boys",
    "DRC",
    "Drechtstreek",
    "Drenkwaard",
    "Drenthina",
    "Drieborg",
    "Drienerlo",
    "DRL",
    "Drogeham",
    "DSC",
    "DSC'65",
    "DSE",
    "DSK",
    "DSO",
    "DSOV",
    "DSS",
    "DSS'14",
    "DSV",
    "DSV '61",
    "DSVD",
    "DSVP",
    "DSZ",
    "DTA Fortius asv",
    "DTC '07",
    "DTD",
    "DTS",
    "DTS '35 Ede",
    "Dubbeldam",
    "Duindorp sv",
    "Duinrand S",
    "Duiveland",
    "DUNO",
    "DUNO D",
    "Dussense Boys",
    "DVC",
    "DVC '26",
    "DVC Appingedam",
    "DVC Dedemsvaart",
    "DVC'59",
    "DVG",
    "DVO",
    "DVO '32",
    "DVO'60",
    "DVOL",
    "DVOV",
    "DVS",
    "DVS Haarlem",
    "DVS'33 Ermelo",
    "DVSA",
    "DVSG",
    "DVSU",
    "DVSV",
    "DVV",
    "DVV '09",
    "dvv Delft",
    "DVVA csv.",
    "DVVC",
    "DWB",
    "Dwingeloo",
    "DWO",
    "DwO'15",
    "DWOW",
    "DWP",
    "DWS afc",
    "DWS Oosterpoort",
    "DWSM",
    "DWZ",
    "Dynamo",
    "DZC '68",
    "DZC'09",
    "DZOH",
    "DZS",
    "DZV/H. vd Biggelaar en Zn.",
    "DZVV",
    "Eastermar",
    "EBC",
    "EBOH",
    "EBZV '85",
    "Echteld",
    "Edesche Boys",
    "EDN'56",
    "EDO (U)",
    "EDO hfc",
    "EDON",
    "EEC",
    "Eemboys",
    "Eemdijk",
    "Eemnes",
    "Eendracht '30",
    "Eendracht '82 sc.",
    "Eendracht Arnhem",
    "Eenrum",
    "Eerbeekse Boys",
    "Eext",
    "EFC",
    "EFC '58",
    "EFC PW 1885",
    "Egchel",
    "Egelantier Boys, de",
    "EGS'20",
    "EGVV",
    "EHC",
    "EHS'85",
    "Eijsden",
    "Eikenderveld",
    "Eindse Boys",
    "Elan",
    "Elbalta",
    "Eldenia",
    "ELI",
    "Elim",
    "Elinkwijk",
    "Elistha",
    "Elsendorp",
    "Elspeet",
    "Elsweide",
    "Eminent Boys",
    "EMK",
    "EMM",
    "EMM'21",
    "Emmen",
    "EMMS",
    "EMOS",
    "Emplina",
    "EMS",
    "Emst",
    "Engelbert",
    "Ens",
    "Enter",
    "Enter Vooruit",
    "Epe",
    "Epse",
    "Erasmus",
    "Erica'86",
    "Erix",
    "Erp",
    "ESA",
    "ESC",
    "Espel",
    "Essche Boys",
    "ESTO",
    "ESZVV Totelos",
    "Eteha",
    "Etten",
    "Etyfoor",
    "EVC",
    "EVV Echt",
    "EVVC",
    "EWC'46",
    "Exact",
    "Excellent",
    "Excelsior",
    "Excelsior '31",
    "Excelsior M.",
    "Excelsior Z.",
    "Excelsior'20",
    "Exo Drankens.",
    "Exstudiantes Amsterdam",
    "Exstudiantes Groningen",
    "Exstudiantes Utrecht",
    "Exstudiantes Zwolle",
    "EZC '84",
    "Ezinge",
    "EZV",
    "f.c. Lesley Boys",
    "F.C. Noort/Dubbele Schaar",
    "F.C. Ommen",
    "Faja Lobi KDS",
    "Farmsum",
    "FC 's-Gravenzande",
    "FC 't Centrum",
    "FC 't Hoekje/Toyota Oldenzaal",
    "FC Aalsmeer",
    "FC Aman",
    "FC Amboina",
    "FC Amsterdam",
    "FC Amsterdamse Bos",
    "FC Aramea",
    "FC Assen",
    "FC Axel",
    "FC Bemelen",
    "FC Bergeijk",
    "FC Bergh",
    "FC Berghuizen",
    "FC Birdaard",
    "FC Bordeauxboys",
    "FC Bordkrijt",
    "FC Brasil '77",
    "FC Burgum",
    "FC Coronaas",
    "FC Cranendonck",
    "FC Dauwendaele",
    "FC De Heiputters",
    "FC de Rakt",
    "FC de Westhoek'20",
    "FC Den Bosch",
    "FC Den Helder",
    "FC Dinxperlo",
    "FC Dordrecht",
    "FC Dordrecht Amateurs",
    "FC Driebergen",
    "FC Drunen",
    "FC Eibergen",
    "FC Eindhoven",
    "FC Eindhoven AV",
    "FC Emmen",
    "FC Engelen",
    "FC Flamingo",
    "FC Fochteloo",
    "FC Foppen",
    "FC Geleen-Zuid",
    "FC Gonemo",
    "FC Groningen",
    "FC Gulpen",
    "fc Harlingen",
    "FC Harmelen",
    "FC Hilversum",
    "FC Hoensbroek",
    "FC Horst",
    "FC IJsselmonde",
    "FC Jeugd",
    "FC Jugador",
    "FC Kerkrade-West",
    "FC Klazienaveen",
    "FC Kraggenburg",
    "FC Landgraaf",
    "FC LEO",
    "FC Lewenborg",
    "FC Lisse",
    "FC M.A.R.L.E.N.E",
    "FC Maasgouw",
    "FC Marktzicht",
    "FC Meppel",
    "FC Mergroate",
    "FC Moerstraten",
    "FC Oda",
    "FC Oegstgeest",
    "FC Oldemarkt",
    "FC Oudewater",
    "FC RDC",
    "FC RIA",
    "FC Right-Oh",
    "FC Roerdalen",
    "FC Schadewijk",
    "FC Sedap",
    "FC Skillz",
    "FC Surhústerfean",
    "FC Suryoye-Mediterraneo",
    "FC Ter Apel '96",
    "FC Tilburg",
    "FC Tinnegieter",
    "FC Trias",
    "FC Twente",
    "FC Twente/Heracles Academie",
    "FC Uden",
    "FC Uitgeest",
    "FC Ulu Spor",
    "FC Utrecht",
    "FC Vlotbrug",
    "FC Volendam",
    "FC Weesp",
    "FC Westland",
    "FC Winterswijk",
    "FC Wolvega",
    "FC Zaanstad",
    "FC Zoetermeer",
    "FC Zuidlaren",
    "FC Zutphen",
    "Fc. Grootegast",
    "FCB-HWD",
    "FCK/De Hommel",
    "FCV-Venlo",
    "FCV/Dorpscafé Willem II",
    "FCZSW/FC Zaanstreek",
    "Festilent",
    "Feyenoord",
    "FFS",
    "Fiducia",
    "FIOS",
    "FIT avv",
    "Fit Boys",
    "FJV'09",
    "Flakkee",
    "Flamingo s 64 (de)",
    "Fleringen",
    "Flevo",
    "Flevo Boys",
    "Floreant",
    "Foarut",
    "Focus '07",
    "Football Factory",
    "Foreholte",
    "Foresters",
    "Fortissimo",
    "Fortuna Sittard",
    "Fortuna Wormerveer sv",
    "Forum Sport",
    "Forza Almere",
    "Forza DGS",
    "Friese Boys",
    "Friesland",
    "Froombosch",
    "Full Speed",
    "Futsal Anouel Zutphen",
    "Futsal Bedum",
    "Futsal Buitenpost",
    "Futsal Dragten",
    "Futsal Heerenveen",
    "Futsal Marum",
    "Futsal Rotterdam",
    "Futsal Team Bergen op Zoom",
    "Futsal Winsum",
    "FVC",
    "FVV",
    "FZO",
    "G.S.F.V. Drs. Vijfje",
    "Gassel",
    "Gasselternijveen",
    "GAVC",
    "GDA",
    "GDC",
    "GDVV Martinistad",
    "Geel Wit",
    "Geel Wit '20 sv.",
    "Geel Zwart 30",
    "Geertruidse Boys",
    "Geinburgia sv.",
    "Geldrop",
    "Gemert",
    "Gendringen",
    "GEO",
    "George st",
    "Germaan/De Eland asv",
    "Germania",
    "Germanicus",
    "Gesta",
    "Gestel",
    "Geulsche Boys",
    "Geusselt Sport",
    "GeuzenMiddenmeer",
    "GFC",
    "GFC'33",
    "GHVV'13",
    "Gieten",
    "Gieterveen",
    "Giethoorn",
    "Gilze",
    "GJS",
    "GKC",
    "Glimmen",
    "Gloria UC",
    "GLZ Delfshaven, rvv",
    "Go Ahead Deventer",
    "Go Ahead Eagles",
    "Go Ahead Kampen",
    "Godlinze",
    "Goes",
    "Golden Oldies",
    "Golden Stars FC",
    "Gomos",
    "Gooi 't sc",
    "Gorecht",
    "Gorredijk",
    "Gorssel",
    "Gouda",
    "Gouderak",
    "GOZ",
    "GPC Vlissingen",
    "Graaf W II VAC",
    "Graauw",
    "Graftdijk",
    "Gramsbergen",
    "Grasshoppers",
    "Graveland 's",
    "GRC Groningen",
    "GRC-14",
    "Grenswachters",
    "Grijpskerk",
    "Groede",
    "Groen Geel",
    "Groen Wit '62",
    "Groene Ster",
    "Groene Ster Vlissingen",
    "Groeneweg",
    "Groenoord ZVV",
    "Groesbeekse Boys",
    "Grol",
    "Groningen",
    "Groninger Boys",
    "Groot-Ammers",
    "Groote Lindt",
    "Gruno",
    "GS'76",
    "GSAVV Forward",
    "GSBW",
    "GSC ESDO",
    "GSC/ODS",
    "GSV",
    "GSV '38",
    "GSV '63",
    "GSV'28",
    "GSVV",
    "GVA",
    "GVAV-Rapiditas",
    "GVB",
    "GVC",
    "GVV",
    "GVV Eilermark",
    "GVV'63",
    "GVVV",
    "GWVV",
    "GZV Watergras",
    "H.B.S.V.",
    "Haaften",
    "Haagse Hout",
    "Haaksbergen",
    "Haarle",
    "Haarlem-Kennemerland f.c.",
    "Haarsteeg",
    "Haddekik FC",
    "Haelen",
    "Halle",
    "Halsteren",
    "Handel",
    "Hansweertse Boys",
    "Hapert",
    "Hapse Boys",
    "Hardegarijp",
    "Hardenberg '85",
    "Hardinxveld",
    "Haren",
    "Harfsen",
    "Harkema Opeinde",
    "Harkemase Boys",
    "Harkstede",
    "Harskamp",
    "Haskerland",
    "Haslou",
    "Hatto Heim",
    "Hauwert 65",
    "Havelte",
    "HAVO",
    "Hazerswoudse Boys",
    "HBC",
    "HBC'09",
    "HBOK",
    "HBS",
    "HBSS",
    "HBV",
    "HC '03",
    "HCSC",
    "HDS",
    "HDV",
    "Hector",
    "Hedel",
    "Heemstede hfc",
    "Heer",
    "Heerde",
    "Heerenveense Boys",
    "Heerewaarden",
    "Heerjansdam",
    "Heertjes/Albino trouwringen",
    "Hees",
    "Heeswijk",
    "Heeten",
    "Hegelsom",
    "Heijen",
    "Heiligerlee",
    "Heinenoord",
    "Heino",
    "Heksenberg-NEC",
    "Helden",
    "Helios",
    "Hellevoetsluis",
    "Helmond Sport",
    "Helpman",
    "Helvoirt",
    "Hengelo",
    "Heracles Almelo",
    "Heracles Almelo Futsal",
    "Hercules",
    "Hercules Zaandam sc",
    "Herkingen '55",
    "Hermes DVS",
    "Herovina",
    "Herpinia",
    "Hertha",
    "Heukelum",
    "Heumen",
    "HFC '15",
    "HHC Hardenberg",
    "HHC'09",
    "HHCombi",
    "Hielpen",
    "Hierden",
    "Hillegersberg, vv",
    "Hillegom sv",
    "Hilvaria",
    "HJSC",
    "HKW'21",
    "HMC '17",
    "HMS",
    "HMSH",
    "HMVV",
    "HODO",
    "Hoeve Vooruit",
    "Hoevelaken",
    "Hoeven",
    "Hof van Brabant",
    "Hollandia",
    "Hollandia T",
    "Hollandscheveld",
    "Holten",
    "Holwerd",
    "Holwierde",
    "Holz ZVC",
    "Homenetmen Almelo",
    "Honderd%/Veldsink Adviesgroep",
    "Honselersdijk",
    "Hontenisse",
    "Hoofddorp s.v.",
    "Hoogeloon",
    "Hoogeveen",
    "Hoogezand",
    "Hoogland",
    "Hooglanderveen",
    "Hoonhorst",
    "Horn",
    "HOSV",
    "Houtigehage",
    "Houtwijk sv",
    "HOV/DJSCR",
    "HOVC",
    "Hovocubo",
    "HPSV",
    "HRC'14",
    "HS'88",
    "HSC",
    "HSC '21",
    "HSC'28",
    "HSSC'61",
    "HSV",
    "HSV Hoek",
    "HSV Sport 1889",
    "HSV'69",
    "HTC",
    "Hugo Boys",
    "Huizen",
    "Hulsel",
    "Hulshorst",
    "Hulsterloo",
    "Hulzense Boys",
    "Husky F.C.",
    "HV/Veerhuys",
    "HVC",
    "HVC'10",
    "HVCH",
    "HVV",
    "HVV Helmond",
    "HVV'24",
    "HVZ",
    "Hyenas FC",
    "HYS",
    "HZ'75",
    "HZV/Het Vennewater",
    "HZVV",
    "IASON",
    "Iduna",
    "IFC",
    "IJFC",
    "IJhorst",
    "IJmuiden vv.",
    "IJsselmeervogels",
    "IJsselstreek",
    "IJVC",
    "IJVV",
    "IJzendijke",
    "Ilpendam",
    "In de Bende'92/Bestrating van Delft",
    "Indri Vloeren",
    "Inside",
    "Inter Maluku Futsal Club",
    "Inter'80",
    "Internos",
    "Intravo",
    "Irene",
    "Irene'58",
    "Irnsum",
    "ISC",
    "Italian Boys",
    "IVO",
    "IVS",
    "IVV",
    "Jabeek ZVV/Decorstuc",
    "Jan van Arckel",
    "JCK/AISO",
    "JCLE",
    "JEKA",
    "Jisp",
    "Jistrum",
    "Jodan Boys, de",
    "Jogadores CF",
    "Jonathan",
    "Jong Ambon",
    "Jong Brabant",
    "Jong Hercules",
    "Jong Holland",
    "Jonge Kracht",
    "Jonge Spartaan, de",
    "Jonker Boys",
    "JOS Watergraafsmeer",
    "JSV Nieuwegein",
    "Jubbega",
    "Juliana '31",
    "Juliana '32",
    "Juliana Mill",
    "Juventa '12",
    "JVC",
    "JVC Cuijk",
    "JVOZ",
    "JVV",
    "Kaagvogels",
    "Kadoelen sv.",
    "Kagia",
    "Kakertse Boys",
    "Kamerik",
    "Kampen",
    "Kampong",
    "Kanja/'t Hart van Gastel",
    "Kapelle",
    "Katwijk",
    "Kawin",
    "KCVO",
    "KDO sv",
    "Keer",
    "Keijenburgse Boys",
    "Kennemers (de)",
    "Kerkwijk",
    "Kesteren",
    "Kethel Spaland, vv",
    "KFC",
    "KGB",
    "KHC",
    "Kids United",
    "Kismet",
    "Klein Dochteren",
    "Kleine Sluis",
    "Kloetinge",
    "Kloosterburen",
    "Kloosterhaar",
    "Klundert",
    "KMD",
    "Knegselse Boys",
    "Knollendam",
    "Kockengen",
    "Koedijk",
    "Koewacht",
    "Kogelvangers",
    "Kolkrijst de ZV",
    "Kollum",
    "Kolping Boys",
    "Kolping-Dynamo",
    "Koningslust",
    "Koninklijke HFC",
    "Koninklijke UD",
    "Koog (de)",
    "Kortenhoef'94",
    "KOSC",
    "Kosterman",
    "Koudekerk",
    "Kozakken Boys",
    "Krabbendijke",
    "Krayenhoff",
    "KRC",
    "Kreileroord",
    "Krim (de)",
    "Kronenberg",
    "Kroon De",
    "Kruiningen",
    "KSC",
    "KSD-Marine",
    "KSV",
    "Kuinre",
    "KVC Oranje",
    "KVVA",
    "Kwadijk",
    "Kwiek",
    "Kwiek 78",
    "La Première",
    "La Rondine fv",
    "Laakkwartier",
    "LAC Frisia 1883",
    "Langeberg",
    "Langeveen",
    "Langezwaag",
    "Langweer",
    "Laren 99 sv",
    "Laura/Hopel Combinatie",
    "Lawa Mena Twello",
    "Leerdam Sport'55",
    "Leeuwarder Zwaluwen",
    "Legmeervogels",
    "Lekkerkerk",
    "Lekvogels",
    "Lelystad '67",
    "Lemele",
    "Lemelerveld",
    "Lemmer vv",
    "LEO (Loon)",
    "Leones",
    "Leonidas - W",
    "Leovardia",
    "Lepelstraatse Boys",
    "Leunen",
    "Leunen ZVV/ClaasSon-Fransol",
    "Leveroy",
    "Lewedorpse Boys",
    "LFC",
    "Libertas",
    "Lienden",
    "Lierop",
    "Liessel",
    "Limmen",
    "Linne",
    "Linschoten",
    "Lions'66",
    "LMO",
    "Lochuizen",
    "Loenermark",
    "Longa '30",
    "Loo",
    "Loosdrecht",
    "Loosduinen",
    "Lopik",
    "LRC",
    "LSC 1890",
    "LSV",
    "LSVV",
    "LSVV 70",
    "LTC",
    "Luctor Et Emergo",
    "Luctor Heinkenszand",
    "Lugdunum",
    "Lunteren",
    "Lycurgus",
    "LYRA",
    "LZV/Kuypers Hair & Wellness",
    "LZVV/MSML.NL",
    "LZVV/Sport 2000",
    "M.S.C.",
    "Maarheeze",
    "Maarssen",
    "Maasdijk",
    "Maaskantse Boys",
    "Maccabi",
    "Madereros C.F.",
    "Madese Boys",
    "Madjoe",
    "Maense, FC",
    "Makkum",
    "Maliskamp",
    "Mamio",
    "Manderveen",
    "Mantje",
    "Marathon/Miniware",
    "Margriet",
    "Mariahout",
    "Marienberg",
    "Marienheem",
    "Markelo",
    "Marken sv.",
    "Marrum",
    "Marum",
    "Marvilde",
    "MASV",
    "Mazzel Stars",
    "MBC'13",
    "MEC",
    "MEC'07",
    "Medemblik FC",
    "Meeden",
    "Meedhuizen",
    "Meer De sv.",
    "Meerburg",
    "Meern De",
    "Meervogels 31",
    "Meeuwenplaat",
    "Meiteam, zvv",
    "Melderslo",
    "Melissant",
    "Menos",
    "Merefeldia",
    "Merino's de",
    "Merselo",
    "Meteoor De sv.",
    "Meterik",
    "METO",
    "MGT Jongeren zvv",
    "Middelstum",
    "Mierlo Hout",
    "Mifano",
    "Mildam",
    "Milheezer Boys",
    "Minnertsga",
    "MKV'29",
    "MMC Weert",
    "MMO",
    "MOC",
    "MOC'17",
    "Moerkapelle",
    "Moerse Boys",
    "Molenschot",
    "Monnickendam",
    "Montfoort S.V.'19",
    "Moordrecht",
    "Morado CF",
    "MOSA'14",
    "MOVV",
    "MSV '71",
    "msv De Betrokken Spartaan",
    "Muiden sc.",
    "Muiderberg sc.",
    "Mulier",
    "Multicult",
    "Muntendam",
    "Musketiers",
    "Musselkanaal",
    "MVC",
    "MVC'19",
    "MVV",
    "MVV '27",
    "MVV '29",
    "MVV '69",
    "MVV'58",
    "MZC'11",
    "MZV/De Heere van Meijel",
    "MZVC",
    "N.E.C.",
    "N.E.C. (voetbalacademie)",
    "Naaldwijk",
    "NAC Breda",
    "Nagele",
    "Nautilus afc",
    "NBSVV",
    "NEC",
    "NEC Delfzijl",
    "Nederhorst vv",
    "Nederwetten",
    "Neerbeek",
    "Neerbeek RKVV",
    "Neerkandia",
    "Neerlandia'31",
    "Nemelaer",
    "NEO",
    "NEO'25",
    "Neptunus-Schiebroek",
    "Netersel",
    "NFC",
    "Nicator",
    "Nicolaas Boys",
    "Niekerk",
    "Nieuw Balinge",
    "Nieuw Buinen",
    "Nieuw Lekkerland",
    "Nieuw Roden",
    "Nieuw Sloten sv",
    "Nieuw Utrecht SV",
    "Nieuw West United SV",
    "Nieuw Woensel",
    "Nieuwdorp",
    "Nieuwe Niedorp",
    "Nieuwenhoorn",
    "Nieuwerkerk",
    "Nieuweschans",
    "Nieuweschoot",
    "Nieuwkoop",
    "Nieuwkuijk",
    "Nieuwland",
    "Nieuwland ASC",
    "Nieuwleusen SV",
    "Nieuwolda",
    "Niftrik",
    "Nijmegen",
    "Nijnsel",
    "NiTA",
    "Nivo Sparta",
    "NKVV",
    "NLC'03",
    "NOAD'32",
    "NOAD'67",
    "NOCKralingen",
    "NOK",
    "Nooit Gedacht",
    "Noord Veluwe Boys",
    "Noordbergum",
    "Noordhoek",
    "Noordpool-U.F.C.",
    "Noordscheschut",
    "Noordster",
    "Noordwijk",
    "Noordwolde",
    "Nootdorp",
    "NSC Nijkerk",
    "NSV",
    "NSV'46",
    "NSVV",
    "NSVV FC Kunde",
    "NTVV",
    "NTW",
    "Nulandia",
    "Nunspeet",
    "NVC",
    "NVS",
    "NWC",
    "NWVV",
    "O.K.S.V.",
    "OACN-Boys",
    "OBW",
    "ODB",
    "ODC",
    "Odijk",
    "Odiliapeel/Braks Groep",
    "Odin",
    "ODIN 59",
    "ODIO",
    "ODV",
    "Odysseus '91",
    "Oeken",
    "Oene",
    "Oerterp",
    "Oeverzwaluwen",
    "OFB",
    "OFC",
    "OHVV",
    "Oirschot Vooruit",
    "OJC ROSMALEN",
    "OKVC",
    "Old Forward",
    "Old Stars 77",
    "Oldambtster Boys",
    "Oldeboorn",
    "Oldeholtpade",
    "Oldenzaal",
    "Oliveo",
    "Olympia",
    "Olympia '25",
    "Olympia Boys",
    "Olympia Haarlem",
    "Olympia'18",
    "Olympia'28",
    "Olympia'60",
    "Omlandia",
    "Ommoord, sv",
    "Omni HSC",
    "ONA",
    "ONA '53",
    "ONB",
    "Onderdendam",
    "ONDO",
    "ONI",
    "Only Friends",
    "ONR",
    "Ons Genoegen",
    "ONS Sneek",
    "Onstwedder Boys",
    "ONT",
    "ONT/CHZ",
    "Onze Gezellen",
    "Oostburg",
    "Oosterend",
    "Oostergo",
    "Oosterhout",
    "Oosterlittens",
    "Oosterparkers",
    "Oosthuizen",
    "Ophemert",
    "Opperdoes",
    "Oranje - Wit",
    "Oranje Blauw",
    "Oranje Blauw'15",
    "Oranje Boys",
    "Oranje Nassau",
    "Oranje Wit",
    "Oranje Zwart",
    "Oranje-Blauw'14",
    "Orion",
    "Os Lusitanos",
    "OSC",
    "OSM '75",
    "OSO",
    "OSV",
    "OSVV 040",
    "Oud-Beijerland",
    "Oudega",
    "Oudehaske",
    "Ouderkerk sv.",
    "Oudesluis",
    "Ouwe Schoen",
    "Ouwe Syl",
    "OVC '85",
    "OVC'26",
    "OVCS",
    "Overamstel sc.",
    "Overasseltse Boys",
    "Overbos sv.",
    "Overmaas",
    "OVV",
    "OVV'67",
    "OWIOS",
    "OZV Obdam",
    "OZW",
    "Palm",
    "Panningen",
    "Parkstad SV",
    "Partij",
    "Passart-VKC",
    "Patria",
    "Patrijzen",
    "Pax",
    "PCP",
    "PEC Zwolle",
    "Peize",
    "Pekelder Boys",
    "Pelikaan",
    "Pelikaan S",
    "Pellor fc/DHS security",
    "Perkouw",
    "Pernis",
    "Pesse",
    "Petten",
    "Peursum",
    "PGS/VOGEL",
    "PH",
    "Phenix",
    "Philippine",
    "PJC",
    "PKC'83",
    "PKC'85",
    "Plank de",
    "Plein83",
    "Poolster",
    "Posthoorn de",
    "Potetos",
    "PPSC",
    "Pretoria R., fc",
    "Prins Bernhard",
    "Prinsenland",
    "Prinses Irene",
    "Prinsland",
    "Protos",
    "PSV",
    "Purmerend",
    "Purmerland",
    "Purmersteijn",
    "Pusphaira",
    "PVC",
    "PVCV",
    "Quick",
    "Quick '20",
    "Quick 1888",
    "Quick Boys",
    "Quick Steps",
    "Quintus",
    "QVC",
    "R.K.S.V. Groen Wit",
    "Raalte",
    "Raamsdonk",
    "Rab Bouwmaterialen",
    "Rabobank",
    "RAP sv.",
    "Raptim",
    "RAS",
    "RBC",
    "RCD",
    "RCH",
    "RCL",
    "RCS",
    "RCZ",
    "RDM",
    "Read Swart",
    "Real Camaradas",
    "Real Lunet",
    "Real Sranang sv.",
    "Reiger Boys",
    "REMO",
    "Renado",
    "Renswoude",
    "RESIA",
    "Reunie",
    "Reusel Sport/CoTrans",
    "Reutum",
    "Reuver",
    "REUZ",
    "RFC",
    "Rhode/VSB",
    "Rhoon",
    "Ria W",
    "Riel",
    "Riethoven",
    "Rietmolen",
    "Rigtersbleek",
    "Rijen",
    "Rijneveen, rsv",
    "Rijnmond Hoogvliet Sport",
    "Rijnsburgse Boys",
    "Rijnstreek",
    "Rijnvogels, fc",
    "Rijp (de)",
    "Rijperkerk",
    "Rijsoord",
    "Rillandia",
    "Rimboe",
    "Rimburg",
    "RIOS'31",
    "Rivierwijkers",
    "RJO Brabant United",
    "RK Deso",
    "RKASV",
    "RKAVC",
    "RKAVIC",
    "RKAVV",
    "RKC Waalwijk",
    "RKDEO",
    "RKDES",
    "RKDSO",
    "RKDSV",
    "RKDVC",
    "RKEDO",
    "RKFC Lindenheuvel-Heidebloem Comb.",
    "RKGSV",
    "RKHBS",
    "RKHSV",
    "RKHVV",
    "RKIVV",
    "RKKSV",
    "RKMSV",
    "RKMVC",
    "RKO",
    "RKPSC",
    "RKSV Boerdonk",
    "RKSV Bornerbroek",
    "RKSV Driel",
    "RKSV Heeze",
    "RKSV Minor",
    "RKSV Nuenen",
    "RKSVB",
    "RKSVN",
    "RKSVO",
    "RKSVV",
    "RKTSV",
    "RKTVC",
    "RKUVC",
    "RKVB",
    "RKVSC",
    "RKVV Keldonk",
    "RKVVM",
    "RKVVO",
    "RKZVC",
    "ROAC",
    "Robur et Velocitas",
    "Rockanje",
    "RODA '23 Rksv",
    "RODA '28",
    "Roda '46",
    "Roda Boys/Bommelerwaard",
    "Roda JC",
    "Roden",
    "Rohda '76",
    "Rohda Raalte",
    "Rolder Boys",
    "Rood Groen LVC'01",
    "Rood Wit",
    "Rood Wit V",
    "Rood Wit ZVV",
    "Rood Wit'62",
    "Rood Wit'67/Plus",
    "Rood Zwart",
    "Rood Zwart Baflo",
    "Rood-Wit '58",
    "Rood-Wit W",
    "Roodenburg",
    "Rooie Lijn de",
    "Roosendaal",
    "Roosteren",
    "Ropta Boys",
    "Roswinkel Sp.",
    "Rotterdam United",
    "Rotterdamse Studenten",
    "Rottevalle",
    "Rozenburg",
    "RPC",
    "RSC",
    "RSV",
    "RTC 72",
    "Ruif (de)",
    "Ruinen",
    "Ruinerwold",
    "Rust Roest",
    "Ruurlo",
    "Ruwaard",
    "RVC '33",
    "RVC Celeritas",
    "RVU",
    "RVV Overschie",
    "RVVH",
    "RWB",
    "RWF",
    "s Heer Arendskerke",
    "s Heerenbroek",
    "s-Gravendeel vv",
    "S.C. Stiens",
    "S.F. Deinum",
    "S.V. Argo",
    "S.V. Brandevoort",
    "S.V. Den Hoorn",
    "s.v. Enkhuizen",
    "S.V. Grashoek",
    "S.V. Haulerwijk",
    "S.V. Heythuysen",
    "S.V. Hoogersmilde",
    "S.V. Houten",
    "S.V. Kickers '69",
    "S.V. Kilder",
    "s.v. Laar",
    "S.V. Losser",
    "S.V. Lottum",
    "S.V. Oosterwolde",
    "S.V. Oostrum",
    "s.v. Rijssen",
    "S.V. Roggel",
    "S.V. Tongelre",
    "S.V. Utrecht United",
    "S.V. Vasse",
    "S.V. Wieringerwaard",
    "Saasveldia",
    "SAB",
    "Saenden",
    "Saestum",
    "Salao'85",
    "Sallandia",
    "Sambeek",
    "Sanderbout",
    "Santos FC",
    "Sarto",
    "SBC",
    "SC 't Zand",
    "SC Angelslo",
    "SC Balkbrug",
    "SC Bemmel",
    "SC Berlikum",
    "SC Bolsward",
    "SC Boornbergum'80",
    "SC Cambuur Leeuwarden",
    "SC Doesburg",
    "SC EDS",
    "SC Elshout",
    "SC Emma",
    "SC Emmeloord",
    "SC Enschede",
    "SC Erica",
    "SC Everstein",
    "SC Feyenoord",
    "SC Franeker",
    "SC Gastel",
    "SC Genemuiden",
    "sc Heerenveen",
    "SC Joure",
    "SC Klarenbeek",
    "SC Kootstertille",
    "SC Kruisland",
    "SC Loppersum",
    "SC Lutten",
    "SC Meddo",
    "SC Millingen",
    "SC Nowruz",
    "SC Rheden",
    "SC Rijnland",
    "SC Rouveen",
    "SC Scheemda",
    "SC Stadspark",
    "SC Stavenisse",
    "SC Twijzel",
    "SC Valburg",
    "SC Veenwouden",
    "SC Welberg",
    "Sc Westervoort",
    "SC Zierikzee",
    "Sc. Overwetering",
    "Scagha 66",
    "SCD '33",
    "SCE",
    "SCG",
    "SCG'18",
    "SCH '44",
    "Schagen United",
    "Schalkwijk",
    "Scharn",
    "Scharnegoutum'70",
    "Scheerwolde",
    "Schelluinen",
    "Scherpenzeel",
    "Scheveningen",
    "Schijf",
    "Schijndel/DE WIT",
    "Schimmert",
    "Schinveld",
    "Schipluiden",
    "Schollevers de asvv",
    "Schoondijke",
    "Schoonebeek",
    "Schoonhoven",
    "Schoten vv.",
    "SCMH",
    "SCN",
    "SCO",
    "SCO '63",
    "SCP",
    "SCPB'22",
    "SCR",
    "SCW",
    "SCW '23",
    "SCZ",
    "SDC '12",
    "SDC Putten",
    "SDO",
    "SDO'39",
    "SDO'63",
    "SDOB",
    "SDOL",
    "SDOO",
    "SDOUC",
    "SDS",
    "SDS' 55",
    "SDV",
    "SDV Barneveld",
    "SDW",
    "SDZ",
    "SDZZ",
    "SEC",
    "SEH",
    "Sekura",
    "Sellingen",
    "Semper Altius",
    "SEOLTO",
    "SEP",
    "Serooskerke",
    "SES",
    "SETA",
    "SEV",
    "Sevenstars",
    "SEW",
    "SGO",
    "SGV",
    "SHE",
    "SHO",
    "Sibbe",
    "Siddeburen",
    "Simonshaven",
    "Sint Boys (St.Maarten)",
    "Sint Joris",
    "SIOL",
    "SIOS",
    "Sittard",
    "Siveo '60",
    "SJC",
    "SJO 't Hogeland",
    "SJO Almen - Harfsen",
    "SJO America-Meterik",
    "SJO Arum/Stormvogels '64",
    "SJO AVC/Tzummarum",
    "SJO AZSV - AD '69",
    "SJO BBC",
    "SJO BDC",
    "SJO BMR/SNC'14",
    "SJO Broekster Walden",
    "SJO Casteren-Netersel",
    "SJO Coendersborg",
    "SJO Dalen/DSC",
    "SJO DBF",
    "SJO De Marne",
    "SJO de Westkust",
    "SJO DEES/Vessem",
    "SJO DFC",
    "SJO Diosa-Niftrik",
    "SJO Ens/Kraggenburg",
    "SJO ESB '19",
    "SJO FC Coevorden",
    "SJO FC Dalfsen",
    "SJO FC Meerdijk",
    "SJO GEO/SVW",
    "SJO HBDZ'23",
    "SJO Heuvelland",
    "SJO HH'97",
    "SJO HPBC'23",
    "SJO HSC - Buurse",
    "SJO HSVC'20",
    "SJO HUC'21",
    "SJO Juventud",
    "SJO JVZ",
    "SJO Krijtland",
    "SJO Lebo/SVN/Patrijzen",
    "SJO MEC/Bredevoort",
    "SJO NKvvProtos",
    "SJO NWVV/Titan",
    "SJO NWWC",
    "SJO OFW",
    "SJO Oudega/HJSC/Heeg",
    "SJO PEC'20",
    "SJO Pekela 2000",
    "SJO PHC23",
    "SJO Rijnland - Gelders Eiland",
    "SJO RSC",
    "SJO RVLC",
    "SJO Serooskerke/Veere",
    "SJO STEO",
    "SJO SVHV",
    "SJO SWB",
    "SJO Triville",
    "SJO UBC",
    "SJO UNO'21",
    "SJO VCG/SCN",
    "SJO VCHW",
    "SJO VIDOSA",
    "SJO Warga WWS",
    "SJO WNBC '09",
    "SJO WTTC",
    "SJO WZV United",
    "SJO ZVC '14",
    "SJO ZWO'23",
    "SJO/SSA MSH Maasduinen",
    "SJO/SSA VESTA'19",
    "SJS",
    "SJVV",
    "SJZ",
    "SKNWK",
    "SKV",
    "Sleat",
    "Sleeuwijk",
    "Slekker Boys",
    "Sliedrecht",
    "Slikkerveer",
    "Sloterdijk avv.",
    "Sluis",
    "Smerdiek",
    "Smilde'94",
    "SML",
    "SMR",
    "SMVC Fair Play",
    "SNA",
    "SNC'14",
    "SNS",
    "Soccer Boys",
    "Sociaal Cultureel en Sportvereniging ZV3",
    "Soest SO",
    "SP Daarle",
    "SP Eefde",
    "SP Haarlo",
    "SP Lochem",
    "Sp Rekken",
    "SP Teuge",
    "Sp. Neede",
    "Spaarnwoude sv.",
    "Spakenburg",
    "Sparta (AV)",
    "Sparta 67",
    "Sparta E.",
    "Sparta Nijkerk",
    "Sparta Rotterdam",
    "Sparta'18",
    "Sparta'25",
    "Sparta'30",
    "Spartaan'20",
    "Spaubeek",
    "Spcl. Brummen",
    "Spero",
    "Spijkenisse",
    "Spil 't",
    "Spirit",
    "Spirit 30 (SC)",
    "Spoordonkse Boys",
    "Sport Vereent",
    "Sportclub Deventer",
    "Sportclub Excelsior",
    "Sportclub Groessen",
    "Sportclub Irene",
    "Sportclub Jekerdal",
    "Sportclub Leeuwen",
    "Sportclub Makkinga",
    "Sportclub Mergelland '03",
    "Sportclub Monster",
    "Sportclub Olympic",
    "Sportclub Overdinkel",
    "Sportclub Rijssen",
    "Sportclub Susteren",
    "Sportclub Urban Talent",
    "Sportclub'25",
    "Sportief",
    "Sporting '70",
    "Sporting Almere",
    "Sporting Andijk",
    "Sporting Emmen",
    "Sporting H.A.C.",
    "Sporting Heerlen/Kracht Nutrition",
    "Sporting Krommenie",
    "Sporting Leiden",
    "Sporting Martinus",
    "Sporting S",
    "Sporting S.T.",
    "Sporting Sittard'13",
    "Sportlust '46",
    "Sportlust Glanerbrug",
    "Sportlust Vroomshoop",
    "Sprinkhanen",
    "Sprundel",
    "SPS",
    "Spui",
    "SPV",
    "SPV '81",
    "SPW",
    "SSA FC Skillz Wateringse Veld",
    "SSA HBC'22",
    "SSA Oosterstreek Zandhuizen",
    "SSA S.V. Lottum - GFC'33",
    "SSA Wisch",
    "SSA-SJO VV Egmond",
    "SSA/SJO BRC’24",
    "SSA/SJO Fortuna Be Quick",
    "SSA/SJO Ratti - Socii",
    "SSA/SJO VITA",
    "SSC'55",
    "SSE",
    "SSS",
    "SSS'18",
    "SSS'68",
    "SSV",
    "SSV'65",
    "SSW",
    "St Joost",
    "St. Jacob",
    "St.Annaparochie",
    "Stadskanaal",
    "Stanga",
    "Staphorst",
    "STB",
    "Stedebroec s.v.",
    "SteDoCo",
    "Stedum",
    "Steeds Hooger",
    "Steen",
    "Steenbergen",
    "Steensel",
    "Steenwijker Boys",
    "Steenwijkerwold",
    "Stellendam",
    "Ster, De",
    "Sterksel",
    "Sterrenwijk",
    "Stevensweert",
    "Stevo",
    "Stiphout Vooruit",
    "Stolwijk",
    "Stompwijk'92",
    "Stormvogels ijvv",
    "Strandvogels",
    "Streefkerk",
    "Strijen",
    "Stroe",
    "Suameer",
    "Suawoude",
    "Succes",
    "Supportersvereniging PSV",
    "Susterse Boys",
    "SV Babberich",
    "SV Basteom",
    "SV Bedum",
    "SV BLC",
    "SV Brunssum",
    "SV Budel",
    "SV Capelle",
    "SV CCW '16",
    "SV De Braak",
    "SV De Lutte",
    "sv DFS",
    "SV DRC 2012",
    "SV DWSH'18",
    "SV Gelders Eiland",
    "SV Geuldal",
    "SV Hattrick Haarlem",
    "SV Helenaveen Griendtsveen",
    "SV Hulsberg",
    "SV Leidschenveen",
    "sv Loil",
    "sv Madestein",
    "SV Meerkerk",
    "SV Meerssen",
    "SV Milsbeek",
    "SV Mussel",
    "SV Nieuw Heeten",
    "SV Noordeloos",
    "sv O.S.S. 1920",
    "SV Olland",
    "sv Olyphia",
    "sv Orderbos",
    "SV Otterlo",
    "SV Overvecht/De Dreef",
    "sv Parkhout",
    "sv Poortugaal",
    "SV Putten",
    "sv Reaal Dronten",
    "SV Reeshof",
    "SV Rood-Wit Zaanstad",
    "SV Schalkhaar",
    "SV Simpelveld",
    "SV Someren",
    "SV Spartanen",
    "SV TOP",
    "SV Twello",
    "SV Unitas'59",
    "SV United",
    "SV Vaassen",
    "SV Valkenswaard",
    "SV Vechtzoom",
    "SV Venray",
    "SV Waalstad",
    "SV Warmunda,",
    "Sv Zalk",
    "sv Zuid West DH",
    "SV Zwart - Wit '19",
    "SV Zwolle",
    "SVA",
    "SVB Besiktas",
    "SVBC",
    "SVBO",
    "SVBS'77",
    "SVBV",
    "SVC",
    "SVC 2000",
    "SVC'08",
    "SVDB",
    "SVEB",
    "SVF",
    "SVG",
    "SVGG",
    "SVH",
    "SVH'39",
    "SVHA",
    "SVI",
    "SVIJ",
    "SVL",
    "SVM",
    "SVM FUTSAL",
    "SVME",
    "SVMM",
    "SVN'69",
    "SVO Buytenpark",
    "SVO DCS/OBW",
    "SVO De Gaard",
    "SVO De Zweef/ DES",
    "SVO Deventer'21",
    "SVO DKC",
    '"SVO DVC""16"',
    "SVO EBC/Delfstrahuizen",
    "SVO FC Maas en Waal",
    "SVO HZM'22",
    "SVO Krommerijnstreek",
    "SVO MDOC",
    "SVO RDM",
    "SVO RVVH/Sparta RV&AV",
    "SVO Twickel",
    "SVO VIVA'23",
    "SVO VV Pinkenberg",
    "SVO Wierden",
    "SVO/DKS'17",
    "SVO/SJO Vianen - HBV",
    "SVOC'01",
    "SVOD'22",
    "SVP",
    "SVS",
    "SVS'65",
    "SVSH",
    "SVSOS",
    "SVSSS",
    "SVV",
    "SVV '56",
    "SVV '91",
    "SVV'04",
    "SVVH",
    "SVVN",
    "SVW",
    "SVW 27",
    "SVZ",
    "SVZW",
    "Swift",
    "Swift '64",
    "Swift Boys",
    "SZV de Toerist",
    "t Brökske/Eetcafé DKW",
    "t Goy",
    "t Harde",
    "t Peeske",
    "TABA afc",
    "TAC'90",
    "Taurus",
    "TAVV",
    "Team Alkmaar/Sportstars",
    "Team Vos",
    "TEC",
    "Teisterbanders",
    "Telstar 1963",
    "TEO",
    "Ter Leede",
    "Terborg",
    "Terheijden",
    "Terlo",
    "Ternaard",
    "Terneuzen",
    "Terneuzense Boys",
    "Terrasvogels",
    "Terschelling",
    "Terschuurse Boys",
    "Terwolde",
    "Texel 94",
    "Teylingen",
    "TFS",
    "The Gunners",
    "The Knickerbockers",
    "The Talent Academy",
    "Theater (het)/Drukkerij Krijgsman",
    "Theole",
    "Tholense Boys",
    "Thor",
    "THOS",
    "Tiendeveen",
    "Tigers Roermond",
    "Tijnje",
    "Titan",
    "Tivoli",
    "TKA",
    "TLC",
    "TOB",
    "TOB Rksv",
    "TOG",
    "TOGB",
    "Tollebeek",
    "Tonego",
    "Toofan",
    "TOP Oss",
    "TOP'63",
    "Tornado",
    "TOS-Actief",
    "TOSS",
    "TOV",
    "Toxandria",
    "TPO",
    "TransvaliaZW",
    "Trekvogels",
    "Tricht",
    "Trinitas",
    "Triple ThreaT/Onside Futsal",
    "TSC",
    "TSC '04",
    "TSV Gudok",
    "TSV Twisk",
    "TSVV Merlijn",
    "TT/Goodnight",
    "Tubantia",
    "Tuldania",
    "Tunas",
    "Turkse Kracht",
    "Tutor",
    "TVC '28",
    "TVC Breda",
    "TVO",
    "TVV",
    "Twedo",
    "Tynaarlo",
    "TZR Fermonia Boys",
    "Tzum",
    "Tzummarum",
    "UBA Sport z.v.v.",
    "Uchta",
    "UD W.",
    "UDI",
    "UDI'19/CSU",
    "Udiros",
    "Uffelte",
    "UHC",
    "Ulftse Boys",
    "UNA",
    "UNI VV",
    "UNI ZVV",
    "Unicum",
    "Union",
    "Unitas",
    "Unitas '28",
    "Unitas'30",
    "United sv/DAVO",
    "Uno Animo",
    "UNO vv",
    "UOW '02",
    "Urk",
    "Urmondia",
    "Ursem",
    "Usquert",
    "USV",
    "USVF",
    "UVS",
    "UVV",
    "UVV'40",
    "V en L",
    "V en V'68",
    "V.V. Achates",
    "V.V. Barneveld",
    "V.V. Bergen",
    "v.v. Dieren",
    "v.v. Diever-Wapse",
    "v.v. Dronrijp",
    "V.V. Heeg",
    "V.V. Heerenveen",
    "v.v. Hekelingen",
    "v.v. Hellendoorn",
    "V.V. Holthees-Smakt",
    "V.V. Schaesberg",
    "v.v. Twenthe",
    "v.v. Veenendaal",
    "V.V. Westerkwartier",
    "Vaesrade",
    "VAKO",
    "Valken de",
    "Valken'68",
    "Valleivogels",
    "Valthermond",
    "Van Nispen",
    "Varsseveld",
    "VC Trynwalden",
    "VC Vlissingen",
    "VCA",
    "VCB",
    "VCG",
    "VCK",
    "VCO",
    "VCR",
    "VCS",
    "VCW",
    "VDC/Malelions",
    "VDL",
    "VDZ",
    "Vecht de",
    "Vedette zvv / De Remise",
    "Veelerveen",
    "Veendam 1894",
    "Veenhuizen",
    "Veensche Boys",
    "Veere",
    "Veldhoek",
    "VELO",
    "Velocitas",
    "Velocitas 1897",
    "Velsen Rkvv",
    "Velsenoord FC",
    "Veluwezoom",
    "Venhorst",
    "Venlosche Boys",
    "VENO",
    "Ventura",
    "VEP",
    "Verburch",
    "Veritas",
    "Vesdo",
    "Vessem",
    "VEV",
    "VEV'67",
    "VEVO",
    "VEW",
    "VFC",
    "VHK",
    "VHL",
    "Vianen",
    "Vianen Vooruit",
    "Victoria",
    "Victoria '25",
    "Victoria '28",
    "Victoria Boys",
    "Victoria O",
    "Victoria'03",
    "Victoria'04",
    "Vierhouten '82",
    "Vierpolders",
    "Vijlen",
    "Vilsteren",
    "VIOD",
    "Viola",
    "VIOS (O)",
    "VIOS B.",
    "VIOS V.",
    "VIOS-W",
    "VIOS'38",
    "VIOS'79 zvv",
    "Virtus",
    "Vitesse",
    "Vitesse 22",
    "Vitesse Delft",
    "Vitesse'08",
    "Vitesse'63",
    "Vivoo",
    "VKW",
    "Vliegdorp 't vv",
    "Vlielandse Sport Vereniging '31",
    "Vlijmense Boys",
    "Vlug en Vaardig cvv.",
    "VMC",
    "VNI",
    "VNS United",
    "VOAB",
    "VOB",
    "VOC",
    "Voerendaal",
    "Vogelenzang sv.",
    "Vogelwaarde",
    "Vogido",
    "Volendam (rkav)",
    "Volharding",
    "Volkel",
    "Voorschoten`97",
    "Voorst",
    "Voorwaarts",
    "Voorwaarts T",
    "Voorwaarts V.",
    "VOP",
    "Vorden",
    "Vorstenbossche Boys",
    "Vosmeer",
    "Vosta",
    "VOW",
    "VRC",
    "Vredenburch",
    "Vrederust",
    "Vriendenschaar",
    "Vrone",
    "Vroomshoopse Boys",
    "VSC",
    "VSCO '61",
    "VSV",
    "VSW",
    "VUC",
    "Vuren",
    "VV ALFA SPORT",
    "VV Baarlo",
    "vv Blauw Rood'20",
    "VV Born",
    "vv Dijk en Waard",
    "vv Ede/Victoria",
    "vv Ewijk",
    "VV FSG",
    "vv Gersloot",
    "VV Haastrecht",
    "VV HEBES",
    "VV Hellas",
    "VV Kessel",
    "VV Lettele",
    "vv Maastricht West",
    "vv Montferland",
    "vv Nijland",
    "VV Opende",
    "VV Papendrecht",
    "VV Sleen",
    "vv Smitshoek",
    "VV Sneek Wit Zwart",
    "vv Stânfries",
    "vv Steenwijk",
    "vv Sweel",
    "VV Trinitas Oisterwijk",
    "vv Winsum",
    "vv Zeester",
    "vv Zwanenburg",
    "VV Zwentibold",
    "VVA Achterberg",
    "VVA/Spartaan",
    "VVAC",
    "VVAK",
    "VVC fc",
    "VVC'68",
    "VVG '25",
    "VVGA",
    "VVGZ",
    "VVH/Velserbroek",
    "VVI",
    "VVIJ",
    "VVJ",
    "VVK",
    "VVO",
    "VVOG Harderwijk",
    "VVOP",
    "VVOR",
    "VVR",
    "VVS",
    "VVS 46",
    "VVSB",
    "VVSV '09",
    "VVT",
    "VVU Ardahanspor",
    "VVV-Venlo",
    "VVV-Venlo/Helmond Sport",
    "VVV'03",
    "vvVOS",
    "VVW",
    "VVZ '49",
    "VVZA",
    "VWC",
    "VZ '81",
    "VZV",
    "Waalre",
    "Waarde",
    "Wacker",
    "Wadenoijen",
    "Wagenborger Boys",
    "Wageningen",
    "Walburgia",
    "Walcheren",
    "Walk Inn Boys",
    "Walram",
    "Wanica Star",
    "Wapenveld",
    "Wapserveen",
    "Wardy",
    "Warffum",
    "Warga",
    "Warnsveldse Boys",
    "Wartburgia asv",
    "Waskemeer",
    "Wasmeer",
    "Waspik",
    "Wassenaar, s.v.",
    "Watergoor",
    "Wateringse Veld Kranenburg",
    "Waterloo",
    "Waterpoort Boys",
    "Waterwijk asc",
    "WAVV",
    "WBSV",
    "WCR",
    "WDS",
    "WDS'19",
    "WDZ",
    "WEC",
    "Wedde",
    "Weerdinge",
    "Weiteveense Boys",
    "Well",
    "Welsum",
    "Weltania",
    "Wemeldinge",
    "WEO",
    "Were Di",
    "Wernhout",
    "Werve, Te",
    "Wesepe",
    "Westendorp",
    "Westerbeekse Boys",
    "Westerbroek",
    "Westerlee",
    "Westerwolde",
    "Westfriezen",
    "Westlandia",
    "Westzaan",
    "WFB",
    "WHC",
    "White Stones",
    "WHS",
    "WHV",
    "WIA",
    "WICO",
    "Wieldrecht",
    "Wieringermeer",
    "Wijhe '92",
    "Wijk aan Zee",
    "Wijnandia",
    "Wijster",
    "Wijthmen",
    "WIK",
    "WIK'57",
    "Wildervank",
    "Wilhelmina",
    "Wilhelmina Boys",
    "Wilhelmina'08",
    "Wilhelmina'26",
    "Wilhelminaschool",
    "Wilhelmus",
    "Willem I",
    "Willem II",
    "Willemsoord",
    "Wilsum",
    "Winkel",
    "Wippolder",
    "Wiron",
    "Wispolia",
    "Wissel",
    "Witkampers",
    "Wittenhorst",
    "Witteveense Boys'87",
    "WKE '16",
    "WMC",
    "WNC",
    "Woander Forest",
    "Wodanseck",
    "Woenselse Boys",
    "Woerden",
    "Woezik",
    "Wognum Nicols",
    "Wolfaartsdijk",
    "Wolfersveen",
    "Woltersum",
    "Workum",
    "Woubrugge",
    "Woudenberg",
    "Woudia",
    "Woudrichem",
    "Woudsend",
    "WSC",
    "WSG",
    "WSJ",
    "WSV",
    "WSV 1930",
    "WSW",
    "WTOC",
    "WV-HEDW",
    "WVF",
    "WVV",
    "WVV'34",
    "WVV'67",
    "WVW",
    "WWNA",
    "WWS",
    "Wykels Hallum",
    "WZC Wapenveld",
    "WZV'87",
    "WZV/Station Alexsander",
    "Xavanti",
    "XerxesDZB",
    "Xing Futsal",
    "Yde de Punt",
    "Yerseke",
    "Ysselsteyn",
    "z.v.v Olympia GO",
    "ZA/ Bluedesk Internet",
    "Zaalvoetbal Onze Gezellen",
    "Zaalvoetbal Vereniging Ommen",
    "Zaamslag",
    "Zaandijk",
    "ZAC",
    "Zandpol",
    "Zandvoort Noord zvv.",
    "Zandvoort s.v.",
    "ZAP",
    "Zavodo 76",
    "ZBC'97",
    "ZBVH",
    "ZCFC",
    "ZDH",
    "ZEC",
    "Zeeburgia avv",
    "Zeelandia Middelburg",
    "Zeemacht",
    "Zeerobben",
    "Zeewolde",
    "Zeist",
    "Zekveld Sport",
    "Zenderen Vooruit",
    "Zestienhoven",
    "Zevenhoven",
    "Zevenhuizen",
    "ZHO/De Weyver",
    "Ziekenhuis Amstelveen zvv",
    "ZIGO",
    "Zinkwegse Boys",
    "Zittert ZVV",
    "ZNC",
    "ZOB",
    "Zouaven (de)",
    "ZSC",
    "ZSC'62",
    "ZSGOWMS",
    "ZSV",
    "Zuid Arnhem",
    "Zuidermeer",
    "Zuidhorn",
    "Zuidlaarderveen",
    "Zuidland",
    "Zuidoost United",
    "Zuidvogels HSV De",
    "Zuidwolde",
    "Zulise Reccom",
    "Zundert",
    "zv Libertas",
    "zv Sèrum",
    "ZV The Match",
    "ZV Vrone",
    "ZVC",
    "ZVC '22",
    "ZVC De Kneeschers",
    "ZVC Veenendaal",
    "ZVCO",
    "ZVE",
    "ZVF",
    "ZVG/Cagemax",
    "ZVH",
    "ZVI/Het Wapen van Ilpendam",
    "ZVL",
    "ZVOB",
    "ZVR 2001/Aqualon Aart van Herk",
    "ZVS/Bodemflex",
    "ZVV '56",
    "ZVV 't Flaterke",
    "zvv Amersfoortse Boys",
    "ZVV Awt-Gelaen",
    "zvv Banzo",
    "ZVV Beuningen",
    "zvv Borger",
    "zvv Cosmos '78",
    "ZVV de Peel / Roelanzia",
    "zvv De Slingerij",
    "ZVV Den Haag",
    "zvv DOS'58",
    "ZVV Dynamo Lelystad",
    "ZVV Ede",
    "ZVV Eindhoven",
    "zvv Grijpskerk",
    "ZVV Haantje",
    "Zvv Homar",
    "ZVV Kroeven/Laboral Services BV",
    "ZVV Le Soleil",
    "zvv Lotus",
    "ZVV Middelburg",
    "zvv Nieuweboer Architecten",
    "ZVV Oldenzaal",
    "ZVV Remunj",
    "ZVV Schattenberg",
    "ZVV SGB/Stevik",
    "ZVV Sparta Nijkerk",
    "zvv Topstars",
    "ZVV Twente",
    "zvv Urk",
    "ZVV Wijlre",
    "ZVVG/Brandlos",
    "ZVVM/Campus",
    "ZVVN 77",
    "ZVVO Sandow",
    "ZvWeert",
    "ZVZ",
    "Zwaag",
    "Zwaagdijk RKVV",
    "Zwaagwesteinde",
    "Zwaluw VFC",
    "Zwaluwe",
    "Zwaluwen",
    "Zwaluwen 30 HCSV",
    "Zwaluwen Utrecht '11",
    "Zwammerdam",
    "Zwart Wit '63",
    "Zwarte Pijl",
    "Zwartemeerse Boys",
    "Zwartewaal",
    "Zwervers, IJVV de",
    "Zwolsche Boys",
    "ZZC'20",
    "ZZVV",
];
