import {
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonList,
    IonText,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CURRENT_USER_OFFICIAL } from "../constants/strings";
import { MatchPhone, OfficialRole } from "refsix-js-models";
import { getRefereeRolesToRecord } from "../services/matchService";
import { useFormContext, useWatch } from "react-hook-form";
import AutocompleteInputItem from "./AutocompleteInputItem";
import { useMatchOfficialsList } from "./hooks/autocomplete/useMatchOfficialsList";
import { TeamSheetFormValues } from "../pages/teamsheets/newTeamSheet";

interface NewMatchOfficialsComponentProps {
    match: MatchPhone;
}

export const NewMatchOfficialsComponent: FC<
    NewMatchOfficialsComponentProps
> = ({ match }) => {
    const { t } = useTranslation();
    const [refereeRoles, setRefereeRoles] = useState({});

    const watchOfficial = useWatch({ name: "officialRole" });

    const {
        setValue,
        formState: { errors },
    } = useFormContext<TeamSheetFormValues>();
    useEffect(() => {
        if (watchOfficial && !match.matchOfficials) {
            refereeRolesToRecord(watchOfficial);
        }
    }, [watchOfficial]);

    function refereeRolesToRecord(role: OfficialRole) {
        setRefereeRoles(getRefereeRolesToRecord(role));
    }

    const matchOfficialsList = useMatchOfficialsList();

    const matchOfficialsFields = [
        {
            label: t("fixture.FixtureNew.referee"),
            inputs: {
                name: "matchOfficials.referee",
                officialName: match.matchOfficials?.referee,
                role: "referee",
                placeHolder: t("fixture.FixtureNew.referee"),
                testId: "referee",
            },
        },
        {
            label: t("fixture.FixtureNew.assistant"),
            inputs: {
                name: "matchOfficials.assistant1",
                officialName: match.matchOfficials?.assistant1,
                role: "assistant1",
                placeHolder: t("fixture.FixtureNew.assistant"),
                testId: "assistant1",
            },
        },
        {
            label: t("fixture.FixtureNew.assistant"),
            inputs: {
                name: "matchOfficials.assistant2",
                role: "assistant2",
                officialName: match.matchOfficials?.assistant2,
                placeHolder: t("fixture.FixtureNew.assistant"),
                testId: "assistant2",
            },
        },
        {
            label: t("fixture.FixtureNew.fourthOfficial"),
            inputs: {
                name: "matchOfficials.fourthOfficial",
                role: "fourthOfficial",
                officialName: match.matchOfficials?.fourthOfficial,
                placeHolder: t("fixture.FixtureNew.fourthOfficial"),
                testId: "fourthOfficial",
            },
        },
        {
            label: t("fixture.FixtureNew.observer"),
            inputs: {
                name: "matchOfficials.observer",
                officialName: match.matchOfficials?.observer,
                role: "observer",
                placeHolder: t("fixture.FixtureNew.observer"),
                testId: "observer",
            },
        },
    ];

    return (
        <IonAccordionGroup>
            <IonAccordion>
                <IonItem
                    className="item-title-container rounded-corners-top"
                    lines="full"
                    data-testid="match-options-officials-accordian"
                    slot="header"
                >
                    <IonText slot="start">
                        {t("fixture.FixtureSummary.matchOfficials")}
                    </IonText>

                    <IonText className="optional-text row-segment-period">
                        {t("general.optional")}
                    </IonText>
                </IonItem>

                <IonList slot="content">
                    {matchOfficialsFields.map((field, index) => {
                        const { label, inputs } = field;
                        return (
                            <div key={index}>
                                <div className="separator" />
                                <div
                                    hidden={
                                        Object.keys(refereeRoles).length > 0
                                            ? !Object.values(
                                                  refereeRoles
                                              ).includes(inputs.role)
                                            : inputs.officialName ===
                                              CURRENT_USER_OFFICIAL
                                    }
                                    className={"item-title"}
                                >
                                    {matchOfficialsList.search &&
                                        matchOfficialsList.renderer && (
                                            <AutocompleteInputItem
                                                label={label}
                                                name={inputs.name}
                                                errors={errors}
                                                ready={true}
                                                setValue={setValue}
                                                testId="matchofficials-item-input"
                                                searchFn={
                                                    matchOfficialsList.search
                                                }
                                                useFlex={true}
                                                renderFn={
                                                    matchOfficialsList.renderer
                                                }
                                            />
                                        )}
                                </div>
                            </div>
                        );
                    })}
                </IonList>
            </IonAccordion>
        </IonAccordionGroup>
    );
};
