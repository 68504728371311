import { geoMercator } from "d3";
import geodesy from "geodesy";
import {
    EventName,
    GpsCalibrationPoints,
    GpsPoint,
    HalfEvent,
    IncidentEvent,
    MatchEvent,
    MatchEventPlottingData,
    PlottingData,
    PlottingDataWrapper,
    Point,
    Sprint,
} from "refsix-js-models";
import { copyObject } from "../utils";

const LatLon = geodesy.LatLonEllipsoidal;

export function _calculatePitch(geoBottomLeft, geoBottomRight, geoTopLeft) {
    const pitch = {
        length: geoBottomLeft.distanceTo(geoBottomRight),
        height: geoBottomLeft.distanceTo(geoTopLeft),
    };
    console.log("Info: Real pitch", pitch);
    return pitch;
}

export function distanceTo(pointA: Point, pointB: Point) {
    return Math.sqrt(
        (pointA.x - pointB.x) * (pointA.x - pointB.x) +
            (pointA.y - pointB.y) * (pointA.y - pointB.y)
    );
}

export function rotate(pointToRotate: Point, centerPoint: Point, θ: number) {
    let a = centerPoint.x;
    let b = centerPoint.y;
    let X = pointToRotate.x,
        Y = pointToRotate.y;
    pointToRotate.x = Math.floor(
        a + ((X - a) * Math.cos(θ) - (Y - b) * Math.sin(θ))
    );
    pointToRotate.y = Math.floor(
        b + ((X - a) * Math.sin(θ) + (Y - b) * Math.cos(θ))
    );
}

/**
 * Transforms GPS points into cartesian points for displaying
 * @param geoPoints
 * @param calibrationPoints
 * @param width
 * @param startTs Optional timestamp for the start of segment
 * @param segmentIndex Optional index of the segment
 * @returns {{plottingData: {min: number, data: Array, calibrationPoints: *, height: number, width: *, pixelsPerMeter: number, pitch}}}
 */
export function generatePlottingData(
    geoPoints,
    calibrationPoints,
    width,
    startTs,
    segmentIndex
): PlottingDataWrapper {
    const left: [number, number] = [
        calibrationPoints.leftCorner.longitude,
        calibrationPoints.leftCorner.latitude,
    ];
    const middle: [number, number] = [
        calibrationPoints.midfield.longitude,
        calibrationPoints.midfield.latitude,
    ];
    const right: [number, number] = [
        calibrationPoints.rightCorner.longitude,
        calibrationPoints.rightCorner.latitude,
    ];

    const geoBottomLeft = new LatLon(left[1], left[0]);
    const geoMidfield = new LatLon(middle[1], middle[0]);
    const geoBottomRight = new LatLon(right[1], right[0]);

    //The distance between center and origin
    const om = geoBottomLeft.distanceTo(geoMidfield);
    const geoTopLeft = geoMidfield.destinationPoint(
        om,
        geoBottomRight.initialBearingTo(geoMidfield)
    );
    const topLeft: [number, number] = [geoTopLeft.lon, geoTopLeft.lat];

    //First projection guess
    const projection = geoMercator().center(middle).scale(1).translate([0, 0]);

    const pBottomRightArr = projection(right);
    const pBottomLeftArr = projection(left);
    const pTopLeftArr = projection(topLeft);

    if (!pBottomRightArr || !pBottomLeftArr || !pTopLeftArr) {
        console.error("Error: Unable to project calibration points");
        return <PlottingDataWrapper>(<unknown>{ plottingData: { data: [] } });
    }

    const pBottomRight: Point = {
        x: pBottomRightArr[0],
        y: pBottomRightArr[1],
    };
    const pBottomLeft: Point = { x: pBottomLeftArr[0], y: pBottomLeftArr[1] };
    const pTopLeft: Point = { x: pTopLeftArr[0], y: pTopLeftArr[1] };

    //Calculate Pitch dimensions
    const pitch = _calculatePitch(geoBottomLeft, geoBottomRight, geoTopLeft);

    //calculate height based on true proportions
    const height = (width * pitch.height) / pitch.length;

    //Recalculate scale based on projection scale factor
    const scale =
        (1 /
            Math.max(
                distanceTo(pBottomLeft, pBottomRight) / width,
                distanceTo(pTopLeft, pBottomLeft) / height
            )) *
        0.97;
    const offset: [number, number] = [width / 2, height / 2];

    //New scaled projection
    const scaledProjection = geoMercator()
        .center(middle)
        .scale(scale)
        .translate(offset);

    const pBottomRightScaledArr = scaledProjection(right);
    const pBottomLeftScaledArr = scaledProjection(left);
    const pMidfieldArr = scaledProjection(middle);

    if (!pBottomRightScaledArr || !pBottomLeftScaledArr || !pMidfieldArr) {
        console.error("Error: Unable to project scaled calibration points");
        return <PlottingDataWrapper>(<unknown>{ plottingData: { data: [] } });
    }

    const pBottomRightScaled = {
        x: pBottomRightScaledArr[0],
        y: pBottomRightScaledArr[1],
    };
    const pBottomLeftScaled = {
        x: pBottomLeftScaledArr[0],
        y: pBottomLeftScaledArr[1],
    };
    const pMidfieldScaled = { x: pMidfieldArr[0], y: pMidfieldArr[1] };

    //Rotate horizontally
    const mx = pBottomLeftScaled.x - pBottomRightScaled.x;
    const my = pBottomLeftScaled.y - pBottomRightScaled.y;
    let teta = Math.atan(-my / mx);

    if (calibrationPoints.flip) {
        teta = teta < Math.PI ? teta + Math.PI : teta - Math.PI;
    }

    // console.log("Pitch rotation degree: " + Math.round(teta.toDegrees() * 100) / 100);
    rotate(pBottomRight, pMidfieldScaled, teta);
    rotate(pBottomLeft, pMidfieldScaled, teta);

    const len = geoPoints.length - 1;
    console.log("Gps points: " + len);
    const cartesianPoints: Point[] = [];
    const startDate = new Date(geoPoints[0].time);
    const accuracies: any[] = [];
    console.log("------------ Work Rate ---------------");

    const pixelsPerMeter = width / pitch.length;

    for (let i = 0; i <= len; i++) {
        const geoPoint = geoPoints[i];
        accuracies.push(geoPoint.accuracy);

        const projectedPoint = scaledProjection([
            geoPoint.longitude,
            geoPoint.latitude,
        ]);

        if (!projectedPoint) {
            console.error("Error: Unable to project GPS point");
            continue;
        }

        const point: Point = { x: projectedPoint[0], y: projectedPoint[1] };

        const diffMs = new Date(geoPoint.time).getTime() - startDate.getTime();
        const minute = Math.floor(diffMs / 1000 / 60);

        //Rotate
        rotate(point, pMidfieldScaled, teta);

        point.i = i;
        point.minute = minute;
        point.timestamp = geoPoint.time;
        if (segmentIndex) {
            point.segmentIndex = segmentIndex;
        }
        if (startTs) {
            point.timeOffsetSecs = Math.floor((geoPoint.time - startTs) / 1000);
        }
        if (i > 0) {
            point.interval =
                (new Date(geoPoint.time).getTime() -
                    new Date(geoPoints[i - 1].time).getTime()) /
                1000;
        } else {
            point.interval = 1;
        }

        cartesianPoints.push(point);
    }

    return {
        plottingData: {
            data: cartesianPoints,
            calibrationPoints: calibrationPoints,
            height: height,
            width: width,
            pixelsPerMeter: pixelsPerMeter,
            pitch: pitch,
        },
    };
}

/**
 * Filter GPS points based on match segment
 * @param geoPoints
 * @param segment
 * @private
 */
export function _filterSegmentPoints(geoPoints, segment) {
    return geoPoints.filter(function (geoPoint) {
        return (
            geoPoint.time > segment.timestamp && geoPoint.time < segment.endTime
        );
    });
}

/**
 * Transforms GPS points into cartesian points for the different segments of the match
 * @param geoPoints
 * @param calibrationPoints
 * @param matchSegments
 * @param width
 * @returns Array
 */
export function generatePlottingDataForSegments(
    geoPoints,
    calibrationPoints,
    matchSegments,
    width
) {
    // an object for each playing segment/period e.g. first half, second half
    const segmentsResults: PlottingDataWrapper[] = [];
    // an object for each playing segment/period e.g. first half, second half PLUS has all points as [0]
    let wholeMatchResults: PlottingDataWrapper | undefined;

    matchSegments.forEach(function (segment, i: number) {
        var filteredGeoPoints = _filterSegmentPoints(geoPoints, segment);

        if (filteredGeoPoints.length !== 0) {
            const segmentResult = generatePlottingData(
                filteredGeoPoints,
                calibrationPoints,
                width,
                segment.timestamp,
                i
            );
            segmentsResults.push(segmentResult);

            if (
                !wholeMatchResults ||
                !wholeMatchResults.plottingData ||
                !wholeMatchResults.plottingData.data
            ) {
                wholeMatchResults = copyObject(segmentResult);
            } else {
                wholeMatchResults.plottingData.data =
                    wholeMatchResults.plottingData.data.concat(
                        segmentResult.plottingData.data
                    );

                wholeMatchResults.plottingData.max = Math.max(
                    wholeMatchResults.plottingData.max || 0,
                    segmentResult.plottingData.max || 0
                );
            }
        }
    });

    // // prepend an object for the whole match in front of the data for periods
    /// e.g. [All, 1H, 2H, ET1, ET2]
    return [wholeMatchResults, ...segmentsResults];
}

/**
 * Position match events in field based on GPS coordinates (WIP)
 * @param geoPoints
 * @param calibrationPoints
 * @param matchSegments
 * @param fixtureEvents
 * @param width
 * @param gpsPlottingData
 */
export function matchEventsPosition(
    geoPoints,
    calibrationPoints,
    matchSegments,
    fixtureEvents,
    width,
    gpsPlottingData
): MatchEventPlottingData | undefined {
    var processedData = gpsPlottingData
        ? gpsPlottingData
        : generatePlottingDataForSegments(
              geoPoints,
              calibrationPoints,
              matchSegments,
              width
          );
    //Need to get first index of plotted data because generatePlottingDataForSegments returns an array with the data for each period of the match [all, 1st period, 2nd period, etc]
    processedData = processedData[0];
    if (!processedData) {
        return undefined;
    }
    const eventsArray: MatchEvent[] = Object.values(fixtureEvents);
    const matchEvents = eventsArray.sort(function (
        a: MatchEvent,
        b: MatchEvent
    ): number {
        return a.timestamp - b.timestamp;
    });

    const incidents = matchEvents.filter(function (event) {
        // @ts-ignore TODO Look to remove or add into model object
        event.hasPosition = false;
        return event.eventName === EventName.incident;
    }) as IncidentEvent[];

    if (incidents.length === 0) {
        return undefined;
    }

    var currentEvent = 0;
    var points = processedData.plottingData.data;

    var results: any[] = [];
    for (var i = 0; i < points.length - 2; i++) {
        if (
            incidents[currentEvent].timestamp >= points[i].timestamp &&
            incidents[currentEvent].timestamp <= points[i + 1].timestamp &&
            // @ts-ignore TODO need to figure out typing for children of match events
            incidents[currentEvent].player &&
            // @ts-ignore TODO need to figure out typing for children of match events
            !incidents[currentEvent].player.teamOfficial
        ) {
            var diff1 = incidents[currentEvent].timestamp - points[i].timestamp;
            var diff2 =
                points[i + 1].timestamp - incidents[currentEvent].timestamp;
            results.push({
                event: incidents[currentEvent],
                point: diff1 > diff2 ? points[i + 1] : points[i],
            });
            // @ts-ignore TODO Look to remove or add into model object
            incidents[currentEvent].hasPosition = true;
            currentEvent++;
            i--;
            // @ts-ignore TODO Look to remove or add into model object
        } else if (
            incidents[currentEvent].timestamp < points[i].timestamp ||
            // @ts-ignore TODO need to figure out typing for children of match events
            (incidents[currentEvent].player &&
                // @ts-ignore TODO need to figure out typing for children of match events
                incidents[currentEvent].player.teamOfficial)
        ) {
            currentEvent++;
            i--;
        }

        if (currentEvent === incidents.length) {
            break;
        }
    }

    var noGpsIncidents = incidents.filter(function (event: any) {
        return (
            event.player &&
            event.hasPosition === false &&
            !event.player.teamOfficial
        );
    });

    return {
        extraData: noGpsIncidents,
        data: results,
        pitch: processedData.plottingData.pitch,
        width: processedData.plottingData.width,
        height: processedData.plottingData.height,
    };
}

export function sprintsPositions(
    geoPoints: GpsPoint[],
    calibrationPoints: GpsCalibrationPoints,
    matchSegments: HalfEvent[],
    sprints: Sprint<number>[],
    width: number,
    gpsPlottingData
): PlottingData<Sprint<Point>[]>[] {
    const processedDataAll: PlottingDataWrapper = gpsPlottingData
        ? gpsPlottingData
        : generatePlottingDataForSegments(
              geoPoints,
              calibrationPoints,
              matchSegments,
              width
          );
    //Need to get first index of plotted data because generatePlottingDataForSegments returns an array with the data for each period of the match [all, 1st period, 2nd period, etc]
    const processedData = processedDataAll[0];
    const points = processedData.plottingData.data;

    const sprintsProcessed = sprints.map(function (sprint) {
        const newSprint: Sprint<Point | number> = copyObject(sprint);
        newSprint.points = sprint.points.map(function (pointIndex) {
            return copyObject(points[pointIndex]);
        });
        return newSprint as Sprint<Point>;
    });
    const results: PlottingData<Sprint<Point>[]>[] = [];
    results.push({
        data: sprintsProcessed,
        pitch: processedData.plottingData.pitch,
        width: processedData.plottingData.width,
        height: processedData.plottingData.height,
    });

    matchSegments.forEach(function (segment, i) {
        const sprintsForSegment = sprintsProcessed.filter(
            (sprint) => sprint.segment === i
        );
        results.push({
            data: sprintsForSegment,
            pitch: processedData.plottingData.pitch,
            width: processedData.plottingData.width,
            height: processedData.plottingData.height,
        });
    });

    return results;
}
