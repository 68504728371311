import React, { CSSProperties } from "react";
import "./reviewCard.css";
import { AggregatedStats, MatchPhone, Stats } from "refsix-js-models";
import { useTranslation } from "react-i18next";

interface StatProps {
    width?: string;
    height?: string;
    css?: CSSProperties;
    className?: string;
}

const Stat: React.FC<StatProps> = ({
    width,
    height,
    children,
    css,
    className,
}) => {
    const style = {
        width: width,
        height: height,
        ...css,
    };
    return (
        <div className={`stat ${className}`} style={style}>
            {children}
        </div>
    );
};

export interface CardPartProps {
    align?: "left" | "center" | "right";
    fontSize?: string;
    className?: string;
    style?: CSSProperties;
}

export const Header: React.FC<CardPartProps> = (props) => {
    return <div className="header">{props.children}</div>;
};
export const MainHeader: React.FC<CardPartProps> = (props) => {
    return <div className="main-header">{props.children}</div>;
};
export const Icon: React.FC<CardPartProps> = (props) => {
    return (
        <div className={["icon", props.className || ""].join(" ")}>
            {props.children}
        </div>
    );
};

export const Content: React.FC<CardPartProps> = (props) => {
    return (
        <div className={`content ${props.className || ""}`}>
            {props.children}
        </div>
    );
};
export const Row: React.FC<CardPartProps> = (props) => {
    return (
        <div className={`row ${props.className || ""}`}>{props.children}</div>
    );
};
export const Col: React.FC<CardPartProps> = (props) => {
    return (
        <div className={`col ${props.className || ""}`}>{props.children}</div>
    );
};
export const Footer: React.FC<CardPartProps> = (props) => {
    return <div className="footer">{props.children}</div>;
};

export const StatsContainer: React.FC<{}> = (props) => (
    <div className="stats-container">{props.children}</div>
);

export const CardsContainer: React.FC<CardPartProps> = (props) => (
    <div
        className={["cards-container", props.className].join(" ")}
        style={props.style}
    >
        {props.children}
    </div>
);
export const CardsHeader: React.FC<CardPartProps> = (props) => (
    <div className="cards-header" style={props.style}>
        {props.children}
    </div>
);

export const Card: React.FC<CardPartProps> = (props) => {
    return <div className="cards">{props.children}</div>;
};
export const CardLabel: React.FC<CardPartProps> = (props) => {
    return <div className="cards-label">{props.children}</div>;
};
export const CardIcon: React.FC<CardPartProps> = (props) => {
    return (
        <div className="card-icon">
            <div className={"card1"}></div>
            <div className={"card2"}></div>
        </div>
    );
};
export const CardIconYellowRed: React.FC<CardPartProps> = (props) => {
    return (
        <div className="card-icon">
            <div className={"card1 yellow"}></div>
            <div className={"card2 red"}></div>
        </div>
    );
};

export const Red: React.FC<CardPartProps> = (props) => {
    return <div className="cards-red">{props.children}</div>;
};
export const Yellow: React.FC<CardPartProps> = (props) => {
    return <div className="cards-yellow">{props.children}</div>;
};
export const MatchResult: React.FC<{ match: MatchPhone }> = ({ match }) => {
    return (
        <Match>
            <Row className={"home-team"}>
                <Team>
                    <TeamNameContainer>
                        <TeamName>{match.homeTeam}</TeamName>
                        <TeamColor color={match.homeColor} />
                    </TeamNameContainer>
                </Team>
                <MatchTeamScore>{match.stats?.goalsHomeTotal}</MatchTeamScore>
            </Row>
            <Row className={"vs"}>VS</Row>
            <Row className={"away-team"}>
                <Team>
                    <TeamNameContainer>
                        <TeamName>{match.awayTeam}</TeamName>
                        <TeamColor color={match.awayColor} />
                    </TeamNameContainer>
                </Team>
                <MatchTeamScore>{match.stats?.goalsAwayTotal}</MatchTeamScore>
            </Row>
        </Match>
    );
};
export const MatchCardsResult: React.FC<{ match: MatchPhone }> = ({
    match,
}) => {
    const totalCards =
        (match.stats?.yellowCardTotal || 0) + (match.stats?.redCardTotal || 0);
    return (
        <MatchCards>
            <Team className="team1">
                <TeamName>{match.homeTeam}</TeamName>
                <TeamColor color={match.homeColor} />
            </Team>
            <CardsContainer className="cards">
                <CardIconYellowRed />
                <CardLabel>{totalCards}</CardLabel>
            </CardsContainer>
            <Team className="team2">
                <TeamName>{match.awayTeam}</TeamName>
                <TeamColor color={match.awayColor} />
            </Team>
        </MatchCards>
    );
};
const Match: React.FC<CardPartProps> = (props) => {
    return <div className="match">{props.children}</div>;
};

const Team: React.FC<CardPartProps> = (props) => {
    return (
        <div
            className={["team-container", props.className].join(" ")}
            style={props.style}
        >
            {props.children}
        </div>
    );
};

const TeamNameContainer: React.FC<CardPartProps> = (props) => {
    return <div className="team-name-container">{props.children}</div>;
};

const TeamName: React.FC<CardPartProps> = (props) => {
    return <div className="team-name">{props.children}</div>;
};

const TeamNameVs: React.FC<CardPartProps> = (props) => {
    return <div className="vs">{props.children}</div>;
};

export interface TeamColorProps {
    color?: string;
}

const TeamColor: React.FC<TeamColorProps> = (props) => {
    const style = {
        backgroundColor: props.color,
    };
    return (
        <div className="team-color" style={style}>
            {props.children}
        </div>
    );
};
const MatchTeamScore: React.FC<CardPartProps> = (props) => {
    return <div className="score">{props.children}</div>;
};

export const Info: React.FC<CardPartProps> = (props) => {
    return <div className="info">{props.children}</div>;
};
export const MatchCards: React.FC<CardPartProps> = (props) => {
    return <div className="match-cards">{props.children}</div>;
};

export const MatchSprintsBySpeed: React.FC<{ stats?: Stats }> = ({
    stats,
    children,
}) => {
    const { t } = useTranslation();
    return (
        <div className="total-sprints-list">
            <Col className="total-sprints-item">
                <div className="total-sprints-high">
                    <div className={"total-sprints-content"}>
                        {stats?.sprintsTotal[2]}
                    </div>
                    <div className={"total-sprints-bar"}></div>
                    <div className={"total-sprints-footer"}>
                        {t("fixture.FixtureSummaryStats.sprintmapKey.high")}
                    </div>
                </div>
                <div className="total-sprints-medium">
                    <div className={"total-sprints-content"}>
                        {stats?.sprintsTotal[1]}
                    </div>
                    <div className={"total-sprints-bar"}></div>
                    <div className={"total-sprints-footer"}>
                        {t("fixture.FixtureSummaryStats.sprintmapKey.medium")}
                    </div>
                </div>
                <div className="total-sprints-low">
                    <div className={"total-sprints-content"}>
                        {stats?.sprintsTotal[0]}
                    </div>
                    <div className={"total-sprints-bar"}></div>
                    <div className={"total-sprints-footer"}>
                        {t("fixture.FixtureSummaryStats.sprintmapKey.low")}
                    </div>
                </div>
            </Col>
        </div>
    );
};
export default Stat;
