import { store } from "../../redux/store";
import { RefsixState } from "../../redux/models/refsixState";
import { checkIfPro } from "../subscriptionService";
import { mpSetUserLocale, mpUpdatePersonOnce } from "./mixpanelService";
import { getProfile } from "../profileService";
import { oneSignalSetUser } from "../OneSignal";

export default class MixpanelUserId {
    previousUsername?: string = undefined;
    previousPro?: boolean = undefined;

    constructor() {
        // immediately set up mixpanel
        this.handleStateChange(store.getState());
        // listen for state changes to update mixpanel
        store.subscribe(() => {
            this.handleStateChange(store.getState());
        });
    }

    handleStateChange(state: RefsixState) {
        const auth = state.auth;
        if (auth && auth.session) {
            const newUsername = auth.session.user_id;
            this.handleUsernameChange(newUsername);
        } else {
            // TODO test logout scenario
            this.handleUsernameChange(undefined);
        }

        const isPro = checkIfPro(state.subscriptions.subscriptions);
        this.handleIsProChange(isPro);
    }

    handleUsernameChange(newUsername: string | undefined) {
        if (window.mixpanel && newUsername !== this.previousUsername) {
            this.previousUsername = newUsername;
            // identify the user on mixpanel
            if (newUsername) {
                console.log("Setting user identity " + newUsername);
                window.mixpanel.flush();
                window.mixpanel.identify(newUsername);
                mpSetUserLocale();
                // make sure property is set for onesignal if it wasn't during registration
                mpUpdatePersonOnce({ $onesignal_user_id: newUsername });
                // update onesignal user id
                getProfile(newUsername).then(function (profile) {
                    oneSignalSetUser(newUsername, profile.email);
                });
            } else {
                console.log("Resetting user identity");
                window.mixpanel.flush();
                window.mixpanel.reset();
            }
        }
    }

    handleIsProChange(newIsPro: boolean) {
        if (window.mixpanel && newIsPro !== this.previousPro) {
            this.previousPro = newIsPro;
            window.mixpanel.registerSuperProperties(
                {
                    userIsPro: newIsPro,
                },
                () => {}, // success
                (error: any) => {
                    console.log(
                        "error registerSuperProperties",
                        JSON.stringify(error)
                    );
                }
            );
        }
    }
}
