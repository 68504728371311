import { IonCol, IonItem, IonLabel } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { isUserPlusEligible } from "../../services/subscriptionService";

interface RenderInAppPurchaseButtonProps {
    title: string;
    selectedSegment: "plus" | "pro";
    benefits: string[];
}

export const GoProOrPlusBenefits: FC<RenderInAppPurchaseButtonProps> = ({
    title,
    selectedSegment,
    benefits,
}) => {
    const { upgradeType } = useParams<{ upgradeType: string }>();
    const { t } = useTranslation();

    const basicBenefits: string[] = [
        t("upgrade.goProModal.advancedTimers"),
        t("upgrade.basicFeatures.uploadTool"),
        t("upgrade.proFeaturesContent.tableFeature6"),
        t("upgrade.basicFeatures.calendarSync"),
        t("upgrade.basicFeatures.addFreeExperience"),
    ];

    const proBenefits: string[] = [
        t("upgrade.proFeatures.everythingInPlus") || "PLUS",
        t("upgrade.goProModal.heatmaps"),
        t("upgrade.goProModal.performanceData"),
        t("upgrade.proFeatureMarketing.trends"),
        t("upgrade.goProModal.videoAnalysis"),
    ];

    const getBenefitLabels = () => {
        if (upgradeType === "pro") {
            return (
                <>
                    <IonLabel className="extra-line-height">
                        ⌚ {basicBenefits[0]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        🔥 {proBenefits[1]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        🏃‍ {proBenefits[2]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        📈 {proBenefits[3]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        📺 {proBenefits[4]}
                    </IonLabel>
                </>
            );
        } else if (selectedSegment === "pro") {
            return (
                <>
                    {isUserPlusEligible() && (
                        <IonLabel className="extra-line-height">
                            ✅ {proBenefits[0]}
                        </IonLabel>
                    )}
                    <IonLabel className="extra-line-height">
                        🔥 {proBenefits[1]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        🏃‍ {proBenefits[2]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        📈 {proBenefits[3]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        📺 {proBenefits[4]}
                    </IonLabel>
                </>
            );
        } else {
            return (
                <>
                    <IonLabel className="extra-line-height">
                        ⌚ {basicBenefits[0]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        🔼 {basicBenefits[1]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        🎨 {basicBenefits[2]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        📅 {basicBenefits[3]}
                    </IonLabel>
                    <IonLabel className="extra-line-height">
                        🪧 {basicBenefits[4]}
                    </IonLabel>
                </>
            );
        }
    };

    return (
        <IonItem color="transparent" lines="none" className="pro-features">
            <IonCol>
                <IonLabel
                    style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        marginBottom: 15,
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {title}
                </IonLabel>
                {getBenefitLabels()}
            </IonCol>
        </IonItem>
    );
};
