import { AggregatedStats, MatchPhone } from "refsix-js-models";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { zip } from "lodash";
import moment from "moment";
import {
    IonCard,
    IonCol,
    IonItem,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonText,
} from "@ionic/react";

import { RootState } from "../../../redux/store";
import { createDefaultFiltersSetting } from "../../filters/filtersHelper";
import { MatchType } from "../../filters/enums";
import {
    lastMatches,
    matchesWithMost,
    PeriodsType,
    topMatches,
    TopMatchesProps,
} from "../../../services/matchService";
import {
    BarChartComponent,
    HeadlineNumbersComponent,
    ListOfTopComponent,
} from "../../../components";
import { distanceString } from "../../../components/DistanceComponent";
import { getDatasets, getDistanceLineBarOptions } from "./distanceHelpers";
import { cleanData } from "../../../utils/arrayUtils";
import { ProTeaser } from "../../../components/ProTeaser";
import { LIST_OF_TOP_DATE_FORMAT } from "../../../components/ListOfTopComponent";

const translations = {
    [PeriodsType.Halves]: "settings.TemplateSettings.period.p2",
    [PeriodsType.Thirds]: "settings.TemplateSettings.period.p3",
    [PeriodsType.Quarters]: "settings.TemplateSettings.period.p4",
};

const getPeriodLabels = (periodNo: PeriodsType) =>
    [
        t("results.heatmap.first"),
        t("results.heatmap.second"),
        t("results.heatmap.third"),
        t("results.heatmap.fourth"),
    ]
        .slice(0, periodNo)
        .concat([
            t("results.heatmap.extraTime1"),
            t("results.heatmap.extraTime2"),
        ]);

type PeriodsData = {
    [key in PeriodsType]?: [
        number | undefined, // Distance Total
        number | undefined, // Average
        string | undefined // Labels
    ][];
};

interface DistanceProps {
    matches: MatchPhone[];
    aggregatedStats?: AggregatedStats;
    hasStatsAccess: boolean;
}

export const Distance: FC<DistanceProps> = ({
    matches,
    aggregatedStats,
    hasStatsAccess,
}) => {
    const filters = useSelector(
        (state: RootState) =>
            state.filters.filterResults ||
            createDefaultFiltersSetting(MatchType.Result)
    );
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );

    const [periods, setPeriods] = useState<PeriodsType[]>([]);
    const [periodsData, setPeriodsData] = useState<PeriodsData>({});
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodsType>(
        PeriodsType.Halves
    );
    const [last10MatchesGraphData, setLast10MatchesGraphData] =
        useState<TopMatchesProps>();
    const [last10Matches, setLast10Matches] = useState<MatchPhone[]>([]);

    useEffect(() => {
        const last10Matches = lastMatches(matches, filters, 10, "gpsAvailable");
        const last10MatchesGraphData = topMatches(last10Matches, useImperial);

        setLast10MatchesGraphData(last10MatchesGraphData);
        setLast10Matches(last10Matches);
    }, [matches, filters, useImperial]);

    useEffect(() => {
        const newPeriods: PeriodsType[] = [];
        const newPeriodsData: PeriodsData = {};

        // Create the PeriodsData tuples for each periods type. Tuple is [total, average, label]
        if (
            aggregatedStats?.distanceByHalvesTotal.length &&
            aggregatedStats?.distanceByHalvesTotal.filter(
                (distance) => distance > 0
            ).length > 0
        ) {
            newPeriods.push(PeriodsType.Halves);
            newPeriodsData[PeriodsType.Halves] = zip(
                cleanData(aggregatedStats.distanceByHalvesTotal),
                cleanData(aggregatedStats.distanceByHalvesAverage),
                getPeriodLabels(PeriodsType.Halves).slice(
                    0,
                    aggregatedStats.distanceByHalvesTotal.length
                )
            );
        }
        if (
            aggregatedStats?.distanceByThirdsTotal.length &&
            aggregatedStats?.distanceByThirdsTotal.filter(
                (distance) => distance > 0
            ).length > 0
        ) {
            newPeriods.push(PeriodsType.Thirds);
            newPeriodsData[PeriodsType.Thirds] = zip(
                cleanData(aggregatedStats.distanceByThirdsTotal),
                cleanData(aggregatedStats.distanceByThirdsAverage),
                getPeriodLabels(PeriodsType.Thirds).slice(
                    0,
                    aggregatedStats.distanceByThirdsTotal.length
                )
            );
        }
        if (
            aggregatedStats?.distanceByQuartersTotal.length &&
            aggregatedStats?.distanceByQuartersTotal.filter(
                (distance) => distance > 0
            ).length > 0
        ) {
            newPeriods.push(PeriodsType.Quarters);
            newPeriodsData[PeriodsType.Quarters] = zip(
                cleanData(aggregatedStats.distanceByQuartersTotal),
                cleanData(aggregatedStats.distanceByQuartersAverage),
                getPeriodLabels(PeriodsType.Quarters).slice(
                    0,
                    aggregatedStats.distanceByQuartersTotal.length
                )
            );
        }

        setPeriods(newPeriods);
        setPeriodsData(newPeriodsData);
    }, [aggregatedStats]);

    return (
        <>
            <IonCard data-testid="trends-segment-distance">
                <HeadlineNumbersComponent
                    title={t("stats.stats.Distance.totalDistance")}
                    testIdsPrefix="total-distance"
                    data={[
                        distanceString(
                            aggregatedStats?.distanceTotal || 0,
                            useImperial,
                            t,
                            2
                        ),
                        distanceString(
                            aggregatedStats?.distanceAverage || 0,
                            useImperial,
                            t,
                            2
                        ),
                    ]}
                    labels={[
                        t("stats.stats.Distance.totalDistance"),
                        t("stats.stats.Distance.averageDistance"),
                    ]}
                    useDummyData={!hasStatsAccess}
                    fromIndex={!hasStatsAccess ? 0 : undefined}
                />
            </IonCard>
            <ProTeaser
                showTeaser={!hasStatsAccess}
                teaserName="trends-distances"
            >
                <>
                    <IonCard>
                        <IonSegment
                            onIonChange={(e) =>
                                setSelectedPeriod(
                                    parseInt(
                                        e.detail.value as string
                                    ) as PeriodsType
                                )
                            }
                            value={selectedPeriod.toString()}
                        >
                            {periods.map((period, idx) => (
                                <IonSegmentButton
                                    value={period.toString()}
                                    key={idx}
                                    data-testid={`${period}-segment-button`}
                                >
                                    <IonLabel>
                                        {t(`${translations[period]}`)}
                                    </IonLabel>
                                </IonSegmentButton>
                            ))}
                        </IonSegment>

                        {periodsData[selectedPeriod]?.map(
                            ([total, avg, label], idx) => (
                                <HeadlineNumbersComponent
                                    key={idx}
                                    data={[
                                        distanceString(
                                            total || 0,
                                            useImperial,
                                            t,
                                            2
                                        ),
                                        distanceString(
                                            avg || 0,
                                            useImperial,
                                            t,
                                            2
                                        ),
                                    ]}
                                    title={label || ""}
                                    labels={[
                                        t("stats.stats.Distance.totalDistance"),
                                        t(
                                            "stats.stats.Distance.averageDistance"
                                        ),
                                    ]}
                                    testIdsPrefix={`${label}-distance`}
                                    useDummyData={!hasStatsAccess}
                                    fromIndex={!hasStatsAccess ? 0 : undefined}
                                />
                            )
                        )}
                    </IonCard>
                </>
            </ProTeaser>

            <IonCard>
                <IonItem lines="none">
                    <IonCol>
                        <IonText className="text-small">
                            {t("stats.stats.last10Matches")}
                        </IonText>
                    </IonCol>
                </IonItem>
                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-distances-last-matches"
                >
                    <>
                        {last10MatchesGraphData?.distanceBySegment &&
                            periodsData[selectedPeriod] && (
                                <IonItem
                                    lines="none"
                                    data-testid="last-10-line-chart"
                                >
                                    <IonCol>
                                        <BarChartComponent
                                            data={{
                                                datasets: getDatasets(
                                                    last10MatchesGraphData,
                                                    t
                                                ),
                                                labels: last10Matches.map(
                                                    (match) =>
                                                        `${match.homeTeamShort} - ${match.awayTeamShort}`
                                                ),
                                            }}
                                            barChartOptions={getDistanceLineBarOptions(
                                                useImperial,
                                                t
                                            )}
                                            testId="line-chart-distance"
                                        />
                                    </IonCol>
                                </IonItem>
                            )}
                    </>
                </ProTeaser>
            </IonCard>

            <IonCard>
                <IonItem lines="none">
                    <IonCol>
                        <div>
                            <IonText className="text-small">
                                {t("stats.stats.Distance.topMatches")}
                            </IonText>
                        </div>
                    </IonCol>
                </IonItem>

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-distances-top-matches"
                >
                    <>
                        {matchesWithMost(
                            matches,
                            "distanceTotal",
                            5,
                            filters
                        ).map((match, i) => (
                            <ListOfTopComponent
                                matchId={match._id}
                                key={i}
                                stat={
                                    match.stats &&
                                    distanceString(
                                        match.stats.distanceTotal,
                                        useImperial,
                                        t,
                                        2
                                    )
                                }
                                teamNames={`${match.homeTeam} v ${match.awayTeam}`}
                                date={moment(match.date).format(
                                    LIST_OF_TOP_DATE_FORMAT
                                )}
                                testId="top-distance-matches"
                                useDummyData={!hasStatsAccess}
                                valueSize={"large"}
                            />
                        ))}
                    </>
                </ProTeaser>
            </IonCard>
        </>
    );
};
