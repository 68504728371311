import {prepareBaseResponse} from "./prepareBaseResponse";
import {MessageTypes} from "../messageTypesEnum";
import {prepareFixturesForWatch} from "../../matchService";
import {MatchData, SinBinSystem} from "refsix-js-models";
import * as Sentry from "@sentry/react";
import {WatchMessage} from "../watchInterface";
import * as semver from "semver"
import {isWatchSystemB2024Compatible} from "../watchHelpers";
import {cloneDeep} from "lodash";

interface MatchListResponse extends WatchMessage {
    matches: MatchData[];
}

const transformMatchSystemB2024 = (match: MatchData) => {
    if(match.match && match.match.sinBinSystem === SinBinSystem.systemB2024){
        const newMatch = cloneDeep(match);
        newMatch.match.sinBinSystem = SinBinSystem.none;
        return newMatch;
    }
    return match;
}

export async function getMatchesListMessage(
    data: any,
    token: any
): Promise<MatchListResponse | undefined> {
    const message = {
        requestId: data.id,
        matches: [],
    };

    // TODO version mismatch?
    // if (data.version && $rootScope.versionNumber !== data.version) {
    //     _sendVersionMismatchMessage(message, data);
    // } else {

    try {
        const response: any = await prepareBaseResponse(
            message,
            MessageTypes.MATCH_LIST_RESPONSE,
            data,
            token
        );
        let matches = prepareFixturesForWatch();
        if(!isWatchSystemB2024Compatible(data.version)){
            console.log("Watch isn't compatible with system B2024, converting matches");
            matches = matches.map(transformMatchSystemB2024)
        }
        return { ...response, matches };
    } catch (error: any) {
        Sentry.captureMessage(
            `[WatchService._handleMatchList()] Problem building match list 
                ${error.message}`
        );
        return undefined;
    }
}
