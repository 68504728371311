import {
    IonButton,
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";
import { FC, useEffect, useMemo, useState } from "react";
import ScrollableContent from "../../../components/ScrollableContents";
import {
    MatchPhone,
    Player,
    PlayerOfTheMatch,
    SelectedTeam,
} from "refsix-js-models";
import { useTranslation } from "react-i18next";
import { R6IonSelect } from "../../../components/r6ionic/R6IonSelect";
import {
    Controller,
    FormProvider,
    useForm,
    useFormContext,
} from "react-hook-form";
import _ from "lodash";
import { updateMatch } from "../../../services/matchService";
import { ToastComponent } from "../../../components";

export const PlayerOfTheMatchWrapper: FC<{ match: MatchPhone }> = ({
    match,
}) => {
    const [formValues, setFormValues] = useState<
        PlayerOfTheMatch | undefined
    >();

    const formMethods = useForm<PlayerOfTheMatch>({
        mode: "all",
        defaultValues: {
            teamSide: undefined,
            player: undefined,
        },
        values: formValues,
    });

    useEffect(() => {
        if (match.playerOfTheMatch) {
            setFormValues(match.playerOfTheMatch);
        }
    }, [match.playerOfTheMatch]);

    return (
        <FormProvider {...formMethods}>
            <PlayerOfTheMatchSegment match={match} />
        </FormProvider>
    );
};

const PlayerOfTheMatchSegment: FC<{
    match: MatchPhone;
}> = ({ match }) => {
    const { t } = useTranslation();

    const { handleSubmit, watch, setValue } =
        useFormContext<PlayerOfTheMatch>();

    const watchTeamSelected: SelectedTeam | undefined = watch("teamSide");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastTitle, setToastTitle] = useState<string>("");

    const savePlayerOfTheMatch = async (playerOfTheMatch: PlayerOfTheMatch) => {
        if (playerOfTheMatch.teamSide && playerOfTheMatch.player) {
            const newMatch = {
                ...match,
                playerOfTheMatch: {
                    teamSide: playerOfTheMatch.teamSide,
                    player: playerOfTheMatch.player,
                },
            };
            try {
                await updateMatch(newMatch);
                setToastTitle(t("playerOfTheMatch.updated"));
                setShowToast(true);
            } catch (err) {
                setToastTitle(t("playerOfTheMatch.notUpdated"));
                setShowToast(true);
                console.log("error trying to update player of the match", err);
            }
        }
    };

    const listOfPlayers: Player[] = useMemo(
        () =>
            match.players && watchTeamSelected
                ? match.players[
                      watchTeamSelected === SelectedTeam.home
                          ? match.homeTeam
                          : match.awayTeam
                  ]
                : [],
        [match.players, watchTeamSelected]
    ).filter((player) => !player.teamOfficial);

    const compareWith = (a: any, b: any) => {
        return _.isEqual(a, b);
    };

    return (
        <ScrollableContent>
            <IonContent>
                <form onSubmit={handleSubmit(savePlayerOfTheMatch)}>
                    <IonCard color="transparent">
                        <IonItem lines="none" color="transparent">
                            <IonLabel>{t("playerOfTheMatch.title")}</IonLabel>
                        </IonItem>
                    </IonCard>

                    <IonCard>
                        <IonItem lines="full">
                            <IonLabel>{t("general.team")}</IonLabel>
                            <R6IonSelect
                                compareWith={compareWith}
                                name="teamSide"
                            >
                                <IonSelectOption value={SelectedTeam.home}>
                                    {match.homeTeam}
                                </IonSelectOption>
                                <IonSelectOption value={SelectedTeam.away}>
                                    {match.awayTeam}
                                </IonSelectOption>
                            </R6IonSelect>
                        </IonItem>
                        <IonItem lines="full" disabled={!watchTeamSelected}>
                            <IonLabel>
                                {t("fixture.FixtureTeam.player")}
                            </IonLabel>
                            <Controller
                                name={"player.number"}
                                render={({ field: { onChange, ...rest } }) => {
                                    return (
                                        <IonSelect
                                            interface="popover"
                                            className="select-text"
                                            onIonChange={(e) => {
                                                const playerNumber =
                                                    e.detail.value;
                                                const player =
                                                    listOfPlayers.find(
                                                        (player) =>
                                                            player.number ===
                                                            playerNumber
                                                    );
                                                if (player) {
                                                    setValue("player", player);
                                                }
                                            }}
                                            {...rest}
                                        >
                                            {listOfPlayers.map((player) => (
                                                <IonSelectOption
                                                    key={player.number}
                                                    value={player.number}
                                                >
                                                    {player.name}
                                                </IonSelectOption>
                                            ))}
                                        </IonSelect>
                                    );
                                }}
                            />
                        </IonItem>
                    </IonCard>

                    <IonCard color="transparent">
                        <IonButton
                            expand="block"
                            color="light"
                            onClick={handleSubmit(savePlayerOfTheMatch)}
                        >
                            {t("general.save")}
                        </IonButton>
                    </IonCard>
                </form>
            </IonContent>
            <ToastComponent
                isOpen={showToast}
                position="bottom"
                message={toastTitle}
                onDismiss={() => setShowToast(false)}
            />
        </ScrollableContent>
    );
};
