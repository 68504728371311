import { UXCamConfig } from "../constants/UXCamConfig";

export function initializeUXCam(username: string) {
    window.UXCam.optIntoSchematicRecordings();
    window.UXCam.setUserIdentity(username);
    window.UXCam.startWithConfiguration(UXCamConfig);
}

export function uxCamTagScreen(screenName: string) {
    window.UXCam.tagScreenName(screenName);
}
