import {
    IonCard,
    IonIcon,
    IonItem,
    IonLabel,
    IonText,
    IonToggle,
} from "@ionic/react";
import { addCircle, removeCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { FiltersResultsModel } from "../../redux/models/filtersModel";

interface FiltersResultStatsProps {
    filters: FiltersResultsModel;
    updateFilter: (props: Partial<FiltersResultsModel>) => void;
}

export function FiltersResultStats({
    filters,
    updateFilter,
}: FiltersResultStatsProps) {
    const { t } = useTranslation();

    const { yellowCards, redCards, goals } = filters.stats;

    const decrementValue = (value: number) => (value > 0 ? value - 1 : 0);

    return (
        <>
            <IonCard>
                <IonItem lines="full">
                    <IonLabel>
                        {t("stats.stats.Filters.showGamesWith")}
                    </IonLabel>
                </IonItem>

                <IonItem className="item-title line">
                    <IonLabel className="filters-officials-label">
                        {t("general.yellow")}
                    </IonLabel>
                    <div className="add-remove-button-container">
                        <IonIcon
                            className="icon-add-remove"
                            icon={removeCircle}
                            size="large"
                            onClick={() =>
                                updateFilter({
                                    stats: {
                                        ...filters.stats,
                                        yellowCards: {
                                            value: decrementValue(
                                                yellowCards.value
                                            ),
                                        },
                                    },
                                })
                            }
                        />
                        <IonText>{filters.stats?.yellowCards?.value}</IonText>
                        <IonIcon
                            className="icon-add-remove"
                            icon={addCircle}
                            size="large"
                            onClick={() =>
                                updateFilter({
                                    stats: {
                                        ...filters.stats,
                                        yellowCards: {
                                            value: yellowCards.value + 1,
                                        },
                                    },
                                })
                            }
                        />
                    </div>
                </IonItem>

                <IonItem className="item-title line">
                    <IonLabel className="filters-officials-label">
                        {t("general.red")}
                    </IonLabel>
                    <div className="add-remove-button-container">
                        <IonIcon
                            className="icon-add-remove"
                            icon={removeCircle}
                            size="large"
                            onClick={() =>
                                updateFilter({
                                    stats: {
                                        ...filters.stats,
                                        redCards: {
                                            value: decrementValue(
                                                redCards.value
                                            ),
                                        },
                                    },
                                })
                            }
                        />
                        <IonText>{filters.stats?.redCards?.value}</IonText>
                        <IonIcon
                            className="icon-add-remove"
                            icon={addCircle}
                            size="large"
                            onClick={() =>
                                updateFilter({
                                    stats: {
                                        ...filters.stats,
                                        redCards: {
                                            value: redCards.value + 1,
                                        },
                                    },
                                })
                            }
                        />
                    </div>
                </IonItem>
                <IonItem className="item-title line">
                    <IonLabel className="filters-officials-label">
                        {t("stats.stats.goalsScored")}
                    </IonLabel>
                    <div className="add-remove-button-container">
                        <IonIcon
                            className="icon-add-remove"
                            icon={removeCircle}
                            size="large"
                            onClick={() =>
                                updateFilter({
                                    stats: {
                                        ...filters.stats,
                                        goals: {
                                            value: decrementValue(goals.value),
                                        },
                                    },
                                })
                            }
                        />
                        <IonText>{filters.stats?.goals?.value}</IonText>
                        <IonIcon
                            className="icon-add-remove"
                            icon={addCircle}
                            size="large"
                            onClick={() =>
                                updateFilter({
                                    stats: {
                                        ...filters.stats,
                                        goals: { value: goals.value + 1 },
                                    },
                                })
                            }
                        />
                    </div>
                </IonItem>

                <IonItem className="item-title line">
                    <IonLabel className="filters-officials-label">
                        {t("stats.stats.Filters.extraTime")}
                    </IonLabel>
                    <IonToggle
                        data-testid="filter-extra-time"
                        className="filters-toggle"
                        checked={filters.extraTime}
                        onIonChange={(e) =>
                            updateFilter({
                                extraTime: e.detail.checked,
                            })
                        }
                    />
                </IonItem>
                <IonItem className="item-title line">
                    <IonLabel className="filters-officials-label">
                        {t("fixture.FixtureSummary.atPenalties")}
                    </IonLabel>
                    <IonToggle
                        data-testid="filter-penalties"
                        className="filters-toggle"
                        checked={filters.penalties}
                        onIonChange={(e) =>
                            updateFilter({
                                penalties: e.detail.checked,
                            })
                        }
                    />
                </IonItem>
                <IonItem className="item-title line">
                    <IonLabel className="filters-officials-label">
                        {t("model.WatchFixture.abandonedDisplayName")}
                    </IonLabel>
                    <IonToggle
                        className="filters-toggle"
                        checked={filters.abandoned}
                        onIonChange={(e) =>
                            updateFilter({
                                abandoned: e.detail.checked,
                            })
                        }
                    />
                </IonItem>
            </IonCard>
        </>
    );
}
