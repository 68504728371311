import React, { FC } from "react";
import { IonSelectOption } from "@ionic/react";
import { map } from "lodash";
import { CustomIonFormProps } from "./components";
import { R6IonSelect } from "../../components/r6ionic/R6IonSelect";
import { useTemplates } from "../../components/hooks";

export const TemplateSelect: FC<CustomIonFormProps> = (props) => {
    const templates = useTemplates();
    return (
        <R6IonSelect {...props} interface={"popover"}>
            {templates &&
                map(templates.templates, (template, idx) => (
                    <IonSelectOption key={idx} value={template._id}>
                        {template.name}
                    </IonSelectOption>
                ))}
        </R6IonSelect>
    );
};
