import { IonCard, IonItem, IonLabel, useIonRouter } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../route/constants";
import { getLanguageCode } from "../../../../utils/localeUtils";
import { isAndroid, isIos } from "../../../../services/platformDetection";

export const Manage: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();

    return (
        <IonCard>
            <IonItem
                className="item-title-container rounded-corners-top"
                lines="full"
            >
                {t("settings.SettingsMain.manage")}
            </IonItem>
            <IonItem
                data-testid="manage-templates-button"
                className="item-title"
                button
                lines="full"
                onClick={() => route.push(routes.refsixMoreSettingsTemplates)}
            >
                <IonLabel>
                    {t("settings.SettingsMain.manageTemplates")}
                </IonLabel>
            </IonItem>
            <IonItem
                data-testid="manage-watches-button"
                className="item-title"
                button
                lines="full"
                onClick={() =>
                    route.push(routes.refsixMoreSettingsManageWatches)
                }
            >
                <IonLabel>{t("settings.SettingsMain.manageWatches")}</IonLabel>
            </IonItem>
            <IonItem
                button
                onClick={() =>
                    route.push(routes.refsixMoreSettingsMisconductCodes)
                }
                data-testid="manage-misconduct-button"
                className="item-title"
                lines="full"
            >
                <IonLabel>
                    {t("settings.SettingsMain.manageMisconduct")}
                </IonLabel>
            </IonItem>
            <IonItem
                button
                onClick={() => route.push(routes.teamPacks)}
                data-testid="manage-teampacks-button"
                className="item-title"
                lines="none"
            >
                <IonLabel>
                    {t("settings.SettingsMain.manageTeamPacks")}
                </IonLabel>
            </IonItem>
            {/* TODO make all languages when translations are in */}
            {getLanguageCode() === "en" && (isIos() || isAndroid()) && (
                <IonItem
                    button
                    onClick={() =>
                        route.push(routes.refsixMoreSettingsPushConsent)
                    }
                    data-testid="manage-push-consent-button"
                    className="item-title"
                    lines="none"
                >
                    <IonLabel>{t("notifications.pushNotifications")}</IonLabel>
                </IonItem>
            )}
        </IonCard>
    );
};
