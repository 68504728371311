import {
    IonButton,
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonToggle,
    useIonRouter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MatchPhone, OfficialRole } from "refsix-js-models";
import { uniqSort } from "../../utils/arrayUtils";
import {
    FiltersModel,
    FiltersResultsModel,
} from "../../redux/models/filtersModel";
import {
    setMatchesFilters,
    setResultsFilters,
} from "../../redux/actions/filters";
import { RootState } from "../../redux/store";
import {
    getAwayTeams,
    getCompetitions,
    getHomeTeams,
    getKeywords,
    getMatchOfficials,
    getVenues,
} from "../../services/matchService";
import {
    createDefaultFiltersSetting,
    officialRoleArray,
} from "./filtersHelper";
import { FiltersDatePicker } from "./filtersDatepicker";
import { FiltersResultStats } from "./filtersResultsStats";
import { HeaderComponent } from "../../components";
import { MatchType } from "./enums";
import "./filters.css";
import "../../css/forms.css";
import { trackEvent } from "../../services/analytics/analyticsService";

interface FilterProps {
    matches: MatchPhone[];
    matchType: MatchType;
    closeModal: () => void;
}

export default function Filters({
    matches,
    matchType,
    closeModal,
}: FilterProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const currentFilter = useSelector((state: RootState) =>
        matchType === MatchType.Fixture
            ? state.filters.filterMatches
            : state.filters.filterResults
    );

    const [newFilter, setNewFilter] = useState(
        currentFilter || createDefaultFiltersSetting(matchType)
    );

    useEffect(() => {
        // Set referee to true if all disabled
        if (
            !newFilter.roles.referee &&
            !newFilter.roles.assistant &&
            !newFilter.roles.fourthOfficial &&
            !newFilter.roles.observer
        ) {
            updateNewFilter({
                roles: { ...newFilter.roles, referee: true },
            });
        }
    }, [newFilter.roles]);

    const applyNewFilter = () => {
        const filterType =
            matchType === MatchType.Fixture
                ? "Fixture"
                : matchType === MatchType.Trends
                ? "Trends"
                : "Result";

        if (matchType === MatchType.Fixture) {
            dispatch(setMatchesFilters(newFilter));
        } else {
            dispatch(setResultsFilters(newFilter));
        }
        trackEvent("filtersApplied", { filterType });
    };

    const updateNewFilter = (updatedProps: Partial<FiltersModel>) => {
        setNewFilter((prev) => ({ ...prev, ...updatedProps }));
    };

    const competitions = uniqSort(getCompetitions(matches));
    const venues = uniqSort(getVenues(matches));
    const teams = uniqSort([
        ...getHomeTeams(matches),
        ...getAwayTeams(matches),
    ]);
    const matchOfficials = uniqSort(getMatchOfficials(matches));
    const keywords = uniqSort(getKeywords(matches));

    return (
        <>
            <HeaderComponent
                showLeftSideBtn
                onLeftBtnClicked={() => {
                    setNewFilter(createDefaultFiltersSetting(matchType));
                }}
                leftSideBtnTxt={t("stats.stats.Filters.reset")}
                title={t("stats.stats.Filters.filterMatches")}
                headerEndText={t("general.apply")}
                endBtnTestId="filterApply"
                onClickEndButton={() => {
                    applyNewFilter();
                    closeModal();
                }}
                titleTestId={`filter-modal-heading-${matchType}`}
            />
            <IonContent data-testid="filter">
                <div style={{ marginTop: 20 }}>
                    <FiltersDatePicker
                        filters={newFilter}
                        matchType={matchType}
                        updateFilter={updateNewFilter}
                    />

                    {/* Competition */}
                    <IonCard>
                        <IonItem lines="full">
                            <IonLabel>
                                {t("fixture.FixtureNew.competition")}
                            </IonLabel>
                            {newFilter.competition.length > 0 && (
                                <IonButton
                                    className="r6-form-link-button clear"
                                    onClick={() =>
                                        updateNewFilter({ competition: [] })
                                    }
                                >
                                    {t("fixture.FixtureTeam.clear")}
                                </IonButton>
                            )}
                        </IonItem>
                        <IonItem className="item-title" lines="none">
                            <IonLabel className="create-match-official-role">
                                {t("fixture.FixtureNew.competition")}
                            </IonLabel>
                            <IonSelect
                                className="select-text"
                                value={newFilter.competition}
                                placeholder={""}
                                onIonChange={(e) =>
                                    updateNewFilter({
                                        competition: e.detail.value,
                                    })
                                }
                                interface="popover"
                                multiple
                            >
                                {competitions.map((comp, index) => (
                                    <IonSelectOption key={index} value={comp}>
                                        {comp}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                    </IonCard>

                    {/* Venue */}
                    <IonCard>
                        <IonItem lines="full">
                            <IonLabel>{t("fixture.FixtureNew.venue")}</IonLabel>
                            {newFilter.venue && newFilter.venue.length > 0 && (
                                <IonButton
                                    className="r6-form-link-button clear"
                                    onClick={() =>
                                        updateNewFilter({ venue: [] })
                                    }
                                >
                                    {t("fixture.FixtureTeam.clear")}
                                </IonButton>
                            )}
                        </IonItem>
                        <IonItem className="item-title" lines="none">
                            <IonLabel className="create-match-official-role">
                                {t("fixture.FixtureNew.venue")}
                            </IonLabel>
                            <IonSelect
                                className="select-text"
                                value={newFilter.venue || []}
                                placeholder={""}
                                onIonChange={(e) =>
                                    updateNewFilter({
                                        venue: e.detail.value,
                                    })
                                }
                                interface="popover"
                                multiple
                            >
                                {venues.map((venue, index) => (
                                    <IonSelectOption key={index} value={venue}>
                                        {venue}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                    </IonCard>

                    {/* Teams */}
                    <IonCard>
                        <IonItem lines="full">
                            <IonLabel>{t("general.teams")}</IonLabel>
                            {newFilter.team.length > 0 && (
                                <IonButton
                                    className="r6-form-link-button clear"
                                    onClick={() =>
                                        updateNewFilter({ team: [] })
                                    }
                                >
                                    {t("fixture.FixtureTeam.clear")}
                                </IonButton>
                            )}
                        </IonItem>
                        <IonItem className="item-title filters" lines="none">
                            <IonLabel className="create-match-official-role">
                                {t("general.team")}
                            </IonLabel>
                            <IonSelect
                                className="select-text"
                                value={newFilter.team}
                                placeholder={""}
                                onIonChange={(e) =>
                                    updateNewFilter({ team: e.detail.value })
                                }
                                interface="popover"
                                multiple
                            >
                                {teams.map((team, index) => (
                                    <IonSelectOption key={index} value={team}>
                                        {team}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                    </IonCard>

                    {/* Match Officials */}
                    <IonCard>
                        <IonItem lines="full">
                            <IonLabel>
                                {t("fixture.FixtureSummary.matchOfficials")}
                            </IonLabel>
                            {newFilter.officials.length > 0 && (
                                <IonButton
                                    className="r6-form-link-button clear"
                                    onClick={() =>
                                        updateNewFilter({ officials: [] })
                                    }
                                >
                                    {t("fixture.FixtureTeam.clear")}
                                </IonButton>
                            )}
                        </IonItem>

                        <IonItem className="item-title filters" lines="none">
                            <IonLabel className="create-match-official-role">
                                {t("fixture.FixtureSummary.official")}
                            </IonLabel>
                            <IonSelect
                                className="select-text"
                                value={newFilter.officials}
                                placeholder={""}
                                onIonChange={(e) =>
                                    updateNewFilter({
                                        officials: e.detail.value,
                                    })
                                }
                                interface="popover"
                                multiple
                            >
                                {matchOfficials.map((off, index) => (
                                    <IonSelectOption key={index} value={off}>
                                        {off}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                    </IonCard>

                    {/* Official role */}
                    <IonCard>
                        <IonItem lines="full">
                            <IonLabel>
                                {t("stats.stats.Filters.officialRole")}
                            </IonLabel>
                        </IonItem>
                        {officialRoleArray.map(({ title, role }, index) => (
                            <IonItem
                                key={index}
                                // Remove the line on the last item
                                lines={
                                    role === OfficialRole.observer
                                        ? "none"
                                        : "full"
                                }
                            >
                                <div
                                    className={`role-icons icon-filters ${role}`}
                                />
                                <IonLabel className="filters-officials-label">
                                    {t(title)}
                                </IonLabel>
                                <IonToggle
                                    data-testid={`filter-role-${role}`}
                                    className="filters-toggle"
                                    checked={newFilter.roles[role]}
                                    onIonChange={() => {
                                        updateNewFilter({
                                            roles: {
                                                ...newFilter.roles,
                                                [role]: !newFilter.roles[role],
                                            },
                                        });
                                    }}
                                />
                            </IonItem>
                        ))}
                    </IonCard>

                    {/* Keywords */}
                    {keywords.length > 0 && (
                        <IonCard>
                            <IonItem lines="full">
                                <IonLabel>
                                    {t("general.keywords.title")}
                                </IonLabel>
                                {newFilter.keywords.length > 0 && (
                                    <IonButton
                                        className="r6-form-link-button clear"
                                        onClick={() =>
                                            updateNewFilter({ keywords: [] })
                                        }
                                    >
                                        {t("fixture.FixtureTeam.clear")}
                                    </IonButton>
                                )}
                            </IonItem>
                            <IonItem
                                className="item-title filters"
                                lines="none"
                            >
                                <IonLabel className="create-match-official-role">
                                    {t("general.keywords.title")}
                                </IonLabel>
                                <IonSelect
                                    className="select-text"
                                    value={newFilter.keywords}
                                    placeholder={""}
                                    onIonChange={(e) =>
                                        updateNewFilter({
                                            keywords: e.detail.value,
                                        })
                                    }
                                    interface="popover"
                                    multiple
                                    data-testid="keywordsFilter"
                                >
                                    {keywords.map((keyword, index) => (
                                        <IonSelectOption
                                            key={index}
                                            value={keyword}
                                        >
                                            {keyword}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            </IonItem>
                        </IonCard>
                    )}

                    {(matchType === MatchType.Result ||
                        matchType === MatchType.Trends) && (
                        <FiltersResultStats
                            filters={newFilter as FiltersResultsModel}
                            updateFilter={updateNewFilter}
                        />
                    )}
                </div>
            </IonContent>
        </>
    );
}
