import { RefObject, useLayoutEffect, useRef, useState } from "react";
import { debounce, DebouncedFunction } from "ts-debounce";

export interface DimensionObject {
    width: number;
    height: number;
    top: number;
    left: number;
    x: number;
    y: number;
    right: number;
    bottom: number;
}

type UseDimensionsHook = [RefObject<any>, DimensionObject | null];

function getDimensionObject(node: HTMLElement): DimensionObject {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: "x" in rect ? rect.x : rect.top,
        left: "y" in rect ? rect.y : rect.left,
        x: "x" in rect ? rect.x : rect.left,
        y: "y" in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    };
}

export const useDimensions = (): UseDimensionsHook => {
    const [dimensions, setDimensions] = useState<DimensionObject | null>(null);
    const ref = useRef<HTMLElement | null>(null);

    const measure: DebouncedFunction<[], () => void> = debounce((): void => {
        window.requestAnimationFrame(() => {
            if (ref.current) setDimensions(getDimensionObject(ref.current));
        });
    });

    useLayoutEffect(() => {
        measure();
        window.addEventListener("resize", measure);

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;
                measure();
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            window.removeEventListener("resize", measure);
            resizeObserver && resizeObserver.disconnect();
        };
    }, [ref.current]);

    return [ref, dimensions];
};
