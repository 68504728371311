import {
    IonButton,
    IonContent,
    IonFooter,
    IonPage,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../components";
import { OnBoardingHeadingComponent } from "./onBoardingHeadingComponent";
import { routes } from "../../route/constants";
import { useDispatch } from "react-redux";
import { setShowOnBoardingFlow } from "../../redux/actions/appStatus";
import { useProfile } from "../../components/hooks";
import { MatchHeader } from "../addResult/matchHeader";
import { MatchPhone, OfficialRole } from "refsix-js-models";
import moment from "moment";
import refsixSquareLogo from "../../assets/images/icon/refsix-square-icon.png";
import assignrSquareLogo from "../../assets/images/icon/assignr-square-icon.png";

export const DanishOnBoardingSetupComplete: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const dispatch = useDispatch();

    return (
        <>
            <IonContent>
                <OnBoardingHeadingComponent
                    progressValue={1}
                    upperTitleInBold={t(
                        "integrations.dbuIntegrationPopup.title"
                    )}
                    secondTitle={t(
                        "integrations.dbuIntegrationPopup.descriptionMessage"
                    )}
                />
                <MatchHeader
                    match={
                        {
                            date: moment().toISOString(),
                            homeTeam: t("general.home"),
                            awayTeam: t("general.away"),
                            competition: t("fixture.FixtureNew.competition"),
                            venue: t("fixture.FixtureNew.venue"),
                            officialRole: OfficialRole.referee,
                        } as MatchPhone
                    }
                />
            </IonContent>
            <IonFooter>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 20,
                    }}
                >
                    <IonButton
                        data-testid="button-go-maybe-later"
                        style={{ color: "white" }}
                        fill="clear"
                        onClick={() => {
                            dispatch(setShowOnBoardingFlow(false));
                            route.push(
                                routes.refsixFixtures,
                                "root",
                                "replace"
                            );
                        }}
                    >
                        {t("general.maybeLater")}
                    </IonButton>
                    <IonButton
                        data-testid="button-go-to-dbu-integration"
                        onClick={() => {
                            dispatch(setShowOnBoardingFlow(false));
                            route.push(
                                routes.refsixDbuIntegration,
                                "root",
                                "replace"
                            );
                        }}
                    >
                        {t("general.yes")}
                    </IonButton>
                </div>
            </IonFooter>
        </>
    );
};
export const UsOnBoardingSetupComplete: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const dispatch = useDispatch();

    return (
        <>
            <IonContent>
                <OnBoardingHeadingComponent
                    progressValue={1}
                    upperTitleInBold={t("integrations.assignr.startOAuthFlow")}
                    secondTitle={t(
                        "integrations.assignr.integrationDescription"
                    )}
                />
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        paddingTop: "36px",
                        paddingBottom: "21px",
                    }}
                >
                    <img style={{ width: "64px" }} src={refsixSquareLogo} />
                    <IonText
                        style={{
                            margin: "0 20px",
                            fontSize: "30px",
                            fontWeight: "700",
                        }}
                    >
                        +
                    </IonText>
                    <img style={{ width: "64px" }} src={assignrSquareLogo} />
                </div>
            </IonContent>
            <IonFooter>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 20,
                    }}
                >
                    <IonButton
                        data-testid="button-go-maybe-later"
                        style={{ color: "white" }}
                        fill="clear"
                        onClick={() => {
                            dispatch(setShowOnBoardingFlow(false));
                            route.push(
                                routes.refsixFixtures,
                                "root",
                                "replace"
                            );
                        }}
                    >
                        {t("general.maybeLater")}
                    </IonButton>
                    <IonButton
                        data-testid="button-go-to-dbu-integration"
                        onClick={() => {
                            dispatch(setShowOnBoardingFlow(false));
                            route.push(
                                routes.refsixAssignrIntegrationAutoStart,
                                "root",
                                "replace"
                            );
                        }}
                    >
                        {t("general.yes")}
                    </IonButton>
                </div>
            </IonFooter>
        </>
    );
};
export const OnBoardingSetupComplete: FC = () => {
    const { t } = useTranslation();
    const userProfile = useProfile();
    const isDanishUser = userProfile?.country?.toUpperCase() === "DK";
    const isUsUser = userProfile?.country?.toUpperCase() === "US";
    const route = useIonRouter();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isUsUser && !isDanishUser) {
            dispatch(setShowOnBoardingFlow(false));

            route.push(routes.refsixFixtures, "root", "replace");
        }
    }, [isUsUser, isDanishUser, route, dispatch]);

    return (
        <IonPage className="onboarding-complete-page">
            <HeaderComponent
                titleTestId="setup-complete-header"
                title={t("help.onBoarding.setupComplete")}
            />
            {isDanishUser ? (
                <DanishOnBoardingSetupComplete />
            ) : isUsUser ? (
                <UsOnBoardingSetupComplete />
            ) : null}
        </IonPage>
    );
};
