import { WatchStatus } from "./watchStatus";
import { WatchService } from "./watchService";
import { AppleWatch } from "./appleWatch";
import { isAndroid, isIos } from "../platformDetection";
import { WearOsWatch } from "./wearOsWatch";
import { MockWatch } from "./mockWatch";

export const watchStatusInstance =
    isAndroid() || isIos() ? new WatchStatus() : null;
export const appleWatchInstance = isIos() ? new AppleWatch() : null;
export const wearOsInstance = isAndroid() ? new WearOsWatch() : null;
export const mockWatchInstance =
    process.env.REACT_APP_MOCK_WATCH_SERVICE === "true"
        ? new MockWatch()
        : null;
export const watchInstance = appleWatchInstance || wearOsInstance || null;
export const watchServiceInstance = watchInstance
    ? new WatchService(watchInstance)
    : process.env.REACT_APP_MOCK_WATCH_SERVICE === "true" && mockWatchInstance
    ? new WatchService(mockWatchInstance)
    : null;
