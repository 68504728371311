import { IonCol, IonItem, IonText } from "@ionic/react";
import { COLORS_SPRINT_CATEGORY } from "../constants/StatsColorPalette";
import {
    metersPerSecondsToKphOrMph,
    SprintCategoryConstants,
} from "refsix-core";
import React from "react";
import { SprintCategoryNames } from "../enums/SprintCategoryNames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const SprintLegendKeysComponent = () => {
    const sprintCategories: string[] = [
        SprintCategoryNames.low,
        SprintCategoryNames.medium,
        SprintCategoryNames.high,
    ];
    const { t } = useTranslation();
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );

    return (
        <>
            {/* Setting sprintCategories text above the legend */}
            <IonItem lines="none">
                {sprintCategories.map((key, index) => {
                    return (
                        <IonCol key={index} size="4" className="align-center">
                            <IonText className="text-small dark-color">
                                {t(
                                    "fixture.FixtureSummaryStats.sprintmapKey." +
                                        key
                                )}
                            </IonText>
                        </IonCol>
                    );
                })}
            </IonItem>

            {/* Showing legend with unit conversion and colors sprints*/}
            <IonItem lines="none">
                {COLORS_SPRINT_CATEGORY.map((color, index) => {
                    const category = sprintCategories[
                        index
                    ] as SprintCategoryNames;
                    return (
                        <IonCol
                            key={index}
                            size="4"
                            style={{ backgroundColor: color }}
                            className="align-center"
                        >
                            <IonText className="text-small text-black">
                                {`${metersPerSecondsToKphOrMph(
                                    SprintCategoryConstants[category]
                                        .lowerBound,
                                    useImperial
                                )} - ${metersPerSecondsToKphOrMph(
                                    SprintCategoryConstants[category]
                                        .upperBound,
                                    useImperial
                                )} ${useImperial ? "mph" : "kmph"}`}
                            </IonText>
                        </IonCol>
                    );
                })}
            </IonItem>
        </>
    );
};

export default SprintLegendKeysComponent;
