import { Settings } from "../models/refsixState";
import { SET_SETTINGS, SET_APPLE_LOGIN_CACHE_VALUE } from "../actions/types";

const initialState = new Settings();

function settingsReducer(state: Settings = initialState, action: any) {
    switch (action.type) {
        case SET_SETTINGS:
            return { ...state, ...action.settings };
        case SET_APPLE_LOGIN_CACHE_VALUE:
            return {
                ...state,
                appleLoginCache: {
                    ...state.appleLoginCache,
                    [action.key]: action.value,
                },
            };
        default:
            return state;
    }
}

export default settingsReducer;
