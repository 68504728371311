import { useLocation } from "react-router-dom";
import { trackPageView } from "./analyticsService";
import * as Sentry from "@sentry/react";
import { uxCamTagScreen } from "../../utils/UXCam";

/**
 * This component is just a black hole into which all route changes fall.
 */
export function PageViewAnalytics() {
    const location = useLocation();
    // after the first time this fires, the location is wrapped in another object, hence location.location
    if (location && location.location) {
        trackPageView(location.location.pathname);
        Sentry.addBreadcrumb({
            category: "pageView",
            message: location.location.pathname,
        });

        if (window.UXCam) {
            uxCamTagScreen(location.location.pathname);
        }
    }
    return null;
}
