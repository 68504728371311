import {
    IonButton,
    IonCard,
    IonCol,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
} from "@ionic/react";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DobModal.css";

interface DobModalProps {
    openDobModal: boolean;

    onDismiss(): void;

    dob(date: string): void;
}

export const DobModal: FC<DobModalProps> = ({
    openDobModal,
    onDismiss,
    dob,
}) => {
    const { t } = useTranslation();
    const [date, setDate] = useState<string>("");

    return (
        <IonModal
            isOpen={openDobModal}
            onDidDismiss={() => onDismiss()}
            initialBreakpoint={1}
            showBackdrop
        >
            <IonCard className="ion-card-transparent">
                <IonItem className="teams-segments-container">
                    <IonText>
                        {t("stats.stats.HeartRate.dateOfBirthPopUp.title")}
                    </IonText>
                </IonItem>

                <IonItem className="teams-segments-container">
                    <IonRow>
                        <IonText>
                            {t(
                                "stats.stats.HeartRate.dateOfBirthPopUp.overviewDescription"
                            )}
                        </IonText>

                        <IonText>
                            {t(
                                "stats.stats.HeartRate.dateOfBirthPopUp.inputDescription"
                            )}
                        </IonText>
                    </IonRow>
                </IonItem>

                <IonItem className="margin" lines="none">
                    <IonLabel>{t("stats.stats.Filters.date")}</IonLabel>
                    <input
                        data-testid="datePicker"
                        className="date-input large"
                        type="date"
                        value={moment(date).format("YYYY-MM-DD")}
                        onChange={(e) =>
                            setDate(moment(e.currentTarget.value).toISOString())
                        }
                    />
                </IonItem>
                <IonItem className="teams-segments-container">
                    <IonCol>
                        <IonButton expand="block" onClick={() => onDismiss()}>
                            {t("general.cancel")}
                        </IonButton>
                    </IonCol>

                    <IonCol>
                        <IonButton
                            data-testid="saveDobDate"
                            expand="block"
                            onClick={() => dob(date)}
                        >
                            {t("general.save")}
                        </IonButton>
                    </IonCol>
                </IonItem>
            </IonCard>
        </IonModal>
    );
};
