import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonPage,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import RefsixLogo from "../../assets/images/refsix-words-h80px.png";
import Slide1 from "../../assets/images/registerOrLogin/slide1.jpg";
import HeatMapImage from "../../assets/images/registerOrLogin/heatmap.png";
import Slide2 from "../../assets/images/registerOrLogin/slide2.jpg";
import TimersImage from "../../assets/images/registerOrLogin/timers.png";
import Slide3 from "../../assets/images/registerOrLogin/slide3.jpg";
import MatchReportsImage from "../../assets/images/registerOrLogin/matchReports.png";
import Slide4 from "../../assets/images/registerOrLogin/slide4.jpg";
import TrendsImage from "../../assets/images/registerOrLogin/trends.png";
import { useTranslation } from "react-i18next";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "./swiper.css";
import "./registerOrLogin.css";
import { routes } from "../../route/constants";

SwiperCore.use([Autoplay, Pagination]);

export const RegisterOrLogin: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();

    const imageSlides = [
        {
            image: Slide1,
            imageFront: HeatMapImage,
            alt: "slide1",
            title: t("firstPageCarousel.slide1Title"),
        },
        {
            image: Slide2,
            imageFront: TimersImage,
            alt: "slide2",
            title: t("firstPageCarousel.slide2Title"),
        },
        {
            image: Slide3,
            imageFront: MatchReportsImage,
            alt: "slide3",
            title: t("firstPageCarousel.slide3Title"),
        },
        {
            image: Slide4,
            imageFront: TrendsImage,
            alt: "slide4",
            title: t("firstPageCarousel.slide4Title"),
        },
    ];

    return (
        <IonPage className="register-or-login">
            <IonContent data-testid="register-or-login-page">
                <div className="register-refsix-image-container">
                    <img
                        src={RefsixLogo}
                        className="register-refsix-logo"
                        alt="refsix-image-text"
                    />
                </div>
                <Swiper
                    slidesPerView={1}
                    pagination={true}
                    autoplay={false}
                    loop={true}
                >
                    {imageSlides.map((slide, index) => (
                        <SwiperSlide
                            key={index}
                            className="register-swiper-slide"
                        >
                            <div
                                className="register-slider-background-image"
                                style={{
                                    backgroundImage: `url(${slide.image})`,
                                }}
                            />
                            <div className="register-slider-front-content">
                                <img
                                    src={slide.imageFront}
                                    alt={slide.alt}
                                    className="register-slider-front-image"
                                />
                                <IonText
                                    style={{
                                        fontSize: "22px",
                                        marginTop: 10,
                                        padding: 5,
                                        textAlign: "center",
                                    }}
                                >
                                    {slide.title}
                                </IonText>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </IonContent>
            <IonFooter className="register-footer">
                <IonCard color="transparent">
                    <IonButton
                        data-testid="register-or-login-register-button"
                        expand="block"
                        onClick={() =>
                            route.push(routes.register, "root", "replace")
                        }
                    >
                        {t("general.register")}
                    </IonButton>
                    <IonButton
                        data-testid="register-or-login-login-button"
                        style={{ fontSize: 16 }}
                        onClick={() =>
                            route.push(routes.login, "root", "replace")
                        }
                        fill="clear"
                        expand="block"
                    >
                        {t("login.login.signIn")}
                    </IonButton>
                </IonCard>
            </IonFooter>
        </IonPage>
    );
};
