import { ReasonType } from "./enums/reasonType";

export class Reason {
    code: string;
    reason: string;
    type: ReasonType;
    secondYellow: boolean;
    sinBin?: boolean;
    subCodes?: Reason[];
    teamOfficial?: boolean;

    constructor(
        code: string,
        reason: string,
        type: ReasonType,
        secondYellow: boolean,
        sinBin?: boolean,
        subCodes?: Reason[],
        teamOfficial?: boolean
    ) {
        this.code = code;
        this.reason = reason;
        this.type = type;
        this.secondYellow = secondYellow;
        this.sinBin = sinBin;
        this.subCodes = subCodes;
        this.teamOfficial = teamOfficial;
    }

    static fromJSON(json: any) {
        let subCodes: Reason[] =
            json.subCodes &&
            json.subCodes.map(function (reason: any) {
                return Reason.fromJSON(reason);
            });

        return new Reason(
            json.code,
            json.reason,
            json.type,
            json.secondYellow,
            json.sinBin,
            subCodes,
            json.teamOfficial
        );
    }
}
