import { store } from "../redux/store";
import request from "./request";
import { addUserProfile } from "../redux/actions/addUserProfile";
import { UserProfile } from "../models/userProfile";
import { copyObject } from "refsix-core";
import { setCurrentUser } from "../redux/actions/currentUser";
import * as Sentry from "@sentry/react";
import moment from "moment";

export async function downloadProfile(username: string) {
    return request.get<UserProfile>("/profile/" + username);
}

/**
 * This function gets the current profile from the state, or there isn't a record
 * for that username then downloads it instead.
 * @param username
 */
export async function getProfile(username: string) {
    const profileMap = store.getState().profiles.profiles;
    if (profileMap && profileMap[username]) {
        return Promise.resolve(profileMap[username]);
    } else {
        try {
            const response = await downloadProfile(username);
            store.dispatch(addUserProfile(response.data)); // cache for later
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

/**
 * Update the profile on the server and the redux store
 * @param username
 * @param data
 */
export async function updateProfile(username: string, data: UserProfile) {
    try {
        const response = await request.put<UserProfile>(
            "/profile/" + username,
            { profile: data }
        );
        store.dispatch(addUserProfile(response.data));
        if (username === store.getState().auth?.session?.user_id) {
            // if it is for the current user, update their profile too
            store.dispatch(setCurrentUser(response.data));
        }
        return response;
    } catch (error) {
        console.warn("Couldn't update profile");
        return Promise.reject();
    }
}

/**
 * Format old profile types (e.g. dob from Date to ISO string). Returns a new object.
 */
export function formatProfile(profile: UserProfile) {
    const newProfile = copyObject(profile);

    newProfile.biometrics = newProfile.biometrics || {};
    if (newProfile.biometrics.weight === null) {
        newProfile.biometrics.weight = undefined;
    }
    if (newProfile.biometrics.height === null) {
        newProfile.biometrics.height = undefined;
    }

    return newProfile;
}

/**
 * Returns the customer token from the nolt service for SSO
 */
export async function getNoltToken(): Promise<string> {
    try {
        const {
            data: { token },
        } = await request.get<{ token: string }>("/nolt-token/");
        return token;
    } catch (err) {
        Sentry.captureMessage(
            `Request for user nolt token failed: ${JSON.stringify(err)}`
        );
        console.log("Request for user nolt token failed");
        throw err;
    }
}
