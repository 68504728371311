import React, { FC } from "react";
import { IonSelectOption } from "@ionic/react";
import { OfficialRole } from "refsix-js-models";
import { CustomIonFormProps } from "./components";
import { R6IonSelect } from "../../components/r6ionic/R6IonSelect";
import { useTranslation } from "react-i18next";

export const OfficialRoleSelect: FC<CustomIonFormProps> = (props) => {
    const { t } = useTranslation();
    return (
        <R6IonSelect {...props} interface={"popover"}>
            <IonSelectOption value={OfficialRole.referee}>
                {t("fixture.FixtureNew.referee")}
            </IonSelectOption>
            <IonSelectOption value={OfficialRole.assistant}>
                {t("fixture.FixtureNew.assistant")}
            </IonSelectOption>
            <IonSelectOption value={OfficialRole.observer}>
                {t("fixture.FixtureNew.observer")}
            </IonSelectOption>
            <IonSelectOption value={OfficialRole.fourthOfficial}>
                {t("fixture.FixtureNew.fourthOfficial")}
            </IonSelectOption>
        </R6IonSelect>
    );
};
