import { FC, useEffect, useState } from "react";
import {
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonSelectOption,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import {
    Controller,
    FieldError,
    useFieldArray,
    useFormContext,
    useWatch,
} from "react-hook-form";
import "./components.css";
import {
    Integration,
    MatchPhone,
    MisconductCodes,
    SinBinSystem,
    Timings,
} from "refsix-js-models";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
    hasFeatureAccess,
    hasFeatureAccessFromState,
    isUserPlusEligible,
} from "../services/subscriptionService";
import {
    getDefaultTemplates,
    getTemplateById,
} from "../services/templateService";
import { addCircle } from "ionicons/icons";
import { fill, uniq } from "lodash";
import { R6SelectorComponent } from "./index";
import { RefsixState } from "../redux/models/refsixState";
import {
    findCodeByCountryId,
    getMisconductCodes,
} from "../services/misconductService";
import { trackProTeaser } from "../services/analytics/analyticsService";
import { updateUserActionsCheckList } from "../services/userActionsCheckListService";
import { GoProOrPlusAlertComponent } from "./upgrade/GoProOrPlusAlertComponent";

export enum MatchFormatMode {
    Match,
    Template,
}

interface MatchFormatProperties {
    mode: MatchFormatMode;
    integration?: Integration;
}

const MatchFormatComponent: FC<MatchFormatProperties> = ({
    mode,
    integration,
}) => {
    const { t } = useTranslation();
    const templates = useSelector(
        (state: RefsixState) =>
            state.templates.templates || getDefaultTemplates()
    );
    const route = useIonRouter();
    const hideSpecificInputsForIntegration = integration
        ? hasFeatureAccess(
              integration.partner + ":disableTemplateInMatchFormat"
          )
        : false;

    const {
        trigger,
        formState: { errors, defaultValues },
        formState,
        reset,
        getFieldState,
    } = useFormContext();

    const { fields: fieldsPeriods } = useFieldArray({
        name: "periods",
    });

    const { fields: fieldsIntervals } = useFieldArray({
        name: "interval",
    });
    const watchExtraHalfTimeLength = useWatch({
        name: "timings.extraTimeHalfLength",
    });

    const { isDirty: periodsNoIsDirty } = getFieldState("periodsNo", formState);
    const { isDirty: misconductCodeIdIsDirty } = getFieldState(
        "misconductCodeId",
        formState
    );
    const { isDirty: templateNameIsDirty } = getFieldState(
        "templateId",
        formState
    );

    const [showTimings, setShowTimings] = useState<boolean>(false);
    const [showIntervalTimings, setShowIntervalTimings] =
        useState<boolean>(false);

    const [proAlert, setProAlert] = useState<boolean>(false);

    const multiplePeriodsAccess = useSelector((state: RootState) => {
        return hasFeatureAccessFromState(
            state.subscriptions,
            "multiplePeriods"
        );
    });

    const watchPeriodsNo = useWatch({
        name: "periodsNo",
    });

    const watchExtraTimeAvailable = useWatch({
        name: "extraTimeAvailable",
    });

    const watchPeriod0 = useWatch({
        name: "periods.0",
    });

    const watchInterval0 = useWatch({
        name: "interval.0",
    });

    const watchMisconductId = useWatch({
        name: "misconductCodeId",
    });

    const watchSinBinSystem = useWatch({
        name: "sinBinSystem",
    });

    const watchTemplate = useWatch({
        name: "templateId",
        defaultValue: templates.defaultTemplate,
    });

    // If periods or intervals has different periods/intervals, expand the list of periods/intervals
    useEffect(() => {
        if (defaultValues) {
            if (uniq(defaultValues.periods).length > 1) {
                setShowTimings(true);
            }

            if (uniq(defaultValues.interval).length > 1) {
                setShowIntervalTimings(true);
            }
        }
    }, [defaultValues]);

    useEffect(() => {
        if (!multiplePeriodsAccess && periodsNoIsDirty) {
            setProAlert(true);
            if (!isUserPlusEligible()) {
                trackProTeaser("multiple-timers", "pro");
            } else if (isUserPlusEligible()) {
                trackProTeaser("multiple-timers", "plus");
            }
            reset((formValues) => ({ ...formValues, periodsNo: "2" }));
            return;
        }

        if (periodsNoIsDirty) {
            updateUserActionsCheckList({
                premiumPeriodsAdded: true,
            });
            const newFieldPeriods = fill(
                Array(Number(watchPeriodsNo)),
                undefined
            );
            const newFieldIntervals = fill(
                Array(Number(watchPeriodsNo - 1)),
                null
            );

            reset((formValues) => ({
                ...formValues,
                periods: newFieldPeriods,
                interval: Number(watchPeriodsNo) !== 1 ? newFieldIntervals : [],
            }));

            setShowTimings(false);
            setShowIntervalTimings(false);
        }

        if (watchPeriodsNo === 1) {
            setShowTimings(false);
        }
    }, [watchPeriodsNo, multiplePeriodsAccess, periodsNoIsDirty, reset]);

    useEffect(() => {
        // highlights required fields with the colour red if they are empty
        handleTriggerErrorFields();
    }, [errors]);

    const handleTriggerErrorFields = async () => {
        if (trigger) {
            await trigger("timings.sinBinTimerLength");
            await trigger("teamSize");
            await trigger("periods");
            await trigger("interval");
        }
    };

    useEffect(() => {
        if (misconductCodeIdIsDirty) {
            // If Misconduct is change, reset fields for sinBinSystem and timer length

            reset((formValues) => ({
                ...formValues,
                sinBinSystem: SinBinSystem.none,
                timings: {
                    sinBinTimerLength: undefined,
                    extraTimeHalfLength: watchExtraHalfTimeLength,
                },
            }));
        }

        if (templateNameIsDirty) {
            handleTemplateChanged();
        }
    }, [watchMisconductId, templateNameIsDirty]);

    const handleTemplateChanged = () => {
        const templateConfig = getTemplateById(
            watchTemplate,
            templates.templates
        ).config;
        reset((formValues: MatchPhone) => ({
            ...formValues,
            teamSize: templateConfig.teamSize,
            subsNo: templateConfig.subsNo,
            periodsNo: templateConfig.periodsNo,
            timings: templateConfig.timings,
            periods: transformInPeriods(templateConfig.timings),
            interval: transformInIntervals(templateConfig.timings),
            extraTimeAvailable: templateConfig.extraTimeAvailable,
            penaltiesAvailable: templateConfig.penaltiesAvailable,
            withGoalScorers: templateConfig.withGoalScorers,
            misconductCodeId: templateConfig.misconductCodeId,
            sinBinSystem: templateConfig.sinBinSystem,
        }));
    };

    const transformInPeriods = (obj: Timings): number[] => {
        const field = [];

        if (obj.period1) {
            field.push(obj.period1);
        }
        if (obj.period2) {
            field.push(obj.period2);
        }
        if (obj.period3) {
            field.push(obj.period3);
        }
        if (obj.period4) {
            field.push(obj.period4);
        }

        return field;
    };

    const transformInIntervals = (obj: Timings): number[] => {
        const field = [];

        if (obj.interval1) {
            field.push(obj.interval1);
        }
        if (obj.interval2) {
            field.push(obj.interval2);
        }
        if (obj.interval3) {
            field.push(obj.interval3);
        }

        return field;
    };

    const getLengthInputPlaceHolder = (
        index: number,
        type: "periods" | "interval"
    ): string => {
        if (type === "periods") {
            return t(
                `fixture.FixtureTemplate.period.p${parseInt(watchPeriodsNo)}`,
                { value: index + 1 }
            );
        } else {
            return t(`general.interval.p${parseInt(watchPeriodsNo || "2")}`, {
                value: index + 1,
            });
        }
    };

    const handleHideExpandButton = (type: "periods" | "interval"): boolean => {
        if (type === "periods") {
            return showTimings || Number(watchPeriodsNo) === 1;
        }

        if (type === "interval") {
            return showIntervalTimings || Number(watchPeriodsNo) === 2;
        }

        return false;
    };

    const getTimeLengthInputLabel = (
        index: number,
        type: "periods" | "interval"
    ): string => {
        if (type === "periods") {
            return t(`fixture.FixtureTemplate.period.p${watchPeriodsNo}`, {
                value: index + 1,
            });
        } else if (type === "interval") {
            return t(`general.interval.p${watchPeriodsNo || 2}`, {
                value: index + 1,
            });
        }

        return "";
    };

    useEffect(() => {
        // Makes sure we are changing the other periods when timings are not expanded.
        if (watchPeriod0 && !showTimings) {
            const newPeriodsArray: number[] = [];

            // Matches from integration will have timings as an empty {}. We need to set period1 and period2
            if (fieldsPeriods.length <= 0) {
                [...Array(watchPeriodsNo)].forEach(() =>
                    newPeriodsArray.push(watchPeriod0)
                );
            } else {
                fieldsPeriods.forEach(() => newPeriodsArray.push(watchPeriod0));
            }

            reset((formValues) => ({
                ...formValues,
                periods: newPeriodsArray,
            }));
        }
    }, [watchPeriod0, showTimings]);

    useEffect(() => {
        // Makes sure we are changing the other periods when timings are not expanded.
        if (watchInterval0 && !showIntervalTimings) {
            const newIntervalsArray: number[] = [];

            fieldsIntervals.forEach(() =>
                newIntervalsArray.push(watchInterval0)
            );

            reset((formValues) => ({
                ...formValues,
                interval: newIntervalsArray,
            }));
        }
    }, [watchInterval0, showIntervalTimings]);

    const halfLengthInput = (index: number, type: "periods" | "interval") => {
        // Hide input interval if period is 1
        let periodsErrors: FieldError | undefined = undefined;

        const errorType = errors[type];
        if (errorType && Array.isArray(errorType)) {
            const periods: FieldError[] = errorType ? errorType : [];

            periodsErrors = periods[index];
        }

        if (Number(watchPeriodsNo) === 1 && type === "interval") return;
        return (
            <IonItem
                className={periodsErrors ? "item-title danger" : "item-title"}
                lines="full"
                key={`${type}.${index}`}
            >
                <IonLabel position="fixed">
                    {getTimeLengthInputLabel(index, type)}
                </IonLabel>
                <Controller
                    name={`${type}.${index}`}
                    rules={{ required: true }}
                    render={({ field: { onChange, ...rest } }) => (
                        <IonInput
                            inputMode="numeric"
                            type="number"
                            className={"align-text-end"}
                            placeholder={getLengthInputPlaceHolder(index, type)}
                            onIonChange={onChange}
                            {...rest}
                        />
                    )}
                />
                <IonIcon
                    hidden={handleHideExpandButton(type)}
                    data-testid={`expand-${type}`}
                    icon={addCircle}
                    className="expand-button"
                    onClick={() => {
                        if (type === "periods") {
                            setShowTimings(true);
                        } else {
                            setShowIntervalTimings(true);
                        }
                    }}
                />
            </IonItem>
        );
    };

    function handleMisconductCodesForId(
        id: string
    ): MisconductCodes | undefined {
        return findCodeByCountryId(id);
    }
    const hasSystemB = handleMisconductCodesForId(watchMisconductId)?.systemB;
    const currentTemplateName = (): string => {
        if (templates && templates.templates) {
            const template = templates.templates.find(
                (template) => template._id === watchTemplate
            );
            if (template) {
                return template.name;
            }
        }

        return "";
    };

    function renderItems() {
        return (
            <>
                {mode === MatchFormatMode.Match && (
                    <IonItem
                        className={
                            errors["timings"] ||
                            errors["periods"] ||
                            errors["interval"]
                                ? "item-title-container rounded-corners-top danger"
                                : "item-title-container rounded-corners-top"
                        }
                        lines="full"
                        data-testid="match-format-accordian"
                        slot="header"
                    >
                        <IonLabel position="fixed">
                            {t("fixture.FixtureNew.matchFormat")}
                        </IonLabel>
                        <IonText
                            hidden={!!(defaultValues && defaultValues._id)}
                            className="optional-text row-segment-period template-name"
                        >
                            {`[${currentTemplateName()}]`}
                        </IonText>
                    </IonItem>
                )}
                <div
                    slot={
                        mode === MatchFormatMode.Match ? "content" : undefined
                    }
                >
                    <div className="separator" />
                    {mode === MatchFormatMode.Match && (
                        <IonItem
                            className="item-title"
                            hidden={hideSpecificInputsForIntegration}
                            lines="full"
                        >
                            <IonLabel>{t("general.templateName")}</IonLabel>

                            <R6SelectorComponent
                                name={"templateId"}
                                className="select-text"
                            >
                                {templates &&
                                    templates.templates.map((temp, index) => {
                                        return (
                                            <IonSelectOption
                                                key={index}
                                                value={temp._id}
                                            >
                                                {temp.name}
                                            </IonSelectOption>
                                        );
                                    })}
                            </R6SelectorComponent>
                        </IonItem>
                    )}

                    {mode === MatchFormatMode.Template && (
                        <IonItem
                            className={
                                errors && errors["name"]
                                    ? "item-title danger"
                                    : "item-title"
                            }
                            lines="full"
                            data-testid="template-name-item"
                        >
                            <IonLabel>{t("general.templateName")}</IonLabel>
                            <Controller
                                name={"name"}
                                rules={{ required: true }}
                                render={({ field: { onChange, ...rest } }) => (
                                    <IonInput
                                        className="align-text-end"
                                        autoCapitalize="on"
                                        placeholder={t("general.templateName")}
                                        onIonChange={onChange}
                                        {...rest}
                                    />
                                )}
                            />
                        </IonItem>
                    )}
                    <IonItem
                        hidden={hideSpecificInputsForIntegration}
                        className={
                            errors["teamSize"]
                                ? "item-title danger"
                                : "item-title"
                        }
                        lines="full"
                    >
                        <IonLabel position="fixed">
                            {t("fixture.FixtureTemplate.teamSize")}
                        </IonLabel>
                        <Controller
                            name={"teamSize"}
                            rules={{ required: true }}
                            render={({ field: { onChange, ...rest } }) => (
                                <IonInput
                                    className="align-text-end"
                                    inputMode="numeric"
                                    type="number"
                                    placeholder={t(
                                        "fixture.FixtureTemplate.teamSize"
                                    )}
                                    data-testid="teamSizeInput"
                                    onIonChange={onChange}
                                    {...rest}
                                />
                            )}
                        />
                    </IonItem>

                    <IonItem
                        hidden={hideSpecificInputsForIntegration}
                        className={
                            errors["subsNo"]
                                ? "item-title danger"
                                : "item-title"
                        }
                        lines="full"
                    >
                        <IonLabel position="fixed">
                            {t("fixture.FixtureTemplate.subsNo")}
                        </IonLabel>
                        <Controller
                            name={"subsNo"}
                            rules={{ required: true }}
                            render={({ field: { onChange, ...rest } }) => (
                                <IonInput
                                    inputMode="numeric"
                                    type="number"
                                    className="align-text-end"
                                    placeholder={t(
                                        "fixture.FixtureTemplate.subsNo"
                                    )}
                                    onIonChange={onChange}
                                    {...rest}
                                />
                            )}
                        />
                    </IonItem>

                    <IonItem className="item-title" lines="full">
                        <IonLabel>
                            {t("fixture.FixtureTemplate.periodsNo")}
                        </IonLabel>
                        <R6SelectorComponent
                            name={"periodsNo"}
                            className={"select-text"}
                            testId={"selectPeriod"}
                        >
                            <IonSelectOption value="1">
                                {t("fixture.FixtureTemplate.onePeriod")}
                            </IonSelectOption>
                            <IonSelectOption value="2">
                                {t("fixture.FixtureTemplate.twoHalves")}
                            </IonSelectOption>
                            <IonSelectOption value="3">
                                {t("fixture.FixtureTemplate.threeThirds")}
                            </IonSelectOption>
                            <IonSelectOption value="4">
                                {t("fixture.FixtureTemplate.fourQuarters")}
                            </IonSelectOption>
                        </R6SelectorComponent>
                    </IonItem>

                    {showTimings
                        ? fieldsPeriods.map((field, idx) => {
                              return halfLengthInput(idx, "periods");
                          })
                        : halfLengthInput(0, "periods")}

                    {showIntervalTimings
                        ? fieldsIntervals.map((field, idx) => {
                              return halfLengthInput(idx, "interval");
                          })
                        : halfLengthInput(0, "interval")}

                    <IonItem className="item-title" lines="full">
                        <IonLabel>
                            {t("stats.stats.Filters.extraTime")}
                        </IonLabel>
                        <R6SelectorComponent
                            name={"extraTimeAvailable"}
                            className={"select-text"}
                            testId={"extraTimeAvailable"}
                        >
                            <IonSelectOption value={true}>
                                {t("general.yes")}
                            </IonSelectOption>
                            <IonSelectOption value={false}>
                                {t("general.no")}
                            </IonSelectOption>
                        </R6SelectorComponent>
                    </IonItem>

                    {watchExtraTimeAvailable && (
                        <IonItem
                            className={
                                errors["timings"]
                                    ? "item-title danger"
                                    : "item-title"
                            }
                            lines="full"
                        >
                            <IonLabel position="fixed">
                                {t(
                                    "fixture.FixtureTemplate.extraTimeHalfLength"
                                )}
                            </IonLabel>
                            <Controller
                                name={"timings.extraTimeHalfLength"}
                                rules={{ required: true }}
                                render={({ field: { onChange, ...rest } }) => (
                                    <IonInput
                                        inputMode="numeric"
                                        type="number"
                                        placeholder={t(
                                            "fixture.FixtureTemplate.extraTimeHalfLength"
                                        )}
                                        className="align-text-end"
                                        onIonChange={onChange}
                                        {...rest}
                                    />
                                )}
                            />
                        </IonItem>
                    )}

                    <IonItem className="item-title" lines="full">
                        <IonLabel>
                            {t("fixture.FixtureSummary.penalties")}
                        </IonLabel>

                        <R6SelectorComponent
                            name={"penaltiesAvailable"}
                            className={"select-text"}
                            testId={"penaltiesAvailable"}
                        >
                            <IonSelectOption value={true}>
                                {t("general.yes")}
                            </IonSelectOption>
                            <IonSelectOption value={false}>
                                {t("general.no")}
                            </IonSelectOption>
                        </R6SelectorComponent>
                    </IonItem>

                    <IonItem className="item-title" lines="full">
                        <IonLabel>
                            {t("fixture.FixtureTemplate.recordScorers")}
                        </IonLabel>
                        <R6SelectorComponent
                            name={"withGoalScorers"}
                            className={"select-text"}
                            testId={"recordGoalsAvailable"}
                        >
                            <IonSelectOption value={true}>
                                {t("general.yes")}
                            </IonSelectOption>
                            <IonSelectOption value={false}>
                                {t("general.no")}
                            </IonSelectOption>
                        </R6SelectorComponent>
                    </IonItem>

                    <IonItem className="item-title" lines="full">
                        <IonLabel>
                            {t("settings.SettingsMain.misconductSettings")}
                        </IonLabel>

                        <R6SelectorComponent
                            name={"misconductCodeId"}
                            className={"select-text"}
                            testId={"misconduct"}
                        >
                            {getMisconductCodes().map((code, index) => {
                                return (
                                    <IonSelectOption
                                        key={index}
                                        value={code.id}
                                    >
                                        {code.name}
                                    </IonSelectOption>
                                );
                            })}
                        </R6SelectorComponent>
                    </IonItem>

                    <IonItem
                        className={
                            watchSinBinSystem !== SinBinSystem.none
                                ? "item-title"
                                : "item-title rounded-corners-bottom"
                        }
                        lines={
                            watchSinBinSystem !== SinBinSystem.none
                                ? "full"
                                : "none"
                        }
                    >
                        <IonLabel>
                            {t("fixture.FixtureSummaryStats.sinBins")}
                        </IonLabel>
                        <R6SelectorComponent
                            name={"sinBinSystem"}
                            className={"select-text"}
                            testId={"sinBinSystem"}
                        >
                            <IonSelectOption value={SinBinSystem.none}>
                                {t("fixture.FixtureTemplate.none")}
                            </IonSelectOption>
                            <IonSelectOption value={SinBinSystem.systemA}>
                                {t("fixture.FixtureTemplate.systemA")}
                            </IonSelectOption>
                            {hasSystemB &&
                                <IonSelectOption value={SinBinSystem.systemB}>
                                    {t("fixture.FixtureTemplate.systemB", {date:""})}
                                </IonSelectOption> }
                            {hasSystemB &&
                              <IonSelectOption value={SinBinSystem.systemB2024}>
                                    {t("fixture.FixtureTemplate.systemB", {date:"2024+ "})}
                                </IonSelectOption>
                            }
                        </R6SelectorComponent>
                    </IonItem>

                    {watchSinBinSystem &&
                        watchSinBinSystem !== SinBinSystem.none && (
                            <IonItem
                                data-testid="sinbin-timer-input"
                                className={
                                    errors["timings"]
                                        ? "item-title danger rounded-corners-bottom"
                                        : "item-title rounded-corners-bottom"
                                }
                                lines="none"
                            >
                                <IonLabel position="fixed">
                                    {t(
                                        "fixture.FixtureTemplate.dismissalLength"
                                    )}
                                </IonLabel>
                                <Controller
                                    name={"timings.sinBinTimerLength"}
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, ...rest },
                                    }) => (
                                        <IonInput
                                            inputMode="numeric"
                                            type="number"
                                            placeholder={t(
                                                "fixture.FixtureTemplate.dismissalLength"
                                            )}
                                            className="align-text-end"
                                            onIonChange={onChange}
                                            {...rest}
                                        />
                                    )}
                                />
                            </IonItem>
                        )}
                </div>
                <GoProOrPlusAlertComponent
                    showProAlert={proAlert}
                    setShowProAlert={setProAlert}
                    goProTestId={"match-format-pro-periods"}
                    teaserName={"periods-no"}
                />
            </>
        );
    }

    return renderItems();
};

export default MatchFormatComponent;
