import { registerPlugin } from '@capacitor/core';
import { WatchBluetoothStatus } from 'refsix-js-models';

export interface AppleWatchConnectivityPlugin {
  watchStatus(): Promise<WatchBluetoothStatus>;

  sendMessageToWatch(params: { payload: Record<string, unknown> }): Promise<{ sent: true }>;

  addMessagedListener(callback: (payload: Record<string, unknown>) => void): void;

  addReachabilityListener(callback: (status: WatchBluetoothStatus) => void): void;
}

export const AppleWatchConnectivity = registerPlugin<AppleWatchConnectivityPlugin>('AppleWatchConnectivity', {
  web: () => import('./web').then((m) => new m.AppleWatchWeb()),
});
