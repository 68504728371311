import { MgmConfig, MgmKickbackConfig } from "refsix-js-models";

export const MGM_FALLBACK_COUPON_NAME = "REFERRAL";
export const MGM_FALLBACK_KICKBACK_AMOUNT = 10;
export const MGM_FALLBACK_DISCOUNT_PERCENT = 20;

export function pickMgmKickbackConfig(
    config: MgmConfig,
    country: string,
    currency: string
): MgmKickbackConfig | undefined {
    let kickback = config.kickbacks.find(
        (k) =>
            k.country?.toLowerCase() === country?.toLowerCase() &&
            k.currency.toLowerCase() === currency.toLowerCase()
    );
    if (!kickback) {
        kickback = config.kickbacks.find(
            (k) => k.currency.toLowerCase() === currency.toLowerCase()
        );
    }

    return kickback;
}

/**
 *
 * @param amountPaid From the stripe invoice amount_paid
 * @param startingBalance From the stripe invoice starting_balance but flipped to positive number e.g. 300 for £3.00
 */
export function calculateCreditUsed(
    amountPaid: number,
    startingBalance: number
) {
    if (startingBalance) {
        return Math.max(Math.min(startingBalance, amountPaid), 0);
    }
    return;
}
