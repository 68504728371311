import * as Sentry from "@sentry/react";
import { isOnline } from "./connectionUtils";
import { store } from "../redux/store";

import { LoginSession, SessionRefresh } from "../redux/models/loginSession";
import request from "./request";
import moment from "moment";
import { refreshSession } from "./authService";
import { setSession, setSessionExpired } from "../redux/actions/auth";
import { trackEvent } from "./analytics/analyticsService";

const TOKEN_REQUEST_TIMEOUT = 7000;
const TOKEN_REFRESH_THRESHOLD = moment().add(1, "month");
let cachedWatchToken: LoginSession | undefined = undefined;

export function sessionIsExpired(session: LoginSession): boolean {
    // if the expires time is before now then the session is expired
    return moment(session.expires).isBefore(moment());
}

export function sessionNeedsRefresh(session: LoginSession): boolean {
    // sessions are 6 months. So if it is going to expire in the next month then refresh
    return moment(session.expires).isBefore(TOKEN_REFRESH_THRESHOLD);
}

export async function checkAuthSession() {
    const session = store.getState().auth.session;

    if (!session) {
        return;
    }

    if (sessionIsExpired(session)) {
        store.dispatch(setSessionExpired(true));
        //await logoutAndRedirect();
        return;
    }
    store.dispatch(setSessionExpired(false));

    const needsRefresh = sessionNeedsRefresh(session);
    if (needsRefresh) {
        console.log(`Session expiring soon, refreshing token`);
        const response = await refreshSession();
        if (!response.data || response.status !== 200) {
            console.log("Session refresh - bad response from server", response);
            return;
        }
        const responseData: SessionRefresh = response.data;
        const updatedSession: LoginSession = {
            ...session,
            // update the roles, expiry, issued etc
            expires: responseData.expires,
            issued: responseData.issued,
            roles: responseData.roles,
        };

        store.dispatch(setSession(updatedSession));
        trackEvent("SessionRefresh", {
            oldExpiry: moment(session.expires).toISOString(),
            newExpiry: moment(updatedSession.expires).toISOString(),
        });
        console.log("Session updated");
    }
}

export async function generateWatchSession() {
    const session = store.getState().auth.session;
    if (!session) {
        Sentry.addBreadcrumb({
            message:
                "[SessionRefreshService.generateWatchSession()] tried to generate token when user not logged in",
        });
        throw "User not logged in";
    }

    if (cachedWatchToken) {
        // if we have got a token already this app session
        Sentry.addBreadcrumb({
            message:
                "[SessionRefreshService.generateWatchSession()] used cached token",
        });
        return cachedWatchToken;
    } else if (isOnline()) {
        const username = session.user_id;

        try {
            const watchSessionResponse = await request.get(
                `/watch-login/create-session/${username}`,
                {
                    timeout: TOKEN_REQUEST_TIMEOUT,
                }
            );
            // store in case this message to watch times out, then we can re-use
            cachedWatchToken = watchSessionResponse.data as LoginSession;
            return watchSessionResponse.data;
        } catch (error) {
            console.log(error);
            Sentry.captureMessage(
                "[SessionRefreshService.generateWatchSession()] Watch needs token but failed"
            );
            throw error;
        }
    } else {
        Sentry.addBreadcrumb({
            message:
                "[SessionRefreshService.generateWatchSession()] offline - noy able to get a token",
        });
        throw "Not online and no cached token";
    }
}
