import { MatchPhone, OfficialRole } from "refsix-js-models";
import * as XLSX from "xlsx";
import { URLS } from "../constants/urls";
import { findCodeByName } from "./misconductService";
import {
    convertToActualSinBinSystem,
    convertValueToBoolean,
    generatesTimings,
    makeMomentFromXlsxRow,
    SpreadsheetDataType,
    SpreadSheetEnum,
    validateExcelFile,
} from "refsix-core";
import _ from "lodash";
import moment from "moment";
import { t } from "i18next";
import { trackEvent } from "./analytics/analyticsService";

enum OfficialRoleFromSpreadSheet {
    referee = "Referee",
    assistant1 = "Assistant Referee",
    assistant2 = "Assistant Referee2",
    fourthOfficial = "4th Official",
    observer = "Observer",
}

const xlsxToMatches: (wb: XLSX.WorkBook) => MatchPhone[] | string = (wb) => {
    const res: MatchPhone[] = [];

    const sheetName = wb.SheetNames[0]; // Assuming you want to process the first sheet
    const sheet = wb.Sheets[sheetName];
    const jsonData: SpreadsheetDataType[] =
        XLSX.utils.sheet_to_json<SpreadsheetDataType>(sheet, {
            header: 1,
        });

    // Filter out empty arrays
    const filteredJsonData: SpreadsheetDataType[] = jsonData.filter(
        (arr) => arr.length > 0
    );

    const invalidMatches: { [key: number]: string[] } =
        validateExcelFile(filteredJsonData);
    const message: string = displayInvalidMatchesAlert(invalidMatches);

    if (message !== "") {
        return message;
    }

    for (let i = 1; i < filteredJsonData.length; i++) {
        const row: SpreadsheetDataType = filteredJsonData[i];

        const keywordsObject = {
            tag1: row[SpreadSheetEnum.Tag1],
            tag2: row[SpreadSheetEnum.Tag2],
            tag3: row[SpreadSheetEnum.Tag3],
            tag4: row[SpreadSheetEnum.Tag4],
            tag5: row[SpreadSheetEnum.Tag5],
        };
        const keywordsArray: (string | null)[] = _.compact(
            _.at(keywordsObject, ["tag1", "tag2", "tag3", "tag4", "tag5"])
        );

        const matchOfficialsObject = {
            referee: row[SpreadSheetEnum.Referee],
            assistant1: row[SpreadSheetEnum.Assistant1],
            assistant2: row[SpreadSheetEnum.Assistant2],
            fourthOfficial: row[SpreadSheetEnum.FourthOfficial],
            observer: row[SpreadSheetEnum.Observer],
        };

        let officialRole: OfficialRole = OfficialRole.referee;
        switch (row[SpreadSheetEnum.OfficialRole]) {
            case OfficialRoleFromSpreadSheet.assistant1:
            case OfficialRoleFromSpreadSheet.assistant2:
                officialRole = OfficialRole.assistant;
                break;
            case OfficialRoleFromSpreadSheet.fourthOfficial:
                officialRole = OfficialRole.fourthOfficial;
                break;
            case OfficialRoleFromSpreadSheet.observer:
                officialRole = OfficialRole.observer;
                break;
            default:
                break;
        }

        // Convert the number to an actual date
        const dateMoment = makeMomentFromXlsxRow(row);

        const misconductCodeId = findCodeByName(
            row[SpreadSheetEnum.MisconductCodeId]
        );

        const matchPhone: MatchPhone = MatchPhone.fromJSON({
            competition: row[SpreadSheetEnum.Competition],
            venue: row[SpreadSheetEnum.Venue],
            date: dateMoment.isValid()
                ? dateMoment.toISOString()
                : moment().toISOString(),
            officialRole: officialRole,
            homeTeam: row[SpreadSheetEnum.HomeTeam],
            homeTeamShort: row[SpreadSheetEnum.HomeTeamShort],
            homeColor: row[SpreadSheetEnum.HomeColor],
            awayTeam: row[SpreadSheetEnum.AwayTeam],
            awayTeamShort: row[SpreadSheetEnum.AwayTeamShort],
            awayColor: row[SpreadSheetEnum.AwayColor],
            teamSize: row[SpreadSheetEnum.TeamSize],
            subsNo: row[SpreadSheetEnum.SubsNo],
            periodsNo: String(row[SpreadSheetEnum.PeriodsNo]),
            timings: generatesTimings(row),
            extraTimeAvailable: convertValueToBoolean(
                row[SpreadSheetEnum.ExtraTimeAvailable]
            ),
            penaltiesAvailable: convertValueToBoolean(
                row[SpreadSheetEnum.PenaltiesAvailable]
            ),
            withGoalScorers: convertValueToBoolean(
                row[SpreadSheetEnum.WithGoalScorers]
            ),
            misconductCodeId: misconductCodeId ? misconductCodeId.id : "fifa",
            sinBinSystem: convertToActualSinBinSystem(
                row[SpreadSheetEnum.SinBinSystem]
            ),
            matchOfficials: matchOfficialsObject,
            earnings: {
                fees: Number(row[SpreadSheetEnum.Fees]),
                expenses: Number(row[SpreadSheetEnum.Expenses]),
            },
            notes: row[SpreadSheetEnum.Notes],
            keywords: keywordsArray,
            source: "spreadsheet",
        });

        res.push(matchPhone);
    }

    return res;
};

export function handleFileRead(event: ProgressEvent<FileReader>) {
    trackEvent("MatchSpreadsheetUpload", {});
    const data = new Uint8Array(event.target?.result as ArrayBuffer);
    // Extract Data (create a workbook object from the table)
    const wb = XLSX.read(data, { type: "array" });
    return xlsxToMatches(wb);
}

export function displayInvalidMatchesAlert(invalidMatches: {
    [key: number]: string[];
}): string {
    const invalidMatchIndexes = Object.keys(invalidMatches).filter(
        (key: any) => invalidMatches[key].length > 0
    );

    if (invalidMatchIndexes.length > 0) {
        let message = `${t("uploadOrImport.noMatchesAdded")}\n`;

        invalidMatchIndexes.forEach((index: any) => {
            const indexToNumber = Number(index) + 2;
            const invalidProps = invalidMatches[index].join(", ");
            message += `${t("uploadOrImport.invalidMatch", {
                row: indexToNumber,
            })}: ${t("uploadOrImport.invalidMatchProperties", {
                properties: invalidProps,
            })}\n`;
        });
        return message;
    }

    return "";
}

export function downloadRefSixUploadMatchesTemplate() {
    window.open(URLS.REFSIX_TEMPLATE_DOWNLOAD, "_system");
}
