import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IonText } from "@ionic/react";
import "./reviewSharedStyles.css";
import "./topMatchesReview.css";
import { DistanceUnit, DistanceValue } from "../DistanceComponent";
import Stat, {
    StatsContainer,
    Content,
    Footer,
    Header,
    Icon,
    Row,
    CardsContainer,
    Yellow,
    Red,
    CardIcon,
    MainHeader,
    Col,
} from "./reviewCard";
import { AggregatedStats, MatchPhone } from "refsix-js-models";
import moment from "moment";
import { sum } from "lodash";
import "./startReview.css";
import "./sharePreview.css";

interface StartReviewProps {
    aggregatedStats: AggregatedStats;
    matches: MatchPhone[];
    name: string;
}

const groupByMonth = (matches: MatchPhone[]) => {
    return matches.reduce((acc, match) => {
        const month = moment(match.date).format("MMM");
        if (!acc[month]) {
            acc[month] = [];
        }
        acc[month].push(match);
        return acc;
    }, {} as any);
};
const getMostActiveMonth = (matches: MatchPhone[]) => {
    const grouped = groupByMonth(matches);
    const mostActiveMonth = Object.keys(grouped).reduce(
        (acc, month) => {
            if (grouped[month].length > acc.count) {
                acc.count = grouped[month].length;
                acc.month = month;
            }
            return acc;
        },
        { count: 0, month: "" }
    );
    return mostActiveMonth;
};
const StartReview: FC<StartReviewProps> = ({ aggregatedStats, matches }) => {
    const { t } = useTranslation();
    const mostActiveMonth = getMostActiveMonth(matches);
    //console.log(getMostActiveMonth(matches));
    return (
        <div className="start-review review">
            <div className="start-review-container">
                <StatsContainer>
                    <Stat className={"total-matches"}>
                        <Row>
                            <MainHeader>
                                {t("stats.stats.Matches.totalMatches")}
                                <Icon />
                            </MainHeader>
                            <Content>{aggregatedStats.matchesTotal}</Content>
                        </Row>
                        <Footer>
                            {t("review.mostActiveMonth")}:{" "}
                            {mostActiveMonth.month}
                        </Footer>
                    </Stat>

                    <Stat className={"average-distance"}>
                        <Row>
                            <MainHeader>
                                {t("stats.stats.Distance.averageDistance")} (
                                <DistanceUnit />)
                                <Icon />
                            </MainHeader>
                            <Content>
                                <DistanceValue
                                    distanceInMeters={
                                        aggregatedStats.distanceAsRefereeAverage
                                    }
                                    decimalPlaces={1}
                                />
                            </Content>
                        </Row>
                        <Footer>
                            {t("stats.stats.Distance.totalDistance")}:{" "}
                            <DistanceValue
                                distanceInMeters={aggregatedStats.distanceTotal}
                                decimalPlaces={1}
                            />
                            <DistanceUnit />
                        </Footer>
                    </Stat>

                    <Stat className={"total-sprints"}>
                        <Row>
                            <MainHeader>
                                {t("stats.stats.Sprints.totalSprints")}
                                <Icon />
                            </MainHeader>
                            <Content>
                                {sum(aggregatedStats.sprintsTotal)}
                            </Content>
                        </Row>
                        <Footer>
                            {sum(aggregatedStats.sprintsTotalAverage).toFixed(
                                1
                            )}{" "}
                            {t("stats.stats.avgPerGame")}
                        </Footer>
                    </Stat>
                </StatsContainer>
                <CardsContainer>
                    <Red>
                        <CardIcon />
                        <Header>{t("review.red")}</Header>
                        <Content>{aggregatedStats.redCardTotal}</Content>
                        <Footer>
                            {aggregatedStats.redCardAverage.toFixed(1)}{" "}
                            {t("stats.stats.avgPerGame")}
                        </Footer>
                    </Red>
                    <Yellow>
                        <CardIcon />
                        <Header>{t("review.yellow")}</Header>
                        <Content>{aggregatedStats.yellowCardTotal}</Content>
                        <Footer>
                            {aggregatedStats.yellowCardAverage.toFixed(1)}{" "}
                            {t("stats.stats.avgPerGame")}
                        </Footer>
                    </Yellow>
                </CardsContainer>
            </div>

            <IonText
                className="ion-text-center"
                style={{ display: "block", fontSize: 30 }}
            ></IonText>
        </div>
    );
};

export default StartReview;
