import { MatchEvent } from "../matchEvent";
import { Player } from "../player";
import { Team } from "../team";
import { ReasonType } from "../enums/reasonType";
import { EventName } from "../enums/eventName";

export class IncidentEvent extends MatchEvent {
    team: Team;
    player: Player;
    reason: string;
    card: ReasonType;
    duringSinBin: boolean;
    sinBin: boolean;
    playerEntersSinBinTimestamp?: number;
    playerLeavesSinBinTimestamp?: number;
    sinBinInjuryTimeAccumulated?: number;

    constructor(
        timestamp: number,
        eventName: EventName,
        half: number,
        minuteOfPlay: number,
        team: Team,
        player: Player,
        reason: string,
        card: ReasonType,
        duringSinBin: boolean,
        sinBin: boolean,
        playerEntersSinBinTimestamp: number | undefined,
        playerLeavesSinBinTimestamp: number | undefined,
        sinBinInjuryTimeAccumulated: number | undefined
    ) {
        super(timestamp, eventName, half, minuteOfPlay);
        this.team = team;
        this.player = player;
        this.reason = reason;
        this.card = card;
        this.duringSinBin = duringSinBin;
        this.sinBin = sinBin;
        this.playerEntersSinBinTimestamp = playerEntersSinBinTimestamp;
        this.playerLeavesSinBinTimestamp = playerLeavesSinBinTimestamp;
        this.sinBinInjuryTimeAccumulated = sinBinInjuryTimeAccumulated;
    }

    static fromJSON(json: any) {
        return new IncidentEvent(
            json.timestamp,
            json.eventName,
            json.half,
            json.minuteOfPlay,
            json.team,
            json.player,
            json.reason,
            json.card,
            json.duringSinBin,
            json.sinBin,
            json.playerEntersSinBinTimestamp,
            json.playerLeavesSinBinTimestamp,
            json.sinBinInjuryTimeAccumulated
        );
    }
}
