import {
    EventName,
    EventPoint,
    EventPositions,
    GpsData,
    GpsFilteredData,
    GpsPoint,
    GpsSprints,
    GpsStats,
    HalfEvent,
    IncidentEvent,
    MatchPhone,
    OfficialRole,
    ReasonType,
    Stats,
} from "refsix-js-models";
import {
    generatePlottingDataForSegments,
    GpsProcessingService,
    matchEventsPosition,
} from "../fitness";

const HEATMAP_WIDTH = 400;

export function processGPS(
    stats: Stats,
    match: MatchPhone,
    gpsData: GpsData | GpsFilteredData,
    matchSegments: HalfEvent[]
): Stats {
    let gpsProcessingService = new GpsProcessingService();
    let sortedValues: GpsFilteredData =
        gpsProcessingService.sortGpsData(gpsData);
    let filteredGPS: GpsFilteredData | null;
    if (sortedValues.accuracyThreshold !== undefined) {
        filteredGPS = sortedValues;
    } else {
        filteredGPS = gpsProcessingService.filterInvalidPoints(
            sortedValues,
            matchSegments
        );
    }

    if (
        !filteredGPS ||
        (filteredGPS.geoPoints && !filteredGPS.geoPoints.length) ||
        match.officialRole === OfficialRole.fourthOfficial ||
        match.officialRole === OfficialRole.observer
    ) {
        console.log(
            "No filtered GPS points or it was fourth official or it was observer"
        );
        stats.gpsProcessed = 1;
        return stats;
    }

    let geoPoints = filteredGPS.geoPoints;
    if (
        gpsData.calibrationPoints &&
        gpsData.calibrationPoints.leftCorner &&
        gpsData.calibrationPoints.rightCorner &&
        gpsData.calibrationPoints.midfield
    ) {
        let plottingData = generatePlottingDataForSegments(
            geoPoints,
            gpsData.calibrationPoints,
            matchSegments,
            HEATMAP_WIDTH
        );
        // TODO: type propers
        let eventPositions;

        if (plottingData && plottingData.length > 0) {
            eventPositions = matchEventsPosition(
                geoPoints,
                gpsData.calibrationPoints,
                matchSegments,
                match.matchEvents,
                HEATMAP_WIDTH,
                plottingData
            );
        }

        if (eventPositions && match.officialRole === OfficialRole.referee) {
            cardPosition(stats, eventPositions);
        }
        stats.gpsCalibratedTotal = 1;
        stats.gpsCenterPoint = gpsData.calibrationPoints.midfield;
    } else {
        let averageGpsPoint = averageGpsPoints(geoPoints);
        if (averageGpsPoint) {
            stats.gpsCenterPoint = averageGpsPoint;
        }
    }

    let gpsStats = gpsProcessingService.calculateStats(
        matchSegments,
        geoPoints,
        filteredGPS.accuracyThreshold
    );
    let gpsSprints: GpsSprints = gpsProcessingService.calculateSprints(
        matchSegments,
        geoPoints
    );

    stats.gpsAvailable[0] = 1;
    let periodsNumber = match.periodsNo || "2";
    stats.gpsAvailable[parseInt(periodsNumber) - 1] = 1;
    stats.gpsProcessed = 1;

    sprintCalculations(stats, gpsSprints, matchSegments, match);

    distanceCalculations(
        stats,
        // TODO: type propers
        gpsStats as any,
        matchSegments,
        match,
        gpsProcessingService
    );

    // TODO: type propers
    speedCategoriesCalculations(stats, gpsStats as any);

    return stats;
}

function averageGpsPoints(geoPoints: GpsPoint[] | undefined) {
    if (geoPoints) {
        let addedUp = geoPoints.reduce(
            function (prev: GpsPoint, curr: GpsPoint): GpsPoint {
                return {
                    latitude: prev.latitude + curr.latitude,
                    longitude: prev.longitude + curr.longitude,
                    time: 0,
                };
            },
            { latitude: 0, longitude: 0, time: 0 }
        );
        if (addedUp.latitude && addedUp.longitude) {
            return {
                latitude: addedUp.latitude / geoPoints.length,
                longitude: addedUp.longitude / geoPoints.length,
                time: addedUp.time,
            };
        }
    }
    return undefined;
}

function sprintCalculations(
    stats: Stats,
    gpsSprints: GpsSprints,
    matchSegments: HalfEvent[],
    match: MatchPhone
) {
    if (!gpsSprints.sprints || gpsSprints.sprints.length === 0) {
        return;
    }
    gpsSprints.sprints.forEach(function (sprint) {
        stats.sprintsTotal[sprint.level - 1]++;
        stats.sprintsDistanceTotal[sprint.level - 1] += sprint.distance;
    });

    let distanceProperty = "sprintsByHalvesDistanceTotal";
    let property = "sprintsByHalvesTotal";
    if (match.periodsNo === "3") {
        distanceProperty = "sprintsByThirdsDistanceTotal";
        property = "sprintsByThirdsTotal";
        stats.sprintsDistanceThirdsTotal = stats.sprintsDistanceTotal;
        stats.sprintsThirdsTotal = stats.sprintsTotal;
    } else if (match.periodsNo === "4") {
        distanceProperty = "sprintsByQuartersDistanceTotal";
        property = "sprintsByQuartersTotal";
        stats.sprintsDistanceQuartersTotal = stats.sprintsDistanceTotal;
        stats.sprintsQuartersTotal = stats.sprintsTotal;
    } else {
        stats.sprintsDistanceHalvesTotal = stats.sprintsDistanceTotal;
        stats.sprintsHalvesTotal = stats.sprintsTotal;
    }

    if (stats.playedET[0]) {
        var etHalves = [
            [0, 0, 0],
            [0, 0, 0],
        ];
        stats[property] = stats[property].concat(etHalves);
        stats[distanceProperty] = stats[distanceProperty].concat(etHalves);
    }

    gpsSprints.sprints.forEach(function (sprint) {
        if (stats[property][sprint.segment] === undefined) {
            stats[property][sprint.segment] = [0, 0, 0];
            stats[distanceProperty][sprint.segment] = [0, 0, 0];
        }
        stats[property][sprint.segment][sprint.level - 1]++;
        stats[distanceProperty][sprint.segment][sprint.level - 1] +=
            sprint.distance;
    });

    stats.sprintsMediumAndHigh = stats.sprintsTotal[1] + stats.sprintsTotal[2];
    return stats;
}

export function speedCategoriesCalculations(stats: Stats, gpsStats: GpsStats) {
    if (gpsStats.matchStats && gpsStats.matchStats.bySpeedCategory) {
        if (gpsStats.matchStats.bySpeedCategory.distance) {
            stats.speedCategoryDistances[0] =
                gpsStats.matchStats.bySpeedCategory.distance.stand || 0;
            stats.speedCategoryDistances[1] =
                gpsStats.matchStats.bySpeedCategory.distance.walk || 0;
            stats.speedCategoryDistances[2] =
                gpsStats.matchStats.bySpeedCategory.distance.jog || 0;
            stats.speedCategoryDistances[3] =
                gpsStats.matchStats.bySpeedCategory.distance.run || 0;
            stats.speedCategoryDistances[4] =
                gpsStats.matchStats.bySpeedCategory.distance.fastRun || 0;
            stats.speedCategoryDistances[5] =
                gpsStats.matchStats.bySpeedCategory.distance.sprint || 0;
        }
        if (gpsStats.matchStats.bySpeedCategory.duration) {
            stats.speedCategoryDurations[0] =
                gpsStats.matchStats.bySpeedCategory.duration.stand || 0;
            stats.speedCategoryDurations[1] =
                gpsStats.matchStats.bySpeedCategory.duration.walk || 0;
            stats.speedCategoryDurations[2] =
                gpsStats.matchStats.bySpeedCategory.duration.jog || 0;
            stats.speedCategoryDurations[3] =
                gpsStats.matchStats.bySpeedCategory.duration.run || 0;
            stats.speedCategoryDurations[4] =
                gpsStats.matchStats.bySpeedCategory.duration.fastRun || 0;
            stats.speedCategoryDurations[5] =
                gpsStats.matchStats.bySpeedCategory.duration.sprint || 0;
        }
    }
    return stats;
}

export function distanceCalculations(
    stats: Stats,
    gpsStats: GpsStats,
    matchSegments: HalfEvent[],
    match: MatchPhone,
    gpsProcessingService: any
) {
    if (gpsStats.matchStats && gpsStats.matchStats.distance) {
        stats.distanceTotal = gpsStats.matchStats.distance;

        switch (match.officialRole) {
            case OfficialRole.assistant:
                stats.distanceAsAssistantTotal = gpsStats.matchStats.distance;
                break;
            case OfficialRole.fourthOfficial:
            case OfficialRole.observer:
                break;
            default:
                stats.distanceAsRefereeTotal = gpsStats.matchStats.distance;
                break;
        }
        let property = "distanceByHalvesTotal";
        if (match.periodsNo === "3") {
            property = "distanceByThirdsTotal";
            stats.distanceThirdsTotal = gpsStats.matchStats.distance;
        } else if (match.periodsNo === "4") {
            property = "distanceByQuartersTotal";
            stats.distanceQuartersTotal = gpsStats.matchStats.distance;
        } else {
            stats.distanceHalvesTotal = gpsStats.matchStats.distance;
        }

        matchSegments.forEach(function (segments, index) {
            stats[property][index] =
                gpsProcessingService.sumHalfProperty(
                    gpsStats.minuteStats,
                    index,
                    "distance"
                ).distance || 0;
        });
    }

    return stats;
}

export function cardPosition(
    stats: Stats,
    eventPositions: EventPositions
): Stats {
    // divide each half of the pitch into a 3x3 matrix (so the pitch is 6x3)
    let widthSquare = eventPositions.width / 6;
    let heightSquare = eventPositions.height / 3;

    eventPositions.data.forEach(function (data: EventPoint) {
        // work out which box the card falls in on a 6x3 matrix of the full pitch
        let x = Math.floor(data.point.x / widthSquare);
        let y = Math.floor(data.point.y / heightSquare);
        if (x > 5 || x < 0 || y > 2 || y < 0) {
            return;
        }
        // merge both ends together into a 3x3 grid (we need to rotate the right side to make accurate)
        if (x > 2) {
            x = Math.abs(x - 5);
            y = Math.abs(y - 2);
        }
        // calculate which slot in a 9 length array that corresponds to
        let position = y * 3 + x;
        if (data.event.eventName === EventName.incident) {
            let cardEvent = <IncidentEvent>data.event;
            if (cardEvent.player && !cardEvent.player.teamOfficial) {
                if (ReasonType.yellow === cardEvent.card) {
                    stats.yellowCardPositions[position]++;
                } else if (ReasonType.red === cardEvent.card) {
                    stats.redCardPositions[position]++;
                }
            }
        }
    });

    return stats;
}
