import {
    SinBinSystem,
    Template,
    TemplateConfig,
    Templates,
    Timings,
} from "refsix-js-models";
import { uuid } from "short-uuid";
import { setTemplates } from "../redux/actions/templates";
import { store } from "../redux/store";
import { IDatabaseService } from "./database/iDatabaseService";
import { TEMPLATES_ID } from "./database/pouchUtils";
import { getDatabaseService } from "./matchService";
import { copyObject, resolveTemplatesConflict } from "refsix-core";
import * as Sentry from "@sentry/react";
import _ from "lodash";

// Use this when we know a template has an ID
type TemplateWithId = Template & { _id: string };

enum DefaultTemplate {
    League = "League",
    Cup = "Cup",
}

const CUP_TIMINGS = new Timings(
    45,
    45,
    15,
    undefined,
    undefined,
    undefined,
    undefined,
    15,
    undefined
);
const CUP = new TemplateConfig(
    CUP_TIMINGS,
    "2",
    false,
    11,
    5,
    true,
    true,
    SinBinSystem.none,
    undefined
);

const LEAGUE_TIMINGS = new Timings(45, 45, 15);
const LEAGUE = new TemplateConfig(
    LEAGUE_TIMINGS,
    "2",
    false,
    11,
    5,
    false,
    false,
    SinBinSystem.none,
    undefined
);

// TODO translate
const DEFAULT_TEMPLATE_LIST: Template[] = [
    Template.fromMatch(DefaultTemplate.League, LEAGUE, uuid()),
    Template.fromMatch(DefaultTemplate.Cup, CUP, uuid()),
];
const DEFAULT_TEMPLATES = new Templates(
    DEFAULT_TEMPLATE_LIST,
    DEFAULT_TEMPLATE_LIST[0]._id as string
);

export function getDefaultTemplates(): Templates {
    return DEFAULT_TEMPLATES;
}

/**
 * Returns true if a template with the provided ID exists in the templates array
 */
function templateWithIdExists(
    templateId: string,
    templates: Template[]
): boolean {
    return !!templates.find((template) => template._id === templateId);
}

/**
 * Returns template if found otherwise return the first template
 */
// TODO: add unit test
export function getTemplateById(
    templateId: string,
    templates: Template[]
): Template {
    return (
        templates.find((template) => template._id === templateId) ||
        templates[0]
    );
}

/**
 * Returns a new array of templates where any template that did not have an ID,
 * has a new UUID ID.
 */
function addAnyMissingIds(templates: Template[]): TemplateWithId[] {
    return templates.reduce(
        (templates, template) =>
            !template._id
                ? [...templates, { ...template, _id: uuid() }]
                : [...templates, template as TemplateWithId],
        [] as TemplateWithId[]
    );
}

/**
 * Return an ID based on either a "League" template, or default to the first template
 */
function selectDefaultTemplateId(templates: TemplateWithId[]): string {
    const leagueTemplate = templates.find(
        (template) => template.name === DefaultTemplate.League
    );
    return leagueTemplate ? leagueTemplate._id : templates[0]._id;
}

/**
 * This will return default templates if `newTemplates` is emtpy, set a new default template if the
 * stated `defaultTemplate` in the wrapper does not exist in `newTemplates`, or will just return
 * the `templatesWrapper` with the `newTemplates` if a correct default does exist.
 */
export function _createNewTemplatesWrapper(
    templatesWrapper: Templates,
    newTemplates: Template[]
): Templates {
    if (newTemplates.length === 0) {
        return getDefaultTemplates();
    }

    const copyOfWrapper: Templates = copyObject(templatesWrapper);

    const templatesWithIds = addAnyMissingIds(newTemplates);
    copyOfWrapper.templates = templatesWithIds;

    if (
        !templateWithIdExists(copyOfWrapper.defaultTemplate, templatesWithIds)
    ) {
        copyOfWrapper.defaultTemplate =
            selectDefaultTemplateId(templatesWithIds);
    }

    return copyOfWrapper;
}

/**
 * Removes template with given ID from store and DB
 */
export async function deleteTemplate(
    templateId: string,
    templates: Templates
): Promise<void> {
    const newTemplates = templates.templates.filter(
        (template) => template._id !== templateId
    );

    await saveTemplates(templates, newTemplates);
}

/**
 * Saves the template to the store and DB
 */
export async function saveTemplate(
    template: Template,
    templates: Templates,
    editing: boolean
): Promise<void> {
    let newTemplates: Template[] = [];

    if (editing) {
        newTemplates = templates.templates.map((temp) =>
            temp._id === template._id ? template : temp
        );
    } else {
        newTemplates = [...templates.templates, template];
    }

    await saveTemplates(templates, newTemplates);
}

/**
 * Store templates in DB and dispatch to store (DB conflicts will also be resolved)
 */
export async function saveTemplates(
    templatesWrapper: Templates,
    newTemplates: Template[]
): Promise<void> {
    const dbService = getDatabaseService() as IDatabaseService;

    const newTemplatesWrapper = _createNewTemplatesWrapper(
        templatesWrapper,
        newTemplates
    );

    try {
        await dbService.upsertDoc(newTemplatesWrapper);
        store.dispatch(setTemplates(newTemplatesWrapper));
    } catch (err) {
        if ((err as PouchDB.Core.Error).name === "conflict") {
            const dbTemplates = (await dbService.getDocById<Templates>(
                TEMPLATES_ID
            )) as Templates; // doc will always exist if there was a conflict

            const mergedTemplates = resolveTemplatesConflict(
                newTemplatesWrapper,
                dbTemplates
            );

            await dbService.getLocalDatabase().put(
                {
                    _rev: dbTemplates._rev as string,
                    ...mergedTemplates,
                },
                { force: true }
            );
            store.dispatch(setTemplates(mergedTemplates));
        } else {
            Sentry.captureMessage(
                `Error updating templates: ${JSON.stringify(err)}`
            );
            console.log("Error updating templates");
        }
    }
}

export const checkIfTemplateOnDBExists = async () => {
    const dbService = getDatabaseService() as IDatabaseService;

    const templates = await dbService.getDocById<Templates>(TEMPLATES_ID);

    if (!templates || templates.templates.length === 0) {
        await dbService.upsertDoc(getDefaultTemplates());
    } else {
        await checkIfTemplatesNeedFixing();
    }
};

export const fixTemplates = (templateDoc: Templates) => {
    templateDoc = copyObject(templateDoc); // don't mutate the original
    const templates = templateDoc.templates;
    let needsUpdate = false;
    templates.forEach(function (template, i) {
        if (typeof template.name !== "string") {
            template.name = `Template ${i + 1}`;
            needsUpdate = true;
        }

        // check if teamSize or subsNo are strings and if so convert to numbers
        if (typeof template.config.teamSize === "string") {
            template.config.teamSize = parseInt(template.config.teamSize);
            needsUpdate = true;
        } else if (!template.config.teamSize) {
            template.config.teamSize = 11;
            needsUpdate = true;
        }

        if (typeof template.config.subsNo === "string") {
            template.config.subsNo = parseInt(template.config.subsNo);
            needsUpdate = true;
        } else if (!template.config.subsNo) {
            template.config.subsNo = 5;
            needsUpdate = true;
        }

        if (typeof template.config.extraTimeAvailable !== "boolean") {
            template.config.extraTimeAvailable = false;
            needsUpdate = true;
        }

        if (typeof template.config.penaltiesAvailable !== "boolean") {
            template.config.penaltiesAvailable = false;
            needsUpdate = true;
        }

        if (typeof template.config.timings.extraTimeHalfLength === "string") {
            template.config.timings.extraTimeHalfLength = parseInt(
                template.config.timings.extraTimeHalfLength
            );
            needsUpdate = true;
        }

        if (typeof template.config.timings.sinBinTimerLength === "string") {
            template.config.timings.sinBinTimerLength = parseInt(
                template.config.timings.sinBinTimerLength
            );
            needsUpdate = true;
        }
    });

    const ids = _.map(templates, "_id");

    if (
        templates.length &&
        templateDoc.defaultTemplate &&
        ids.indexOf(templateDoc.defaultTemplate) === -1
    ) {
        templateDoc.defaultTemplate = templateDoc.templates[0]._id as string;
        needsUpdate = true;
    }

    if (templates.length && !templateDoc.defaultTemplate) {
        templateDoc.defaultTemplate = templateDoc.templates[0]._id as string;
        needsUpdate = true;
    }

    if (needsUpdate) {
        console.log("Fixed templates which had issues");
        Sentry.addBreadcrumb({
            category: "templateService",
            message: "Fixed templates which had issues",
        });
        return saveTemplates(templateDoc, templates);
    } else {
        return Promise.resolve("No updates");
    }
};

export const checkIfTemplatesNeedFixing = async () => {
    const dbService = getDatabaseService() as IDatabaseService;

    const templates = await dbService.getDocById<Templates>(TEMPLATES_ID);

    if (templates) {
        return fixTemplates(templates);
    }
};
export const hasSystemBTemplates = (templates: Templates) => {
    if (templates) {
        return templates.templates.some(
            (template) => template.config.sinBinSystem === SinBinSystem.systemB
        );
    }
    return false;
}
export const migrateSystemBTemplates = async (templates: Templates) => {

    if (templates) {
        const newTemplates = templates.templates.map((template) => {
            if (template.config.sinBinSystem === SinBinSystem.systemB) {
                template.config.sinBinSystem = SinBinSystem.systemB2024;
            }
            return template;
        });
        return saveTemplates(templates, newTemplates);
    }
}