import {
    EventName,
    GoalEvent,
    HalfEvent,
    MatchEvent,
    MatchPhone,
    ReasonType,
    SelectedTeam,
} from "refsix-js-models";
import { flow, includes, map, reduce, values } from "lodash/fp";
import {
    filterHalfObjectIncludingPenalties,
    isEventWithTeam,
    isGoalEvent,
    isIncidentEvent,
    isSubstitutionEvent,
} from "../services/eventService";
import { FieldValues } from "react-hook-form";
import { findPlayer } from "refsix-core";

export function minutePlayed(event: MatchEvent) {
    if (event.eventName === EventName.half) {
        let halfEvent = event as HalfEvent;

        if (
            halfEvent.name.includes("End ") &&
            halfEvent.name !== "Half Time End "
        ) {
            // Getting length of the half event
            let halfLength: number =
                halfEvent?.minuteOfPlay !== 0 ? halfEvent.minuteOfPlay : 45;

            switch (halfEvent.index) {
                case 2:
                    // Length og second half
                    halfLength = halfLength * 2;
                    break;
                case 4:
                    // Length og third half
                    halfLength = halfLength * 3;
                    break;
                case 6:
                    // Length og fourth half
                    halfLength = halfLength * 4;
                    break;
                case 8:
                    // Length og ET 1 half
                    halfLength = halfLength * 5;
                    break;
                case 10:
                    // Length og ET 2 half
                    halfLength = halfLength * 6;
                    break;
            }

            // Check if match was ended before half length finished
            let additionalTime: number;
            if (halfEvent.endMinute && halfEvent.endMinute < halfEvent.length) {
                additionalTime = 0;

                // we need to add the length with minute played to show the end played minute when
                // user stops the match before the half length
                halfLength = halfEvent.length + halfEvent.endMinute;
            } else {
                additionalTime = halfEvent?.endMinute
                    ? halfEvent?.endMinute -
                      // For some reason minuteOfPlay is set to 0, we default to 45
                      (halfEvent.minuteOfPlay !== 0
                          ? halfEvent.minuteOfPlay
                          : 45)
                    : 0;
            }

            return additionalTime === 0
                ? `${halfLength.toFixed(0)}´`
                : `${halfLength.toFixed(0)}´+ ${additionalTime.toFixed(0)}´`;
        } else if (halfEvent.name === "Half Time End ") {
            return `${halfEvent.minuteOfPlay.toFixed(0)}´+ ${
                halfEvent.endMinute
            }´`;
        }
    } else if (event.eventName === EventName.penalties) {
        let halfEvent = event as HalfEvent;
        if (halfEvent.name !== undefined && halfEvent.name.includes("End ")) {
            return halfEvent.name;
        }
    }
    return `${event.minuteOfPlay}´`;
}

export function halfObject(event: MatchEvent) {
    return (
        event.eventName !== EventName.half &&
        event.eventName !== EventName.penalties
    );
}

export function showKickOffSide(event: MatchEvent) {
    if (event.eventName === EventName.half) {
        const halfEvent = event as HalfEvent;
        if (halfEvent.name.includes("End ")) {
            return;
        }
        return halfEvent.kickOffSide;
    }
    return;
}

export type EditEventForm = {
    "eventType-select": EventType;
    "period-select": number;
    "team-select": SelectedTeam;
    "minute-select": string;
    "goal-select": string;
    "player-select": number;
    "player-name": string;
    "player-number": number;
    "player-on-name": string;
    "player-on-number": number;
    "player-off-name": string;
    "player-off-number": number;
    "player-on-select": number;
    "player-off-select": number;
    "reason-select": string;
};

export enum EventType {
    Goal = "goal",
    Yellow = "yellow",
    Red = "red",
    Subs = "subs",
}

const eventToType = (event: MatchEvent) => {
    if (isGoalEvent(event)) {
        return EventType.Goal;
    } else if (isIncidentEvent(event)) {
        if (event.card == ReasonType.red) {
            return EventType.Red;
        } else if (event.card == ReasonType.yellow) {
            return EventType.Yellow;
        }
    } else if (isSubstitutionEvent(event)) {
        return EventType.Subs;
    }

    return EventType.Goal;
};
export type Transformer<T = any, V = any> = {
    input: (v: T) => V;
    output: (v: V) => T;
};
const mapEventToGoals = (e: GoalEvent): string => {
    if (e.ownGoal) return "2";
    if (e.penalty) return "3";
    if (e.freeKick) return "4";
    return "1";
};

// Take a list of Partial<T> and merge them together
// It might be that the result is actually a full T, but we can't enforce that here
const merge: <T>(args: Partial<T>[]) => Partial<T> = reduce((acc, v) => {
    return {
        ...acc,
        ...v,
    };
}, {});
type ProcessFN<T, P> = (data: T) => Partial<P>;
const over =
    <T, P>(fns: ProcessFN<T, P>[]) =>
    (data: T) => {
        return map((cb) => {
            return cb(data);
        }, fns);
    };

// Takes a list of callbackb, and execute them in parallel
// Each callback returns a Partial<MatchEvent>.
// We then merge all the results from the callbacks to create our
// Final MatchEvent
type ProcessEventWithType = <T, P>(
    ...cbs: ProcessFN<T, Partial<P>>[]
) => ProcessFN<T, P>;

const processEventWith: ProcessEventWithType = (...cbs) =>
    flow(over(cbs), merge);

export const EventConverter: (
    match: MatchPhone
) => Transformer<MatchEvent, EditEventForm> = (match) => ({
    input: (event) => {
        // List of process Functions to load the form when editing
        const cbs: ProcessFN<MatchEvent, Partial<EditEventForm>>[] = [
            (event) =>
                isEventWithTeam(event)
                    ? {
                          "eventType-select": eventToType(event),
                          "period-select": event.half,
                          "minute-select": event.additionalTime
                              ? `${event.minuteOfPlay}+${event.additionalTime}`
                              : `${event.minuteOfPlay}`,
                          "team-select": event.team.side,
                      }
                    : {},
            (event) =>
                isGoalEvent(event)
                    ? {
                          "player-select": event.player?.number || undefined,
                          "goal-select": mapEventToGoals(event),
                      }
                    : {},
            (event) =>
                isIncidentEvent(event)
                    ? {
                          "player-select": event.player?.number || undefined,
                          "reason-select": event.reason,
                      }
                    : {},
            (event) =>
                isSubstitutionEvent(event)
                    ? {
                          "player-on-select":
                              event.playerOn?.number || undefined,
                          "player-off-select":
                              event.playerOff?.number || undefined,
                      }
                    : {},
        ];
        const populate = processEventWith(...cbs);
        return populate(event) as EditEventForm;
    },
    output: (data) => {
        // Used to convert from the form to a MatchEvent
        const events = values((match && match.matchEvents!) || {});
        const halfEvents = filterHalfObjectIncludingPenalties(events);
        const teamName =
            data["team-select"] == SelectedTeam.home
                ? match.homeTeam
                : match.awayTeam;
        const teamNameShort =
            data["team-select"] == SelectedTeam.home
                ? match.homeTeamShort
                : match.awayTeamShort;
        // Convert half + minutes to a match Timestamp
        const calculateEventMillis = (): number => {
            const halfTimestamp =
                halfEvents[data["period-select"]]?.timestamp || 0;
            let minuteSelect: number = 0;

            if (data["minute-select"].includes("+")) {
                const split = data["minute-select"].split("+").map(Number);
                minuteSelect = split.reduce((a, b) => a + b, 0);
            } else {
                minuteSelect = Number(data["minute-select"]);
            }

            const selectedMinute =
                minuteSelect -
                (halfEvents[data["period-select"]].minuteOfPlay || 0);
            return halfTimestamp + selectedMinute * 60 * 1000;
        };
        // Iterates through the list of events, and continuously increments the timestamp
        // until the timestamp is unique
        const dedupTs: (ts: number) => number = (ts) => {
            const allTimestamps = map("timestamp", events);
            let currentTs = ts;
            if (allTimestamps.length === 0) {
                return currentTs;
            }
            while (includes(currentTs, allTimestamps)) {
                currentTs++;
            }
            return currentTs;
        };
        // Given form data, calculat a unique timestamp to add to this match
        const populateTS: ProcessFN<EditEventForm, FieldValues> = (data) => {
            const rawTs = calculateEventMillis();
            const timestamp = dedupTs(rawTs);

            return {
                timestamp,
            };
        };

        const populateMinuteOfPlay: ProcessFN<EditEventForm, FieldValues> = (
            data
        ) => {
            const minuteOfPlay = data["minute-select"].includes("+")
                ? data["minute-select"].split("+")[0]
                : data["minute-select"];

            const additionalTime = data["minute-select"].includes("+")
                ? data["minute-select"].split("+")[1]
                : undefined;

            return {
                minuteOfPlay: parseInt(minuteOfPlay),
                additionalTime: additionalTime
                    ? parseInt(additionalTime)
                    : undefined,
            };
        };

        const populateTeam: ProcessFN<EditEventForm, FieldValues> = (data) => ({
            team: {
                side: data["team-select"],
                teamName: teamName,
                shortName: teamNameShort,
            },
        });
        const populateHalf: ProcessFN<EditEventForm, FieldValues> = (data) => ({
            half: halfEvents[data["period-select"]]?.index,
        });

        const populatePlayer: (
            inSelector?: keyof EditEventForm,
            outSelector?: keyof FieldValues
        ) => ProcessFN<EditEventForm, FieldValues> =
            (inSelector = "player-select", outSelector = "player") =>
            (data) => {
                const player = data[inSelector]
                    ? findPlayer(
                          match,
                          teamName,
                          parseInt("" + data[inSelector])
                      )
                    : null;
                return {
                    [outSelector]: player,
                };
            };

        const processGoalEvent = processEventWith(
            (data) => ({
                eventName: EventName.goal,
                penalty: data["goal-select"] === "3",
                ownGoal: data["goal-select"] === "2",
                freeKick: data["goal-select"] === "4",
                hasPosition: false,
            }),
            populateTS,
            populateMinuteOfPlay,
            populateTeam,
            populateHalf,
            populatePlayer()
        );

        const processYellowCardEvent = processEventWith(
            (data) => {
                return {
                    eventName: EventName.incident,
                    hasPosition: false,
                    card: "yellow",
                    reason: data["reason-select"],
                };
            },
            populateTS,
            populateMinuteOfPlay,
            populateTeam,
            populateHalf,
            populatePlayer()
        );
        const processRedCardEvent = processEventWith(
            (data) => {
                return {
                    eventName: EventName.incident,
                    hasPosition: false,
                    card: "red",
                    reason: data["reason-select"],
                };
            },
            populateTS,
            populateMinuteOfPlay,
            populateTeam,
            populateHalf,
            populatePlayer()
        );
        const processSubEvent = processEventWith(
            () => {
                return {
                    eventName: EventName.substitution,
                    hasPosition: false,
                };
            },
            populateTS,
            populateMinuteOfPlay,
            populateTeam,
            populateHalf,
            populatePlayer("player-off-select", "playerOff"),
            populatePlayer("player-on-select", "playerOn")
        );
        const process = (data: EditEventForm): MatchEvent => {
            switch (data["eventType-select"]) {
                case EventType.Goal:
                    return processGoalEvent(data) as MatchEvent;
                case EventType.Yellow:
                    return processYellowCardEvent(data) as MatchEvent;
                case EventType.Red:
                    return processRedCardEvent(data) as MatchEvent;
                case EventType.Subs:
                    return processSubEvent(data) as MatchEvent;
                default:
                    throw "Unknown Event Type";
            }
        };
        const res = process(data);
        return res;
    },
});
