import { IntegrationPartner } from "refsix-js-models";
import { IntegrationMessageType } from "../models/refsixState";

export interface IntegrationMessageAction {
    type: string;
    integration: IntegrationPartner;
    message?: IntegrationMessageType;
}

export const pushIntegrationMessage = (
    integration: IntegrationPartner,
    message: IntegrationMessageType
): IntegrationMessageAction => ({
    type: `PUSH_INTEGRATION_MESSAGE`,
    integration,
    message,
});
export const popIntegrationMessage = (
    integration: IntegrationPartner
): IntegrationMessageAction => ({
    type: `POP_INTEGRATION_MESSAGE`,
    integration,
});
