import { SET_USERNAME, SHOW_INTEGRATION_POPUP } from "./types";

export const setUsername = (usernameOrEmail: String) => ({
    type: SET_USERNAME,
    usernameOrEmail,
});

export const setShowIntegrationPopup = (show: boolean) => ({
    type: SHOW_INTEGRATION_POPUP,
    show,
});
