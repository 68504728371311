import {
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonLoading,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventName, MatchEvent, MatchPhone } from "refsix-js-models";
import {
    adjustHalfAdditionalTimeMins,
    getMinuteStrings,
    getOnlyHalfEvents,
} from "../../../services/eventService";
import { values } from "lodash";
import { recalculateStats, updateMatch } from "../../../services/matchService";
import { useProfile } from "../../../components/hooks";
import * as Sentry from "@sentry/react";

interface CropMatchLengthInterface {
    isModalOpen: boolean;
    onDismiss: () => void;
    match: MatchPhone;
}

export const CropMatchLength: FC<CropMatchLengthInterface> = ({
    isModalOpen,
    onDismiss,
    match,
}) => {
    const { t } = useTranslation();
    const events = values((match && match.matchEvents!) || {});
    const halfEvents = getOnlyHalfEvents(events);
    const [selectedMinute, setSelectedMinute] = useState<String>("");
    const profile = useProfile();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastEventMinute, setLastEventMinute] = useState<number>(0);

    const displayMinuteStringToNumber = (minute: string): number => {
        const splitStringOfMinute = minute.split("+").map(Number);
        return splitStringOfMinute.reduce((a, b) => a + b, 0);
    };

    const getNonHalfEvents = (events: MatchEvent[]): MatchEvent[] => {
        return events
            .filter((event) => event.eventName !== EventName.half)
            .sort((a, b) => a.timestamp - b.timestamp);
    };

    // If an event is added in additional time, make from the time of addition and below disabled
    const calculateLastEventMinute = (events: MatchEvent[]): number => {
        const filteredEvents = getNonHalfEvents(events);
        const lastEvent =
            filteredEvents.length > 0
                ? filteredEvents[filteredEvents.length - 1]
                : events[events.length - 1];

        return lastEvent.additionalTime
            ? lastEvent.minuteOfPlay + lastEvent.additionalTime
            : lastEvent.minuteOfPlay;
    };

    useEffect(() => {
        setLastEventMinute(calculateLastEventMinute(events));
    }, [events]);

    const handleDoneClicked = async () => {
        setIsLoading(true);
        const additionalTime = selectedMinute.includes("+")
            ? selectedMinute.split("+")[1]
            : "0";
        const lastHalfEvent = halfEvents[halfEvents.length - 1];

        const updatedHalf = adjustHalfAdditionalTimeMins(
            lastHalfEvent,
            parseInt(additionalTime)
        );

        if (match.matchEvents) {
            const newMatch = {
                ...match,
                matchEvents: {
                    ...match.matchEvents,
                    [updatedHalf.timestamp]: updatedHalf,
                },
            };

            try {
                if (profile) {
                    // this does a db update too.
                    await recalculateStats(newMatch, profile);
                    onDismiss();
                } else {
                    await updateMatch(newMatch);
                    onDismiss();
                }
            } catch (e) {
                Sentry.captureMessage(
                    `Crop Match - error updating match: ${e}`
                );
                console.error(e);
            }
        }

        setIsLoading(false);
    };

    return (
        <IonModal
            isOpen={isModalOpen}
            showBackdrop
            initialBreakpoint={1}
            onDidDismiss={onDismiss}
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => onDismiss()}>
                            {t("general.cancel")}
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton
                            data-testid="select-end-minute-button"
                            onClick={() => handleDoneClicked()}
                        >
                            {t("general.done")}
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{t("fixture.Fixture.cropMatch")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonLoading isOpen={isLoading} />
                <IonCard>
                    {match.periodsNo &&
                        halfEvents[halfEvents.length - 1].playing && (
                            <IonList>
                                <IonRadioGroup
                                    value={selectedMinute}
                                    onIonChange={(e) => {
                                        setSelectedMinute(e.detail.value);
                                    }}
                                >
                                    {getMinuteStrings(
                                        halfEvents[halfEvents.length - 1],
                                        true
                                    )
                                        // limit the upper bound to 3 hours in case they left running for days
                                        .slice(0, 3 * 60)
                                        .map((minute, idx) => {
                                            return (
                                                <IonItem key={idx}>
                                                    <IonRadio
                                                        data-testid={`minute-${minute}`}
                                                        disabled={
                                                            displayMinuteStringToNumber(
                                                                minute
                                                            ) < lastEventMinute
                                                        }
                                                        onClick={() =>
                                                            setSelectedMinute(
                                                                minute
                                                            )
                                                        }
                                                        justify="space-between"
                                                        className="radio-button"
                                                        mode="ios"
                                                        value={minute}
                                                    >
                                                        {`${minute}'`}
                                                    </IonRadio>
                                                </IonItem>
                                            );
                                        })}
                                </IonRadioGroup>
                            </IonList>
                        )}
                </IonCard>
            </IonContent>
        </IonModal>
    );
};
