import { FC } from "react";
import {
    IonCard,
    IonContent,
    IonItem,
    IonPage,
    useIonRouter,
} from "@ionic/react";
import { HeaderComponent } from "../../../components";
import { routes } from "../../../route/constants";
import { useTranslation } from "react-i18next";
import assignrSquareLogo from "../../../assets/images/icon/assignr-square-icon.png";
export const Integrations: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMore}
                title={t("general.integrations")}
                titleTestId="integrations-page-header"
            />
            <IonContent>
                <IonCard>
                    <IonItem
                        button
                        lines="none"
                        data-testid="button-integration-assignr"
                        onClick={() =>
                            route.push(routes.refsixAssignrIntegration)
                        }
                    >
                        Assignr&nbsp;
                        <img
                            alt="Assignr"
                            style={{ width: "21px", paddingLeft: "4px" }}
                            src={assignrSquareLogo}
                        />
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonItem
                        button
                        lines="none"
                        data-testid="button-integration-dbu"
                        onClick={() => route.push(routes.refsixDbuIntegration)}
                    >
                        DBU 🇩🇰
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem
                        button
                        lines="none"
                        data-testid="button-integration-refassist"
                        onClick={() =>
                            route.push(routes.refsixRefAssistIntegration)
                        }
                    >
                        RefAssist
                    </IonItem>
                </IonCard>

                {/* Hidden in initial release */}
                <IonCard hidden={true}>
                    <IonItem
                        button
                        lines="none"
                        data-testid="button-integration-stack-sports"
                        onClick={() =>
                            route.push(routes.refsixStackSportsIntegration)
                        }
                    >
                        Stack Officials 🇺🇸 🇨🇦
                    </IonItem>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
