import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import pitchImage from "../../assets/images/pitch.png";
import "./pitchMap.css";
import { GpsCalibrationPoints, GpsPoint, HalfEvent } from "refsix-js-models";
import StatsPeriodToggle from "./statsPeriodToggle";
import HeatMapRenderer, { DataPoint } from "heatmap-ts";
import { useDimensions } from "../hooks";
import { StoreData } from "heatmap-ts/dist/store";
import { generatePlottingDataForSegments } from "refsix-core";

interface HeatMapInterface {
    playingSegments: HalfEvent[];
    geoPoints: GpsPoint[];
    calibrationPoints: GpsCalibrationPoints;
    periodsNo: string;
    testId?: string;
}

const DEFAULT_WIDTH = 300;
const HEATMAP_INTENSITY = 0.4;
const POINT_VALUE = 100;
const POINT_RADIUS_METERS = 4;

const HeatMapComponent: FC<HeatMapInterface> = ({
    playingSegments,
    geoPoints,
    calibrationPoints,
    periodsNo,
    testId,
}) => {
    const [selectedSegment, setSelectedSegment] = useState<number>(0);
    const [data, setData] = useState<StoreData | null>(null);
    const [containerDiv, dims] = useDimensions();
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [canvasSize, setCanvasSize] = useState<[number, number]>([
        DEFAULT_WIDTH,
        100,
    ]);

    useEffect(() => {
        console.log("sumdims", dims);
    }, [dims]);

    const segmentsPlottedData = useMemo(() => {
        if (geoPoints && calibrationPoints && playingSegments && dims?.width) {
            console.log(dims?.width, DEFAULT_WIDTH);
            return generatePlottingDataForSegments(
                geoPoints,
                calibrationPoints,
                playingSegments,
                dims?.width || DEFAULT_WIDTH
            );
        }
        return null;
    }, [geoPoints, calibrationPoints, playingSegments, dims]);

    // Ralculate on dimension changes
    useEffect(() => {
        // pick which playing segment to show e.g. [all, h1, h2, et1, et2...]
        if (segmentsPlottedData) {
            const processedData = segmentsPlottedData[selectedSegment];
            if (processedData && processedData.plottingData) {
                const plottingData: StoreData = {
                    data: processedData.plottingData.data.map(function (point) {
                        const dataPoint: DataPoint = {
                            ...point,
                            value: POINT_VALUE,
                            radius:
                                POINT_RADIUS_METERS *
                                (processedData.plottingData.pixelsPerMeter ||
                                    1),
                        };
                        return dataPoint;
                    }),
                    max:
                        processedData.plottingData.data.length *
                        HEATMAP_INTENSITY,
                };
                setData(plottingData);
                setCanvasSize([
                    processedData.plottingData.width,
                    processedData.plottingData.height,
                ]);
            } else {
                setData({ data: [] });
            }
        } else {
            setData({ data: [] });
        }
    }, [selectedSegment, segmentsPlottedData]);

    useEffect(() => {
        if (canvasRef.current && data && canvasSize) {
            const heatmap = new HeatMapRenderer({
                canvas: canvasRef.current,
                width: canvasSize[0],
                height: canvasSize[1],
            });
            heatmap.setData(data);
            heatmap.repaint();
        }
    }, [data, canvasRef.current, canvasSize]);

    return (
        <div ref={containerDiv} data-testid={testId}>
            <StatsPeriodToggle
                playingSegments={playingSegments}
                periodsNo={periodsNo}
                onSegmentChange={setSelectedSegment}
            />
            <div className="map-container">
                <img
                    src={pitchImage}
                    width={canvasSize[0]}
                    height={canvasSize[1]}
                    alt="Pitch image"
                    className="responsive svg-pitch"
                />
                <canvas
                    ref={canvasRef}
                    width={canvasSize[0]}
                    height={canvasSize[1]}
                />
            </div>
        </div>
    );
};

export default HeatMapComponent;
