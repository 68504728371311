import { InflateData } from "./InflateData";

export class FitnessDataProcessing {
    self = this;
    inflateData: InflateData;

    constructor(atob?: (data: string) => string) {
        if (atob) {
            // pass in a polyfill for node environment
            this.inflateData = new InflateData(atob);
        } else {
            // assume browser environment
            this.inflateData = new InflateData(window.atob);
        }
    }

    _hrCsvToJson(csvData) {
        if (typeof csvData !== "string" || csvData.length === 0) {
            return [];
        }
        return csvData
            .trim()
            .replace(/\n$|^\n/g, "")
            .split("\n")
            .map(function (line) {
                let values = line.split(",");
                if (values.length === 2) {
                    return {
                        value: Number(values[0]),
                        time: Number(values[1]),
                    };
                }
            });
    }

    _gpsCsvToJson(csvData) {
        if (typeof csvData !== "string" || csvData.length === 0) {
            return [];
        }
        return csvData
            .trim()
            .replace(/\n$|^\n/g, "")
            .split("\n")
            .map(function (line) {
                let values = line.split(",");
                if (values.length >= 6) {
                    return {
                        accuracy: Number(values[0]),
                        bearing: Number(values[1]),
                        latitude: Number(values[2]),
                        longitude: Number(values[3]),
                        speed: Number(values[4]),
                        time: Number(values[5]),
                    };
                }
            });
    }

    _processDataFromWatch(trackingData, csvFn, outputParam) {
        const self = this;
        let processedResult = trackingData;
        if (trackingData.format) {
            let formats = trackingData.format.split(";");
            processedResult = formats.reduce(function (result, format) {
                switch (format) {
                    case "gzip":
                        result.data = self.inflateData.inflateData(result.data);
                        break;
                    case "csv":
                        result.data = csvFn(result.data);
                }
                return result;
            }, trackingData);
        }

        processedResult[outputParam] =
            typeof processedResult.data === "string"
                ? JSON.parse(processedResult.data)
                : processedResult.data;
        return processedResult;
    }

    processWatchHeartRate(payload) {
        return this._processDataFromWatch(
            payload,
            this._hrCsvToJson,
            "heartRateValues"
        );
    }

    processWatchGPS(payload) {
        return this._processDataFromWatch(
            payload,
            this._gpsCsvToJson,
            "geoPoints"
        );
    }
}

export default FitnessDataProcessing;
