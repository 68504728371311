import { UseList } from "./types";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getFuseCache } from "../../../services/TeamPackService";
import MiniSearch from "minisearch";
import { IonItem, IonLabel, IonListHeader } from "@ionic/react";
import { groupBy as _groupBy, map as _map } from "lodash";
import _ from "lodash/fp";
import { useTeams } from "../match";
import { withIds } from "../utils";
import { useTeamPackCompetitionTeams } from "../useTeamPackCompetitionTeams";

export type TeamListItem = {
    id: any;
    name: string;
    league: string;
    order?: number;
};
export type UseTeamList<T> = UseList<T> & {
    //setCompetition?: (competition: string) => void;
};
const useTeamsItems = () => {
    const { t } = useTranslation();
    const previousTeams = useTeams();
    return useMemo(
        () =>
            previousTeams.map((v, id) => ({
                name: v,
                league: t("fixture.FixtureNew.yourTeams"),
            })) as TeamListItem[],
        [t, previousTeams]
    );
};

export const useTeamList: (competition: string) => UseList<TeamListItem[]> = (
    competition: string
) => {
    const { t } = useTranslation();
    const teamPackTeams = useTeamPackCompetitionTeams(competition);
    //const teamPackTeams = useTeamPackTeams();
    const _previousTeams = useTeamsItems();
    const previousTeams = useMemo(
        () => withIds(_previousTeams) || [],
        [_previousTeams]
    );

    const [res, setRes] = useState<UseList<TeamListItem[]>>({});
    const teamPackSearch = getFuseCache("teams");

    const localSearch = useMemo(() => {
        const search = new MiniSearch({
            fields: ["name"],
            storeFields: ["name", "league"],
        });
        search.addAll(previousTeams);
        return search;
    }, [previousTeams]);

    const cb: (query: string) => TeamListItem[] = useCallback(
        (query: string = "") => {
            if (!localSearch) {
                return [];
            }
            //setCurrentQuery(query);
            if (!query) {
                if (teamPackTeams.length > 0) {
                    return teamPackTeams;
                }
                return previousTeams;
            }
            const res: TeamListItem[] =
                [
                    ...(localSearch.search(query, {
                        prefix: true,
                        fuzzy: false,

                        //combineWith: "AND",
                    }) as any as TeamListItem[]),
                    ...((teamPackSearch?.search(
                        query
                    ) as any as TeamListItem[]) || []),
                ] || [];
            return res;
        },
        [localSearch, previousTeams, teamPackTeams]
    );
    const renderer = useCallback(
        (results: TeamListItem[], save: (v: string) => void) => {
            const renderGroupItem = (item: TeamListItem, idx: number) => {
                return (
                    <IonItem
                        key={`${idx}-${item.name}`}
                        onClick={() => save(item.name)}
                    >
                        {item.name}
                    </IonItem>
                );
            };
            const renderGroup = (group: TeamListItem[], groupName: string) => {
                return (
                    <div key={groupName}>
                        <IonListHeader>
                            <IonLabel>{groupName}</IonLabel>
                        </IonListHeader>
                        {_map(
                            _.orderBy(["name"], ["asc"], group),
                            renderGroupItem
                        )}
                    </div>
                );
            };
            // Extract the  results for the current competition
            const competitionResults: any = _.groupBy((item: TeamListItem) => {
                if (item.league === competition) {
                    return "competition";
                } else if (item.league === t("fixture.FixtureNew.yourTeams")) {
                    return "yourTeams";
                }
                return "other";
            });
            const groupedResults = competitionResults(results);

            const renderedItems: any[] = [];
            if (groupedResults["competition"]) {
                renderedItems.push(
                    renderGroup(groupedResults["competition"], competition)
                );
            }
            if (groupedResults["yourTeams"]) {
                renderedItems.push(
                    renderGroup(
                        groupedResults["yourTeams"],
                        t("fixture.FixtureNew.yourTeams")
                    )
                );
            }
            if (groupedResults["other"]) {
                const otherResults = groupedResults["other"];
                const groupedOtherResults = _groupBy<TeamListItem>(
                    _.take(100, otherResults),
                    "league"
                );
                for (let key in groupedOtherResults) {
                    renderedItems.push(
                        renderGroup(groupedOtherResults[key], key)
                    );
                }
            }
            return renderedItems;
        },
        [competition, t]
    );
    useEffect(() => {
        setRes({
            search: cb,
            renderer,
            //setCompetition,
        });
    }, [cb, renderer]);
    return res;
};
