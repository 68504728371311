import { FC } from "react";
import { IonCol, IonItem, IonRow, IonText } from "@ionic/react";
import { getProcessedValues } from "../services/statsService";
import { distanceString } from "./DistanceComponent";
import { secondsToMinuteClock } from "refsix-core";
import { CurrencyFilter } from "../utils/CurrencyFilter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { createArrayOfOnes } from "../services/dummyDataService";
import "./RoundBarComponent.css";
import { getRefereeRoleClass } from "./MatchMiddleCardComponent";

interface RoundBarComponentInterface {
    values: number[];
    title?: string;
    series: string[];
    distanceBy?: string;
    numberOfCategories: number;
    unit?: "distance" | "%" | "minutes" | "currency";
    colorOverride?: string | string[];
    seriesIcon?: boolean;
    testId?: string;
    useDummyData?: boolean;
}

const RoundBarComponent: FC<RoundBarComponentInterface> = ({
    title,
    series,
    values,
    distanceBy,
    numberOfCategories,
    unit,
    colorOverride,
    seriesIcon,
    testId,
    useDummyData,
}) => {
    const { t } = useTranslation();
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );

    // Replace values with dummy values if useDummyData is true
    if (useDummyData) values = createArrayOfOnes(values.length);

    function getLabelValue(value: number) {
        switch (unit) {
            case "distance":
                return distanceString(value, useImperial, t, 2);
            case "%":
                return Math.round(value) + unit;
            case "minutes":
                return secondsToMinuteClock(value);
            case "currency":
                return CurrencyFilter(value);
            default:
                return value;
        }
    }

    return (
        <IonItem
            lines="none"
            className="round-bar-graph-container"
            data-testid={testId}
        >
            <IonCol>
                <IonText className="text-small">{title}</IonText>
                {getProcessedValues(values, series, distanceBy, colorOverride)
                    .slice(0, numberOfCategories)
                    .map((el, index) => {
                        return (
                            <IonRow key={index} className="round-bar-container">
                                <IonCol size="10.3" className="col-round-bar">
                                    <div
                                        key={index}
                                        className="round-bar-graph"
                                        style={{
                                            width: `${el.width}%`,
                                            backgroundColor: el.color,
                                        }}
                                    >
                                        <IonText className="values-text">
                                            {getLabelValue(el.value)}
                                        </IonText>
                                    </div>
                                </IonCol>
                                <div>
                                    {seriesIcon ? (
                                        <div
                                            className={`role-icons ${getRefereeRoleClass(
                                                series[index]
                                            )} small`}
                                        />
                                    ) : (
                                        <IonText className="round-bar-label">
                                            {series[index]}
                                        </IonText>
                                    )}
                                </div>
                            </IonRow>
                        );
                    })}
            </IonCol>
        </IonItem>
    );
};

export default RoundBarComponent;
