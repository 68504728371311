import React, { FC, useCallback } from "react";
import { useIntegrationsMessages } from "./hooks";
import { IonButton, IonCard, IonCardContent, IonToast } from "@ionic/react";
import { useDispatch } from "react-redux";
import css from "./SessionExpiredBanner.module.css";
import { useTranslation } from "react-i18next";
import { popIntegrationMessage } from "../redux/actions/integration";
import { useHistory } from "react-router";
import { routes } from "../route/constants";
import { IntegrationPartner } from "refsix-js-models";
import { IntegrationMessageType } from "../redux/models/refsixState";

export const IntegrationsBanner: FC = () => {
    const messages = useIntegrationsMessages();

    if (!messages || messages.length === 0) {
        return <></>;
    }
    const message = messages[0];
    if (
        message.integration === IntegrationPartner.assignr &&
        message.message === IntegrationMessageType.MISSING_CREDENTIALS
    ) {
        return <AssignrLoginBanner />;
    }
    if (
        message.integration === IntegrationPartner.assignr &&
        message.message === IntegrationMessageType.SERVICE_UNAVAILABLE
    ) {
        return <AssignrSyncToast />;
    }
    return <></>;
};

export const AssignrSyncToast: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <IonToast
            isOpen={true}
            message={t("integrations.assignr.notResponding")}
            duration={5000}
            onDidDismiss={() => {
                dispatch(popIntegrationMessage(IntegrationPartner.assignr));
            }}
            onClick={() => {
                dispatch(popIntegrationMessage(IntegrationPartner.assignr));
            }}
        />
    );
};

export const AssignrLoginBanner: FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const clickLogin = useCallback(async () => {
        // Pop the integration message, and then navigate to the login page
        dispatch(popIntegrationMessage(IntegrationPartner.assignr));
        history.push(routes.refsixAssignrIntegration);
    }, [dispatch, history]);

    return (
        <>
            <IonCard className={css.card}>
                <IonCardContent
                    className={css.container}
                    style={{ padding: 10 }}
                >
                    <div className={css.content} style={{ fontSize: 14 }}>
                        {t("integrations.assignr.syncingStopped")}
                    </div>
                    <div className={css.vr}>
                        <div className={css["vertical-hr"]}></div>
                    </div>
                    <IonButton
                        className={`${css.button} ion-text-wrap`}
                        size="default"
                        fill="clear"
                        onClick={clickLogin}
                    >
                        {t("integrations.assignr.startOAuthFlow")}
                    </IonButton>
                </IonCardContent>
            </IonCard>
        </>
    );
};
