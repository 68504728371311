import { FC, useEffect } from "react";
import {
    useLoadingMatches,
    useProfile,
    useUserActionsCheckList,
} from "./hooks";
import { routes } from "../route/constants";
import { useIonRouter } from "@ionic/react";

export const useOnboardingInfo = () => {
    const userActionsCheckList = useUserActionsCheckList() || {};
    const profile = useProfile();
    const route = useIonRouter();

    const onboardingCompleted =
        userActionsCheckList.onboardingCompleted || false;
    const matchCreatedCountPhone =
        userActionsCheckList.matchCreatedCountPhone || 0;
    const matchCreatedCountWatch =
        userActionsCheckList.matchCreatedCountWatch || 0;

    const sufficientActions =
        matchCreatedCountPhone > 0 || matchCreatedCountWatch > 0;

    const isAlreadyOnboardingPage =
        route.routeInfo?.pathname?.indexOf("onboarding") >= 0;

    const pageRoute =
        profile?.firstName && profile?.lastName
            ? routes.refsixOnboardingGettingStarted
            : routes.registerStepTwo;

    return {
        onboardingCompleted,
        matchCreatedCountPhone,
        matchCreatedCountWatch,
        showOnboarding: !(
            onboardingCompleted ||
            sufficientActions ||
            isAlreadyOnboardingPage
        ),
        pageRoute,
    };
};

export const OnBoardingManager: FC<{}> = ({ children }) => {
    // If user action checklist Result and Fixture count is 0, show onboarding
    // And if a user's info is missing send them to page 2 of registration
    const onBoardingInfo = useOnboardingInfo();
    const { showOnboarding, pageRoute } = onBoardingInfo;
    const route = useIonRouter();
    const loading = useLoadingMatches();

    useEffect(() => {
        if (loading === false && showOnboarding) {
            console.log(
                "Loading:",
                loading,
                "ShowOnboarding:",
                showOnboarding,
                "PageRoute:",
                pageRoute
            );
            route.push(pageRoute, "forward", "replace");
        }
    }, [route, loading, showOnboarding]);

    return null;
};
