// a react ionic page component to display the developer tools

import {
    IonBackButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getDatabaseService } from "../../services/matchService";
import { MatchHeader } from "../addResult/matchHeader";
import { MatchPhone } from "refsix-js-models";
import { useTranslation } from "react-i18next";

const PouchDebug: React.FC = () => {
    const { t } = useTranslation();
    const localDB = getDatabaseService()?.getLocalDatabase();

    const [changes, setChanges] = useState<any[]>([]);
    let changesListener: any;

    function filterChanges(changes: any[]) {
        return changes.filter((change) => {
            return !!change.doc;
        });
    }

    useEffect(() => {
        localDB
            ?.changes({
                include_docs: true,
                conflicts: true,
                descending: true,
                style: "all_docs",
            })
            .then((log) => {
                console.log("changes", log);
                setChanges(log.results);
            });
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref="/"
                            text={t("help.OnboardingVideo.controls.back")}
                        />
                    </IonButtons>
                    <IonTitle>PouchDB Changes Log</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonText>
                            This page contains tools designed to help debug the
                            the local database in the app.
                        </IonText>
                    </IonItem>
                </IonList>

                <div>
                    {changes.map((change) => (
                        <IonCard key={change.seq}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td>seq</td>
                                        <td>{change.seq}</td>
                                    </tr>

                                    <tr>
                                        <td>deleted</td>
                                        <td>{change.deleted ? "Yes" : "No"}</td>
                                    </tr>

                                    {change.doc && (
                                        <>
                                            <tr>
                                                <td>id</td>
                                                <td>{change.doc._id}</td>
                                            </tr>
                                            <tr>
                                                <td>rev</td>
                                                <td>{change.doc._rev}</td>
                                            </tr>
                                        </>
                                    )}

                                    {change.doc &&
                                        change.doc._id.indexOf("r6_") < 0 && (
                                            <>
                                                <tr>
                                                    <td>Stats Processed</td>
                                                    <td>
                                                        {!!change.doc.stats
                                                            ? "Yes"
                                                            : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Stats Version</td>
                                                    <td>
                                                        {
                                                            change.doc.stats
                                                                ?.version
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Has Tracking</td>
                                                    <td>
                                                        {change.doc.hasTracking
                                                            ? "Yes"
                                                            : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>GPS Processed</td>
                                                    <td>
                                                        {" "}
                                                        {change.doc.stats
                                                            ?.gpsProcessed === 1
                                                            ? "Yes"
                                                            : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Has Heart Rate</td>
                                                    <td>
                                                        {change.doc.hasHeartRate
                                                            ? "Yes"
                                                            : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>HR Processed</td>
                                                    <td>
                                                        {" "}
                                                        {change.doc.stats
                                                            ?.heartRateProcessed ===
                                                        1
                                                            ? "Yes"
                                                            : "No"}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                </tbody>
                            </table>

                            {change.doc._id.indexOf("r6_") < 0 && (
                                <MatchHeader match={change.doc as MatchPhone} />
                            )}
                        </IonCard>
                    ))}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default PouchDebug;
