import { IonCol, IonItem } from "@ionic/react";

const TermsAndConditionsText = () => {
    return (
        <IonItem>
            <IonCol>
                <h5>
                    We are REFSIX Ltd, a company incorporated and registered in
                    England and Wales with company number 10416702 whose
                    registered office is at 51 Half Moon Lane, Worthing, West
                    Sussex.
                </h5>
                <div className="sqs-block-content">
                    <p>These Terms of Use</p>
                    <p>
                        These terms of use (together with the documents referred
                        to in them) (the Terms) govern your relationship with us
                        when you use the REFSIX app (the Software) available
                        from our website{" "}
                        <a href="http://www.refsix.com">www.refsix.com</a>
                        and (if applicable) use any of the services offered via
                        the Software (the Services).
                    </p>
                    <p>
                        Please refer carefully to these Terms before you start
                        to use the Software and (if applicable) the Services. We
                        recommend that you print a copy of these Terms for
                        future reference. By using the Software and (if
                        applicable) the Services, you will be deemed to have
                        understood and accepted these Terms, which form a
                        binding agreement between you and us.
                    </p>
                    <p>
                        If you are intending to use the Services on behalf of a
                        business you work for or represent (the Company) these
                        Terms form an agreement between us and the Company, in
                        which case all references to 'you' should be read as
                        references to the Company. Please therefore ensure that
                        you have the authority of the Company to bind it to
                        these Terms. If you do not have such authority you may
                        be personally liable to us for your use of the Software
                        and the Services.
                    </p>
                    <p>
                        These Terms may be supplemented by additional written
                        agreement(s) between you and us which relate to specific
                        uses of the Services. If that is the case these Terms
                        will continue to apply to you except to the extent they
                        conflict with the terms of any such written agreement.
                    </p>
                    <p>Other Applicable Terms</p>
                    <p>
                        These Terms refer to our Privacy Policy and Cookie
                        Policy{" "}
                        <a href="http://www.refsix.com/privacy">
                            www.refsix.com/privacy
                        </a>
                        (the Privacy and Cookie Policy), which also applies to
                        your use of the Software. The Privacy and Cookie Policy
                        sets out (a) the terms on which we process any personal
                        data we collect from you or that you provide to us; and
                        (b) information about our use of cookies. By using the
                        Software and (if applicable) the Services, you consent
                        to such processing and you warrant that all data
                        provided by you is accurate.
                    </p>
                    <p>Changes to These Terms</p>
                    <p>
                        We may revise these Terms at any time by amending this
                        page. Please check this page (www.refsix.com/privacy)
                        from time to time to take notice of any changes we made,
                        as they are binding on you.
                    </p>
                    <p>The Services</p>
                    <p>
                        The REFSIX app, which allows you to record match
                        incidents in games and track your physical performance.
                        &nbsp;
                    </p>
                    <p>
                        The list above is not an exhaustive list of the Services
                        we offer, and we may decide to offer additional
                        Services, or cease to provide any Services, at any time.
                    </p>
                    <p>Registering for the Services</p>
                    <p>
                        You are not obliged to register to download the
                        Software, but access to the Subscriber Services is only
                        available to registered users.
                    </p>
                    <p>
                        To register, you will need to complete a simple
                        registration process by providing certain information
                        (including your name, email address, payment details (if
                        applicable), and choose a username and password for use
                        in connection with your access to the Services.
                    </p>
                    <p>
                        You agree that you will provide truthful and accurate
                        information when registering. The decision to register a
                        password is in our discretion and we may revoke your
                        password at any time.
                    </p>
                    <p>
                        You are responsible for maintaining the confidentiality
                        of your password and account information, and you are
                        solely responsible for all activities that occur under
                        your password or account and for any access to or use of
                        the Software by you or any person or entity using your
                        password, whether or not such access or use has been
                        authorised by you.
                    </p>
                    <p>
                        You must immediately notify us of any unauthorised use
                        of your password or account or any other breach of
                        security. We will not be liable for any loss or damage
                        whatsoever resulting from the disclosure of your
                        username and/or password contrary to these Terms.
                    </p>
                    <p>
                        You are responsible for ensuring that the information we
                        hold is up to date. Please amend your details as
                        appropriate from time to time or email
                        support@refsix.com to notify us of any changes.
                    </p>
                    <p>
                        Please refer to our Privacy Policy{" "}
                        <a href="http://www.refsix.com/privacy">
                            www.refsix.com/privacy
                        </a>{" "}
                        for further explanation as to how we use the information
                        we collect from you.
                    </p>
                    <p>Access to the Subscriber Services</p>
                    <p>
                        Access to the Subscriber Services is subject to the
                        payment of a monthly or annual subscription fee, which
                        will be displayed on the relevant page(s) of our website
                        (the Subscription Fee).
                    </p>
                    <p>
                        You must subscribe to the Subscriber Services for a
                        minimum of one (1) month.
                    </p>
                    <p>
                        You may cancel your subscription at any time after the
                        first month, by emailing us at support@refsix.com.
                    </p>
                    <p>
                        Your subscription to the Subscriber Services, including
                        your payment each month of the Subscriber Fee, will
                        continue until cancelled by you as described above, or
                        alternatively until terminated by us as described in the
                        'Termination' section below.
                    </p>
                    <p>Your Use of the Software</p>
                    <p>
                        You are responsible for making all arrangements
                        necessary to download the Software. You are also
                        responsible for ensuring that all persons who access the
                        Software are aware of these Terms and that they comply
                        with them.
                    </p>
                    <p>
                        You may use the Software and (if applicable) the
                        Services only for lawful purposes. In particular, but
                        without limitation, may not use the Software or (if
                        applicable) the Services:
                    </p>
                    <p>
                        • in any way that is unlawful or fraudulent, or has any
                        unlawful or fraudulent purpose or effect;
                    </p>
                    <p>
                        • to send, or procure the sending of, any unsolicited or
                        unauthorised advertising or promotional material or any
                        other form of similar solicitation (spam); or
                    </p>
                    <p>
                        • to knowingly transmit any data, send or upload any
                        material that contains viruses, Trojan horses, worms,
                        time-bombs, keystroke loggers, spyware, adware or any
                        other harmful programs or similar computer code designed
                        to adversely affect the operation of the Software or any
                        computer software or hardware.
                    </p>
                    <p>You also agree:</p>
                    <p>
                        • not to reproduce, duplicate, copy or re-sell any part
                        of the Software in contravention of these Terms;
                    </p>
                    <p>
                        • not to use the Software in any way that could damage,
                        disable, overburden, impair or otherwise compromise our
                        systems or security or interfere with other users of the
                        Software; or
                    </p>
                    <p>
                        • not to access without authority, interfere with,
                        damage, alter, disassemble, reverse-engineer, or disrupt
                        (a) any part of the Software; (b) any equipment or
                        network from which the Software and the Themes are
                        provided; or (c) any equipment or network or software
                        owned or used by any third party.
                    </p>
                    <p>&nbsp;</p>
                </div>
                <div className="sqs-block-content">
                    <p>Intellectual Property</p>
                    <p>
                        You acknowledge that our website and the Software and
                        all material available through the website and the
                        Software including but not limited to text, graphics,
                        photos, logos, button icons, images, trade marks,
                        databases, data and software (the REFSIX App) are owned
                        and controlled by or licensed exclusively to us.
                    </p>
                    <p>
                        You may print off one copy, and may download extracts,
                        of any page(s) from our website which you are authorised
                        to access provided that such prints and downloads are
                        for your personal and non-commercial use only.
                    </p>
                    <p>Availability of the Software</p>
                    <p>
                        The Software is provided on an "as is" basis without any
                        warranties of any kind. We make no representations,
                        warranties or guarantees of any kind regarding the
                        availability or operation of the Software or that the
                        Software will be secure, uninterrupted or free of
                        defects.
                    </p>
                    <p>
                        Your access to the Services may be suspended or
                        restricted occasionally to allow for maintenance,
                        repairs, upgrades, or the introduction of new facilities
                        or services. We will always try to limit the frequency
                        and duration of any planned disruption but we will not
                        be liable to you if for any reason the Services are
                        unavailable at any time or for any period.
                    </p>
                    <p>Computer Viruses</p>
                    <p>
                        We will use reasonable endeavours to ensure that no part
                        of the Software will contain or spread any viruses or
                        other malicious code. However, we recommend that you
                        ensure that devices on which you download the Software
                        run up-to-date anti-virus software as a precaution, and
                        you are advised to virus-check the Software and
                        regularly check for the presence of viruses and other
                        malicious code.
                    </p>
                    <p>
                        To the full extent permitted by law we exclude liability
                        for damage or loss of any kind caused by viruses or
                        other harmful components originating or contracted from
                        the Software.
                    </p>
                    <p>Limitation of Liability</p>
                    <p>
                        Nothing in these Terms excludes or limits our liability
                        for death or personal injury arising from our
                        negligence, or our fraud or fraudulent
                        misrepresentation, or any other liability that cannot be
                        excluded or limited by law.
                    </p>
                    <p>
                        Except as expressly set out in these Terms, we make no
                        representations, warranties or guarantees of any kind in
                        respect of the Software or the Services and all
                        conditions, warranties and representations express or
                        implied are hereby excluded to the full extent permitted
                        by law.
                    </p>
                    <p>
                        We will not be liable to you for any loss or damage,
                        whether in contract, tort (including negligence), breach
                        of statutory duty, or otherwise arising under or in
                        connection with (a) use of, or inability to use, the
                        Software and/or the Services; or (b) use of or reliance
                        on any content displayed via the Software.
                    </p>
                    <p>
                        We provide the Software and the Services for both
                        private and commercial use. You agree that if you use
                        the Software and/or the Services for any commercial or
                        business purposes, we shall have no liability to you for
                        any loss of profit, loss of business, business
                        interruption, or loss of business opportunity.
                    </p>
                    <p>
                        We assume no responsibility for the content of any third
                        party websites linked to on the Software and we will not
                        be liable for any loss or damage that may arise from
                        your use of them.
                    </p>
                    <p>Termination</p>
                    <p>
                        We may, in our sole discretion, terminate your password,
                        account (or any part thereof) or use of the Software
                        and/or the Services without notice if you are in breach
                        of these Terms or if we believe in our absolute
                        discretion that your use of the Software and/or Services
                        is unsuitable in any way.
                    </p>
                    <p>
                        Upon termination by us for any reason referred to above
                        all rights granted to you under these Terms shall cease
                        and you must immediately cease all your use of the
                        Services, save that you may continue to use any
                        Synchronised Content downloaded by you prior to such
                        termination or cancellation as licensed hereunder.
                    </p>
                    <p>
                        Notwithstanding the foregoing, upon termination by us
                        for any reason referred to above, we reserve the right
                        to require you to cease all use of any Synchronised
                        Content downloaded by you prior to such termination and
                        to remove any or all such Synchronised Content from any
                        platform or media via which it is available.
                    </p>
                    <p>Payment Methods</p>
                    <p>
                        We accept payment via MasterCard, Visa and American
                        Express.
                    </p>
                    <p>
                        Prices for all the Services appear on our website and
                        are inclusive of VAT unless otherwise stated. We may
                        change the prices for Services at any time by posting
                        new prices on the website.
                    </p>
                    <p>
                        By paying using your credit or debit card you confirm
                        that the card is yours and that there are sufficient
                        funds or credit available to cover the charges.
                    </p>
                    <p>Links to Other Sites</p>
                    <p>
                        The website and/or the Software may offer links to other
                        websites from which third party services can be obtained
                        and which we reasonably believe to be reputable sources
                        of such services. However, you acknowledge that these
                        other websites are independent from us and we make no
                        representations or warranties as to the legitimacy,
                        accuracy or quality of such third party services, and we
                        do not accept any responsibility for their content,
                        safety, practices or privacy policies.
                    </p>
                    <p>Miscellaneous</p>
                    <p>
                        If any provision or part of a provision of these Terms
                        is held to be illegal, invalid, unenforceable or against
                        public policy pursuant to a final adjudication by a
                        court of competent jurisdiction, such provision will be
                        deemed severed from these Terms and the remainder of
                        these Terms will remain in full force and effect.
                    </p>
                    <p>
                        No provision of these Terms will be enforceable solely
                        by virtue of the Contracts (Rights of Third Parties) Act
                        1999 by any person who is not a party to these Terms.
                    </p>
                    <p>
                        These Terms are governed by English law and the courts
                        of England and Wales will have non-exclusive
                        jurisdiction over any disputes arising in respect of or
                        in relation to these Terms and your use of the Software
                        and (if applicable) the Services.
                    </p>
                </div>
            </IonCol>
        </IonItem>
    );
};

export default TermsAndConditionsText;
