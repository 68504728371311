import "./ColorPickerComponent.css";
import { FC } from "react";

interface ColorPickerInterface {
    colorPicked(color: string): void;
}

const ColorPickerComponent: FC<ColorPickerInterface> = (props) => {
    const colors = [
        "#920202",
        "#f71311",
        "#FF3F33",
        "#FF9F33",
        "#F9FF33",
        "#F6FF73",
        "#1A9202",
        "#49FF33",
        "#009794",
        "#2BECFF",
        "#0000FF",
        "#000080",
        "#9C33FF",
        "#FC33FF",
        "#FE9DFE",
        "#131313",
        "#975700",
        "#8C8C8C",
        "#FFFFFF",
        "#e4cbad",
    ];

    return (
        <div className="color-picker-container">
            {colors.map((color, index) => {
                return (
                    <button
                        key={index}
                        className="color-picker"
                        style={{ backgroundColor: color }}
                        onClick={() => props.colorPicked(color)}
                    />
                );
            })}
        </div>
    );
};

export default ColorPickerComponent;
