import { FC } from "react";
import { isUserPlusEligible } from "../../services/subscriptionService";
import { isAndroid, isIos } from "../../services/platformDetection";
import { routes } from "../../route/constants";
import { trackProTeaser } from "../../services/analytics/analyticsService";
import { URLS } from "../../constants/urls";
import AlertComponent from "../AlertComponent";
import { useTranslation } from "react-i18next";
import { useIonRouter } from "@ionic/react";

interface GoProOrPlusAlertComponentProps {
    showProAlert: boolean;
    setShowProAlert: (value: boolean) => void;
    goProTestId: string;
    teaserName: string;
}

export const GoProOrPlusAlertComponent: FC<GoProOrPlusAlertComponentProps> = ({
    showProAlert,
    setShowProAlert,
    goProTestId,
    teaserName,
}) => {
    const { t } = useTranslation();
    const route = useIonRouter();

    return (
        <AlertComponent
            testId={goProTestId}
            title={`REFSIX ${
                !isUserPlusEligible()
                    ? t("upgrade.proFeaturesContent.tableHeader3")
                    : t("upgrade.proFeaturesContent.tableHeader4")
            }`}
            description={
                !isUserPlusEligible()
                    ? t("accountNotification.featureUnavailable.description")
                    : t(
                          "accountNotification.featureUnavailable.descriptionPlus"
                      )
            }
            showAlert={showProAlert}
            buttonDone={t("accountNotification.featureUnavailable.close")}
            onDonePressed={() => setShowProAlert(false)}
            dismissAlert={() => setShowProAlert(false)}
            showAlternativeButton={true}
            buttonAltText={t(
                "accountNotification.featureUnavailable.learnMore"
            )}
            onButtonAltClicked={() => {
                setShowProAlert(false);
                if (isIos() || isAndroid()) {
                    if (!isUserPlusEligible()) {
                        route.push(routes.upgrade + "/pro");
                        trackProTeaser(teaserName, "pro");
                    } else if (isUserPlusEligible()) {
                        route.push(routes.upgrade + "/plus");
                        trackProTeaser(teaserName, "plus");
                    }
                } else {
                    window.open(
                        URLS.REFSIX_ACCOUNT +
                            "?utm_source=refsix&utm_medium=app&utm_campaign=in-app-mobile-web&utm_id=in-app-mobile-web",
                        "_system"
                    );
                }
            }}
        />
    );
};
