import { UseList } from "./types";
import { Dictionary, map as _map } from "lodash";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import _, { flatten, flow, groupBy, map } from "lodash/fp";
import { IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import MiniSearch from "minisearch";
import { useTeamPacksContext } from "../TeamPacks";
import { useCompetitions } from "../match";

export type CompetitionListItem = {
    name: string;
    league: string;
    fullName: string;
};
export const useAllCompetitionList = () => {
    const { t } = useTranslation();
    const { downloadedPacks, ready } = useTeamPacksContext();
    const originalComps = useCompetitions();
    const [fullCompList, setFullCompList] = useState<CompetitionListItem[]>();
    const [localCompList, setLocalCompList] = useState<CompetitionListItem[]>(
        []
    );
    useEffect(() => {
        // Get comps from team packs
        const packComps = flow(
            map("divisions"),
            flatten,
            map((comp) =>
                map(
                    (division: any) =>
                        ({
                            name: division.shortName,
                            league: comp.name,
                            fullName: division.fullName,
                        } as CompetitionListItem)
                )(comp.division)
            ),
            flatten
        )(downloadedPacks);
        // get comps from previous comps
        const yourComps = originalComps.map(
            (comp, id) =>
                ({
                    id,
                    name: comp,
                    league: t("fixture.FixtureNew.yourCompetitions"),
                    fullName: comp,
                } as CompetitionListItem)
        );
        const withIds = yourComps.concat(packComps).map((v, id) => ({
            ...v,
            id,
        }));
        setLocalCompList(yourComps);
        setFullCompList(withIds);
    }, [ready, downloadedPacks, originalComps, t]);

    return [fullCompList, localCompList];
};
export const useCompetitionSearch = () => {
    const [comps, yourComps] = useAllCompetitionList();
    //const [res, setRes] = useState<MiniSearch<CompetitionListItem>>();
    const res = useMemo(() => {
        if (!comps) return;

        const searcher = new MiniSearch<CompetitionListItem>({
            fields: ["league", "name"],
            storeFields: ["name", "league", "fullName"],
            searchOptions: {
                prefix: true,
                //maxFuzzy: 3,
                fuzzy: 0.1,
                combineWith: "AND",
            },
        });
        searcher.addAll(comps);
        return searcher;
    }, [comps]);

    return res;
};
export const useCompetitionList: () => UseList<
    Dictionary<CompetitionListItem[]>
> = () => {
    const { t } = useTranslation();
    const [comps, localComps] = useAllCompetitionList();
    const searcher = useCompetitionSearch();
    //const localComps = useCompetitions();

    const [res, setRes] = useState<UseList<Dictionary<CompetitionListItem[]>>>(
        {}
    );
    useEffect(() => {
        if (!searcher) return;

        setRes({
            search: (query: string = ""): Dictionary<CompetitionListItem[]> => {
                if (query === "") {
                    const res = groupBy<CompetitionListItem>("league")(
                        localComps || []
                    );
                    return res;
                }
                const res = searcher.search(query, {
                    prefix: true,
                }) as any as CompetitionListItem[];
                return groupBy<CompetitionListItem>("league")(res || []);
            },
            renderer: competitionsList2Renderer(t),
            ready: true,
        });
    }, [searcher, comps, t, localComps]);
    return res;
};
export const competitionsList2Renderer =
    (t: any) =>
    (results: Dictionary<CompetitionListItem[]>, save: (v: string) => void) => {
        const renderGroupItem = (item: CompetitionListItem, idx: number) => {
            return (
                <IonItem
                    key={`${idx}-${item.fullName}`}
                    onClick={() => {
                        save(item.fullName);
                    }}
                    style={{ fontSize: 14, "--min-height": "40px" }}
                >
                    {item.name}
                </IonItem>
            );
        };
        // This renders the group heading and then any values listed beneath it
        const renderGroup = (
            group: CompetitionListItem[],
            groupName: string,
            testId: string
        ) => {
            return (
                <div
                    key={groupName}
                    data-testid={`${_.kebabCase(groupName)}-${testId}`}
                >
                    <IonListHeader>
                        <IonLabel style={{ fontSize: 14, marginTop: 5 }}>
                            {groupName}
                        </IonLabel>
                    </IonListHeader>
                    <IonList
                        inset={true}
                        style={{ marginTop: 5, marginBottom: 5 }}
                    >
                        {_map(
                            _.orderBy(["name"], ["asc"], group),
                            renderGroupItem
                        )}
                    </IonList>
                </div>
            );
        };
        const renderedItems = [];

        for (let key in results) {
            if (
                key !== "" &&
                key !== t("fixture.FixtureNew.yourCompetitions")
            ) {
                renderedItems.push(renderGroup(results[key], key, "auto"));
            }
        }
        // If we have "your competitions" then render them first
        if (results[t("fixture.FixtureNew.yourCompetitions")]) {
            renderedItems.push(
                renderGroup(
                    results[t("fixture.FixtureNew.yourCompetitions")],
                    t("fixture.FixtureNew.yourCompetitions"),
                    "local"
                )
            );
        }

        return renderedItems;
    };
