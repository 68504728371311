import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonFooter,
    IonPage,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";
import "../login/login.css";
import refsixProLogo from "../../assets/images/go-pro/REFSIXPROInBadge.png";

import "./register.css";
import { routes } from "../../route/constants";
import { OnBoardingHeadingComponent } from "../onBoarding/onBoardingHeadingComponent";
import { HeaderComponent } from "../../components";

const RegisterFreeTrial: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();

    return (
        <IonPage className="notch-safe">
            <HeaderComponent title={t("upgrade.upgrade.freeTrial")} />
            <IonContent data-testid="registerFreeTrialPage">
                <OnBoardingHeadingComponent progressValue={0.9} />
                <h1
                    style={{ color: "white", fontSize: 18 }}
                    className="ion-text-center"
                >
                    {t("register.register.registrationConfirmation")}
                </h1>
                <div className="align-center">
                    <img
                        src={refsixProLogo}
                        alt={t("menu.upgrade")}
                        style={{ height: "200px" }}
                    ></img>
                </div>

                <IonCard style={{ color: "white" }}>
                    <IonCardContent>
                        <h2 style={{ fontSize: 20 }}>
                            {t("upgrade.upgrade.thankYouFreeTrial")}
                        </h2>
                        <ol style={{ paddingLeft: 15 }}>
                            <li>
                                <IonText>
                                    {t("upgrade.upgrade.finishFirstMatch")}
                                </IonText>
                            </li>
                            <li>
                                <IonText>
                                    {t("upgrade.upgrade.uniqueAlgo")}
                                </IonText>
                            </li>
                        </ol>
                    </IonCardContent>
                </IonCard>
            </IonContent>
            <IonFooter>
                <IonButton
                    data-testid="freeTrialContinueButton"
                    expand="block"
                    onClick={() => {
                        route.push(
                            routes.refsixOnBoardingSetupComplete,
                            "forward",
                            "replace"
                        );
                    }}
                >
                    {t("settings.WatchSettings.continue")}
                </IonButton>
            </IonFooter>
        </IonPage>
    );
};

export default RegisterFreeTrial;
