import {
    GpsCalibrationPoints,
    GpsPoint,
    HalfEvent,
    MatchPhone,
    PlottingData,
    Point,
    Sprint,
} from "refsix-js-models";
import { FC, useEffect, useRef, useState } from "react";
import pitchImage from "../../assets/images/pitch.png";
import "./pitchMap.css";
import "./sprintMap.css";
import { useDimensions } from "../hooks";
import StatsPeriodToggle from "./statsPeriodToggle";
import { COLORS_SPRINT_CATEGORY } from "../../constants/StatsColorPalette";
import { useTranslation } from "react-i18next";
import { IonCol, IonRow, IonText } from "@ionic/react";
import mapEventNameToDisplayName from "../../utils/MapEventToDisplayName";
import { hasFeatureAccess } from "../../services/subscriptionService";
import { copyObject, sprintsPositions } from "refsix-core";

interface SprintMapInterface {
    playingSegments: HalfEvent[];
    geoPoints: GpsPoint[];
    calibrationPoints: GpsCalibrationPoints;
    periodsNo: string;
    sprints: Sprint<number>[];
    match: MatchPhone;
    testId?: string;
}

const ARROW_HEAD_LENGTH = 10;
const ARROW_TAIL_WIDTH = 3;
const DEFAULT_WIDTH = 300;

const SprintMapComponent: FC<SprintMapInterface> = ({
    playingSegments,
    geoPoints,
    calibrationPoints,
    periodsNo,
    sprints,
    match,
    testId,
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [containerDiv, dims] = useDimensions();
    const [selectedSegment, setSelectedSegment] = useState<number>(0);
    const [data, setData] = useState<PlottingData<Sprint<Point>[]> | null>(
        null
    );
    const sprintMapAccess = hasFeatureAccess("sprints");
    const { t } = useTranslation();
    const [filtersByCategory] = useState<boolean[]>([true, true, true]);
    const sprintCategoryColors = COLORS_SPRINT_CATEGORY;
    const sprintCategories = [
        t("fixture.FixtureSummaryStats.sprintmapKey.low"),
        t("fixture.FixtureSummaryStats.sprintmapKey.medium"),
        t("fixture.FixtureSummaryStats.sprintmapKey.high"),
    ];
    const [segmentsPlottedData, setSegmentsPlottedData] = useState<
        PlottingData<Sprint<Point>[]>[]
    >([]);

    useEffect(() => {
        const segmentsPlottedData = sprintsPositions(
            geoPoints,
            calibrationPoints,
            playingSegments,
            sprints,
            dims?.width || DEFAULT_WIDTH,
            null
        );
        setSegmentsPlottedData(segmentsPlottedData);

        // pick which playing segment to show e.g. [all, h1, h2, et1, et2...]
        const processedData = segmentsPlottedData[selectedSegment];
        if (processedData) {
            setData(processedData);
        }
    }, [
        dims,
        selectedSegment,
        geoPoints,
        calibrationPoints,
        playingSegments,
        sprints,
    ]);
    useEffect(() => {
        if (canvasRef.current && data) {
            canvasRef.current.width = data.width;
            canvasRef.current.height = data.height;
            const ctx: CanvasRenderingContext2D | null =
                canvasRef.current.getContext("2d");
            if (ctx) {
                drawSprintMap(ctx);
            }
        }
    }, [data, canvasRef.current]);

    const _canvasArrow = (
        ctx: CanvasRenderingContext2D,
        fromx: number,
        fromy: number,
        tox: number,
        toy: number
    ) => {
        const dx = tox - fromx;
        const dy = toy - fromy;
        const angle = Math.atan2(dy, dx);
        ctx.moveTo(fromx, fromy);
        ctx.lineTo(tox, toy);
        ctx.lineTo(
            tox - ARROW_HEAD_LENGTH * Math.cos(angle - Math.PI / 6),
            toy - ARROW_HEAD_LENGTH * Math.sin(angle - Math.PI / 6)
        );
        ctx.moveTo(tox, toy);
        ctx.lineTo(
            tox - ARROW_HEAD_LENGTH * Math.cos(angle + Math.PI / 6),
            toy - ARROW_HEAD_LENGTH * Math.sin(angle + Math.PI / 6)
        );
    };

    const drawSprintMap = (ctx: CanvasRenderingContext2D) => {
        ctx.lineWidth = ARROW_TAIL_WIDTH;
        data?.data.forEach((sprint) => {
            const points = sprint.points;

            ctx.strokeStyle = COLORS_SPRINT_CATEGORY[sprint.level - 1];

            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);

            points.forEach(function (point: Point) {
                ctx.lineTo(point.x, point.y);
                ctx.moveTo(point.x, point.y);
            });

            _canvasArrow(
                ctx,
                points[points.length - 2].x,
                points[points.length - 2].y,
                points[points.length - 1].x,
                points[points.length - 1].y
            );

            ctx.stroke();
        });
    };

    // Filtering either the category in or out based on selection.
    function filterSprintsByCategory(
        sprintsForSegment: PlottingData<Sprint<Point>[]>
    ) {
        // Copying sprintsForSegment into sprints.
        const sprints = copyObject(sprintsForSegment);
        sprints.data = sprintsForSegment.data.filter(function (sprint) {
            return filtersByCategory[sprint.level - 1];
        });
        return sprints;
    }

    // Updating sprintMap based on which sprintCategories is chosen.
    function updateSprintMap() {
        if (segmentsPlottedData) {
            const sprintsForSegment = segmentsPlottedData[selectedSegment];
            const filteredSprintsByCategory =
                filterSprintsByCategory(sprintsForSegment);
            if (filteredSprintsByCategory) {
                // Setting the new filtered data and drawing sprintMap again
                setData(filteredSprintsByCategory);
            }
        }
    }

    function showHideCategories(index: number) {
        filtersByCategory[index] = !filtersByCategory[index];
        updateSprintMap();
    }

    function hasMoreThanOneSegment() {
        return playingSegments && playingSegments.length > 1;
    }

    // Adding the sprints to get the total number for each period
    function totalSprintsForPeriods(sprints: number[]) {
        return sprints.reduce((a, b) => a + b, 0);
    }

    // returning number of sprints for periods
    function halvesDistance(index: number): number | null {
        const stats = match.stats;
        if (!stats) return null;
        let sprints: number[];

        switch (match.periodsNo) {
            case "1":
                sprints = stats.sprintsTotal;
                break;
            case "3":
                sprints = stats.sprintsByThirdsTotal[index];
                break;
            case "4":
                sprints = stats.sprintsByQuartersTotal[index];
                break;
            case "2":
            default:
                sprints = stats.sprintsByHalvesTotal[index];
        }

        return totalSprintsForPeriods(sprints);
    }

    return (
        <div ref={containerDiv} data-testid={testId}>
            {sprintMapAccess && (
                <>
                    <IonCol>
                        {/* Calculating total sprints*/}
                        <IonRow>
                            <IonCol size="6">
                                <IonText className="white-text-and-font">
                                    {t("stats.stats.Sprints.totalSprints")}
                                </IonText>
                            </IonCol>
                            <IonCol size="6" className="align-right">
                                {/* Calculating total sprints for all categories*/}
                                <IonText className="icon-date">
                                    {match.stats?.sprintsTotal.reduce(
                                        (total, currentValue) =>
                                            total + currentValue,
                                        0
                                    )}
                                </IonText>
                            </IonCol>
                        </IonRow>

                        {/* Calculating total for categories, low, medium and high*/}
                        {match.stats?.sprintsTotal.map((value, index) => {
                            return (
                                <IonRow key={index}>
                                    <IonCol size="6">
                                        <IonText className="matches-with-text match-details-desc">
                                            {sprintCategories[index]}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="6" className="align-right">
                                        <IonText className="icon-date">
                                            {value}
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            );
                        })}
                    </IonCol>

                    {/* Calculating the different sprints for the different periods*/}
                    {playingSegments &&
                        playingSegments.map((seg, index) => {
                            return (
                                <IonRow
                                    key={index}
                                    hidden={!hasMoreThanOneSegment}
                                >
                                    <IonCol size="6">
                                        <IonText className="white-text-and-font">
                                            {mapEventNameToDisplayName(seg, t)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="6" className="align-right">
                                        <IonText className="icon-date">
                                            {halvesDistance(index)?.toFixed(0)}
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            );
                        })}
                </>
            )}

            <StatsPeriodToggle
                playingSegments={playingSegments}
                periodsNo={periodsNo}
                onSegmentChange={setSelectedSegment}
            />
            <div className="map-container" data-testid="mapContainer">
                <div className="responsive svg-pitch">
                    <img src={pitchImage} alt="Pitch image" />
                </div>
                <canvas ref={canvasRef} className="canvas" />
                <IonCol className="sprint-key-container">
                    {sprintCategories.map((key, index) => {
                        return (
                            <span
                                className={`sprint-key ${
                                    filtersByCategory[index] ? "" : "hidden"
                                }`}
                                key={index}
                                data-testid={"sprintCategory" + index}
                                onClick={() => showHideCategories(index)}
                            >
                                <span
                                    style={{
                                        backgroundColor:
                                            sprintCategoryColors[index],
                                    }}
                                    className="ion-stop"
                                />
                                {key}
                            </span>
                        );
                    })}
                </IonCol>
            </div>
        </div>
    );
};
export default SprintMapComponent;
