import {
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonItem,
    IonRow,
    IonText,
    IonTextarea,
} from "@ionic/react";
import { FC, useState } from "react";
import { Fixture } from "./overview";
import { useTranslation } from "react-i18next";
import "./wellbeing.css";
import { updateMatch } from "../../../services/matchService";
import { useForm } from "react-hook-form";
import { ToastComponent } from "../../../components";
import ScrollableContentWithAds from "../../../components/ScrollableContentsWithAd";
import { updateUserActionsCheckList } from "../../../services/userActionsCheckListService";

const Wellbeing: FC<Fixture> = ({ match }) => {
    const { handleSubmit } = useForm({ reValidateMode: "onChange" });
    const { t } = useTranslation();
    const wellbeingScoreOptions = [
        "emoji-0",
        "emoji-1",
        "emoji-2",
        "emoji-3",
        "emoji-4",
    ];
    const [wellbeingScore, setWellbeingScore] = useState<number>(
        match?.wellbeing?.score || 2
    );
    const [wellbeingComments, setWellbeingComments] = useState<string>(
        match?.wellbeing?.comments || ""
    );
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastTitle, setToastTitle] = useState<string>("");

    // fix for deleting the match
    if (!match) {
        return <div />;
    }

    if (!match.matchFinished) {
        return (
            <div>
                <IonCard className="match-card-container">
                    <IonText
                        data-testid="wellbeingMatchNotFinished"
                        style={{ color: "white" }}
                    >
                        {t("fixture.FixtureWellbeing.notAvailable")}
                    </IonText>
                </IonCard>
            </div>
        );
    }

    function updateWellbeingScore(score: number) {
        setWellbeingScore(score);
    }

    async function saveWellbeing() {
        match.wellbeing = {
            score: wellbeingScore,
            comments: wellbeingComments,
        };
        try {
            await updateMatch(match);
            updateUserActionsCheckList({ wellbeingAdded: true });
            setToastTitle(t("fixture.FixtureWellbeing.wellbeingUpdates"));
            setShowToast(true);
        } catch (err) {
            setToastTitle(t("fixture.FixtureWellbeing.wellbeingFailedUpdate"));
            setShowToast(true);
            console.log("error trying to update earnings", err);
        }
    }

    return (
        <ScrollableContentWithAds>
            <IonContent data-testid="fixture-segment-wellbeing">
                <IonCard className="ion-card-transparent">
                    <form onSubmit={handleSubmit(saveWellbeing)}>
                        <div className="wellbeing-container">
                            <h2>
                                {t("fixture.FixtureWellbeing.howDidYouFeel")}
                            </h2>
                            <IonRow className="score-emojis">
                                {wellbeingScoreOptions.map((emoji, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() =>
                                                updateWellbeingScore(index)
                                            }
                                            className={`wellbeing-icons ${emoji} ${
                                                wellbeingScore !== index
                                                    ? "inactive"
                                                    : ""
                                            }`}
                                        />
                                    );
                                })}
                            </IonRow>
                            <IonCol>
                                <IonItem
                                    className="rounded-corners"
                                    lines="none"
                                >
                                    <IonTextarea
                                        value={wellbeingComments}
                                        className="notes-area"
                                        autoCapitalize="on"
                                        onIonChange={(e) =>
                                            e.detail.value! &&
                                            setWellbeingComments(
                                                e.detail.value!
                                            )
                                        }
                                        placeholder={t(
                                            "fixture.FixtureWellbeing.yourAnswer"
                                        )}
                                    />
                                </IonItem>
                            </IonCol>
                            <IonButton
                                data-testid="wellbeingSaveButton"
                                expand="block"
                                color="light"
                                type="submit"
                            >
                                {t("general.save")}
                            </IonButton>
                        </div>
                    </form>
                    <ToastComponent
                        isOpen={showToast}
                        position="bottom"
                        message={toastTitle}
                        onDismiss={() => setShowToast(false)}
                    />
                </IonCard>
            </IonContent>
        </ScrollableContentWithAds>
    );
};

export default Wellbeing;
