import { RefsixState } from "../models/refsixState";
import { USER_LOGGED_IN } from "../actions/types";
import { Middleware } from "redux";
import { startDatabase } from "../../services/matchService";

export const startDatabaseMiddleware: Middleware<{}, RefsixState> =
    (store) => (next) => (action) => {
        let shouldStart = false;
        let newLogin = false;

        // check if any matches need updating
        switch (action.type) {
            case USER_LOGGED_IN:
                const currentAuth = store.getState().auth.session;

                newLogin = action.session && !currentAuth;
                shouldStart = true;

                break;

            case "persist/REHYDRATE":
                newLogin = !!action.session;
                shouldStart = true;
                break;
        }

        next(action);

        if (shouldStart) {
            startDatabase(newLogin);
        }
    };
