import shortUUID from "short-uuid";
import { store } from "../../../redux/store";
import { FREE_USER_EXPIRES_IN_MS } from "../watchService";
import { MessageTypes } from "../messageTypesEnum";
import { WatchMessage } from "../watchInterface";
import { SubscriptionStatus } from "refsix-js-models";

export function getWatchSubscriptionStatus() {
    const state = store.getState();

    const expiry = state.subscriptions.expiryTs;

    return {
        expiry:
            expiry === Infinity || !expiry
                ? Date.now() + FREE_USER_EXPIRES_IN_MS
                : expiry,
        features: state.subscriptions.features || [],
        username: state.auth.session?.user_id,
    } as SubscriptionStatus;
}

export function prepareBaseResponse(
    response: any,
    messageId: MessageTypes,
    request: WatchMessage,
    token?: string
): WatchMessage {
    response.messageId = messageId;
    response._id = request._id || shortUUID().uuid();

    if (token) {
        response.token = token;
    }

    response.subscriptionStatus = getWatchSubscriptionStatus();

    return response;
}
