export enum PeriodNames {
    // 1 period
    firstPeriod = "1st Period",
    // 2 halves
    firstHalf = "First Half",
    secondHalf = "Second Half",
    // Thirds
    firstThird = "1st Third",
    secondThird = "2nd Third",
    thirdThird = "3rd Third",
    // Quarters
    firstQuarter = "1st Quarter",
    secondQuarter = "2nd Quarter",
    thirdQuarter = "3rd Quarter",
    fourthQuarter = "4th Quarter",
    // Half Time
    halfTime = "Half Time",
    // Intervals
    firstBreak = "1st Break",
    secondBreak = "2nd Break",
    thirdBreak = "3rd Break",
    // Extra Time
    et1 = "ET1",
    et2 = "ET2",
}
