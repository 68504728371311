import { FC } from "react";
import { IonCol, IonItem } from "@ionic/react";

interface ComparisonBarComponentProps {
    values: number[];
    colors: string[];
}

const ComparisonBarComponent: FC<ComparisonBarComponentProps> = ({
    values,
    colors,
}) => {
    return (
        <IonItem lines="none">
            <IonCol>
                <div className="comparison-bar">
                    {values.map((value, index) => {
                        return (
                            <div
                                key={index}
                                className="comparison-item"
                                style={{
                                    width: value * 100 + "%",
                                    backgroundColor: colors[index],
                                }}
                            />
                        );
                    })}
                </div>
            </IonCol>
        </IonItem>
    );
};

export default ComparisonBarComponent;
