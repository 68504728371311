import i18n from "react-i18next";
import { Player } from "refsix-js-models";

export const INITIAL_TEAM_OFFICIAL_NUMBER = 1000;
export const INITIAL_TEAM_OFFICIAL_SIZE = 4;

interface selectTeamOfficial {
    name: string;
    value: string;
}

export const getTeamOfficialsList = (
    t: i18n.TFunction
): selectTeamOfficial[] => {
    return [
        {
            name: t("fixture.FixtureTeam.manager"),
            value: "manager",
        },
        {
            name: t("fixture.FixtureTeam.assistantManager"),
            value: "assistantManager",
        },
        {
            name: t("fixture.FixtureTeam.coach"),
            value: "coach",
        },
        {
            name: t("fixture.FixtureTeam.goalKeeperCoach"),
            value: "goalKeeperCoach",
        },
        {
            name: t("fixture.FixtureTeam.fitnessCoach"),
            value: "fitnessCoach",
        },
        {
            name: t("fixture.FixtureTeam.physio"),
            value: "physio",
        },
        {
            name: t("fixture.FixtureTeam.doctor"),
            value: "doctor",
        },
        {
            name: t("fixture.FixtureTeam.kitManager"),
            value: "kitManager",
        },
        {
            name: t("fixture.FixtureTeam.assistantKitManager"),
            value: "assistantKitManager",
        },
        {
            name: t("fixture.FixtureTeam.teamSecretary"),
            value: "teamSecretary",
        },
        {
            name: t("fixture.FixtureTeam.analyst"),
            value: "analyst",
        },
        {
            name: t("fixture.FixtureTeam.other"),
            value: "other",
        },
    ];
};

export const getDefaultTeamOfficials = (t: i18n.TFunction) => {
    return [
        {
            name: t("fixture.FixtureTeam.manager"),
            value: "manager",
        },
        {
            name: t("fixture.FixtureTeam.assistantManager"),
            value: "assistantManager",
        },
        {
            name: t("fixture.FixtureTeam.coach"),
            value: "coach",
        },
        {
            name: t("fixture.FixtureTeam.physio"),
            value: "physio",
        },
    ];
};

export const getTeamOfficialName = (
    teamOfficial: Player,
    t: i18n.TFunction
): string => {
    return teamOfficial.name || t("fixture.FixtureTeam." + teamOfficial.role);
};

export const createDefaultTeamOfficials = (t: i18n.TFunction) => {
    const teamOfficials: Player[] = [];

    getDefaultTeamOfficials(t).map(function (initialTeamOfficial, index) {
        const teamOfficial = {
            captain: false,
            starting: false,
            number: INITIAL_TEAM_OFFICIAL_NUMBER + index,
            name: initialTeamOfficial.name,
            role: initialTeamOfficial.value,
            teamOfficial: true,
        };
        teamOfficials.push(teamOfficial);
    });

    return teamOfficials.slice();
};
