import { FC, useState } from "react";
import {
    IonItem,
    IonList,
    IonListHeader,
    IonPopover,
    useIonRouter,
} from "@ionic/react";
import { routes } from "../../../route/constants";
import { useTranslation } from "react-i18next";
import { IntegrationPartner, MatchPhone } from "refsix-js-models";
import { hasFeatureAccess } from "../../../services/subscriptionService";
import {
    checkForTeamSheet,
    markAsResult,
    recalculateStats,
    updateMatch,
} from "../../../services/matchService";
import { useProfile } from "../../../components/hooks";
import {
    getWatchMatchId,
    isMatchStarted,
} from "../../../services/phoneMatchWatchService";
import { values } from "lodash";
import {
    getMinuteStrings,
    getOnlyHalfEvents,
} from "../../../services/eventService";
import { isFeatureAvailableCurrentLanguage } from "../../../utils/FeatureToggle";
import { AlertComponent } from "../../../components";
import moment from "moment";

export function canDeleteIntegrationMatch(match: MatchPhone): boolean {
    if (
        !match.integration ||
        match.integration.partner === IntegrationPartner.knvb
    ) {
        return true;
    }
    return !match.matchFinished && moment(match.date).isBefore(moment());
}

interface OptionsPopoverProps {
    showPopover: any;
    match: MatchPhone;
    handleDuplicateMatch: () => void;
    handleCropMatchClicked: () => void;
    setShowPopover({}): void;

    setShowAlert(show: boolean): void;
}
const OptionsPopover: FC<OptionsPopoverProps> = ({
    setShowPopover,
    showPopover,
    match,
    handleDuplicateMatch,
    handleCropMatchClicked,
    setShowAlert,
}) => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const disableTeamsheet = match.integration
        ? hasFeatureAccess(match.integration.partner + ":disableTeamSheets")
        : false;
    const [showMarkAsResultAlert, setShowMarkAsResultAlert] = useState(false);

    const profile = useProfile();

    const showCropOption = () => {
        const isCropMatchAvailable =
            isFeatureAvailableCurrentLanguage("cropMatch");
        if (!isCropMatchAvailable) {
            return false;
        }

        if (
            !match.matchFinished ||
            match.playedPenalties ||
            match.matchAbandoned
        ) {
            return false;
        }

        const events = values((match && match.matchEvents!) || {});
        const halfEvents = getOnlyHalfEvents(events);
        const lastHalf = halfEvents[halfEvents.length - 1];

        if (!lastHalf || !lastHalf.playing) {
            return false;
        }

        const additionalTimeMinutes = getMinuteStrings(lastHalf, true);

        // there will always be one minute - the last minute of the half
        return additionalTimeMinutes.length > 1;
    };

    return (
        <>
            <IonPopover
                triggerAction="hover"
                isOpen={showPopover.open}
                event={showPopover.event}
                onDidDismiss={(e) => {
                    setShowPopover({ open: false });
                }}
                showBackdrop={false}
            >
                <IonList className="options-list">
                    <IonListHeader className="options-header">
                        {t("overview.options")}
                    </IonListHeader>
                    <IonItem
                        hidden={!match.matchFinished}
                        detail
                        className="options-item"
                        data-testid="addEvent"
                        onClick={() => {
                            setShowPopover({ open: false });
                            route.push(
                                `${routes.refsixResultsAddEvent}/${match._id}`
                            );
                        }}
                    >
                        {t("fixture.Fixture.addIncident")}
                    </IonItem>
                    <IonItem
                        detail
                        className="options-item"
                        data-testid="editMatch"
                        onClick={() => {
                            setShowPopover({ open: false });
                            route.push(`${routes.createMatch}/${match._id}`);
                        }}
                    >
                        {t("fixture.Fixture.editFixture")}
                    </IonItem>
                    <IonItem
                        hidden={disableTeamsheet}
                        onClick={() => {
                            setShowPopover({ open: false });
                            route.push(
                                `${routes.refsixAddEditTeamSheet}/${match._id}/${match.homeTeam}`
                            );
                        }}
                        detail
                        data-testid="teamSheetHome"
                        className="options-item"
                    >
                        {`${checkForTeamSheet(match.homeTeam, match, t)} ${t(
                            "fixture.Fixture.editHomeTeam"
                        )}`}
                    </IonItem>
                    <IonItem
                        hidden={disableTeamsheet}
                        onClick={() => {
                            setShowPopover({ open: false });
                            route.push(
                                `${routes.refsixAddEditTeamSheet}/${match._id}/${match.awayTeam}`
                            );
                        }}
                        detail
                        data-testid="teamSheetAway"
                        className="options-item"
                    >{`${checkForTeamSheet(match.awayTeam, match, t)} ${t(
                        "fixture.Fixture.editAwayTeam"
                    )}`}</IonItem>
                    {!match.matchFinished && (
                        <IonItem
                            data-testid="markAsResultButton"
                            detail
                            className="options-item"
                            onClick={() => {
                                setShowMarkAsResultAlert(true);
                                setShowPopover({ open: false });
                            }}
                        >
                            {t("fixture.Fixture.markAsResult")}
                        </IonItem>
                    )}
                    {!match.matchFinished && (
                        <IonItem
                            detail
                            className="options-item"
                            onClick={handleDuplicateMatch}
                        >
                            {t("general.duplicate")}
                        </IonItem>
                    )}
                    {showCropOption() && (
                        <IonItem
                            data-testid="crop-game-option"
                            detail
                            className="options-item"
                            onClick={handleCropMatchClicked}
                        >
                            {t("fixture.Fixture.cropMatch")}
                        </IonItem>
                    )}
                    {canDeleteIntegrationMatch(match) && (
                        <IonItem
                            detail
                            className="options-item"
                            onClick={() => setShowAlert(true)}
                            data-testid="deleteButton"
                        >
                            {t("general.delete")}
                        </IonItem>
                    )}

                    {!match.matchFinished && (
                        <IonItem
                            detail
                            lines={"none"}
                            className="options-item"
                            data-testid="startOnPhone"
                            onClick={() => {
                                setShowPopover({ open: false });
                                route.push(
                                    `${routes.startWatchPhone}/${match._id}`,
                                    "none",
                                    "replace"
                                );
                            }}
                        >
                            {getWatchMatchId() === match._id && isMatchStarted()
                                ? t("fixture.Fixture.resumeOnPhone")
                                : t("fixture.Fixture.startOnPhone")}
                        </IonItem>
                    )}
                </IonList>
            </IonPopover>
            <AlertComponent
                testId="mark-as-result-alert"
                showAlert={showMarkAsResultAlert}
                description={t(
                    "fixture.Fixture.confirmMarkAsResult.description"
                )}
                dismissAlert={() => setShowMarkAsResultAlert(false)}
                buttonAltText={t("general.cancel")}
                showAlternativeButton
                buttonDone={t("fixture.Fixture.markAsResult")}
                onDonePressed={() => {
                    const newMatch = markAsResult(match);
                    updateMatch(newMatch).then((matchPhone) => {
                        if (matchPhone && profile) {
                            recalculateStats(matchPhone, profile).then(() => {
                                if (
                                    match.matchEvents &&
                                    Object.values(match.matchEvents).length > 0
                                ) {
                                    route.push(
                                        `/overview/` + match._id,
                                        "none",
                                        "replace"
                                    );
                                } else {
                                    route.push(
                                        `${routes.addResultDetail}/${match._id}`,
                                        undefined,
                                        "replace"
                                    );
                                }
                            });
                        }
                    });
                }}
            />
        </>
    );
};

export default OptionsPopover;
