export class MatchOfficials {
    referee?: string;
    assistant1?: string;
    assistant2?: string;
    fourthOfficial?: string;
    observer?: string;

    constructor(
        referee?: string,
        assistant1?: string,
        assistant2?: string,
        fourthOfficial?: string,
        observer?: string
    ) {
        this.referee = referee;
        this.assistant1 = assistant1;
        this.assistant2 = assistant2;
        this.fourthOfficial = fourthOfficial;
        this.observer = observer;
    }

    static fromJSON(json: any) {
        return new MatchOfficials(
            json.referee,
            json.assistant1,
            json.assistant2,
            json.fourthOfficial,
            json.observer
        );
    }
}
