import {
    IonCard,
    IonCol,
    IonGrid,
    IonItem,
    IonRow,
    IonText,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./overview.css";
import "../../../css/general.css";
import {
    NextMatchInComponent,
    PartnerMatchIdComponent,
} from "../../../components";
import { MatchPhone } from "refsix-js-models";
import {
    getRefereeRoleClass,
    sortAllRefereeRoles,
} from "../../../components/MatchMiddleCardComponent";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import DateFormatFilterComponent from "../../../components/DateFormatFilterComponent";
import { MatchTags } from "../../../components/MatchTags";
import ScrollableContentWithAds from "../../../components/ScrollableContentsWithAd";

export interface Fixture {
    match: MatchPhone;
}

const Overview: FC<Fixture> = ({ match }) => {
    const { t } = useTranslation();

    const firstname = useSelector(
        (state: RootState) => state.currentUser?.profile?.firstName
    );
    const lastname = useSelector(
        (state: RootState) => state.currentUser?.profile?.lastName
    );

    const officialRoles = sortAllRefereeRoles(match, t);

    // fix for deleting the match
    if (!match) {
        return <div />;
    }

    return (
        <ScrollableContentWithAds>
            <div>
                {!match.matchFinished && <NextMatchInComponent match={match} />}
                <IonCard className="match-card-container">
                    <IonGrid>
                        <IonText
                            data-testid="match-details-title"
                            className="match-details-title"
                        >
                            {t("general.matchDetails")}
                        </IonText>
                        <IonItem
                            color="transparent"
                            className="ion-no-padding"
                            lines="full"
                        >
                            <IonCol size="3.5" className="col-no-padding">
                                <IonText className="match-details-text">
                                    {t("fixture.Fixture.kickOff")}
                                </IonText>
                            </IonCol>

                            <IonCol>
                                <IonText className="match-details-desc match-date">
                                    {match.dateLocalisedString &&
                                        match.dateLocalisedString}
                                    {!match.dateLocalisedString && (
                                        <>
                                            <DateFormatFilterComponent
                                                value={match.date}
                                                formatString={"ddd ll"}
                                                timezone={match.timezone}
                                            />

                                            <DateFormatFilterComponent
                                                value={match.date}
                                                formatString={"time"}
                                                timezone={match.timezone}
                                            />
                                        </>
                                    )}
                                </IonText>
                            </IonCol>
                        </IonItem>
                        <IonItem
                            color="transparent"
                            className="ion-no-padding"
                            lines="full"
                        >
                            <IonCol size="3.5" className="col-no-padding">
                                <IonText className="match-details-text">
                                    {t("fixture.FixtureNew.competition")}
                                </IonText>
                            </IonCol>

                            <IonCol>
                                <IonText className="match-details-desc competition-name">
                                    {match.competition}
                                </IonText>
                            </IonCol>
                        </IonItem>
                        <IonItem
                            color="transparent"
                            className="ion-no-padding"
                            lines="full"
                        >
                            <IonCol size="3.5" className="col-no-padding">
                                <IonText className="match-details-text">
                                    {t("fixture.FixtureNew.venue")}
                                </IonText>
                            </IonCol>

                            <IonCol>
                                <IonText className="match-details-desc venue-name">
                                    {match.venue}
                                </IonText>
                            </IonCol>
                        </IonItem>
                        <IonItem
                            color="transparent"
                            className="ion-no-padding"
                            lines="full"
                        >
                            <IonCol size="3.5" className="col-no-padding">
                                <IonText className="match-details-text">
                                    {t("general.keywords.title")}
                                </IonText>
                            </IonCol>

                            <IonCol>
                                <IonText className="match-details-desc venue-name">
                                    <MatchTags match={match} />
                                </IonText>
                            </IonCol>
                        </IonItem>
                        <IonItem
                            color="transparent"
                            className="ion-no-padding"
                            lines="full"
                            hidden={!match.integration}
                        >
                            <PartnerMatchIdComponent match={match} />
                        </IonItem>
                    </IonGrid>
                </IonCard>

                <IonCard className="match-card-container">
                    <IonGrid>
                        <IonText className="match-details-title">
                            {t("fixture.FixtureSummary.matchOfficials")}
                        </IonText>

                        {officialRoles.map((official, index) => {
                            // Hide officials if the name is undefined
                            if (!official.name) return "";

                            return (
                                <IonRow
                                    className="match-official-row"
                                    key={index}
                                >
                                    <div
                                        className={`role-icons ${getRefereeRoleClass(
                                            official.role
                                        )} small`}
                                    />
                                    <IonText
                                        className={`text-ellipsis ${official.role}-name`}
                                        style={{
                                            fontWeight:
                                                official.name ===
                                                    "currentUser" && "bold",
                                            marginLeft: 20,
                                        }}
                                    >
                                        {official.name === "currentUser"
                                            ? `${firstname} ${lastname}`
                                            : official.name}
                                    </IonText>
                                </IonRow>
                            );
                        })}
                    </IonGrid>
                </IonCard>
            </div>
        </ScrollableContentWithAds>
    );
};

export default Overview;
