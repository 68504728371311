import { useEffect, useRef, useState } from "react";
import { AlertComponent, HeaderComponent } from "../../components";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { PhoneWatch } from "../../components/PhoneWatch";
import { useParams } from "react-router";
import { useHasFeatureAccess, useSettings } from "../../components/hooks";
import { buildMatchForWatch } from "refsix-core";
import { createDefaultTeamOfficials } from "../../services/TeamOfficialsService";
import { MatchData } from "refsix-js-models";
import { useTranslation } from "react-i18next";
import { isMatchStarted } from "../../services/phoneMatchWatchService";
import * as Sentry from "@sentry/react";
import { useMatch } from "../../components/hooks/match";

const PhoneMatchWatchPage = () => {
    const { t } = useTranslation();
    const pageRef = useRef();
    const { id } = useParams<{ id: string }>();
    const route = useIonRouter();

    const match = useMatch(id);

    const settings = useSettings();
    const hasTimersFeature = useHasFeatureAccess("timers");

    const [matchData, setMatchData] = useState<MatchData>();

    // Alert user if changes have been made to the form when clicking back button
    const [showBackAlert, setShowBackAlert] = useState<boolean>(false);

    const handBackClicked = () => {
        if (isMatchStarted()) {
            setShowBackAlert(true);
        } else {
            route.push(`match/${id}/overview`, "none", "replace");
        }
    };

    useEffect(() => {
        if (match && settings) {
            try {
                const matchData = buildMatchForWatch(
                    match,
                    settings,
                    hasTimersFeature,
                    true,
                    createDefaultTeamOfficials()
                );
                console.log("Match data", matchData);
                setMatchData(matchData);
            } catch (e: any) {
                console.log(
                    "PhoneWatch - couldn't buildMatchForWatch.",
                    e.message,
                    e.stack
                );
                Sentry.captureException(e);
            }
        }
    }, [match, settings, hasTimersFeature]);
    return (
        <IonPage ref={pageRef}>
            <HeaderComponent
                customStartButton
                customStartButtonOnClick={() => handBackClicked()}
            />
            <IonContent style={{ "--background": "black" }}>
                <div>
                    {matchData && (
                        <PhoneWatch
                            match={matchData}
                            onFinished={(match) => {
                                route.goBack();
                            }}
                        ></PhoneWatch>
                    )}
                </div>
            </IonContent>
            <AlertComponent
                testId="phone-watch-back-alert"
                onDonePressed={() => {
                    setShowBackAlert(false);
                    route.push(`match/${id}/match-log`, "none", "replace");
                }}
                showAlert={showBackAlert}
                title={t("fixture.FixtureWatch.goBack.title")}
                description={
                    "<p>" +
                    t("fixture.FixtureWatch.goBack.desc1") +
                    "</p><p>" +
                    t("fixture.FixtureWatch.goBack.desc2") +
                    "</p>"
                }
                buttonDone={t("help.OnboardingVideo.controls.ok")}
                showAlternativeButton
                buttonAltText={t("general.cancel")}
                onButtonAltClicked={() => setShowBackAlert(false)}
            />
        </IonPage>
    );
};

export default PhoneMatchWatchPage;
