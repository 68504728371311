import { FC } from "react";
import { useController } from "react-hook-form";
import { IonSelect } from "@ionic/react";
import "./r6ionic/R6IonSelect.css";

type R6IonSelectType = {
    name: string;
    className: string;
    testId?: string;
    disabled?: boolean;
};
const R6SelectorComponent: FC<R6IonSelectType> = ({
    name,
    className,
    testId,
    disabled,
    children,
}) => {
    const {
        field: { onChange, ...rest },
    } = useController({ name });
    return (
        <IonSelect
            className={className}
            onIonChange={onChange}
            data-testid={testId ?? name}
            interface="popover"
            disabled={disabled}
            {...rest}
        >
            {children}
        </IonSelect>
    );
};

export default R6SelectorComponent;
