import { IonButton, IonCard, IonText, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { routes } from "../route/constants";
import { FC } from "react";
import { useProfile } from "./hooks";

interface NoMatchProps {
    notMatchesTitle: string;
    page: "fixtures" | "results";
    selectedDate?: string;
}

const NoMatchesComponent: FC<NoMatchProps> = ({
    notMatchesTitle,
    page,
    selectedDate,
}) => {
    const { t } = useTranslation();
    const router = useIonRouter();

    const userProfile = useProfile();
    const isDutchUser = userProfile?.country?.toUpperCase() === "NL";

    return (
        <IonCard className="no-upcoming-matches">
            <IonText data-testid="noEndedFixturesTxt">
                <IonText>{notMatchesTitle}</IonText>
            </IonText>
            <IonButton
                data-testid="addMatch"
                expand="block"
                className="button-width"
                onClick={() =>
                    page === "fixtures"
                        ? router.push(routes.createMatch)
                        : router.push(routes.addResult)
                }
            >
                {page === "fixtures"
                    ? t("general.addMatch")
                    : t("general.addResult")}
            </IonButton>
            {isDutchUser && page === "fixtures" && (
                <IonButton
                    data-testid="addKnvbMatch"
                    expand="block"
                    className="button-width"
                    style={{
                        "--background": "var(--knvb-orange)",
                        "--background-hover":
                            "color-mix(in srgb,var(--knvb-orange),#000 10%)",
                        "--background-activated":
                            "color-mix(in srgb,var(--knvb-orange),#000 25%)",
                        "--background-focused":
                            "color-mix(in srgb,var(--knvb-orange),#000 20%)",
                        "--color": "white",
                    }}
                    onClick={() => router.push(routes.addKnvbMatch)}
                >
                    {/*<IonIcon icon={knvb}></IonIcon>*/}
                    KNVB: {t("general.addMatch")}&nbsp;
                    <sup style={{ color: "var(--ion-color-primary)" }}>
                        BETA
                    </sup>
                </IonButton>
            )}
        </IonCard>
    );
};

export default NoMatchesComponent;
