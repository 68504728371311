// a react ionic page component to display the developer tools

import { IonCard } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getDatabaseService } from "../../services/matchService";
import { MatchPhone } from "refsix-js-models";
import { map } from "lodash";

type PouchDebugMatchRevsProps = {
    matchId: string;
};

const PouchDebugMatchRevs: React.FC<PouchDebugMatchRevsProps> = ({
    matchId,
}) => {
    const localDB = getDatabaseService()?.getLocalDatabase();

    const [matchRevisions, setMatchRevisions] = useState<MatchPhone[]>([]);

    const [match, setMatch] = useState<MatchPhone | null>(null);

    useEffect(() => {
        if (localDB && matchId !== "") {
            localDB
                .get(matchId, {
                    conflicts: true,
                    revs: true,
                    revs_info: true,
                })
                .then((doc: any) => {
                    setMatch(doc);
                    console.log("doc", doc);

                    if (doc._revisions && doc._revs_info.length) {
                        return localDB.get(matchId, {
                            open_revs: map(doc._revs_info, "rev"),
                        });
                    }
                    console.log("no revisions");
                    return Promise.resolve([]);
                })
                .then((docs: any[]) => {
                    const okDocs = map(docs, "ok");
                    console.log("revisions", okDocs);
                    setMatchRevisions(okDocs);
                })
                .catch((err: any) => {
                    console.log("err", err);
                });
        }
    }, [matchId]);

    return (
        <div>
            {matchRevisions.map((revision) => (
                <IonCard key={revision._rev}>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            {revision && (
                                <>
                                    <tr>
                                        <td>id</td>
                                        <td>{revision._id}</td>
                                    </tr>
                                    <tr>
                                        <td>rev</td>
                                        <td>{revision._rev}</td>
                                    </tr>
                                    <tr>
                                        <td>Match Finished</td>
                                        <td>
                                            {!!revision.matchFinished
                                                ? "Yes"
                                                : "No"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Stats Processed</td>
                                        <td>
                                            {!!revision.stats ? "Yes" : "No"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Stats Version</td>
                                        <td>{revision.stats?.version}</td>
                                    </tr>
                                    <tr>
                                        <td>Has Tracking</td>
                                        <td>
                                            {revision.hasTracking
                                                ? "Yes"
                                                : "No"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>GPS Processed</td>
                                        <td>
                                            {" "}
                                            {revision.stats?.gpsProcessed === 1
                                                ? "Yes"
                                                : "No"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Has Heart Rate</td>
                                        <td>
                                            {revision.hasHeartRate
                                                ? "Yes"
                                                : "No"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>HR Processed</td>
                                        <td>
                                            {" "}
                                            {revision.stats
                                                ?.heartRateProcessed === 1
                                                ? "Yes"
                                                : "No"}
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </IonCard>
            ))}
        </div>
    );
};

export default PouchDebugMatchRevs;
