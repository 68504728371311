import { Templates } from "refsix-js-models";
import { isEmpty } from "lodash";

/**
 * Returns a merged version, prioritising all `newTemplates` properties,
 * but uniquely concatinates all templates from both objects
 */
export function resolveTemplatesConflict(
    newTemplates: Templates,
    dbTemplates: Templates
): Templates {
    const mergedTemplates = [
        ...newTemplates.templates,
        ...dbTemplates.templates,
    ];

    // filter out any duplicates based on IDs
    const ids = mergedTemplates.map((template) => template._id);
    const uniqMergedTemplates = mergedTemplates.filter(
        ({ _id }, index) => !ids.includes(_id, index + 1)
    );

    const defaultExists = uniqMergedTemplates.some(
        (template) => template._id === newTemplates.defaultTemplate
    );

    let defaultId: string;

    if (defaultExists) {
        defaultId = newTemplates.defaultTemplate;
    } else if (!defaultExists && !isEmpty(uniqMergedTemplates)) {
        // if there's no default and templates exist, just pick the first one
        defaultId = uniqMergedTemplates[0]._id as string; // all saved templates should have IDs
    } else {
        // there are no templates so set to empty string
        defaultId = "";
    }

    return {
        ...newTemplates,
        templates: uniqMergedTemplates,
        defaultTemplate: defaultId,
    };
}
