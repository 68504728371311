import { MatchPhone, SelectedTeam } from "refsix-js-models";
import { FC, useEffect, useState } from "react";

interface CardIndicatorInput {
    match: MatchPhone;
    side: SelectedTeam.home | SelectedTeam.away;
}

export const CardIndicator: FC<CardIndicatorInput> = ({ match, side }) => {
    const [yellowCount, setYellowCount] = useState<number>(0);
    const [redCount, setRedCount] = useState<number>(0);

    useEffect(() => {
        if (side === SelectedTeam.home) {
            setYellowCount(match.stats?.yellowCardHomeTotal || 0);
            setRedCount(match.stats?.redCardHomeTotal || 0);
        } else {
            setYellowCount(match.stats?.yellowCardAwayTotal || 0);
            setRedCount(match.stats?.redCardAwayTotal || 0);
        }
    }, [match, side]);

    return (
        <div
            data-testid="card-indicator"
            className="card-indicator"
            style={{ display: "flex" }}
        >
            {!!yellowCount &&
                [...Array(yellowCount)].map((e, i) => (
                    <div
                        className={`cards yellow yellow-card-${side}-total`}
                        key={i}
                    />
                ))}

            {!!redCount &&
                [...Array(redCount)].map((e, i) => (
                    <div
                        className={`cards red red-card-${side}-total`}
                        key={i}
                    />
                ))}
        </div>
    );
};
