import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { IonBadge } from "@ionic/react";
import { FC } from "react";

export type MessageCountProps = {
    slot: "start" | "end" | undefined;
};

export const MessageCount: FC<MessageCountProps> = ({ slot }) => {
    const messages = useSelector((state: RootState) => state.messages.messages);

    const count = messages.filter((message) => !message.read).length;

    return (
        <IonBadge slot={slot} hidden={!count}>
            {count}
        </IonBadge>
    );
};
