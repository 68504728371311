import { PersistGate } from "redux-persist/integration/react";
import { persistor, RootState } from "./redux/store";
import Route from "./route";
import { StatusBar, Style } from "@capacitor/status-bar";
import builtInExperiments from "./experiements.json";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { Router } from "react-router-dom";
import history from "./route/history";

import { isPlatform, setupIonicReact } from "@ionic/react";
import { useEffect } from "react";
import moment from "moment";
// Bundle all locales into the app
import "moment/min/locales";
import { Keyboard } from "@capacitor/keyboard";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { AppUpdateProvider } from "./components/hooks/appVersionHooks";
import ErrorToaster from "./components/ErrorToaster";

import "./App.css";
import { OneSignalInit } from "./services/OneSignal";
import { setActiveExperiments } from "./redux/actions/experiementsActions";
import { migrateData } from "./services/store/migration";
import { isAndroid, isIos } from "./services/platformDetection";
import { Capacitor } from "@capacitor/core";

setupIonicReact({
    innerHTMLTemplatesEnabled: true,
});
OneSignalInit();

function App() {
    // Set up the user's current locale so dates change to the correct format for the user
    moment.locale(navigator.language);

    const dispatch = useDispatch();
    dispatch(setActiveExperiments(builtInExperiments));

    const session = useSelector((state: RootState) => state.auth.session);

    useEffect(() => {
        if (isIos() || isAndroid()) {
            migrateData();
        }
    }, []);

    useEffect(() => {
        // Set the user up in Sentry
        if (session?.user_id) {
            Sentry.setUser({
                id: session?.user_id,
                username: session?.user_id,
            });
            console.log("Sentry user set to: " + session?.user_id);
        } else {
            Sentry.setUser(null);
        }
    }, [session?.user_id]);

    useEffect(() => {
        // Show accessoryBar above keyboard to access done button and hide keyboard on physical devices
        enableAccessoryBar().catch(() => {
            // error trying to enable accessoryBar
        });

        if (!isPlatform("mobileweb")) {
            setStatusBarStyleLight();
        }
    }, []);

    async function enableAccessoryBar() {
        if (Capacitor.isPluginAvailable("Keyboard")) {
            await Keyboard.setAccessoryBarVisible({ isVisible: true });
        }
    }

    // Setting the status bar color to white is the mode is dark mode.
    function setStatusBarStyleLight() {
        StatusBar.setStyle({ style: Style.Dark }).catch(() => {
            // will error on browser which is fine
        });
    }

    return (
        <PersistGate persistor={persistor} loading={null}>
            <AppUpdateProvider>
                <ErrorToaster />
                <Router history={history}>
                    <Route />
                </Router>
            </AppUpdateProvider>
        </PersistGate>
    );
}

export default App;
