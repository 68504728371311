import { IonButton, IonIcon } from "@ionic/react";
import React, { FC } from "react";
import { pick } from "lodash/fp";
import {
    SignInWithApple,
    SignInWithAppleOptions,
    SignInWithAppleResponse,
} from "@capacitor-community/apple-sign-in";
import { SocialLoginProps, SocialOauthRequestType } from "./Google";
import { socialLogin } from "../../../services/authService";
import { useAppleLoginCache } from "../../../components/hooks";
import { useDispatch } from "react-redux";
import { setAppleLoginCacheValue } from "../../../redux/actions/settings";
import { useTranslation } from "react-i18next";
import { logoApple } from "ionicons/icons";
import { pushErrorMessage } from "../../../redux/actions/errorMessagesActions";

let options: SignInWithAppleOptions = {
    clientId: "com.refsix.app",
    redirectURI: "https://r6-tim-auth.ngrok.app/apple-test",
    scopes: "email name",
    state: "INITIAL_STATE",
    nonce: "nonce",
};
const toAppleLoginCache = pick(["email", "firstName", "lastName", "user"]);

export const Apple: FC<SocialLoginProps> = ({
    login,
    requestType,
    showSpinner = false,
}) => {
    const appleLoginCache = useAppleLoginCache();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleAppleLogin = async () => {
        try {
            const appleUserResp: SignInWithAppleResponse =
                await SignInWithApple.authorize(options);
            let appleUser = appleUserResp.response;
            // if we have the email in the response, cache it
            if (appleUserResp.response.email) {
                const cache = toAppleLoginCache(appleUser);
                dispatch(setAppleLoginCacheValue(appleUser.user!, cache));
            } else {
                // if we don't have the email, we need to get it from the cache
                if (appleUserResp.response.user) {
                    const cache = appleLoginCache[appleUserResp.response.user];
                    if (cache) {
                        appleUser = {
                            ...appleUser,
                            ...cache,
                        };
                    }
                }
            }
            const auth = {
                id_token: appleUser.identityToken,
                code: appleUser.authorizationCode,
            };
            const profile = {
                id: appleUser.user,
                email: appleUser.email!,
                firstName: appleUser.givenName!,
                lastName: appleUser.familyName!,
            };
            await login(profile, auth, "apple");
        } catch (e) {
            // dispatch error error toaster
            dispatch(
                pushErrorMessage({
                    messageKey: "general.errorTryAgain",
                    color: "danger",
                })
            );
        }
    };
    return (
        <IonButton color="login" expand="block" onClick={handleAppleLogin}>
            <IonIcon icon={logoApple} slot="start" />
            {requestType === "Register"
                ? t("register.registerOrLogin.continueWithApple")
                : t("register.registerOrLogin.signInWithApple")}
        </IonButton>
    );
};
