import { useTranslation } from "react-i18next";
import { FC } from "react";
import { trackEvent } from "../../services/analytics/analyticsService";
import calendarSync from "../../assets/images/pro-banners/calendar-sync.gif";
import positioning from "../../assets/images/pro-banners/positioning.gif";
import reduceAdmin from "../../assets/images/pro-banners/reduce-admin.gif";
import removeAds from "../../assets/images/pro-banners/remove-ads.gif";
import videoReplay from "../../assets/images/pro-banners/video-replay.gif";
import "./UpgradeBanner.css";
import { useIonRouter } from "@ionic/react";
import { routes } from "../../route/constants";
import { UpgradeBannerText } from "./UpgradeBannerText";
import { getLanguageCode } from "../../utils/localeUtils";
import { useShowBannerAd } from "../hooks/adHooks";
import { isUserPlusEligible } from "../../services/subscriptionService";

export const UpgradeBanner: FC<any> = () => {
    const { t } = useTranslation();

    const router = useIonRouter();

    const showBannerAd = useShowBannerAd();

    const bannerImageObjects = [
        {
            name: "calendarSync",
            image: calendarSync,
            altText: t("upgrade.banner.calendarSync"),
        },
        {
            name: "positioning",
            image: positioning,
            altText: t("upgrade.proFeatureMarketing.heatmap"),
        },
        {
            name: "reduceAdmin",
            image: reduceAdmin,
            altText: t("upgrade.banner.reduceAdmin"),
        },
        {
            name: "removeAds",
            image: removeAds,
            altText: t("upgrade.banner.removeAds"),
        },
        {
            name: "videoReplay",
            image: videoReplay,
            altText: t("upgrade.proFeatureMarketing.videoReplay"),
        },
    ];

    const getRandomInt = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    };

    const selectedBanner =
        bannerImageObjects[getRandomInt(0, bannerImageObjects.length)];
    const bannerClicked = () => {
        const trackingProps = {
            selectedBannerImage: selectedBanner.name,
            pageUrl: window.location.pathname,
            language: getLanguageCode(),
            bannerType: "image",
        };

        trackEvent("UpgradeBanner", trackingProps);
        router.push(routes.upgrade + "/upgrade");
    };

    if (!showBannerAd) return null;

    if (getLanguageCode() !== "en") {
        return <UpgradeBannerText />;
    }

    return (
        <>
            <div className="upgrade-banner">
                <button
                    onClick={() => {
                        bannerClicked();
                    }}
                    title={t("menu.upgrade")}
                >
                    <img
                        src={selectedBanner.image}
                        alt={selectedBanner.altText}
                    />
                </button>
            </div>
        </>
    );
};
