import {
    EventName,
    GoalEvent,
    HalfEvent,
    IncidentEvent,
    MatchEvent,
    Player,
    ReasonType,
    SubstitutionEvent,
    Team,
} from "refsix-js-models";
import { ComputedHalfInfo } from "../models/ComputedHalfInfo";
import moment from "moment";

export function getOnlyHalfEvents(events: MatchEvent[]): HalfEvent[] {
    return (
        events.filter(
            ({ eventName }) => eventName === EventName.half
        ) as HalfEvent[]
    ).sort((a, b) => a.index - b.index);
}

export function filterHalfObjectIncludingPenalties(
    events: MatchEvent[]
): HalfEvent[] {
    const halfEventsSorted = (
        events.filter(
            ({ eventName }) => eventName === EventName.half
        ) as HalfEvent[]
    ).sort((a, b) => a.index - b.index);

    const penalties = events.filter(
        ({ eventName }) => eventName === EventName.penalties
    ) as HalfEvent[];

    return halfEventsSorted.concat(penalties);
}

/**
 * @param event The half event to get the minutes for
 * @param additionalTimeOnly If true, only returns the additional time minutes
 * @returns {string[]} Array of strings representing the minutes that half
 */
export function getMinuteStrings(
    event: HalfEvent,
    additionalTimeOnly?: boolean
): string[] {
    // if the match has been abandoned then the half event may not have a length at least on Garmin
    if (!event.length) {
        return [];
    }

    const lengthInMins = Math.ceil(event.length / 1000 / 60);
    const additionalTimeMinutes: string[] = [];

    const minutes = new Array(lengthInMins)
        .fill(0)
        .map((_, i) => `${i + event.minuteOfPlay + 1}`);

    if (event.endMinute) {
        const lastMinute = minutes[minutes.length - 1];
        const additionalMins =
            event.endMinute - lengthInMins > 0
                ? event.endMinute - lengthInMins
                : 0;
        const additionalTime =
            additionalMins === 0
                ? []
                : new Array(additionalMins)
                      .fill(0)
                      .map((_, i) => `${lastMinute}+${i + 1}`);

        if (additionalTimeOnly) {
            additionalTimeMinutes.push(lastMinute);

            for (let i = 1; i < additionalMins; i++) {
                additionalTimeMinutes.push(`${lastMinute}+${i}`);
            }
            return additionalTimeMinutes;
        }

        return minutes.concat(additionalTime);
    }

    return minutes;
}

export function calculateMinuteAndAdditional(
    event: MatchEvent,
    currentHalfInfo: ComputedHalfInfo
): { minute: number; additionMinute: number } {
    const generatedMinuteAndAdditionalMinute = {
        minute: event.minuteOfPlay,
        additionMinute: 0,
    };

    if (event.minuteOfPlay > currentHalfInfo.actualEndMinute) {
        generatedMinuteAndAdditionalMinute.minute = Number(
            currentHalfInfo.actualEndMinute.toFixed(0)
        );
        generatedMinuteAndAdditionalMinute.additionMinute =
            event.minuteOfPlay -
            currentHalfInfo.startMinute -
            currentHalfInfo.actualDurationMinutes;
    }
    return generatedMinuteAndAdditionalMinute;
}

export function adjustHalfAdditionalTimeMins(
    halfEvent: HalfEvent,
    additionalTimeMins: number
): HalfEvent {
    const halfStartTs = halfEvent.timestamp;
    const endMinute = halfEvent.length / 1000 / 60 + additionalTimeMins;

    const momentTimestamp = moment(halfStartTs);
    momentTimestamp.add(endMinute, "minutes");
    const endTime = momentTimestamp.valueOf();

    // we need to crop the injury time if it is longer than the additional time e.g. if they left the watch running in injury time at the end of the match
    let injuryTimeAccumulated = halfEvent.injuryTimeAccumulated;
    let injuryTimeInInjuryTimeAccumulated =
        halfEvent.injuryTimeInInjuryTimeAccumulated;

    if (
        injuryTimeAccumulated + injuryTimeInInjuryTimeAccumulated / 60 >
        additionalTimeMins
    ) {
        // adjust
        injuryTimeAccumulated = additionalTimeMins * 60;
        // reset to zero as we don't know the proportion
        injuryTimeInInjuryTimeAccumulated = 0;
    }

    return {
        ...halfEvent,
        endTime: endTime,
        endMinute: endMinute, // starts at 0 for each half
        injuryTimeAccumulated: injuryTimeAccumulated,
        injuryTimeInInjuryTimeAccumulated: injuryTimeInInjuryTimeAccumulated,
    };
}

const translations = {
    1: "model.WatchFixture.firstPeriodDisplayName",
    2: "model.WatchFixture.firstHalfDisplayName",
    3: "model.WatchFixture.secondHalfDisplayName",
    4: "model.WatchFixture.halfTimeDisplayName",
    5: "model.WatchFixture.firstThirdDisplayName",
    6: "model.WatchFixture.secondThirdDisplayName",
    7: "model.WatchFixture.thirdThirdDisplayName",
    8: "model.WatchFixture.firstQuarterDisplayName",
    9: "model.WatchFixture.secondQuarterDisplayName",
    10: "model.WatchFixture.thirdQuarterDisplayName",
    11: "model.WatchFixture.fourthQuarterDisplayName",
    12: "model.WatchFixture.firstBreakDisplayName",
    13: "model.WatchFixture.secondBreakDisplayName",
    14: "model.WatchFixture.thirdBreakDisplayName",
    15: "results.heatmap.extraTime1",
    16: "results.heatmap.extraTime2",
    17: "fixture.FixtureSummary.penalties",
};

export function getHalfEventTranslation(
    matchPeriodsNo: number,
    event: HalfEvent
) {
    if (event.eventName === EventName.penalties) {
        return translations[17];
    }

    switch (matchPeriodsNo) {
        case 1: {
            switch (event.index) {
                case 0:
                    return translations[1];
                case 1:
                    return translations[15];
                case 2:
                    return translations[16];
                case 3:
                    return translations[17];
            }
            break;
        }
        case 2: {
            switch (event.index) {
                case 0:
                    return translations[2];
                case 1:
                    return translations[4];
                case 2:
                    return translations[3];
                case 3:
                    return translations[15];
                case 4:
                    return translations[16];
                case 5:
                    return translations[17];
            }
            break;
        }
        case 3: {
            switch (event.index) {
                case 0:
                    return translations[5];
                case 1:
                    return translations[12];
                case 2:
                    return translations[6];
                case 3:
                    return translations[13];
                case 4:
                    return translations[7];
                case 5:
                    return translations[15];
                case 6:
                    return translations[16];
                case 7:
                    return translations[17];
            }
            break;
        }
        case 4: {
            switch (event.index) {
                case 0:
                    return translations[8];
                case 1:
                    return translations[12];
                case 2:
                    return translations[9];
                case 3:
                    return translations[13];
                case 4:
                    return translations[10];
                case 5:
                    return translations[14];
                case 6:
                    return translations[15];
                case 7:
                    return translations[16];
                case 8:
                    return translations[17];
            }
            break;
        }
    }
    return translations[1];
}

export type RedCardIncidentEvent = IncidentEvent;
export type YellowCardIncidentEvent = IncidentEvent;
export const isGoalEvent = (
    event: GoalEvent | MatchEvent
): event is GoalEvent => {
    return event.eventName === "Goal";
};
export const isIncidentEvent = (
    event: IncidentEvent | MatchEvent
): event is IncidentEvent => {
    return event.eventName === "Incident";
};
export const isYellowCardIncidentEvent = (
    event: YellowCardIncidentEvent | MatchEvent
): event is YellowCardIncidentEvent => {
    return isIncidentEvent(event) && event.card == ReasonType.yellow;
};
export const isRedCardIncidentEvent = (
    event: RedCardIncidentEvent | MatchEvent
): event is RedCardIncidentEvent => {
    return isIncidentEvent(event) && event.card == ReasonType.red;
};
export const isSubstitutionEvent = (
    event: SubstitutionEvent | MatchEvent
): event is SubstitutionEvent => {
    return event.eventName === "Substitution";
};
export const isEventWithPlayer = (
    event: MatchEvent | (MatchEvent & { player: Player })
): event is MatchEvent & { player: Player } => {
    return (
        isGoalEvent(event) ||
        isIncidentEvent(event) ||
        isSubstitutionEvent(event)
    );
};
// export const
export const isEventWithTeam = (
    event: MatchEvent | (MatchEvent & { team: Team })
): event is MatchEvent & { team: Team } => {
    return (
        isGoalEvent(event) ||
        isIncidentEvent(event) ||
        isSubstitutionEvent(event)
    );
};
