export function getNationalFA(): NationalFA {
    return {
        GB: {
            ENG: "England",
            NIR: "Northen Ireland",
            SCO: "Scotland",
            WAL: "Wales",
        },
    };
}

export interface NationalFA {
    [countryCode: string]: NestedNationalFA;
}

export interface NestedNationalFA {
    [countryCode: string]: string;
}
