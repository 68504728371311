import { IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import "../login/login.css";
import { useFormContext } from "react-hook-form";
import "../../css/general.css";
import { checkForEmail, checkIfValidEmail } from "../../services/authService";
import { eye, eyeOff } from "ionicons/icons";
import "./register.css";
import "./registerStepOne.css";
import { errorProps } from "../../services/errorService";
import { R6IonInput } from "../../components/r6ionic/R6IonInput";
import { emailPattern } from "../../constants/emailPattern";

const useEmailDomainValidator = () => {
    const { t } = useTranslation();
    const cache = useRef<{ [key: string]: string | boolean | undefined }>({});

    const checkEmailDomain = async (
        value: string
    ): Promise<string | boolean | undefined> => {
        if (cache.current[value] !== undefined) {
            return cache.current[value];
        }

        try {
            const response = await checkIfValidEmail(value);
            if (response) {
                // Check if email address is already in use
                await checkForEmail(value);
            }
            cache.current[value] = true;
            return true;
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                const message = t("register.register.incorrectEmail");
                cache.current[value] = message;
                return message;
            } else if (error.response && error.response.status === 409) {
                const message = t("utils.ErrorMessages.existingEmail");
                cache.current[value] = message;
                return message;
            }
        }
    };

    return checkEmailDomain;
};

const RegisterStepOne: FC = () => {
    const [emailFocus, setEmailFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const {
        formState: { errors },
    } = useFormContext();

    const checkEmailDomain = useEmailDomainValidator();

    return (
        <>
            <div style={{ marginBottom: 10 }}>
                <IonLabel style={{ color: "white", fontSize: 14 }}>
                    {t("register.register.email")}
                </IonLabel>
            </div>
            <IonItem
                lines="none"
                {...errorProps(
                    "registerEmail",
                    errors,
                    true,
                    emailFocus ? "focus" : "",
                    true
                )}
                data-testid="register-step-1"
            >
                <R6IonInput
                    testId="registerEmail"
                    name="registerEmail"
                    className="placeholder-input"
                    rules={{
                        required: t("utils.ErrorMessages.required").toString(),
                        validate: {
                            checkEmailDomain: checkEmailDomain,
                        },
                        pattern: {
                            value: emailPattern,
                            message: t("utils.ErrorMessages.email"),
                        },
                    }}
                    onFocus={() => {
                        setPasswordFocus(false);
                        setEmailFocus(true);
                    }}
                    autocapitalize="off"
                    inputMode="email"
                    placeholder={t("register.register.email")}
                />
            </IonItem>

            {errors && errors.registerEmail && (
                <IonItem lines="none" color="transparent">
                    <IonText className="error-message">
                        {errors["registerEmail"].message}
                    </IonText>
                </IonItem>
            )}

            <br />

            <div style={{ marginBottom: 10 }}>
                <IonLabel style={{ color: "white", fontSize: 14 }}>
                    {t("login.login.password")}
                </IonLabel>
            </div>
            <IonItem
                lines="none"
                {...errorProps(
                    "registerPassword",
                    errors,
                    true,
                    passwordFocus ? "focus" : "",
                    true
                )}
            >
                <R6IonInput
                    testId="registerPassword"
                    name="registerPassword"
                    type={showPassword ? "text" : "password"}
                    className="placeholder-input"
                    rules={{
                        required: t("utils.ErrorMessages.required").toString(),

                        minLength: {
                            value: 6,
                            message: t("utils.ErrorMessages.short"),
                        },
                        maxLength: {
                            value: 16,
                            message: t("utils.ErrorMessages.long"),
                        },
                    }}
                    onFocus={() => {
                        setEmailFocus(false);
                        setPasswordFocus(true);
                    }}
                    autocapitalize="off"
                    placeholder={t("login.login.password")}
                />
                <IonIcon
                    icon={showPassword ? eye : eyeOff}
                    slot="end"
                    size="medium"
                    onClick={() => setShowPassword(!showPassword)}
                />
            </IonItem>

            {errors && errors.registerPassword && (
                <IonItem lines="none" color="transparent">
                    <IonText className="error-message">
                        {errors["registerPassword"].message}
                    </IonText>
                </IonItem>
            )}
        </>
    );
};

export default RegisterStepOne;
