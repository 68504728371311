import { FC, useEffect, useRef, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonModal,
    IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import pushImage from "../assets/images/notifications/push-permission-prompt.gif";
import {
    getPushNotificationStatus,
    requestPushConsent,
    shouldShowPushConsentModal,
} from "../services/OneSignal";
import { useDispatch, useSelector } from "react-redux";
import { setPushConsentPromptLastShown } from "../redux/actions/appStatus";
import moment from "moment";
import { trackEvent } from "../services/analytics/analyticsService";
import { updateUserActionsCheckList } from "../services/userActionsCheckListService";

export type PushConsentModalProps = {
    forceShow?: boolean;
};

const PushConsentModal: FC<PushConsentModalProps> = ({ forceShow }) => {
    const { t } = useTranslation();
    const modal = useRef<HTMLIonModalElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const pushConsentPromptLastShown = useSelector(
        (state: any) => state.appStatus.pushConsentPromptLastShown
    );

    const setLastShown = () => {
        dispatch(setPushConsentPromptLastShown(Date.now()));
    };

    const hasNotRecentlyBeenShown = () => {
        if (forceShow) {
            return true;
        }
        if (!pushConsentPromptLastShown) {
            return true;
        }
        return (
            pushConsentPromptLastShown &&
            // use moment to check if the date is older than 1 week
            moment(pushConsentPromptLastShown).isBefore(
                moment().subtract(1, "week")
            )
        );
    };

    useEffect(() => {
        shouldShowPushConsentModal()
            .then((shouldShow) => {
                if (shouldShow && hasNotRecentlyBeenShown()) {
                    setIsOpen(true);
                }
            })
            .catch((err) => {
                console.log("Push notifications not supported", err);
            });

        getPushNotificationStatus()
            .then((status) => {
                console.log(`Push notification status ${status}`);
            })
            .catch((err) => {});
    }, []);

    return (
        <IonModal
            isOpen={isOpen}
            onDidDismiss={() => {}}
            breakpoints={[1]}
            initialBreakpoint={1}
            showBackdrop
            data-testid="push-consent-modal"
            ref={modal}
        >
            <IonCard className="ion-card-transparent">
                <IonCardHeader>
                    <IonCardTitle>
                        {t("notifications.pushNotifications")}
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <img src={pushImage} />
                    <IonText
                        style={{
                            color: "white",
                            fontSize: 20,
                        }}
                    >
                        {t("notifications.pushConsentMessage")}
                    </IonText>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 20,
                        }}
                    >
                        <IonButton
                            onClick={async () => {
                                trackEvent("PushNotificationConsentYes", {});
                                updateUserActionsCheckList({
                                    acceptedPushNotifications: true,
                                });
                                await requestPushConsent();
                                modal.current?.dismiss();
                            }}
                        >
                            {t("general.allow")}
                        </IonButton>

                        <IonButton
                            style={{ color: "white" }}
                            fill="clear"
                            onClick={() => {
                                setLastShown();
                                trackEvent(
                                    "PushNotificationConsentDismissed",
                                    {}
                                );
                                modal.current?.dismiss();
                            }}
                        >
                            {t("general.maybeLater")}
                        </IonButton>
                    </div>
                </IonCardContent>
            </IonCard>
        </IonModal>
    );
};

export default PushConsentModal;
