import {FC, useEffect} from "react";
import {App, URLOpenListenerEvent} from "@capacitor/app";
import {useHistory} from "react-router";
import {handleRefreshIntegrationMatches} from "../services/integrations/integrationSyncUpdates";
import {appLaunchUrlToPath} from "../utils/AppUrlHelper";
import {useUserLoggedIn} from "./hooks";
import {getUTMParamsFromURL} from "../services/analytics/utmAttribution";
import {mpRegisterSuperProperties} from "../services/analytics/mixpanelService";
import {trackEvent} from "../services/analytics/analyticsService";
import {routes} from "../route/constants";

/** Deep linking code for Android and iOS */
const AppUrlListener: FC<any> = () => {
    let history = useHistory();
    const loggedIn = useUserLoggedIn();

    useEffect(() => {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            if (!loggedIn) {
                // TODO with current routing deep links to logged in content will lead to black
                //  screen if user isn't logged in
                return;
            }
            const path = appLaunchUrlToPath(event.url);

            if (path) {
                const utmParams = getUTMParamsFromURL(event.url);
                mpRegisterSuperProperties(utmParams);
                trackEvent("DeepLinkAppOpen", {
                    urlPath: path,
                    ...utmParams,
                });

                // check for integration match updates in case the push is about a match
                handleRefreshIntegrationMatches().catch((e) => {
                    console.log("Error refreshing integration matches", e);
                });

                if (path === "" || path === "/") {
                    // the fallback route doesn't seem to work by default
                    history.push(routes.refsixFixtures);
                } else {
                    history.push(path);
                }
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
};

export default AppUrlListener;
