import { SET_USERNAME, SHOW_INTEGRATION_POPUP } from "../actions/types";
import { User } from "../models/refsixState";

const initialState = new User();

function userReducer(state: User = initialState, action: any) {
    switch (action.type) {
        case SET_USERNAME:
            return {
                ...state,
                usernameOrEmail: action.usernameOrEmail,
            };
        case SHOW_INTEGRATION_POPUP:
            return {
                ...state,
                integrationPopup: action.show,
            };
        default:
            return state;
    }
}

export default userReducer;
