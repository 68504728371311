import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonPage,
    useIonRouter,
} from "@ionic/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../components";
import { OnBoardingHeadingComponent } from "./onBoardingHeadingComponent";
import { TimerOptionsComponent } from "../../components/TimerOptionsComponent";
import { useDispatch } from "react-redux";
import { useUsername } from "../../components/hooks";
import { setShowOnBoardingFlow } from "../../redux/actions/appStatus";
import { routes } from "../../route/constants";

export const OnBoardingTimerSettings: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [isValidLayout, setIsValidLayout] = useState<boolean>(true);
    const username = useUsername();
    const dispatch = useDispatch();

    const handleNextClicked = () => {
        if (!isValidLayout) {
            // setTimerOptionAlert(true);
            return;
        }
        dispatch(setShowOnBoardingFlow(false));
        return route.push(
            routes.refsixOnBoardingSetupComplete,
            "root",
            "replace"
        );
    };

    return (
        <IonPage>
            <HeaderComponent
                titleTestId="timer-settings-header"
                title={t("settings.TimerSettings.title")}
            />
            <IonContent>
                <OnBoardingHeadingComponent progressValue={0.5} />
                <IonCard style={{ marginTop: 0 }} color="transparent">
                    <TimerOptionsComponent
                        validationListener={(valid) => setIsValidLayout(valid)}
                    />
                </IonCard>
            </IonContent>
            <IonFooter>
                {!isValidLayout && (
                    <p className="error-message">
                        {t("settings.TimerSettings.failure.template")}
                    </p>
                )}
                <IonButton
                    data-testid="timer-settings-next-button"
                    expand="block"
                    disabled={!isValidLayout}
                    onClick={() => handleNextClicked()}
                >
                    {t("general.next")}
                </IonButton>
            </IonFooter>
        </IonPage>
    );
};
