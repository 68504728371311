import { Middleware } from "redux";
import { RefsixState } from "../models/refsixState";
import { SET_USER_ACTIONS_CHECKLIST, SHOW_ONBOARDING_FLOW } from "../actions/types";
import { updateUserActionsCheckListInDB } from "../../services/userActionsCheckListService";
import _ from "lodash";
import { UserActionsCheckList } from "refsix-js-models";
import { trackUserActionsCheckListUpdated } from "../../services/analytics/analyticsService";
import { setUserActionsCheckList } from "../actions/userActionsCheckList";

export const userActionsCheckListMiddleware: Middleware<{}, RefsixState> =
    (store) => (next) => (action) => {
        switch (action.type) {
            case SHOW_ONBOARDING_FLOW:
                if (!action.value) {
                    store.dispatch(
                        setUserActionsCheckList({
                            onboardingCompleted: true,
                        })
                    );
                }
                break;
            case SET_USER_ACTIONS_CHECKLIST:
                const oldUserActionsCheckList =
                    store.getState().userActionsCheckList;
                const newUserActionsCheckList = action.userActionsCheckList;

                // Send the updated values to analytics platforms
                let propertiesChanged = {};
                if (oldUserActionsCheckList) {
                    const oldUserActionsCheckListObject =
                        oldUserActionsCheckList as UserActionsCheckList;
                    // use loadash to filter out the properties that did not change
                    propertiesChanged = _.omitBy(
                        newUserActionsCheckList,
                        (value: any, key) => {
                            return _.isEqual(
                                value,
                                oldUserActionsCheckListObject[key]
                            );
                        }
                    );
                } else {
                    propertiesChanged = newUserActionsCheckList;
                }

                if (Object.keys(propertiesChanged).length > 0) {
                    // Update the userActionsCheckList in the database
                    updateUserActionsCheckListInDB(
                        newUserActionsCheckList
                    ).catch((err) => {
                        console.log(
                            "Error updating user actions checklist in DB: ",
                            err
                        );
                    });

                    // only track if there are changes
                    trackUserActionsCheckListUpdated(propertiesChanged);
                }

                break;
        }

        next(action);
    };
