import { SET_SUBSCRIPTIONS } from "./types";
import { SubscriptionState } from "../models/subscriptionState";

export type setSubscriptionsAction = {
    type: string;
    subscriptions: SubscriptionState;
};

export const setSubscriptions = (
    subscriptions: SubscriptionState
): setSubscriptionsAction => ({
    type: SET_SUBSCRIPTIONS,
    subscriptions,
});
