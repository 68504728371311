import { IonCol, IonItem, IonLabel, IonText } from "@ionic/react";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export interface GoProBenefitsProps {
    longTranslations?: boolean;
}

export const GoProBenefits: FC<GoProBenefitsProps> = ({ longTranslations }) => {
    const { t } = useTranslation();

    // Shorter translations
    const short = {
        heatmap: t("upgrade.goProModal.heatmaps"),
        timers: t("upgrade.goProModal.advancedTimers"),
        fitness: t("upgrade.goProModal.performanceData"),
        trends: t("upgrade.proFeatureMarketing.trends"),
        videoReplay: t("upgrade.goProModal.videoAnalysis"),
        ads: t("ads.removeAds"),
    };

    // Longer more marketingy translations
    const long = {
        heatmap: t("upgrade.proFeatureMarketing.heatmap"),
        timers: t("upgrade.proFeatureMarketing.timers"),
        fitness: t("upgrade.proFeatureMarketing.fitness"),
        trends: t("upgrade.proFeatureMarketing.trends"),
        videoReplay: t("upgrade.proFeatureMarketing.videoReplay"),
        ads: t("ads.removeAds"),
    };

    const [translations, setTranslations] = useState(
        longTranslations ? long : short
    );

    return (
        <IonItem
            color="transparent"
            lines="none"
            className="pro-features"
            style={{ marginTop: -10 }}
        >
            <IonCol>
                <IonLabel
                    className="extra-line-height"
                    style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        marginBottom: 10,
                    }}
                >
                    {t("upgrade.goProModal.title2")}
                </IonLabel>
                <IonLabel className="extra-line-height">
                    🔥 {translations.heatmap}
                </IonLabel>
                <IonLabel className="extra-line-height">
                    ⌚ {translations.timers}
                </IonLabel>
                <IonLabel className="extra-line-height">
                    🏃 {translations.fitness}
                </IonLabel>
                <IonLabel className="extra-line-height">
                    📈 {translations.trends}
                </IonLabel>
                <IonLabel className="extra-line-height">
                    📺 {translations.videoReplay}
                </IonLabel>
                <IonLabel className="extra-line-height">
                    🪧 {translations.ads}
                </IonLabel>
            </IonCol>
        </IonItem>
    );
};
