import { RefresherEventDetail } from "@ionic/react";
import { hasFeatureAccess } from "../subscriptionService";
import {
    hasKnvbFixtures,
    syncAssignrMatches,
    syncDbuMatches,
    syncKNVBMatches,
    syncRefAssistMatches,
    syncStackSportsMatches,
} from "../integrationService";
import {
    getAndStoreDownloadedClosedAndOpenDates,
    getAndStoreRefAssistNotifications,
} from "./refassist/refAssistService";
import { store } from "../../redux/store";

export async function handleRefreshIntegrationMatches(
    event?: CustomEvent<RefresherEventDetail>
) {
    const errors = [];

    try {
        if (hasFeatureAccess("dbuMember")) {
            await syncDbuMatches();
        }
    } catch (e: any) {
        errors.push("DBU");
    }
    try {
        if (hasFeatureAccess("refAssistMember")) {
            await Promise.all([
                syncRefAssistMatches(),
                getAndStoreRefAssistNotifications(),
                getAndStoreDownloadedClosedAndOpenDates(),
            ]);
        }
    } catch (e: any) {
        errors.push("RefAssist");
    }
    try {
        if (hasFeatureAccess("stackSportsMember")) {
            await syncStackSportsMatches();
        }
    } catch (e: any) {
        errors.push("StackSports");
    }
    try {
        if (hasFeatureAccess("assignrMember")) {
            await syncAssignrMatches();
        }
    } catch (e: any) {
        console.log("Assignr sync failed", e);
        //errors.push("Assignr");
    }
    try {
        const matches = store.getState().matches.matches;
        if (hasKnvbFixtures(matches)) {
            await syncKNVBMatches();
        }
    } catch (e: any) {
        console.log("KNVB sync failed", e);
        //errors.push("Assignr");
    }
    event && event.detail.complete();
    return errors;
}
