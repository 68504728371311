import semver from "semver/preload";

export function sameMajorMinorVersion(
    watchAppVersion: string | undefined,
    phoneAppVersion: string | undefined
): boolean {
    if (!watchAppVersion || !phoneAppVersion) {
        return false;
    }
    // use semver to check that the major and minor versions are the same for version1 and version2
    return (
        semver.major(watchAppVersion) === semver.major(phoneAppVersion) &&
        semver.minor(watchAppVersion) === semver.minor(phoneAppVersion)
    );
}

export function watchAppHasAcceptableVersion(
    watchAppVersion: string | undefined,
    phoneAppVersion: string | undefined
): boolean {
    if (!watchAppVersion || !phoneAppVersion) {
        // in this case we don't know the version of the watch app, so we assume it is fine
        return true;
    }
    // use semver to check that the major and minor versions are the same for version1 and version2
    return (
        sameMajorMinorVersion(watchAppVersion, phoneAppVersion) ||
        semver.gte(watchAppVersion, phoneAppVersion)
    );
}
