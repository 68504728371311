import { FitnessDataType } from "refsix-js-models";
import { removeDatabase } from "./database/fitnessDatabases.service";
import { destroyDatabaseService } from "./matchService";
import request from "./request";
import { store } from "../redux/store";
import { userLogout } from "../redux/actions/auth";
import { UserProfile } from "../models/userProfile";
import * as Sentry from "@sentry/react";
import { isAndroid, isIos } from "./platformDetection";
import OneSignal from "onesignal-cordova-plugin";
import {
    SocialLogin,
} from "@capgo/capacitor-social-login";

export async function refreshSessionRequest(data: object = {}) {
    return request.post("/auth/refresh", data);
}
export async function loginRequest(data: object = {}) {
    return request.post("/auth/login", data);
}

export async function login(username: string, password: string) {
    if (!username || !password) {
        return Promise.reject();
    } else {
        try {
            const data = {
                username: username,
                password: password,
            };
            return await loginRequest(data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export async function refreshSession() {
    return await refreshSessionRequest();
}

export async function logout() {
    try {
        store.dispatch(userLogout());
        await removeDatabase(FitnessDataType.GPS);
        await removeDatabase(FitnessDataType.HeartRate);
        await destroyDatabaseService();
        await SocialLogin.logout({
            provider: "google"
        })

    } catch (err) {
        Sentry.captureMessage(`Error logging out: ${JSON.stringify(err)}`);
        console.log("Error logging out: ", err);
    }
}

export async function logoutAndRedirect() {
    try {
        await logout();
        // if the user is no longer identifiable in your app (i.e. they logged out), the logout method should be called
        if (isIos() || isAndroid()) {
            OneSignal.logout();
        }

        window.location.href = "/";
    } catch (err) {
        Sentry.captureMessage(`Error logging out: ${JSON.stringify(err)}`);
        console.log("Error logging out: ", err);
    }
}

export async function checkForUsername(username: string) {
    if (!username || username === "") {
        return Promise.reject(false);
    }
    return await request.get<string>(
        "/auth/validate-username/" + encodeURIComponent(username)
    );
}

export async function checkIfValidEmail(email: string) {
    return await request.get(
        "/register/check-email-domain/" + encodeURIComponent(email)
    );
}

export async function checkForEmail(email: string) {
    return await request.get(
        "/auth/validate-email/" + encodeURIComponent(email)
    );
}

export async function registerUser(user: UserProfile) {
    if (!user) {
        return Promise.reject(false);
    }
    try {
        return await request.post<UserProfile>("/register-in-app", {
            profile: user,
        });
    } catch (err) {
        console.log("Request for register user", err);
        throw err;
    }
}
type UserRegisterErrorResponse = {
    success: boolean;
    message: string;
    type: string;
    data?: any;
};

export type Provider = "google" | "apple";

// A wrapper around social login that will handle the different cases of social login
// 1. If the initial login fails:
// 1.1. check if the email is already in use:
// 1.1.1 provider == [local] : prompt for password
// 1.1.2 provider == [google | apple] : if the provider is different ....
export const socialLoginWrapper = (profile: any, auth: any, provider: Provider) => {
    return socialLogin(profile, auth, provider).catch(async (err) => {
        console.log("ERROR: Request for register user", err);
        if (err && err.error && err.error.message === "Email already in use") {
            // Get the providers. as long as we only have 1 provider, and it is local, then we can prompt for password and link accounts
        }
        throw err;
    });
}
export async function socialLink(profile: any, auth: any, provider: Provider) {
    try {
        return await request.post<any>(`/link/${provider}/token`, {
            profile: profile,
            auth,
            provider,
        });
    } catch (err) {
        console.log("Request for link user", err);
        throw err;
    }
}
export async function socialLogin(profile: any, auth: any, provider: Provider) {

        return await request.post<UserProfile | UserRegisterErrorResponse>(
            "/social-auth",
            {
                profile: profile,
                auth,
                provider,
            }
        );
}
export async function providers(profile: any) {
    try {
        return await request.post("/register/providers", {
            profile: profile,
        });
    } catch (err) {
        console.log("Request for providers failed", err);
        throw err;
    }
}
export async function forgotPassword(email: string) {
    try {
        return await request.post("/auth/forgot-password", { email: email });
    } catch (err) {
        console.log("Request for forgot password failed", err);
        throw err;
    }
}
