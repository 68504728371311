import {
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonText,
    IonThumbnail,
    useIonRouter,
    useIonViewDidEnter,
} from "@ionic/react";
import React, { FC } from "react";
import { HeaderComponent } from "../../../components";
import { useTranslation } from "react-i18next";
import { routes } from "../../../route/constants";
import { useStoreRefreshSubscriptions } from "../../../components/hooks/refreshStoreSubscriptions";
import ProBadge from "../../../assets/images/pro-badge.png";
import PlusBadge from "../../../assets/images/upgrade-plus-and-pro/plus-badge.png";
import { SubscriptionItems } from "refsix-js-models";
import moment from "moment";
import { isAndroid, isIos } from "../../../services/platformDetection";
import { trackProTeaser } from "../../../services/analytics/analyticsService";
import { URLS } from "../../../constants/urls";
import { useIsBasic, useSubscription } from "../../../components/hooks";
import {
    isUserPlusEligible,
    refreshSubscriptions,
} from "../../../services/subscriptionService";
import { isSubscriptionPaid } from "refsix-core";

export const Subscriptions: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const subscriptionState = useSubscription();

    const isBasic = useIsBasic();

    const refresh = useStoreRefreshSubscriptions();

    useIonViewDidEnter(() => {
        refreshSubscriptions().catch((error: any) => {
            console.log(error);
        });
    });

    const renderCancelSubscriptionButton = (provider: string) => {
        if (subscriptionState && isSubscriptionPaid(provider)) {
            return (
                <IonCol size="12" className="ion-col-no-padding">
                    <IonButton
                        data-testid="cancel-subscription-button"
                        fill="outline"
                        style={{ marginTop: 15 }}
                        onClick={() =>
                            route.push(
                                routes.refsixSubscriptionCancel +
                                    "/" +
                                    provider,
                                "forward"
                            )
                        }
                    >
                        {t("myaccount.cancelSubscription")}
                    </IonButton>
                </IonCol>
            );
        }
    };

    const renderSubscription = (
        subscription: SubscriptionItems,
        index: number
    ) => {
        return (
            <IonItem
                data-testid={`subscription-item${index}`}
                key={index}
                lines={
                    subscriptionState &&
                    subscriptionState.subscriptions.length > 1
                        ? "full"
                        : "none"
                }
            >
                {subscription.planFeatures === "pro" && (
                    <IonThumbnail slot="start">
                        <img style={{ width: "49px" }} src={ProBadge} />
                    </IonThumbnail>
                )}
                {subscription.planFeatures === "basic" && (
                    <IonThumbnail slot="start">
                        <img style={{ width: "49px" }} src={PlusBadge} />
                    </IonThumbnail>
                )}
                <IonRow style={{ padding: 5 }}>
                    <IonCol size="12" className="ion-col-no-padding">
                        <IonText
                            className="text-medium"
                            data-testid={`sub${index}-plan-description`}
                            style={{
                                color: "white",
                                fontWeight: "bold",
                                whiteSpace: "initial",
                            }}
                        >
                            {subscription.planDescription}
                        </IonText>
                    </IonCol>

                    <IonCol size="12" className="ion-col-no-padding">
                        <IonText
                            className="text-medium"
                            style={{ textTransform: "uppercase" }}
                        >
                            {subscription.provider}
                        </IonText>
                    </IonCol>

                    <IonCol size="12" className="ion-col-no-padding">
                        <IonText>
                            {t("billing.expiry")}:{" "}
                            {moment(subscription.validUntil).format("LLL")}
                        </IonText>
                    </IonCol>

                    {renderCancelSubscriptionButton(subscription.provider)}
                </IonRow>
            </IonItem>
        );
    };

    return (
        <IonPage>
            <HeaderComponent
                title={t("myaccount.subscriptionsHeader")}
                showBackButton
                defaultHref={routes.refsixMoreProfile}
                titleTestId={"more-subscriptions-page-header"}
            />
            <IonContent>
                <IonCard>
                    <IonList lines="inset">
                        {subscriptionState &&
                            subscriptionState.subscriptions.length > 0 &&
                            subscriptionState.subscriptions.map(
                                renderSubscription
                            )}

                        {!subscriptionState ||
                            (!subscriptionState.subscriptions.length && (
                                <>
                                    <IonItem data-testid="free-user-sub">
                                        <IonText>{t("myaccount.free")}</IonText>
                                    </IonItem>
                                    <IonItem>
                                        <IonButton
                                            data-testid="subs-free-user-upgrade-btn"
                                            color="primary"
                                            // button
                                            onClick={() => {
                                                if (isIos() || isAndroid()) {
                                                    if (
                                                        isUserPlusEligible() &&
                                                        !isBasic
                                                    ) {
                                                        route.push(
                                                            routes.upgrade +
                                                                "/plus"
                                                        );
                                                        trackProTeaser(
                                                            "subscriptions",
                                                            "basic"
                                                        );
                                                    } else if (
                                                        !isUserPlusEligible() ||
                                                        isBasic
                                                    ) {
                                                        route.push(
                                                            routes.upgrade +
                                                                "/pro"
                                                        );
                                                        trackProTeaser(
                                                            "subscriptions",
                                                            "pro"
                                                        );
                                                    }
                                                } else {
                                                    window.open(
                                                        URLS.REFSIX_ACCOUNT +
                                                            "?utm_source=refsix&utm_medium=app&utm_campaign=in-app-mobile-web&utm_id=in-app-mobile-web",
                                                        "_system"
                                                    );
                                                }
                                            }}
                                        >
                                            <IonLabel>
                                                {t("menu.upgrade")}
                                            </IonLabel>
                                        </IonButton>
                                    </IonItem>
                                </>
                            ))}
                    </IonList>
                </IonCard>

                <IonCard>
                    <IonButton
                        expand="block"
                        onClick={refresh}
                        data-testid="restore-purchases-btn"
                    >
                        {t("myaccount.refreshButton")}
                    </IonButton>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
