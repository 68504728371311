export function DecimalFilter(): Function {
    return function (input?: number, decimalPlaces?: number): number {
        if (typeof input !== "number" || isNaN(input)) {
            return 0;
        }
        const dp =
            typeof decimalPlaces === "number" && decimalPlaces >= 0
                ? decimalPlaces
                : 2;
        return parseFloat(input.toFixed(decimalPlaces || dp));
    };
}
