import {
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import {
    Controller,
    FormProvider,
    useForm,
    useFormContext,
} from "react-hook-form";
import {
    AvailabilityType,
    ClosedOpenDate,
    PeriodMap,
    PeriodWithKey,
    RefAssistCreateAvailability,
    REFSIX_CLOSED_DATE_PREFIX,
    REFSIX_OPEN_DATE_PREFIX,
} from "refsix-js-models";
import { HeaderComponent } from "../../components";
import { useTranslation } from "react-i18next";
import { R6IonInput } from "../../components/r6ionic/R6IonInput";
import shortUUID from "short-uuid";
import {
    createClosedDates,
    createOpenDates,
    getAndStoreDownloadedClosedAndOpenDates,
    getTimeZoneId,
} from "../../services/integrations/refassist/refAssistService";

export const periodMap: PeriodMap = {
    allDay: { start: "00:00", end: "23:59", fullDay: true },
    morning: { start: "00:00", end: "12:00" },
    afternoon: { start: "12:00", end: "17:00" },
    evening: { start: "17:00", end: "23:59" },
    morningAndAfternoon: { start: "00:00", end: "17:00" },
    afternoonAndEvening: { start: "12:00", end: "23:59" },
};

const periods: PeriodWithKey[] = Object.keys(periodMap).map((name) => ({
    ...periodMap[name],
    name,
}));

const reasons = [
    "Holiday",
    "Personal",
    "Work Commitment",
    "Football - Hearing",
    "Football - Meeting",
    "Football - Unspecified",
    "Football - Supply League",
    "Football - County Cup",
];

export const AddAvailabilityWrapper: FC<{
    openClosedDateModal: boolean;
    dismiss: () => void;
    availabilityType: AvailabilityType;
    selectedDate?: string;
    closedDate?: ClosedOpenDate;
}> = ({
    openClosedDateModal,
    dismiss,
    availabilityType,
    selectedDate,
    closedDate,
}) => {
    const periods: PeriodWithKey[] = Object.keys(periodMap).map((name) => ({
        ...periodMap[name],
        name,
    }));

    const formMethods = useForm<ClosedOpenDate>({
        mode: "all",
        defaultValues: {
            startDate: selectedDate || moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            period: periods[0],
            reason: reasons[0],
        },
        values: closedDate,
    });

    return (
        <FormProvider {...formMethods}>
            <AddAvailabilityDate
                open={openClosedDateModal}
                onDismiss={dismiss}
                availabilityType={availabilityType}
                closedDate={closedDate}
            />
        </FormProvider>
    );
};

const AddAvailabilityDate: FC<{
    open: boolean;
    availabilityType: AvailabilityType;
    onDismiss: () => void;
    closedDate?: ClosedOpenDate;
}> = ({ open, availabilityType, onDismiss, closedDate }) => {
    const { handleSubmit, setValue, watch } = useFormContext<ClosedOpenDate>();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const watchStartDate = watch("startDate");
    const EXTERNAL_MATCH = "ExternalMatch";

    useEffect(() => {
        setValue("endDate", watchStartDate);
    }, [watchStartDate, setValue]);

    const handleSubmitClosedOrOpenDate = async (
        closedOrOpenDate: ClosedOpenDate
    ) => {
        setLoading(true);
        const timeZoneId: number = (await getTimeZoneId()).data;
        const period = closedOrOpenDate.period;
        let start = moment(closedOrOpenDate.startDate);
        let end = moment(closedOrOpenDate.endDate);
        const totalDays = Math.round(
            moment.duration(end.diff(start)).asDays() + 1
        );

        const closedDateOutput: RefAssistCreateAvailability[] = [];
        const openDateOutput: RefAssistCreateAvailability[] = [];
        const isClosedDate = availabilityType === AvailabilityType.closedDate;

        for (let i = 0; i < totalDays; i++) {
            const date = moment(start).add(i, "days");

            const idPrefix = isClosedDate
                ? REFSIX_CLOSED_DATE_PREFIX
                : REFSIX_OPEN_DATE_PREFIX;

            const availability = {
                partnerId: idPrefix + shortUUID.generate(),
                timeZoneId: timeZoneId, // TODO detect
                day: date.format("D/MM/YYYY"),
                start: !period.fullDay ? period.start : undefined,
                end: !period.fullDay ? period.end : undefined,
                isFullDay: !!period.fullDay,
                comments:
                    closedDate && closedDate.period.name === EXTERNAL_MATCH
                        ? t("availability.closedDate.comments")
                        : isClosedDate
                        ? closedOrOpenDate.reason || ""
                        : undefined,
                holidayType: closedDate
                    ? closedDate.period.name
                    : isClosedDate
                    ? t("availability.refAssistReasons.Personal")
                    : undefined,
            };

            if (isClosedDate) {
                closedDateOutput.push(availability);
            } else {
                openDateOutput.push(availability);
            }
        }

        try {
            if (isClosedDate) {
                await createClosedDates(closedDateOutput);
            } else {
                await createOpenDates(openDateOutput);
            }
            await getAndStoreDownloadedClosedAndOpenDates();
            setLoading(false);
            onDismiss();
        } catch (e: any) {
            console.log("Error trying to create an open or closed date", e);
            setLoading(false);
        }
    };

    return (
        <IonPage>
            <HeaderComponent />
            <form onSubmit={handleSubmit(handleSubmitClosedOrOpenDate)}>
                <IonModal isOpen={open} showBackdrop initialBreakpoint={1}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton onClick={() => onDismiss()}>
                                    {t("general.cancel")}
                                </IonButton>
                            </IonButtons>
                            <IonTitle>
                                {availabilityType ===
                                    AvailabilityType.closedDate &&
                                    t("availability.addClosedDate")}
                                {availabilityType ===
                                    AvailabilityType.openDate &&
                                    t("availability.addOpenDate")}
                            </IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        {loading && (
                            <IonLoading isOpen={loading} showBackdrop />
                        )}
                        <IonCard>
                            <IonItem lines="full" className="item-title">
                                <IonLabel>
                                    {t("availability.closedDate.details")}
                                </IonLabel>
                            </IonItem>
                            <IonItem className="item-title">
                                <R6IonInput
                                    testId="closed-date-from-date"
                                    type="date"
                                    name="startDate"
                                    rules={{
                                        min: moment().format("YYYY-MM-DD"),
                                    }}
                                    min={moment().format("YYYY-MM-DD")}
                                    label={t("stats.stats.Filters.from")}
                                />
                            </IonItem>

                            <IonItem className="item-title">
                                <R6IonInput
                                    testId="closed-date-end-date"
                                    type="date"
                                    name="endDate"
                                    rules={{ min: watchStartDate }}
                                    label={t("stats.stats.Filters.to")}
                                />
                            </IonItem>

                            <IonItem className="item-title">
                                <IonLabel className="inner-label">
                                    {availabilityType ===
                                    AvailabilityType.closedDate
                                        ? t(
                                              "availability.closedDate.selectPeriod"
                                          )
                                        : t(
                                              "availability.closedDate.selectOpenPeriod"
                                          )}
                                </IonLabel>
                                <Controller
                                    name={"period.name"}
                                    render={({
                                        field: { onChange, ...rest },
                                    }) => {
                                        return (
                                            <IonSelect
                                                interface="popover"
                                                className="select-text"
                                                onIonChange={(e) => {
                                                    const periodName =
                                                        e.detail.value;
                                                    const period = periods.find(
                                                        (period) =>
                                                            period.name ===
                                                            periodName
                                                    );
                                                    if (period) {
                                                        setValue(
                                                            "period",
                                                            period
                                                        );
                                                    }
                                                }}
                                                {...rest}
                                            >
                                                {periods.map(
                                                    (period, index) => (
                                                        <IonSelectOption
                                                            key={index}
                                                            value={period.name}
                                                        >
                                                            {t(
                                                                `availability.periods.${period.name}`
                                                            )}
                                                        </IonSelectOption>
                                                    )
                                                )}
                                            </IonSelect>
                                        );
                                    }}
                                />
                            </IonItem>
                            <IonItem
                                className="item-title"
                                lines="none"
                                hidden={
                                    availabilityType ===
                                        AvailabilityType.openDate ||
                                    (closedDate &&
                                        closedDate.period.name ===
                                            EXTERNAL_MATCH)
                                }
                            >
                                <IonLabel className="inner-label">
                                    {t("availability.closedDate.selectReason")}
                                </IonLabel>
                                <Controller
                                    name={"reason"}
                                    render={({
                                        field: { onChange, ...rest },
                                    }) => {
                                        return (
                                            <IonSelect
                                                interface="popover"
                                                className="select-text"
                                                {...rest}
                                            >
                                                {reasons.map(
                                                    (reason, index) => (
                                                        <IonSelectOption
                                                            key={index}
                                                            value={reason}
                                                        >
                                                            {reason}
                                                        </IonSelectOption>
                                                    )
                                                )}
                                            </IonSelect>
                                        );
                                    }}
                                />
                            </IonItem>
                        </IonCard>
                        <IonCard color="transparent">
                            <IonButton
                                data-testid="save-closed-date"
                                expand="block"
                                onClick={handleSubmit(
                                    handleSubmitClosedOrOpenDate
                                )}
                            >
                                {t("availability.closedDate.saveDetails")}
                            </IonButton>
                        </IonCard>
                        <IonCard>
                            {availabilityType ===
                                AvailabilityType.closedDate && (
                                <>
                                    {t("availability.closedDate.title")}:{" "}
                                    {t("availability.closedDateDescription")}
                                </>
                            )}

                            {availabilityType === AvailabilityType.openDate && (
                                <>
                                    {t("availability.closedDate.openDateTitle")}
                                    : {t("availability.openDateDescription")}
                                </>
                            )}
                        </IonCard>
                    </IonContent>
                </IonModal>
            </form>
        </IonPage>
    );
};
