import { PartialDeep } from "type-fest";
import { Timings } from "refsix-js-models";

export const transformInPeriods = (obj: PartialDeep<Timings>): number[] => {
    const field = [];

    if (obj.period1) {
        field.push(obj.period1);
    }
    if (obj.period2) {
        field.push(obj.period2);
    }
    if (obj.period3) {
        field.push(obj.period3);
    }
    if (obj.period4) {
        field.push(obj.period4);
    }

    return field;
};

export const transformInIntervals = (obj: Partial<Timings>): number[] => {
    const field = [];

    if (obj.interval1) {
        field.push(obj.interval1);
    }
    if (obj.interval2) {
        field.push(obj.interval2);
    }
    if (obj.interval3) {
        field.push(obj.interval3);
    }

    return field;
};
