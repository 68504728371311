export enum MessageTypes {
    MATCH_LIST_RESPONSE = "matchListResponse",
    SEND_RESULT = "sendResult", // Message received by phone when watch sends the match
    GET_MATCHES_LIST = "getMatchesList",
    RESULT_RECEIVED = "resultReceived", // This is the response to SEND_RESULT
    VERSION_MISMATCH = "versionMismatch",
    GET_CONFIGURATION = "getConfiguration",
    CONFIGURATION_RESPONSE = "configurationResponse",
    WAKE_UP = "wakeUp",
    WAKE_UP_RESPONSE = "wakeUpResponse",
    TOKEN_REQUEST = "tokenRequest",
    TOKEN_RESPONSE = "tokenResponse",
    GPS_RECEIVED = "splitGPS",
    HR_RECEIVED = "splitHeartRate",
    DEVICE_INFO_RESPONSE = "deviceInfoResponse",
}
