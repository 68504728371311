import { FC, useEffect, useRef, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLoading,
    IonPage,
    IonText,
    useIonRouter,
} from "@ionic/react";
import { AlertComponent, HeaderComponent } from "../../../../components";
import { useTranslation } from "react-i18next";
import { isAndroid, isIos } from "../../../../services/platformDetection";
import { WatchOptions } from "../../../../services/watch/watchStatus";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import "./watches.css";
import moment from "moment";
import { unpairWatch } from "../../../../services/watch/watchPairingService";
import { routes } from "../../../../route/constants";
import { watchModel } from "../../../../models/watchModel";
import { removeWatchDetails } from "../../../../services/watch/watchHelpers";
import PairWatchModal from "../../../../modals/pairWatchModal";

const Watches: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();
    const [watches, setWatches] = useState<watchModel[]>([]);
    const watchStatus = useSelector((state: RootState) => state.watchStatus);
    const settings = useSelector((state: RootState) => state.settings.settings);
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    const [watchToDelete, setWatchToDelete] = useState<watchModel>();
    const [loading, setLoading] = useState<boolean>(false);
    const slideOptions = useRef<any>(null);
    const [showPairModal, setShowPairModal] = useState<boolean>(false);

    useEffect(() => {
        setWatches([]);
        getWatches();
    }, [settings.watches]);

    useEffect(() => {
        if (watchToDelete) {
            setShowDeleteAlert(true);
        }
    }, [watchToDelete]);

    const getWatches = () => {
        const pairedWatches: watchModel[] = [];

        if (isIos() && watchStatus.isPaired) {
            pairedWatches.push({
                model: "Apple Watch",
                watchOS: WatchOptions.APPLE_WATCH,
            });
        } else if (isAndroid() && watchStatus.isPaired) {
            pairedWatches.push({
                make: watchStatus.make || undefined,
                model: watchStatus.model || "WearOS",
                watchOS: WatchOptions.WEAR_OS,
            });
        }
        setWatches(pairedWatches);
        setWatches((watches) => [...watches, ...settings.watches]);
    };

    const getWatchesInfo = (sessionId: string) => {
        let hasWearOs = false,
            hasTizen = false,
            hasFitbit = false,
            hasGarmin = false,
            hasAppleWatch = false;

        for (let i = 0; i < watches.length; i++) {
            if (watches[i].sessionId === sessionId) {
                if (watches[i].watchOS === WatchOptions.WEAR_OS) {
                    hasWearOs = true;
                }
                if (watches[i].watchOS === WatchOptions.TIZEN) {
                    hasTizen = true;
                }
                if (watches[i].watchOS === WatchOptions.FITBIT) {
                    hasFitbit = true;
                }
                if (watches[i].watchOS === WatchOptions.GARMIN) {
                    hasGarmin = true;
                }
                if (watches[i].watchOS === WatchOptions.APPLE_WATCH) {
                    hasAppleWatch = true;
                }
            }
        }

        return {
            hasWearOs: hasWearOs,
            hasTizen: hasTizen,
            hasFitbit: hasFitbit,
            hasGarmin: hasGarmin,
            hasAppleWatch: hasAppleWatch,
        };
    };

    const deleteWatch = async (watch: watchModel | undefined) => {
        setShowDeleteAlert(false);

        if (!watch || !watch.sessionId || !watch.platform) return;

        setLoading(true);

        // Closes all the opened slides
        await slideOptions.current.closeOpened();

        try {
            const deleteWatchResponse = await unpairWatch(
                watch.sessionId,
                watch.platform,
                watch.model,
                getWatchesInfo(watch.sessionId)
            );

            if (deleteWatchResponse) {
                await removeWatchDetails(watch.sessionId);
            }
            setLoading(false);
        } catch (e: any) {
            console.log("Error trying to delete watch", e);
            setLoading(false);
        }
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                titleTestId={"manage-watches-page-header"}
                title={t("settings.WatchSettings.title")}
                headerEndText={t("general.add")}
                onClickEndButton={() =>
                    route.push(routes.refsixMoreSettingsManageWatchesPair)
                }
            />
            <IonContent>
                <IonCard>
                    <IonLoading isOpen={loading} />
                    {watches.length > 0 &&
                        watches.map((watch, index) => {
                            return (
                                <IonItemSliding
                                    ref={slideOptions}
                                    key={watch.watchOS + index}
                                    id="slideOptions"
                                    disabled={!watch.datePaired}
                                >
                                    <IonItem lines="full">
                                        <IonCol size="6">
                                            <div
                                                className={`watch-image ${watch.watchOS}`}
                                            />
                                        </IonCol>
                                        <div className="watch-item-text">
                                            {watch.make && (
                                                <IonText>{watch.make}</IonText>
                                            )}
                                            <IonText>{watch.model}</IonText>
                                            <IonText hidden={!watch.datePaired}>
                                                {`${t(
                                                    "settings.WatchSettings.added"
                                                )} ${moment(
                                                    watch.datePaired
                                                ).format("DD/MM/yyyy")}`}
                                            </IonText>
                                        </div>
                                    </IonItem>
                                    <IonItemOptions>
                                        <IonItemOption
                                            data-testid="delete-watch-button"
                                            color="danger"
                                            onClick={() =>
                                                setWatchToDelete(watch)
                                            }
                                        >
                                            {t("general.delete")}
                                        </IonItemOption>
                                    </IonItemOptions>
                                </IonItemSliding>
                            );
                        })}

                    {/* NO WATCHES PAIRED */}
                    {!watches ||
                        (!watches.length && (
                            <IonCard>
                                <IonCardContent>
                                    <IonText style={{ color: "white" }}>
                                        {t(
                                            "fixture.FixtureReady.noPairedWatchError.title"
                                        )}
                                    </IonText>
                                </IonCardContent>
                            </IonCard>
                        ))}
                </IonCard>
                <IonCard>
                    <IonButton
                        data-testid="add-watch-button"
                        expand="block"
                        onClick={() => setShowPairModal(true)}
                    >
                        <IonText>{t("settings.WatchSettings.addNew")}</IonText>
                    </IonButton>
                </IonCard>

                <AlertComponent
                    testId="delete-watch-alert"
                    onDonePressed={() => deleteWatch(watchToDelete)}
                    showAlert={showDeleteAlert}
                    title={t("general.confirmRemoval")}
                    description={t("settings.WatchSettings.removeWatch", {
                        model: watchToDelete?.model,
                    })}
                    showAlternativeButton
                    buttonAltText={t("general.cancel")}
                    onButtonAltClicked={() => {
                        setShowDeleteAlert(false);
                        setWatchToDelete(undefined);
                    }}
                    buttonDone={t("general.delete")}
                />
            </IonContent>
            <PairWatchModal
                openPairModal={showPairModal}
                onDismiss={() => setShowPairModal(false)}
            />
        </IonPage>
    );
};

export default Watches;
