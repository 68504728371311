import {
    Directory,
    Encoding,
    FileInfo,
    Filesystem,
} from "@capacitor/filesystem";
import request from "./request";
import { filter, flatten } from "lodash";
import _ from "lodash/fp";
import MiniSearch, { SearchOptions } from "minisearch";
import {
    TeamPackData,
    TeamPackDivision,
    TeamPackTeamPlayers,
} from "refsix-js-models";
import { TeamListItem } from "../components/hooks/autocomplete/useTeamList";
import { CompetitionListItem } from "../components/hooks/autocomplete/useCompetitionList";
import { TeamPlayerListItem } from "../components/hooks/autocomplete/useTeamPlayerList";
import { AxiosResponse } from "axios";

const FILE_EXTENSION = ".json";

const TEAMPACKS_PATH = "team-packs2";

const writeFile = async (path: string, data: string) =>
    await Filesystem.writeFile({
        directory: Directory.Data,
        path: `${TEAMPACKS_PATH}${path}`,
        data,
        encoding: Encoding.UTF8,
    });
const deleteFile = async (path: string) => {
    try {
        await Filesystem.deleteFile({
            path: `${TEAMPACKS_PATH}${path}`,
            directory: Directory.Data,
        });
    } catch (e) {}
};
const readFile = async (path: string) =>
    (
        await Filesystem.readFile({
            path: `${TEAMPACKS_PATH}${path}`,
            directory: Directory.Data,
            encoding: Encoding.UTF8,
        })
    ).data;
const readDir = async (path: string) =>
    await Filesystem.readdir({
        path: `${TEAMPACKS_PATH}${path}`,
        directory: Directory.Data,
    });
const mkdir = async (path: string) =>
    await Filesystem.mkdir({
        path: `${TEAMPACKS_PATH}${path}`,
        directory: Directory.Data,
    }).catch((e) => {});

export async function downloadPack(name: string) {
    // If we have a local pack, get the last modified date
    let lastModifiedLocal: string | undefined = undefined;
    let localPack: TeamPackData | undefined = undefined;
    try {
        localPack = await loadPack(name);
        lastModifiedLocal = localPack.lastModified;
    } catch (e) {
        console.log("No local pack", name);
        console.log(e);
    }
    const pack = await getPack(name, lastModifiedLocal);
    if (!pack) {
        return;
    }

    await mkdir("/");

    return await writeFile(`/${name}${FILE_EXTENSION}`, JSON.stringify(pack));
}

export interface TeamPackInfo {
    name: string;
    description: string;
    displayName: string;
    autoDownload: boolean;
}

export const getPack = async (
    pack: string,
    lastModified: string | undefined
) => {
    try {
        const res = await request.get<TeamPackData>("/team-packs/" + pack, {
            headers: {
                // Last modified date string
                "Last-Modified": lastModified,
            },
        });
        const data = res.data;
        data.lastModified = res.headers["last-modified"];
        return data;
    } catch (e) {
        return null;
    }
};
export const listPacksForCountry = async (
    countryCode: string,
    nationalFA: string | undefined,
    clubName?: string
): Promise<AxiosResponse<TeamPackInfo[]> | null> => {
    try {
        const params = new URLSearchParams({ countryCode });
        if (nationalFA) {
            params.append("nationalFA", nationalFA);
        }
        if (clubName) {
            params.append("clubName", clubName);
        }

        return await request.get<TeamPackInfo[]>("/team-packs/", {
            params,
        });
    } catch (e) {
        console.log("Error listing team packs", e);
        return null;
    }
};

export const getLocalPacks = async () => {
    try {
        await mkdir("/");
        const packs = await readDir("/");
        const loadPacks = _.flow(
            _.map<FileInfo, string>((c) => c.name.replace(FILE_EXTENSION, "")),
            _.map(loadPack)
        );

        return await Promise.all(loadPacks(packs.files));
    } catch (e) {
        return [];
    }
};
export const getAvailablePacks = async () => {
    const _localPacks = await localPacks;
    availablePacks = filter(
        availablePacks,
        (p) => !_.find({ name: p.name }, _localPacks)
    );

    return availablePacks;
};

const stalePacks = async (
    local: Promise<TeamPackData[]>,
    remote: AxiosResponse<TeamPackInfo[]> | null
) => {
    if (!remote) return;
    const available = remote.data;
    (await local).forEach((l: any) => {
        const remotePack = _.find({ displayName: l.displayName }, available);
        if (!remotePack) {
            console.log("Stale Pack:", l.displayName);
            deletePack(l.name);
        }
    });
};
const loadPack = async (pack: string): Promise<TeamPackData> => {
    const packData = await readFile(`/${pack}${FILE_EXTENSION}`);
    return JSON.parse(packData as string);
};
let localPacks = getLocalPacks();
let availablePacks: TeamPackInfo[] = [];

export const subscribeToPack = async (pack: string) => {
    await downloadPack(pack);
    localPacks = getLocalPacks();
};
export const deletePack = async (pack: string) => {
    await deleteFile(`/${pack}${FILE_EXTENSION}`);
    localPacks = getLocalPacks();
};
export const deletePacks = async () => {
    await Filesystem.rmdir({
        path: `${TEAMPACKS_PATH}`,
        directory: Directory.Data,
        recursive: true,
    });
    localPacks = getLocalPacks();
};
// gb eng
export const onStart = async (
    countryCode: string,
    nationalFA: string | undefined,
    clubName: string | undefined,
    defaultPacks?: string[]
) => {
    // Fetch packs for country
    const packs = await listPacksForCountry(countryCode, nationalFA, clubName);
    let tempAvailablePacks: TeamPackInfo[] = [];
    stalePacks(localPacks, packs);
    if (packs) {
        // If any are autodownload, register them
        const packsToRegister = filter(packs.data, (p) => p.autoDownload);
        for (let p of packsToRegister) {
            await subscribeToPack(p.name);
        }
        // If not, then add them to the list of available packs
        tempAvailablePacks = filter(packs.data, (p) => !p.autoDownload);
    }
    // console.log("Packs Already downloaded", packsToRegister);
    for (let p of defaultPacks ?? []) {
        await subscribeToPack(p);
    }

    const _localPacks = await localPacks;
    availablePacks = filter(
        tempAvailablePacks,
        (p) => !_.find({ name: p.name }, _localPacks)
    );
    populateFuseCache(_localPacks);
};

type FuseCache = {
    teams?: MiniSearch<TeamListItem>;
    competitions?: MiniSearch<CompetitionListItem>;
    teamPlayers?: MiniSearch<TeamPlayerListItem>;
};

const fuseCache: FuseCache = {
    //teams: null,
};
const searchOptions: SearchOptions = {
    prefix: true,
    //maxFuzzy: 3,
    fuzzy: 0.1,
    combineWith: "AND",
};
const populateFuseCacheTeamPlayers = (packs: TeamPackData[]) => {
    const getItems: (p: TeamPackData[]) => TeamPlayerListItem[] = _.flow(
        _.flatMap("players"),
        _.flatMap((t: TeamPackTeamPlayers) =>
            _.map(
                (p): TeamPlayerListItem => ({
                    id: _.uniqueId("TeamPlayerListItem"),
                    team: t.name,
                    name: p,
                }),
                t.players
            )
        )
    );
    const items = flatten(packs).length > 0 ? getItems(packs) : [];
    //console.log("Populating Fuse Cache Team Players", items);
    fuseCache.teamPlayers = new MiniSearch<TeamPlayerListItem>({
        fields: ["name", "team"],
        storeFields: ["name", "team"],
        searchOptions,
    });
    fuseCache.teamPlayers.addAll(items);
};
const populateFuseCacheTeams = (packs: TeamPackData[]) => {
    const getItems: (p: TeamPackData[]) => TeamListItem[] = _.flatMap((p) =>
        _.flatMap(
            (d) =>
                _.map(
                    (t): TeamListItem => ({
                        id: _.uniqueId("TeamListItem"),
                        league: d.name,
                        name: t.fullName,
                    }),
                    d.team
                ),
            p.teams
        )
    );
    const items = _.uniqBy("id", getItems(packs));
    fuseCache.teams = new MiniSearch<TeamListItem>({
        fields: ["name"],
        storeFields: ["name", "league"],
        searchOptions: {
            boost: { name: 2, league: 1 },
            ...searchOptions,
        },
    });
    fuseCache.teams.addAll(items);
};
const populateFuseCacheCompetitions = (packs: TeamPackData[]) => {
    const getItems: (p: TeamPackData[]) => CompetitionListItem[] = _.flow(
        _.flatMap("divisions"),
        _.flatMap((ds: TeamPackDivision) => {
            return _.map(
                (t) => ({
                    id: ds.name + t.fullName,
                    league: ds.name,
                    name: t.shortName,
                    fullName: t.fullName,
                }),
                ds.division
            );
        }),
        // Filter out empty IDs
        _.filter((c) => c.id !== "" || c.fullName !== "")
        //_.tap((c) => console.log(c))
    );
    const items =
        flatten(packs).length > 0 ? _.uniqBy("id", getItems(packs)) : [];

    fuseCache.competitions = new MiniSearch<CompetitionListItem>({
        fields: ["league", "name"],
        storeFields: ["name", "league", "fullName"],
        searchOptions,
    });
    fuseCache.competitions.addAll(items);
};
const populateFuseCache: (packs: TeamPackData[]) => any = _.flow(
    _.tap(populateFuseCacheTeams),
    _.tap(populateFuseCacheCompetitions),
    _.tap(populateFuseCacheTeamPlayers)
);

export const getFuseCache = <T extends keyof FuseCache>(
    instanceType: T
): FuseCache[T] => fuseCache[instanceType];
