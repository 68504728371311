import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { hasFeatureAccessFromState } from "../../services/subscriptionService";
import {
    ConfigPhone,
    MatchOfficials,
    Player,
    Templates,
    UserActionsCheckList,
} from "refsix-js-models";
import { filter, flatten, map, values } from "lodash/fp";
import { UserProfile } from "../../models/userProfile";
import { SubscriptionState } from "../../redux/models/subscriptionState";
import { useMatches, useMatchesBy } from "./match";
import { getLanguageCode } from "../../utils/localeUtils";
import { useStatus } from "./useNetwork";

export const useUsername = (): string | undefined =>
    useSelector((state: RootState) => state.auth.session?.user_id);
export const useEmail = (): string | undefined =>
    useSelector((state: RootState) => state.currentUser?.profile?.email);
export const useInstalledAppVersion = (): string | undefined =>
    useSelector((state: RootState) => state.appStatus.installedVersion);

export const useUserLoggedIn = (): boolean =>
    useSelector((state: RootState) => state.auth.loggedIn);
export const useLoginOrRegisterComplete = (): boolean =>
    useSelector((state: RootState) => state.appStatus.loginOrRegisterComplete);
export const useShouldShowWhatsNew = (): boolean =>
    useSelector((state: RootState) => state.appStatus.showWhatsNew);

export const useHasFeatureAccess = (feature: string): boolean =>
    useSelector((state: RootState) =>
        hasFeatureAccessFromState(state.subscriptions, feature)
    );

export const usePlayersByTeam = (teamName: string): string[] => {
    const matches = useMatchesBy(
        (m) => m.homeTeam === teamName || m.awayTeam === teamName
    );
    const players = flatten(
        matches?.map((m) => (m.players && m.players[teamName]) || [])
    );
    const filteredPlayers = filter((p) => !p.teamOfficial, players);
    return map("name", filteredPlayers);
};

export const useMatchOfficials = (): string[] => {
    const matches = useMatches(); // Assuming useMatches() returns an array of match objects
    const officialNames: string[] = [];

    const officials: (MatchOfficials | undefined)[] = matches
        ? matches.map((match) => match.matchOfficials)
        : [];

    officials.forEach((officials) => {
        if (officials && typeof officials === "object") {
            Object.values(officials).forEach((name) => {
                if (name) {
                    officialNames.push(name);
                }
            });
        }
    });

    return officialNames;
};

export const useTeamOfficials = (): Player[] => {
    const matches = useMatches();

    const players = flatten(
        matches?.map((m) => (m.players && values(m.players)) || []).flat()
    );

    return players.filter((p) => p.teamOfficial);
};

export const useSettings = (): ConfigPhone | undefined =>
    useSelector((state: RootState) => state.settings.settings);

export const useProfile = (): UserProfile | undefined =>
    useSelector((state: RootState) => state.currentUser?.profile);
export const useTemplates = (): Templates | undefined =>
    useSelector((state: RootState) => state.templates.templates);

export const useSubscription = (): SubscriptionState | undefined =>
    useSelector((state: RootState) => state.subscriptions);

export const useIsPro = (): boolean =>
    useSelector((state: RootState) => state.subscriptions?.isPro);
export const useIsBasic = (): boolean =>
    useSelector((state: RootState) => state.subscriptions?.isPlus);

export const useUserActionsCheckList = (): UserActionsCheckList =>
    useSelector((state: RootState) => state.userActionsCheckList);

export const useLocation = (): Location => window.location;

export const useIsLanguageJapanese = (): boolean => getLanguageCode() === "ja";
export const useIsLanguageEnglish = (): boolean => getLanguageCode() === "en";

export const useSessionExpired = (): boolean =>
    useSelector((state: RootState) => state.auth.sessionExpired || false);
export const useSessionExpiredModalDismissed = (): boolean =>
    useSelector(
        (state: RootState) =>
            state.appStatus.sessionExpiredModalDismissed || false
    );
export const useDbUpdatedAt = (): number | undefined =>
    useSelector((state: RootState) => state.appStatus.dbUpdatedAt);
export const useIsOnline = () => {
    const { networkStatus } = useStatus();
    return networkStatus?.connected || false;
};
export const useAppleLoginCache = (): any =>
    useSelector((state: RootState) => state.settings.appleLoginCache);

export const useLoadingMatches = (): any =>
    useSelector((state: RootState) => state.matches.loading);

export const useShowOnBoardingFlow = (): boolean =>
    useSelector((state: RootState) => state.appStatus.showOnBoardingFlow);

export const useIntegrationsMessages = (): any =>
    useSelector(
        (state: RootState) => state.integrationsMessages.messages || []
    );

export * from "./fitnessData";
export * from "./dimensions";
