import { FC, useState } from "react";
import {
    IonButton,
    IonCol,
    IonItem,
    IonLoading,
    useIonAlert,
} from "@ionic/react";
import { MatchPhone } from "refsix-js-models";
import { useTranslation } from "react-i18next";
import {
    syncStackSportsMatches,
    updateParticipantStatus,
} from "../services/integrationService";
import "./acceptDecline.css";
import { useUsername } from "./hooks";

interface AcceptDeclineProps {
    match: MatchPhone;
}

const AcceptDeclineComponent: FC<AcceptDeclineProps> = ({ match }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const username = useUsername();
    const [displayAlert] = useIonAlert();

    if (!match.pendingAcceptance) return <></>;

    const acceptDeclineMatch = async (
        match: MatchPhone,
        status: "accepted" | "declined"
    ) => {
        const confirmationMessage =
            status === "declined"
                ? t("integrations.declineConfirmation")
                : t("integrations.acceptConfirmation");

        await displayAlert({
            header: "Stack Officials",
            message: confirmationMessage,
            buttons: [
                {
                    text: t("general.later"),
                    role: "cancel",
                },
                {
                    text: t("general.yes"),
                    role: "confirm",
                    handler: () => updateParticipant(status),
                },
            ],
        });
    };

    const updateParticipant = async (status: "accepted" | "declined") => {
        if (!username) return;

        setLoading(true);
        try {
            if (match.integration && match.integration.participantId) {
                const updateResponse = await updateParticipantStatus(
                    match.integration.participantId,
                    status,
                    username
                );

                if (updateResponse) {
                    await syncStackSportsMatches();
                }
            }

            setLoading(false);
        } catch (e) {
            console.log("error updating participant id", e);
            setLoading(false);
        }
    };

    return (
        <IonItem
            lines="none"
            className="row-segment-period container-flex"
            data-testid={`accept-decline-row-${match._id}`}
        >
            <IonCol size="6" className="match-log-event-separator-wrapper">
                <IonButton
                    fill="clear"
                    className="button-accept-decline accept"
                    data-testid="stack-sports-button-accepted"
                    onClick={async (e) => {
                        e.stopPropagation();
                        await acceptDeclineMatch(match, "accepted");
                    }}
                >
                    {t("integrations.accept")}
                </IonButton>
            </IonCol>
            <IonLoading isOpen={loading} />
            <IonCol size="6" className="match-log-event-separator-wrapper">
                <IonButton
                    fill="clear"
                    className="button-accept-decline decline"
                    data-testid="stack-sports-button-declined"
                    onClick={async (e) => {
                        e.stopPropagation();
                        await acceptDeclineMatch(match, "declined");
                    }}
                >
                    {t("integrations.decline")}
                </IonButton>
            </IonCol>
        </IonItem>
    );
};

export default AcceptDeclineComponent;
