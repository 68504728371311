import {
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSelectOption,
    IonText,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import React, { FC, useEffect } from "react";
import { HeaderComponent, UserInfoComponent } from "../../../components";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { updateProfile } from "../../../services/profileService";
import { routes } from "../../../route/constants";
import { useStoreRefreshSubscriptions } from "../../../components/hooks/refreshStoreSubscriptions";
import { R6IonInput } from "../../../components/r6ionic/R6IonInput";
import { R6IonSelect } from "../../../components/r6ionic/R6IonSelect";
import moment from "moment";
import { mpUpdateUserProfile } from "../../../services/analytics/mixpanelService";
import { logoutAndRedirect } from "../../../services/authService";
import request from "../../../services/request";
import { useProfile, useUsername } from "../../../components/hooks";

export const Profile: FC = () => {
    const { t } = useTranslation();

    const profile = useProfile();
    const route = useIonRouter();
    const formMethods = useForm<FieldValues>({
        mode: "all",
        defaultValues: profile,
    });
    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = formMethods;
    const [displayDeleteAlert] = useIonAlert();
    const [displayDeleteCompleteAlert] = useIonAlert();
    const [displayDeleteCompleteFailAlert] = useIonAlert();
    const username = useUsername();

    useEffect(() => {
        if (profile && profile.dob) {
            reset((formValues) => ({
                ...formValues,
                dob: moment(profile.dob).format("YYYY-MM-DD"),
            }));
        }
    }, []);
    const refresh = useStoreRefreshSubscriptions();
    const errorProps = (name: string) => {
        const errorMessage = get(errors, `${name}.message`);
        return errorMessage !== undefined
            ? { className: "item-title danger" }
            : { className: "item-title" };
    };

    const handleSettingsChange = async (data: any) => {
        if (profile?.username) {
            try {
                if (data.dob) {
                    data.dob = moment(data.dob).toISOString();
                }
                await updateProfile(profile.username, data);

                // Update Mixpanel user profile
                mpUpdateUserProfile(data);
                route.goBack();
            } catch (e) {
                console.error("error updatin props", e);
            }
        }
    };

    const confirmDelete = async () => {
        try {
            await request.post(`/delete-account/${username}`, {
                name: `${profile?.firstName} ${profile?.firstName}`,
                username,
            });
            await displayDeleteCompleteAlert(
                t("myaccount.delete.confirmation"),
                [
                    {
                        text: t("help.OnboardingVideo.controls.ok"),
                        handler: async () => {
                            await logoutAndRedirect();
                        },
                    },
                ]
            );
        } catch (err: any) {
            console.log("Error sending delete match request", err);
            await displayDeleteCompleteFailAlert({
                message: t("general.errorTryAgain"),
                buttons: [
                    {
                        text: t("help.OnboardingVideo.controls.ok"),
                    },
                ],
            });
        }
    };

    const deleteAccountConfirm = async () => {
        await displayDeleteAlert({
            message: t("myaccount.delete.title"),
            cssClass: "confirm-delete-account",
            buttons: [
                {
                    text: t("general.delete"),
                    role: "destructive",
                    handler: () => {
                        confirmDelete();
                    },
                },
                {
                    text: t("general.cancel"),
                    role: "cancel",
                },
            ],
        });
    };

    return (
        <IonPage>
            <HeaderComponent
                title={t("more.menu.account")}
                headerEndText={t("general.save")}
                onClickEndButton={handleSubmit(handleSettingsChange)}
                showBackButton
                defaultHref={routes.refsixMore}
                titleTestId={"more-profile-page-header"}
            />
            <IonContent>
                <FormProvider {...formMethods}>
                    <IonCard className="match-card-container">
                        <UserInfoComponent />

                        <IonItem {...errorProps("username")} lines="full">
                            <IonLabel>{t("general.username")}</IonLabel>

                            <R6IonInput
                                name="username"
                                disabled={true}
                                rules={{
                                    required: true,
                                }}
                                className="align-right"
                                autocapitalize="off"
                            />
                        </IonItem>
                        <IonItem lines="full" {...errorProps("email")}>
                            <IonLabel>{t("register.register.email")}</IonLabel>
                            <R6IonInput
                                name="email"
                                rules={{
                                    required: true,
                                    pattern: /[^@]+@[^@]+/,
                                }}
                                type="email"
                                inputMode="email"
                                className="align-right"
                                autocapitalize="off"
                            />
                        </IonItem>

                        <IonItem {...errorProps("dob")} lines="full">
                            <IonLabel>{t("myaccount.dateOfBirth")}</IonLabel>
                            <R6IonInput
                                name="dob"
                                type="date"
                                className="align-right"
                            />
                        </IonItem>
                        <IonItem {...errorProps("gender")} lines="full">
                            <IonLabel>{t("myaccount.gender.title")}</IonLabel>
                            <R6IonSelect name="gender">
                                <IonSelectOption value={"male"}>
                                    {t("myaccount.gender.male")}
                                </IonSelectOption>
                                <IonSelectOption value={"female"}>
                                    {t("myaccount.gender.female")}
                                </IonSelectOption>
                                <IonSelectOption value={"nonBinary"}>
                                    {t("myaccount.gender.nonBinary")}
                                </IonSelectOption>
                            </R6IonSelect>
                        </IonItem>
                        <IonItem
                            lines="full"
                            {...errorProps("biometrics.height")}
                        >
                            <IonLabel>{t("myaccount.height")}</IonLabel>
                            <R6IonInput
                                name="biometrics.height"
                                type="number"
                                inputMode={"numeric"}
                                className="align-right"
                                rules={{ min: 1 }}
                            />
                            <IonText color="medium">
                                {t("myaccount.cm")}
                            </IonText>
                        </IonItem>
                        <IonItem
                            lines="none"
                            {...errorProps("biometrics.weight")}
                        >
                            <IonLabel>{t("myaccount.weight")}</IonLabel>
                            <R6IonInput
                                name="biometrics.weight"
                                type="number"
                                rules={{ min: 1 }}
                                inputMode={"numeric"}
                                className="align-right"
                            />
                            <IonText color="medium">
                                {t("myaccount.kg")}
                            </IonText>
                        </IonItem>
                    </IonCard>
                </FormProvider>
                <IonCard>
                    <IonList>
                        <IonItem
                            lines="none"
                            button
                            className="item-title"
                            onClick={() =>
                                route.push(routes.refsixMoreSubscriptions)
                            }
                            data-testid="support-button"
                        >
                            <IonLabel>
                                {t("myaccount.subscriptionsHeader")}
                            </IonLabel>
                        </IonItem>
                    </IonList>
                </IonCard>

                <IonCard>
                    <IonList>
                        <IonItem
                            lines="none"
                            button
                            className="item-title"
                            onClick={async () => {
                                await deleteAccountConfirm();
                            }}
                            data-testid="delete-account-button"
                        >
                            <IonLabel>
                                {t("myaccount.delete.deleteAccount")}
                            </IonLabel>
                        </IonItem>
                    </IonList>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
