// import {
//     AdMobPlus,
//     BannerAd,
//     InterstitialAd,
//     TrackingAuthorizationStatus,
// } from "@admob-plus/capacitor";
import { store } from "../redux/store";
import { checkIfBasic, checkIfPro, hasFeatureAccess } from "./subscriptionService";
import { isIos } from "./platformDetection";
import { App } from "@capacitor/app";
import { routes } from "../route/constants";

export function isAdFreePage(path: string): boolean {
    const adFreePages = [
        routes.upgrade,
        `${routes.upgrade}/pro`,
        `${routes.upgrade}/plus`,
        routes.upgrade,
        routes.registerStepTwo,
        routes.registerUpgrade,
        routes.registerFreeTrial,
        routes.refsixOnboardingGettingStarted,
        routes.refsixOnboardingFoundWatch,
        routes.refsixOnboardingNoWatchFound,
        routes.refsixOnboardingPairWatch,
        routes.refsixOnBoardingShareLocationData,
        routes.refsixOnBoardingTimerSettings,
        routes.refsixOnBoardingTimerSettingsStepTwo,
        routes.refsixOnBoardingSetupComplete,
    ];
    return adFreePages.includes(path);
}

export function shouldDisplayAds(): boolean {
    const state = store.getState();
    if (!state.auth.session) return false;
    const matches = state.matches.matches;
    const subscriptions = state.subscriptions.subscriptions;
    const path = window.location.pathname;
    return (
        matches.length > 3 &&
        !isAdFreePage(path) &&
        !hasFeatureAccess("noAds") && // This is preferred check
        // fall back to checking basic and pro in case they haven't been updated yet
        // TODO remove sometime in the future
        !checkIfBasic(subscriptions) &&
        !checkIfPro(subscriptions)
    );
}

export async function createBanner(id: string) {
    // const banner = new BannerAd({
    //     adUnitId: id,
    //     position: "bottom",
    // });
    // AdMobPlus.addListener("banner.loadfail", (info) => {
    //     console.log("admob banner loadfail", info);
    //     banner.hide();
    // });
    // AdMobPlus.addListener("banner.load", (info) => {
    //     console.log("admob banner load", info);
    //     banner.show().catch((err) => {
    //         console.log("error showing banner", err);
    //     });
    //     console.log("show admob banner");
    // });
    // await banner.load();
    // return banner;
}

export async function showInterstitial(id: string) {
    // const interstitial = new InterstitialAd({
    //     adUnitId: id,
    // });
    // await interstitial.load();
    // await interstitial.show().catch((err) => {
    //     console.log("error showing interstitial", err);
    // });
}

export class AdMobService {
    static instance: AdMobService;
    loading: boolean = false;
    appIsInForeground: boolean = false; // initialize to false as can boot in background
    enabled: boolean = true;
    // banner?: BannerAd;
    // bannerId: string = "ca-app-pub-3940256099942544/6300978111"; // TEST ID - use during testing
    bannerId: string = isIos()
        ? (process.env.REACT_APP_AD_IOS_BANNER as string)
        : (process.env.REACT_APP_AD_ANDROID_BANNER as string);

    private constructor() {
        // clean up old ads
        this.stopAds();

        store.subscribe(this.onStateChange.bind(this));

        // don't show ads if the app is booting in background
        App.getState().then((state) => {
            this.appIsInForeground = state.isActive;
        });

        // listen for changes to foreground/background
        App.addListener("appStateChange", ({ isActive }) => {
            this.appIsInForeground = isActive;
            if (isActive) {
                this.startAds();
            } else {
                this.stopAds();
            }
        });

        this.startAds();
    }

    async requestAuthorization() {
        // const response = await AdMobPlus.trackingAuthorizationStatus();
        // if (response.status === TrackingAuthorizationStatus.notDetermined) {
        //     const status = await AdMobPlus.requestTrackingAuthorization();
        //     console.log("admob request tracking auth status", status);
        // }
    }

    private onStateChange() {
        // if (
        //     this.appIsInForeground &&
        //     this.enabled &&
        //     !this.loading &&
        //     shouldDisplayAds()
        // ) {
        //     this.loading = true;
        //
        //     this.requestAuthorization().catch((err) =>
        //         console.log("Error requesting add tracking auth", err)
        //     );
        //
        //     if (this.banner) {
        //         this.banner.show().catch((err) => {
        //             console.log("error showing banner", err);
        //         });
        //     } else {
        //         createBanner(this.bannerId)
        //             .then((ad) => {
        //                 this.banner = ad;
        //                 this.loading = false;
        //             })
        //             .catch((err) => {
        //                 console.log("error showing banner", err);
        //             });
        //     }
        // }
        //
        // // get rid of it if they go pro or something
        // if (this.banner && (!shouldDisplayAds() || !this.appIsInForeground)) {
        //     this.stopAds();
        // }
    }

    stopAds() {
        // this.enabled = false;
        // this.loading = false;
        // if (this.banner) {
        //     this.banner.hide().catch((err: any) => {
        //         console.log("error hiding banner", err);
        //     });
        // }
    }
    startAds() {
        // TODO disabled admob to stop crashes.
        // this.enabled = true;
        // this.onStateChange();
    }

    static getInstance(): AdMobService {
        if (!AdMobService.instance) {
            AdMobService.instance = new AdMobService();
        }
        return AdMobService.instance;
    }
}

export const adMobService = AdMobService.getInstance();

export function stopAds() {
    adMobService.stopAds();
}

export function startAds() {
    adMobService.startAds();
}
