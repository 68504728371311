/**
 * Returns a deep copy of the object
 */
export function copyObject<Type>(obj: Type): Type {
    if (typeof obj !== "undefined") {
        return JSON.parse(JSON.stringify(obj));
    }
    console.log("Error tried to copy an object that was undefined");
    return obj;
}
