import { IonButton, IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import { addCircle, removeCircle } from "ionicons/icons";
import { FC } from "react";
import { SelectedTeam } from "refsix-js-models";

export const ToggleRowComponent: FC<{
    teamName: string;
    behaviorScore: number;
    updateScore: (behaviorScore: number) => void;
    incrementMax?: number;
    decrementMin?: number;
    testIdTeamSide?: SelectedTeam.home | SelectedTeam.away;
}> = ({
    teamName,
    behaviorScore,
    updateScore,
    incrementMax,
    decrementMin,
    testIdTeamSide,
}) => {
    const handleDecrement = () => {
        if (behaviorScore > (decrementMin || 0)) {
            updateScore(behaviorScore - 1);
        }
    };

    const handleIncrement = () => {
        if (behaviorScore < (incrementMax || 5)) {
            updateScore(behaviorScore + 1);
        }
    };

    return (
        <IonItem className="item-title" lines="none">
            <IonLabel slot="start" className="filters-officials-label">
                {teamName}
            </IonLabel>
            <div className="add-remove-button-container">
                <IonButton
                    size="default"
                    color="transparent"
                    slot="end"
                    onClick={() => handleDecrement()}
                    data-testid={`${testIdTeamSide}-decrement`}
                >
                    <IonIcon
                        className="icon-add-remove"
                        icon={removeCircle}
                        size="large"
                    />
                </IonButton>
                <IonText
                    data-testid={`${testIdTeamSide}-behavior-score`}
                    slot="end"
                >
                    {behaviorScore}
                </IonText>
                <IonButton
                    size="default"
                    color="transparent"
                    slot="end"
                    onClick={() => handleIncrement()}
                    data-testid={`${testIdTeamSide}-increment`}
                >
                    <IonIcon
                        className="icon-add-remove"
                        icon={addCircle}
                        size="large"
                    />
                </IonButton>
            </div>
        </IonItem>
    );
};
