import { IonButton, IonText } from "@ionic/react";
import { FC } from "react";

interface OverlayMapProps {
    imageUrl: string;
    title: string;
    showButton?: boolean;
    btnTestId?: string;
    onButtonClick?: () => void;
}

const OverlayMapComponent: FC<OverlayMapProps> = ({
    imageUrl,
    title,
    showButton,
    btnTestId,
    onButtonClick,
}) => {
    return (
        <div className="pro-teaser">
            <img alt="" src={imageUrl} className="pro-teaser img" />
            {showButton && onButtonClick ? (
                <IonButton
                    className="pro-lock"
                    expand="block"
                    data-testid={btnTestId}
                    onClick={onButtonClick}
                >
                    <IonText className="find-more-text text-black">
                        {title}
                    </IonText>
                </IonButton>
            ) : (
                <IonText className="find-more-text overlay-text">
                    {title}
                </IonText>
            )}
        </div>
    );
};

export default OverlayMapComponent;
