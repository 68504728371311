import { FC } from "react";
import { MatchPhone } from "refsix-js-models";
import { useTranslation } from "react-i18next";
import RoundBarComponent from "./RoundBarComponent";
import { hasFeatureAccess } from "../services/subscriptionService";
import { ProTeaser } from "./ProTeaser";

export interface SpeedCategoryComponentInterface {
    match: MatchPhone;
}

const SpeedCategoryComponent: FC<SpeedCategoryComponentInterface> = ({
    match,
}) => {
    const { t } = useTranslation();
    const speedThroughMatchAccess = hasFeatureAccess("speedThroughMatch");

    if (!match.stats) {
        return <></>;
    }

    const speedCategoryDistances = [
        match.stats.speedCategoryDistances[1],
        match.stats.speedCategoryDistances[2],
        match.stats.speedCategoryDistances[3],
        match.stats.speedCategoryDistances[4] +
            match.stats.speedCategoryDistances[5],
    ];

    const speedCategoryDurations = [
        match.stats.speedCategoryDurations[0],
        match.stats.speedCategoryDurations[1],
        match.stats.speedCategoryDurations[2],
        match.stats.speedCategoryDurations[3],
        match.stats.speedCategoryDurations[4] +
            match.stats.speedCategoryDurations[5],
    ];

    const series = [
        t("graphs.SpeedCategoryDistanceGraph.stand"),
        t("general.walk"),
        t("general.jog"),
        t("general.run"),
        t("general.sprint"),
    ];

    return (
        <>
            <ProTeaser
                showTeaser={
                    !speedThroughMatchAccess && match._id !== "example-result1"
                }
                teaserName="stats-speed-distance"
            >
                <RoundBarComponent
                    title={t("fixture.FixtureSummaryStats.distanceCovered")}
                    values={speedCategoryDistances}
                    series={series.slice(1, 5)}
                    distanceBy="distance"
                    numberOfCategories={4}
                    unit={"distance"}
                    useDummyData={
                        !speedThroughMatchAccess &&
                        match._id !== "example-result1"
                    }
                />
            </ProTeaser>

            <ProTeaser
                showTeaser={
                    !speedThroughMatchAccess && match._id !== "example-result1"
                }
                teaserName="stats-speed-percentage"
            >
                <RoundBarComponent
                    title={t("fixture.FixtureSummaryStats.timeDuration")}
                    values={speedCategoryDurations}
                    series={series}
                    distanceBy="duration"
                    numberOfCategories={5}
                    unit={"%"}
                    useDummyData={
                        !speedThroughMatchAccess &&
                        match._id !== "example-result1"
                    }
                />
            </ProTeaser>
        </>
    );
};

export default SpeedCategoryComponent;
