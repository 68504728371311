import * as Sentry from "@sentry/react";
import { addRawFitnessData } from "../fitnessDataSync/fitnessDataSyncService";
import { FitnessDataType } from "refsix-js-models";
import { store } from "../../redux/store";
import { RawFitnessData } from "../fitnessDataSync/types";

const TIMEOUT_DURATION = 15000;

export enum MessageChunkType {
    gps = "gps",
    hr = "heartRate",
}

const matchFlags = {
    [MessageChunkType.gps]: "hasTracking",
    [MessageChunkType.hr]: "hasHeartRate",
};

export interface ChunkMessage {
    matchId: string;
    payloadType: string;
    chunkCount: number;
    chunkNo: number;
    data: string;
}

interface ChunkMapRecord {
    messages: ChunkMessage[];
    timeout: NodeJS.Timeout;
    chunkCount: number;
}

export class TrackingChunkAssembler {
    static instance: TrackingChunkAssembler | undefined;
    gpsMap: Map<string, ChunkMapRecord> = new Map();
    hrMap: Map<string, ChunkMapRecord> = new Map();

    private constructor() {}

    static getInstance() {
        if (!TrackingChunkAssembler.instance) {
            TrackingChunkAssembler.instance = new TrackingChunkAssembler();
        }
        return TrackingChunkAssembler.instance;
    }

    private _getMap(
        dataType: MessageChunkType
    ): Map<string, ChunkMapRecord> | undefined {
        switch (dataType) {
            case MessageChunkType.gps:
                return this.gpsMap;
            case MessageChunkType.hr:
                return this.hrMap;
            default:
                console.error(
                    "Tried to assemble data chunk of unknown type: " + dataType
                );
        }
    }

    _cancelMessage(dataType: MessageChunkType, matchId: string) {
        var theMap = this._getMap(dataType);
        if (!theMap) {
            return;
        }
        const record = theMap.get(matchId);
        if (record && record.timeout) {
            clearTimeout(record.timeout);
            theMap.delete(matchId);
        }
    }

    async receivedChunk(dataType: MessageChunkType, message: ChunkMessage) {
        const self = this;
        const theMap = this._getMap(dataType);
        if (!theMap) {
            Sentry.captureMessage(
                `[TrackingChunkAssembler.receivedChunk()] Unknown type ${dataType}`
            );
            return;
        }
        const matchId = message.matchId.split("_")[0];
        if (!theMap.get(matchId)) {
            var timeout = setTimeout(function () {
                self._cancelMessage(dataType, matchId);
            }, TIMEOUT_DURATION);
            theMap.set(matchId, {
                messages: [],
                chunkCount: message.chunkCount,
                timeout: timeout,
            });
        }
        const record = theMap.get(matchId);
        if (!record) {
            return;
        }
        record.messages.push(message);
        if (record.messages.length === record.chunkCount) {
            try {
                await self.combineMessages(dataType, matchId);
            } catch (error) {
                Sentry.captureMessage(
                    `[TrackingChunkAssembler.receivedChunk()] failed to combine messages ${JSON.stringify(
                        error
                    )}`
                );
            }
        }
    }

    async addFitnessDataToSync(
        dataType: MessageChunkType,
        data: RawFitnessData
    ) {
        let processingType: FitnessDataType | null = null;
        switch (dataType) {
            case MessageChunkType.gps:
                processingType = FitnessDataType.GPS;
                break;
            case MessageChunkType.hr:
                processingType = FitnessDataType.HeartRate;
        }

        const username = store.getState().auth?.session?.user_id;
        if (processingType && username) {
            return await addRawFitnessData(data, username, processingType);
        }
    }

    async combineMessages(dataType: MessageChunkType, matchId: string) {
        const map = this._getMap(dataType);
        if (!map) {
            throw `No map for ${dataType}`;
        }
        const record = map.get(matchId);

        if (!record) {
            throw `Record missing for ${matchId}`;
        }

        const messages = record.messages;
        const data: string = messages
            .sort(function (a, b) {
                return a.chunkNo - b.chunkNo;
            })
            .reduce(function (acc, message) {
                return acc + message.data;
            }, "");

        const trackingData: RawFitnessData = {
            _id: matchId,
            format: record.messages[0].payloadType || "gzip;csv",
            data: data,
        };

        try {
            await this.addFitnessDataToSync(dataType, trackingData);
            this._cancelMessage(dataType, matchId);
        } catch (error) {
            throw `Cannot combine messages ${error}`;
        }
    }
}
