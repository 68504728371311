import { MatchEvent } from "../matchEvent";
import { EventName } from "../enums/eventName";

export class PenaltiesEvent extends MatchEvent {
    endTime?: number;

    constructor(
        timestamp: number,
        eventName: EventName,
        half: number,
        minuteOfPlay: number,
        endTime: number | undefined
    ) {
        super(timestamp, eventName, half, minuteOfPlay);
        this.endTime = endTime;
    }

    static fromJSON(json: any) {
        return new PenaltiesEvent(
            json.timestamp,
            json.eventName,
            json.half,
            json.minuteOfPlay,
            json.endTime
        );
    }
}
