import { resolveSettingsConflicts } from "refsix-core";
import { setSettings } from "../redux/actions/settings";
import { store } from "../redux/store";
import { getDatabaseService } from "./matchService";
import { Settings } from "../redux/models/refsixState";
import { IDatabaseService } from "./database/iDatabaseService";
import { SETTINGS_ID } from "./database/pouchUtils";
import * as Sentry from "@sentry/react";
import { ConfigPhone } from "refsix-js-models";
import { getCustomTeamOfficialCodes } from "../utils/Misconduct";
import _ from "lodash";

export const createSettingsIfDoesNotExist = async () => {
    const dbService = getDatabaseService() as IDatabaseService;

    const dbSettings = (await dbService.getDocById<Settings>(
        SETTINGS_ID
    )) as Settings & PouchDB.Core.GetMeta; // this will always exist if there was a conflict

    if (dbSettings) {
        await checkIfWeHaveAllSettings(dbSettings);
        return;
    }

    console.log("Creating default settings in the database");
    return updateSettings({ settings: ConfigPhone.defaultConfig() });
};

/**
 * Updates to local DB and dispatches to store
 */
export async function updateSettings(settings: Settings) {
    // this should never return undefined
    const dbService = getDatabaseService() as IDatabaseService;

    try {
        await dbService.upsertDoc({ _id: SETTINGS_ID, ...settings });

        store.dispatch(setSettings(settings));
    } catch (err) {
        if ((err as PouchDB.Core.Error).name === "conflict") {
            const dbSettings = (await dbService.getDocById<Settings>(
                SETTINGS_ID
            )) as Settings & PouchDB.Core.GetMeta; // this will always exist if there was a conflict

            const mergedSettings = resolveSettingsConflicts(
                settings,
                dbSettings
            );

            await dbService.getLocalDatabase().put(
                {
                    _id: SETTINGS_ID,
                    _rev: dbSettings._rev,
                    ...mergedSettings,
                },
                { force: true }
            );
            store.dispatch(setSettings(mergedSettings));
        } else {
            Sentry.captureMessage(
                `Error updating settings: ${JSON.stringify(err)}`
            );
            console.log("Error updating settings");
        }
    }
}

export const checkIfWeHaveAllSettings = async (settings: Settings) => {
    const defaultSettings = ConfigPhone.defaultConfig() as any;
    const updatedSettings = { ...defaultSettings, ...settings.settings };
    let updated: boolean = !_.isEqual(updatedSettings, settings.settings);

    if (!updatedSettings.customCodes) {
        updated = updateCustomCodes(updatedSettings) || updated;
    }

    if (updatedSettings.disableGPS) {
        updatedSettings.disableGPS = false;
        updated = true;
    }

    if (updated) {
        await updateSettings({ settings: updatedSettings });
    }
};

const updateCustomCodes = (settings: Settings) => {
    const customCodes = settings.settings.customCodes[0]?.codes;
    if (!customCodes) {
        return false;
    }

    if (!customCodes.some((code) => code.teamOfficial)) {
        const additionalCodes = getCustomTeamOfficialCodes();
        customCodes.push(...additionalCodes);
        return true;
    }

    return false;
};
