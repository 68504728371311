import { MatchEvent } from "../matchEvent";
import { EventName } from "../enums/eventName";
import { Player } from "../player";
import { Team } from "../team";

export class GoalEvent extends MatchEvent {
    team: Team;
    player?: Player;
    ownGoal: boolean;
    penalty: boolean;
    freeKick: boolean;

    constructor(
        timestamp: number,
        eventName: EventName,
        half: number,
        minuteOfPlay: number,
        team: Team,
        player: Player | undefined,
        ownGoal: boolean,
        penalty: boolean,
        freeKick: boolean
    ) {
        super(timestamp, eventName, half, minuteOfPlay);
        this.team = team;
        this.player = player;
        this.ownGoal = ownGoal;
        this.penalty = penalty;
        this.freeKick = freeKick;
    }

    static fromJSON(json: any) {
        return new GoalEvent(
            json.timestamp,
            json.eventName,
            json.half,
            json.minuteOfPlay,
            json.team,
            json.player,
            json.ownGoal,
            json.penalty,
            json.freeKick
        );
    }
}
