import { IonCard, IonContent, IonModal, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import GoogleMap from "google-map-react";
import { useState } from "react";
import { GpsCalibrationPoints, GpsPoint, MatchPhone } from "refsix-js-models";
import {
    cornersToCalibrationPoints,
    getCenterPoint,
    getSampleOfPoints,
} from "../../../../services/calibration/calibration";
import { HeaderComponent } from "../../../../components";
import {
    MapMarker,
    MapMarkerType,
} from "../../../../components/MapMarkerComponent";
import {
    DEFAULT_ZOOM,
    GOOGLE_MAPS_API_KEY,
    MAX_CORNER_MARKERS,
} from "./constants";
import "./calibrationModal.css";

interface CalibrationModalProps {
    display: boolean;
    gpsPoints: GpsPoint[];
    onCornersSubmited: (points: GpsCalibrationPoints) => any;
    onCloseModal: () => void;
}

export function CalibrationModal({
    display,
    gpsPoints,
    onCornersSubmited,
    onCloseModal,
}: CalibrationModalProps) {
    const { t } = useTranslation();

    const [cornerMarkers, setCornerMarkers] = useState<GpsPoint[]>([]);

    const centerPoint = getCenterPoint(gpsPoints);
    const pointsSample = getSampleOfPoints(gpsPoints);

    const handleMapClicked = ({ lat, lng }: GoogleMap.ClickEventValue) => {
        if (cornerMarkers.length < MAX_CORNER_MARKERS) {
            setCornerMarkers((prev) => [
                ...prev,
                { latitude: lat, longitude: lng },
            ]);
        }
    };
    const handleUndoClicked = () => {
        if (cornerMarkers.length > 0) {
            // remove last element
            setCornerMarkers((prev) => prev.slice(0, -1));
        }
    };
    const handleDoneClicked = () => {
        if (cornerMarkers.length === MAX_CORNER_MARKERS) {
            const calibrationPoints = cornersToCalibrationPoints([
                cornerMarkers[0],
                cornerMarkers[1],
                cornerMarkers[2],
            ]);
            onCornersSubmited(calibrationPoints);
        }
    };

    return (
        <IonModal
            isOpen={display}
            initialBreakpoint={1}
            onDidDismiss={() => onCloseModal()}
            showBackdrop
            onIonModalWillPresent={() => setCornerMarkers([])}
        >
            <IonContent>
                <HeaderComponent
                    showLeftSideBtn
                    onLeftBtnClicked={
                        cornerMarkers.length > 0
                            ? handleUndoClicked
                            : onCloseModal
                    }
                    leftSideBtnTxt={
                        cornerMarkers.length > 0
                            ? t("general.undo")
                            : t("general.cancel")
                    }
                    title={t("calibration.calibration.header")}
                    headerEndText={t("general.done")}
                    onClickEndButton={handleDoneClicked}
                    endBtnTestId="modalCalibrateDoneBtn"
                    endButtonDisabled={
                        cornerMarkers.length !== MAX_CORNER_MARKERS
                    }
                />

                <IonCard className="calibration-instructions">
                    <IonText>
                        {t("calibration.calibration.instructions")} [
                        {cornerMarkers.length}/{MAX_CORNER_MARKERS}]
                    </IonText>
                </IonCard>

                <div
                    style={{ height: "60%", width: "100%", marginTop: 20 }}
                    data-testid="pitchCorners"
                >
                    <GoogleMap
                        bootstrapURLKeys={{
                            key: GOOGLE_MAPS_API_KEY,
                        }}
                        defaultCenter={{
                            lat: centerPoint ? centerPoint.lat : 50.820833,
                            lng: centerPoint ? centerPoint.lon : -0.1375,
                        }}
                        options={{
                            zoomControl: false,
                            fullscreenControl: false,
                            rotateControl: false,
                            mapTypeId: "satellite",
                            tilt: 0,
                        }}
                        defaultZoom={DEFAULT_ZOOM}
                        onClick={handleMapClicked}
                    >
                        {cornerMarkers.map(({ latitude, longitude }, i) => (
                            <MapMarker
                                type={MapMarkerType.Corner}
                                lat={latitude}
                                lng={longitude}
                                key={i}
                            />
                        ))}
                        {pointsSample.map(({ latitude, longitude }, i) => (
                            <MapMarker
                                type={MapMarkerType.Generic}
                                lat={latitude}
                                lng={longitude}
                                key={i}
                            />
                        ))}
                    </GoogleMap>
                </div>
            </IonContent>
        </IonModal>
    );
}
