import { TemplateConfig } from "./templateConfig";
import { MatchPhone } from "./matchPhone";

export class Template {
    _id?: string;
    name: string;
    config: TemplateConfig;

    constructor(name: string, config: TemplateConfig, _id?: string) {
        this.name = name;
        this.config = config;
        this._id = _id;
    }

    static fromJSON(json: any) {
        let config = TemplateConfig.fromJSON(json.config);
        return new Template(json.name, config, json._id);
    }

    static fromMatch(
        name: string,
        match: MatchPhone | TemplateConfig,
        _id?: string,
        _rev?: string
    ) {
        let config = TemplateConfig.fromJSON(match);
        return new Template(name, config, _id);
    }
}
