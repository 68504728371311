import { FC, useState } from "react";
import "./pitchMap.css";
import { HalfEvent } from "refsix-js-models";
import { useTranslation } from "react-i18next";
import "./statsPeriodToggle.css";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";

interface StatsPeriodToggleInterface {
    playingSegments: HalfEvent[];
    periodsNo: string;

    onSegmentChange(index: number): void;
}

const StatsPeriodToggle: FC<StatsPeriodToggleInterface> = ({
    playingSegments,
    periodsNo,
    onSegmentChange,
}) => {
    const { t } = useTranslation();

    const playedExtraTime = playingSegments.reduce<boolean>(function (
        result: boolean,
        half: HalfEvent
    ) {
        return result || half.isExtraTime;
    },
    false);

    const [selectedButton, setSelectedButton] = useState<string>("0");

    // build all possible normal playing labels
    let periodLabels = [
        t("general.all"),
        t("results.heatmap.first"),
        t("results.heatmap.second"),
        t("results.heatmap.third"),
        t("results.heatmap.fourth"),
    ];

    // slice to get only the correct number of playing period labels for this match
    // this feels like a weird way to do it, but can't think of a shorter way to write it.
    periodLabels = periodLabels.slice(0, parseInt(periodsNo) + 1);

    if (playedExtraTime) {
        periodLabels.push(
            t("results.heatmap.extraTime1"),
            t("results.heatmap.extraTime2")
        );
    }

    const generateSegmentButtons = function () {
        return [{}, ...playingSegments].map((half, index) => {
            return (
                <IonSegmentButton value={"" + index} key={index}>
                    <IonLabel>{periodLabels[index]}</IonLabel>
                </IonSegmentButton>
            );
        });
    };

    return (
        <IonSegment
            onIonChange={(e) => {
                if (e.detail.value) {
                    setSelectedButton(e.detail.value as string);
                    onSegmentChange(parseInt(e.detail.value as string));
                }
            }}
            value={selectedButton}
            // hide if only one playing half (e.g. [All, H1])
            style={periodLabels.length < 2 ? { display: "none" } : {}}
        >
            {generateSegmentButtons()}
        </IonSegment>
    );
};

export default StatsPeriodToggle;
