import { UseList } from "./types";
import { useTranslation } from "react-i18next";
import _, { filter, get } from "lodash/fp";
import React, { useCallback, useMemo } from "react";
import { IonItem } from "@ionic/react";
import { useTeamOfficials } from "../index";

type Item = string | number | object;
type Selectors = string[];

export const useSearcher = <T extends Item>(list: T[]) => {
    return useCallback(
        (search: string, selectors?: Selectors) => {
            const searchWords = search.toLowerCase().split(/\s+/);
            const matches = list.filter((item) =>
                searchWords.every((word) =>
                    selectors
                        ? selectors.some((selector) =>
                              get(selector, item).toLowerCase().includes(word)
                          )
                        : item.toString().toLowerCase().includes(word)
                )
            );

            return matches;
        },
        [list]
    );
};

export const useTeamOfficialList: () => UseList<string[]> = () => {
    const { t } = useTranslation();

    const matchOfficials = useTeamOfficials().map((official) => official.name);

    const search = useSearcher(matchOfficials);

    const cb = useCallback(
        (query: string = "") => {
            if (query === "") {
                return matchOfficials;
            }
            const res = search(query);
            const removeExactMatch: (l: string[]) => string[] = filter(
                (x) => x !== query
            );
            return removeExactMatch(res);
        },
        [matchOfficials]
    );

    return useMemo(
        () => ({
            search: cb,
            renderer: teamOfficialsListRenderer(t),
        }),
        [cb, t]
    );
};
export const teamOfficialsListRenderer =
    (t: any) => (results: string[], save: (v: string) => void) => {
        const orderByValue = _.orderBy<string>((x) => x, ["asc"]);
        if (results.length === 0) {
            return <p>NONE</p>;
        }
        return orderByValue(results).map((result, index) => {
            return (
                <span
                    color="medium"
                    data-testid={`team-official-list-name-${result}`}
                    style={{
                        display: "inline-block",
                        backgroundColor: "#363636",
                        margin: "7px 5px 7px 0px",
                        color: "white",
                        padding: "10px",
                        borderRadius: "5px",
                    }}
                    key={`${index}-${result}`}
                    onClick={() => save(result)}
                >
                    {result}
                </span>
            );
        });
    };
