export function getLocaleFA(): LocaleFA {
    return {
        US: [
            "Alabama",
            "Alaska",
            "Arizona",
            "Arkansas",
            "Cal North",
            "Cal South",
            "Colorado",
            "Connecticut",
            "DC Metro",
            "Delaware",
            "East NY",
            "East PA",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusettes",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "North Carolina",
            "North Dakota",
            "Ohio North",
            "Ohio South",
            "Oklahoma",
            "Oregon",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas North",
            "Texas South",
            "Utah",
            "Vermont",
            "Washington",
            "West NY",
            "West PA",
            "West Virginia",
            "Wisconsin",
            "Wyoming",
        ],
        GB_ENG: [
            "Amateur Football Association",
            "Army FA",
            "Bedfordshire FA",
            "Berks and Bucks FA",
            "Birmingham FA",
            "Cambridgeshire FA",
            "Chesire FA",
            "Cornwall FA",
            "Cumberland FA",
            "Derbyshire FA",
            "Devon FA",
            "Dorset FA",
            "Durham FA",
            "East Riding FA",
            "English Schools FA",
            "Essex FA",
            "Glouctershire FA",
            "Guernsey FA",
            "Hampshire FA",
            "Herefordshire FA",
            "Hertfordshire FA",
            "Huntingdonshire FA",
            "Isle of Man FA",
            "Jersey FA",
            "Kent FA",
            "Lancashire FA",
            "Leicestershire & Rutland FA",
            "Lincolnshire FA",
            "Liverpool FA",
            "London FA",
            "Manchester FA",
            "Middlesex FA",
            "Norfolk FA",
            "North Riding FA",
            "Northamptonshire FA",
            "Northumberland FA",
            "Nottinghamshire FA",
            "Oxfordshrie FA",
            "RAF FA",
            "Royal Navy FA",
            "Sheffield & Hallamshire FA",
            "Shropshire FA",
            "Somerset FA",
            "Staffordshire FA",
            "Suffolk FA",
            "Surrey FA",
            "Sussex FA",
            "West Riding FA",
            "Westmorland FA",
            "Wiltshire FA",
            "Worcestershire FA",
        ],
        GB_SCO: [
            "Aberdeen",
            "Angus & Perthshire",
            "Aysrshire",
            "Edinburgh",
            "Fife",
            "Glasgow",
            "Lanarkshire",
            "Moray & Banff",
            "North of Scotland",
            "Renfrewshire",
            "South of Scotland",
            "Stirlingshire",
        ],
        IT: [
            "Abbiategrasso",
            "Acireale",
            "Adria",
            "Agrigento",
            "Agropoli",
            "Albano Laziale",
            "Albenga",
            "Alessandria",
            "Alghero",
            "Ancona",
            "Aosta",
            "Aprilia",
            "ArcoRiva",
            "Arezzo",
            "Ariano Irpino",
            "Ascoli Piceno",
            "Asti",
            "Avellino",
            "Avezzano",
            "Barcellona Pozzo Di Gotto",
            "Bari",
            "Barletta",
            "Bassano Del Grappa",
            "Battipaglia",
            "Belluno",
            "Benevento",
            "Bergamo",
            "Biella",
            "Bologna",
            "Bolzano",
            "Bra",
            "Brescia",
            "Brindisi",
            "Busto Arsizio",
            "Cagliari",
            "Caltanissetta",
            "Campobasso",
            "Carbonia",
            "Carrara",
            "Casale Monferrato",
            "Casarano",
            "Caserta",
            "Cassino",
            "Castelfranco Veneto",
            "Castellammare Di Stabia",
            "Catania",
            "Catanzaro",
            "Cesena",
            "Chiari",
            "Chiavari",
            "Chieti",
            "Chioggia",
            "Chivasso",
            "Ciampino",
            "Citta Di Castello",
            "Civitavecchia",
            "Collegno",
            "Como",
            "Conegliano",
            "Cormons",
            "Cosenza",
            "Crema",
            "Cremona",
            "Crotone",
            "Cuneo",
            "Empoli",
            "Enna",
            "Ercolano",
            "Este",
            "Faenza",
            "Fermo",
            "Ferrara",
            "Finale Emilia",
            "Florence",
            "Foggia",
            "Foligno",
            "Forli",
            "Formia",
            "Frattamaggiore",
            "Frosinone",
            "Gallarate",
            "Genoa",
            "Gorizia",
            "Grosseto",
            "Gubbio",
            "Imola",
            "Imperia",
            "Isernia",
            "Ivrea",
            "Jesi",
            "Lamezia Terme",
            "Lanciano",
            "LAquila",
            "La Spezia",
            "Latina",
            "Lecce",
            "Lecco",
            "Legnago",
            "Legnano",
            "Livorno",
            "Locri",
            "Lodi",
            "Lomellina",
            "Lovere",
            "LowFriuli",
            "Lucca",
            "Lugo Di Romagna",
            "Macerata",
            "Maniago",
            "Mantua",
            "Marsala",
            "Matera",
            "Merano",
            "Messina",
            "Mestre",
            "Milan",
            "Modena",
            "Molfetta",
            "Moliterno",
            "Monfalcone",
            "Monza",
            "Naples",
            "Nichelino",
            "Nocera Inferiore",
            "Nola",
            "Novara",
            "Novi Ligure",
            "Nuoro",
            "Olbia",
            "Oristano",
            "Orvieto",
            "OstiaLido",
            "Ozieri",
            "Padua",
            "Palermo",
            "Paola",
            "Parma",
            "Pavia",
            "Perugia",
            "Pesaro",
            "Pescara",
            "Piacenza",
            "Pinerolo",
            "Piombino",
            "Pisa",
            "Pistoia",
            "Policoro",
            "Pontedera",
            "Pordenone",
            "Portogruaro",
            "Potenza",
            "Prato",
            "Ragusa",
            "Ravenna",
            "Reggio Calabria",
            "Reggio Emilia",
            "Rieti",
            "Rimini",
            "Rome1",
            "Rome2",
            "Rossano",
            "Rovereto",
            "Rovigo",
            "Sala Consilina",
            "Salerno",
            "SanBenedetto Del Tronto",
            "SanDona Di Piave",
            "Sapri",
            "Saronno",
            "Sassari",
            "Savona",
            "Schio",
            "Seregno",
            "Sesto San Giovanni",
            "Siena",
            "Sondrio",
            "Soverato",
            "Sulmona",
            "Syracuse",
            "Taranto",
            "Taurianova",
            "Teramo",
            "Termoli",
            "Terni",
            "Tivoli",
            "Tolmezzo",
            "Torre Annunziata",
            "Torre Del Greco",
            "Tortoli",
            "Trapani",
            "Trento",
            "Treviglio",
            "Treviso",
            "Trieste",
            "Turin",
            "Udine",
            "Valdarno",
            "Varese",
            "Vasto",
            "Vco",
            "Venice",
            "Venosa",
            "Vercelli",
            "Verona",
            "Viareggio",
            "Vibo Valentia",
            "Vicenza",
            "Viterbo",
            "Voghera",
        ],
    };
}

export interface LocaleFA {
    [countryCode: string]: string[];
}
