import {
    FitnessDataProcessing,
    GpsProcessingService,
    HeartRateProcessingService,
} from "refsix-core";
import { RawFitnessData } from "./types";
import { FitnessData, FitnessDataType, HalfEvent } from "refsix-js-models";

export interface FitnessDataSyncConfig {
    urlPath: string;
    sortFn: (data: FitnessData) => FitnessData;
    filterFn: (
        data: FitnessData,
        matchTimings: HalfEvent[]
    ) => FitnessData | null;
    processFn: (data: RawFitnessData) => FitnessData;
}

/**
 * Config to be accessed using `FitnessDataType` as key, e.g. `config[dataType]`
 */
export default {
    [FitnessDataType.GPS]: {
        urlPath: "tracking",
        sortFn: new GpsProcessingService().sortGpsData,
        filterFn: (() => {
            // TODO: refactor service to be able to import individual function
            const service = new GpsProcessingService();
            return service.filterInvalidPoints.bind(service);
        })(),
        processFn: (() => {
            // TODO: refactor service to be able to import individual function
            const service = new FitnessDataProcessing();
            return service.processWatchGPS.bind(service);
        })(),
    } as FitnessDataSyncConfig,
    [FitnessDataType.HeartRate]: {
        urlPath: "heart-rate",
        sortFn: new HeartRateProcessingService().sortHeartRate,
        filterFn: (() => {
            // TODO: refactor service to be able to import individual function
            const service = new HeartRateProcessingService();
            return service.filterInvalidHrData.bind(service);
        })(),
        processFn: (() => {
            // TODO: refactor service to be able to import individual function
            const service = new FitnessDataProcessing();
            return service.processWatchHeartRate.bind(service);
        })(),
    } as FitnessDataSyncConfig,
};
