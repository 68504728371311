import { FC } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { metersToYards } from "refsix-core";
import { RootState } from "../redux/store";

interface DistanceSmallProps {
    distanceInMeters: number;
}

export function distanceSmallString(
    distanceInMeters: number,
    useImperial: boolean,
    t: TFunction,
    decimalPlaces: number
) {
    const value = useImperial
        ? metersToYards(distanceInMeters)
        : distanceInMeters;
    const units = useImperial
        ? t("general.distanceUnit.small.imperial")
        : t("general.distanceUnit.small.metric");

    return `${value.toFixed(decimalPlaces)} ${units}`;
}

const DistanceSmall: FC<DistanceSmallProps> = ({ distanceInMeters }) => {
    const { t } = useTranslation();
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );

    return <>{distanceSmallString(distanceInMeters, useImperial, t, 0)}</>;
};

export default DistanceSmall;
