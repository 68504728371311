import {
    IonButton,
    IonDatetime,
    IonIcon,
    IonItem,
    IonText,
} from "@ionic/react";
import { caretBack, caretForward } from "ionicons/icons";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { MatchPhone } from "refsix-js-models";
import {
    createMatchOrAvailability,
    filterMatchesBasedOnSelectedDate,
} from "../../services/matchService";
import "./calendar.css";
import _ from "lodash";
import { DatetimeHighlight } from "@ionic/core";
import { MatchOrAvailability } from "../../pages/tabs/fixtures";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { filterAvailabilityByDate } from "../../services/integrations/refassist/refAssistService";

interface CalendarProps {
    allFixtures: MatchPhone[];
    setSelectedDate: string;
    setUserSelectedMonth: (date: string) => void;
    dateTimeRef: React.RefObject<HTMLIonDatetimeElement>;
    setMatchOrAvailability(matchOrAvailability: MatchOrAvailability[]): void;
}

export const Calendar: FC<CalendarProps> = ({
    allFixtures,
    setSelectedDate: selectedDate,
    setUserSelectedMonth: setSelectedDate,
    dateTimeRef,
    setMatchOrAvailability,
}) => {
    const allClosedDates = useSelector(
        (state: RootState) => state.matches.closedDates
    );
    const allOpenDates = useSelector(
        (state: RootState) => state.matches.openDates
    );
    const getHighlightedDates = (): DatetimeHighlight[] => {
        const dateTimeHighlight: DatetimeHighlight[] = [];

        const todayDate = moment().format("YYYY-MM-DD");
        const matchDates = _.uniq(
            allFixtures.map((match) => {
                return moment(match.date).format("YYYY-MM-DD");
            })
        );

        const leaveDates = _.uniq(
            allClosedDates.map((leave) => {
                return moment(leave.day, "DD/MM/YYYY").format("YYYY-MM-DD");
            })
        );

        const openDates = _.uniq(
            allOpenDates.map((leave) => {
                return moment(leave.day, "DD/MM/YYYY").format("YYYY-MM-DD");
            })
        );

        matchDates.map((matchDate) => {
            dateTimeHighlight.push({
                date: matchDate,
                textColor: "black",
                backgroundColor:
                    selectedDate === todayDate && selectedDate === matchDate
                        ? "#d5e830"
                        : "#d5e83014",
            });
        });

        leaveDates.map((leaveDate) => {
            dateTimeHighlight.push({
                date: leaveDate,
                textColor: "red",
                backgroundColor: "transparent",
            });
        });

        openDates.map((openDate) => {
            dateTimeHighlight.push({
                date: openDate,
                textColor: "yellow",
                backgroundColor: "transparent",
            });
        });

        return dateTimeHighlight;
    };

    const [highlightedDates, setHighlightedDates] = useState<
        DatetimeHighlight[]
    >([]);

    useEffect(() => {
        setHighlightedDates(getHighlightedDates());
    }, [allFixtures, allClosedDates, allOpenDates]);

    const handleNextButtonClick = () => {
        const currentMonth = moment(selectedDate);
        const nextMonth = currentMonth.add(1, "month");
        const isCurrentMonth = moment().isSame(currentMonth, "month");

        if (isCurrentMonth) {
            const currentDay = moment().format("YYYY-MM-DD");
            setSelectedDate(currentDay);
            reset(currentDay);
        } else {
            setSelectedDate(nextMonth.startOf("month").format("YYYY-MM-DD"));
            reset(nextMonth.startOf("month").format("YYYY-MM-DD"));
        }
    };

    const handlePrevButtonClick = () => {
        const currentMonth = moment(selectedDate);
        const prevMonth = currentMonth.subtract(1, "month");
        const isCurrentMonth = moment().isSame(currentMonth, "month");

        if (isCurrentMonth) {
            const currentDay = moment().format("YYYY-MM-DD");
            setSelectedDate(currentDay);
            reset(currentDay);
        } else {
            setSelectedDate(prevMonth.startOf("month").format("YYYY-MM-DD"));
            reset(prevMonth.startOf("month").format("YYYY-MM-DD"));
        }
    };

    const reset = (date: string) => {
        dateTimeRef.current?.reset(date);
    };

    return (
        <>
            <IonItem color="transparent" lines="none">
                <IonButton
                    slot="start"
                    onClick={() => handlePrevButtonClick()}
                    color="transparent"
                >
                    <IonIcon icon={caretBack} style={{ color: "white" }} />
                </IonButton>
                <div className="team-name-color-container-right">
                    <IonText data-testid="date-calendar-month-string">
                        {moment(selectedDate).format("MMMM YYYY")}
                    </IonText>
                </div>
                <IonButton
                    data-testid="date-calendar-next-button"
                    slot="end"
                    onClick={() => handleNextButtonClick()}
                    color="transparent"
                >
                    <IonIcon icon={caretForward} style={{ color: "white" }} />
                </IonButton>
            </IonItem>
            <IonDatetime
                data-testid="date-calendar-view"
                className="datetime-calendar"
                ref={dateTimeRef}
                size="cover"
                value={selectedDate}
                presentation="date"
                onIonChange={(e) => {
                    const { value } = e.detail;
                    setMatchOrAvailability([]);
                    if (value) {
                        setSelectedDate(value as string);
                        setHighlightedDates(getHighlightedDates());

                        const matches = filterMatchesBasedOnSelectedDate(
                            allFixtures,
                            value
                        );

                        setMatchOrAvailability(
                            createMatchOrAvailability(
                                matches,
                                filterAvailabilityByDate(
                                    value as string,
                                    allClosedDates
                                ),
                                filterAvailabilityByDate(
                                    value as string,
                                    allOpenDates
                                )
                            )
                        );
                    }
                }}
                highlightedDates={highlightedDates}
            ></IonDatetime>
        </>
    );
};
