import React, { FC, useEffect, useRef, useState } from "react";
import {
    useDbUpdatedAt,
    useIsLanguageEnglish,
    useIsOnline,
    useProfile,
    useSessionExpired,
    useSessionExpiredModalDismissed,
    useUsername,
} from "./hooks";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar,
    useIonLoading,
} from "@ionic/react";
import { setSessionExpired, userLoggedIn } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { setSessionExpiredModalDismissed } from "../redux/actions/appStatus";
import css from "./SessionExpiredBanner.module.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { forgotPassword, login } from "../services/authService";
import { LoginSession } from "../redux/models/loginSession";
import { trackEvent } from "../services/analytics/analyticsService";

interface SessionExpiredModalProps {
    open: boolean;
}
export const SessionExpiredModal: FC<SessionExpiredModalProps> = ({ open }) => {
    const { t } = useTranslation();
    const isEnglish = useIsLanguageEnglish();
    const modal = useRef<HTMLIonModalElement>(null);
    const input = useRef<HTMLIonInputElement>(null);
    const dispatch = useDispatch();
    const isSessionExpired = useSessionExpired();
    const profile = useProfile();
    const username = useUsername();
    const dbUpdatedAt = useDbUpdatedAt();
    const dismiss = () => {
        modal.current?.dismiss();
        dispatch(setSessionExpiredModalDismissed(true));
    };
    useEffect(() => {
        if (!isSessionExpired) {
            modal.current?.dismiss();
        }
    }, [isSessionExpired, modal]);
    const [presentLoading, dismissLoading] = useIonLoading();

    const [passwordReset, setPasswordReset] = useState(false);
    const [hasError, setHasError] = useState(false);

    const handleResetPassword = async () => {
        await presentLoading();
        const response = await forgotPassword(profile!.email);
        if (response && response.status === 200) {
            setPasswordReset(true);
            await dismissLoading();
        } else {
            console.log(response.data);
        }
    };
    // useEffect(() => {
    //     setHasError(false);
    // }, [input.current?.value]);
    const handleRelogin = () => {
        const fn = async () => {
            try {
                const password = input.current?.value?.toString() || "";
                const resp = await login(username || "", password);

                const data = resp.data as any;
                const session = data as LoginSession;

                dispatch(userLoggedIn(true, session));
                dispatch(setSessionExpired(false));
                setTimeout(() => window.location.reload(), 2000);
                setHasError(false);

                trackEvent("Reauthenticate", {
                    label: "expiredSession",
                    dbUpdatedAt,
                });
            } catch (e) {
                dispatch(setSessionExpired(true));
                setHasError(true);
            }
        };
        fn();
    };
    return (
        <IonModal ref={modal} isOpen={open}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={dismiss}>
                            {t("general.cancel")}
                        </IonButton>
                    </IonButtons>
                    <IonTitle>
                        {t(
                            isEnglish
                                ? "login.sessionExpired.reenterPassword"
                                : "login.login.signIn"
                        )}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={handleRelogin}>
                            {t("general.done")}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            {passwordReset ? (
                <IonItem>
                    <IonText>{t("login.ForgotPassword.confirmation")}</IonText>
                </IonItem>
            ) : null}
            <IonContent className="ion-padding">
                <p style={{ marginBottom: 20 }} hidden={!isEnglish}>
                    {t("login.sessionExpired.description", {
                        date: dbUpdatedAt
                            ? moment(dbUpdatedAt).format("DD MMM YYYY")
                            : "?",
                    })}
                </p>

                <IonItem lines="none">
                    <IonInput
                        label={t(
                            isEnglish
                                ? "login.sessionExpired.reenterPassword"
                                : "login.login.password"
                        )}
                        labelPlacement="stacked"
                        ref={input}
                        type="password"
                        placeholder={t("login.login.password")}
                        onInput={() => setHasError(false)}
                    />
                </IonItem>

                {hasError && (
                    <p className={"error-message"} style={{ padding: 10 }}>
                        {t("login.login.failure.description")}
                    </p>
                )}

                <IonButton
                    expand="block"
                    onClick={handleRelogin}
                    style={{ marginTop: 20 }}
                >
                    {t("login.login.signIn")}
                </IonButton>

                <div className="forgot-password-wrapper">
                    {!passwordReset ? (
                        <IonText
                            data-testid="forgot-password"
                            className="forgot-password-text"
                            onClick={handleResetPassword}
                        >
                            {t("login.login.forgotPassword").toUpperCase()}?
                        </IonText>
                    ) : null}
                </div>
            </IonContent>
        </IonModal>
    );
};
export const SessionExpiredBanner: FC = ({ children }) => {
    const isSessionExpired = useSessionExpired();
    const sessionExpiredModalDismissed = useSessionExpiredModalDismissed();
    const dispatch = useDispatch();
    const dbUpdatedAt = useDbUpdatedAt();
    const online = useIsOnline();
    const { t } = useTranslation();
    const isEnglish = useIsLanguageEnglish();
    const relogin = () => {
        dispatch(setSessionExpiredModalDismissed(false));
    };
    return (
        <>
            {online && isSessionExpired ? (
                <IonCard className={css.card} onClick={relogin}>
                    <IonCardContent
                        className={css.container}
                        style={{ padding: 10 }}
                    >
                        <div className={css.content} style={{ fontSize: 14 }}>
                            {isEnglish
                                ? t("login.sessionExpired.description", {
                                      date: dbUpdatedAt
                                          ? moment(dbUpdatedAt).format(
                                                "DD MMM YYYY"
                                            )
                                          : "?",
                                  })
                                : t("general.errorTryAgain")}
                        </div>
                        <div className={css.vr}>
                            <div className={css["vertical-hr"]}></div>
                        </div>
                        <IonButton
                            className={`${css.button} ion-text-wrap`}
                            onClick={relogin}
                            size="default"
                            fill="clear"
                        >
                            {t(
                                isEnglish
                                    ? "login.sessionExpired.restoreSync"
                                    : "login.login.signIn"
                            )}
                        </IonButton>
                    </IonCardContent>
                </IonCard>
            ) : (
                children
            )}
            <SessionExpiredModal
                open={
                    !sessionExpiredModalDismissed && isSessionExpired && online
                }
            />
        </>
    );
};
