import { ADD_PROFILE, SET_PROFILES } from "../actions/types";
import { UserProfiles } from "../models/refsixState";
import { addUserProfileAction } from "../actions/addUserProfile";

const initialState = new UserProfiles();

function userProfilesReducer(state: UserProfiles = initialState, action: any) {
    state = state || initialState;
    state.profiles = state.profiles || {};

    switch (action.type) {
        case SET_PROFILES:
            return { ...state, profiles: action.userProfiles };
        case ADD_PROFILE:
            const addProfile = action as addUserProfileAction;
            const username = addProfile.userProfile.username;
            const newProfiles = {
                ...state.profiles,
                [username]: action.userProfile,
            };
            return { ...state, profiles: newProfiles };
        default:
            return state;
    }
}

export default userProfilesReducer;
