import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IonText } from "@ionic/react";
import { AggregatedStats } from "refsix-js-models";
import "./reviewSharedStyles.css";
import "./distanceTotalReview.css";
import shirt from "../../assets/images/review/shirt.png";
import distanceIcon from "../../assets/images/review/distance.png";
import Distance, { DistanceUnit } from "../DistanceComponent";
import { ProTeaser, TeaserStyle } from "../ProTeaser";
import { hasFeatureAccess } from "../../services/subscriptionService";
import Stat, {
    Col,
    Content,
    Footer,
    Header,
    Icon,
    Info,
    MainHeader,
    Row,
    StatsContainer,
} from "./reviewCard";

interface DistanceTotalReviewProps {
    aggregatedStats: AggregatedStats;
}

const DistanceTotalReview: FC<DistanceTotalReviewProps> = ({
    aggregatedStats,
}) => {
    const { t } = useTranslation();
    const statsAccess = hasFeatureAccess("stats");
    /* convert distance in metres into number of marathons */
    const marathonsRaw = aggregatedStats.distanceTotal / 42195;
    // if less than 1 marathon show 1 decimal place, otherwise round to nearest whole number
    const marathons =
        marathonsRaw > 1
            ? Math.round(aggregatedStats.distanceTotal / 42195)
            : marathonsRaw.toFixed(1);

    return (
        <div className="distance-total-review review">
            <ProTeaser
                showTeaser={!statsAccess}
                teaserName="review-distance-marathons"
                isRefSixEligible={false}
                teaserStyle={TeaserStyle.button}
            >
                <>
                    {/* total distance*/}
                    <StatsContainer>
                        <Stat className={"average-distance"}>
                            <Row>
                                <MainHeader>
                                    <div>
                                        <Icon className={"start"} />
                                        <span>
                                            {t(
                                                "upgrade.proFeaturesContent.tableFeature3"
                                            )}{" "}
                                        </span>
                                        {/*<img*/}
                                        {/*    src={distanceIcon}*/}
                                        {/*    alt=""*/}
                                        {/*    width="50"*/}
                                        {/*/>*/}
                                    </div>
                                </MainHeader>
                                <Content className="distance-value">
                                    {statsAccess ? (
                                        <>
                                            <span style={{ fontWeight: 900 }}>
                                                <Distance
                                                    distanceInMeters={
                                                        aggregatedStats.distanceTotal
                                                    }
                                                    showUnit={false}
                                                    decimalPlaces={0}
                                                ></Distance>
                                            </span>
                                            <span className="distance-unit">
                                                <DistanceUnit></DistanceUnit>
                                            </span>
                                        </>
                                    ) : (
                                        <span>
                                            ? <DistanceUnit></DistanceUnit>
                                        </span>
                                    )}
                                </Content>
                            </Row>
                        </Stat>
                        <div
                            className="info"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "relative",
                                marginTop: "50px",
                            }}
                        >
                            <div className="content" style={{}}>
                                <span>{t("review.equivalentTo")}&nbsp;</span>
                                <span style={{ fontWeight: 900 }}>
                                    {statsAccess ? marathons : "?"}&nbsp;
                                </span>
                                <span
                                    style={{
                                        textTransform: "lowercase",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {t("review.marathons")}
                                </span>
                            </div>
                            {/* Running man */}
                            <div className={"icon"}>
                                <svg
                                    viewBox="0 0 187 202"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M120.06 55.4929L120.4 55.6252L120.687 55.849L140.609 71.3458L168.101 47.7262L169.998 46.0971L171.627 47.9934L181.628 59.6342L183.257 61.5305L181.361 63.1597L142.828 96.2642L141.282 97.5923L139.67 96.3458L113.149 75.8423L94.2416 114.626L122.217 135.316L123.169 136.02L123.228 137.202L126.086 194.864L126.21 197.361L123.713 197.484L103.276 198.497L100.779 198.621L100.656 196.124L98.337 149.322L76.8586 133.439L71.3032 166.574L70.9794 168.506L69.0268 168.654L7.73715 173.306L5.24425 173.495L5.05511 171.002L3.5071 150.599L3.31796 148.106L5.81075 147.917L49.1537 144.628L53.8689 115.479L53.9063 115.248L53.986 115.028L73.3943 61.3926H48.6865L31.3779 100.541L30.367 102.828L28.0805 101.817L14.0451 95.6119L11.7586 94.6011L12.7694 92.3146L34.7761 42.5348L35.4344 41.0457H37.0626H82.5105H82.9804L83.4182 41.2162L120.06 55.4929Z"
                                        fill="white"
                                        stroke="#22231B"
                                        stroke-width="5"
                                    />
                                    <path
                                        d="M111.777 48.9018C98.9632 48.9018 88.5758 38.5144 88.5758 25.7009C88.5758 12.8874 98.9632 2.5 111.777 2.5C124.59 2.5 134.978 12.8874 134.978 25.7009C134.978 38.5144 124.59 48.9018 111.777 48.9018Z"
                                        fill="white"
                                        stroke="#22231B"
                                        stroke-width="5"
                                    />
                                </svg>
                            </div>

                            <div className={"lines"}></div>
                        </div>
                    </StatsContainer>
                </>
            </ProTeaser>
        </div>
    );
};

export default DistanceTotalReview;
