import countries from "i18n-iso-countries";

export function getLocale() {
    const experimentParamName = `locale`;
    const variantOverride = new URLSearchParams(window.location.search).get(
        experimentParamName
    );
    if (variantOverride) {
        console.log(
            `Locale overridden to ${experimentParamName}=${variantOverride}`
        );
        return variantOverride;
    }

    if (navigator.languages != undefined) return navigator.languages[0];
    return navigator.language;
}

export function getLanguageCode() {
    if (getLocale()) {
        return getLocale().split("-")[0];
    }

    return "";
}

export function isCountryCodePresent() {
    return getLocale().indexOf("-") !== -1;
}

export function getCountryCode(code?: string) {
    const parts = code ? code.split("-") : getLocale().split("-");
    if (parts.length > 1) {
        return parts[parts.length - 1];
    } else {
        // Dutch in chrome is reported as 'nl' not 'nl-NL'. Probably the same elsewhere.
        const languageCode = parts[0].toUpperCase();
        if (countries.isValid(languageCode)) {
            return languageCode;
        }
        return undefined;
    }
}

// TODO: we only need to load the correct lanaguage
export function setCountriesLocale() {
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/ja.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nb.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
}
