import { IonAlert, IonicSafeString } from "@ionic/react";
import { FC } from "react";
import "./AlertComponent.css";

interface AlertInterface {
    showAlert: boolean;
    onDonePressed: () => void;
    buttonDone: string;
    buttonAltText?: string;
    dismissAlert?: () => void;
    title?: string;
    subTitle?: string;
    description?: string | IonicSafeString;
    showAlternativeButton?: boolean;
    onButtonAltClicked?: () => void;
    confirmButtonClassName?: string;
    testId: string;
}

const AlertComponent: FC<AlertInterface> = ({
    showAlert,
    title,
    buttonAltText,
    buttonDone,
    description,
    dismissAlert,
    onButtonAltClicked,
    onDonePressed,
    showAlternativeButton,
    subTitle,
    confirmButtonClassName,
    testId,
}) => {
    return (
        <IonAlert
            data-testid={testId}
            id={`alert-${testId}`}
            isOpen={showAlert}
            onDidDismiss={dismissAlert}
            header={title}
            subHeader={subTitle}
            message={description}
            buttons={
                showAlternativeButton
                    ? [
                          {
                              text: buttonAltText || "",
                              handler: onButtonAltClicked,
                              cssClass: "alert-button-cancel",
                          },
                          {
                              text: buttonDone,
                              handler: onDonePressed,
                              cssClass: "alert-button-done",
                          },
                      ]
                    : [
                          {
                              text: buttonDone,
                              handler: onDonePressed,
                              cssClass: `alert-button-done ${confirmButtonClassName}`,
                          },
                      ]
            }
        />
    );
};

AlertComponent.defaultProps = {
    title: "",
    subTitle: "",
    description: "",
    showAlternativeButton: false,
    buttonAltText: "",
    buttonDone: "help.OnboardingVideo.controls.ok",
};

export default AlertComponent;
