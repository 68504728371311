import {
    IonContent,
    IonItem,
    IonLoading,
    IonModal,
    IonPage,
    IonSegment,
    IonSegmentButton,
    useIonRouter,
} from "@ionic/react";
import { filter } from "ionicons/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HeaderComponent } from "../../components";
import { RootState } from "../../redux/store";
import { filterResults, getFilteredStats } from "../../services/matchService";
import { MatchType } from "../filters/enums";
import Filters from "../filters/filters";
import { createDefaultFiltersSetting } from "../filters/filtersHelper";
import { Misconduct } from "./trendsSegments/misconduct/misconduct";
import Speed from "./trendsSegments/speed";
import Matches from "./trendsSegments/matches";
import { Distance } from "./trendsSegments/distance";
import Finance from "./trendsSegments/finance";
import { Positioning } from "./trendsSegments/positioning";
import HeartRate from "./trendsSegments/heartRate";
import "./trends.css";
import { useSubscription } from "../../components/hooks";
import { hasFeatureAccess } from "../../services/subscriptionService";
import { UpgradeBanner } from "../../components/upgrade/UpgradeBanner";
import { routes } from "../../route/constants";
import { useHistory } from "react-router";
import { UseBackButtonHandler } from "../../components/hooks/useBackButtonHandler";
import { useParams } from "react-router";

enum Segment {
    misconduct = "misconduct",
    distance = "distance",
    positioning = "positioning",
    speed = "speed",
    heartRate = "heart-rate",
    finance = "finance",
    filters = "filters",
    matches = "matches",
}

const Trends: React.FC = () => {
    const { t } = useTranslation();

    const filters = useSelector(
        (state: RootState) =>
            state.filters.filterResults ||
            createDefaultFiltersSetting(MatchType.Result)
    );
    const segmentEl = useRef<HTMLIonSegmentElement>(null);

    const { page } = useParams<{ page: string }>();
    const route = useIonRouter();
    const subscription = useSubscription();
    const history = useHistory();
    const allMatches = useSelector((state: RootState) => state.matches.matches);
    const matches = useMemo(() => {
        return filterResults(allMatches);
    }, [allMatches]);

    const hasStatsAccess = hasFeatureAccess("stats");

    const isValidSegment = (value: string): value is Segment => {
        return Object.values(Segment).includes(value as Segment);
    };

    const [selectedSegment, setSelectedSegment] = useState<Segment>(
        isValidSegment(page) ? page : Segment.matches
    );
    const [filtersOpen, setFiltersOpen] = useState(false);

    const handleCloseModal = () => {
        setFiltersOpen(false);
        window.history.replaceState(
            {},
            "",
            `${routes.refsixTrends}/${selectedSegment}`
        );
    };

    const aggregatedStats = useMemo(() => {
        return getFilteredStats(filters, matches);
    }, [matches, filters]);

    useEffect(() => {
        if (window.location.search === "?showFilters=true") {
            setFiltersOpen(true);
        } else if (!page) {
            setSelectedSegment(Segment.matches);
            window.history.replaceState(
                {},
                "",
                routes.refsixTrends + "/" + Segment.matches
            );
        } else if (isValidSegment(page)) {
            setSelectedSegment(page);
        } else {
            setSelectedSegment(Segment.matches);
            route.push(`${routes.refsixTrends}/${page}`, "none", "replace");
        }
    }, [page]);

    const handleSegmentChange = (e: CustomEvent) => {
        const newSelectedSegment = e.detail.value as Segment;
        setSelectedSegment(newSelectedSegment);
        // update the address bar without redoing the react history and page animations.
        window.history.replaceState(
            null,
            "",
            `${routes.refsixTrends}/${newSelectedSegment}`
        );
    };

    const handleBackButton = () => {
        if (
            !window.location.search.includes("?showFilters=true") &&
            window.location.pathname === routes.refsixTrends
        ) {
            handleCloseModal();
        }
    };

    useEffect(() => {
        if (segmentEl && segmentEl.current && isValidSegment(page)) {
            setTimeout(() => {
                // get the child element of the segment button with matching value
                if (!segmentEl || !segmentEl.current) return;
                const segmentButton = segmentEl.current.querySelector(
                    `[value=${page}]`
                );
                // scroll segment into center of the view
                segmentButton?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "center",
                });
            }, 500);
        }
    }, [page, segmentEl, segmentEl.current]);

    return (
        <>
            <IonLoading isOpen={matches.length > 0 && !aggregatedStats} />
            <IonPage>
                <HeaderComponent
                    titleTestId="trends-heading"
                    title={t("general.trends")}
                    endBtnIconName={filter}
                    onClickEndButton={() => {
                        setFiltersOpen(true);
                        window.history.replaceState(
                            {},
                            "",
                            `${routes.refsixTrends}/${selectedSegment}?showFilters=true`
                        );
                    }}
                    endBtnTestId={"trendsFilterBtn"}
                    showProBadge={subscription?.isPro}
                    showPlusBadge={subscription?.isPlus}
                />
                <IonContent className="trends-page">
                    <UseBackButtonHandler handlerFunction={handleBackButton} />
                    <IonItem className="item-segments-container">
                        <IonSegment
                            ref={segmentEl}
                            scrollable={true}
                            value={selectedSegment}
                            className="fixture-segments segment"
                            mode="ios"
                            onIonChange={(e) => handleSegmentChange(e)}
                            onClick={(e) => {
                                // scroll segment into center of the view on click
                                if (e.target) {
                                    // @ts-ignore
                                    e.target.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end",
                                        inline: "center",
                                    });
                                }
                            }}
                        >
                            <IonSegmentButton
                                data-testid="matches-trend-segment"
                                value={Segment.matches}
                                className="segment-button"
                            >
                                {t("stats.stats.matches")}
                            </IonSegmentButton>
                            <IonSegmentButton
                                data-testid="misconduct-trend-segment"
                                value={Segment.misconduct}
                                className="segment-button"
                            >
                                {t("fixture.FixtureSummaryStats.misconduct")}
                            </IonSegmentButton>
                            <IonSegmentButton
                                data-testid="distance-trend-segment"
                                value={Segment.distance}
                                className="segment-button"
                            >
                                {t("fixture.FixtureSummaryStats.distance")}
                            </IonSegmentButton>
                            <IonSegmentButton
                                data-testid="positioning-trend-segment"
                                value={Segment.positioning}
                                className="segment-button"
                            >
                                {t("stats.stats.Positioning.positioning")}
                            </IonSegmentButton>
                            <IonSegmentButton
                                value={Segment.speed}
                                className="segment-button"
                                data-testid="speed-trend-segment"
                            >
                                {t("general.speed")}
                            </IonSegmentButton>
                            <IonSegmentButton
                                data-testid="heart-rate-trend-segment"
                                value={Segment.heartRate}
                                className="segment-button"
                            >
                                {t("general.heartRate")}
                            </IonSegmentButton>
                            <IonSegmentButton
                                value={Segment.finance}
                                className="segment-button"
                                data-testid="finance-trend-segment"
                            >
                                {t("stats.stats.finance")}
                            </IonSegmentButton>
                        </IonSegment>
                    </IonItem>

                    <UpgradeBanner />

                    {selectedSegment === Segment.speed && (
                        <Speed
                            matches={matches}
                            hasStatsAccess={hasStatsAccess || false}
                            aggregatedStats={aggregatedStats}
                            filters={filters}
                        />
                    )}

                    {selectedSegment === Segment.matches && (
                        <Matches
                            matches={matches}
                            hasStatsAccess={hasStatsAccess || false}
                            filters={filters}
                            aggregatedStats={aggregatedStats}
                        />
                    )}

                    {selectedSegment === Segment.distance && (
                        <Distance
                            matches={matches}
                            hasStatsAccess={hasStatsAccess || false}
                            aggregatedStats={aggregatedStats}
                        />
                    )}
                    {selectedSegment === Segment.misconduct && (
                        <Misconduct
                            matches={matches}
                            hasStatsAccess={hasStatsAccess || false}
                            aggregatedStats={aggregatedStats}
                            filters={filters}
                        />
                    )}

                    {selectedSegment === Segment.finance && (
                        <Finance
                            aggregatedStats={aggregatedStats}
                            hasStatsAccess={hasStatsAccess || false}
                        />
                    )}

                    {selectedSegment === Segment.positioning && (
                        <Positioning
                            matches={matches}
                            hasStatsAccess={hasStatsAccess || false}
                        />
                    )}

                    {selectedSegment === Segment.heartRate && (
                        <HeartRate
                            matches={matches}
                            aggregatedStats={aggregatedStats}
                            filters={filters}
                            hasStatsAccess={hasStatsAccess || false}
                        />
                    )}

                    {filtersOpen && (
                        <IonModal
                            isOpen={filtersOpen}
                            onDidDismiss={() => setFiltersOpen(false)}
                            initialBreakpoint={1}
                            showBackdrop
                        >
                            <Filters
                                matches={matches}
                                matchType={MatchType.Trends}
                                closeModal={() => handleCloseModal()}
                            />
                        </IonModal>
                    )}
                </IonContent>
            </IonPage>
        </>
    );
};

export default Trends;
