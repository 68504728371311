import { IonCard, IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import { t } from "i18next";
import { FC, useState } from "react";
import { MatchPhone } from "refsix-js-models";
import { GpsMap, GpsMapType } from "../../../components/GpsMapComponent";
import pitchImage from "../../../assets/images/pitch.png";
import { ProTeaser } from "../../../components/ProTeaser";
import "./positioning.css";
import { matchesSortedByDate } from "../../../services/matchService";
import { FitnessDataProvider } from "../../../components/hooks";

interface PositioningProps {
    matches: MatchPhone[];
    hasStatsAccess: boolean;
}

export const Positioning: FC<PositioningProps> = ({
    matches,
    hasStatsAccess,
}) => {
    const selectSheetOptions = {
        cssClass: "positioning-select-sheet",
    };
    const [firstMatch, setFirstMatch] = useState<MatchPhone>();
    const [secondMatch, setSecondMatch] = useState<MatchPhone>();

    // Sort matches by newest date and filter out matches with no tracking
    const sortedMatches = matchesSortedByDate(matches)
        .reverse()
        .filter((match) => match.hasTracking);

    return (
        <ProTeaser showTeaser={!hasStatsAccess} teaserName="trends-positioning">
            <>
                {/*<p>{t("stats.stats.Positioning.heatmapComparison")}</p>*/}

                <IonCard
                    className="trends-positioning"
                    data-testid="heatmap-comparison-card"
                >
                    <IonItem>
                        <IonSelect
                            className="select-text"
                            placeholder={t(
                                "stats.stats.Positioning.selectAFixture"
                            )}
                            onIonChange={(e) => {
                                const match = matches.find(
                                    (match) => match._id === e.detail.value
                                ) as MatchPhone;
                                setFirstMatch(match);
                            }}
                            data-testid="comparison-heatmap-1"
                            interface="action-sheet"
                            interfaceOptions={selectSheetOptions}
                        >
                            {sortedMatches.map((match, i) => (
                                <IonSelectOption
                                    key={match._id}
                                    value={match._id}
                                >
                                    {`${match.homeTeam} v ${match.awayTeam}`}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>
                    {firstMatch ? (
                        <FitnessDataProvider matchId={firstMatch._id as string}>
                            <GpsMap
                                match={firstMatch}
                                mapType={GpsMapType.Heatmap}
                                testId="heatmap1"
                            />
                        </FitnessDataProvider>
                    ) : (
                        <div className="responsive svg-pitch">
                            <img src={pitchImage} alt="Pitch image" />
                        </div>
                    )}
                </IonCard>

                <IonCard className="trends-positioning">
                    <IonItem>
                        <IonSelect
                            className="select-text"
                            placeholder={t(
                                "stats.stats.Positioning.selectAFixture"
                            )}
                            onIonChange={(e) => {
                                const match = matches.find(
                                    (match) => match._id === e.detail.value
                                ) as MatchPhone;
                                setSecondMatch(match);
                            }}
                            interface="action-sheet"
                            interfaceOptions={selectSheetOptions}
                            data-testid="comparison-heatmap-2"
                        >
                            {sortedMatches.map((match, i) => (
                                <IonSelectOption key={i} value={match._id}>
                                    {`${match.homeTeam} v ${match.awayTeam}`}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>

                    {secondMatch ? (
                        <FitnessDataProvider
                            matchId={secondMatch._id as string}
                        >
                            <GpsMap
                                match={secondMatch}
                                mapType={GpsMapType.Heatmap}
                                testId="heatmap2"
                            />
                        </FitnessDataProvider>
                    ) : (
                        <div className="responsive svg-pitch">
                            <img src={pitchImage} alt="Pitch image" />
                        </div>
                    )}
                </IonCard>
            </>
        </ProTeaser>
    );
};
