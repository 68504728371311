import { FC, useState } from "react";
import { COLORS_HEART_RATE_ZONES } from "../constants/StatsColorPalette";
import { IonButton, IonLoading, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import RoundBarComponent from "./RoundBarComponent";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { DobModal } from "../modals/DobModal";
import AlertComponent from "./AlertComponent";
import { saveDob } from "../services/account/dateOfBirthService";

export interface ProcessedValuesInterface {
    name: string;
    value: number;
    width: number;
    color: string;
    class: string;
}

export interface HeartRateZonesComponentInterface {
    zoneDuration: number[];
    useDummyData?: boolean;
    hidden?: boolean;
}

const HeartRateZonesComponent: FC<HeartRateZonesComponentInterface> = ({
    zoneDuration,
    useDummyData,
    hidden,
}) => {
    const { t } = useTranslation();

    const dob = useSelector(
        (state: RootState) => state.currentUser?.profile?.dob
    );
    const userProfile = useSelector(
        (state: RootState) => state.currentUser?.profile
    );

    const [openDobModal, setOpenDobModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [success, setSuccess] = useState(false);

    const alertTitle = success
        ? t("myaccount.update.success.title")
        : t("myaccount.update.failure.title");
    const alertDesc = success
        ? t("myaccount.update.success.description")
        : t("myaccount.update.failure.description");

    const legendValues = [
        "50 - 59%",
        "60 - 69%",
        "70 - 79%",
        "80 - 89%",
        "> 90%",
    ];
    const labels = ["1", "2", "3", "4", "5"];
    const colours = COLORS_HEART_RATE_ZONES;

    function updateDob(date: string) {
        setOpenDobModal(false);
        setLoading(true);
        if (userProfile) {
            saveDob(date, userProfile)
                .then(() => {
                    setSuccess(true);
                    setShowAlert(true);
                })
                .catch((error) => {
                    setSuccess(false);
                    setShowAlert(true);
                    console.log("Error saving DOB", error);
                })
                .finally(() => {
                    setLoading(false);
                    setShowAlert(true);
                });
        }
    }

    return (
        <>
            <IonLoading isOpen={loading} />
            <div
                hidden={hidden}
                className="overlay-wrapper"
                data-testid="hr-zones-overlay"
            >
                {!dob && (
                    <IonButton
                        className="pro-lock"
                        expand="block"
                        data-testid="dob-overlay-button"
                        onClick={() => setOpenDobModal(true)}
                    >
                        <IonText className="find-more-text">
                            {t(
                                "fixture.FixtureSummaryStats.viewHeartRateZones"
                            )}
                        </IonText>
                    </IonButton>
                )}
                <div className={`${!dob && "semi-opaque"}`}>
                    <RoundBarComponent
                        values={zoneDuration}
                        title={t("fixture.FixtureSummaryStats.heartRateZones")}
                        series={labels}
                        colorOverride={COLORS_HEART_RATE_ZONES}
                        numberOfCategories={5}
                        unit={"minutes"}
                        useDummyData={useDummyData}
                    />
                    <div className="graph-legend">
                        {legendValues.map((value, index) => {
                            return (
                                <div key={index} className="legend-label">
                                    <IonText>{labels[index]}</IonText>
                                    <div
                                        className="graph-legend-item"
                                        style={{
                                            backgroundColor: colours[index],
                                        }}
                                    >
                                        <IonText>{value}</IonText>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <DobModal
                openDobModal={openDobModal}
                onDismiss={() => setOpenDobModal(false)}
                dob={(date) => updateDob(date)}
            />
            <AlertComponent
                testId="heart-zones-dob-alert"
                showAlert={showAlert}
                buttonDone={t("help.OnboardingVideo.controls.ok")}
                description={alertDesc}
                title={alertTitle}
                onDonePressed={() => setShowAlert(false)}
            />
        </>
    );
};

export default HeartRateZonesComponent;
