import { getCountryCode } from "./localeUtils";
import { getAllISOCodes } from "iso-country-currency";

export const CurrencySymbol = () => {
    const countryCode = getCountryCode();
    const allISOCodes = getAllISOCodes();
    const currency = allISOCodes.find((cur) => countryCode === cur.iso);
    if (currency) {
        return currency.symbol.toString();
    } else {
        return "";
    }
};

export const formatForCurrency = (
    value: number,
    currency: string,
    locale: string
) => {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
    }).format(value);
};
