import { get } from "lodash";
import { FieldErrors } from "react-hook-form";

export function errorProps(
    name: string,
    errors: FieldErrors,
    roundedCorners?: boolean,
    focus?: string,
    useWhiteBackground?: boolean
) {
    const errorMessage = get(errors, `${name}.message`);
    return errorMessage !== undefined
        ? {
              className: roundedCorners
                  ? `item-title ${focus} danger rounded-corners-small`
                  : `item-title ${focus} danger`,
          }
        : {
              className: roundedCorners
                  ? `item-title ${focus} rounded-corners-small ${
                        useWhiteBackground ? "item-white-background" : ""
                    }`
                  : `item-title ${focus}  ${
                        useWhiteBackground ? "item-white-background" : ""
                    }`,
          };
}
