import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonLoading,
    IonPage,
    IonText,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { AlertComponent } from "../../components";
import { AxiosError } from "axios";
import { LoginSession } from "../../redux/models/loginSession";
import { useDispatch, useSelector } from "react-redux";
import { userLoggedIn } from "../../redux/actions/auth";
import { useTranslation } from "react-i18next";
import { downloadProfile } from "../../services/profileService";
import { UserProfile } from "../../models/userProfile";
import { setCurrentUser } from "../../redux/actions/currentUser";
import { addUserProfile } from "../../redux/actions/addUserProfile";
import { RootState } from "../../redux/store";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
    checkForEmail,
    checkForUsername,
    login,
} from "../../services/authService";
import { setShowIntegrationPopup, setUsername } from "../../redux/actions/user";
import { bug, eye, eyeOff } from "ionicons/icons";
import "./login.css";
import "../../css/general.css";
import "../../css/forms.css";
import { routes } from "../../route/constants";
import { R6IonInput } from "../../components/r6ionic/R6IonInput";
import {
    setLoggedInOrRegisterComplete,
    setShowWhatsNew,
} from "../../redux/actions/appStatus";
import {
    checkIfDbuUser,
    getSubscriptions,
} from "../../services/subscriptionService";
import { get } from "lodash";
import { useCurrentInAppVersion } from "../../components/hooks/appVersionHooks";
import { setDeveloperToolsEnabled } from "../../redux/actions/developerToolsAction";
import { startAds } from "../../services/AdMobService";
import { handleRefreshIntegrationMatches } from "../../services/integrations/integrationSyncUpdates";
import RefSixImage from "../../assets/images/refsix-words-h80px.png";
import { useSafeKeyboard } from "../../components/hooks/useSafeKeyboard";
import { Google } from "./providers/Google";
import { Apple } from "./providers/Apple";
import { insertExampleResult } from "../../services/example-result.service";
import { isAndroid, isIos } from "../../services/platformDetection";
import { DividerText } from "../../components/DividerText";
import { useSocialAuthLogin } from "./providers/SocialAuthLogin";
import { mpEnable } from "../../services/analytics/mixpanelService";
import { trackRegistration } from "../../services/analytics/analyticsService";

interface LoginProps {
    email: string; // username or email
    password: string;
}

export default function Login() {
    const route = useIonRouter();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const inAppVersion = useCurrentInAppVersion();
    const { isOpen: isKeyboardVisible } = useSafeKeyboard();
    const formMethods = useForm<FieldValues>({
        mode: "onSubmit",
        reValidateMode: "onChange",
    });
    const { session, socialLogin, alerts } = useSocialAuthLogin();
    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = formMethods;

    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertDesc, setAlertDesc] = useState<string>("");
    const [alertTitle, setAlertTitle] = useState<string>("");
    const userStorageEmail = useSelector(
        (state: RootState) => state.user.usernameOrEmail
    );
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [versionClickCount, setVersionClickCount] = useState<number>(0);
    const developerToolsEnabled = useSelector(
        (state: RootState) => state.developerTools.enabled
    );
    const [loginAlertTestId, setLoginAlertTestId] =
        useState<string>("login-alert");

    const [presentAlert] = useIonAlert();

    useEffect(() => {
        if (userStorageEmail !== undefined && userStorageEmail !== "") {
            reset({
                email: userStorageEmail.replace(/\s/g, ""),
            });
        }
    }, [userStorageEmail, reset]);
    useEffect(() => {
        if (session && session.data) {
            const resp = loginResponse(session, (session.data as any).email);
        }
    }, [session]);
    function showGeneralError() {
        setAlertDesc(t("general.errorTryAgain"));
        setShowAlert(true);
    }

    // function called by onClick if clicked 5 times in a row will set hasDeveloperAccess to true
    const developerAccess = () => {
        // have a counter for how many times clicked
        if (versionClickCount === 4) {
            dispatch(setDeveloperToolsEnabled(true));
        } else if (versionClickCount < 4) {
            setVersionClickCount(versionClickCount + 1);
        }
    };

    async function downloadUserProfile(username: string) {
        const response = await downloadProfile(username);
        const profile = response.data as UserProfile;

        dispatch(setCurrentUser(profile));
        dispatch(addUserProfile(profile));

        try {
            const subscriptions = await getSubscriptions();
            if (subscriptions) {
                // If user is from Denmark -> ask to link DBU account
                if (
                    profile &&
                    profile.country &&
                    profile.country.toUpperCase() === "DK" &&
                    !checkIfDbuUser(subscriptions.subscriptions)
                ) {
                    dispatch(setShowIntegrationPopup(true));
                }

                // check for update from integrations
                handleRefreshIntegrationMatches().catch(function () {
                    console.log("Failed to refresh integration matches");
                });
            }
        } catch (error) {
            console.log("Failed to get subscriptions when logging in", error);
        }
    }

    async function loginResponse(response: any, username: string) {
        setShowLoading(true);
        if (response.status === 200) {
            const session = response.data as LoginSession;
            const userId = session.user_id || (session as any)._id;
            const status = session.status;
            const email = session.email;

            const finishLogin = async () => {
                dispatch(setUsername(userId));
                dispatch(userLoggedIn(true, session));
                setTimeout(() => {
                    insertExampleResult(username);
                }, 1000);
            };
            if (status === "new") {
                await finishLogin();
                mpEnable();
                const provider = session.provider || "local";
                trackRegistration(provider);

                setShowLoading(false);

                route.push(routes.registerStepTwo, "forward", "replace");
                return;
            }
            dispatch(setUsername(userId));
            dispatch(userLoggedIn(true, session));

            dispatch(setLoggedInOrRegisterComplete(true));
            dispatch(setShowWhatsNew(true));
            downloadUserProfile(userId).catch(function () {
                console.log("Failed to download the user profile");
            });

            startAds();
        } else {
            showGeneralError();
        }
        setShowLoading(false);
    }

    async function userClickedLogin(data: any) {
        setShowLoading(true);

        let emailOrUsername = data.email;

        // Check if data.email is an email address
        if (emailOrUsername && emailOrUsername.includes("@")) {
            const email = emailOrUsername.toLowerCase().trim();
            // Check if email address is already in use
            try {
                const response = await checkForEmail(email);
                // Check if email address is in our system
                // should show error message if it is not
                // checkForEmail function will return true if email does not exist, it is used for register. We are using it the other way for login purpose.
                if (response && response.status === 200) {
                    setLoginAlertTestId("login-alert-email-not-exist");
                    setAlertTitle(t("register.register.customerFailure.title"));
                    setAlertDesc(t("login.login.emailDoesNotExist"));
                    setShowLoading(false);
                    setShowAlert(true);
                    return;
                }
            } catch (e: any) {
                console.log("Error checking for email", e);

                if (e.response && e.response.status === 409) {
                    await logUserIn(email, data.password);
                } else {
                    showGeneralError();
                    setShowLoading(false);
                }
            }
        } else {
            // check username already exists in our database. if it returns 200 then username does not exist and show error
            // if it returns 409 then username exists and proceed with login
            const username = emailOrUsername.toLowerCase().trim();
            try {
                await checkForUsername(username);
                setLoginAlertTestId("login-alert-username-not-exist");
                setAlertTitle(t("register.register.customerFailure.title"));
                setAlertDesc(t("login.login.usernameDoesNotExist"));
                setShowLoading(false);
                setShowAlert(true);
            } catch (e: any) {
                if (e.response && e.response.status === 409) {
                    await logUserIn(username, data.password);
                } else {
                    showGeneralError();
                    setShowLoading(false);
                }

                return;
            }
        }
    }
    const onSocialLogin = async (email: string, next: () => void) => {
        try {
            const res = await checkForEmail(email);
            console.log(res);
            await presentAlert({
                header: t("login.login.socialLogin.noAccountWithThisEmail"),
                message: email,
                buttons: [
                    {
                        text: t("login.login.socialLogin.createNew", {
                            email: email,
                        }),
                        role: "confirm",
                    },
                    {
                        text: t(
                            "login.login.socialLogin.loginWithDifferentEmail"
                        ),
                        role: "cancel",
                    },
                ],
                onDidDismiss: ({ detail }) => {
                    if (detail.role === "cancel") {
                        return;
                    } else if (detail.role === "confirm") {
                        return next();
                    }
                },
            });
        } catch (e) {
            console.log(e);
            next();
        }
    };

    const logUserIn = async (usernameOrEmail: string, password: string) => {
        try {
            const response = await login(usernameOrEmail, password);
            await loginResponse(response, usernameOrEmail);
        } catch (error) {
            setShowLoading(false);
            const axError = error as AxiosError;
            if (axError.response && axError.response.status === 401) {
                // incorrect credentials
                setLoginAlertTestId("login-alert");
                setAlertTitle(t("login.login.failure.title"));
                setAlertDesc(t("login.login.failure.description"));
                setShowAlert(true);
            } else {
                // some other error
                showGeneralError();
            }
        }
    };

    return (
        <IonPage className="login-page">
            <IonContent data-testid="login-page-header">
                {alerts}
                <IonItem
                    lines="none"
                    color="transparent"
                    style={{ marginTop: 50 }}
                >
                    <div className="flex-column-container">
                        <div className="flex-center-container">
                            <img src={RefSixImage} style={{ width: "100px" }} />
                        </div>
                        <h1>{t("login.login.signIn")}</h1>
                    </div>
                </IonItem>
                {isIos() ? (
                    <div>
                        <Apple
                            requestType={"Login"}
                            showSpinner={true}
                            login={socialLogin}
                        />
                    </div>
                ) : null}
                <div>
                    <Google
                        onSignIn={onSocialLogin}
                        requestType={"Login"}
                        showSpinner={true}
                        login={socialLogin}
                    />
                </div>

                <DividerText>
                    {t("register.registerOrLogin.orSignInWith")}
                </DividerText>

                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(userClickedLogin)}>
                        <IonCard className="ion-card-transparent">
                            <div style={{ marginBottom: 10 }}>
                                <IonLabel
                                    style={{ color: "white", fontSize: 14 }}
                                >
                                    {`${t("register.register.email")}/${t(
                                        "general.username"
                                    )}`}
                                </IonLabel>
                            </div>
                            <IonItem
                                lines="none"
                                className={
                                    get(errors, `email.message`)
                                        ? "danger rounded-corners"
                                        : "item-white-background rounded-corners-small"
                                }
                            >
                                <R6IonInput
                                    className="placeholder-input"
                                    name="email"
                                    testId="usernameInput"
                                    rules={{
                                        required: t(
                                            "utils.ErrorMessages.required"
                                        ).toString(),
                                    }}
                                    inputMode="email"
                                    autocapitalize="off"
                                    placeholder={t("register.register.email")}
                                />
                            </IonItem>

                            <br />
                            <div style={{ marginBottom: 10 }}>
                                <IonLabel
                                    style={{ color: "white", fontSize: 14 }}
                                >
                                    {t("login.login.password")}
                                </IonLabel>
                            </div>
                            <IonItem
                                lines="none"
                                className={
                                    get(errors, `password.message`)
                                        ? "danger rounded-corners"
                                        : "item-white-background rounded-corners-small"
                                }
                            >
                                <R6IonInput
                                    name="password"
                                    className="placeholder-input"
                                    testId="passwordInput"
                                    rules={{
                                        required: t(
                                            "utils.ErrorMessages.required"
                                        ).toString(),
                                    }}
                                    type={showPassword ? "text" : "password"}
                                    autocapitalize="off"
                                    placeholder={t("login.login.password")}
                                />

                                <IonIcon
                                    icon={showPassword ? eye : eyeOff}
                                    slot="end"
                                    size="medium"
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                />
                            </IonItem>
                            <IonButton
                                data-testid="loginButton"
                                type="submit"
                                expand="block"
                                className="login-button"
                                color="primary"
                            >
                                {t("general.login")}
                            </IonButton>
                            <IonButton
                                fill="clear"
                                expand="block"
                                data-testid="forgot-password"
                                onClick={() =>
                                    route.push(routes.forgotPassword)
                                }
                            >
                                {t("login.login.forgotPassword")}?
                            </IonButton>
                            <input type="submit" hidden={true} />
                        </IonCard>
                    </form>
                </FormProvider>
                <IonLoading isOpen={showLoading} data-testid="login" />
                <AlertComponent
                    testId={loginAlertTestId}
                    showAlert={showAlert}
                    buttonDone={t("help.OnboardingVideo.controls.ok")}
                    dismissAlert={() => setShowAlert(false)}
                    description={alertDesc}
                    title={alertTitle}
                    onDonePressed={() => {}}
                    buttonAltText={""}
                />
            </IonContent>
            <IonFooter>
                <div hidden={isKeyboardVisible}>
                    <IonButton
                        fill="clear"
                        expand="block"
                        data-testid="go-to-register-button"
                        onClick={() => route.push(routes.register, "root")}
                    >
                        {t("billing.signUpForFree")}
                    </IonButton>
                    <div className="margin-bottom-centered-flex">
                        <IonText onClick={developerAccess}>
                            {t("menu.version", {
                                versionNumber: inAppVersion || "DEV",
                            })}
                        </IonText>
                    </div>
                    {developerToolsEnabled && (
                        <div className="margin-bottom-centered-flex">
                            <IonButton routerLink={routes.developerTools}>
                                <IonIcon icon={bug} slot="start" />
                                <IonLabel>Developer Tools</IonLabel>
                            </IonButton>
                        </div>
                    )}
                </div>
            </IonFooter>
        </IonPage>
    );
}
