import { useTranslation } from "react-i18next";
import { UseList } from "./types";
import { useMatchOfficials } from "../index";
import { useCallback, useMemo } from "react";
import _ from "lodash";
import { IonItem, IonText } from "@ionic/react";
import { useSearcher } from "./useTeamOfficialList";
import { TeamPlayerListItem } from "./useTeamPlayerList";

export const useMatchOfficialsList: () => UseList<string[]> = () => {
    const { t } = useTranslation();

    const matchOfficials = useMatchOfficials();
    const search = useSearcher(matchOfficials);

    const cb = useCallback(
        (query: string = ""): string[] => {
            if (query === "") {
                return matchOfficials;
            }
            const res = search(query);
            // Filter out exact matches
            return res.filter((x: string) => x !== query);
        },
        [matchOfficials, search]
    );

    return useMemo(
        () => ({
            search: cb,
            renderer: matchOfficialsListRenderer(t),
        }),
        [cb, t]
    );
};

export const matchOfficialsListRenderer =
    (t: any) => (results: string[], save: (v: string) => void) => {
        const uniqueNames = _.uniq(results);

        if (results.length === 0) {
            return <IonText>{t("settings.TimerSettings.none")}</IonText>;
        }
        return uniqueNames.map((result, index) => {
            return (
                <span
                    color="medium"
                    style={{
                        display: "inline-block",
                        backgroundColor: "#363636",
                        margin: "7px 5px 7px 0px",
                        color: "white",
                        padding: "10px",
                        borderRadius: "5px",
                    }}
                    key={`${index}-${result}`}
                    onClick={() => save(result)}
                >
                    {result}
                </span>
            );
        });
    };
