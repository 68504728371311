import { FC, useEffect, useState } from "react";
import { AggregatedStats, MatchPhone } from "refsix-js-models";
import { FiltersModel } from "../../../redux/models/filtersModel";
import { IonCard, IonCol, IonItem, IonText } from "@ionic/react";
import {
    BarChartComponent,
    HeadlineNumbersComponent,
    HeartRateZonesComponent,
    LineChartComponent,
    ListOfTopComponent,
} from "../../../components";
import { t } from "i18next";
import { barChartOptions } from "./barChartOptions";
import { lastMatches, matchesWithMost } from "../../../services/matchService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
    COLORS_HEART_RATE,
    COLORS_HEART_RATE_ZONES,
} from "../../../constants/StatsColorPalette";
import { heartRateLineBarOptions } from "./heartRateLineOptions";
import moment from "moment";
import { secondsToMinuteClock } from "refsix-core";
import { HeartRateFilter } from "../../../utils/filters/HeartRateFilter";
import { ProTeaser } from "../../../components/ProTeaser";
import { LIST_OF_TOP_DATE_FORMAT } from "../../../components/ListOfTopComponent";

interface HeartRateProps {
    matches: MatchPhone[];
    aggregatedStats: AggregatedStats | undefined;
    filters: FiltersModel;
    hasStatsAccess: boolean;
}

// TODO Replace with proper charts.js type that this duplicates
type Datasets = {
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    barThickness: number;
    lineTension?: number;
    label?: string;
};

const HeartRate: FC<HeartRateProps> = ({
    matches,
    aggregatedStats,
    filters,
    hasStatsAccess,
}) => {
    const [labels, setLabels] = useState<string[]>([]);
    const [hrLineData, setHrLineData] = useState<Datasets[]>([]);
    const [timeSpentInZonesDatasets, setTimeSpentInZonesDatasets] = useState<
        Datasets[]
    >([]);
    const count = 10;
    const statsType = "heartRateAvailable";
    const dateFormat = LIST_OF_TOP_DATE_FORMAT;
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );

    useEffect(() => {
        getLast10Matches();
    }, [matches, filters, useImperial]);

    async function getLast10Matches() {
        // Getting the last 10 matches
        const last10Matches = lastMatches(matches, filters, count, statsType);

        const lineLabels: string[] = [];
        const maxHrArray: number[] = [];
        const avgHrArray: number[] = [];
        const zoneDurationsArray: number[][] = [];
        const timeSpentInZonesArray: Datasets[] = [];

        last10Matches.forEach((match) => {
            lineLabels.push(`${match.homeTeamShort} - ${match.awayTeamShort}`);

            if (match.stats) {
                maxHrArray.push(match.stats.heartRateMax);
                avgHrArray.push(match.stats.heartRateAverage);

                zoneDurationsArray.push(match.stats.heartRateZoneDuration);
            }
        });

        // Getting for each match the duration number and adding them to an array of 5 to display the time spent
        const timeSpentArray = zoneDurationsArray.reduce(
            (acc, item, i) => {
                acc.forEach((innerArray: number[], i) => {
                    // Time spent in minutes
                    innerArray.push(item[i] / 60);
                });

                return acc;
            },
            [[], [], [], [], []]
        );

        timeSpentArray.forEach((val: number[], i) => {
            timeSpentInZonesArray.push({
                data: val,
                backgroundColor: COLORS_HEART_RATE_ZONES[i],
                borderColor: COLORS_HEART_RATE_ZONES[i],
                borderWidth: 1,
                barThickness: 20,
                label: (i + 1).toString(),
            });
        });

        setTimeSpentInZonesDatasets(timeSpentInZonesArray);

        setLabels(lineLabels);

        setHrLineData([
            {
                data: maxHrArray,
                backgroundColor: COLORS_HEART_RATE[0],
                borderColor: COLORS_HEART_RATE[0],
                borderWidth: 1,
                barThickness: 20,
                label: t("fixture.FixtureSummaryStats.peakHeartRate"),
                lineTension: 0.4,
            },
            {
                data: avgHrArray,
                backgroundColor: COLORS_HEART_RATE[1],
                borderColor: COLORS_HEART_RATE[1],
                borderWidth: 1,
                barThickness: 20,
                label: t("fixture.FixtureSummaryStats.averageHeartRate"),
                lineTension: 0.4,
            },
        ]);
    }

    const data = {
        labels: labels,
        datasets: hrLineData,
    };

    const timeInZonesSpentBarData = {
        labels: labels,
        datasets: timeSpentInZonesDatasets,
    };

    const matchesWithHighestIntensity = matchesWithMost(
        matches,
        "heartRateZone4And5",
        5,
        filters
    );

    return (
        <>
            <IonCard data-testid="trends-segment-heart-rate">
                <IonItem lines="none">
                    <IonCol>
                        <IonText className="text-small">
                            {t("general.heartRate")}
                        </IonText>
                    </IonCol>
                </IonItem>
                <HeadlineNumbersComponent
                    title={t("stats.stats.HeartRate.averageAcrossMatches")}
                    testIdsPrefix="heart-rate-avg-matches"
                    data={[
                        HeartRateFilter(aggregatedStats?.heartRateMax || 0),
                        HeartRateFilter(aggregatedStats?.heartRateAverage || 0),
                    ]}
                    labels={[
                        t("stats.stats.HeartRate.max"),
                        t("stats.stats.HeartRate.average"),
                    ]}
                />
                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-hr-bar-graph"
                >
                    <IonItem lines="none">
                        <IonCol>
                            <LineChartComponent
                                data={data}
                                lineChartOptions={heartRateLineBarOptions(
                                    matches,
                                    t("graphs.HeartRateTimeGraph.yAxis")
                                )}
                                testId="trends-hr-line-chart"
                                fixedHeight={250}
                                useDummyData={!hasStatsAccess}
                            />
                        </IonCol>
                    </IonItem>
                </ProTeaser>
            </IonCard>

            <IonCard data-testid="heart-rate-zones-card">
                <IonItem lines="none">
                    <IonCol>
                        <IonText className="text-small">
                            {t("stats.stats.HeartRate.heartRateZones")}
                        </IonText>
                    </IonCol>
                </IonItem>
                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-hr-time-in-zones"
                >
                    <HeartRateZonesComponent
                        zoneDuration={
                            aggregatedStats?.heartRateZoneDurationAverage || []
                        }
                        useDummyData={!hasStatsAccess}
                    />
                </ProTeaser>

                <IonItem lines="none">
                    <IonCol>
                        <IonText className="text-small">
                            {t("stats.stats.last10Matches")}
                        </IonText>
                    </IonCol>
                </IonItem>

                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-hr-last10"
                >
                    <IonItem lines="none">
                        <IonCol>
                            <BarChartComponent
                                data={timeInZonesSpentBarData}
                                barChartOptions={barChartOptions(
                                    matches,
                                    t("stats.stats.HeartRate.timeInZone"),
                                    useImperial,
                                    "time"
                                )}
                                testId="heart-rate-zones-chart"
                                useDummyData={!hasStatsAccess}
                            />
                        </IonCol>
                    </IonItem>
                </ProTeaser>

                {/*Getting last 5 top matches*/}
                <IonItem
                    lines="none"
                    data-testId="top-matches-highest-intensity"
                >
                    <IonCol>
                        <IonText className="text-small">
                            {t("stats.stats.HeartRate.matchesWithHighest")}
                        </IonText>
                    </IonCol>
                </IonItem>
                <ProTeaser
                    showTeaser={!hasStatsAccess}
                    teaserName="trends-hr-top-matches"
                >
                    <>
                        {matchesWithHighestIntensity.map((match, index) => {
                            return (
                                <ListOfTopComponent
                                    matchId={match._id}
                                    key={index}
                                    stat={
                                        match.stats &&
                                        secondsToMinuteClock(
                                            match.stats["heartRateZone4And5"]
                                        )
                                    }
                                    teamNames={`${match.homeTeam} v ${match.awayTeam}`}
                                    date={moment(match.date).format(dateFormat)}
                                    useDummyData={!hasStatsAccess}
                                    valueSize={"medium"}
                                />
                            );
                        })}
                    </>
                </ProTeaser>
            </IonCard>
        </>
    );
};

export default HeartRate;
