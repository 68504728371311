import { IonCol, IonItem, IonText } from "@ionic/react";
import { FC } from "react";
import { replaceWithDashes } from "../services/dummyDataService";

interface AggregatedStatsComponentProps {
    data: (string | number)[];
    title: string;
    labels: string[];
    colors?: string[];
    testIdsPrefix?: string;
    useDummyData?: boolean;
    fromIndex?: number;
}

/**
 * This component will create headline numbers based on the data. used in trends pages.
 */

const HeadlineNumbersComponent: FC<AggregatedStatsComponentProps> = ({
    data,
    title,
    labels,
    colors,
    testIdsPrefix,
    useDummyData,
    fromIndex,
}) => {
    if (useDummyData && data.length >= 1) {
        // Replace values with dashes from specified index
        data = replaceWithDashes(data, fromIndex);
    }

    return (
        <>
            <IonItem lines="none">
                <IonCol>
                    <div>
                        <IonText
                            className="text-small headline-numbers-title"
                            data-testid={`${testIdsPrefix}-title`}
                        >
                            {title}
                        </IonText>
                    </div>
                </IonCol>
            </IonItem>
            <IonItem lines="none">
                <IonCol>
                    <div className="headline-container">
                        {data.map((val, index) => {
                            return (
                                <IonCol
                                    // 12 is the max size of the IonCol
                                    size={(12 / data.length).toString()}
                                    key={index}
                                    className="headline-numbers-container"
                                    style={{
                                        borderBottomColor: colors
                                            ? colors[index]
                                            : "transparent",
                                    }}
                                >
                                    <IonText
                                        color="primary"
                                        className="text-large text-weight-bold"
                                        data-testid={`${testIdsPrefix}-data-text-${index}`}
                                    >
                                        {data[index]}
                                    </IonText>
                                    <IonText
                                        className="text-small dark-color headline-numbers-labels"
                                        data-testid={`${testIdsPrefix}-title`}
                                    >
                                        {labels[index]}
                                    </IonText>
                                </IonCol>
                            );
                        })}
                    </div>
                </IonCol>
            </IonItem>
        </>
    );
};
export default HeadlineNumbersComponent;
