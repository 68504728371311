import { Timings } from "./timings";
import { SinBinSystem } from "./enums/sinBinSystem";
import { Match } from "./match";

export class TemplateConfig {
    timings: Timings;
    periodsNo: string; // legacy, this should really change
    withGoalScorers: boolean;
    teamSize: number;
    subsNo: number;
    extraTimeAvailable: boolean;
    penaltiesAvailable: boolean;
    sinBinSystem: SinBinSystem;
    misconductCodeId?: string;

    constructor(
        timings: Timings,
        periodsNo: string,
        withGoalScorers: boolean,
        teamSize: number,
        subsNo: number,
        extraTimeAvailable: boolean,
        penaltiesAvailable: boolean,
        sinBinSystem: SinBinSystem = SinBinSystem.none,
        misconductCodeId: string | undefined
    ) {
        this.timings = timings;
        this.periodsNo = periodsNo;
        this.withGoalScorers = withGoalScorers;
        this.teamSize = teamSize;
        this.subsNo = subsNo;
        this.extraTimeAvailable = extraTimeAvailable;
        this.penaltiesAvailable = penaltiesAvailable;
        this.sinBinSystem = sinBinSystem;
        this.misconductCodeId = misconductCodeId;
    }

    static fromJSON(json: any) {
        return new TemplateConfig(
            json.timings,
            json.periodsNo,
            json.withGoalScorers,
            json.teamSize,
            json.subsNo,
            json.extraTimeAvailable,
            json.penaltiesAvailable,
            json.sinBinSystem,
            json.misconductCodeId
        );
    }

    static fromMatch(match: Match) {
        let halves = match.halves;
        let matchPeriod1 = halves[0].length / 60000;
        // [0],[1],[2]
        // p1, i1, p2
        let matchPeriod2 =
            halves.length >= 3 ? halves[2].length / 60000 : undefined;
        // [0],[1],[2],[3],[4]
        // p1, i1, p2, i2, p3
        let matchPeriod3 =
            halves.length >= 4 ? halves[4].length / 60000 : undefined;
        // [0],[1],[2],[3],[4],[5],[6]
        // p1, i1, p2, i2, p3, i3, p4
        let matchPeriod4 =
            halves.length >= 6 ? halves[6].length / 60000 : undefined;

        let matchInterval1 =
            halves.length > 2 ? halves[1].length / 60000 : undefined;
        let matchInterval2 =
            halves.length > 3 ? halves[3].length / 60000 : undefined;
        let matchInterval3 =
            halves.length > 4 ? halves[5].length / 60000 : undefined;

        let timings = new Timings(
            matchPeriod1,
            matchPeriod2,
            matchInterval1,
            matchPeriod3,
            matchPeriod4,
            matchInterval2,
            matchInterval3,
            match.extraTimeAvailable ? 15 : undefined,
            match.sinBinSystem === "none" ? undefined : 10
        );

        let periodsNo = 2;

        if (halves.length === 1) {
            periodsNo = 1;
        } else if (halves.length === 3) {
            periodsNo = 2;
        } else if (halves.length === 5) {
            periodsNo = 3;
        } else if (halves.length === 7) {
            periodsNo = 4;
        }

        let sinBinSystem = match.sinBinSystem || SinBinSystem.none;

        return new TemplateConfig(
            timings,
            "" + periodsNo,
            false,
            11,
            5,
            match.extraTimeAvailable,
            match.penaltiesAvailable,
            sinBinSystem,
            match.misconductCodeId || "fifa"
        );
    }
}
