import { HeaderComponent } from "./index";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonPage,
    IonText,
} from "@ionic/react";
import React from "react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import "./NewFeaturesContent.css";
import { getLanguageCode } from "../utils/localeUtils";
import autocompleteImage from "../assets/images/new-features/6.1-autocomplete.png";
import calendarImage from "../assets/images/new-features/6.2-calendar.png";
import cropImage from "../assets/images/new-features/6.4-crop-match.png";
import complicationImage from "../assets/images/new-features/6.7-complication.png";
import compareHeatmapImage from "../assets/images/new-features/6.7-compare-heatmap.png";
import socialLogin from "../assets/images/new-features/6.10-social-sign-in.png";
import { getStringOrFallback } from "../i18n";
import { isAndroid, isIos } from "../services/platformDetection";
import { isFeatureAvailableCurrentLanguage } from "../utils/FeatureToggle";
import knvbLogo from "../assets/images/icon/knvb-logo-vector-2022.svg";
import knvbButton from "../assets/images/new-features/6.14.1-knvb-match.png";

// test function so easily show different variants
// const isIos = () => {
//     return false;
// };

export const NewFeatureContent = (dismiss: () => void) => {
    const { t } = useTranslation();

    const language = getLanguageCode();

    return (
        <IonPage className="new-features-modal">
            <HeaderComponent
                title={t("help.WhatsNewModal.title")}
                onClickEndButton={() => {
                    dismiss();
                }}
                endBtnIconName={closeOutline}
                titleTestId="new-feature-title"
                endBtnTestId="new-feature-header-close"
            />
            <IonContent className="new-features">
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle style={{ color: "white" }}>
                            v6.15 <span className={"rainbox-text"}>AI</span>{" "}
                            {t("fixture.Fixture.teamsheet")} BETA
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div
                            style={{
                                position: "relative",
                                width: "80%",
                                margin: "0 auto 10px",
                            }}
                        >
                            <div
                                style={{
                                    padding: "177.78% 0 0 0",
                                    position: "relative",
                                }}
                            >
                                <iframe
                                    src="https://player.vimeo.com/video/1056009294?h=29ae7bc3d8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&muted=1&background=1"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    title={`AI ${t(
                                        "fixture.Fixture.teamsheet"
                                    )}`}
                                ></iframe>
                            </div>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                    </IonCardContent>
                </IonCard>

                {language === "nl" && (
                    <IonCard color="light">
                        <IonCardHeader>
                            <IonCardTitle>v6.14.1</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <img src={knvbButton} alt="KNVB" />
                            <>
                                <p>
                                    <IonText>
                                        KNVB: {t("general.addMatch")} BETA
                                    </IonText>
                                </p>
                            </>
                        </IonCardContent>
                    </IonCard>
                )}

                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.10</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <>
                            <img
                                src={socialLogin}
                                style={{
                                    border: "1px solid grey",
                                    borderRadius: "5px",
                                    display: "block",
                                    margin: "0 auto",
                                    marginBottom: "10px",
                                    maxHeight: 180,
                                }}
                            ></img>
                            {language !== "en" && (
                                <p>
                                    <IonText>
                                        {t(
                                            "register.registerOrLogin.signInWithGoogle"
                                        )}
                                        &nbsp;/&nbsp;
                                        {t(
                                            "register.registerOrLogin.signInWithApple"
                                        )}
                                    </IonText>
                                </p>
                            )}
                            {language === "en" && (
                                <p>
                                    <IonText>
                                        Login or register using your existing
                                        Google or Apple account.
                                    </IonText>
                                </p>
                            )}
                        </>

                        <p>
                            <IonText>
                                {t("help.WhatsNewModal.general.minor")}
                            </IonText>
                        </p>
                    </IonCardContent>
                </IonCard>
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.9</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {language === "en" && (
                            <>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "56.25% 0 0 0",
                                            position: "relative",
                                        }}
                                    >
                                        <iframe
                                            src="https://player.vimeo.com/video/986387261?h=29ae7bc3d8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=0"
                                            frameBorder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowFullScreen
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            title="REFSIX v6.0"
                                        ></iframe>
                                    </div>
                                    <script src="https://player.vimeo.com/api/player.js"></script>
                                </div>
                            </>
                        )}
                        {/* System B sin bin chnages affect these countries */}
                        {(language === "en" ||
                            language === "fr" ||
                            language === "de") && (
                            <p>
                                <IonText>
                                    {t("sinbinChanges.noChangeNeeded")}
                                </IonText>
                            </p>
                        )}
                        <p>
                            <IonText>
                                {t("help.WhatsNewModal.general.minor")}
                            </IonText>
                        </p>
                    </IonCardContent>
                </IonCard>
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.8</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <ul>
                            {language === "en" && (
                                <>
                                    <li>
                                        <IonText>
                                            Redesigned login and registration
                                            screens. Make it harder to
                                            accidentally sign up for a new
                                            account when trying to log in.
                                        </IonText>
                                    </li>
                                    {isIos() && (
                                        <>
                                            <li>
                                                <IonText>
                                                    Apple Watch: Team Colours
                                                    now show correctly on small
                                                    Apple Watches
                                                </IonText>
                                            </li>
                                            <li>
                                                <IonText>
                                                    Apple Watch: Fix for Setup
                                                    Substitutes pre-game - the
                                                    number now updates correctly
                                                    after a change
                                                </IonText>
                                            </li>
                                        </>
                                    )}
                                    {isAndroid() && (
                                        <>
                                            <li>
                                                <IonText>
                                                    WearOS: Fix an issue where
                                                    some users were seeing the
                                                    green tick on the watch but
                                                    no match loaded
                                                </IonText>
                                            </li>
                                            <li>
                                                <IonText>
                                                    WearOS: Fix issue where
                                                    matches with different
                                                    period lengths didn't work
                                                    as expected.
                                                </IonText>
                                            </li>
                                        </>
                                    )}
                                </>
                            )}
                            <li>
                                <IonText>
                                    {t("help.WhatsNewModal.general.minor")}
                                </IonText>
                            </li>
                        </ul>
                    </IonCardContent>
                </IonCard>
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.7</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {isIos() && (
                            <img
                                src={complicationImage}
                                style={{
                                    border: "1px solid grey",
                                    borderRadius: "5px",
                                    display: "block",
                                    margin: "0 auto",
                                    marginBottom: "10px",
                                    maxHeight: 180,
                                }}
                            ></img>
                        )}
                        {!isIos() && language === "en" && (
                            <img
                                src={compareHeatmapImage}
                                style={{
                                    border: "1px solid grey",
                                    borderRadius: "5px",
                                    display: "block",
                                    margin: "0 auto",
                                    marginBottom: "10px",
                                }}
                            ></img>
                        )}

                        <ul>
                            {language === "en" && (
                                <li>
                                    We have been focusing on squashing as many
                                    bugs as possible.
                                </li>
                            )}
                            {language === "en" && (
                                <>
                                    <li>
                                        <IonText>
                                            Compare heatmaps from 2 matches
                                            using the button under the heatmap.
                                        </IonText>
                                    </li>
                                    <li>
                                        <IonText>
                                            You can now quickly create a match
                                            on the phone without entering any
                                            information. It will default to the
                                            team names "Home" and "Away" which
                                            you can easily edit later.
                                        </IonText>
                                    </li>
                                    {isIos() && (
                                        <>
                                            <li>
                                                <IonText>
                                                    Made fixes to prevent
                                                    matches getting stuck as
                                                    "live" after the match. If
                                                    you match still says Live,
                                                    then you can "Mark as
                                                    result" to resolve this.
                                                </IonText>
                                            </li>
                                            <li>
                                                <IonText>
                                                    Apple Watch complication is
                                                    back!
                                                </IonText>
                                            </li>
                                            <li>
                                                <IonText>
                                                    Apple Watch - added missing
                                                    team colour options.
                                                </IonText>
                                            </li>
                                        </>
                                    )}
                                    {isAndroid() && (
                                        <>
                                            <li>
                                                <IonText>
                                                    WearOS watch fixed for
                                                    square screens (e.g. Oppo
                                                    Watch)
                                                </IonText>
                                            </li>
                                        </>
                                    )}
                                </>
                            )}
                            <li>
                                <IonText>
                                    {t("help.WhatsNewModal.general.minor")}
                                </IonText>
                            </li>
                        </ul>
                    </IonCardContent>
                </IonCard>
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.4</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {isFeatureAvailableCurrentLanguage("cropMatch") && (
                            <img
                                src={cropImage}
                                style={{
                                    border: "1px solid grey",
                                    borderRadius: "5px",
                                    display: "block",
                                    margin: "0 auto",
                                    marginBottom: "10px",
                                }}
                            ></img>
                        )}
                        <ul>
                            {language !== "en" && (
                                <>
                                    {isFeatureAvailableCurrentLanguage(
                                        "cropMatch"
                                    ) && (
                                        <li>
                                            <IonText>
                                                {t("fixture.Fixture.cropMatch")}
                                            </IonText>
                                        </li>
                                    )}

                                    <li>
                                        <IonText>
                                            {t(
                                                "help.WhatsNewModal.general.update_v6_2.autocompleteTeamPacks"
                                            )}
                                        </IonText>
                                    </li>
                                    {isIos() && (
                                        <li>
                                            <IonText>
                                                WatchOS 10, Apple Watch Ultra.
                                            </IonText>
                                        </li>
                                    )}
                                </>
                            )}
                            {language === "en" && (
                                <>
                                    <li>
                                        <IonText>
                                            Crop match - change the match end
                                            minute if you forgot to stop the
                                            watch on the pitch. Access via the
                                            Options menu on a result.
                                        </IonText>
                                    </li>
                                    <li>
                                        <IonText>
                                            Improved autocomplete when creating
                                            a match.
                                        </IonText>
                                    </li>
                                    {isIos() && (
                                        <>
                                            <li>
                                                <IonText>
                                                    Support Apple Watch watchOS
                                                    10 new screen layout with
                                                    bigger headers.
                                                </IonText>
                                            </li>
                                            {/* THIS WAS REMOVED IN 6.5.0 due to watch incompatibility */}
                                            {/*<li>*/}
                                            {/*    <IonText>*/}
                                            {/*        Apple Watch complication*/}
                                            {/*        updated for watchOS 10+.*/}
                                            {/*    </IonText>*/}
                                            {/*</li>*/}
                                            <li>
                                                <IonText>
                                                    Apple Watch Ultra screen
                                                    size improvements.
                                                </IonText>
                                            </li>
                                            <li>
                                                <IonText>
                                                    Apple Watch can sync match
                                                    events to your phone in real
                                                    time over a wifi or cellular
                                                    connection.
                                                </IonText>
                                            </li>
                                        </>
                                    )}
                                </>
                            )}
                            <li>
                                <IonText>
                                    {t("help.WhatsNewModal.general.minor")}
                                </IonText>
                            </li>
                        </ul>
                    </IonCardContent>
                </IonCard>
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.3</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <ul>
                            <li>
                                {language !== "en" && (
                                    <IonText>
                                        {t("general.integrations")}: RefAssist
                                    </IonText>
                                )}
                                {language === "en" && (
                                    <IonText>
                                        <a
                                            href="https://refassist.com/en"
                                            target="_system"
                                        >
                                            RefAssist integration
                                        </a>{" "}
                                        - If your league uses RefAssist you can
                                        now get match appointments, submit
                                        availability, see files and messages
                                        directly in the REFSIX app!
                                    </IonText>
                                )}
                            </li>
                            <li>
                                <IonText>
                                    {t("help.WhatsNewModal.general.minor")}
                                </IonText>
                            </li>
                        </ul>
                    </IonCardContent>
                </IonCard>
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.2</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <img
                            style={{
                                border: "1px solid grey",
                                borderRadius: "5px",
                            }}
                            src={calendarImage}
                        ></img>

                        <ul>
                            <li>
                                <IonText>
                                    {t(
                                        "settings.SettingsMain.addFixtureToCalendar"
                                    )}
                                    &nbsp;[PRO]
                                </IonText>
                            </li>
                            <li>
                                <IonText>
                                    {t(
                                        "help.WhatsNewModal.general.update_v6_2.uploadMultipleMatches"
                                    )}
                                    &nbsp;[PRO]
                                </IonText>
                            </li>
                            <li>
                                <IonText>
                                    {t(
                                        "help.WhatsNewModal.general.update_new_languages.hebrew"
                                    )}
                                </IonText>
                            </li>
                        </ul>
                    </IonCardContent>
                </IonCard>
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.1</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <img
                            style={{ border: "1px solid grey" }}
                            src={autocompleteImage}
                        ></img>

                        <ul>
                            <li>
                                <IonText>
                                    {getStringOrFallback(
                                        "help.WhatsNewModal.general.update_v6_2.autocompleteTeamPacks",
                                        "settings.SettingsMain.manageTeamPacks"
                                    )}
                                </IonText>
                            </li>
                            <li>
                                <IonText>
                                    {t("help.WhatsNewModal.general.minor")}
                                </IonText>
                            </li>
                        </ul>
                    </IonCardContent>
                </IonCard>
                <IonCard color="light">
                    <IonCardHeader>
                        <IonCardTitle>v6.0</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <div
                                style={{
                                    padding: "56.25% 0 0 0",
                                    position: "relative",
                                }}
                            >
                                <iframe
                                    src="https://player.vimeo.com/video/812763165?h=60103db823&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=0"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    title="REFSIX v6.0"
                                ></iframe>
                            </div>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                        <IonText style={{ marginTop: "10px" }}>
                            {t(
                                "help.WhatsNewModal.general.update_v6_0.phoneReDesign"
                            )}
                        </IonText>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
