import {
    MatchPhone,
    Templates,
    UserActionsCheckList,
    UserActionsCheckListDoc,
} from "refsix-js-models";
import { Settings } from "../../redux/models/refsixState";
import { DatabaseDocType } from "./refereeDatabase.service";

export type ProcessedDocs = {
    matches: MatchPhone[];
    settings: Settings;
    templates: Templates | undefined;
    userActionsCheckList: UserActionsCheckListDoc | undefined;
};

const NON_MATCH_PREFIX = "r6_";
export const SETTINGS_ID = "r6_settings";
export const TEMPLATES_ID = "r6_fixtureTemplates";

export const USER_ACTIONS_CHECKLIST_ID = "r6_userActionsCheckList";

export function emptyDb(db: any) {
    // definitely not from stackoverflow
    return db.allDocs().then(function (result: any) {
        return Promise.all(
            result.rows.map(function (row: any) {
                return db.remove(row.id, row.value.rev);
            })
        );
    });
}

export function processUserDocs(
    result: PouchDB.Core.AllDocsResponse<DatabaseDocType>
): ProcessedDocs {
    let matches: MatchPhone[] = [];
    let settings: Settings = new Settings();
    let templates: Templates | undefined = undefined;
    let userActionsCheckList: UserActionsCheckListDoc | undefined = undefined;

    result.rows.forEach((doc) => {
        // filter out design docs
        if (doc.id.indexOf("_") !== 0) {
            if (doc.id === USER_ACTIONS_CHECKLIST_ID) {
                userActionsCheckList = doc.doc as
                    | UserActionsCheckListDoc
                    | undefined;
            } else if (doc.id === SETTINGS_ID) {
                settings = doc.doc as Settings;
            } else if (doc.id === TEMPLATES_ID) {
                templates = doc.doc as Templates | undefined;
            } else if (doc.id.indexOf(NON_MATCH_PREFIX) !== 0) {
                // must be a normal match document
                // resArray.push(Fixture.build(el.doc)); // TODO port over fixture functionality to MatchPhone?
                matches.push(doc.doc as MatchPhone);
            }
        }
    });

    return { matches, settings, templates, userActionsCheckList };
}

/**
 * Taken from pouchdb and modified max backoff
 * https://github.com/pouchdb/pouchdb/blob/master/packages/node_modules/pouchdb-utils/src/defaultBackOff.js
 * @param min
 * @param max
 */
function randomNumber(min: number, max: number) {
    var maxTimeout = 60000; // REFSIX: Only modified line - now 1 minute
    // @ts-ignore
    min = parseInt(min, 10) || 0;
    // @ts-ignore
    max = parseInt(max, 10);
    if (max !== max || max <= min) {
        max = (min || 1) << 1; //doubling
    } else {
        max = max + 1;
    }
    // In order to not exceed maxTimeout, pick a random value between half of maxTimeout and maxTimeout
    if (max > maxTimeout) {
        min = maxTimeout >> 1; // divide by two
        max = maxTimeout;
    }
    var ratio = Math.random();
    var range = max - min;

    return ~~(range * ratio + min); // ~~ coerces to an int, but fast.
}

/**
 * Taken from pouchdb - not modified this wrapper
 * @param min the last backoff value
 */
export function pouchBackOff(min: number) {
    var max = 0;
    if (!min) {
        max = 2000;
    }
    return randomNumber(min, max);
}
