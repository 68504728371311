import React, {
    createContext,
    FC,
    useCallback,
    useEffect,
    useState,
} from "react";
import { FitnessDataType, GpsFilteredData, HrData } from "refsix-js-models";
import { getFilteredFitnessData } from "../../services/fitnessDataSync/fitnessDataSyncService";
import { useProfile } from "./index";
import { getPlayingSegments } from "refsix-core";
import { recalculateStatsForHrAndGps } from "../../services/matchService";
import * as Sentry from "@sentry/react";
import { useMatch } from "./match";

export interface FitnessDataContextValue {
    matchId: string;
    loading: boolean;
    gpsData: GpsFilteredData | undefined;
    hrData: HrData | undefined;
    reload: () => void;
}

export interface FitnessDataContextProviderProps {
    matchId: string;
}

const FitnessDataContext = createContext<FitnessDataContextValue>({
    matchId: "",
    loading: false,
    gpsData: undefined,
    hrData: undefined,
    reload: () => {},
});
export const useFitnessDataContext = () => React.useContext(FitnessDataContext);
export const FitnessDataProvider: FC<FitnessDataContextProviderProps> = ({
    matchId,
    children,
}: any) => {
    const profile = useProfile();
    const match = useMatch(matchId);

    const [loading, setLoading] = useState<boolean>(false);
    const [gpsData, setGpsData] = useState<GpsFilteredData | undefined>(
        undefined
    );
    const [hrData, setHrData] = useState<HrData | undefined>(undefined);

    const reload = useCallback(() => {
        const fn = async () => {
            if (matchId && profile?.username && match?.timings) {
                setLoading(true);
                const playingSegments = getPlayingSegments(match);
                try {
                    const filteredGpsData = await getFilteredFitnessData(
                        matchId,
                        playingSegments,
                        profile?.username,
                        FitnessDataType.GPS,
                        false
                    );
                    setGpsData(filteredGpsData as GpsFilteredData);
                } catch (e) {
                    console.log(e);
                }

                try {
                    const filteredHrData = await getFilteredFitnessData(
                        matchId,
                        playingSegments,
                        profile?.username,
                        FitnessDataType.HeartRate,
                        false
                    );
                    setHrData(filteredHrData as HrData);
                } catch (e) {
                    console.log(e);
                }
                setLoading(false);
            }
        };

        fn();
    }, [matchId, profile?.username, match?.timings]);

    useEffect(() => {
        // Update match stats if we have gps or hr but the flags were set to false
        if (!loading && match && profile) {
            recalculateStatsForHrAndGps(profile, match, hrData, gpsData).catch(
                (e) => {
                    console.log(e);
                    Sentry.captureMessage(
                        "Error updating match stats " + JSON.stringify(e)
                    );
                }
            );
        }
    }, [hrData, gpsData, loading, profile, match]);

    useEffect(() => {
        console.log("Refreshing data");
        reload();
    }, [match, matchId, profile?.username]);

    return (
        <FitnessDataContext.Provider
            value={{
                matchId,
                loading,
                gpsData,
                hrData,
                reload,
            }}
        >
            {children}
        </FitnessDataContext.Provider>
    );
};
