import {
    IonCard,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonToggle,
    useIonRouter,
} from "@ionic/react";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ConfigPhone } from "refsix-js-models";
import { RootState } from "../../../../redux/store";
import { routes } from "../../../../route/constants";

interface PreferencesProps {
    onPreferenceChange: (prop: Partial<ConfigPhone>) => void;
}

export const Preferences: FC<PreferencesProps> = ({ onPreferenceChange }) => {
    const { t } = useTranslation();

    const settings = useSelector((root: RootState) => root.settings);
    const {
        useImperial,
        use12HourTime,
        seasonDate,
        hide13,
        playerNumberPicker,
        showGoalOptions,
    } = settings.settings;
    const route = useIonRouter();

    return (
        <>
            <IonCard>
                <IonItem
                    className="item-title-container rounded-corners-top"
                    lines="full"
                >
                    {t("settings.SettingsMain.preferences")}
                </IonItem>
                <IonItem
                    data-testid="calendar-sync-button"
                    className="item-title"
                    button
                    lines="full"
                    onClick={() =>
                        route.push(routes.refsixMoreSettingsSyncCalendar)
                    }
                >
                    <IonLabel>
                        {t("settings.SettingsMain.addFixtureToCalendar")}
                    </IonLabel>
                </IonItem>
                <IonItem
                    data-testid="units-select-item"
                    className="item-title"
                    lines="full"
                >
                    <IonLabel>
                        {t("settings.SettingsMain.unitsOfMeasureLabel")}
                    </IonLabel>
                    <IonSelect
                        className="select-text"
                        value={useImperial ? "imperial" : "metric"}
                        onIonChange={(e) =>
                            onPreferenceChange({
                                useImperial: e.detail.value === "imperial",
                            })
                        }
                        interface="popover"
                    >
                        <IonSelectOption
                            data-testid="metric-option"
                            value="metric"
                            className="metric-option"
                        >
                            {t("settings.SettingsMain.unitsOfMeasure.metric")}
                        </IonSelectOption>
                        <IonSelectOption
                            data-testid="imperial-option"
                            value="imperial"
                            className="imperial-option"
                        >
                            {t("settings.SettingsMain.unitsOfMeasure.imperial")}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>

                <IonItem
                    data-testid="time-select-item"
                    className="item-title"
                    lines="full"
                >
                    <IonLabel>
                        {t("settings.SettingsMain.timeFormatsLabel")}
                    </IonLabel>
                    <IonSelect
                        className="select-text"
                        value={use12HourTime ? "hour12" : "hour24"}
                        onIonChange={(e) =>
                            onPreferenceChange({
                                use12HourTime: e.detail.value === "hour12",
                            })
                        }
                        interface="popover"
                    >
                        <IonSelectOption value="hour24">
                            {t("settings.SettingsMain.timeFormats.hour24")}
                        </IonSelectOption>
                        <IonSelectOption value="hour12">
                            {t("settings.SettingsMain.timeFormats.hour12")}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>

                <IonItem className="item-title" lines="full">
                    <IonLabel>
                        {t("settings.SettingsMain.seasonStartDate")}
                    </IonLabel>
                    <input
                        className="date-input"
                        type="date"
                        value={moment(seasonDate).format("YYYY-MM-DD")}
                        onChange={(e) =>
                            onPreferenceChange({
                                seasonDate: moment(
                                    e.currentTarget.value
                                ).toISOString(),
                            })
                        }
                    />
                </IonItem>

                <IonItem className="item-title" lines="full">
                    <IonLabel>{t("settings.SettingsMain.hide13")}</IonLabel>
                    <IonToggle
                        checked={hide13}
                        onIonChange={(e) =>
                            onPreferenceChange({
                                hide13: e.detail.checked,
                            })
                        }
                    />
                </IonItem>

                <IonItem className="item-title" lines="full">
                    <IonLabel>
                        {t("settings.SettingsMain.playerNumberPicker")}
                    </IonLabel>
                    <IonToggle
                        checked={playerNumberPicker}
                        onIonChange={(e) =>
                            onPreferenceChange({
                                playerNumberPicker: e.detail.checked,
                            })
                        }
                    />
                </IonItem>

                <IonItem
                    className="item-title rounded-corners-bottom"
                    lines="none"
                >
                    <IonLabel>
                        {t("settings.SettingsMain.showGoalOptions")}
                    </IonLabel>
                    <IonToggle
                        checked={showGoalOptions}
                        onIonChange={(e) =>
                            onPreferenceChange({
                                showGoalOptions: e.detail.checked,
                            })
                        }
                    />
                </IonItem>
            </IonCard>
        </>
    );
};
