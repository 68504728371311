import { IonRadioGroup } from "@ionic/react";
import { FC } from "react";
import { useController } from "react-hook-form";

interface R6IonRadioProps {
    name: string;
    rules?: any;
}

export const R6IonRadioGroup: FC<R6IonRadioProps> = ({
    name,
    rules,
    children,
}) => {
    const { field } = useController({
        name: name || "",
        rules,
    });

    const mappedField = {
        ...field,
        onIonChange: field.onChange,
    };

    return <IonRadioGroup {...mappedField}>{children}</IonRadioGroup>;
};
