import { IonCol, IonItem, IonText } from "@ionic/react";
import { FC } from "react";
import DateFormatFilterComponent from "../DateFormatFilterComponent";
import { AvailabilityType, RefAssistAvailability } from "refsix-js-models";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const AvailabilityComponent: FC<{
    availability: RefAssistAvailability;
    availabilityType: AvailabilityType;
}> = ({ availability, availabilityType }) => {
    const { t } = useTranslation();
    return (
        <>
            <IonItem lines="none" className="item-no-padding-start">
                <IonCol size="8">
                    <IonText color="primary" className="text-small align-start">
                        <DateFormatFilterComponent
                            value={moment(
                                availability.day,
                                "DD/MM/YYYY"
                            ).toISOString()}
                            formatString={"ddd ll"}
                        />
                    </IonText>
                    <IonText color="primary" className="text-small">
                        {`${availability.start} - ${availability.end}`}
                    </IonText>
                </IonCol>
                <IonCol size="4" style={{ textAlign: "end", padding: 0 }}>
                    <IonText className="text-small align-text-end dark-color">
                        {availabilityType === AvailabilityType.closedDate &&
                            t("availability.closedDate.title")}
                        {availabilityType === AvailabilityType.openDate &&
                            t("availability.closedDate.openDateTitle")}
                    </IonText>
                </IonCol>
            </IonItem>
            {availability.comments && (
                <IonItem lines="full" className="item-no-padding-start">
                    <IonCol>
                        <IonText className="dark-color text-small">
                            {availability.comments}
                        </IonText>
                    </IonCol>
                </IonItem>
            )}
        </>
    );
};
