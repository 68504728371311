import {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import React, { useEffect, useMemo } from "react";

import { getFilteredResults, getFilteredStats } from "../services/matchService";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { createDefaultFiltersSetting } from "./filters/filtersHelper";
import { MatchType } from "./filters/enums";
import { DatePreset } from "../redux/models/filtersModel";
import moment from "moment";
import { filter } from "lodash/fp";
import "../components/review/reviewSharedStyles.css";
import "../components/review/sharePreview.css";
import { closeOutline } from "ionicons/icons";
import {
    compact,
    hardestWorking,
    highestDistance,
    highestScoring,
    mostCards,
} from "../utils/filters/review";
import HardestMatchReview from "../components/review/hardestMatchReview";
import MostCardsReview from "../components/review/mostCardsReview";
import DistanceReview from "../components/review/distanceReview";
import DistanceTotalReview from "../components/review/distanceTotalReview";
import TopMatchesReview from "../components/review/topMatchesReview";
import HighestScoringMatchReview from "../components/review/highestScoringMatch";
import { routes } from "../route/constants";
import { useTranslation } from "react-i18next";
import { useIsPro } from "../components/hooks";
import StartReview from "../components/review/startReview";
import { Story, StoryPlayer } from "../components/review/storyPlayer";
import { MatchPhone } from "refsix-js-models";
import { mpUpdatePersonOnce } from "../services/analytics/mixpanelService";
import { trackEvent } from "../services/analytics/analyticsService";
import { uniq } from "lodash";

interface Props {
    // Define your props here
}

export enum ReviewShareType {
    story = "review-share-story",
    feed = "review-share-feed",
}

// Function that filters out all non truthy values

// Where stat is a property of MatchStats
export const planStoryMap: StoryTypeMap = {
    freeUser: [
        "StartReview",
        "TopMatchesReview",
        "HighestScoringMatchReview",
        "MostCardsReview",
        "HardestMatchReview",
        "DistanceReview",
        "DistanceTotalReview",
        "EndReview",
    ],
    proUser: [
        "StartReview",
        "DistanceTotalReview",
        "HighestScoringMatchReview",
        "MostCardsReview",
        "HardestMatchReview",
        "DistanceReview",
        "TopMatchesReview",
        "EndReview",
    ],
};

export const planShareMap: StoryTypeMap = {
    // override some sharing screens for free users
    freeUser: [
        "StartReview",
        "TopMatchesReview",
        "HighestScoringMatchReview",
        "StartReview",
        "StartReview",
        "StartReview",
        "StartReview",
        "StartReview",
    ],
    proUser: planStoryMap.proUser,
};
type StoriesMap = {
    StartReview: React.ReactNode;
    EndReview: React.ReactNode;
    DistanceTotalReview: React.ReactNode;
    HighestScoringMatchReview: React.ReactNode;
    MostCardsReview: React.ReactNode;
    HardestMatchReview: React.ReactNode;
    DistanceReview: React.ReactNode;
    TopMatchesReview: React.ReactNode;
};
type UserType = "freeUser" | "proUser";
type StoryType = keyof StoriesMap;
type StoryTypeMap = { [s in UserType]: StoryType[] };

const filterByDate = (startDate: string, endDate: string) => {
    return filter((match: MatchPhone) => {
        return (
            moment(match.date).isSameOrAfter(startDate) &&
            moment(match.date).isSameOrBefore(endDate)
        );
    });
};
const ReviewPage: React.FC<Props> = (props) => {
    const startDate = moment("2024-01-01").startOf("day").toISOString();
    const endDate = moment("2024-12-31").endOf("day").toISOString();

    const filters = useMemo(
        () => ({
            ...createDefaultFiltersSetting(MatchType.Result),
            datePreset: DatePreset.Custom,
            // generate moment date for the start of the year 2023
            startDate,
            endDate,
        }),
        [startDate, endDate]
    );

    useEffect(() => {
        mpUpdatePersonOnce({ viewedReview2023: true });
    }, []);

    const router = useIonRouter();
    const { t } = useTranslation();

    const allMatches = useSelector((state: RootState) => state.matches.matches);

    const matches = useMemo(() => {
        return getFilteredResults(filters, allMatches);
    }, [allMatches, filters]);

    const aggregatedStats = useMemo(() => {
        return getFilteredStats(filters, matches);
    }, [matches, filters]);
    const matchIds = useMemo(() => {
        return {
            highestDistanceMatchId: highestDistance(matches),
            highestScoringMatchId: highestScoring(matches),
            mostCardsMatchId: mostCards(matches),
            hardestWorkingMatchId: hardestWorking(matches),
        };
    }, [matches]);

    useEffect(() => {
        if (aggregatedStats && matches && matchIds) {
            const payload = {
                ...matchIds,
                numberOfUniqueMatches: uniq(Object.values(matchIds)).length,
            };
            console.log("track payload", payload);
            trackEvent("ReviewPageOpened", {
                numberOfUniqueMatches: uniq(Object.values(matchIds)).length,
            });
        }
    }, [aggregatedStats, matches, matchIds]);

    const storiesMap: StoriesMap = useMemo(() => {
        return {
            StartReview: (
                <StartReview
                    aggregatedStats={aggregatedStats}
                    matches={matches}
                    name="StartReview"
                ></StartReview>
            ),
            EndReview: (
                <StartReview
                    aggregatedStats={aggregatedStats}
                    matches={matches}
                    name="EndReview"
                ></StartReview>
            ),
            DistanceTotalReview: (
                <DistanceTotalReview
                    aggregatedStats={aggregatedStats}
                ></DistanceTotalReview>
            ),
            HighestScoringMatchReview: (
                <HighestScoringMatchReview
                    matchId={matchIds.highestScoringMatchId}
                    aggregatedStats={aggregatedStats}
                />
            ),
            MostCardsReview: (
                <MostCardsReview
                    matchId={matchIds.mostCardsMatchId}
                    aggregatedStats={aggregatedStats}
                />
            ),
            HardestMatchReview: (
                <HardestMatchReview
                    matchId={matchIds.hardestWorkingMatchId}
                    aggregatedStats={aggregatedStats}
                />
            ),
            DistanceReview: (
                <DistanceReview
                    matchId={matchIds.highestDistanceMatchId}
                    aggregatedStats={aggregatedStats}
                />
            ),
            TopMatchesReview: (
                <TopMatchesReview
                    highestScoringMatchId={matchIds.highestScoringMatchId}
                    mostCardsMatchId={matchIds.mostCardsMatchId}
                    highestDistanceMatchId={matchIds.highestDistanceMatchId}
                    hardestWorkingMatchId={matchIds.hardestWorkingMatchId}
                ></TopMatchesReview>
            ),
        };
    }, [aggregatedStats, matchIds, matches]);

    const isPro = useIsPro();
    const storyNames = useMemo(
        () => planStoryMap[isPro ? "proUser" : "freeUser"],
        [isPro]
    );

    const shareNames = useMemo(
        () => planShareMap[isPro ? "proUser" : "freeUser"],
        [isPro]
    );

    const stories = useMemo(() => {
        //const mapping = userTypeMap[isPro ? "proUser" : "freeUser"];
        return compact(storyNames.map((storyType) => storiesMap[storyType]));
    }, [storiesMap, storyNames]);
    console.log(storyNames);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle color={"primary"}>
                        2024 {t("review.yearInReview")}
                    </IonTitle>
                    <IonButtons
                        style={{ position: "absolute", right: 10 }}
                        slot="end"
                        onClick={() => router.push(routes.refsixFixtures)}
                    >
                        <IonIcon icon={closeOutline} size="large" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} scrollY={false}>
                <div
                    // style={{
                    //     position: "relative",
                    //     height: "calc(100vh - 43px - env(safe-area-inset-bottom))",
                    // }}
                    className="story-player-container"
                >
                    <StoryPlayer
                        storyNames={storyNames}
                        shareNames={shareNames}
                    >
                        {stories.map((story, idx) => (
                            <Story key={idx}>{story}</Story>
                        ))}
                    </StoryPlayer>
                </div>
            </IonContent>
        </IonPage>
    );
};

// A sharable page, with a header, content block and footer
export default ReviewPage;
