import {
    IonCard,
    IonContent,
    IonItem,
    IonList,
    IonPage,
    useIonRouter,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../../components";
import { URLS } from "../../../constants/urls";
import { routes } from "../../../route/constants";
import { getNoltToken } from "../../../services/profileService";
import { isAndroid, isIos } from "../../../services/platformDetection";

export const Support: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();

    const [customerToken, setCustomerToken] = useState("");

    useEffect(() => {
        getNoltToken().then((token) => {
            setCustomerToken(token);
        });
    }, []);

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                defaultHref={routes.refsixMore}
                title={t("more.menu.support")}
                titleTestId="support-page-header"
            />
            <IonContent>
                <IonCard>
                    <IonList>
                        <IonItem
                            button
                            href={URLS.FAQS}
                            target="_system"
                            className="item-title-container"
                            lines="full"
                            data-testid="faqs-button"
                        >
                            {t("help.Help.questionsTitle")}
                        </IonItem>
                        <IonItem
                            button
                            href={`${URLS.FEEDBACK}${customerToken}`}
                            target="_system"
                            className="item-title-container"
                            lines="full"
                            data-testid="features-request-button"
                        >
                            {t("help.menu.features")}
                        </IonItem>
                        {(isIos() || isAndroid()) && (
                            <IonItem
                                button
                                onClick={() =>
                                    route.push(routes.refsixMoreWatchDebug)
                                }
                                className="item-title-container"
                                lines="full"
                            >
                                {t("more.watchDebug.watchDebugger")}
                            </IonItem>
                        )}
                        <IonItem
                            button
                            onClick={() =>
                                route.push(routes.refsixMoreSupportContact)
                            }
                            className="item-title-container"
                            lines="full"
                            data-testid="contact-button"
                        >
                            {t("help.menu.contact")}
                        </IonItem>
                        <IonItem
                            button
                            href={URLS.TERMSCONDITIONS}
                            target="_system"
                            className="item-title-container"
                            lines="full"
                            data-testid="terms-button"
                        >
                            {t("help.menu.termsAndConditions")}
                        </IonItem>
                        <IonItem
                            button
                            href={URLS.PRIVACYPOLICY}
                            target="_system"
                            className="item-title-container"
                            lines="full"
                            data-testid="privacy-button"
                        >
                            {t("help.menu.privacyPolicy")}
                        </IonItem>
                    </IonList>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
