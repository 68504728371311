import geodesy from "geodesy";
import {
    GpsFilteredData,
    GpsPoint,
    HalfEvent,
    PossibleSprint,
    Sprint,
} from "refsix-js-models";
import {
    DecimalFilter,
    SpeedCategoryFilter,
    SprintCategoryFilter,
} from "../filters";
import { SprintCategoryConstants } from "./SprintCategoryConstants";

const LatLon = geodesy.LatLonEllipsoidal;

export class GpsProcessingService {
    static readonly DEBUG_ENABLED = false;
    static readonly BOLT_CONSTANT = 12.5; // Max speed of USAIN sprinting in m/s
    static readonly ACCURACY_PERCENTILE = 0.99; // Try an use accuracy threshold that covers 90% of data
    static readonly ACCURACY_MAX_TOLERANCE = 20; // Upper bound of the accuracy we accept
    static readonly MIN_SPRINT_DURATION = 2000; // Upper bound of the accuracy we accept

    constructor() {}

    _toLatLon(point) {
        if (point instanceof LatLon) {
            return point;
        } else {
            return new LatLon(point.latitude, point.longitude);
        }
    }

    _buildSpeedsCategories() {
        return {
            stand: 0,
            walk: 0,
            jog: 0,
            run: 0,
            fastRun: 0,
            sprint: 0,
        };
    }

    _distanceBetweenPoints(pointA, pointB) {
        var a = new LatLon(pointA.latitude, pointA.longitude);
        var b = new LatLon(pointB.latitude, pointB.longitude);
        return a.distanceTo(b) || 0; // stupidly returns undefined if no difference - make that 0
    }

    _2dp(val) {
        return DecimalFilter()(val, 2);
    }

    /**
     *
     * @param speed in metres per second
     * @returns {*} Speed category name
     * @private
     */
    _speedToCategory(speed): string {
        return SpeedCategoryFilter()(speed);
    }

    _buildMinuteStats(matchTimings, minute, firstTimestamp) {
        return {
            minuteFromKickoff: minute,
            segment: this._minuteOffsetToSegment(matchTimings, firstTimestamp),
            distance: 0,
            speedMax: 0,
            speedAvg: 0,
            heartRateMax: 0,
            heartRateAvg: 0,
            bySpeedCategory: {
                distance: this._buildSpeedsCategories(),
                duration: this._buildSpeedsCategories(),
            },
        };
    }

    _updateMinuteStats(stats, currentDistance, currentSpeed, currentInterval) {
        stats.distance += currentDistance;
        stats.speedMax = Math.max(stats.speedMax, currentSpeed);
        var speedCat = this._speedToCategory(currentSpeed);
        if (speedCat) {
            stats.bySpeedCategory.distance[speedCat] += currentDistance;
            stats.bySpeedCategory.duration[speedCat] += currentInterval;
        }
        return stats;
    }

    _finaliseMinuteStats(stats, speedSum, speedCount) {
        if (stats) {
            if (speedSum && speedCount) {
                stats.speedAvg = this._2dp(speedSum / speedCount);
            }
            stats.distance = this._2dp(stats.distance);
            stats.speedMax = this._2dp(stats.speedMax);
            stats.speedAvg = this._2dp(stats.speedAvg);
        }
        return stats;
    }

    //TODO this function is used on both Gps and HR processing
    _minuteOffsetToSegment(matchTimings, gpsTimestamp) {
        if (matchTimings && matchTimings.length && gpsTimestamp) {
            for (var i = 0; i < matchTimings.length; i++) {
                var segment = matchTimings[i];
                if (
                    segment.timestamp <= gpsTimestamp &&
                    segment.endTime >= gpsTimestamp
                ) {
                    return i;
                }
            }
        }
        return undefined;
    }

    /**
     * Checks if GPS timestamp is during active play. Sometimes the device GPS serves a cached result for speed.
     * @param matchTimings
     * @param gpsTimestamp
     * @returns {boolean}
     * @private
     */
    _isPointDuringPlay(matchTimings, gpsTimestamp) {
        var segment = this._minuteOffsetToSegment(matchTimings, gpsTimestamp);
        return typeof segment === "number" && segment >= 0;
    }

    /**
     * Basic validation that the gps timestamp is during play and the accuracy is within tolerance.
     * @private
     */
    _hasValidTimestampAndAccuracy(matchTimings, geoPoint, accuracyThreshold) {
        return (
            this._isPointDuringPlay(matchTimings, geoPoint.time) &&
            geoPoint.accuracy <= accuracyThreshold
        );
    }

    _percentile(arr, p) {
        if (arr.length === 0) return 0;
        if (typeof p !== "number") throw new TypeError("p must be a number");
        if (p <= 0) return arr[0];
        if (p >= 1) return arr[arr.length - 1];

        arr.sort(function (a, b) {
            return a - b;
        });
        var index = (arr.length - 1) * p;
        var lower = Math.floor(index);
        var upper = lower + 1;
        var weight = index % 1;

        if (upper >= arr.length) return arr[lower];
        return arr[lower] * (1 - weight) + arr[upper] * weight;
    }

    _calculateAccuracyThreshold(datapoints) {
        var accuracies: number[] = [];
        datapoints.forEach(function (el: any) {
            if (typeof el.accuracy === "number") {
                accuracies.push(el.accuracy);
            }
        });
        return Math.min(
            GpsProcessingService.ACCURACY_MAX_TOLERANCE,
            this._percentile(
                accuracies,
                GpsProcessingService.ACCURACY_PERCENTILE
            )
        );
    }

    /**
     * Calculates center position of GPS points and the radius of the furthest point away
     * @param geoPoints
     * @returns {{center: LatLon, radius: number}}
     */
    positionSummary(geoPoints: GpsPoint[]) {
        const self = this;
        var latSum = 0,
            lonSum = 0,
            validPoints = 0;
        geoPoints.forEach(function (point) {
            if (
                !point.accuracy ||
                point.accuracy <= GpsProcessingService.ACCURACY_MAX_TOLERANCE
            ) {
                latSum += point.latitude;
                lonSum += point.longitude;
                validPoints++;
            }
        });
        var center = validPoints
            ? new LatLon(latSum / validPoints, lonSum / validPoints)
            : null;
        var diameter = geoPoints.reduce(function (accumulator, point) {
            if (
                !point.accuracy ||
                point.accuracy <= GpsProcessingService.ACCURACY_MAX_TOLERANCE
            ) {
                return Math.max(
                    accumulator,
                    center.distanceTo(self._toLatLon(point))
                );
            }
            return accumulator;
        }, 0);
        var data = {
            center: center,
            radius: diameter / 2,
        };
        console.log("positionSummary", data);
        return data;
    }

    /**
     * Calculates performance stats from GPS data (speed, distance, etc)
     * @param matchTimings
     * @param geoPoints
     * @param accuracyThreshold
     * */
    calculateStats(matchTimings, geoPoints, accuracyThreshold) {
        var len = geoPoints.length - 1;

        var startDate = new Date(
            matchTimings.sort(function (a, b) {
                return a.index < b.index ? -1 : 1;
            })[0].timestamp
        ); // from official kick off

        // match stats
        var minAccuracy = 1000;
        var maxAccuracy = 0;
        var totDist = 0;
        var maxSpeed = 0;
        var maxGpsSpeed = 0;
        var bySpeedCategory = {
            distance: this._buildSpeedsCategories(),
            duration: this._buildSpeedsCategories(),
        };

        var minuteStats = {};

        // workings
        var currentMinute = 0;
        var discarded = 0;
        var speedSum = 0;
        var minuteSpeedSum = 0;
        var minuteSpeedCount = 0;
        var lastGoodPoint;

        for (var i = 0; i <= len; i++) {
            var geoPoint = geoPoints[i];

            minAccuracy = Math.min(geoPoint.accuracy, minAccuracy);
            maxAccuracy = Math.max(geoPoint.accuracy, maxAccuracy);

            if (!lastGoodPoint) {
                lastGoodPoint = geoPoint;
                continue;
            }

            // Calculate time since kickoff (including half time etc)
            var minuteOffset = Math.floor(
                (new Date(geoPoint.time).getTime() - startDate.getTime()) /
                    1000 /
                    60
            );

            if (lastGoodPoint) {
                var lastPoint = lastGoodPoint;

                // compare current and last point
                var distanceDiff = this._distanceBetweenPoints(
                    geoPoint,
                    lastPoint
                );
                var timeDiff =
                    (new Date(geoPoint.time).getTime() -
                        new Date(lastPoint.time).getTime()) /
                    1000;
                var speed = distanceDiff / timeDiff;

                if (speed > GpsProcessingService.BOLT_CONSTANT) {
                    discarded++;
                    continue;
                }

                if (currentMinute > minuteOffset) {
                    currentMinute = minuteOffset;
                }

                // calculate minute stats
                if (!minuteStats[minuteOffset]) {
                    // we have entered a new minute!
                    // finalise previous minute's stats
                    this._finaliseMinuteStats(
                        minuteStats[currentMinute],
                        minuteSpeedSum,
                        minuteSpeedCount
                    );
                    minuteSpeedSum = 0;
                    minuteSpeedCount = 0;
                    currentMinute = minuteOffset;
                    // setup this minute
                    minuteStats[minuteOffset] = this._buildMinuteStats(
                        matchTimings,
                        minuteOffset,
                        geoPoint.time
                    );
                }
                this._updateMinuteStats(
                    minuteStats[minuteOffset],
                    distanceDiff,
                    speed,
                    timeDiff
                );
                minuteSpeedSum += speed;
                minuteSpeedCount++;

                // calculate match stats
                if (typeof speed !== "undefined" && speed > 0) {
                    speedSum += speed;
                    maxSpeed = Math.max(maxSpeed, speed);
                    bySpeedCategory.distance[this._speedToCategory(speed)] +=
                        distanceDiff;
                    bySpeedCategory.duration[this._speedToCategory(speed)] +=
                        timeDiff;
                }

                if (distanceDiff > 0) totDist += distanceDiff;
            }
            maxGpsSpeed = Math.max(maxGpsSpeed, geoPoint.speed);
            lastGoodPoint = geoPoint;
        }
        this._finaliseMinuteStats(
            minuteStats[currentMinute],
            minuteSpeedSum,
            minuteSpeedCount
        ); // straggler

        var validPointCount = len - discarded;

        return {
            matchStats: {
                speedMax: this._2dp(maxSpeed),
                speedAvg: this._2dp(speedSum / (validPointCount - 1)), // -1 because it takes 2 points to tango and first had a headache
                distance: this._2dp(totDist),
                totalPoints: len,
                validPoints: validPointCount,
                bySpeedCategory: bySpeedCategory,
                accuracyThreshold: accuracyThreshold,
            },
            minuteStats: Object.values(minuteStats),
        };
    }

    /**
     * Static validation of parameters
     * @param data
     * @returns data
     * @private
     */
    _validateParameters(data) {
        if (!data) {
            console.log("Data is missing");
            return null;
        }

        var err = "";

        if (data.geoPoints) {
            if (
                GpsProcessingService.DEBUG_ENABLED == false &&
                data.geoPoints.length < 100
            ) {
                err += "Tracking points less than 100 for match " + data._id;
            }
        } else {
            err += "Missing Data Points.";
        }

        if (!data.matchTimings) {
            err += "Match timing information not available.";
        }

        if (err != "") {
            console.log(err);
            console.log(err);
            return null;
        }
        console.log("Data: OK");
        return data;
    }

    _checkSprintTime(sprints) {
        if (sprints.length > 1) {
            if (
                sprints[sprints.length - 1].point.time -
                    sprints[0].point.time >=
                GpsProcessingService.MIN_SPRINT_DURATION
            ) {
                return true;
            }
        }
        return false;
    }

    _addNewSprint(
        matchTimings: HalfEvent[],
        sprints: Sprint<number>[],
        possibleSprint: PossibleSprint,
        sprintDistance: number
    ) {
        var pointsIndex = possibleSprint.map(function (sprintPoint) {
            return sprintPoint.index;
        });
        var avgSpeed = possibleSprint.reduce(function (acc, sprintPoint) {
            return acc + (sprintPoint.point.speed || 0);
        }, 0);

        avgSpeed = avgSpeed / possibleSprint.length;
        var category: any = SprintCategoryFilter()(avgSpeed);
        sprints.push({
            distance: sprintDistance,
            level: category.level,
            points: pointsIndex,
            segment: this._minuteOffsetToSegment(
                matchTimings,
                possibleSprint[0].point.time
            ),
        });
    }

    calculateSprints(matchTimings: HalfEvent[], gpsPoints: GpsPoint[]) {
        const sprints: Sprint<number>[] = [];
        var prevSprint,
            possibleSprint: PossibleSprint = [],
            sprintDistance = 0;

        for (let i = 0; i < gpsPoints.length; i++) {
            let point = gpsPoints[i];

            if (
                point.speed &&
                point.speed >= SprintCategoryConstants.low.lowerBound &&
                point.speed < SprintCategoryConstants.high.upperBound
            ) {
                possibleSprint.push({
                    point: point,
                    index: i,
                });
                if (possibleSprint.length > 1) {
                    sprintDistance += this._distanceBetweenPoints(
                        possibleSprint[possibleSprint.length - 1].point,
                        possibleSprint[possibleSprint.length - 2].point
                    );
                }
                if (
                    i === gpsPoints.length - 1 &&
                    this._checkSprintTime(possibleSprint)
                ) {
                    this._addNewSprint(
                        matchTimings,
                        sprints,
                        possibleSprint,
                        sprintDistance
                    );
                }
            } else if (this._checkSprintTime(possibleSprint)) {
                this._addNewSprint(
                    matchTimings,
                    sprints,
                    possibleSprint,
                    sprintDistance
                );
                possibleSprint = [];
                sprintDistance = 0;
                prevSprint = undefined;
            } else {
                possibleSprint = [];
                sprintDistance = 0;
                prevSprint = undefined;
            }
        }
        return {
            sprints: sprints,
        };
    }

    filterOutInvalidPoints(geoPoints: GpsPoint[]): GpsPoint[] {
        return geoPoints.filter(function (point) {
            if (
                point && // tizen sometimes gives us nulls
                !!(point.latitude || point.latitude === 0) && // filter nulls, NaNs and undefined
                !!(point.longitude || point.longitude === 0) &&
                point.latitude >= -90 &&
                point.latitude <= 90 &&
                point.longitude >= -180 &&
                point.longitude <= 180
            ) {
                return true;
            } else {
                return false;
            }
        });
    }
    /**
     * Filters invalid points based on _validateParameters and _hasValidTimestampAndAccuracy
     * @param rawData
     * @param matchTimings
     * @returns gpsData
     */
    filterInvalidPoints(rawData, matchTimings): GpsFilteredData | null {
        var self = this;
        rawData.matchTimings = matchTimings;
        var gpsData = this._validateParameters(rawData);
        if (!gpsData) {
            console.log("no stats");
            return null;
        }

        gpsData.geoPoints = this.filterOutInvalidPoints(gpsData.geoPoints);

        gpsData.accuracyThreshold = this._calculateAccuracyThreshold(
            gpsData.geoPoints
        );

        gpsData.geoPoints = gpsData.geoPoints.filter(function (geoPoint) {
            return self._hasValidTimestampAndAccuracy(
                matchTimings,
                geoPoint,
                gpsData.accuracyThreshold
            );
        });
        gpsData.geoPoints = this._postProcessData(gpsData.geoPoints);
        return gpsData;
    }

    sortGpsData(data) {
        data.geoPoints = data.geoPoints
            .filter(function (gpsPoint) {
                return (
                    gpsPoint !== null &&
                    typeof gpsPoint !== "undefined" &&
                    gpsPoint.accuracy !== 0
                );
            })
            .sort(function (a, b) {
                return a.time - b.time;
            });

        return data;
    }

    sumHalfProperty(minuteStats, segmentIndex, property) {
        return minuteStats
            .filter(function (el) {
                return el.segment === segmentIndex;
            })
            .reduce(function (stat, val) {
                if (typeof stat[property] === "undefined") {
                    stat[property] = 0;
                }
                if (val[property] > 0) {
                    stat[property] += val[property];
                }
                return stat;
            }, {});
    }

    _postProcessData(geoPoints: GpsPoint[]): GpsPoint[] {
        const processedPoints: any[] = [];
        for (let i = 1; i < geoPoints.length; i++) {
            const currentPoint = geoPoints[i];
            const lastPoint = geoPoints[i - 1];

            if (!currentPoint.time || !lastPoint.time) {
                continue;
            }

            const distanceDiff = this._distanceBetweenPoints(
                currentPoint,
                lastPoint
            );

            const timeDiff = (currentPoint.time - lastPoint.time) / 1000;

            const speed = distanceDiff / timeDiff;

            if (speed < GpsProcessingService.BOLT_CONSTANT) {
                processedPoints.push({
                    ...currentPoint,
                    speed, // TODO are we using this or re-calculating?
                });
            }
        }

        return processedPoints;
    }
}

export default GpsProcessingService;
