import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonSpinner,
    IonTitle,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { shareScreenshotImage } from "../utils/share";
import { ReviewShareType } from "./reviewPage";
import "../components/review/sharePreview.css";
import { trackEvent } from "../services/analytics/analyticsService";

type ReviewShareModalProps = {
    children: React.ReactNode;
    dismiss: () => void;
    storyName: string;
    isOpen: boolean;
};

export const ReviewShareModal: FC<ReviewShareModalProps> = ({
    children,
    dismiss,
    storyName,
    isOpen,
}) => {
    const [numInteractions, setNumInteractions] = React.useState(0);
    const { t } = useTranslation();
    const [isGeneratingScreenshot, setIsGeneratingScreenshot] =
        React.useState(false);
    const [selected, setSelected] = React.useState<ReviewShareType>(
        ReviewShareType.story
    );
    const [hasGeneratedScreenshot, setHasGeneratedScreenshot] =
        React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);
    const router = useIonRouter();

    const makeScreenshot = () => {
        if (!ref.current) {
            console.log("No ref");
            return;
        }
        const resolution =
            selected === ReviewShareType.story
                ? {
                      // width: 1080,
                      // height: 1920,
                      width: 360,
                      height: 640,
                  }
                : {
                      // width: 1080,
                      // height: 1080,
                      width: 360,
                      height: 360,
                  };

        // use a timeout to ensure the button starts spinning immediately
        setTimeout(() => {
            if (!ref.current) {
                setIsGeneratingScreenshot(false);
                return;
            }
            shareScreenshotImage(ref.current, resolution)
                // .then(props.close)
                .finally(() => {
                    setIsGeneratingScreenshot(false);
                    dismiss();
                });

            trackEvent("ReviewShared", {
                page: storyName,
                type: selected,
                numInteractions,
            });
        }, 100);
    };

    useEffect(() => {
        if (
            isOpen &&
            ref.current &&
            !isGeneratingScreenshot &&
            !hasGeneratedScreenshot
        ) {
            setIsGeneratingScreenshot(true);
            setHasGeneratedScreenshot(true);
            setTimeout(() => {
                makeScreenshot();
            }, 1000);
        }
    }, [isGeneratingScreenshot, ref, hasGeneratedScreenshot, isOpen]);

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t("review.share")}</IonTitle>
                    <IonButtons
                        style={{ position: "absolute", right: 10 }}
                        slot="end"
                        onClick={(event) => {
                            console.log(event);
                            dismiss();
                            // event.target.dismiss()
                        }}
                    >
                        <IonIcon icon={closeOutline} size="large" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div
                    style={{
                        maxWidth: "100%",
                        height: "100%",
                        background: "black",
                    }}
                >
                    {/*
                    import styles from "./reviewHeader.module.css";

                    */}
                    <div
                        style={{
                            border: isGeneratingScreenshot
                                ? "1px solid black"
                                : "1px solid grey",
                        }}
                        className={`${
                            selected === ReviewShareType.feed
                                ? ReviewShareType.feed
                                : ReviewShareType.story
                        } review-share-preview`}
                    >
                        <div
                            // style={{ marginTop: -58 }}
                            className="review-share-preview-content"
                            ref={ref}
                        >
                            {children}
                        </div>
                    </div>
                    {/* Create 2 large buttons with Icons inside */}
                    {/* TODO: might be best to do IonSegment? */}
                    <div
                        style={{
                            position: "fixed",
                            bottom: "env(safe-area-inset-bottom)",
                            left: 0,
                            right: 0,
                            background: "black",
                        }}
                    >
                        {/*<IonSegment*/}
                        {/*    mode="ios"*/}
                        {/*    value={selected}*/}
                        {/*    onIonChange={(e) => {*/}
                        {/*        setNumInteractions(numInteractions + 1);*/}
                        {/*        return (*/}
                        {/*            e.detail.value !== undefined &&*/}
                        {/*            setSelected(*/}
                        {/*                e.detail.value as ReviewShareType*/}
                        {/*            )*/}
                        {/*        );*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <IonSegmentButton*/}
                        {/*        value={ReviewShareType.story}*/}
                        {/*        className="share-size"*/}
                        {/*    >*/}
                        {/*        <IonIcon icon={phonePortrait} size="large" />*/}
                        {/*    </IonSegmentButton>*/}
                        {/*    <IonSegmentButton*/}
                        {/*        value={ReviewShareType.feed}*/}
                        {/*        className="share-size"*/}
                        {/*    >*/}
                        {/*        <IonIcon icon={square} size="large" />*/}
                        {/*    </IonSegmentButton>*/}
                        {/*</IonSegment>*/}
                        <div>
                            {/*<IonButton*/}
                            {/*    style={{ width: "100%" }}*/}
                            {/*    expand="block"*/}
                            {/*    disabled={isGeneratingScreenshot}*/}
                            {/*    onClick={() => {*/}
                            {/*        if (!ref.current) {*/}
                            {/*            return;*/}
                            {/*        }*/}
                            {/*        const resolution =*/}
                            {/*            selected === ReviewShareType.story*/}
                            {/*                ? {*/}
                            {/*                      // width: 1080,*/}
                            {/*                      // height: 1920,*/}
                            {/*                      width: 360,*/}
                            {/*                      height: 640,*/}
                            {/*                  }*/}
                            {/*                : {*/}
                            {/*                      // width: 1080,*/}
                            {/*                      // height: 1080,*/}
                            {/*                      width: 360,*/}
                            {/*                      height: 360,*/}
                            {/*                  };*/}
                            {/*        setIsGeneratingScreenshot(true);*/}

                            {/*        // use a timeout to ensure the button starts spinning immediately*/}
                            {/*        setTimeout(() => {*/}
                            {/*            if (!ref.current) {*/}
                            {/*                setIsGeneratingScreenshot(false);*/}
                            {/*                return;*/}
                            {/*            }*/}
                            {/*            shareScreenshotImage(*/}
                            {/*                ref.current,*/}
                            {/*                resolution*/}
                            {/*            )*/}
                            {/*                // .then(props.close)*/}
                            {/*                .finally(() => {*/}
                            {/*                    setIsGeneratingScreenshot(*/}
                            {/*                        false*/}
                            {/*                    );*/}
                            {/*                });*/}

                            {/*            trackEvent("ReviewShared", {*/}
                            {/*                page: storyName,*/}
                            {/*                type: selected,*/}
                            {/*                numInteractions,*/}
                            {/*            });*/}
                            {/*        }, 100);*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <span*/}
                            {/*        hidden={isGeneratingScreenshot}*/}
                            {/*        style={{*/}
                            {/*            display: "flex",*/}
                            {/*            justifyContent: "center",*/}
                            {/*            alignItems: "center",*/}
                            {/*            gap: 5,*/}
                            {/*            fontSize: 17,*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <IonIcon*/}
                            {/*            icon={shareOutline}*/}
                            {/*            className="share-button-icon"*/}
                            {/*        />{" "}*/}
                            {/*        {t("review.share")}*/}
                            {/*    </span>*/}
                            {isGeneratingScreenshot && (
                                <div style={{ textAlign: "center" }}>
                                    <IonSpinner
                                        name="crescent"
                                        style={{ width: 50, height: 50 }}
                                    />
                                </div>
                            )}
                            {/*</IonButton>*/}
                        </div>
                    </div>
                </div>
            </IonContent>
        </>
    );
};
