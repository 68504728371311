import {
    IonCard,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonProgressBar,
    useIonRouter,
} from "@ionic/react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../components";
import { SelectWatchComponent } from "../../components/SelectWatchComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { routes } from "../../route/constants";
import { isIos } from "../../services/platformDetection";

export const OnBoardingSelectWatch: FC = () => {
    const { t } = useTranslation();
    const route = useIonRouter();

    const watchStatus = useSelector((state: RootState) => state.watchStatus);

    useEffect(() => {
        if (watchStatus.isPaired) {
            const platform = isIos() ? "apple" : "wearos";
            route.push(
                routes.refsixOnboardingFoundWatch + `/${platform}`,
                "forward",
                "replace"
            );
        }
    }, [watchStatus.isPaired]);

    return (
        <IonPage>
            <HeaderComponent
                titleTestId="select-watch-header"
                title={t("help.onBoarding.step0a")}
            />
            <IonContent>
                <IonCard>
                    <IonProgressBar style={{ marginTop: 10 }} value={0.1} />
                </IonCard>
                <IonCard color="transparet">
                    <IonItem lines="none" color="transparet">
                        <IonLabel
                            className="align-center text-weight-bold"
                            color="primary"
                        >
                            {t("help.onBoarding.noWatchFound")}
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="none" color="transparet">
                        <IonLabel className="align-center">
                            {t("help.onBoarding.pairWatchNow")}
                        </IonLabel>
                    </IonItem>
                </IonCard>

                <SelectWatchComponent />
            </IonContent>
        </IonPage>
    );
};
