import { AppleWatchConnectivity } from "refsix-apple-watch-connectivity";
import { App } from "@capacitor/app";
import { mpUpdatePerson } from "../analytics/mixpanelService";
import { store } from "../../redux/store";
import * as Sentry from "@sentry/react";
import { ConfigPhone, WatchBluetoothStatus } from "refsix-js-models";
import { setWatchStatus } from "../../redux/actions/watchStatusAction";
import { isAndroid, isIos } from "../platformDetection";

export enum WatchOptions {
    WEAR_OS = "wearOS",
    APPLE_WATCH = "watchOS",
    TIZEN = "tizen",
    FITBIT = "fitbit",
    GARMIN = "garmin",
}

export class WatchStatus {
    WEB_WATCH_SUFFIX = "Web";
    // TODO the android watch will have a different status object but same props
    detailedStatus = false;

    constructor() {
        this._setupDevice();
        if (isIos() || isAndroid()) {
            this.refreshWatchStatus();
            // update on app resume
            App.addListener("appStateChange", ({ isActive }) => {
                if (isActive) {
                    this._onResume();
                }
            });
        }

        if (isIos()) {
            // listen for status changes
            AppleWatchConnectivity.addReachabilityListener(this._stateChange);
            if (store.getState().auth.loggedIn) {
                mpUpdatePerson({
                    hasAppleWatch: store.getState().watchStatus.isPaired,
                });
            }
        } else if (isAndroid()) {
            if (store.getState().auth.loggedIn) {
                mpUpdatePerson({
                    hasWearOs: store.getState().watchStatus.isPaired,
                });
            }
        } else {
            console.log("Not iOS, not watching reachability");
        }
    }

    _stateChange = (state: WatchBluetoothStatus | null) => {
        this._setStatusProperties(state);
    };

    _setStatusProperties = (state: WatchBluetoothStatus | null) => {
        if (!state) {
            console.log("Invalid Apple Watch state", state);
            return;
        }

        store.dispatch(
            setWatchStatus({
                isSupported: isIos() || isAndroid(), // set to true if testing in ionic serve, else default false
                isWatchAppInstalled: state.isWatchAppInstalled, // TODO not all of these properties might come back on wearOS
                isComplicationEnabled: state.isComplicationEnabled,
                isActivated: state.isActivated,
                isPaired: state.isPaired,
                isReachable: state.isReachable,
            })
        );
    };

    _setupDevice() {
        if (isIos()) {
            this.detailedStatus = true;
        }
    }

    _onResume() {
        if (isIos()) {
            // AppleWatchConnectivity.addReachabilityListener(this._stateChange);
            this.refreshWatchStatus();
        } else if (isAndroid()) {
            this.refreshWatchStatus();
        }
    }

    refreshWatchStatus() {
        if (isIos()) {
            AppleWatchConnectivity.watchStatus()
                .then(this._setStatusProperties)
                .catch((error) => {
                    console.log("Error checking AppleWatch state", error);
                    Sentry.captureMessage(
                        "[WatchStatus.refreshWatchStatus()] Apple Watch reachability listener error " +
                            JSON.stringify(error)
                    );
                });
        } else if (isAndroid()) {
            // $window.androidWatchConnectivity.watchStatus(
            //     _stateChange,
            //     function (error) {
            //         console.log("Error checking AndroidWatch state", error);
            //         Sentry.captureMessage(
            //             "[WatchStatus.refreshWatchStatus()] WearOS reachability listener error " +
            //                 JSON.stringify(error)
            //         );
            //     }
            // );
        }
    }

    // getWatchStatus(): WatchBluetoothStatus {
    //     return store.getState().watchStatus.isPaired;
    // }
    //
    // deviceSupported(): boolean {
    //     return this.status.isSupported;
    // }
    //
    // reachabilitySupported(): boolean {
    //     return this.detailedStatus && this.status.isSupported;
    // }

    checkForInternetPairedWatches(settings: ConfigPhone) {
        return settings.watches && settings.watches.length > 0;
    }

    checkForPairedWatches(status: WatchBluetoothStatus) {
        if (isIos()) {
            return status.isReachable || status.isPaired;
        } else if (isAndroid()) {
            return status.isPaired;
        } else {
            return false;
        }
    }

    isNativeWatch(platform: string) {
        if (isIos()) {
            return platform.indexOf(WatchOptions.APPLE_WATCH) === 0;
        } else if (isAndroid()) {
            return platform.indexOf(WatchOptions.WEAR_OS) === 0;
        } else {
            return false;
        }
    }

    getPairedWatches(settings: ConfigPhone) {
        var watches = [];

        var watchStatus = store.getState().watchStatus;
        if (isIos() && watchStatus.isPaired) {
            watches.push(WatchOptions.APPLE_WATCH);
        }
        if (isAndroid() && watchStatus.isPaired) {
            watches.push(WatchOptions.WEAR_OS);
        }
        if (settings.watches && settings.watches.length > 0) {
            settings.watches
                .sort(function (a, b) {
                    return a.datePaired - b.datePaired;
                })
                .forEach((watch) => {
                    watches.push(watch.watchOS + this.WEB_WATCH_SUFFIX);
                });
        }
        return watches;
    }

    watchDisplayName(platform: string) {
        switch (platform) {
            case WatchOptions.WEAR_OS:
            case WatchOptions.WEAR_OS + this.WEB_WATCH_SUFFIX:
                return "WearOS";
            case WatchOptions.APPLE_WATCH:
            case WatchOptions.APPLE_WATCH + this.WEB_WATCH_SUFFIX:
                return "Apple Watch";
            case WatchOptions.TIZEN:
            case WatchOptions.TIZEN + this.WEB_WATCH_SUFFIX:
                return "Samsung";
            default:
                return platform;
        }
    }
}
