import {
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonItem,
    IonLabel,
    IonList,
    IonText,
    IonTextarea,
} from "@ionic/react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const NewNotesComponent = () => {
    const { t } = useTranslation();
    return (
        <IonAccordionGroup>
            <IonAccordion>
                <IonItem
                    className="item-title-container"
                    slot="header"
                    data-testid="match-options-notes-accordian"
                    lines="full"
                >
                    <IonLabel position="fixed">{t("general.notes")}</IonLabel>
                    <IonText className="optional-text row-segment-period">
                        {t("general.optional")}
                    </IonText>
                </IonItem>
                <IonList slot="content">
                    <div className="separator" />
                    <IonCard className="card-notes">
                        <Controller
                            name="notes"
                            render={({ field: { onChange, ...rest } }) => (
                                <IonTextarea
                                    className="notes-area"
                                    data-testid="notesTextArea"
                                    onIonChange={onChange}
                                    placeholder={t("general.notes")}
                                    {...rest}
                                />
                            )}
                        />
                    </IonCard>
                </IonList>
            </IonAccordion>
        </IonAccordionGroup>
    );
};
