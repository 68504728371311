import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonItem,
    IonModal,
    IonPage,
    IonText,
    useIonLoading,
    useIonRouter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import RegisterStep1 from "./registerStepOne";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
    checkForUsername,
    login,
    registerUser,
} from "../../services/authService";
import { setCurrentUser } from "../../redux/actions/currentUser";
import { addUserProfile } from "../../redux/actions/addUserProfile";
import { setShowIntegrationPopup, setUsername } from "../../redux/actions/user";
import { userLoggedIn } from "../../redux/actions/auth";
import { useDispatch } from "react-redux";
import { LoginSession } from "../../redux/models/loginSession";
import { insertExampleResult } from "../../services/example-result.service";
import { trackRegistration } from "../../services/analytics/analyticsService";
import { UserProfile } from "../../models/userProfile";
import { AlertComponent, HeaderComponent } from "../../components";
import { routes } from "../../route/constants";
import TermsAndConditionsText from "./termsAndConditionsText";
import PrivacyText from "./privacyText";
import { setTemplates } from "../../redux/actions/templates";
import { getDefaultTemplates } from "../../services/templateService";
import { mpEnable } from "../../services/analytics/mixpanelService";
import { migrateLoginSession } from "../../migrateToNewApp";
import { oneSignalSetUser } from "../../services/OneSignal";
import {
    setLoggedInOrRegisterComplete,
    setShowWhatsNew,
} from "../../redux/actions/appStatus";
import { initializeUXCam } from "../../utils/UXCam";
import { Google } from "../login/providers/Google";
import { Apple } from "../login/providers/Apple";
import { startAds } from "../../services/AdMobService";
import { downloadProfile } from "../../services/profileService";
import {
    checkIfDbuUser,
    getSubscriptions,
} from "../../services/subscriptionService";
import { handleRefreshIntegrationMatches } from "../../services/integrations/integrationSyncUpdates";
import RefSixImage from "../../assets/images/refsix-words-h80px.png";
import { useSafeKeyboard } from "../../components/hooks/useSafeKeyboard";
import { isAndroid, isIos } from "../../services/platformDetection";
import { DividerText } from "../../components/DividerText";
import { useSocialAuthLogin } from "../login/providers/SocialAuthLogin";

export const getAlphaNumericValues = (value: string) => {
    return value.replace(/[^0-9a-z]/gi, "");
};
export const getRandomUsername = (email: string) => {
    const emailSplit = email.split("@")[0];
    const readableUsername = getAlphaNumericValues(emailSplit);

    return readableUsername.slice(0, 10) + Math.random().toString().slice(-6);
};

export const createUsername = async (email: string) => {
    let username = getRandomUsername(email);
    let count: number = 0;
    let inValidUsername = true;

    while (inValidUsername && count < 10) {
        count++;
        username = getRandomUsername(email);
        try {
            await checkForUsername(username);
            inValidUsername = false;
            return username;
        } catch (e) {
            console.log("invalid username, trying again");
        }
    }

    return Promise.reject();
};
const Register = () => {
    let route = useIonRouter();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertDesc, setAlertDesc] = useState<string>("");
    const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
    const [showTerms, setShowTerms] = useState<boolean>(false);
    const [presentLoader, dismissLoader] = useIonLoading();
    const [deviceReady, setDeviceReady] = useState(false);
    const { isOpen: isKeyboardVisible } = useSafeKeyboard();
    const { session, socialLogin, alerts } = useSocialAuthLogin();

    // TODO this keeps the user of the old app (v5) logged in when they upgrade to the new one (v6). Should be removed in the future
    migrateLoginSession()
        .then((hasMigrated) => {
            if (hasMigrated) {
                route.push(routes.refsixFixtures, "root", "replace");
            }
        })
        .catch(() => {
            console.log(
                "Something happened when migrating the login session, probably ok"
            );
        });

    const formMethods = useForm<FieldValues>({
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    const { handleSubmit } = formMethods;

    useEffect(() => {
        const listener = async function () {
            setDeviceReady(true);
        };

        document.addEventListener("deviceready", listener, false);

        return () => {
            document.removeEventListener("deviceready", listener, false);
        };
    }, [document]);
    useEffect(() => {
        if (session && session.data) {
            const resp = loginResponse(session, (session.data as any).email);
        }
    }, [session]);

    function logUserIn(response: any, email: string) {
        if (response.status === 200) {
            const session = response.data as LoginSession;
            dispatch(setUsername(email));
            dispatch(userLoggedIn(true, session));
        }
    }

    function showGeneralError() {
        setAlertDesc(t("general.errorTryAgain"));
        setShowAlert(true);
    }

    const handleUserSubmit = async (registrationData: any) => {
        await presentLoader({ spinner: "circles" });
        try {
            const email = registrationData.registerEmail.toLowerCase();
            const username = await createUsername(email);
            const password = registrationData.registerPassword;

            const user: UserProfile = {
                password: password,
                confirmPassword: password,
                email: email,
                username: username,
                country: "",
                firstName: "",
                lastName: "",
                localFA: "",
                nationalFA: "",
                refereeLevel: "",
            };

            await registerUser(user);

            const loginResponse = await login(email, password);
            logUserIn(loginResponse, email);
            await presentLoader({ spinner: "circles" });

            dispatch(setCurrentUser(user));
            dispatch(addUserProfile(user));
            dispatch(setTemplates(getDefaultTemplates()));
            dispatch(setShowWhatsNew(false));

            // explicitly set the user in OneSignal before enabling mixpanel
            oneSignalSetUser(user.username, user.email);

            mpEnable();
            trackRegistration();

            if (deviceReady) {
                initializeUXCam(username);
            }

            // Add example match to result after successful registration
            setTimeout(() => {
                insertExampleResult(username);
            }, 100);

            await dismissLoader();
            route.push(routes.registerStepTwo, "forward", "replace");
        } catch (error) {
            await dismissLoader();
            showGeneralError();
        }
    };
    async function downloadUserProfile(username: string) {
        const response = await downloadProfile(username);
        const profile = response.data as UserProfile;

        dispatch(setCurrentUser(profile));
        dispatch(addUserProfile(profile));

        try {
            const subscriptions = await getSubscriptions();
            if (subscriptions) {
                // If user is from Denmark -> ask to link DBU account
                if (
                    profile &&
                    profile.country &&
                    profile.country.toUpperCase() === "DK" &&
                    !checkIfDbuUser(subscriptions.subscriptions)
                ) {
                    dispatch(setShowIntegrationPopup(true));
                }

                // check for update from integrations
                handleRefreshIntegrationMatches().catch(function () {
                    console.log("Failed to refresh integration matches");
                });
            }
        } catch (error) {
            console.log("Failed to get subscriptions when logging in", error);
        }
    }

    const loginResponse = async (response: any, email: string) => {
        await presentLoader({ spinner: "circles" });
        if (response.status === 200) {
            const session = response.data as LoginSession;
            const username = session.user_id;
            const status = session.status;
            console.log("Session:", session);
            dispatch(setUsername(username));
            dispatch(userLoggedIn(true, session));
            if (status === "existing") {
                dispatch(setLoggedInOrRegisterComplete(true));
                dispatch(setShowWhatsNew(true));
                downloadUserProfile(username).catch(function () {
                    console.log("Failed to download the user profile");
                });

                startAds();
                await dismissLoader();
                route.push(routes.refsixFixtures, "forward", "replace");

                return;
            }

            const user: UserProfile = {
                email: email,
                username: session.user_id,
                country: "",
                firstName: "",
                lastName: "",
                localFA: "",
                nationalFA: "",
                refereeLevel: "",
            };

            downloadUserProfile(username).catch(function () {
                console.log("Failed to download the user profile");
            });

            dispatch(setTemplates(getDefaultTemplates()));
            dispatch(setShowWhatsNew(false));

            // // explicitly set the user in OneSignal before enabling mixpanel
            oneSignalSetUser(user.username, user.email);

            mpEnable();
            const provider = session.provider || "local";
            trackRegistration(provider);

            if (deviceReady) {
                initializeUXCam(username);
            }

            // Add example match to result after successful registration
            setTimeout(() => {
                insertExampleResult(username);
            }, 1000);

            route.push(routes.registerStepTwo, "forward", "replace");
        } else {
            showGeneralError();
        }
        await dismissLoader();
    };

    return (
        <IonPage>
            <IonContent>
                {alerts}
                <IonItem
                    lines="none"
                    color="transparent"
                    style={{ marginTop: 50 }}
                >
                    <div className="flex-column-container">
                        <div className="flex-center-container">
                            <img src={RefSixImage} style={{ width: "100px" }} />
                        </div>
                        <h1>{t("general.register")}</h1>
                    </div>
                </IonItem>
                {isIos() ? (
                    <div>
                        <Apple
                            requestType={"Register"}
                            showSpinner={true}
                            login={socialLogin}
                        />
                    </div>
                ) : null}
                <div>
                    <Google
                        requestType={"Register"}
                        showSpinner={true}
                        login={socialLogin}
                    />
                </div>
                <DividerText>
                    {t("register.registerOrLogin.orContinueWith")}
                </DividerText>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(handleUserSubmit)}>
                        <IonCard className="ion-card-transparent">
                            <RegisterStep1 />
                            <p>
                                <IonText className="text-wrapper">
                                    {t("register.register.bySigningUp")}
                                    <IonText
                                        className="terms"
                                        onClick={() => setShowTerms(true)}
                                    >
                                        {t("register.register.terms")}
                                    </IonText>
                                    {t("register.register.and")}
                                    <IonText
                                        className="privacy"
                                        onClick={() => setShowPrivacy(true)}
                                    >
                                        {t("register.register.privacy")}
                                    </IonText>
                                </IonText>
                            </p>
                            <IonButton
                                expand="block"
                                className="login-button"
                                data-testid="signUpButton"
                                onClick={handleSubmit(handleUserSubmit)}
                            >
                                {t("register.register.button")}
                            </IonButton>
                            <IonButton
                                fill="clear"
                                expand="block"
                                data-testid="go-to-login-button"
                                onClick={() => route.push(routes.login, "root")}
                            >
                                {t("general.login")}
                            </IonButton>
                            <input type="submit" hidden={true} />
                        </IonCard>
                    </form>
                </FormProvider>

                <IonModal
                    isOpen={showTerms || showPrivacy}
                    onDidDismiss={() => {
                        setShowPrivacy(false);
                        setShowTerms(false);
                    }}
                    initialBreakpoint={1}
                    showBackdrop
                >
                    <HeaderComponent
                        headerEndText={t("upgrade.proFeatures.close")}
                        onClickEndButton={() => {
                            setShowPrivacy(false);
                            setShowTerms(false);
                        }}
                    />
                    <IonText>
                        {showTerms ? (
                            <TermsAndConditionsText />
                        ) : (
                            <PrivacyText />
                        )}
                    </IonText>
                </IonModal>
                <AlertComponent
                    testId={"register-alert"}
                    showAlert={showAlert}
                    buttonDone={t("help.OnboardingVideo.controls.ok")}
                    dismissAlert={() => setShowAlert(false)}
                    description={alertDesc}
                    onDonePressed={() => {}}
                />
            </IonContent>
            <IonFooter>
                <div hidden={isKeyboardVisible}></div>
            </IonFooter>
        </IonPage>
    );
};

export default Register;
