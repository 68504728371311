import html2canvas, { Options } from "html2canvas-pro";
// @ts-ignore
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";

export function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = function () {
            if (!reader.result) {
                reject();
                return;
            }
            var base64String = reader.result.toString().split(",")[1];
            resolve(base64String);
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsDataURL(blob);
    });
}

export async function writeBlobToDisk(
    blob: Blob,
    fileName: string
): Promise<string> {
    const result = await Filesystem.writeFile({
        path: fileName,
        data: await blobToBase64(blob),
        directory: Directory.Cache,
        recursive: true,
    });
    return result.uri;
}

export async function screenshotElement(
    domElement: HTMLElement,
    html2canvasOptions: Partial<Options> = {}
): Promise<Blob> {
    // Create a canvas and use rasterizeHTML to render the DOM element
    const canvas = await html2canvas(domElement, html2canvasOptions);
    // Convert the canvas to a Blob
    try {
        console.log("canvas");
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        if (!blob) throw "Blob is null";
        return blob as Blob;
    } catch (e) {
        throw "Error converting canvas to blob";
    }
}

export async function shareScreenshotImage(
    domElement: HTMLElement,
    html2canvasOptions: Partial<Options> = {
        backgroundColor: "#000000",
    },
    fileName: string = "refsix.png"
) {
    html2canvasOptions = {
        backgroundColor: "#000000",
        ...html2canvasOptions,
    };
    const testing = false;
    // Capture the screenshot
    if (!testing) {
        const blob = await screenshotElement(domElement, html2canvasOptions);

        try {
            // Write the Blob to the cache directory
            const fileURL = await writeBlobToDisk(blob as Blob, fileName);

            const payload = {
                url: fileURL,
            };
            // Share the captured image
            await Share.share(payload);
        } catch (e) {
            // for local testing only
            // const canvas = await html2canvas(domElement, html2canvasOptions);
            // const dataURL: string = canvas.toDataURL("image/jpeg");
            // const image = new Image();
            // image.src = dataURL;
            // const newWindow = window.open("");
            // newWindow && newWindow.document.write(image.outerHTML);
            //
            // console.log(dataURL);
            console.error("Error sharing image", e);
        }
    } else {
        // for local testing only
        const canvas = await html2canvas(domElement, html2canvasOptions);
        const dataURL: string = canvas.toDataURL("image/jpeg");
        const image = new Image();
        image.src = dataURL;
        const newWindow = window.open("");
        newWindow && newWindow.document.write(image.outerHTML);

        console.log(dataURL);
    }
}

export async function shareText(text: string) {
    const payload = {
        text: text,
    };
    return Share.share(payload);
}
