import request from "./request";
import axios from "axios";
import { MgmConfig, MgmReferrer, MgmSummary } from "refsix-js-models";

export const MGM_ENABLED_COUNTRIES = ["GB"];

export async function getSummary(): Promise<MgmSummary> {
    const response = await request.get<MgmSummary>("/mgm/summary");
    return response.data;
}

export async function postReferrerSetup(locale: string): Promise<MgmReferrer> {
    const response = await request.post<MgmReferrer>("/mgm/referrer-setup", {
        locale,
    });
    return response.data;
}

export async function getMgmConfig(): Promise<MgmConfig> {
    return axios
        .get("https://assets.refsix.com/mgm/config.json")
        .then((response) => {
            // @ts-ignore
            return response.data;
        })
        .catch((error) => {
            console.error("Error fetching MGM config:", error);
        });
}
