import React, { FC } from "react";
import "./reviewHeader.css";
import refsixLogo from "../../assets/images/refsix-words-h80px.png";
import reviewLogo from "../../assets/images/review/2024-Icon.png";
import { useProfile } from "../hooks";
import { useTranslation } from "react-i18next";
import { StoryHeader } from "./storyPlayer";

export const ReviewHeader: FC<{}> = () => {
    return (
        <>
            <div className="review-header-story-spacer"></div>

            <div>
                <StoryHeader></StoryHeader>
            </div>
        </>
    );
};
export const Logo: FC = () => {
    return <img className="logo" src={refsixLogo} alt="REFSIX" />;
};
export const Title: FC = () => {
    const { t } = useTranslation();
    return <div className="title">{t("review.yearInReview")} 2023</div>;
};
export const UserName: FC = () => {
    const profile = useProfile();
    return (
        <div className="subtitle">
            {profile?.firstName} {profile?.lastName}
        </div>
    );
};
export const DateBadge: FC = () => {
    return (
        <div className="dateBadge">
            <img src={reviewLogo} />
        </div>
    );
};
