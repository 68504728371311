import { IonIcon, IonItem, IonSelectOption } from "@ionic/react";
import { R6IonSelect } from "../../components/r6ionic/R6IonSelect";
import { getTeamOfficialsList } from "../../utils/teamOfficialsUtils";
import { closeCircle } from "ionicons/icons";
import React, { FC, useCallback, useState } from "react";
import { Player } from "refsix-js-models";
import { TeamSheetFormValues, TeamSheetPlayerType } from "./newTeamSheet";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { UseList } from "../../components/hooks/autocomplete/types";

interface RenderTeamOfficialsPlayers {
    idx: number;
    player: Player;
    playerFieldType: TeamSheetPlayerType;
    showAutoComplete: boolean;
    teamOfficialList: UseList<string[]>;
    removePlayerFromList: (
        playerFieldType: TeamSheetPlayerType,
        idx: number
    ) => void;
    onFocused: () => void;
}

export const RenderTeamOfficialsPlayers: FC<RenderTeamOfficialsPlayers> = ({
    idx,
    player,
    playerFieldType,
    showAutoComplete,
    teamOfficialList,
    removePlayerFromList,
    onFocused,
}) => {
    const { t } = useTranslation();
    const { register, watch, setValue } = useFormContext<TeamSheetFormValues>();
    const playerWatch = watch(`${playerFieldType}.${idx}`);
    const officialRole = watch(`${playerFieldType}.${idx}.role`);

    const [showOverride, setShowOverride] = useState(true);

    const save = useCallback(
        (value: string) => {
            setValue(`${playerFieldType}.${idx}.name`, value);
            setShowOverride(false);
        },
        [setValue, playerFieldType, idx]
    );

    return (
        <div key={player.number}>
            <IonItem lines="full" key={player.number}>
                <R6IonSelect
                    className="teamofficial-select"
                    name={`${playerFieldType}.${idx}.role`}
                    slot="start"
                >
                    {getTeamOfficialsList(t).map((official, index) => (
                        <IonSelectOption key={index} value={official.value}>
                            {official.name}
                        </IonSelectOption>
                    ))}
                </R6IonSelect>
                <div className="input-container">
                    <input
                        data-testid={`teamofficial-input-${idx}`}
                        className="borderless-input transparent-input"
                        {...register(`${playerFieldType}.${idx}.name`)}
                        placeholder={`${t(
                            "fixture.FixtureTeam." + officialRole
                        )}`}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={onFocused}
                    />
                </div>
                <div>
                    <div
                        className="delete-button-wrapper"
                        onClick={() =>
                            removePlayerFromList(playerFieldType, idx)
                        }
                        data-testid="removePlayerButton"
                    >
                        <IonIcon icon={closeCircle} size="small" />
                    </div>
                </div>
            </IonItem>
            <div
                data-testid="teamofficial-autocomplete"
                style={{
                    whiteSpace: "nowrap",
                    overflowX: "scroll",
                }}
            >
                {showAutoComplete &&
                    showOverride &&
                    teamOfficialList.renderer &&
                    teamOfficialList.search &&
                    teamOfficialList.renderer(
                        teamOfficialList.search(playerWatch.name),
                        save
                    )}
            </div>
        </div>
    );
};
