import { IonItem, IonLabel, IonSelectOption } from "@ionic/react";
import { FC, useEffect } from "react";
import {
    getCountryCode,
    getLanguageCode,
    setCountriesLocale,
} from "../utils/localeUtils";
import countries from "i18n-iso-countries";
import { map, size } from "lodash";
import { getNationalFA } from "../constants/nationalFA";
import { useFormContext } from "react-hook-form";
import { getLocaleFA } from "../constants/localeFA";
import { useTranslation } from "react-i18next";
import { R6IonSelect } from "./r6ionic/R6IonSelect";
import { errorProps } from "../services/errorService";

const NationalFASelect = (country: string) => {
    const nationalFAs = getNationalFA()[country];
    if (nationalFAs) {
        return {
            hasNationalFAs: true,
            nationalFAs: map(nationalFAs, (country, countryId) => (
                <IonSelectOption value={countryId} key={countryId}>
                    {country}
                </IonSelectOption>
            )),
        };
    }
    return {
        hasNationalFAs: false,
    };
};
const LocalFASelect = (country: string, nationalFA: string | undefined) => {
    const lfas = getLocaleFA();
    const lfaId = nationalFA ? `${country}_${nationalFA}` : country;
    if (lfas[lfaId]) {
        return {
            hasLocalFAs: true,
            localFAs: map(lfas[lfaId], (fa, id) => (
                <IonSelectOption value={fa} key={id}>
                    {fa}
                </IonSelectOption>
            )),
        };
    }
    return {
        hasLocalFAs: false,
    };
};

export const CountryLeagueSelectorComponent: FC = () => {
    setCountriesLocale();
    const {
        formState: { errors },
        watch,
        reset,
    } = useFormContext();

    const { t } = useTranslation();

    const country = watch("country");
    const nationalFA = watch("nationalFA");

    const { hasNationalFAs, nationalFAs } = NationalFASelect(country);
    const { hasLocalFAs, localFAs } = LocalFASelect(country, nationalFA);

    useEffect(() => {
        if (!hasNationalFAs) {
            reset((formValues) => ({
                ...formValues,
                nationalFA: undefined,
            }));
        }
        if (!hasLocalFAs) {
            reset((formValues) => ({
                ...formValues,
                localFA: undefined,
            }));
        }
        if (!country) {
            reset((formValues) => ({
                ...formValues,
                country: getCountryCode(),
            }));
        }
    }, [country, nationalFA]);

    let countryMap = countries.getNames(getLanguageCode());
    if (size(countryMap) == 0) {
        countryMap = countries.getNames("en");
    }

    const countryOptions = map(countryMap, (country, countryId) => (
        <IonSelectOption value={countryId} key={countryId}>
            {country}
        </IonSelectOption>
    ));
    return (
        <>
            <IonItem {...errorProps("country", errors)} lines="full">
                <IonLabel>{t("myaccount.country")}</IonLabel>
                <R6IonSelect name="country">{countryOptions}</R6IonSelect>
            </IonItem>
            {hasNationalFAs && (
                <IonItem {...errorProps("nationalFA", errors)} lines="full">
                    <IonLabel>{t("myaccount.nationalFA")}</IonLabel>
                    <R6IonSelect name="nationalFA">{nationalFAs}</R6IonSelect>
                </IonItem>
            )}
            {hasLocalFAs && (
                <IonItem {...errorProps("localFA", errors)} lines="full">
                    <IonLabel>{t("myaccount.localFA")}</IonLabel>
                    <R6IonSelect name="localFA">{localFAs}</R6IonSelect>
                </IonItem>
            )}
        </>
    );
};
