import {
    DatePreset,
    FiltersFixtureModel,
    FiltersModel,
    FiltersResultsModel,
} from "../../redux/models/filtersModel";
import { OfficialRole } from "refsix-js-models";
import moment from "moment";
import { MatchType } from "./enums";

export const officialRoleArray = [
    {
        title: "fixture.FixtureNew.referee",
        role: OfficialRole.referee,
    },
    {
        title: "fixture.FixtureNew.assistant",
        role: OfficialRole.assistant,
    },
    {
        title: "fixture.FixtureNew.fourthOfficial",
        role: OfficialRole.fourthOfficial,
    },
    {
        title: "fixture.FixtureNew.observer",
        role: OfficialRole.observer,
    },
];

const fixturesFilter: FiltersFixtureModel = {
    startDate: moment(0).toISOString(),
    endDate: moment().add(10, "years").toISOString(),
    competition: [],
    venue: [],
    team: [],
    officials: [],
    roles: {
        referee: true,
        assistant: true,
        fourthOfficial: true,
        observer: true,
    },
    keywords: [],
    datePreset: DatePreset.Custom,
};

const resultsFilter: FiltersResultsModel = {
    startDate: moment(0).toISOString(),
    endDate: moment().toISOString(),
    competition: [],
    venue: [],
    team: [],
    officials: [],
    roles: {
        referee: true,
        assistant: true,
        fourthOfficial: true,
        observer: true,
    },
    keywords: [],
    stats: {
        yellowCards: {
            value: 0,
        },
        redCards: {
            value: 0,
        },
        goals: {
            value: 0,
        },
    },
    extraTime: false,
    penalties: false,
    abandoned: false,
    datePreset: DatePreset.Career,
};

export function createDefaultFiltersSetting(
    matchType: MatchType
): FiltersModel {
    if (matchType === MatchType.Fixture) {
        return fixturesFilter;
    } else {
        return resultsFilter;
    }
}
