import { ICardCodeMap, MisconductCodes } from "refsix-js-models";
import { store } from "../redux/store";
import { getCustomCodes } from "../utils/Misconduct";
import { getPreSetCodes } from "refsix-core";

export const ALL_CODES = "allcodes";

export enum CardType {
    Yellow = "Yellow",
    Red = "Red",
}

export interface MisconductCodeTotal {
    id: string;
    code: string;
    value: number;
}

/**
 * Transforms `ICardCodeMap` into `MisconductCodeTotal[]`, sorted by highest values,
 * for easier processing
 */
export function transformToMisconductCodeTotals(
    codeMap: ICardCodeMap
): MisconductCodeTotal[] {
    return Object.keys(codeMap)
        .map((keyCode) => ({
            id: keyCode.split("-")[0],
            code: keyCode.split("-")[1],
            value: codeMap[keyCode],
        }))
        .sort((a, b) => b.value - a.value);
}

/**
 * Filters codes based on passed in ID.
 */
export function getAllCodesForId(
    id: string,
    codes: MisconductCodeTotal[]
): MisconductCodeTotal[] {
    return id === ALL_CODES ? codes : codes.filter((code) => code.id === id);
}

export function getMisconductCodes(): MisconductCodes[] {
    let customCodes = store.getState().settings.settings.customCodes[0];
    return [
        customCodes || getCustomCodes(),
        ...getPreSetCodes().sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ),
    ];
}

export function findCodeByCountryId(id: string): MisconductCodes | undefined {
    return getMisconductCodes().find((code) => code.id === id);
}

export function findCodeByName(name: string): MisconductCodes | undefined {
    return getMisconductCodes().find(
        (code) => code.name.toLowerCase() === name.toLowerCase()
    );
}
