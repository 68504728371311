import { store } from "./redux/store";
import { userLoggedIn } from "./redux/actions/auth";
import { setUsername } from "./redux/actions/user";
import { addUserProfile } from "./redux/actions/addUserProfile";
import { setCurrentUser } from "./redux/actions/currentUser";
import { setSubscriptions } from "./redux/actions/setSubscription";
import { SubscriptionState } from "./redux/models/subscriptionState";
import { SubscriptionDetails } from "refsix-js-models";
import { checkIfBasic, checkIfPro } from "./services/subscriptionService";
import { UserProfile } from "./models/userProfile";
import { LoginSession } from "./redux/models/loginSession";
import * as Sentry from "@sentry/react";
import { isAndroid, isIos } from "./services/platformDetection";

const OLD_SESSION_KEY = "superlogin.session";
const OLD_PROFILE_KEY = "refsix.userProfile";
const OLD_SUBSCRIPTIONS_KEY = "refsix.subscription";
const OLD_SUBSCRIPTION_EXPIRY_KEY = "refsix.subscription_expiry";

type OldSubscription = {
    username: string;
    details: SubscriptionDetails;
    updatedAt: number;
};

let running = false;
export function getItem<Type>(key: string): Promise<Type> {
    if (!window.NativeStorage) {
        return Promise.reject();
    }
    const storage = window.NativeStorage;
    return new Promise((resolve, reject) => {
        storage.getItem(key, resolve, reject);
    });
}

export function getString(key: string): Promise<string> {
    if (!window.NativeStorage) {
        return Promise.reject();
    }
    const storage = window.NativeStorage;
    return new Promise((resolve, reject) => {
        storage.getString(key, resolve, reject);
    });
}

export function remove(key: string): Promise<any> {
    if (!window.NativeStorage) {
        return Promise.reject();
    }
    const storage = window.NativeStorage;
    return new Promise((resolve, reject) => {
        storage.remove(key, resolve, reject);
    });
}

export function convertOldSubscription(
    subscription: OldSubscription,
    expiry: number | undefined
): SubscriptionState {
    const subs = subscription?.details?.subscriptions || [];
    const features = subscription?.details?.features || [];
    const isPro = checkIfPro(subs);
    const isPlus = checkIfBasic(subs);

    return {
        subscriptions: subs,
        features: features,
        updatedAt: subscription.updatedAt,
        expiryTs: expiry,
        isPro: isPro,
        isPlus: isPlus,
    };
}

export async function migrateLoginSession() {
    if (running) {
        Sentry.addBreadcrumb({
            message: "migrateLoginSession is already running - skipping",
        });
        throw "migrateLoginSession already running";
    }
    running = true;

    const previousUsername = store.getState().user.usernameOrEmail;

    if (previousUsername) {
        throw "User has already logged into the new app, so no need to migrate";
    }

    if (!window.NativeStorage) {
        if (isIos() || isAndroid()) {
            Sentry.captureMessage(
                `migrateLoginSession NativeStorage not defined on native platform`
            );
        }

        throw "migrateLoginSession NativeStorage plugin not available";
    }

    Sentry.addBreadcrumb({ message: "Running migrateLoginSession" });

    try {
        const [session, profile, subscription, expiryString] =
            await Promise.all([
                getItem<LoginSession>(OLD_SESSION_KEY),
                getItem<UserProfile>(OLD_PROFILE_KEY),
                getItem<OldSubscription>(OLD_SUBSCRIPTIONS_KEY),
                getString(OLD_SUBSCRIPTION_EXPIRY_KEY),
            ]);

        const expiry =
            expiryString === "Infinity" ? Infinity : parseInt(expiryString);
        const newSub = convertOldSubscription(subscription, expiry);

        // update state to make the ser logged in and with everything needed to continue offline e.g. profile, subs
        store.dispatch(setSubscriptions(newSub));
        store.dispatch(userLoggedIn(true, session));
        store.dispatch(setUsername(profile.email));
        store.dispatch(addUserProfile(profile));
        store.dispatch(setCurrentUser(profile));

        // clean up old NativeStorage keys from the old app
        await Promise.all([
            remove(OLD_SESSION_KEY),
            remove(OLD_PROFILE_KEY),
            remove(OLD_SUBSCRIPTIONS_KEY),
            remove(OLD_SUBSCRIPTION_EXPIRY_KEY),
        ]);

        return true;
    } catch (e: any) {
        if (e.code !== 2) {
            Sentry.captureMessage(
                `migrateLoginSession failed with error ${JSON.stringify(e)}`
            );
        }
    }

    return false;
}
