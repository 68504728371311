import { FC } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { metersToKmOrMiles } from "refsix-core";
import { RootState } from "../redux/store";

interface DistanceProps {
    distanceInMeters: number;
    showUnit?: boolean;
    decimalPlaces?: number;
}

export const DistanceUnit: FC = () => {
    const { t } = useTranslation();
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );
    const units = useImperial
        ? t("general.distanceUnit.imperial")
        : t("general.distanceUnit.metric");
    return <>{units}</>;
};
export const DistanceValue: FC<{
    distanceInMeters: number;
    decimalPlaces?: number;
    showUnit?: boolean;
}> = ({ distanceInMeters, decimalPlaces = 2 }) => {
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );
    const value = metersToKmOrMiles(distanceInMeters, useImperial).toFixed(
        decimalPlaces
    );
    return <>{value}</>;
};

export function distanceString(
    distanceInMeters: number,
    useImperial: boolean,
    t: TFunction,
    decimalPlaces: number,
    showUnit: boolean = true
) {
    const value = metersToKmOrMiles(distanceInMeters, useImperial).toFixed(
        decimalPlaces
    );
    const units = useImperial
        ? t("general.distanceUnit.imperial")
        : t("general.distanceUnit.metric");

    if (!showUnit) {
        return `${value}`;
    }

    return `${value} ${units}`;
}

const Distance: FC<DistanceProps> = ({
    distanceInMeters,
    showUnit,
    decimalPlaces = 2,
}) => {
    const { t } = useTranslation();
    const { useImperial } = useSelector(
        (root: RootState) => root.settings.settings
    );

    return (
        <>
            {distanceString(
                distanceInMeters,
                useImperial,
                t,
                decimalPlaces,
                showUnit
            )}
        </>
    );
};

export default Distance;
