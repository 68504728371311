// Call this function when your app starts
import OneSignal, { OSNotificationPermission } from "onesignal-cordova-plugin";
import { isAndroid, isIos } from "./platformDetection";
import { getLanguageCode } from "../utils/localeUtils";
import { trackEvent } from "./analytics/analyticsService";

export function OneSignalInit(): void {
    if (!isIos() && !isAndroid()) {
        console.log("OneSignalInit: Not supported");
        return;
    }

    OneSignal.initialize(
        process.env.REACT_APP_ONESIGNAL_APP_ID ||
            "13fca259-87b0-4e7e-8dcb-1895eb75af0e"
    );

    // The notifications are actually handled in the OneSignalPushListener component

    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);
}

export function oneSignalSetUser(username: string, email: string): void {
    if (!(isIos() || isAndroid())) {
        console.log("oneSignalSetUser: Not supported");
        return;
    }
    if (username && email) {
        OneSignal.login(username);
        OneSignal.User.addEmail(email);
    } else {
        console.log(
            `oneSignalSetUser: username or email is not set ${username} ${email}}`
        );
    }
}

export enum PushNotificationStatus {
    NotDetermined = 0,
    Denied = 1,
    Authorized = 2,
    Provisional = 3,
    Ephemeral = 4,
}

export function getPushNotificationStatus(): Promise<PushNotificationStatus> {
    if (!isIos() && !isAndroid()) {
        return Promise.reject("Not supported");
    }
    return new Promise((resolve) => {
        const hasPermission = OneSignal.Notifications.hasPermission();
        if (hasPermission) {
            return resolve(PushNotificationStatus.Authorized);
        } else {
            return resolve(PushNotificationStatus.Denied);
        }
    });
}

export async function shouldShowPushConsentModal(): Promise<boolean> {
    const experimentParamName = `suppressPushConsent`;
    const variantOverride = new URLSearchParams(window.location.search).get(
        experimentParamName
    );
    if (variantOverride) {
        console.log(`Suppressing push consent`);
        return Promise.reject("Suppressed");
    }

    // TODO push only for english until we get consent translated
    if (!isIos() && !isAndroid() && getLanguageCode() === "en") {
        return Promise.reject("Not supported");
    }
    return new Promise((resolve, reject) => {
        // This is a bug, reported by many users. Timeout is needed until fix is made.
        // hasPermission will always return false without using the Timeout.
        setTimeout(() => {
            const hasPermission = OneSignal.Notifications.hasPermission();
            resolve(!hasPermission);
        }, 500);
    });
}

export async function requestPushConsent() {
    return new Promise((resolve, reject) => {
        return OneSignal.Notifications.requestPermission(true).then(
            (allowed) => {
                const eventName = allowed
                    ? "PushNotificationsAllow"
                    : "PushNotificationsDeny";
                trackEvent(eventName, {});
                OneSignal.setConsentGiven(true);
                trackEvent("PushNotificationConsentYes", {});
                resolve(allowed);
            }
        );
    });
}
