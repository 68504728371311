import {
    IonCard,
    IonContent,
    IonIcon,
    IonItem,
    IonPage,
    IonText,
    useIonAlert,
    useIonRouter,
} from "@ionic/react";
import { cloudDownloadOutline, cloudUploadOutline } from "ionicons/icons";
import { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../components";
import {
    downloadRefSixUploadMatchesTemplate,
    handleFileRead,
} from "../../services/uploadFileService";
import "./uploadDownloadFiles.css";
import {
    addTeamOfficials,
    getPlayersFromTeamPack,
    updateMatch,
} from "../../services/matchService";
import { MatchPhone } from "refsix-js-models";
import {
    trackEvent,
    trackMatchCreated,
} from "../../services/analytics/analyticsService";
import { useTeamPacksContext } from "../../components/hooks/TeamPacks";
import {
    useHasFeatureAccess,
    useUserActionsCheckList,
} from "../../components/hooks";
import { ProTeaser } from "../../components/ProTeaser";
import { getStringOrFallback } from "../../i18n";
import { isUserPlusEligible } from "../../services/subscriptionService";
import { updateUserActionsCheckList } from "../../services/userActionsCheckListService";

export const UploadDownloadFiles = () => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const route = useIonRouter();
    const [presentAlert] = useIonAlert();
    const { downloadedPacks } = useTeamPacksContext();
    const hasFeatureAccessAutoTeamSheet = useHasFeatureAccess("autoTeamSheet");
    const hasFeatureAccessToSpreadsheet = useHasFeatureAccess("uploadMatches");
    const userActionsCheckList = useUserActionsCheckList();

    const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = async (event) => {
                if (event.target?.result) {
                    try {
                        const transformedDataOrStringMessage =
                            handleFileRead(event);
                        if (
                            typeof transformedDataOrStringMessage !==
                                "string" &&
                            transformedDataOrStringMessage.length > 0
                        ) {
                            transformedDataOrStringMessage.forEach(
                                (match: MatchPhone) => {
                                    match.players = match.players || {};

                                    if (hasFeatureAccessAutoTeamSheet) {
                                        match.players = {
                                            [match.homeTeam]:
                                                getPlayersFromTeamPack(
                                                    match.homeTeam,
                                                    downloadedPacks
                                                ),
                                            [match.awayTeam]:
                                                getPlayersFromTeamPack(
                                                    match.awayTeam,
                                                    downloadedPacks
                                                ),
                                        };
                                    }

                                    match.players = addTeamOfficials(
                                        match.players,
                                        match.homeTeam,
                                        match.awayTeam
                                    );

                                    // Create the match
                                    updateMatch(match).then((savedFixture) => {
                                        if (savedFixture) {
                                            if (
                                                !userActionsCheckList.uploadSpreadsheet
                                            ) {
                                                updateUserActionsCheckList({
                                                    uploadSpreadsheet: true,
                                                });
                                            }
                                            trackMatchCreated(savedFixture);
                                        }
                                    });
                                }
                            );

                            await presentAlert({
                                id: "upload-success-alert",
                                header: getStringOrFallback(
                                    "uploadOrImport.successTitle",
                                    "integrations.matches.matchesAdded"
                                ),
                                message: `${
                                    transformedDataOrStringMessage.length
                                } ${t("integrations.matches.matchesAdded")}`,
                                buttons: [
                                    {
                                        id: "upload-success-alert-ok",
                                        text: t(
                                            "help.OnboardingVideo.controls.ok"
                                        ),
                                        handler: () => {
                                            route.goBack();
                                        },
                                    },
                                ],
                            });
                        } else {
                            trackEvent("MatchSpreadsheetUploadFailed", {});
                            await presentAlert({
                                id: "upload-error-alert",
                                message:
                                    transformedDataOrStringMessage as string,
                                buttons: [
                                    {
                                        id: "upload-error-alert-ok",
                                        text: t(
                                            "help.OnboardingVideo.controls.ok"
                                        ),
                                    },
                                ],
                            });
                        }
                    } catch (error) {
                        console.log("File processing file:", error);
                    }
                }
            };

            reader.readAsArrayBuffer(file);
        }
    };

    // Trigger file selection from the input element
    const handleItemClick = () => {
        if (inputRef.current) {
            inputRef.current.click(); // Trigger file selection
        }
    };

    return (
        <IonPage>
            <HeaderComponent
                showBackButton
                title={t("general.addMultipleMatches")}
            />
            <IonContent>
                <IonCard color="transparent">
                    <IonItem lines="none" color="transparent">
                        <IonText className="align-center">
                            {t("uploadOrImport.description")}
                        </IonText>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem
                        lines="none"
                        button
                        onClick={() => downloadRefSixUploadMatchesTemplate()}
                        className="item-height"
                    >
                        <IonIcon icon={cloudDownloadOutline} slot="start" />
                        <div className="upload-text-container">
                            <div className="upload-text-wrapper">
                                <IonText className="small-space">
                                    {t("uploadOrImport.download")}
                                </IonText>
                                <IonText className="text-small dark-color">
                                    {t("uploadOrImport.downloadDescription")}
                                </IonText>
                            </div>
                        </div>
                    </IonItem>
                </IonCard>
                <IonCard>
                    <ProTeaser
                        showTeaser={!hasFeatureAccessToSpreadsheet}
                        teaserName="spreadsheetUpload"
                        isRefSixEligible={isUserPlusEligible()}
                    >
                        <IonItem
                            lines="none"
                            button
                            onClick={handleItemClick}
                            className="item-height"
                            data-testid="upload-button"
                        >
                            <IonIcon icon={cloudUploadOutline} slot="start" />
                            <div className="upload-text-container">
                                <div className="upload-text-wrapper">
                                    <IonText className="small-space">
                                        {t("uploadOrImport.upload")}
                                    </IonText>
                                    <IonText className="text-small dark-color">
                                        {t("uploadOrImport.uploadDescription")}
                                    </IonText>
                                </div>
                            </div>
                            <input
                                ref={inputRef}
                                type="file"
                                accept=".xlsx"
                                onChange={handleFileSelect}
                                hidden={true}
                            ></input>
                        </IonItem>
                    </ProTeaser>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
