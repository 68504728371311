import { SET_DEVELOPER_TOOLS_ENABLED } from "../actions/types";
import { DeveloperTools } from "../models/refsixState";

const initialState: DeveloperTools = {
    enabled: false,
};

export function developerToolsReducer(
    state: DeveloperTools = initialState,
    action: any
) {
    switch (action.type) {
        case SET_DEVELOPER_TOOLS_ENABLED:
            return {
                ...state,
                enabled: action.enabled,
            };
        default:
            return state;
    }
}
