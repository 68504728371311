import { IonIcon, IonRow, IonText } from "@ionic/react";
import { arrowForwardOutline } from "ionicons/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
    EventName,
    GoalEvent,
    IncidentEvent,
    MatchEvent,
    Player,
    SubstitutionEvent,
} from "refsix-js-models";
import { getMatchEventIcon } from "../../utils/playersUtils";

interface RenderTeamsPlayerEventInterface {
    sortedEvents: MatchEvent[];
    player: Player;
}

export const RenderTeamsPlayerEvents: FC<RenderTeamsPlayerEventInterface> = ({
    sortedEvents,
    player,
}) => {
    const { t } = useTranslation();
    const checkForSubsInOrOut = (player: Player, event: SubstitutionEvent) => {
        let iconColor: string;
        let textContent: string;

        if (player.name === event.playerOff.name) {
            iconColor = "red";
            textContent = `${event.minuteOfPlay}' ${event.playerOn.name} #${event.playerOn.number}`;
        } else if (player.name === event.playerOn.name) {
            iconColor = "green";
            textContent = `${event.minuteOfPlay}' ${event.playerOff.name} #${event.playerOff.number}`;
        } else {
            return null;
        }

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <IonIcon
                    style={{ color: iconColor, marginLeft: 2 }}
                    icon={arrowForwardOutline}
                />
                <IonText className="event-minute">{textContent}</IonText>
            </div>
        );
    };

    const getGoalTypeString = (event: GoalEvent): string => {
        if (event.penalty) {
            return t("general.penalty");
        } else if (event.ownGoal) {
            return t("general.ownGoal");
        } else if (event.freeKick) {
            return t("general.freeKick");
        } else {
            return "";
        }
    };

    return (
        <>
            {sortedEvents.length > 0 &&
                sortedEvents
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .reverse()
                    .map((event, idx) => {
                        let incidentEvent = undefined;

                        switch (event.eventName) {
                            case EventName.penaltyShot:
                                return <div />;
                            case EventName.incident:
                                incidentEvent = event as IncidentEvent;
                                return (
                                    <>
                                        <IonRow
                                            className={`event-icons ${getMatchEventIcon(
                                                event
                                            )} small`}
                                        />
                                        <IonText className="event-minute">
                                            {event.minuteOfPlay}'
                                        </IonText>
                                        <IonText className="event-minute">
                                            {incidentEvent &&
                                                `(${incidentEvent.reason})`}
                                        </IonText>
                                    </>
                                );
                            case EventName.goal:
                                incidentEvent = event as GoalEvent;
                                return (
                                    <IonRow key={idx}>
                                        <IonRow
                                            data-testid="goal-event-icon"
                                            key={idx}
                                            className={`event-icons ${getMatchEventIcon(
                                                event
                                            )} small`}
                                        />
                                        <IonText>
                                            {getGoalTypeString(incidentEvent)}
                                        </IonText>
                                        <IonText className="event-minute">
                                            {event.minuteOfPlay}´
                                        </IonText>
                                    </IonRow>
                                );
                            case EventName.substitution:
                                incidentEvent = event as SubstitutionEvent;
                                return checkForSubsInOrOut(
                                    player,
                                    event as SubstitutionEvent
                                );

                            default:
                                return (
                                    <IonRow key={idx}>
                                        <IonRow
                                            key={idx}
                                            className={`event-icons ${getMatchEventIcon(
                                                event
                                            )} small`}
                                        />
                                        <IonText className="event-minute">
                                            {event.minuteOfPlay}´
                                        </IonText>
                                    </IonRow>
                                );
                        }
                    })}
        </>
    );
};
