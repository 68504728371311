import { store } from "../redux/store";
import { t } from "i18next";
import { MisconductCodes, Reason, ReasonType } from "refsix-js-models";
import { findCodeByCountryId } from "../services/misconductService";

export function defaultPlayerYellow(): Reason {
    return {
        code: "Y1",
        reason: t("fixture.FixtureSummary.yellowFull"),
        type: ReasonType.yellow,
        secondYellow: false,
    };
}

export function defaultPlayerRed(): Reason {
    return {
        code: "R1",
        reason: t("fixture.FixtureSummary.red"),
        type: ReasonType.red,
        secondYellow: true,
    };
}

export function defaultOfficialYellow(): Reason {
    return {
        code: t("settings.MisconductSettings.teamOfficialInitial") + "1",
        reason: t("fixture.FixtureSummary.yellowFull"),
        type: ReasonType.yellow,
        secondYellow: false,
        teamOfficial: true,
    };
}

export function defaultOfficialRed(): Reason {
    return {
        code: t("settings.MisconductSettings.teamOfficialInitial") + "2",
        reason: t("fixture.FixtureSummary.red"),
        type: ReasonType.red,
        secondYellow: true,
        teamOfficial: true,
    };
}

export function defaultCodes(): Reason[] {
    return [defaultPlayerYellow(), defaultPlayerRed()].concat(
        getCustomTeamOfficialCodes()
    );
}

export function getCustomCodes(): [MisconductCodes] {
    return [
        {
            id: "custom",
            name: t("settings.MisconductSettings.custom"),
            codes: defaultCodes(),
        },
    ];
}

export function getCustomTeamOfficialCodes() {
    return [defaultOfficialYellow(), defaultOfficialRed()];
}

export function getUserSelectedCodes() {
    let misconductCode = store.getState().settings.settings.misconductCode;
    return findCodeByCountryId(misconductCode);
}

export function getUserSelectedCodesByCard(cardType: "yellow" | "red") {
    let misconductCode = store.getState().settings.settings.misconductCode;
    const codeFilteredByCountry = findCodeByCountryId(misconductCode);
    if (codeFilteredByCountry) {
        return codeFilteredByCountry.codes.filter(
            (card) => card.type === cardType
        );
    }
}
