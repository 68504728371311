import { watchAppHasAcceptableVersion } from "../../utils/versionUtils";
import { getCurrentInAppVersion } from "../../components/hooks/appVersionHooks";
import { store } from "../../redux/store";
import { pushErrorMessage } from "../../redux/actions/errorMessagesActions";
import { watchVersionMismatchError } from "../../utils/errorMessages";
import * as Sentry from "@sentry/react";

export async function checkWatchVersion(watchAppVersion: string | undefined) {
    const phoneAppVersion = await getCurrentInAppVersion();

    Sentry.addBreadcrumb({
        category: "watch",
        message: `Watch app version: ${watchAppVersion} Phone app version: ${phoneAppVersion}`,
    });

    if (
        watchAppVersion &&
        !watchAppHasAcceptableVersion(watchAppVersion, phoneAppVersion)
    ) {
        // Alert the user that the version is old.
        store.dispatch(pushErrorMessage(watchVersionMismatchError));
    }
}
