import cornerMarkerImg from "../assets/images/green-marker.png";

export enum MapMarkerType {
    Generic,
    Corner,
}

export interface MapMarkerProps {
    // lat/lng used implicitly by Google Map parent
    lat: number;
    lng: number;
    type: MapMarkerType;
}

export function MapMarker({ type }: MapMarkerProps) {
    const backgroundColor =
        type === MapMarkerType.Corner ? "transparent" : "yellow";
    const backgroundImg =
        type === MapMarkerType.Corner ? `url(${cornerMarkerImg})` : "";

    return (
        <div
            data-testid="map-marker"
            style={{
                background: `${backgroundColor} ${backgroundImg}`,
                height: "15px",
                width: "15px",
                transform: "translate(-50%, -50%)",
                borderRadius: "8px",
                opacity: type === MapMarkerType.Corner ? "1" : "0.7",
            }}
        ></div>
    );
}
