import { PushMessages } from "../models/refsixState";
import { POP_PUSH_MESSAGE, PUSH_PUSH_MESSAGE } from "../actions/types";

const initialState: PushMessages = {
    messages: [],
};

export function pushMessagesReducer(
    state: PushMessages = initialState,
    action: any
) {
    switch (action.type) {
        case PUSH_PUSH_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.message],
            };
        case POP_PUSH_MESSAGE:
            return {
                ...state,
                messages:
                    state.messages.length > 0
                        ? state.messages.slice(1)
                        : state.messages,
            };
        default:
            return state;
    }
}
