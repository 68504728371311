import React, { FC, useEffect, useState } from "react";
import { Fixture } from "./overview";
import { ToastComponent } from "../../../components";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { recalculateStats } from "../../../services/matchService";
import { IonButton, IonCard, IonCol, IonItem, IonLabel } from "@ionic/react";
import { R6IonInput } from "../../../components/r6ionic/R6IonInput";
import { CurrencySymbol } from "../../../utils/CurrencySymbol";
import "./earnings.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ScrollableContentWithAds from "../../../components/ScrollableContentsWithAd";
import { updateUserActionsCheckList } from "../../../services/userActionsCheckListService";

const Earnings: FC<Fixture> = ({ match }) => {
    const { t } = useTranslation();

    const formMethods = useForm<FieldValues>({
        mode: "all",
        defaultValues: match.earnings,
    });
    const { handleSubmit, reset, watch } = formMethods;

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastTitle, setToastTitle] = useState<string>("");

    const userProfile = useSelector(
        (state: RootState) => state.currentUser?.profile
    );

    const watchFees = watch("fees");
    const watchExpenses = watch("expenses");

    useEffect(() => {
        reset({ fees: watchFees, expenses: watchExpenses });
    }, [watchFees, watchExpenses]);

    // fix for deleting the match
    if (!match) {
        return <div />;
    }

    async function updateEarnings(data: any) {
        try {
            if (!userProfile) {
                throw "Missing user profile";
            }
            let updated = await recalculateStats(
                {
                    ...match,
                    earnings: {
                        fees: parseFloat(data.fees),
                        expenses: parseFloat(data.expenses),
                    },
                },
                userProfile
            );
            if (updated) {
                updateUserActionsCheckList({ earningsAdded: true });

                setToastTitle(
                    t("fixture.FixtureSummaryEarnings.earningsUpdated")
                );
                setShowToast(true);
            }
        } catch (err) {
            setToastTitle(t("fixture.FixtureSummaryEarnings.failedUpdating"));
            setShowToast(true);
            console.log("error trying to update earnings", err);
        }
    }

    return (
        <ScrollableContentWithAds>
            <IonCard
                className="ion-card-transparent"
                data-testid="fixture-segment-earnings"
            >
                <FormProvider {...formMethods}>
                    <form
                        onSubmit={handleSubmit(updateEarnings)}
                        data-testid="earningsForm"
                    >
                        <IonCol>
                            <IonItem
                                lines="full"
                                className="item-title rounded-corners-small"
                            >
                                <IonLabel>{t("general.matchFees")}</IonLabel>
                                <R6IonInput
                                    name="fees"
                                    testId="earningsFee"
                                    type="number"
                                    className="align-right"
                                    step="0.01"
                                />
                                <IonLabel>{CurrencySymbol()}</IonLabel>
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem
                                lines="full"
                                className="item-title rounded-corners-small"
                            >
                                <IonLabel>{t("general.expenses")}</IonLabel>
                                <R6IonInput
                                    name="expenses"
                                    testId="earningsExpenses"
                                    type="number"
                                    className="align-right"
                                    step="0.01"
                                />
                                <IonLabel>{CurrencySymbol()}</IonLabel>
                            </IonItem>
                        </IonCol>

                        <IonButton
                            data-testid="earningsSaveBtn"
                            expand="block"
                            color="light"
                            type="submit"
                        >
                            {t("general.save")}
                        </IonButton>
                    </form>
                </FormProvider>
                <ToastComponent
                    isOpen={showToast}
                    position="bottom"
                    message={toastTitle}
                    onDismiss={() => setShowToast(false)}
                />
            </IonCard>
        </ScrollableContentWithAds>
    );
};

export default Earnings;
