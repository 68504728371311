import { IonItem, IonProgressBar, IonText } from "@ionic/react";
import { FC } from "react";

interface OnBoardingHeadingComponentInterface {
    progressValue: number;
    upperTitleInBold?: string;
    secondTitle?: string;
}

export const OnBoardingHeadingComponent: FC<
    OnBoardingHeadingComponentInterface
> = ({ upperTitleInBold, secondTitle, progressValue }) => {
    return (
        <div>
            <div style={{ width: "100%" }}>
                <IonProgressBar
                    style={{ marginTop: 10 }}
                    value={progressValue}
                />
                {upperTitleInBold && (
                    <IonItem lines="none" color="transparet">
                        <IonText
                            className="text-weight-bold text-large align-center"
                            color="primary"
                            style={{ width: "100%", marginTop: 20 }}
                        >
                            {upperTitleInBold}
                        </IonText>
                    </IonItem>
                )}
                {secondTitle && (
                    <IonItem lines="none" color="transparet">
                        <IonText
                            className="align-center"
                            style={{ width: "100%" }}
                        >
                            {secondTitle}
                        </IonText>
                    </IonItem>
                )}
            </div>
        </div>
    );
};
